import { Currencies } from 'src/shared/constants';

export const useGetCurrencyOptions = (): { title: string; value: string }[] => {
    return [
        {
            title: Currencies.AUD,
            value: Currencies.AUD,
        },
        {
            title: Currencies.CAD,
            value: Currencies.CAD,
        },
        {
            title: Currencies.CHF,
            value: Currencies.CHF,
        },
        {
            title: Currencies.CNY,
            value: Currencies.CNY,
        },
        {
            title: Currencies.CZK,
            value: Currencies.CZK,
        },
        {
            title: Currencies.DKK,
            value: Currencies.DKK,
        },
        {
            title: Currencies.EUR,
            value: Currencies.EUR,
        },
        {
            title: Currencies.GBP,
            value: Currencies.GBP,
        },
        {
            title: Currencies.HKD,
            value: Currencies.HKD,
        },
        {
            title: Currencies.HUF,
            value: Currencies.HUF,
        },
        {
            title: Currencies.JPY,
            value: Currencies.JPY,
        },
        {
            title: Currencies.MYR,
            value: Currencies.MYR,
        },
        {
            title: Currencies.NOK,
            value: Currencies.NOK,
        },
        {
            title: Currencies.PLN,
            value: Currencies.PLN,
        },
        {
            title: Currencies.RON,
            value: Currencies.RON,
        },
        {
            title: Currencies.SEK,
            value: Currencies.SEK,
        },
        {
            title: Currencies.SGD,
            value: Currencies.SGD,
        },
        {
            title: Currencies.THB,
            value: Currencies.THB,
        },
        {
            title: Currencies.USD,
            value: Currencies.USD,
        },
        {
            title: Currencies.ZAR,
            value: Currencies.ZAR,
        },
    ];
};
