import { makeStyles, Theme } from '@material-ui/core/styles';
import { THEME_MODES } from 'src/theming';

import { isCeatInstance, isHectorInstance } from 'src/environment';

const getBackgroundGradient = (isLight: boolean, theme: Theme): string => {
    if (isLight) {
        if (isCeatInstance) {
            return `transparent linear-gradient(180deg, ${theme.palette.secondary.main} 0%, ${theme.palette.secondary.light} 100%) 0% 0% no-repeat padding-box`;
        }
        if (isHectorInstance) {
            return 'transparent linear-gradient(180deg, #6635DF 0%, #8771BE 100%) 0% 0% no-repeat padding-box';
        }
    } else {
        if (isCeatInstance) {
            return `transparent linear-gradient(180deg, ${theme.palette.secondary.main} 0%, ${theme.palette.secondary.light} 100%) 0% 0% no-repeat padding-box`;
        }
        if (isHectorInstance) {
            return 'transparent linear-gradient(180deg, #6635DF 0%, #51339C 100%) 0% 0% no-repeat padding-box';
        }
    }
    return '';
};

const getFormContainerGradient = (isLight: boolean, theme: Theme): string => {
    if (!isLight) {
        return `transparent linear-gradient(180deg, ${theme.palette.secondary.main} 0%, ${theme.palette.secondary.light} 100%) 0% 0% no-repeat padding-box`;
    } else {
        return theme.palette.primary.light;
    }
};

export const useCeatLayoutStyles = makeStyles((theme: Theme) => {
    const isLightTheme = theme.palette.type === THEME_MODES.LIGHT;
    return {
        pageContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100vw',
            height: '100vh',
            backgroundColor: theme.palette.primary.light,
        },
        block: {
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            width: '100%',
        },
        logoAndSwitcherContainer: {
            minHeight: 218,
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
                minHeight: 150,
            },
        },
        switcherContainer: {
            position: 'absolute',
            right: 100,
            top: 20,
        },
        logoContainer: {
            width: 398,
            '& svg': {
                width: '100%',
                height: '100%',
                '& g': {
                    fill: isLightTheme
                        ? theme.palette.secondary.main
                        : theme.palette.primary.contrastText,
                },
            },
        },
        logo: {
            width: '100%',
            filter: !isLightTheme && isCeatInstance ? 'brightness(0) invert(1)' : null,
        },
        previewContainer: {
            minHeight: 260,
            backgroundColor: theme.palette.secondary.main,
            background: getBackgroundGradient(isLightTheme, theme),
            [theme.breakpoints.down('md')]: {
                minHeight: 200,
            },
        },
        previewText: {
            position: 'absolute',
            marginTop: -85,
            color: theme.palette.secondary.contrastText,
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: '3.7rem',
            zIndex: 1001,
        },
        childrenContainer: {
            position: 'relative',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: -125,
            marginBottom: 20,
            [theme.breakpoints.down('md')]: {
                marginTop: -70,
            },
        },
        formContainer: {
            width: 650,
            padding: `${theme.spacing(8)}px ${theme.spacing(1)}rem`,
            boxSizing: 'border-box',
            zIndex: 1000,
            boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.08)',
            border:
                theme.palette.type === THEME_MODES.DARK
                    ? `1px solid ${theme.palette.common.white}`
                    : 'none',
            background: getFormContainerGradient(isLightTheme, theme),
            [theme.breakpoints.down('md')]: {
                padding: `${theme.spacing(4)}px ${theme.spacing(5)}px`,
            },
        },
        footerContainer: {
            width: '100%',
            minHeight: 60,
            backgroundColor: theme.palette.primary.dark,
            '& a,span': {
                color:
                    THEME_MODES.DARK === theme.palette.type
                        ? theme.palette.secondary.contrastText
                        : theme.palette.secondary.main,
            },
        },
    };
});
