import { TFunction } from 'i18next';
import { SelectItem } from 'src/theming';

export enum WHEN_DAMAGE_DEVELOPED_OPTIONS {
    LOADING = 'Loading',
    TRANSPORT = 'Transport',
    UNLOADING = 'Unloading',
}

export const damageReportFormGenerateWhenDamageDevelopedOptions = (
    t: TFunction
): SelectItem[] => {
    return [
        {
            title: t('damages-report:noChoice'),
            value: 'No choice',
        },
        {
            title: t('damages-report:whenIsTheDamageDevelopedOptions.loading'),
            value: WHEN_DAMAGE_DEVELOPED_OPTIONS.LOADING,
        },
        {
            title: t('damages-report:whenIsTheDamageDevelopedOptions.transport'),
            value: WHEN_DAMAGE_DEVELOPED_OPTIONS.TRANSPORT,
        },
        {
            title: t('damages-report:whenIsTheDamageDevelopedOptions.unloading'),
            value: WHEN_DAMAGE_DEVELOPED_OPTIONS.UNLOADING,
        },
    ];
};
