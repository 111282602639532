import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import { ThemeFilesPanelItem } from './theme-files-panel-item';

import { useStyle } from './theme-files-panel-style';

interface ThemeFilesPanelProps {
    files: { id: string; fileName: string }[];
    readonly: boolean;
    onDeleteFile: (id: string) => void;
    route?: string;
    routeId?: string;
    title?: string;
    titleClass?: string;
}

export const ThemeFilesPanel = (props: ThemeFilesPanelProps): JSX.Element => {
    const { title, files, readonly, onDeleteFile, route, routeId } = props;
    const classes = useStyle();

    return (
        <Grid container>
            {
                <>
                    {title && (
                        <Typography variant='h6' className={classes.titleContainer}>
                            {title}
                        </Typography>
                    )}
                    <Grid item container spacing={4} alignItems='flex-start'>
                        {files.map((file) => {
                            return (
                                <ThemeFilesPanelItem
                                    route={route}
                                    routeId={routeId}
                                    onDeleteFile={onDeleteFile}
                                    key={file.id}
                                    readonly={readonly}
                                    file={file}
                                />
                            );
                        })}
                    </Grid>
                </>
            }
        </Grid>
    );
};
