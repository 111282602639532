import { TFunction } from 'i18next';
import { SelectItem } from 'src/theming';

export enum DamageTypes {
    PUBLIC_LIABILITY = 'publicLiability',
    PRODUCT_LIABILITY = 'productLiability',
    ENVIRONMENTAL_LIABILITY = 'environmentalLiability',
    HOME_AND_LANDOWNER_LIABILITY = 'homeAndLandownerLiability',
    OTHER = 'other',
}

export const damageReportFormGenerateBHVAndPHVDamageTypeOptions = (
    t: TFunction
): SelectItem[] => {
    return [
        {
            title: t('damages-report:noChoice'),
            value: 'No choice',
        },
        {
            title: t('damages-report:damageTypeOptionsForPHVAndBHV.publicLiability'),
            value: DamageTypes.PUBLIC_LIABILITY,
        },
        {
            title: t('damages-report:damageTypeOptionsForPHVAndBHV.productLiability'),
            value: DamageTypes.PRODUCT_LIABILITY,
        },
        {
            title: t(
                'damages-report:damageTypeOptionsForPHVAndBHV.environmentalLiability'
            ),
            value: DamageTypes.ENVIRONMENTAL_LIABILITY,
        },
        {
            title: t(
                'damages-report:damageTypeOptionsForPHVAndBHV.homeAndLandownerLiability'
            ),
            value: DamageTypes.HOME_AND_LANDOWNER_LIABILITY,
        },
        {
            title: t('damages-report:damageTypeOptionsForPHVAndBHV.other'),
            value: DamageTypes.OTHER,
        },
    ];
};
