import { makeStyles, Theme } from '@material-ui/core/styles';
import { isSGIFFOXXKInstance } from 'src/environment';

interface CardStyleProps {
    isDarkMode?: boolean;
    withoutHover?: boolean;
    draggable?: boolean;
    isActive?: boolean;
}

export const useCardStyle = makeStyles((theme: Theme) => {
    if (isSGIFFOXXKInstance) {
        return {
            root: {
                height: '100%',
                backgroundColor: ({ isActive }: CardStyleProps) =>
                    isActive ? theme.palette.secondary.main : theme.palette.primary.dark,
                borderRadius: 2,
                transition: '0.2s',
                cursor: ({ draggable }: CardStyleProps) =>
                    draggable ? 'grab' : 'pointer',
                color: ({ isActive }: CardStyleProps) =>
                    isActive
                        ? theme.palette.info.contrastText
                        : theme.palette.primary.contrastText,
                '& path': {
                    fill: ({ isDarkMode, isActive }: CardStyleProps) => {
                        if (isActive) {
                            return theme.palette.secondary.contrastText;
                        } else {
                            return isDarkMode
                                ? theme.palette.info.main
                                : theme.palette.secondary.main;
                        }
                    },
                    opacity: ({ isActive }: CardStyleProps) => (isActive ? 1 : 0.4),
                },
                '&:hover': {
                    backgroundColor: ({ withoutHover }: CardStyleProps) =>
                        !withoutHover && theme.palette.info.main,
                    color: ({ withoutHover }: CardStyleProps) =>
                        !withoutHover && theme.palette.info.contrastText,
                    boxShadow: ({ withoutHover }: CardStyleProps) =>
                        !withoutHover &&
                        '0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)',
                    '& path': {
                        fill: ({ withoutHover }: CardStyleProps) =>
                            !withoutHover && theme.palette.secondary.contrastText,
                        opacity: ({ withoutHover }: CardStyleProps) => !withoutHover && 1,
                    },
                },
            },
        };
    }
    return {
        root: {
            height: '100%',
            backgroundColor: ({ isActive }: CardStyleProps) =>
                isActive ? theme.palette.info.main : theme.palette.primary.light,
            borderRadius: 2,
            transition: '0.2s',
            cursor: ({ draggable }: CardStyleProps) => (draggable ? 'grab' : 'pointer'),
            color: ({ isActive }: CardStyleProps) =>
                isActive
                    ? theme.palette.info.contrastText
                    : theme.palette.primary.contrastText,
            '& path': {
                fill: ({ isDarkMode, isActive }: CardStyleProps) => {
                    if (isActive) {
                        return theme.palette.secondary.contrastText;
                    } else {
                        return isDarkMode
                            ? theme.palette.info.main
                            : theme.palette.secondary.main;
                    }
                },
                opacity: ({ isActive }: CardStyleProps) => (isActive ? 1 : 0.4),
            },
            '&:hover': {
                backgroundColor: ({ withoutHover }: CardStyleProps) =>
                    !withoutHover && theme.palette.info.main,
                color: ({ withoutHover }: CardStyleProps) =>
                    !withoutHover && theme.palette.info.contrastText,
                boxShadow: ({ withoutHover }: CardStyleProps) =>
                    !withoutHover &&
                    '0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)',
                '& path': {
                    fill: ({ withoutHover }: CardStyleProps) =>
                        !withoutHover && theme.palette.secondary.contrastText,
                    opacity: ({ withoutHover }: CardStyleProps) => !withoutHover && 1,
                },
            },
        },
    };
});

export const useCardContentStyle = makeStyles(() => {
    return {
        root: {
            height: '100%',
        },
    };
});
