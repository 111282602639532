import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { ThemeCircularProgress, ThemeModalWindow } from 'src/theming';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { useStyle } from './user-editing-form-styles';

import { UserEditFormApproveDeletingModalWindowProps } from './user-edit-form-types';

export const UserEditFormApproveDeletingModalWindow = (
    props: UserEditFormApproveDeletingModalWindowProps
): JSX.Element => {
    const classes = useStyle();

    const { t } = useTranslation(['common', 'user-management']);

    const httpClient = useHttpClient();
    const { enqueueSnackbar } = useSnackbar();

    const { activeUser, makeRequestForUsersList, handleAddNewUser } = props;

    const [isOpened, setOpen] = useState(false);
    const [isDeletingLoading, setDeletingLoading] = useState(false);

    const handleOpenModalWindow = (): void => {
        setOpen(true);
    };

    const handleCloseModalWindow = (): void => {
        setOpen(false);
    };

    const handleDeleteUser = (): void => {
        setDeletingLoading(true);
        httpClient
            .delete(`users/${activeUser}`)
            .then(() => {
                enqueueSnackbar(
                    t('user-management:umSection.userIsDeletedSuccessfully'),
                    {
                        variant: 'success',
                    }
                );
                makeRequestForUsersList();
                handleAddNewUser();
            })
            .catch(() => {
                enqueueSnackbar(t('errors:unknownError'), {
                    variant: 'error',
                });
            })
            .finally(() => {
                setDeletingLoading(false);
                setOpen(false);
            });
    };

    return (
        <>
            <IconButton
                className={classes.deleteUserButton}
                onClick={handleOpenModalWindow}
            >
                <DeleteIcon />
            </IconButton>
            <ThemeModalWindow isOpen={isOpened} onClose={handleCloseModalWindow}>
                <></>
                <Typography variant='body1' className={classes.modalMessage}>
                    {t('user-management:umSection.approveMessageForDeleteUser')}
                </Typography>
                <Grid container className={classes.modalActionsContainer}>
                    <Button
                        variant='outlined'
                        className={clsx(classes.modalButton, classes.cancelButton)}
                        onClick={handleCloseModalWindow}
                    >
                        {t('common:cancel')}
                    </Button>
                    <Button
                        disableElevation
                        variant='contained'
                        className={clsx(classes.modalButton, classes.okButton)}
                        onClick={handleDeleteUser}
                        disabled={isDeletingLoading}
                        startIcon={
                            <ThemeCircularProgress isLoading={isDeletingLoading} />
                        }
                    >
                        {t('common:yes')}
                    </Button>
                </Grid>
            </ThemeModalWindow>
        </>
    );
};
