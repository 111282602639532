import React from 'react';
import useDarkMode from 'use-dark-mode';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { ThemeLayout } from 'src/theming';
import { ContractsList } from 'src/contracts';

export const ContractsPage = (): JSX.Element => {
    const darkMode = useDarkMode();
    return (
        <DndProvider backend={HTML5Backend}>
            <ThemeLayout darkMode={darkMode}>
                <div style={{ paddingBottom: 20, height: '100%' }}>
                    <ContractsList />
                </div>
            </ThemeLayout>
        </DndProvider>
    );
};
