import React, { ChangeEvent, RefObject } from 'react';
import { TFunction } from 'i18next';
import { GridColDef, GridRowId, GridCellParams } from '@material-ui/data-grid';

import { makeStyles, Typography, Theme, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Visibility } from '@material-ui/icons';

import {
    CheckboxItem,
    MenuItem,
    ThemeNestedMenu,
    ToggleButtonItem,
    ThemeDraggableElement,
} from 'src/theming';

import { APP_ROUTES } from 'src/routing';
import { Divisions } from 'src/shared/constants';

import {
    renderCellAsCurrencyExpand,
    renderCellAsDateExpand,
    renderCellExpand,
    renderCellExpandValueAsKeyForTranslation,
} from 'src/theming/theme-table/theme-table-cell-expand';
import { ContractsListTableSettings } from './contracts-list-table-settings';
import { SECTIONS } from '../contracts-details-view/contract-details-view-details';
import { FilterOptions } from '../contracts-configs';
import { CellWithSearch } from '../../theming/theme-table/cell-with-search';
import { CellWithFilterCheckboxes } from '../../theming/theme-table/cell-with-filter-checkboxes';

import {
    appInstancesThatHaveAccessToCreateContract,
    AppInstances,
} from 'src/shared/constants';
import { appInstance } from 'src/environment';
import { AnyAbility } from '@casl/ability';
import { AbilityActions, AbilitySubjects } from 'src/roleAccesses';

const isAONKompositInstance = appInstance === AppInstances.AON_KOMPOSIT;

export enum ColumnKeys {
    OPEN = '',
    VNR = 'contractNumber',
    PRODUCT = 'product',
    CLIENT = 'customer',
    GENERAL_RISK = 'risk',
    START_OF_CONTRACT = 'startDate',
    CONTRACT_EXPIRATION = 'endDate',
    ANNUAL_PREMIUM = 'annualPremiumInclusiveTaxes',
    PAYMENT_METHOD = 'paymentMethod',
    COST_CENTER_VN = 'costCenterVn',
    LIABILITY = 'liability',
    FULLY_COVERAGE = 'fullyCoverage',
    PARTIAL_COVERAGE = 'partialCoverage',
    ACTIONS = 'actions',
}

interface ColumnHeaderWithInnerRefProps {
    title: string;
    innerRef?: RefObject<HTMLBaseElement>;
    rightSeparation?: boolean;
    leftSeparation?: boolean;
}

interface HeaderStyleProps {
    leftSeparation: boolean;
    rightSeparation: boolean;
}

const useColumnHeaderStyles = makeStyles((theme: Theme) => {
    return {
        title: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            borderLeft: ({ leftSeparation }: HeaderStyleProps) =>
                leftSeparation ? '1px solid white' : 'none',
            borderRight: ({ rightSeparation }: HeaderStyleProps) =>
                rightSeparation ? '1px solid white' : 'none',
        },
    };
});

export const ColumnHeaderWithInnerRef = ({
    title,
    innerRef,
    leftSeparation,
    rightSeparation,
    ...rest
}: ColumnHeaderWithInnerRefProps): JSX.Element => {
    const classes = useColumnHeaderStyles({ leftSeparation, rightSeparation });
    return (
        <Typography
            ref={innerRef}
            variant='body2'
            component='span'
            className={classes.title}
            {...rest}
        >
            {title}
        </Typography>
    );
};

export const generateContractsListTableSocietyList = (
    list: CheckboxItem[],
    t: TFunction,
    customers?: string[]
): CheckboxItem[] => {
    if (customers && customers.length > 0) {
        return list.map((item) => {
            if (customers.find((customer) => customer === item.value)) {
                return { ...item, checked: true };
            } else {
                return { ...item, checked: false };
            }
        });
    }
    const filteredSocietyList = list.find((item) => item.value === 'all')?.checked
        ? list.filter((item) => item.value !== 'all')
        : list.filter((item) => item.checked && item.value !== 'all');
    return [
        ...filteredSocietyList.map((item) => ({ ...item, checked: false })),
        {
            value: 'all',
            title: t('all'),
            checked: true,
            data: undefined,
        },
    ];
};

export const generateContractsListMenuForTableSettings = (
    t: TFunction,
    data?: ContractsListTableSettings,
    division?: string
): CheckboxItem[] => {
    if (data) {
        const needAdditionalColumnsForKFZW = division === Divisions.KFZW;
        return [
            {
                title: t('contracts-list:tableHeaders.vnr'),
                value: ColumnKeys.VNR,
                checked: data?.columns?.find((item) => item.name === ColumnKeys.VNR)
                    ?.isShown,
                data: data?.columns?.find((item) => item.name === ColumnKeys.VNR),
            },
            ...(!isAONKompositInstance
                ? [
                      {
                          title: t('contracts-list:tableHeaders.product'),
                          value: ColumnKeys.PRODUCT,
                          checked: data?.columns?.find(
                              (item) => item.name === ColumnKeys.PRODUCT
                          )?.isShown,
                          data: data?.columns?.find(
                              (item) => item.name === ColumnKeys.PRODUCT
                          ),
                      },
                  ]
                : []),
            {
                title: t('contracts-list:tableHeaders.client'),
                value: ColumnKeys.CLIENT,
                checked: data?.columns?.find((item) => item.name === ColumnKeys.CLIENT)
                    ?.isShown,
                data: data?.columns?.find((item) => item.name === ColumnKeys.CLIENT),
            },
            {
                title: t('contracts-list:tableHeaders.generalRisk'),
                value: ColumnKeys.GENERAL_RISK,
                checked: data?.columns?.find(
                    (item) => item.name === ColumnKeys.GENERAL_RISK
                )?.isShown,
                data: data?.columns?.find(
                    (item) => item.name === ColumnKeys.GENERAL_RISK
                ),
            },
            {
                title: t('contracts-list:tableHeaders.startOfContract'),
                value: ColumnKeys.START_OF_CONTRACT,
                checked: data?.columns?.find(
                    (item) => item.name === ColumnKeys.START_OF_CONTRACT
                )?.isShown,
                data: data?.columns?.find(
                    (item) => item.name === ColumnKeys.START_OF_CONTRACT
                ),
            },
            {
                title: t('contracts-list:tableHeaders.contractExpiration'),
                value: ColumnKeys.CONTRACT_EXPIRATION,
                checked: data?.columns?.find(
                    (item) => item.name === ColumnKeys.CONTRACT_EXPIRATION
                )?.isShown,
                data: data?.columns?.find(
                    (item) => item.name === ColumnKeys.CONTRACT_EXPIRATION
                ),
            },
            {
                title: t('contracts-list:tableHeaders.annualPremium'),
                value: ColumnKeys.ANNUAL_PREMIUM,
                checked: data?.columns?.find(
                    (item) => item.name === ColumnKeys.ANNUAL_PREMIUM
                )?.isShown,
                data: data?.columns?.find(
                    (item) => item.name === ColumnKeys.ANNUAL_PREMIUM
                ),
            },
            {
                title: t('contracts-list:tableHeaders.paymentMethod'),
                value: ColumnKeys.PAYMENT_METHOD,
                checked: data?.columns?.find(
                    (item) => item.name === ColumnKeys.PAYMENT_METHOD
                )?.isShown,
                data: data?.columns?.find(
                    (item) => item.name === ColumnKeys.PAYMENT_METHOD
                ),
            },
            ...(needAdditionalColumnsForKFZW
                ? [
                      {
                          title: t('contracts-list:tableHeaders.costCenterVN'),
                          value: ColumnKeys.COST_CENTER_VN,
                          checked: data?.columns?.find(
                              (item) => item.name === ColumnKeys.COST_CENTER_VN
                          )?.isShown,
                          data: data?.columns?.find(
                              (item) => item.name === ColumnKeys.COST_CENTER_VN
                          ),
                      },
                      {
                          title: t('contracts-list:tableHeaders.carCoverageLiability'),
                          value: ColumnKeys.LIABILITY,
                          checked: data?.columns?.find(
                              (item) => item.name === ColumnKeys.LIABILITY
                          )?.isShown,
                          data: data?.columns?.find(
                              (item) => item.name === ColumnKeys.LIABILITY
                          ),
                      },
                      {
                          title: t(
                              'contracts-list:tableHeaders.carCoverFullyComprehensive'
                          ),
                          value: ColumnKeys.FULLY_COVERAGE,
                          checked: data?.columns?.find(
                              (item) => item.name === ColumnKeys.FULLY_COVERAGE
                          )?.isShown,
                          data: data?.columns?.find(
                              (item) => item.name === ColumnKeys.FULLY_COVERAGE
                          ),
                      },
                      {
                          title: t(
                              'contracts-list:tableHeaders.carCoverPartiallyComprehensive'
                          ),
                          value: ColumnKeys.PARTIAL_COVERAGE,
                          checked: data?.columns?.find(
                              (item) => item.name === ColumnKeys.PARTIAL_COVERAGE
                          )?.isShown,
                          data: data?.columns?.find(
                              (item) => item.name === ColumnKeys.PARTIAL_COVERAGE
                          ),
                      },
                  ]
                : []),
        ];
    }
    return [];
};

const generateContractsListTableAdditionalMenu = (
    t: TFunction,
    handleContractDetails: (params: { id: GridRowId }, preselectedTab?: SECTIONS) => void,
    id: GridRowId,
    goTo: (path: string) => void,
    ability: AnyAbility,
    isDummy?: boolean,
    contractNumber?: string
): MenuItem[] => {
    return [
        ...(ability.can(AbilityActions.see, AbilitySubjects.damageReport)
            ? [
                  {
                      title: t('contracts-list:rowAdditionalMenu.reportDamage'),
                      action: () =>
                          goTo(
                              `${APP_ROUTES.DAMAGE_REPORT}?contractNumber=${contractNumber}&contractId=${id}`
                          ),
                  },
              ]
            : []),
        {
            title: t('contracts-list:rowAdditionalMenu.contractDetails'),
            action: () => handleContractDetails({ id }),
        },
        {
            title: t('contracts-list:rowAdditionalMenu.contractDamage'),
            action: () => handleContractDetails({ id }, SECTIONS.DAMAGES),
        },
        {
            title: t('contracts-list:rowAdditionalMenu.contractDocuments'),
            action: () => handleContractDetails({ id }),
        },
        ...(appInstancesThatHaveAccessToCreateContract.includes(
            appInstance as AppInstances
        ) &&
        ability.can(AbilityActions.edit, AbilitySubjects.dummyContract) &&
        isDummy
            ? [
                  {
                      title: t('contracts-list:editDummyContract'),
                      action: () => goTo(`${APP_ROUTES.DUMMY_CONTRACT}?contractId=${id}`),
                  },
              ]
            : []),
    ];
};

export const generateContractsListTableHeader = (
    t: TFunction,
    vnrValue: string,
    handleChangeVnr: (e: ChangeEvent<HTMLInputElement>) => void,
    productsList: CheckboxItem[],
    handleChangeList: (list: CheckboxItem[], name: string) => void,
    columnsSettings: CheckboxItem[],
    handleContractDetails: (params: { id: GridRowId }, preselectedTab?: SECTIONS) => void,
    goTo: (path: string) => void,
    ability: AnyAbility,
    onCloseHandler: () => void,
    isDragAndDropLocked: boolean,
    hoverPosition: number,
    startPosition: number
): GridColDef[] => {
    return [
        {
            field: ColumnKeys.OPEN,
            width: 60,
            headerName: '',
            renderCell: ({ id }) => (
                <IconButton
                    onClick={() => {
                        handleContractDetails({ id });
                    }}
                >
                    <Visibility />
                </IconButton>
            ),
        },
        {
            field: ColumnKeys.VNR,
            headerName: t('contracts-list:tableHeaders.vnr'),
            flex: 1,
            renderHeader: (props) => (
                <ThemeDraggableElement
                    isDragAndDropLocked={isDragAndDropLocked}
                    draggableId={ColumnKeys.VNR}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) => column.value === ColumnKeys.VNR
                        )?.data?.position
                    }
                >
                    <CellWithSearch
                        {...props}
                        title={t('contracts-list:tableHeaders.vnr')}
                        value={vnrValue}
                        handleChange={handleChangeVnr}
                    />
                </ThemeDraggableElement>
            ),
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.VNR
            )?.checked,
            renderCell: renderCellExpand,
            sortable: false,
        },
        ...(!isAONKompositInstance
            ? [
                  {
                      field: ColumnKeys.PRODUCT,
                      headerName: t('contracts-list:tableHeaders.product'),
                      flex: 1,
                      renderHeader: (props) => (
                          <ThemeDraggableElement
                              draggableId={ColumnKeys.PRODUCT}
                              draggableIndex={
                                  columnsSettings.find(
                                      (column: CheckboxItem) =>
                                          column.value === ColumnKeys.PRODUCT
                                  )?.data?.position
                              }
                              isDragAndDropLocked={isDragAndDropLocked}
                              hoverPosition={hoverPosition}
                              startPosition={startPosition}
                          >
                              <CellWithFilterCheckboxes
                                  {...props}
                                  onCloseHandler={onCloseHandler}
                                  name='product'
                                  title={t('contracts-list:tableHeaders.product')}
                                  itemList={productsList}
                                  setItemList={handleChangeList}
                              />
                          </ThemeDraggableElement>
                      ),
                      hide: !columnsSettings.find(
                          (column: CheckboxItem) => column.value === ColumnKeys.PRODUCT
                      )?.checked,
                      renderCell: renderCellExpand,
                  },
              ]
            : []),
        {
            field: ColumnKeys.CLIENT,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.CLIENT
            )?.checked,
            renderCell: renderCellExpand,
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeys.CLIENT}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) => column.value === ColumnKeys.CLIENT
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('contracts-list:tableHeaders.client')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeys.GENERAL_RISK,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.GENERAL_RISK
            )?.checked,
            renderCell: renderCellExpand,
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeys.GENERAL_RISK}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value === ColumnKeys.GENERAL_RISK
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('contracts-list:tableHeaders.generalRisk')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeys.START_OF_CONTRACT,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.START_OF_CONTRACT
            )?.checked,
            renderCell: renderCellAsDateExpand,
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeys.START_OF_CONTRACT}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value === ColumnKeys.START_OF_CONTRACT
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('contracts-list:tableHeaders.startOfContract')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeys.CONTRACT_EXPIRATION,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.CONTRACT_EXPIRATION
            )?.checked,
            renderCell: renderCellAsDateExpand,
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeys.CONTRACT_EXPIRATION}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value === ColumnKeys.CONTRACT_EXPIRATION
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('contracts-list:tableHeaders.contractExpiration')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeys.ANNUAL_PREMIUM,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.ANNUAL_PREMIUM
            )?.checked,
            renderCell: renderCellAsCurrencyExpand,
            align: 'right',
            headerAlign: 'right',
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeys.ANNUAL_PREMIUM}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value === ColumnKeys.ANNUAL_PREMIUM
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('contracts-list:tableHeaders.annualPremium')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeys.PAYMENT_METHOD,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.PAYMENT_METHOD
            )?.checked,
            renderCell: (params: GridCellParams) =>
                renderCellExpandValueAsKeyForTranslation(params, ['contracts-list']),
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeys.PAYMENT_METHOD}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value === ColumnKeys.PAYMENT_METHOD
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('contracts-list:tableHeaders.paymentMethod')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeys.COST_CENTER_VN,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.COST_CENTER_VN
            )?.checked,
            renderCell: renderCellExpand,
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeys.COST_CENTER_VN}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value === ColumnKeys.COST_CENTER_VN
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('contracts-list:tableHeaders.costCenterVN')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeys.LIABILITY,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.LIABILITY
            )?.checked,
            renderCell: (params: GridCellParams) =>
                renderCellExpandValueAsKeyForTranslation(params, ['common']),
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeys.LIABILITY}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value === ColumnKeys.LIABILITY
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('contracts-list:tableHeaders.carCoverageLiability')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeys.FULLY_COVERAGE,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.FULLY_COVERAGE
            )?.checked,
            renderCell: (params: GridCellParams) =>
                renderCellExpandValueAsKeyForTranslation(params, ['common']),
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeys.FULLY_COVERAGE}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value === ColumnKeys.FULLY_COVERAGE
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t(
                            'contracts-list:tableHeaders.carCoverFullyComprehensive'
                        )}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeys.PARTIAL_COVERAGE,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.PARTIAL_COVERAGE
            )?.checked,
            renderCell: (params: GridCellParams) =>
                renderCellExpandValueAsKeyForTranslation(params, ['common']),
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeys.PARTIAL_COVERAGE}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value === ColumnKeys.PARTIAL_COVERAGE
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t(
                            'contracts-list:tableHeaders.carCoverPartiallyComprehensive'
                        )}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeys.ACTIONS,
            flex: 1,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            headerName: t('contracts-list:tableHeaders.actions'),
            renderCell: (params) => {
                return (
                    <ThemeNestedMenu
                        menuItems={generateContractsListTableAdditionalMenu(
                            t,
                            handleContractDetails,
                            params.id,
                            goTo,
                            ability,
                            params?.row?.isDummy,
                            params.row.contractNumber
                        )}
                        buttonIcon={<MoreVertIcon />}
                    />
                );
            },
        },
    ];
};

export const generateContractsListToggleButtons = (t: TFunction): ToggleButtonItem[] => {
    return [
        { title: t('contracts-list:filterTitles.active'), value: FilterOptions.ACTIVE },
        { title: t('contracts-list:filterTitles.inactive'), value: FilterOptions.ALL },
    ];
};
