import { makeStyles } from '@material-ui/core';

export const useDashboardListOfNumericValuesStyle = makeStyles(() => {
    return {
        root: {
            width: '100%',
            '& .MuiListItemText-root': {
                width: '50%',
                '& .MuiTypography-root': {
                    fontWeight: 'bold',
                },
                '&[data-value] .MuiTypography-root': {
                    fontSize: '1.8rem',
                },
            },
            '& .MuiListItem-root': {
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
    };
});
