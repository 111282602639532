import React from 'react';
import useDarkMode from 'use-dark-mode';
import { ThemeLayout } from 'src/theming';
import { ReportCenter } from 'src/contracts/report-center/report-center';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export const ReportCenterPage = (): JSX.Element => {
    const darkMode = useDarkMode();
    return (
        <DndProvider backend={HTML5Backend}>
            <ThemeLayout darkMode={darkMode}>
                <ReportCenter />
            </ThemeLayout>
        </DndProvider>
    );
};
