import { makeStyles, Theme } from '@material-ui/core';

export const useStyle = makeStyles((theme: Theme) => {
    return {
        titleContainer: {
            paddingBottom: 20,
        },
        fileItemContainer: {
            position: 'relative',
            overflow: 'hidden',
            maxWidth: 100,
            justifyContent: 'center',
            alignItems: 'center',
            transition: '0.2s',
            '&:hover': {
                backgroundColor: theme.palette.info.light,
                '& .MuiButtonBase-root': {
                    display: 'flex',
                },
            },
        },
        attachmentIcon: {
            width: 50,
            height: 50,
        },
        fileName: {
            width: '100%',
            overflow: 'hidden',
            marginTop: theme.spacing(2),
            fontSize: '1.2rem',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        iconButton: {
            display: 'none',
            position: 'absolute',
            width: 20,
            height: 20,
            borderRadius: 0,
        },
        deleteButton: {
            top: 0,
            left: 0,
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.main,
        },
        downloadButton: {
            top: 0,
            right: 0,
            backgroundColor: theme.palette.info.light,
            color: theme.palette.info.main,
        },
    };
});
