import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, FormControl, FormControlLabel, GridSize } from '@material-ui/core';
import { FileObject } from 'material-ui-dropzone';

import { ThemeDropZone } from 'src/theming';

import {
    useFormControlLabelStyles,
    useFormControlStyles,
    useContractsCreateContractsFormStyles,
} from '../contracts-create-contract-form-styles';

interface DocumentsProps {
    value: FileObject[];
    name: string;
    label: string;
    onAdd: (file: FileObject[]) => void;
    onRemove: (index: number) => void;
    error?: boolean;
    documentFormats?: string[];
    md?: GridSize;
}

export const DocumentsField = ({
    md,
    value,
    error,
    label,
    onAdd,
    onRemove,
    documentFormats,
}: DocumentsProps): JSX.Element => {
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();
    const classes = useContractsCreateContractsFormStyles();
    const { t } = useTranslation(['create-contract']);

    return (
        <Grid item md={md}>
            <FormControl
                fullWidth
                classes={formControlClasses}
                variant='outlined'
                className={classes.dateField}
                error={error}
            >
                <FormControlLabel
                    classes={formControlLabelClasses}
                    control={
                        <ThemeDropZone
                            error={error}
                            fileObjects={value}
                            formats={documentFormats}
                            onRemove={onRemove}
                            onAdd={onAdd}
                        />
                    }
                    label={t(label)}
                    labelPlacement='top'
                />
            </FormControl>
        </Grid>
    );
};
