import { makeStyles, Theme } from '@material-ui/core/styles';

import {
    isSGIFFOXXKInstance,
    isAonMotorInstance,
    isAonDigitalInstance,
    isHectorDemoInstance,
} from 'src/environment';
import { THEME_MODES } from '..';

export const useStyle = makeStyles((theme: Theme) => {
    const isDarkMode = theme.palette.type === THEME_MODES.DARK;
    if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
        return {
            footer: {
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                color: theme.palette.primary.contrastText,
                fontSize: 12,
            },
            separator: {
                margin: '0 1rem',
            },
            footerLink: {
                color: theme.palette.primary.contrastText,
            },
        };
    }
    if (isSGIFFOXXKInstance) {
        return {
            footer: {
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                color: isDarkMode
                    ? theme.palette.primary.contrastText
                    : theme.palette.secondary.main,
                fontSize: 12,
            },
            separator: {
                margin: '0 1rem',
            },
            footerLink: {
                color: theme.palette.primary.contrastText,
            },
        };
    }
    return {
        footer: {
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontSize: 12,
        },
        separator: {
            margin: '0 1rem',
        },
        footerLink: {
            color: theme.palette.secondary.contrastText,
        },
    };
});
