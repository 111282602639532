import React from 'react';
import useDarkMode from 'use-dark-mode';
import { DndProvider } from 'react-dnd';

import { DamagesList } from 'src/contracts/damages-list/damages-list';
import { ThemeLayout } from 'src/theming';
import { HTML5Backend } from 'react-dnd-html5-backend';

export const DamagesPage = (): JSX.Element => {
    const darkMode = useDarkMode();
    return (
        <DndProvider backend={HTML5Backend}>
            <ThemeLayout darkMode={darkMode}>
                <div style={{ paddingBottom: 20, height: '100%' }}>
                    <DamagesList />
                </div>
            </ThemeLayout>
        </DndProvider>
    );
};
