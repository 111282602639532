import { useState, useEffect } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

export const useInsuranceCompanies = <T>(
    formatter: (item: string) => T
): string[] | T[] => {
    const httpClient = useHttpClient();
    const [companies, setCompanies] = useState<string[] | T[]>([]);

    const makeRequestToGetCompanies = async (): Promise<string[]> => {
        return httpClient.get(`insurance-companies`);
    };

    useEffect(() => {
        (async () => {
            const data = await makeRequestToGetCompanies();
            setCompanies(formatter ? data.map(formatter) : data || []);
        })();
    }, []);

    return companies;
};
