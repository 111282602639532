export const nonUsersDamageReportFormDefaultState = {
    licensePlate: '',
    driverInformationFirstName: '',
    driverInformationSurname: '',
    driverInformationEmail: '',
    driverInformationPhoneNumber: '',
    accidentHostFirstName: '',
    accidentHostSurname: '',
    accidentHostEmail: '',
    accidentHostPhoneNumber: '',
    damageDay: null,
    damageTime: null,
    accidentLocation: '',
    wasRecordedByThePolice: false,
    receivingPoliceStation: '',
    damageDescription: '',
    files: [],
};
