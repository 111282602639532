import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

import { Grid, Typography, Button } from '@material-ui/core';
import { FileObject } from 'material-ui-dropzone';

import { ThemeLoader } from 'src/theming';
import { useAllDivisions } from 'src/shared/hooks';

import {
    useGetCurrencyOptions,
    useInsuranceCompanies,
    useQuery,
    useDocumentFormats,
} from 'src/shared/hooks';
import { SelectItem, ThemeCircularProgress } from 'src/theming';
import { useGetGeneralContractDetails } from '../contracts-details-view/use-get-general-contract-details';

import { useContractsCreateContractsFormStyles } from './contracts-create-contract-form-styles';

import { contractsCreateContractGenerateForm } from './contracts-create-contract-generate-form';

import { contractsCreateContractFormSchema } from './contracts-create-contract-form-validation-schema';
import { useMutation } from './contracts-create-contract-use-mutation';

export type ContractCreateForm = {
    division: string;
    insuranceCompany: string;
    addInsuranceCompany: string;
    contractNumber: string;
    startOfContract: ParsableDate;
    contractExpiration: ParsableDate;
    netPremium: string;
    currency: string;
    risk: string;
    isNewInsuranceCompany: boolean;
    files: FileObject[];
    fileInfos?: { id: string; fileName: string }[];
};

export const ContractsCreateContractForm = (): JSX.Element => {
    const classes = useContractsCreateContractsFormStyles();
    const { t } = useTranslation(['common', 'create-contract', 'divisions']);
    const history = useHistory();

    const currencyItems = useGetCurrencyOptions();

    const contractId = useQuery().get('contractId');
    const customerId = useQuery().get('customerId');

    const [isMutationLoading, mutate] = useMutation(contractId, customerId);
    const [isReceivingLoading, contractData] = useGetGeneralContractDetails(contractId);

    const documentFormats = useDocumentFormats();

    const divisions = useAllDivisions<{ title: string; value: string }>();

    const formattedDivisions = useMemo(
        () =>
            divisions
                ?.map((item) => ({
                    title: t(`divisions:${item.name}`),
                    value: item.id,
                }))
                .sort((a, b) => a.title?.localeCompare(b.title)) || [],
        [divisions, t]
    );

    const { watch, setValue, trigger, formState, handleSubmit, reset, control } =
        useForm<ContractCreateForm>({
            defaultValues: {
                division: '',
                insuranceCompany: '',
                addInsuranceCompany: null,
                contractNumber: '',
                startOfContract: null,
                contractExpiration: null,
                netPremium: '',
                currency: 'EUR',
                isNewInsuranceCompany: false,
                risk: '',
                files: [],
            },
            resolver: yupResolver(contractsCreateContractFormSchema),
        });

    const { fields, remove } = useFieldArray({ control, name: 'files' });

    const { errors } = formState;

    const [
        division,
        insuranceCompany,
        addInsuranceCompany,
        contractNumber,
        startOfContract,
        contractExpiration,
        netPremium,
        currency,
        risk,
    ] = watch([
        'division',
        'insuranceCompany',
        'addInsuranceCompany',
        'contractNumber',
        'startOfContract',
        'contractExpiration',
        'netPremium',
        'currency',
        'risk',
    ]);

    const insuranceCompanies = useInsuranceCompanies<{ title: string; value: string }>(
        (item: string) => ({
            title: item,
            value: item,
        })
    );

    useEffect(() => {
        if (customerId && Array.isArray(divisions) && divisions.length > 0) {
            setValue('division', (divisions[0] as SelectItem)?.value);
        }
    }, [customerId, divisions]);

    useEffect(() => {
        if (contractData) {
            reset({
                division: contractData.division,
                insuranceCompany: contractData.insuranceCompanyCode,
                contractNumber: contractData.contractNumber,
                startOfContract: contractData.startDate,
                contractExpiration: contractData.endDate,
                netPremium: String(contractData.annualPremiumInclusiveTaxes),
                currency: contractData?.currency,
                risk: contractData?.risk,
            });
        }
    }, [contractData]);

    const handleAddFile = (newFiles: FileObject[]): void => {
        setValue('files', [...fields, ...newFiles]);
    };

    const handleDeleteFile = (index: number): void => {
        remove(index);
    };

    const handleChangeValue = (name, value): void => {
        if (name === 'addInsuranceCompany') {
            setValue('insuranceCompany', '');
        }
        if (name === 'insuranceCompany') {
            setValue('addInsuranceCompany', '');
        }
        setValue(name, value);
        trigger();
    };

    const handleChangeAddNewInsuranceCompany = (value: boolean): void => {
        setValue('isNewInsuranceCompany', value);
        trigger();
    };

    const formConfig = useMemo(
        () =>
            contractsCreateContractGenerateForm({
                divisionSelectionItems: formattedDivisions as SelectItem[],
                insuranceCompanies: insuranceCompanies as SelectItem[],
                division,
                insuranceCompany,
                addInsuranceCompany,
                contractNumber,
                startOfContract,
                contractExpiration,
                netPremium,
                currency,
                currencyItems,
                handleChangeAddNewInsuranceCompany,
                contractId,
                risk,
                fields,
            }),
        [
            formattedDivisions,
            division,
            insuranceCompany,
            addInsuranceCompany,
            contractNumber,
            startOfContract,
            contractExpiration,
            netPremium,
            currency,
            currencyItems,
            handleChangeAddNewInsuranceCompany,
            contractId,
            risk,
            fields,
        ]
    );

    return (
        <Grid container className={classes.pageContainer}>
            <Grid item md={12}>
                {customerId && (
                    <Typography variant='h5'>{t('create-contract:title')}</Typography>
                )}
                {contractId && (
                    <Typography variant='h5'>
                        {t('create-contract:editDummyContract')}
                    </Typography>
                )}
            </Grid>
            <Grid
                container
                item
                component='form'
                md={12}
                spacing={2}
                className={classes.fieldsContainer}
                onSubmit={handleSubmit(mutate)}
            >
                {isReceivingLoading && <ThemeLoader />}
                {!isReceivingLoading &&
                    formConfig.map(
                        ({
                            Item,
                            Component,
                            id,
                            md,
                            label,
                            name,
                            value,
                            items,
                            previewText,
                            onSwitch,
                            disabled,
                        }) => {
                            return (
                                <Item
                                    key={id}
                                    md={md}
                                    label={label}
                                    name={name}
                                    value={value}
                                    items={items}
                                    previewText={previewText}
                                    handleChangeValue={handleChangeValue}
                                    Component={Component}
                                    onSwitch={onSwitch}
                                    error={Boolean(errors[name])}
                                    disabled={disabled}
                                    onAdd={handleAddFile}
                                    onRemove={handleDeleteFile}
                                    documentFormats={documentFormats}
                                />
                            );
                        }
                    )}
                {!isReceivingLoading && (
                    <Grid item container md={12} className={classes.actionsContainer}>
                        <Button
                            type='submit'
                            disabled={!formState.isValid || isMutationLoading}
                            className={classes.saveButton}
                            startIcon={
                                <ThemeCircularProgress isLoading={isMutationLoading} />
                            }
                        >
                            {t('save')}
                        </Button>
                        <Button
                            disabled={isMutationLoading}
                            variant='outlined'
                            onClick={history.goBack}
                        >
                            {t('cancel')}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
