import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

interface UseDeleteDummyDocumentReturn {
    remove: (id: string) => Promise<void>;
    isLoading: boolean;
}

interface UseDeleteDummyDocumentParams {
    entity: string;
    entityId: string;
    onSuccess: () => void;
}

export const useDeleteDummyDocument = ({
    entity,
    entityId,
    onSuccess,
}: UseDeleteDummyDocumentParams): UseDeleteDummyDocumentReturn => {
    const { t } = useTranslation(['errors']);
    const httpClient = useHttpClient();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState<boolean>(false);

    const remove = async (id: string): Promise<void> => {
        try {
            setLoading(true);
            await httpClient.delete(`${entity}/${entityId}/attachments/${id}`);
            onSuccess();
        } catch {
            enqueueSnackbar(t('errors:unknownError'), {
                variant: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    return { isLoading, remove };
};
