import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React, { ReactNode } from 'react';

import { Draggable } from 'react-beautiful-dnd';

interface Props {
    draggableId: string;
    draggableIndex: number;
    children: JSX.Element | JSX.Element[];
    isDragAndDropLocked: boolean;
    hoverPosition?: number;
    startPosition?: number;
}

function getStyle(style, snapshot): CSSProperties {
    if (!snapshot.isDropAnimating) {
        return style;
    }
    const { curve, duration } = snapshot.dropAnimation;
    const translate = `translate(0px, 0px)`;
    return {
        ...style,
        transform: `${translate}`,
        transition: `all ${curve} ${duration}s`,
    };
}

export const ThemeDraggableElement = (props: Props): JSX.Element => {
    return (
        <Draggable
            isDragDisabled={props.isDragAndDropLocked}
            draggableId={props.draggableId}
            index={props.draggableIndex}
        >
            {(provided, snapshot) => {
                return (
                    <>
                        {React.Children.map<ReactNode, ReactNode>(
                            props.children,
                            (child) => {
                                if (React.isValidElement(child)) {
                                    return React.cloneElement(child, {
                                        isDragDisabled: props.isDragAndDropLocked,
                                        innerRef: provided.innerRef,
                                        leftSeparation:
                                            props.hoverPosition ===
                                                props.draggableIndex &&
                                            props.startPosition > props.draggableIndex,
                                        rightSeparation:
                                            props.hoverPosition ===
                                                props.draggableIndex &&
                                            props.startPosition < props.draggableIndex,
                                        ...provided.draggableProps,
                                        ...provided.dragHandleProps,
                                        style: getStyle(
                                            provided.draggableProps,
                                            snapshot
                                        ),
                                    });
                                }
                            }
                        )}
                    </>
                );
            }}
        </Draggable>
    );
};
