import { TFunction } from 'i18next';
import { CheckboxItem } from 'src/theming';

export enum DamageTypes {
    LIABILITY = 'liability',
    FULLY_COMPREHENSIVE = 'fullyComprehensive',
    PARTIAL_COVERAGE = 'partialCoverage',
}

export const damageReportFormGenerateDamageTypesOptions = (
    t: TFunction,
    chosenDamageTypes: string[]
): CheckboxItem[] => {
    return [
        {
            title: t('damages-report:generalFormBlock.damageTypes.liability'),
            value: DamageTypes.LIABILITY,
            checked: chosenDamageTypes.includes(DamageTypes.LIABILITY),
            data: undefined,
        },
        {
            title: t('damages-report:generalFormBlock.damageTypes.fullyComprehensive'),
            value: DamageTypes.FULLY_COMPREHENSIVE,
            checked: chosenDamageTypes.includes(DamageTypes.FULLY_COMPREHENSIVE),
            data: undefined,
        },
        {
            title: t('damages-report:generalFormBlock.damageTypes.partialCoverage'),
            value: DamageTypes.PARTIAL_COVERAGE,
            checked: chosenDamageTypes.includes(DamageTypes.PARTIAL_COVERAGE),
            data: undefined,
        },
    ];
};
