import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Grid, GridSize, IconButton, Typography } from '@material-ui/core';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import { SelectItem } from 'src/theming';

import { FormItemProps } from '../contracts-create-contract-generate-form';

import { useContractsCreateContractsFormStyles } from '../contracts-create-contract-form-styles';

interface AddFieldProps {
    name: string;
    value: string | number | ParsableDate;
    label: string;
    Component: (props: FormItemProps) => JSX.Element;
    handleChangeValue: (name, value) => void;
    md?: GridSize;
    items?: SelectItem[];
    previewText?: string;
    onSwitch?: (val: boolean) => void;
    error?: boolean;
}

export const AddField = ({
    md,
    Component,
    name,
    value,
    label,
    items,
    previewText,
    handleChangeValue,
    onSwitch,
    error,
}: AddFieldProps): JSX.Element => {
    const { t } = useTranslation(['create-contract']);
    const [isShown, setShown] = useState<boolean>(false);

    const classes = useContractsCreateContractsFormStyles();

    useEffect(() => {
        if (!value) {
            setShown(false);
            onSwitch(false);
        }
    }, [value]);

    const switchComponent = (): void => {
        if (isShown) {
            handleChangeValue(name, null);
        }
        setShown(!isShown);
        if (onSwitch) {
            onSwitch(!isShown);
        }
    };

    return (
        <Grid item container md={md} alignItems='flex-end'>
            {isShown && (
                <Grid item md={10}>
                    <Component
                        name={name}
                        value={value}
                        label={label}
                        handleChangeValue={handleChangeValue}
                        items={items}
                        error={error}
                    />
                </Grid>
            )}

            <IconButton
                onClick={switchComponent}
                className={clsx(
                    classes.actionButton,
                    !isShown ? classes.addButton : classes.deleteButton
                )}
                style={{ marginLeft: isShown ? 'auto' : 0 }}
            >
                {isShown ? <DeleteIcon /> : <AddIcon />}
            </IconButton>
            {!isShown && (
                <Typography variant='body1' className={classes.addFieldPreviewText}>
                    {t(previewText)}
                </Typography>
            )}
        </Grid>
    );
};
