import React, { BaseSyntheticEvent } from 'react';
import { FieldErrors } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { AnyAbility } from '@casl/ability';

import {
    FormControlClassKey,
    FormControlLabelClassKey,
    OutlinedInputClassKey,
    FormControl,
    FormControlLabel,
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';

import { SelectItem, ThemeSelectInput, ThemeOutlineInput } from 'src/theming';
import { AbilityActions } from 'src/roleAccesses';

interface Params {
    accessToEditForm: boolean;
    t: TFunction<string[]>;
    errors: FieldErrors;
    formControlClasses: Partial<ClassNameMap<FormControlClassKey>>;
    formControlLabelClasses: Partial<ClassNameMap<FormControlLabelClassKey>>;
    inputClasses: Partial<ClassNameMap<OutlinedInputClassKey>>;
    editMode: boolean;
    value: string[];
    items: SelectItem[];
    handleChangeRoles: (e: BaseSyntheticEvent) => void;
    ability: AnyAbility;
}

export const userEditFormRenderRoleFields = (params: Params): JSX.Element => {
    const {
        t,
        accessToEditForm,
        formControlClasses,
        formControlLabelClasses,
        inputClasses,
        errors,
        value,
        editMode,
        items,
        handleChangeRoles,
        ability,
    } = params;

    const isAccessToEditRolesField = !value.some((item) => {
        return ability.cannot(AbilityActions.change, item);
    });

    if (!accessToEditForm) {
        return (
            <ThemeOutlineInput
                disabled
                inputClasses={inputClasses}
                formControlClasses={formControlClasses}
                formControlLabelClasses={formControlLabelClasses}
                label={t('user-management:umSection.form.role')}
                name=''
                value={value
                    ?.map((item) => t(`user-management:roles.${item}`))
                    .join(', ')}
            />
        );
    }

    return (
        <FormControl
            fullWidth
            classes={formControlClasses}
            variant='outlined'
            error={Boolean(errors?.roles || errors?.userGroup)}
        >
            <FormControlLabel
                label={t('user-management:umSection.form.role')}
                labelPlacement='top'
                classes={formControlLabelClasses}
                control={
                    <ThemeSelectInput
                        multiple
                        editMode={isAccessToEditRolesField ? editMode : false}
                        name='roles'
                        value={value || []}
                        items={items}
                        onChange={handleChangeRoles}
                    />
                }
            />
        </FormControl>
    );
};
