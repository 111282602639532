import { useState, Dispatch, SetStateAction } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { FormState } from 'react-hook-form';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { NotificationsRowFormData } from './notifications-row';
import { notificationPutAdapter } from './notification-put-adapter';
import { useNotificationHandleErrors } from './notification-handle-errors';

interface UseNotificationMutateRowParams {
    id: string;
    trigger: string;
    formState: FormState<NotificationsRowFormData>;
    watchedFurtherReceivers: { name: string }[];
    watchedFallbackEmails: { name: string }[];
    watchedCustomerAdvisors: { name: string }[];
    watchedCustomers: { name: string }[];
    watchedAccountManager: string;
    watchedDisplayNotificationClerk: boolean;
    watchedDisplayNotificationCustomerAdviser: boolean;
    setTriggerCompare: Dispatch<SetStateAction<boolean>>;
    refetchNotifications: () => Promise<void>;
}

type UseNotificationMutateRowReturn = [boolean, (division?: string) => Promise<void>];

export const useNotificationMutateRow = ({
    trigger,
    id,
    formState,
    watchedFurtherReceivers,
    watchedFallbackEmails,
    watchedCustomerAdvisors,
    watchedCustomers,
    watchedAccountManager,
    watchedDisplayNotificationClerk,
    watchedDisplayNotificationCustomerAdviser,
    setTriggerCompare,
    refetchNotifications,
}: UseNotificationMutateRowParams): UseNotificationMutateRowReturn => {
    const httpClient = useHttpClient();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation(['user-management', 'errors']);

    const [isLoading, setLoading] = useState<boolean>(false);

    const handleErrors = useNotificationHandleErrors({ formState });

    const makeRequestToUpdateEmailSettings = async (division?: string): Promise<void> => {
        if (formState.isValid) {
            setLoading(true);
            const formattedData = notificationPutAdapter(
                id,
                trigger,
                division,
                watchedFurtherReceivers,
                watchedFallbackEmails,
                watchedCustomerAdvisors,
                watchedCustomers,
                watchedAccountManager,
                watchedDisplayNotificationClerk,
                watchedDisplayNotificationCustomerAdviser
            );
            try {
                if (id) {
                    await httpClient.put(`notification-triggers/${id}`, formattedData);
                    refetchNotifications();
                    enqueueSnackbar(
                        t(`user-management:notificationsSection.updatedSuccessfully`, {
                            trigger: t(`user-management:notificationsSection.${trigger}`),
                        }),
                        {
                            variant: 'success',
                        }
                    );
                } else if (!id && division) {
                    await httpClient.post('notification-triggers', formattedData);
                    refetchNotifications();
                }
            } catch (err) {
                enqueueSnackbar(t('errors:unknownError'), { variant: 'error' });
            } finally {
                setLoading(false);
                setTriggerCompare((value) => !value);
            }
        } else {
            handleErrors();
        }
    };

    return [isLoading, makeRequestToUpdateEmailSettings];
};
