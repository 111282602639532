import { makeStyles, Theme } from '@material-ui/core/styles';
import { THEME_MODES } from '../themes';
import {
    isHectorInstance,
    isCeatInstance,
    isSGIFFOXXKInstance,
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorDemoInstance,
} from 'src/environment';

export const useStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
        return {
            button: {
                width: '100%',
                color: theme.palette.secondary.contrastText,
                '& .MuiButton-endIcon': {
                    display: 'flex',
                    alignItems: 'center',
                },
            },
            buttonText: {
                fontSize: '1.4rem',
            },
            icon: {
                display: 'flex',
                alignItems: 'center',
                width: 16,
                height: 16,
                color: isDarkMode
                    ? theme.palette.secondary.contrastText
                    : theme.palette.info.main,
                opacity: 0.6,
            },
            checkedIcon: {
                width: 16,
                height: 16,
                color: isDarkMode
                    ? theme.palette.secondary.contrastText
                    : theme.palette.info.main,
                opacity: 1,
            },
            itemsContainer: {
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
            },
            filterIconButton: {
                marginRight: theme.spacing(1),
            },
            numberBadge: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 22,
                height: 22,
                borderRadius: '50%',
                backgroundColor: theme.palette.secondary.contrastText,
                fontSize: '1.4rem!important',
                color: theme.palette.secondary.main,
                marginRight: theme.spacing(1),
            },
        };
    }
    if (isSGIFFOXXKInstance) {
        return {
            button: {
                width: '100%',
                color: theme.palette.secondary.contrastText,
                '& .MuiButton-endIcon': {
                    display: 'flex',
                    alignItems: 'center',
                },
            },
            buttonText: {
                fontSize: '1.4rem',
            },
            icon: {
                display: 'flex',
                alignItems: 'center',
                width: 16,
                height: 16,
                color: isDarkMode
                    ? theme.palette.secondary.contrastText
                    : theme.palette.secondary.main,
                opacity: 0.6,
            },
            checkedIcon: {
                width: 16,
                height: 16,
                color: isDarkMode
                    ? theme.palette.secondary.contrastText
                    : theme.palette.secondary.main,
                opacity: 1,
            },
            itemsContainer: {
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
            },
            filterIconButton: {
                marginRight: theme.spacing(1),
            },
            numberBadge: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 22,
                height: 22,
                borderRadius: '50%',
                backgroundColor: theme.palette.secondary.contrastText,
                fontSize: '1.4rem!important',
                color: theme.palette.secondary.main,
                marginRight: theme.spacing(1),
            },
        };
    }
    return {
        button: {
            width: '100%',
            color: theme.palette.secondary.contrastText,
            '& .MuiButton-endIcon': {
                display: 'flex',
                alignItems: 'center',
            },
        },
        buttonText: {
            fontSize: '1.4rem',
        },
        icon: {
            display: 'flex',
            alignItems: 'center',
            width: 16,
            height: 16,
            color:
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.secondary.contrastText
                    : theme.palette.secondary.main,
            opacity: 0.6,
        },
        checkedIcon: {
            width: 16,
            height: 16,
            color: theme.palette.info.main,
            opacity: 1,
        },
        itemsContainer: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
        filterIconButton: {
            marginRight: theme.spacing(1),
        },
        numberBadge: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 22,
            height: 22,
            borderRadius: '50%',
            backgroundColor:
                isHectorInstance || isCeatInstance
                    ? theme.palette.primary.main
                    : theme.palette.info.main,
            fontSize: '1.4rem!important',
            color:
                isHectorInstance || isCeatInstance
                    ? theme.palette.primary.contrastText
                    : theme.palette.info.contrastText,
            marginRight: theme.spacing(1),
        },
    };
});

export const useFormControlLabelStyle = makeStyles(() => {
    return {
        label: {
            fontSize: '1.4rem',
        },
    };
});
