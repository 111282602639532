import React from 'react';
import { SearchOptions } from 'src/contracts/contracts-dashboard/generateContractTypeSearchOptions';
import { ContractsGlobalSearchList } from './contracts-global-search-list';
import { useLocation } from 'react-router-dom';
import { DamagesGlobalSearchList } from './damages-global-search-list';

interface LocationState {
    type: string;
    globalSearchTerm?: string;
}

export const GlobalSearchList = (): JSX.Element => {
    const location = useLocation<LocationState>();

    const { type, globalSearchTerm } = location.state;

    return (
        <>
            {type === SearchOptions.CONTRACT && (
                <ContractsGlobalSearchList globalSearchTerm={globalSearchTerm} />
            )}
            {type === SearchOptions.DAMAGE && (
                <DamagesGlobalSearchList globalSearchTerm={globalSearchTerm} />
            )}
        </>
    );
};
