import { makeStyles, Theme } from '@material-ui/core';

export const useMaschineStyles = makeStyles((theme: Theme) => {
    return {
        table: {
            width: '100%',
            marginBottom: theme.spacing(4),
            textAlign: 'left',
        },
        tableCell: {
            color: theme.palette.primary.contrastText,
        },
        tableHeader: {
            color: theme.palette.primary.contrastText,
            fontWeight: theme.typography.fontWeightBold,
        },
    };
});
