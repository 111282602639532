import React, { useMemo, useState } from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { ThemeLoader } from 'src/theming';
import { useDocumentFormats } from 'src/shared/hooks';
import { APP_ROUTES } from 'src/routing';
import { useGetCustomer } from '../customer-requests';
import { CustomerChangeNotification } from '../customer-change-notification/customer-change-notification';
import { useStyle } from './customer-details-view-styles';
import { CustomerDetailsViewDTO } from './customer-details-view-dto';
import { CustomerDetailsViewData } from './customer-details-view-data';
import { CustomerDetailsViewDocuments } from './customer-details-view-documents';
import { INTERNAL_ROLES } from 'src/shared/constants';
import { useAuth } from 'src/user-management/context-auth';

export const CustomerDetailsView = (): JSX.Element => {
    const classes = useStyle();
    const history = useHistory();
    const { t } = useTranslation(['customer-details-view']);

    const [isOpenChangeNotification, setOpenChangeNotification] = useState(false);

    const { id } = useParams<{ id: string }>();

    const documentFormats = useDocumentFormats();
    const [isLoading, data] = useGetCustomer<CustomerDetailsViewDTO>(id);

    const goTo = (pathname: string): void => {
        history.push(pathname);
    };

    const onCloseChangeNotification = (): void => {
        setOpenChangeNotification(false);
    };

    const openChangeNotification = (): void => {
        setOpenChangeNotification(true);
    };

    const {
        userData: {
            userInfo: { roles: userRoles },
        },
    } = useAuth();

    const hideActions = useMemo(() => {
        return userRoles.includes(INTERNAL_ROLES.ADMIN_DOMAIN) && userRoles.length === 1;
    }, [userRoles]);

    return (
        <>
            {isLoading && <ThemeLoader />}
            {!isLoading && (
                <Grid container className={classes.customerDetailsContainer}>
                    <Grid item container className={classes.header}>
                        <IconButton
                            size='small'
                            className={classes.backButton}
                            onClick={() => goTo(APP_ROUTES.CUSTOMERS)}
                        >
                            <KeyboardBackspaceIcon />
                        </IconButton>
                        <Typography variant='h5' className={classes.title}>
                            {t('customer-details-view:backButton')} - {data?.name}
                            {data?.name2} {data?.name3}
                        </Typography>
                    </Grid>
                    <Grid container item spacing={2} className={classes.cardsContainer}>
                        <Grid item className={classes.customerDataContainer}>
                            <CustomerDetailsViewData
                                data={data}
                                openChangeNotification={openChangeNotification}
                            />
                        </Grid>
                        <Grid item className={classes.documentsContainer}>
                            <CustomerDetailsViewDocuments
                                documentFormats={documentFormats}
                                hideUploadDocument={hideActions}
                                customerId={data?.amsIdNr}
                            />
                        </Grid>
                    </Grid>
                    <CustomerChangeNotification
                        onClose={onCloseChangeNotification}
                        isOpen={isOpenChangeNotification}
                        customerConnection={data?.matchCode}
                        client={data?.name}
                        address={`${data?.street} ${data?.postcode} ${data?.city}`}
                        documentFormats={documentFormats}
                    />
                </Grid>
            )}
        </>
    );
};
