import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { CheckboxItem } from 'src/theming';

import { useCardStyle } from './user-editing-form-styles';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
} from '@material-ui/core';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export interface ClientFormInternalCard {
    id: string;
    clients: CheckboxItem[];
    clientConnections: CheckboxItem[];
}

interface UserEditingFormInternalCardProps {
    internalClientCard: ClientFormInternalCard;
    readonly?: boolean;
}

export const UserEditingFormInternalCard = (
    props: UserEditingFormInternalCardProps
): JSX.Element => {
    const { internalClientCard, readonly } = props;
    const classes = useCardStyle();

    const { t } = useTranslation(['common', 'user-management']);

    return (
        <Grid container className={classes.cardContainer}>
            <FormControl component='fieldset' className={classes.formControl}>
                <Grid container>
                    <Grid item md={4}>
                        <FormLabel>
                            {t('user-management:umSection.form.clientConnection')}
                        </FormLabel>
                    </Grid>
                    <Grid item md={8}>
                        <FormLabel>
                            {t('user-management:umSection.form.client')}
                        </FormLabel>
                    </Grid>
                </Grid>
                <FormGroup>
                    {internalClientCard.clientConnections
                        .sort((a, b) => a.title.localeCompare(b.title))
                        .map((cc) => (
                            <Grid container key={cc.value} className={classes.ccGroup}>
                                <Grid item md={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={readonly}
                                                checked={cc.checked}
                                                name={cc.value}
                                                icon={
                                                    <CheckBoxOutlineBlankIcon
                                                        className={classes.icon}
                                                    />
                                                }
                                                checkedIcon={
                                                    <CheckBoxIcon
                                                        className={clsx(
                                                            classes.icon,
                                                            classes.checkedIcon
                                                        )}
                                                    />
                                                }
                                            />
                                        }
                                        label={cc.title}
                                    />
                                </Grid>
                                <Grid item md={8}>
                                    {internalClientCard.clients
                                        .sort((a, b) => a.title.localeCompare(b.title))
                                        .map((client) => {
                                            if (
                                                cc.data.customers.some(
                                                    (item) =>
                                                        item.customer ===
                                                        client.data.customer
                                                )
                                            ) {
                                                return (
                                                    <>
                                                        <FormControlLabel
                                                            key={client.value}
                                                            control={
                                                                <Checkbox
                                                                    disabled={readonly}
                                                                    checked={
                                                                        client.checked
                                                                    }
                                                                    name={client.value}
                                                                    icon={
                                                                        <CheckBoxOutlineBlankIcon
                                                                            className={
                                                                                classes.icon
                                                                            }
                                                                        />
                                                                    }
                                                                    checkedIcon={
                                                                        <CheckBoxIcon
                                                                            className={clsx(
                                                                                classes.icon,
                                                                                classes.checkedIcon
                                                                            )}
                                                                        />
                                                                    }
                                                                />
                                                            }
                                                            label={client.title}
                                                            style={{ width: '100%' }}
                                                        />
                                                    </>
                                                );
                                            }
                                            return null;
                                        })}
                                </Grid>
                            </Grid>
                        ))}
                </FormGroup>
            </FormControl>
            {/* <FormControl component='fieldset' className={classes.formControl}>
                <FormLabel>{t('user-management:umSection.form.client')}</FormLabel>
                <FormGroup>
                    {internalClientCard.clients.map((client) => (
                        <FormControlLabel
                            key={client.value}
                            control={
                                <Checkbox
                                    disabled={readonly}
                                    checked={client.checked}
                                    name={client.value}
                                    icon={
                                        <CheckBoxOutlineBlankIcon
                                            className={classes.icon}
                                        />
                                    }
                                    checkedIcon={
                                        <CheckBoxIcon
                                            className={clsx(
                                                classes.icon,
                                                classes.checkedIcon
                                            )}
                                        />
                                    }
                                />
                            }
                            label={client.title}
                        />
                    ))}
                </FormGroup>
            </FormControl> */}
        </Grid>
    );
};
