import { Dispatch, SetStateAction, useCallback } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { ResponseError } from 'src/shared/types';
import { TokenResponse, UserSettingsDto } from '../context-auth-types';

interface useCheckAuthProps {
    setAuth: Dispatch<SetStateAction<boolean>>;
    setUserData: (data: UserSettingsDto) => void;
}

interface useCheckAuthReturn {
    checkAuth: () => Promise<void>;
}

export const useCheckAuth = ({
    setAuth,
    setUserData,
}: useCheckAuthProps): useCheckAuthReturn => {
    const httpClient = useHttpClient();
    const checkAuth = useCallback(async (): Promise<void> => {
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
            try {
                const data: TokenResponse = await httpClient.post(`users/refresh-token`, {
                    refreshToken,
                });
                if (data) {
                    localStorage.setItem('accessToken', data.accessToken);
                    localStorage.setItem('refreshToken', data.refreshToken);
                    setAuth(true);
                }
            } catch (response) {
                const refreshTokenErrors: string[] = (response as ResponseError)?.error
                    ?.response?.data?.errors?.refreshToken;
                if (
                    !refreshTokenErrors ||
                    (Array.isArray(refreshTokenErrors) &&
                        refreshTokenErrors?.length > 0 &&
                        refreshTokenErrors?.some(
                            (item) =>
                                item === "Refresh token doesn't match the saved token" ||
                                item ===
                                    'Refresh token has expired, user needs to relogin' ||
                                item === 'Refresh token has been revoked.' ||
                                item === "Refresh token doesn't exist."
                        ))
                ) {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('refreshToken');
                    setAuth(false);
                    setUserData(null);
                }
            }
        } else {
            setAuth(false);
            setUserData(null);
        }
    }, [httpClient, setAuth, setUserData]);

    return { checkAuth };
};
