import React from 'react';
import { useTheme } from '@material-ui/core';

import {
    isSGIFFOXXKInstance,
    isAonMotorInstance,
    isAonDigitalInstance,
    isHectorDemoInstance,
} from 'src/environment';

interface CustomerUserIconProps {
    isActive: boolean;
}

export const CustomerUserIcon = (props: CustomerUserIconProps): JSX.Element => {
    const theme = useTheme();
    const { isActive } = props;

    const currentActiveColor =
        isSGIFFOXXKInstance ||
        isAonMotorInstance ||
        isAonDigitalInstance ||
        isHectorDemoInstance
            ? theme.palette.secondary.contrastText
            : theme.palette.info.main;

    return (
        <svg width='20' height='19' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M15.5 17.634L12.719 19l.53-2.894L11 14.056l3.11-.423L15.5 11l1.391 2.633 3.109.423-2.25 2.05.53 2.894-2.78-1.366zM10 11.252V19H0a8 8 0 013.1-6.326c.966-.748 3.4-1.917 6.9-1.422z'
                fill={isActive ? currentActiveColor : '#C2C2C2'}
            />
            <circle
                cx='9.5'
                cy='4.5'
                r='4.5'
                fill={isActive ? currentActiveColor : '#C2C2C2'}
            />
        </svg>
    );
};
