import { AbilityActions, AbilitySubjects } from '../constants';
import { customerAdvisorRoleAccessGenerator } from './customerAdvisorRoleAccessGenerator';
import { clerkPlusRoleAccessGenerator } from './clerkPlusRoleAccessGenerator';

// A Clerk plus can everything what can a simple clerk with additional abilities

type abilityBuilderFn = (
    actions: string | string[],
    subjects: string | string[],
    params?: any
) => void;

export function caBrokerRoleAccessGenerator(
    can: abilityBuilderFn,
    cannot: abilityBuilderFn,
    userId: string,
    isCustomerAdviserBrokerFromAms?: boolean
): void {
    clerkPlusRoleAccessGenerator(can);
    customerAdvisorRoleAccessGenerator(can, cannot);
    can(
        [AbilityActions.create, AbilityActions.edit, AbilityActions.edit],
        [AbilitySubjects.caBroker, AbilitySubjects.internalRoleGroup]
    );
    if (isCustomerAdviserBrokerFromAms) {
        cannot([AbilityActions.edit], AbilitySubjects.caBroker, {
            id: {
                $eq: userId,
            },
        });
    }
}
