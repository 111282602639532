import React, { BaseSyntheticEvent, ChangeEvent, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MenuItem, Select, Checkbox, ListItemText } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { useSelectStyles, useStyle, useMenuClasses } from './theme-select-input-styles';

export interface SelectItem {
    title: string;
    value: string;
    disabled?: boolean;
    titleAsKeyForTranslation?: boolean;
}

export interface ThemeSelectInputProps {
    items: SelectItem[];
    value: string | string[];
    name: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    editMode?: boolean;
    multiple?: boolean;
    callbackAfterClose?: (e?: BaseSyntheticEvent) => void;
    namespaces?: string[];
    alphabeticallySort?: boolean;
    injectedMenuClasses?: ClassNameMap<string> | null;
    injectedSelectClasses?: ClassNameMap<string> | null;
}

export const ThemeSelectInput = (props: ThemeSelectInputProps): JSX.Element => {
    const {
        items,
        value,
        name,
        editMode,
        onChange,
        multiple,
        callbackAfterClose,
        namespaces,
        alphabeticallySort,
        injectedMenuClasses,
        injectedSelectClasses,
    } = props;
    const selectClasses = useSelectStyles();
    const menuClasses = useMenuClasses();
    const classes = useStyle();

    const { t } = useTranslation(namespaces);

    const [val, setVal] = useState(value);
    const [open, setOpen] = useState<boolean>(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        onChange(e);
        if (multiple) {
            setVal(e.target.value);
        }
    };

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
        if (typeof callbackAfterClose === 'function') {
            callbackAfterClose();
        }
    };

    useEffect(() => {
        if (multiple) {
            setVal(value);
        }
    }, [value, items, multiple]);

    return (
        <Select
            fullWidth
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            multiple={multiple}
            disabled={!editMode}
            IconComponent={editMode ? ExpandMoreIcon : () => null}
            renderValue={(selected: string[]) => {
                return selected && multiple
                    ? selected
                          .map((item) => items.find((i) => i.value === item)?.title)
                          .filter((item) => Boolean(item))
                          .join(', ')
                    : items.find((i) => i.value === value)?.title;
            }}
            classes={injectedSelectClasses ? injectedSelectClasses : selectClasses}
            value={multiple ? val : value}
            onChange={handleChange}
            name={name}
            MenuProps={{
                disableScrollLock: true,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                getContentAnchorEl: null,
                classes: injectedMenuClasses ? injectedMenuClasses : menuClasses,
            }}
        >
            {(!alphabeticallySort
                ? items
                : items?.sort((a, b) =>
                      (a.titleAsKeyForTranslation ? t(a.title) : a.title).localeCompare(
                          b.titleAsKeyForTranslation ? t(b.title) : b.title
                      )
                  )
            ).map((item: SelectItem) => {
                return (
                    <MenuItem
                        disabled={item.disabled}
                        key={item.value}
                        value={item.value}
                    >
                        {multiple && (
                            <Checkbox
                                icon={
                                    <CheckBoxOutlineBlankIcon className={classes.icon} />
                                }
                                checkedIcon={
                                    <CheckBoxIcon
                                        className={clsx(
                                            classes.icon,
                                            classes.checkedIcon
                                        )}
                                    />
                                }
                                checked={val.indexOf(item.value) > -1}
                            />
                        )}
                        <ListItemText>
                            {item.titleAsKeyForTranslation ? t(item.title) : item.title}
                        </ListItemText>
                    </MenuItem>
                );
            })}
        </Select>
    );
};
