import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';

import { ThemeModalWindow, ThemeCircularProgress } from 'src/theming';

import { Button, Grid, Typography } from '@material-ui/core';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { useStyle } from './user-management-delete-external-link-modal-window-style';

interface UserManagementDeleteExternalLinkModalWindowProps {
    isOpen: boolean;
    externalLinkId: string;
    handleClose: () => void;
    refetch: () => void;
}

export const UserManagementDeleteExternalLinkModalWindow = (
    props: UserManagementDeleteExternalLinkModalWindowProps
): JSX.Element => {
    const { isOpen, handleClose, externalLinkId, refetch } = props;
    const classes = useStyle();
    const httpClient = useHttpClient();
    const { enqueueSnackbar } = useSnackbar();

    const [isDeleteLoading, setDeleteLoading] = useState(false);

    const { t } = useTranslation(['common', 'user-management', 'error']);

    const approve = (): void => {
        setDeleteLoading(true);
        httpClient
            .delete(`external-links/${externalLinkId}`)
            .then(() => {
                handleClose();
                refetch();
                enqueueSnackbar(
                    t(
                        `user-management:masterData.deleteLinkModalWindow.deletedSuccessfully`
                    ),
                    {
                        variant: 'success',
                    }
                );
            })
            .catch(() => {
                enqueueSnackbar(t('errors:unknownError'), {
                    variant: 'error',
                });
            })
            .finally(() => {
                setDeleteLoading(false);
            });
    };

    return (
        <ThemeModalWindow isOpen={isOpen} onClose={handleClose}>
            <Typography variant='h5' className={classes.modalTitle}>
                {t('user-management:masterData.deleteLinkModalWindow.title')}
            </Typography>
            <Typography variant='body1' className={classes.modalText}>
                {t('user-management:masterData.deleteLinkModalWindow.modalWindowText')}
            </Typography>
            <Grid container className={classes.modalActionsContainer}>
                <Button
                    disableElevation
                    disabled={isDeleteLoading}
                    variant='contained'
                    className={clsx(classes.modalButton, classes.saveButton)}
                    onClick={approve}
                    startIcon={<ThemeCircularProgress isLoading={isDeleteLoading} />}
                >
                    {t('common:confirm')}
                </Button>
                <Button
                    disabled={isDeleteLoading}
                    variant='outlined'
                    className={clsx(classes.modalButton, classes.cancelButton)}
                    onClick={handleClose}
                >
                    {t('common:cancel')}
                </Button>
            </Grid>
        </ThemeModalWindow>
    );
};
