import { TFunction } from 'i18next';
import { CheckboxItem } from 'src/theming';

export enum Elationship {
    TERMS_SERVICE = 'termsService',
    SPECIAL_DELIVERY_CONDITIONS = 'specialDeliveryConditions',
    SPECIAL_LIABILITY_AGREEMENTS = 'specialLiabilityAgreements',
    SPECIAL_WARRANTY_AGREEMENTS = 'specialWarrantyAgreements',
    ASSURANCE_PROPERTIES = 'assuranceProperties',
    MANUFACTURER_INSTRUCTIONS_CLIENT = 'manufacturerInstructionsClient',
    DEVELOPMENT_ORDER = 'developmentOrder',
    OTHER = 'other',
}

export const damageReportFormElationshipOptions = (
    t: TFunction,
    chosenDocuments: string[]
): CheckboxItem[] => {
    return [
        {
            title: t(
                'damages-report:contractualRelationshipBetweenYouAndTheClaimant.termsService'
            ),
            value: Elationship.TERMS_SERVICE,
            checked: chosenDocuments.includes(Elationship.TERMS_SERVICE),
            data: undefined,
        },
        {
            title: t(
                'damages-report:contractualRelationshipBetweenYouAndTheClaimant.specialDeliveryConditions'
            ),
            value: Elationship.SPECIAL_DELIVERY_CONDITIONS,
            checked: chosenDocuments.includes(Elationship.SPECIAL_DELIVERY_CONDITIONS),
            data: undefined,
        },
        {
            title: t(
                'damages-report:contractualRelationshipBetweenYouAndTheClaimant.specialLiabilityAgreements'
            ),
            value: Elationship.SPECIAL_LIABILITY_AGREEMENTS,
            checked: chosenDocuments.includes(Elationship.SPECIAL_LIABILITY_AGREEMENTS),
            data: undefined,
        },
        {
            title: t(
                'damages-report:contractualRelationshipBetweenYouAndTheClaimant.specialWarrantyAgreements'
            ),
            value: Elationship.SPECIAL_WARRANTY_AGREEMENTS,
            checked: chosenDocuments.includes(Elationship.SPECIAL_WARRANTY_AGREEMENTS),
            data: undefined,
        },
        {
            title: t(
                'damages-report:contractualRelationshipBetweenYouAndTheClaimant.assuranceProperties'
            ),
            value: Elationship.ASSURANCE_PROPERTIES,
            checked: chosenDocuments.includes(Elationship.ASSURANCE_PROPERTIES),
            data: undefined,
        },
        {
            title: t(
                'damages-report:contractualRelationshipBetweenYouAndTheClaimant.manufacturerInstructionsClient'
            ),
            value: Elationship.MANUFACTURER_INSTRUCTIONS_CLIENT,
            checked: chosenDocuments.includes(
                Elationship.MANUFACTURER_INSTRUCTIONS_CLIENT
            ),
            data: undefined,
        },
        {
            title: t(
                'damages-report:contractualRelationshipBetweenYouAndTheClaimant.developmentOrder'
            ),
            value: Elationship.DEVELOPMENT_ORDER,
            checked: chosenDocuments.includes(Elationship.DEVELOPMENT_ORDER),
            data: undefined,
        },
        {
            title: t(
                'damages-report:contractualRelationshipBetweenYouAndTheClaimant.other'
            ),
            value: Elationship.OTHER,
            checked: chosenDocuments.includes(Elationship.OTHER),
            data: undefined,
        },
    ];
};
