import React, {
    BaseSyntheticEvent,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSnackbar } from 'notistack';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import uniqid from 'uniqid';
import clsx from 'clsx';
import { yupResolver } from '@hookform/resolvers/yup';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { APP_ROUTES } from 'src/routing';
import { useGlobalFilter } from 'src/shared/contexts';
import { useQuery, useValidateContractByDate } from 'src/shared/hooks';
import { DamageReportGroups } from 'src/shared/constants';

import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { FileObject } from 'material-ui-dropzone';

import { useStyle } from '../../damage-report-form-styles/damage-report-form-styles';
import { useStyle as useComponentsStyle } from '../../damage-report-form-components/damage-report-form-components-styled';

import { DamageReportFormDeleteConfirmationModalWindow } from '../../damage-report-form-components/damage-report-form-delete-confirmation';
import { DamageReportFormView } from '../damage-report-form-view';
import { damageReportFormGenerateAONTechnicalProjectPhaseOptions } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-generate-AON-technical-project-phase-selection-options';
import { DamageReportFormGetRecordDTO } from '../../damage-report-form-utils/damage-report-form-types/damage-report-form-get-record-dto';

import { generateDisabledInputs } from '../../damage-report-form-config/damage-report-form-AON-komposit-config/damage-report-form-config-AON-Komposit-liability';
import {
    DamageReportFormAONKompositTechnicalTypes,
    DamageReportFormAONKompositTechnicalMutation,
} from '../../damage-report-form-utils/damage-report-form-types/damage-report-form-AON-Komposit-types/damage-report-form-AON-Komposit-technical-types';
import { damageReportFormAONKompositTechnicalDefaultState } from '../../damage-report-form-utils/damage-report-form-default-states/damage-report-form-AON-Komposit-default-state/damage-report-form-AON-default-state-technical';
import { DamageReportFormConfigAONKompositTechnical } from '../../damage-report-form-config/damage-report-form-AON-komposit-config/damage-report-form-config-AON-Komposit-technical';
import { damageReportFormGenerateAONTechnicalThingAffectedOptions } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-generate-AON-tecnical-thing-affected-options';
import { damageReportFormGeneralAONKompositSchema } from '../../damage-report-form-utils/damage-report-form-schemas/damage-report-form-schemas-AON-Komposit/damage-report-form-general-AON-Komposit-schema';
import { damageReportFormTechnicalMutateAdapter } from '../../damage-report-form-utils/damage-report-form-request-body-adapters/damage-report-form-adapters-AON-Komposit/damage-report-form-technical-mutate-adapter';
import { damageReportFormTechnicalGetReportRecordAdapter } from '../../damage-report-form-utils/damage-report-form-request-body-adapters/damage-report-form-adapters-AON-Komposit/damage-report-form-technical-get-adapter';
import { useDamageReportFormSaveReport } from '../../damage-report-form-hooks';

interface DamageReportFormONKompositTechnicalProps {
    setDivision: (value: string) => void;
    hideButtons: boolean;
    data: DamageReportFormGetRecordDTO;
    divisionState?: string;
}

export const DamageReportFormTechnical = memo(
    (props: DamageReportFormONKompositTechnicalProps) => {
        const { setDivision, hideButtons, data, divisionState } = props;

        const { t } = useTranslation(['common', 'damages-report', 'errors']);
        const contractId = useQuery().get('contractId');

        const {
            watch,
            setValue,
            control,
            handleSubmit,
            formState,
            reset,
            trigger,
            getValues,
        } = useForm<DamageReportFormAONKompositTechnicalTypes>({
            mode: 'onChange',
            reValidateMode: 'onChange',
            resolver: yupResolver(damageReportFormGeneralAONKompositSchema),
            defaultValues: {
                ...damageReportFormAONKompositTechnicalDefaultState(contractId),
                division: divisionState,
            },
        });

        const { changeDivision } = useGlobalFilter();

        const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);

        const { id } = useParams<{ id?: string }>();
        const classes = useStyle();
        const componentClasses = useComponentsStyle();
        const httpClient = useHttpClient();
        const router = useHistory();

        const [isSendingToAmsLoading, setSendingToAmsLoading] = useState(false);
        const { enqueueSnackbar } = useSnackbar();

        const { fields: itemsAffectedByTheDamageFields, prepend: prependRow } =
            useFieldArray({
                control,
                name: 'itemsAffectedByTheDamage',
            });

        const { fields: files, remove } = useFieldArray({ control, name: 'files' });

        const { fields: fileInfos } = useFieldArray({
            control,
            name: 'fileInfos',
        });

        const [
            division,
            dayOfDamage,
            timeOfDamage,
            risk,
            contractNumber,
            policyHolder,
            insuranceCompany,
            placeOfDamage,
            itemsAffectedByTheDamage,
            damageAndCause,
            estimatedLossAmountInEUR,
            shouldDamagedItemsBeOperatedProvisionally,
            namely,
            isARepairPossibleAndPlanned,
            through,
            whoCanAnswerQuestionsAboutTheTechnicalDetails,
            isWitnesses,
            witnessesSurname,
            witnessesRoad,
            witnessesPostcode,
            witnessesPlace,
            witnessesPhoneNumber,
            witnessesEmail,
            damageInformationAreDamagedItemsOtherwiseInsured,
            indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance,
            warrantyPeriodForTheDamagedObjectExpired,
            namelyBy,
            lossOfInsuredItemsDueToTheftBurglaryOrArson,
            isRecordedByThePolice,
            policeStation,
            fileNumber,
            interruptionInOperationOrDelayIncommissioning,
            interruptionInOperationNamely,
            interruptionInOperationStandsSince,
            expectedDamageInEurosPerDayOrWeekOrMonth,
            mitigationMeasuresHaveAlreadyBeenTaken,
            additionalCostsAreIncurredForBridgingMeasures,
            whoCreatedTheServicesAffectedByTheDamage,
            projectPhase,
            thingAffectedByTheDamage,
            thingAffectedByTheDamageSince,
            thingAffectedAcceptedSince,
            thingAffectedAcceptedBy,
            insuredConstructionOrAssemblyProject,
            insuredConstructionOrAssemblyProjectSince,
            insuredConstructionOrAssemblyProjectAcceptedSince,
            insuredConstructionOrAssemblyProjectAcceptedBy,
            caseOfTheftForConstructionInsurance,
        ] = watch([
            'division',
            'dayOfDamage',
            'timeOfDamage',
            'risk',
            'contractNumber',
            'policyHolder',
            'insuranceCompany',
            'placeOfDamage',
            'itemsAffectedByTheDamage',
            'damageAndCause',
            'estimatedLossAmountInEUR',
            'shouldDamagedItemsBeOperatedProvisionally',
            'namely',
            'isARepairPossibleAndPlanned',
            'through',
            'whoCanAnswerQuestionsAboutTheTechnicalDetails',
            'isWitnesses',
            'witnessesSurname',
            'witnessesRoad',
            'witnessesPostcode',
            'witnessesPlace',
            'witnessesPhoneNumber',
            'witnessesEmail',
            'damageInformationAreDamagedItemsOtherwiseInsured',
            'indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance',
            'warrantyPeriodForTheDamagedObjectExpired',
            'namelyBy',
            'lossOfInsuredItemsDueToTheftBurglaryOrArson',
            'isRecordedByThePolice',
            'policeStation',
            'fileNumber',
            'interruptionInOperationOrDelayIncommissioning',
            'interruptionInOperationNamely',
            'interruptionInOperationStandsSince',
            'expectedDamageInEurosPerDayOrWeekOrMonth',
            'mitigationMeasuresHaveAlreadyBeenTaken',
            'additionalCostsAreIncurredForBridgingMeasures',
            'whoCreatedTheServicesAffectedByTheDamage',
            'projectPhase',
            'thingAffectedByTheDamage',
            'thingAffectedByTheDamageSince',
            'thingAffectedAcceptedSince',
            'thingAffectedAcceptedBy',
            'insuredConstructionOrAssemblyProject',
            'insuredConstructionOrAssemblyProjectSince',
            'insuredConstructionOrAssemblyProjectAcceptedSince',
            'insuredConstructionOrAssemblyProjectAcceptedBy',
            'caseOfTheftForConstructionInsurance',
        ]);

        const {
            isLoading: isSaveLoading,
            setLoading: setSaveLoading,
            saveReport,
            handleSaveReport,
        } = useDamageReportFormSaveReport<
            DamageReportFormAONKompositTechnicalTypes,
            DamageReportFormAONKompositTechnicalMutation
        >({
            id,
            pathname: 'technical',
            data: { ...getValues(), files },
            division: divisionState,
            adapter: damageReportFormTechnicalMutateAdapter,
        });

        const isContractValidByDate = useValidateContractByDate(dayOfDamage, contractId);

        useEffect(() => {
            trigger();
        }, []);

        useEffect(() => {
            if (data) {
                reset(
                    damageReportFormTechnicalGetReportRecordAdapter(data, divisionState)
                );
                trigger();
            }
        }, [data]);

        useEffect(() => {
            setDivision(division);
        }, [division]);

        const handleChangeValue = useCallback(
            (e: BaseSyntheticEvent): void => {
                setValue(e.target.name, e.target.value);
                trigger();
            },
            [setValue]
        );

        const onDeleteFile = (id: string): void => {
            const newFiles = fileInfos.filter((file) => {
                return file.id !== id;
            });
            setValue('fileInfos', newFiles);
        };

        const makeRequestForSavingDataToAms = async (
            data: DamageReportFormAONKompositTechnicalTypes
        ): Promise<void | string> => {
            setSendingToAmsLoading(true);
            try {
                const { id: newId } = await saveReport(data);
                return httpClient
                    .post<Promise<void | string>>(
                        `damage-reports/${newId || id}/send-to-ams`
                    )
                    .catch(() => {
                        return Promise.reject(newId);
                    });
            } catch {
                return Promise.reject();
            }
        };

        const saveReportToAms = (): void => {
            const data: DamageReportFormAONKompositTechnicalTypes = getValues();
            makeRequestForSavingDataToAms(data)
                .then(() => {
                    enqueueSnackbar(t(`damages-report:sentSuccessfully`), {
                        variant: 'success',
                    });
                    changeDivision(divisionState);
                    router.push(`${APP_ROUTES.DAMAGES}?filterValue=created`);
                })
                .catch((newId: string) => {
                    if (newId) {
                        router.push(`${APP_ROUTES.DAMAGE_REPORT}/${newId}`);
                    }
                    enqueueSnackbar(t(`errors:unknownError`), {
                        variant: 'error',
                    });
                })
                .finally(() => {
                    setSendingToAmsLoading(false);
                    setSaveLoading(false);
                });
        };

        const handleAddRow = useCallback((): void => {
            const newRow = {
                id: uniqid(),
                numberAndTypeOfThings: '',
                machineDirectoryOrDeclaration: null,
                extentOfDamage: null,
            };
            prependRow(newRow);
        }, [itemsAffectedByTheDamageFields?.length]);

        const handleDeleteRow = useCallback(
            (id: string): void => {
                const filteredRows = itemsAffectedByTheDamageFields?.filter(
                    (item) => item.id !== id
                );
                setValue('itemsAffectedByTheDamage', filteredRows);
            },
            [itemsAffectedByTheDamageFields]
        );

        const handleCancel = (): void => {
            history.back();
        };

        const handleReadOnlyFields = (data: {
            licenseNumber: string;
            contractNumber: string;
            policyHolder: string;
            insuranceCompany: string;
            contractId: string;
        }): void => {
            setValue('risk', data.licenseNumber);
            setValue('contractNumber', data.contractNumber);
            setValue('policyHolder', data.policyHolder);
            setValue('insuranceCompany', data.insuranceCompany);
            setValue('contractId', data.contractId);
            trigger();
        };

        const handleChangeDate = useCallback(
            (name, value: ParsableDate): void => {
                setValue(name, value);
                trigger();
            },
            [setValue]
        );

        const handleAddFile = useCallback(
            (newFiles: FileObject[]): void => {
                setValue('files', [...files, ...newFiles]);
            },
            [setValue, files]
        );

        const handleDeleteFile = useCallback(
            (index: number): void => {
                remove(index);
            },
            [setValue]
        );

        const onSubmit = (): void => {
            saveReportToAms();
        };

        const handleChangeSwitcher = useCallback(
            (name, value: boolean): void => {
                setValue(name, value);
                trigger();
            },

            [setValue]
        );

        const handleOnChangeRow = useCallback(
            (id, row) => {
                const newRows = itemsAffectedByTheDamageFields?.map((item) => {
                    if (item.id === id) {
                        return { ...row };
                    }
                    return item;
                });
                setValue('itemsAffectedByTheDamage', newRows);
            },
            [itemsAffectedByTheDamageFields]
        );

        const isDivisionFromDataIsFromState = data?.division === division;

        const headerConfig = useMemo(
            () =>
                generateDisabledInputs({
                    licenseNumber: isDivisionFromDataIsFromState || !id ? risk : '',
                    contractNumber:
                        isDivisionFromDataIsFromState || !id ? contractNumber : '',
                    policyHolder:
                        isDivisionFromDataIsFromState || !id ? policyHolder : '',
                    insuranceCompany:
                        isDivisionFromDataIsFromState || !id ? insuranceCompany : '',
                    errors: formState.errors,
                }),
            [
                risk,
                contractNumber,
                policyHolder,
                insuranceCompany,
                division,
                data?.division,
                formState.errors.contractNumber,
                formState.errors.insuranceCompany,
                formState.errors.policyHolder,
            ]
        );

        const projectPhaseOptions = useMemo(
            () => damageReportFormGenerateAONTechnicalProjectPhaseOptions(t),
            [t]
        );

        const thingAffectedByTheDamageOptions = useMemo(
            () => damageReportFormGenerateAONTechnicalThingAffectedOptions(t),
            [t]
        );

        const insuredConstructionOrAssemblyProjectOptions = useMemo(
            () => damageReportFormGenerateAONTechnicalThingAffectedOptions(t),
            [t]
        );

        const configs = useMemo(
            () =>
                DamageReportFormConfigAONKompositTechnical({
                    division,
                    placeOfDamage,
                    itemsAffectedByTheDamage,
                    damageAndCause,
                    estimatedLossAmountInEUR,
                    shouldDamagedItemsBeOperatedProvisionally,
                    namely,
                    isARepairPossibleAndPlanned,
                    through,
                    whoCanAnswerQuestionsAboutTheTechnicalDetails,
                    isWitnesses,
                    witnessesSurname,
                    witnessesRoad,
                    witnessesPostcode,
                    witnessesPlace,
                    witnessesPhoneNumber,
                    witnessesEmail,
                    damageInformationAreDamagedItemsOtherwiseInsured,
                    indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance,
                    warrantyPeriodForTheDamagedObjectExpired,
                    namelyBy,
                    lossOfInsuredItemsDueToTheftBurglaryOrArson,
                    isRecordedByThePolice,
                    policeStation,
                    fileNumber,
                    interruptionInOperationOrDelayIncommissioning,
                    interruptionInOperationNamely,
                    interruptionInOperationStandsSince,
                    expectedDamageInEurosPerDayOrWeekOrMonth,
                    mitigationMeasuresHaveAlreadyBeenTaken,
                    additionalCostsAreIncurredForBridgingMeasures,
                    whoCreatedTheServicesAffectedByTheDamage,
                    projectPhase,
                    projectPhaseOptions,
                    thingAffectedByTheDamage,
                    thingAffectedByTheDamageOptions,
                    thingAffectedByTheDamageSince,
                    thingAffectedAcceptedSince,
                    thingAffectedAcceptedBy,
                    insuredConstructionOrAssemblyProject,
                    insuredConstructionOrAssemblyProjectOptions,
                    insuredConstructionOrAssemblyProjectSince,
                    insuredConstructionOrAssemblyProjectAcceptedSince,
                    insuredConstructionOrAssemblyProjectAcceptedBy,
                    caseOfTheftForConstructionInsurance,
                    handleChangeSwitcher,
                    handleAddRow,
                    handleDeleteRow,
                    handleOnChangeRow,
                    fullWidthClass: componentClasses?.fullWidth,
                }),
            [
                placeOfDamage,
                itemsAffectedByTheDamage,
                damageAndCause,
                estimatedLossAmountInEUR,
                shouldDamagedItemsBeOperatedProvisionally,
                namely,
                isARepairPossibleAndPlanned,
                through,
                whoCanAnswerQuestionsAboutTheTechnicalDetails,
                isWitnesses,
                witnessesSurname,
                witnessesRoad,
                witnessesPostcode,
                witnessesPlace,
                witnessesPhoneNumber,
                witnessesEmail,
                damageInformationAreDamagedItemsOtherwiseInsured,
                indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance,
                warrantyPeriodForTheDamagedObjectExpired,
                namelyBy,
                lossOfInsuredItemsDueToTheftBurglaryOrArson,
                isRecordedByThePolice,
                policeStation,
                fileNumber,
                interruptionInOperationOrDelayIncommissioning,
                interruptionInOperationNamely,
                interruptionInOperationStandsSince,
                expectedDamageInEurosPerDayOrWeekOrMonth,
                mitigationMeasuresHaveAlreadyBeenTaken,
                additionalCostsAreIncurredForBridgingMeasures,
                whoCreatedTheServicesAffectedByTheDamage,
                projectPhase,
                projectPhaseOptions,
                thingAffectedByTheDamage,
                thingAffectedByTheDamageOptions,
                thingAffectedByTheDamageSince,
                thingAffectedAcceptedSince,
                thingAffectedAcceptedBy,
                insuredConstructionOrAssemblyProject,
                insuredConstructionOrAssemblyProjectOptions,
                insuredConstructionOrAssemblyProjectSince,
                insuredConstructionOrAssemblyProjectAcceptedSince,
                insuredConstructionOrAssemblyProjectAcceptedBy,
                caseOfTheftForConstructionInsurance,
                itemsAffectedByTheDamageFields,
                handleChangeSwitcher,
                handleAddRow,
                handleDeleteRow,
                handleOnChangeRow,
            ]
        );

        return (
            <>
                <DamageReportFormDeleteConfirmationModalWindow
                    id={id}
                    isOpen={isDeleteConfirmOpen}
                    onClose={() => setDeleteConfirmOpen(false)}
                />
                {
                    <DamageReportFormView
                        damageReportGroup={DamageReportGroups.TECHNICAL}
                        setDeleteConfirmOpen={setDeleteConfirmOpen}
                        deleteText={t('damages-report:deleteReport')}
                        deleteButtonClass={classes.deleteButton}
                        hideButtons={hideButtons}
                        boxContainer={classes.boxContainer}
                        sectionContainer={classes.sectionContainer}
                        formState={formState}
                        handleChangeValue={handleChangeValue}
                        footerConfig={[]}
                        headerConfig={headerConfig}
                        configs={configs}
                        sendText={t('send')}
                        formBlockElementClass={classes.formBlockElement}
                        containerClassName={classes.formBlockContainer}
                        saveText={t('save')}
                        saveButtonClass={clsx(classes.button, classes.saveButton)}
                        sendButtonClass={clsx(classes.button, classes.sendButton)}
                        isSendDisabled={
                            !formState.isValid ||
                            isSendingToAmsLoading ||
                            isSaveLoading ||
                            (contractId && !isContractValidByDate)
                        }
                        isSendingToAmsLoading={isSendingToAmsLoading}
                        isSaveLoading={isSaveLoading}
                        dayOfDamage={dayOfDamage}
                        IsSaveDisabled={
                            isSaveLoading ||
                            isSendingToAmsLoading ||
                            !division ||
                            !dayOfDamage ||
                            !contractNumber ||
                            !insuranceCompany ||
                            !policyHolder ||
                            (contractId && !isContractValidByDate)
                        }
                        handleCancel={handleCancel}
                        cancelText={t('cancel')}
                        handleReadOnlyFields={handleReadOnlyFields}
                        timeOfDamage={timeOfDamage}
                        handleChangeDate={handleChangeDate}
                        division={division}
                        divisionFromRecord={data?.division}
                        mainFormContainer={classes.mainFormContainer}
                        isReceivingDataLoading={false}
                        handleSubmit={handleSubmit(onSubmit)}
                        footerClassName={clsx(
                            classes.buttonsContainer,
                            classes.formBlockContainer
                        )}
                        handleAddFile={handleAddFile}
                        files={files}
                        fileInfos={fileInfos}
                        onDeleteFile={onDeleteFile}
                        handleDeleteFile={handleDeleteFile}
                        handleSaveReport={handleSaveReport}
                    />
                }
            </>
        );
    }
);
