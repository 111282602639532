import React, { Component, ErrorInfo, ReactNode } from 'react';

import { MaintanancePage } from 'src/pages';

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(): State {
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        console.error('Uncaught error:', error, errorInfo);
    }

    public render(): ReactNode {
        if (this.state.hasError) {
            return <MaintanancePage errorNumber={404} message='message' />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
