import React, { BaseSyntheticEvent, memo } from 'react';

import { FormControl, FormControlLabel } from '@material-ui/core';

import { SelectItem, ThemeSelectInput } from 'src/theming';

import { useFormControlLabelStyles, useFormControlStyles } from '../relink-styles';

interface Props {
    label: string;
    isEdit: boolean;
    items: SelectItem[];
    name: string;
    value: string;
    onChange: (e: BaseSyntheticEvent) => void;
}

export const RelinkSelectInput = memo(
    ({ label, isEdit, items, name, value, onChange }: Props): JSX.Element => {
        const formControlClasses = useFormControlStyles();
        const formControlLabelClasses = useFormControlLabelStyles();

        return (
            <FormControl fullWidth classes={formControlClasses} variant='outlined'>
                <FormControlLabel
                    classes={formControlLabelClasses}
                    control={
                        <ThemeSelectInput
                            editMode={isEdit}
                            items={items}
                            name={name}
                            value={value || ''}
                            onChange={onChange}
                        />
                    }
                    label={label}
                    labelPlacement='start'
                />
            </FormControl>
        );
    }
);
