import React, { ReactElement, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import useDarkMode from 'use-dark-mode';

import {
    FormControl,
    OutlinedInput,
    InputAdornment,
    Button,
    IconButton,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { ThemeCircularProgress } from 'src/theming';
import {
    isHectorInstance,
    isSGIFFOXXKInstance,
    isAonDigitalInstance,
    isAonMotorInstance,
    isCeatInstance,
    isHectorDemoInstance,
} from 'src/environment';

import {
    useFormControlStyles,
    useThemeSearchFieldStyles,
    useThemeSearchFieldInputStyles,
} from './theme-search-field-style';

interface ThemeSearchFieldProps {
    disabled?: boolean;
    isLoading?: boolean;
    name?: string;
    value?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const ThemeSearchField = (props: ThemeSearchFieldProps): ReactElement => {
    const formControlClasses = useFormControlStyles();
    const classes = useThemeSearchFieldStyles();
    const darkMode = useDarkMode();

    const inputClasses = useThemeSearchFieldInputStyles({ isDarkMode: darkMode.value });

    const { t } = useTranslation(['common']);

    const { disabled, isLoading, ...rest } = props;

    const searchWithIconButton =
        isHectorDemoInstance ||
        isHectorInstance ||
        isCeatInstance ||
        isSGIFFOXXKInstance ||
        isAonDigitalInstance ||
        isAonMotorInstance;

    return (
        <>
            <FormControl fullWidth classes={formControlClasses} variant='outlined'>
                <OutlinedInput
                    disabled={disabled}
                    placeholder={t('search')}
                    startAdornment={
                        searchWithIconButton ? (
                            <InputAdornment position='start'>
                                <SearchIcon className={classes.searchIcon} />
                            </InputAdornment>
                        ) : null
                    }
                    classes={inputClasses}
                    {...rest}
                />
            </FormControl>
            {!isHectorDemoInstance &&
                !isCeatInstance &&
                !isHectorInstance &&
                !isSGIFFOXXKInstance &&
                !isAonDigitalInstance &&
                !isAonMotorInstance && (
                    <Button
                        disabled={disabled}
                        startIcon={
                            isLoading ? (
                                <ThemeCircularProgress isLoading={isLoading} />
                            ) : (
                                <SearchIcon />
                            )
                        }
                        className={classes.searchButton}
                        type='submit'
                    >
                        {t('search')}
                    </Button>
                )}
            {(isHectorDemoInstance ||
                isHectorInstance ||
                isCeatInstance ||
                isSGIFFOXXKInstance ||
                isAonDigitalInstance ||
                isAonMotorInstance) && (
                <IconButton
                    disabled={disabled}
                    className={classes.searchButton}
                    type='submit'
                >
                    <SearchIcon />
                </IconButton>
            )}
        </>
    );
};
