import { useHttpClient } from 'src/lib/http-client/use-http-client';

export const useDownloadFile = (): {
    download: (route: string, routeId: string, attachmentId: string) => Promise<void>;
} => {
    const httpClient = useHttpClient();

    const download = async (
        route: string,
        routeId: string,
        attachmentId: string
    ): Promise<void> => {
        httpClient.download(`${route}/${routeId}/attachments/${attachmentId}`);
    };

    return {
        download,
    };
};
