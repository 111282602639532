import React from 'react';
import { DarkMode } from 'use-dark-mode';
import { useTranslation } from 'react-i18next';

import { Box, Grid, useTheme, useMediaQuery } from '@material-ui/core';

import { APP_MODULES } from 'src/shared/constants';
import NotificationCenter from 'src/notification-center/notification-center';
import { Can, AbilityActions, AbilitySubjects } from 'src/roleAccesses';
import { useGlobalFilter } from 'src/shared/contexts/contexts-global-filter/contexts-global-filter';
import { useStyle } from './theme-header-styles';
import { ThemeHeaderProfileMenu } from './theme-header-profile-menu';
import { ThemeSwitcher } from '..';

import { ThemeHeaderMainPortalGlobalFilters } from './theme-header-main-portal-global-filters';

import { ThemeHeaderLogo } from './theme-header-get-logo';
import { ThemeCustomerHeaderLogo } from './theme-header-get-customer-logo';

interface ThemeHeaderProps {
    darkMode: DarkMode;
    module?: APP_MODULES;
}

export const ThemeHeader = (props: ThemeHeaderProps): JSX.Element => {
    const theme = useTheme();
    const { filter } = useGlobalFilter();
    const matchesUpLargeSize = useMediaQuery(theme.breakpoints.up('lg'));
    const { darkMode, module } = props;

    const { t } = useTranslation(['common']);

    const classes = useStyle();

    return (
        <Grid container component='header' className={classes.header}>
            <Grid item className={classes.logoContainer}>
                <Box mr={!matchesUpLargeSize ? 2 : 4} className={classes.portalLogo}>
                    <ThemeHeaderLogo />
                </Box>
                <div className={classes.customerLogoContainer}>
                    <ThemeCustomerHeaderLogo
                        customerConnections={filter.customerConnections}
                    />
                </div>
            </Grid>
            {(matchesUpLargeSize || module) && <div style={{ flexGrow: 1 }} />}
            {!module && <ThemeHeaderMainPortalGlobalFilters />}
            <Grid item className={classes.switcherContainer}>
                <ThemeSwitcher
                    labelClass={classes.switcherLabel}
                    leftLabel={t('common:light')}
                    rightLabel={t('common:dark')}
                    checked={darkMode.value}
                    onChange={darkMode.toggle}
                />
            </Grid>
            <Can
                passThrough
                I={AbilityActions.use}
                a={AbilitySubjects.notificationCenter}
            >
                {(allowed: boolean) => allowed && <NotificationCenter />}
            </Can>

            <ThemeHeaderProfileMenu />
        </Grid>
    );
};
