import { AbilityActions, AbilitySubjects } from '../constants';

export function clerkRoleAccessGenerator(
    can: (actions: string | string[], subjects: string | string[]) => void
): void {
    can(AbilityActions.see, [
        AbilitySubjects.damages,
        AbilitySubjects.customers,
        AbilitySubjects.contractListProductsDropDown,
        AbilitySubjects.customerConnectionLogo,
        AbilitySubjects.dashboard,
    ]);
    can(AbilityActions.establish, AbilitySubjects.socketConnection);
    can(AbilityActions.use, AbilitySubjects.notificationCenter);
    can(
        [AbilityActions.see, AbilityActions.edit, AbilityActions.create],
        AbilitySubjects.damageReport
    );
    can(AbilityActions.edit, AbilitySubjects.contract);
    can(AbilityActions.see, AbilitySubjects.notificationNewMessageCustomerAdviserForm);
}
