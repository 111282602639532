import { makeStyles, Theme } from '@material-ui/core';
import {
    isAonMotorInstance,
    isAonDigitalInstance,
    isHectorDemoInstance,
} from 'src/environment';
import { THEME_MODES } from '../themes';

export const useStyle = makeStyles((theme: Theme) => {
    return {
        historiesButton: {
            marginLeft: theme.spacing(1.5),
        },
        closeButton: {
            position: 'absolute',
            top: '0',
            right: '0',
            '&:hover': { backgroundColor: 'inherit' },
        },
        centeredContainer: {
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        noDataMessage: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paginationContainer: {
            marginRight: 'auto',
        },
    };
});

export const useModalClasses = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        paper: {
            position: 'relative',
            minWidth: (props: { width: number }) => {
                return props?.width ? `${props.width}px` : '550px';
            },
            maxWidth: 'initial',
            padding: theme.spacing(5),
            backgroundColor: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return isDarkMode
                        ? theme.palette.primary.dark
                        : theme.palette.primary.light;
                }
                return theme.palette.primary.light;
            },

            '& label': {
                display: 'block',
                padding: '0 0.5rem',
                background: '#ffffff',
            },
        },
    };
});

export const useStyleDialogTitle = makeStyles((theme: Theme) => {
    return {
        root: {
            padding: '0',
            paddingBottom: theme.spacing(1.5),
            '& h2': {
                display: 'flex',
                alignItems: 'center',
                fontSize: '2.4rem',
            },
        },
    };
});

export const useStyleDialogContent = makeStyles(() => {
    return {
        root: {
            padding: '0',
            overflow: 'hidden',
        },
    };
});

export const useStyleDialogActions = makeStyles((theme: Theme) => {
    return {
        root: {
            marginTop: theme.spacing(3),
            padding: 0,
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(1),
            },
        },
    };
});
