import React, { FC, ReactElement } from 'react';
import { useDashboardContext } from 'src/shared/contexts';
import LoadingIndicator from 'src/shared/loading-indicator';
import { DashboardCard } from '../dashboard-card/dashboard-card';
import { DashboardNumericValue } from '../dashboard-numeric-value/dashboard-numeric-value';

interface Props {
    title: string;
    editMode: boolean;
    amount: number;
    isShown?: boolean;
    handleChangeShown?: (name: string, value: boolean) => void;
    name?: string;
}

export const DashboardContractsYearlyPremium: FC<Props> = ({
    title,
    editMode,
    isShown,
    handleChangeShown,
    name,
    amount,
}): ReactElement => {
    const { isGetDashboardDataLoading } = useDashboardContext();

    return (
        <DashboardCard
            header={title}
            editMode={editMode}
            isShown={isShown}
            handleChangeShown={handleChangeShown}
            name={name}
        >
            {!isGetDashboardDataLoading ? (
                <DashboardNumericValue value={amount} unit='€' />
            ) : (
                <LoadingIndicator />
            )}
        </DashboardCard>
    );
};
