import React, { ReactElement, ComponentType } from 'react';

import { Badge, makeStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const useWithBadgeStyles = makeStyles(() => {
    return {
        root: {
            width: '100%',
        },
        badge: {
            width: 'fit-content',
            minWidth: 15,
            height: 15,
            right: 5,
            top: 5,
        },
    };
});

interface withBadgeProps {
    badgeValue: number | string;
    injectedClasses?: ClassNameMap;
}

export const withBadge = function <T>(
    Component: ComponentType<T>
): ComponentType<T & withBadgeProps> {
    return ({
        badgeValue,
        injectedClasses,
        ...rest
    }: T & withBadgeProps): ReactElement => {
        const restParams: unknown = { ...rest };
        const classes = useWithBadgeStyles();

        return (
            <Badge
                classes={injectedClasses || classes}
                badgeContent={badgeValue}
                color='error'
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Component {...(restParams as T)} />
            </Badge>
        );
    };
};
