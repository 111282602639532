import { makeStyles, Theme } from '@material-ui/core';

export const useMessageComponentStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
        },
        message: {
            fontSize: '2rem',
        },
        success: {
            color: theme.palette.success.main,
        },
        error: {
            color: theme.palette.error.main,
        },
        info: {
            color: theme.palette.primary.contrastText,
        },
    };
});
