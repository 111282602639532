import React from 'react';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { Moment } from 'moment';

import { makeStyles, Theme, Grid, Typography } from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';

import { ThemeDatePicker } from 'src/theming';

interface CellWithDatePickerProps {
    title: string;
    value: ParsableDate;
    handleChange: (name: string, value: Moment) => void;
    name: string;
    dateSearchFieldClass?: string;
}

const useStyle = makeStyles((theme: Theme) => {
    return {
        container: {
            display: 'flex',
            flexWrap: 'nowrap',
            width: 'fit-content',
            minWidth: 70,
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            margin: 0,
        },
        searchIcon: {
            width: 18,
            height: 18,
            color: ({ value }: { value: ParsableDate }) =>
                value ? theme.palette.info.main : theme.palette.info.contrastText,
        },
    };
});

export const CellWithDatePicker = (props: CellWithDatePickerProps): JSX.Element => {
    const { title, value, handleChange, name, dateSearchFieldClass } = props;
    const classes = useStyle({ value });

    return (
        <Grid container alignItems='center' className={classes.container}>
            <ThemeDatePicker
                iconButtonTrigger={<SearchIcon className={classes.searchIcon} />}
                value={value}
                onChange={handleChange}
                name={name}
                className={dateSearchFieldClass}
            />
            <Typography variant='body2' component='span' className={classes.title}>
                {title}
            </Typography>
        </Grid>
    );
};
