import { TFunction } from 'i18next';
import { SelectItem } from 'src/theming';

export enum DamageReportFormTravelPurpouses {
    OFFICIAL = 'official',
    PRIVATE = 'private',
    NOT_KNOWN = 'notKnown',
}

export const generateTravelPurpouseOptions = (t: TFunction): SelectItem[] => {
    return [
        {
            title: t('damages-report:travelPurpouseOptions.official'),
            value: DamageReportFormTravelPurpouses.OFFICIAL,
        },
        {
            title: t('damages-report:travelPurpouseOptions.private'),
            value: DamageReportFormTravelPurpouses.PRIVATE,
        },
        {
            title: t('damages-report:travelPurpouseOptions.notKnown'),
            value: DamageReportFormTravelPurpouses.NOT_KNOWN,
        },
    ];
};
