import React, { memo } from 'react';
import { Grid, Typography } from '@material-ui/core';

interface DamageReportFormContainerProps {
    className: string;
    title: string;
    children: JSX.Element;
}

export const DamageReportFormContainer = memo(
    ({ className, title, children }: DamageReportFormContainerProps) => {
        return (
            <Grid container spacing={2}>
                {title && (
                    <Typography variant='h6' className={className}>
                        {title}
                    </Typography>
                )}
                {children}
            </Grid>
        );
    }
);
