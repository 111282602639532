import { GridSortModel } from '@material-ui/data-grid';
import { INTERNAL_ROLES } from 'src/shared/constants';
import { CheckboxItem } from 'src/theming';
import { DamageStatuses } from './damage-list-configs';

export const damagesListRequestBodyPreparing = (
    userRoles: string[],
    contractNumber?: string,
    damageNumber?: string,
    damageNumberE?: string,
    damageNumberI?: string,
    sortModel?: GridSortModel,
    filterValue?: string,
    page?: number,
    rowsPerPage?: number,
    division?: string,
    customerConnections?: CheckboxItem[],
    customers?: CheckboxItem[],
    availableDivisions?: string[]
): {
    contractNumber?: string;
    allowedCustomerConnections?: string[];
    allowedCustomers?: string[];
    allowedDivisions?: string[];
    damageNumber?: string;
    damageNumberE?: string;
    damageNumberI?: string;
    sortField?: string;
    isAscending?: boolean;
    skip?: number;
    take?: number;
} => {
    const skip = (page + 1) * rowsPerPage - rowsPerPage;
    const take = rowsPerPage + 1; // To understand there are values on the next page. Because we don't know general count
    const requestBody: {
        contractNumber?: string;
        allowedCustomerConnections?: string[];
        allowedCustomers?: string[];
        allowedDivisions?: string[];
        allowedStatuses?: string[];
        damageNumber?: string;
        damageNumberE?: string;
        damageNumberI?: string;
        sortField?: string;
        isAscending?: boolean;
        skip?: number;
        take?: number;
    } = {
        skip,
        take,
        allowedDivisions: division ? [division] : availableDivisions,
    };
    const isAllClientConnectionsChecked = customerConnections.find(
        (item) => item.value === 'all'
    )?.checked;
    const isAllClientsChecked = customers.find((item) => item.value === 'all')?.checked;

    requestBody.allowedCustomerConnections = customerConnections
        .filter((item) => isAllClientConnectionsChecked || item.checked)
        .map((item) => item.title);

    requestBody.allowedCustomers = customers
        .filter((item) => isAllClientsChecked || item.checked)
        .map((item) => item.value);

    const isUserAdminDomain = userRoles.some((r) => r === INTERNAL_ROLES.ADMIN_DOMAIN);
    if (isAllClientConnectionsChecked && isAllClientsChecked && isUserAdminDomain) {
        requestBody.allowedCustomerConnections = undefined;
        requestBody.allowedCustomers = undefined;
    } else if (isAllClientConnectionsChecked && isUserAdminDomain) {
        requestBody.allowedCustomerConnections = undefined;
    }

    if (contractNumber) {
        requestBody.contractNumber = contractNumber;
    }
    if (damageNumber) {
        requestBody.damageNumber = damageNumber;
    }
    if (damageNumberE) {
        requestBody.damageNumberE = damageNumberE;
    }
    if (damageNumberI) {
        requestBody.damageNumberI = damageNumberI;
    }
    if (sortModel && sortModel.length !== 0) {
        requestBody.isAscending = sortModel[0].sort === 'asc';
        requestBody.sortField = sortModel[0].field;
    }
    if (filterValue) {
        requestBody.allowedStatuses =
            filterValue === DamageStatuses.OPEN ? ['Offen'] : ['Geschlossen'];
    }
    return requestBody;
};
