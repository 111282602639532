import { CheckboxItem } from '../../theming';

export const localStorageServices = {
    clearAllTokens() {
        localStorage.removeItem('clientConnections');
        localStorage.removeItem('clients');
    },
    setClientConnections(connections: CheckboxItem[]) {
        localStorage.setItem('clientConnections', JSON.stringify(connections));
    },
    setClients(connections: CheckboxItem[]) {
        localStorage.setItem('clients', JSON.stringify(connections));
    },
    getClientConnection(): CheckboxItem[] {
        return JSON.parse(localStorage.getItem('clientConnections'));
    },
    getClients(): CheckboxItem[] {
        return JSON.parse(localStorage.getItem('clients'));
    },
    clearAuthData() {
        localStorage.removeItem('clientConnections');
        localStorage.removeItem('clients');
        localStorage.removeItem('userData');
        localStorage.removeItem('accessTokenExpireDate');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    },
};
