import { useTranslation } from 'react-i18next';

import { CheckboxItem } from 'src/theming';
import { useContractsListSettings } from 'src/shared/contexts';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

type UseProductListAndDivsion = (
    newDivision: string,
    finallyFunc?: (value: string) => void,
    productListState?: CheckboxItem[]
) => Promise<void>;

export const useProductListAndDivision = (): UseProductListAndDivsion => {
    const httpClient = useHttpClient();
    const { t } = useTranslation(['common', 'contracts-list', 'contract-types']);
    const { handleChangeList } = useContractsListSettings();

    const makeRequestForProductItems = async (
        divisionsParameter: string
    ): Promise<{ label: string; value: string }[]> => {
        return httpClient.get(`products/select-items${divisionsParameter}`);
    };

    const updateProductListAndDivision: UseProductListAndDivsion = async (
        newDivision,
        finallyFunc,
        productListState?: CheckboxItem[]
    ): Promise<void> => {
        const selectedDivisions = newDivision ? [newDivision] : null;
        const divisionsParameter = selectedDivisions
            ? selectedDivisions?.reduce(
                  (sum, curr) => sum + `?AllowedDivisions=${curr}`,
                  ''
              )
            : '';

        try {
            const data = await makeRequestForProductItems(divisionsParameter);
            const formattedValues = data
                ? data?.map((item) => ({
                      title: item.label,
                      value: item.value,
                      checked: false,
                      data: undefined,
                  }))
                : [];
            handleChangeList(
                productListState?.length > 1
                    ? productListState
                    : [
                          {
                              title: t('all'),
                              value: 'all',
                              checked: true,
                              data: undefined,
                          },
                          ...formattedValues,
                      ],
                null,
                productListState?.length > 1
            );
        } catch {
            return Promise.reject();
        } finally {
            if (finallyFunc) {
                finallyFunc(newDivision);
            }
        }
    };

    return updateProductListAndDivision;
};
