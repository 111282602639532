import { useEffect, useState } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { SelectItem } from 'src/theming';

interface DummyCustomerDTO {
    amsIdNr: string;
    customer: string;
    isDummy: boolean;
}

export const useCustomers = (customerConnection: string): SelectItem[] => {
    const httpClient = useHttpClient();

    const [customers, setCustomers] = useState<SelectItem[]>([]);

    const getCustomers = async (customerConnection: string): Promise<void> => {
        if (customerConnection) {
            const data = await httpClient.get<DummyCustomerDTO[]>(
                `customers/relink?customerConnection=${customerConnection}&isDummy=false`
            );
            if (Array.isArray(data)) {
                setCustomers(
                    data
                        ?.map((item) => ({ title: item.customer, value: item.amsIdNr }))
                        .sort((a, b) => a.title.localeCompare(b.title))
                );
            }
        }
    };

    useEffect(() => {
        getCustomers(customerConnection);
    }, [customerConnection]);

    return customers;
};
