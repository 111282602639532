import React from 'react';

import { makeStyles, Theme } from '@material-ui/core';

const useStyle = makeStyles((theme: Theme) => {
    return {
        street: {
            margin: 0,
        },
        postcode: {
            marginRight: theme.spacing(2),
        },
    };
});

interface Props {
    street: string;
    country?: string;
    postcode: string;
    city: string;
}

export const ThemeAddress = ({ street, postcode, city, country }: Props): JSX.Element => {
    const classes = useStyle();

    if (!street && !postcode && !city) {
        return null;
    }

    return (
        <div>
            {street && <p className={classes.street}>{street}</p>}
            {postcode && <span className={classes.postcode}>{postcode}</span>}
            {city && <span>{city}</span>}
            {country && <div>{country}</div>}
        </div>
    );
};
