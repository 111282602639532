import { AbilityActions, AbilitySubjects } from '../constants';
import { administratorRoleAccessGenerator } from './administratorRoleAccessGenerator';

// An Administrator plus can everything what can a simple administrator with additional abilities

export function administratorPlusRoleAccessGenerator(
    can: (actions: string | string[], subjects: string | string[]) => void
): void {
    administratorRoleAccessGenerator(can);

    can(AbilityActions.see, AbilitySubjects.umMasterData);
}
