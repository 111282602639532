import React from 'react';

import { IconProps } from './index';

export const MontageIcon = (props: IconProps): JSX.Element => {
    const { fill } = props;
    return (
        <svg width='40' height='40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M23.6 27.095L35.733 40 40 35.354 27.866 22.45 23.6 27.095zm7.522-11.184c-.788 0-1.638-.108-2.305-.409L5.784 39.892l-4.267-4.538L16.5 19.438l-3.579-3.828-1.456 1.505-2.932-3.032v6.15L7.118 21.74 0 14.082l1.416-1.505h5.682L4.267 9.544l7.2-7.657c2.365-2.516 6.167-2.516 8.533 0l-4.267 4.646 2.851 3.011-1.435 1.527 3.62 3.829 3.68-4.044c-.283-.71-.405-1.613-.405-2.409 0-4.237 3.155-7.57 7.078-7.57 1.194 0 2.245.3 3.196.903l-5.4 5.743 3.034 3.226 5.399-5.743c.566 1.01.85 2.086.85 3.441 0 4.13-3.135 7.464-7.079 7.464z'
                fill={fill}
            />
        </svg>
    );
};
