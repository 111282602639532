import React, { useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { Grid, List, ListItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { ThemeLoader, ThemeNestedMenu } from 'src/theming';
import { useAbilityCtx } from 'src/roleAccesses';

import { ContractDetailsListItem } from '../contract-details-list-item';

import {
    useStyle,
    useContractDetailsViewDataStyle,
} from '../contracts-details-view-styles';
import { useHrStyles } from './contarct-details-view-hr-style';

import { generateContractsDetailsAdditionalMenu } from '../contract-details-view-configs';

import { ContractDetailsViewDetailsResponseBodyHr } from './contract-details-view-details-response-body-hr';

interface ContractDetailsViewHrProps {
    handleOpenContractEdit: () => void;
    hideThemeNestedMenu: boolean;
    contractNumber: string;
}

export const ContractDetailsViewHr = (props: ContractDetailsViewHrProps): JSX.Element => {
    const ability = useAbilityCtx();
    const classes = useStyle();
    const contractDetailsViewDataClasses = useContractDetailsViewDataStyle();
    const maschineClasses = useHrStyles();

    const { t } = useTranslation([
        'contract-details-view',
        'formatted-values',
        'damages-list',
        'common',
    ]);

    const router = useHistory();
    const { id } = useParams<{ id: string }>();
    const httpClient = useHttpClient();

    const { handleOpenContractEdit, hideThemeNestedMenu, contractNumber } = props;

    const [isLoadingDetails, setLoadingDetails] = useState(false);
    const [detailsData, setDetailsData] =
        useState<ContractDetailsViewDetailsResponseBodyHr>();

    const makeRequestForDetails =
        async (): Promise<ContractDetailsViewDetailsResponseBodyHr> => {
            setLoadingDetails(true);
            return httpClient.get<ContractDetailsViewDetailsResponseBodyHr>(
                `contracts/${id}/hr`
            );
        };

    useEffect(() => {
        makeRequestForDetails()
            .then((data: ContractDetailsViewDetailsResponseBodyHr) => {
                setDetailsData(data);
            })
            .finally(() => {
                setLoadingDetails(false);
            });
    }, []);

    const goTo = (pathname: string): void => {
        router.push(pathname);
    };

    const menuItems = useMemo(
        () =>
            generateContractsDetailsAdditionalMenu(
                t,
                null,
                handleOpenContractEdit,
                goTo,
                ability,
                id,
                contractNumber
            ),
        [t, contractNumber, ability]
    );

    const atLeastOneValueInCoverageTypesTable = useMemo(
        () =>
            detailsData?.coverageTypes?.some((item) => item.coating || item.coatingType),
        [detailsData]
    );
    const atLeastOneValueInValuablesTable = useMemo(
        () =>
            detailsData?.valuables?.some(
                (item) =>
                    item.jewelry ||
                    item.cash ||
                    item.antique ||
                    item.certificates ||
                    item.fur ||
                    item.general
            ),
        [detailsData]
    );
    const atLeastOneValueInExpensiveThingsTable = useMemo(
        () =>
            detailsData?.expensiveThings?.some(
                (item) =>
                    item.artFirstColumn ||
                    item.artSecondColumn ||
                    item.wertFirstColumn ||
                    item.wertSecondColumn
            ),
        [detailsData]
    );
    const isDetailDataEmpty = useMemo(() => {
        if (detailsData) {
            const detailsDataKeys = Object.keys(detailsData);
            return !detailsDataKeys
                ?.map((x) => detailsData[x])
                ?.filter((x) => (x || typeof x === 'boolean') && !Array.isArray(x))
                ?.length;
        }
        return true;
    }, [detailsData]);

    return (
        <Grid container>
            <Grid container className={classes.titleAndMenuContainer}>
                <div style={{ flexGrow: 1 }} />
                {!hideThemeNestedMenu &&
                    Array.isArray(menuItems) &&
                    menuItems.length > 0 && (
                        <ThemeNestedMenu
                            menuItems={menuItems}
                            buttonClass={classes.additionalMenuButton}
                            buttonIcon={<MenuIcon className={classes.menuIcon} />}
                        />
                    )}
            </Grid>
            {isLoadingDetails && <ThemeLoader />}
            {isDetailDataEmpty &&
                !isLoadingDetails &&
                !atLeastOneValueInCoverageTypesTable &&
                !atLeastOneValueInExpensiveThingsTable &&
                !atLeastOneValueInValuablesTable && (
                    <ListItem>
                        <ListItemText data-title>
                            {t('contract-details-view:noContractDetails')}
                        </ListItemText>
                    </ListItem>
                )}
            {detailsData && !isLoadingDetails && !isDetailDataEmpty && (
                <>
                    <List
                        className={clsx(
                            contractDetailsViewDataClasses.list,
                            maschineClasses.listItem
                        )}
                    >
                        <ListItem>
                            <ListItemText className={maschineClasses.subtitle}>
                                {t(
                                    'contract-details-view:contractDetails.riskDescription'
                                )}
                            </ListItemText>
                        </ListItem>
                        <ContractDetailsListItem
                            hidden={!detailsData?.riskAddress}
                            title='contract-details-view:contractDetails.riskAddress'
                            value={detailsData?.riskAddress}
                        />
                        <ListItem className={maschineClasses.listItem}>
                            {detailsData && atLeastOneValueInValuablesTable && (
                                <table className={maschineClasses.table}>
                                    <thead className={maschineClasses.tableHeader}>
                                        {t(
                                            'contract-details-view:contractDetails.valuable'
                                        )}
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th></th>
                                            <th>
                                                {t(
                                                    'contract-details-view:contractDetails.cash'
                                                )}
                                            </th>
                                            <th>
                                                {t(
                                                    'contract-details-view:contractDetails.certificates'
                                                )}
                                            </th>
                                            <th>
                                                {t(
                                                    'contract-details-view:contractDetails.jewelry'
                                                )}
                                            </th>
                                            <th>
                                                {t(
                                                    'contract-details-view:contractDetails.fur'
                                                )}
                                            </th>
                                            <th>
                                                {t(
                                                    'contract-details-view:contractDetails.antique'
                                                )}
                                            </th>
                                            <th>
                                                {t(
                                                    'contract-details-view:contractDetails.general'
                                                )}
                                            </th>
                                        </tr>
                                        {detailsData?.valuables
                                            ?.filter((item) => {
                                                return (
                                                    item.jewelry ||
                                                    item.cash ||
                                                    item.antique ||
                                                    item.certificates ||
                                                    item.fur ||
                                                    item.general
                                                );
                                            })
                                            ?.map((item) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td
                                                            className={
                                                                maschineClasses.tableCell
                                                            }
                                                        >
                                                            {t(
                                                                `contract-details-view:contractDetails.${item.title}`
                                                            )}
                                                        </td>
                                                        {item.cash !== 0 && (
                                                            <td
                                                                className={
                                                                    maschineClasses.tableCell
                                                                }
                                                            >
                                                                {t(
                                                                    'formatted-values:formattedCurrency',
                                                                    {
                                                                        currency:
                                                                            item.cash,
                                                                    }
                                                                )}
                                                            </td>
                                                        )}
                                                        {item.certificates !== 0 && (
                                                            <td
                                                                className={
                                                                    maschineClasses.tableCell
                                                                }
                                                            >
                                                                {t(
                                                                    'formatted-values:formattedCurrency',
                                                                    {
                                                                        currency:
                                                                            item.certificates,
                                                                    }
                                                                )}
                                                            </td>
                                                        )}
                                                        {item.jewelry !== 0 && (
                                                            <td
                                                                className={
                                                                    maschineClasses.tableCell
                                                                }
                                                            >
                                                                {t(
                                                                    'formatted-values:formattedCurrency',
                                                                    {
                                                                        currency:
                                                                            item.jewelry,
                                                                    }
                                                                )}
                                                            </td>
                                                        )}
                                                        {item.fur !== 0 && (
                                                            <td
                                                                className={
                                                                    maschineClasses.tableCell
                                                                }
                                                            >
                                                                {t(
                                                                    'formatted-values:formattedCurrency',
                                                                    {
                                                                        currency:
                                                                            item.fur,
                                                                    }
                                                                )}
                                                            </td>
                                                        )}
                                                        {item.antique !== 0 && (
                                                            <td
                                                                className={
                                                                    maschineClasses.tableCell
                                                                }
                                                            >
                                                                {t(
                                                                    'formatted-values:formattedCurrency',
                                                                    {
                                                                        currency:
                                                                            item.antique,
                                                                    }
                                                                )}
                                                            </td>
                                                        )}
                                                        {item.general !== 0 && (
                                                            <td
                                                                className={
                                                                    maschineClasses.tableCell
                                                                }
                                                            >
                                                                {t(
                                                                    'formatted-values:formattedCurrency',
                                                                    {
                                                                        currency:
                                                                            item.general,
                                                                    }
                                                                )}
                                                            </td>
                                                        )}
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            )}
                        </ListItem>
                        <ListItem>
                            <ListItemText className={maschineClasses.subtitle}>
                                {t('contract-details-view:contractDetails.ec')}
                            </ListItemText>
                        </ListItem>
                        <ContractDetailsListItem
                            hidden={!detailsData?.year}
                            title='contract-details-view:contractDetails.year'
                            value={detailsData?.year}
                        />
                        <ContractDetailsListItem
                            hidden={detailsData?.disorder}
                            title='contract-details-view:contractDetails.civilUnrestOrMaliciousDamageOrStrikeOrLockout'
                            value={
                                detailsData?.disorder ? t('common:yes') : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={detailsData?.blowSmokeWaves}
                            title='contract-details-view:contractDetails.vehicleImpactOrSmokeOrSupersonicWaves'
                            value={
                                detailsData?.blowSmokeWaves
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={detailsData?.fireFightingSystem}
                            title='contract-details-view:contractDetails.sprinklerSystem'
                            value={
                                detailsData?.fireFightingSystem
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={detailsData?.tapWater}
                            title='contract-details-view:contractDetails.tapWater'
                            value={
                                detailsData?.tapWater ? t('common:yes') : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={detailsData?.storm}
                            title='contract-details-view:contractDetails.storm'
                            value={detailsData?.storm ? t('common:yes') : t('common:no')}
                        />
                        <ContractDetailsListItem
                            hidden={detailsData?.hail}
                            title='contract-details-view:contractDetails.hail'
                            value={detailsData?.hail ? t('common:yes') : t('common:no')}
                        />
                        <ContractDetailsListItem
                            hidden={detailsData?.lossOfAnnuity}
                            title='contract-details-view:contractDetails.lossOfRent'
                            value={
                                detailsData?.lossOfAnnuity
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={detailsData?.glass}
                            title='contract-details-view:contractDetails.glass'
                            value={detailsData?.glass ? t('common:yes') : t('common:no')}
                        />
                        <ContractDetailsListItem
                            hidden={detailsData?.unknownHazards}
                            title='contract-details-view:contractDetails.unnamedDangers'
                            value={
                                detailsData?.unknownHazards
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={detailsData?.elementary}
                            title='contract-details-view:contractDetails.elementary'
                            value={
                                detailsData?.elementary ? t('common:yes') : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={
                                typeof detailsData?.floodingOfTheInsuredPlace !==
                                'boolean'
                            }
                            title='contract-details-view:contractDetails.floodingOfInsuredLocation'
                            value={
                                detailsData?.floodingOfTheInsuredPlace
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={detailsData?.earthquake}
                            title='contract-details-view:contractDetails.earthquake'
                            value={
                                detailsData?.earthquake ? t('common:yes') : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={detailsData?.drawdownOrLandslide}
                            title='contract-details-view:contractDetails.subsidenceOrLandslide'
                            value={
                                detailsData?.drawdownOrLandslide
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={detailsData?.backwater}
                            title='contract-details-view:contractDetails.backwater'
                            value={
                                detailsData?.backwater ? t('common:yes') : t('common:no')
                            }
                            titleClassName={maschineClasses.listItem}
                        />
                        <ContractDetailsListItem
                            hidden={detailsData?.avalanches}
                            title='contract-details-view:contractDetails.snowPressureOrAvalanches'
                            value={
                                detailsData?.avalanches ? t('common:yes') : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={detailsData?.eruption}
                            title='contract-details-view:contractDetails.volcanicEruption'
                            value={
                                detailsData?.eruption ? t('common:yes') : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={detailsData?.graffiti}
                            title='contract-details-view:contractDetails.graffiti'
                            value={
                                detailsData?.graffiti ? t('common:yes') : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={detailsData?.vandalism}
                            title='contract-details-view:contractDetails.vandalism'
                            value={
                                detailsData?.vandalism ? t('common:yes') : t('common:no')
                            }
                        />
                        <ListItem className={maschineClasses.listItem}>
                            {detailsData && atLeastOneValueInExpensiveThingsTable && (
                                <table className={maschineClasses.table}>
                                    <tbody>
                                        <tr>
                                            <th></th>
                                            <th>
                                                {t(
                                                    'contract-details-view:contractDetails.type'
                                                )}
                                            </th>
                                            <th>
                                                {t(
                                                    'contract-details-view:contractDetails.value'
                                                )}
                                            </th>
                                            <th>
                                                {t(
                                                    'contract-details-view:contractDetails.type'
                                                )}
                                            </th>
                                            <th>
                                                {t(
                                                    'contract-details-view:contractDetails.value'
                                                )}
                                            </th>
                                        </tr>
                                        {detailsData?.expensiveThings
                                            ?.filter((item) => {
                                                return (
                                                    item.artFirstColumn ||
                                                    item.artSecondColumn ||
                                                    item.wertFirstColumn ||
                                                    item.wertSecondColumn
                                                );
                                            })
                                            ?.map((item) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td
                                                            className={
                                                                maschineClasses.tableCell
                                                            }
                                                        >
                                                            {item.title
                                                                ? t(
                                                                      `contract-details-view:contractDetails.${item.title}`
                                                                  )
                                                                : ''}
                                                        </td>
                                                        <td
                                                            className={
                                                                maschineClasses.tableCell
                                                            }
                                                        >
                                                            {item.artFirstColumn}
                                                        </td>
                                                        <td
                                                            className={
                                                                maschineClasses.tableCell
                                                            }
                                                        >
                                                            {t(
                                                                'formatted-values:formattedCurrency',
                                                                {
                                                                    currency:
                                                                        item.wertFirstColumn,
                                                                }
                                                            )}
                                                        </td>
                                                        <td
                                                            className={
                                                                maschineClasses.tableCell
                                                            }
                                                        >
                                                            {item.artSecondColumn}
                                                        </td>
                                                        <td
                                                            className={
                                                                maschineClasses.tableCell
                                                            }
                                                        >
                                                            {t(
                                                                'formatted-values:formattedCurrency',
                                                                {
                                                                    currency:
                                                                        item.wertSecondColumn,
                                                                }
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            )}
                        </ListItem>
                        <ListItem>
                            {detailsData && atLeastOneValueInCoverageTypesTable && (
                                <table className={maschineClasses.table}>
                                    <tbody>
                                        <tr>
                                            <th>
                                                {t(
                                                    'contract-details-view:contractDetails.coverageType'
                                                )}
                                            </th>
                                            <th>
                                                {t(
                                                    'contract-details-view:contractDetails.coverageAmount'
                                                )}
                                            </th>
                                        </tr>
                                        {detailsData?.coverageTypes
                                            ?.filter((item) => {
                                                return item.coatingType || item.coating;
                                            })
                                            ?.map((item) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td
                                                            className={
                                                                maschineClasses.tableCell
                                                            }
                                                        >
                                                            {item.coatingType}
                                                        </td>
                                                        {item.coating !== 0 && (
                                                            <td
                                                                className={
                                                                    maschineClasses.tableCell
                                                                }
                                                            >
                                                                {t(
                                                                    'formatted-values:formattedCurrency',
                                                                    {
                                                                        currency:
                                                                            item.coating,
                                                                    }
                                                                )}
                                                            </td>
                                                        )}
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            )}
                        </ListItem>
                        <ContractDetailsListItem
                            hidden={
                                detailsData?.sbInPercent === null ||
                                detailsData?.sbInPercent === 0
                            }
                            title='contract-details-view:contractDetails.sbInPercent'
                            value={
                                detailsData?.sbInPercent
                                    ? String(detailsData?.sbInPercent)
                                    : ''
                            }
                        />
                        <ContractDetailsListItem
                            hidden={
                                detailsData?.sbMind === null || detailsData?.sbMind === 0
                            }
                            title='contract-details-view:contractDetails.min'
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.sbMind,
                            })}
                        />
                        <ContractDetailsListItem
                            hidden={
                                detailsData?.sbMax === null || detailsData?.sbMax === 0
                            }
                            title='contract-details-view:contractDetails.max'
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.sbMax,
                            })}
                        />
                        <ContractDetailsListItem
                            hidden={
                                detailsData?.sbInEuro === null ||
                                detailsData?.sbInEuro === 0
                            }
                            title='contract-details-view:contractDetails.sbInEUR'
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.sbInEuro,
                            })}
                        />
                        <ListItem>
                            <ListItemText className={maschineClasses.subtitle}>
                                {t('contract-details-view:contractDetails.glass')}
                            </ListItemText>
                        </ListItem>
                        <ContractDetailsListItem
                            hidden={!detailsData?.risk}
                            title='contract-details-view:contractDetails.risk'
                            value={detailsData?.risk}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.glassRiskAddress}
                            title='contract-details-view:contractDetails.glassRiskAddress'
                            value={detailsData?.glassRiskAddress}
                        />
                    </List>
                </>
            )}
        </Grid>
    );
};
