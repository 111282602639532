import React, { SyntheticEvent, useState, useRef, useCallback, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';
import renderHtml from 'html-react-parser';

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    AccordionActions,
    IconButton,
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
    useStyle,
    useAccordionSummaryStyle,
    useAccordionStyle,
    useAccordionActionsStyle,
    useAccordionDetailsStyle,
} from './theme-expansion-panel-styles';

export interface ExpandPanel {
    id: string;
    header: string;
    content: string | JSX.Element;
    headerClassName?: string;
}

interface ThemeExpansionPanelProps {
    data: ExpandPanel[];
    readonly: boolean;
    onEdit?: (panel: string) => void;
    onDelete?: (panel: string) => void;
    onDrop?: (panels: unknown) => void;
    contentAsJSX?: boolean;
    externalAccordionSummaryClassName?: string;
    externalAccordionDetailsClassName?: string;
    externalAccordionClassName?: string;
}

export const ThemeExpansionItem = ({
    panel,
    index,
    readonly,
    expanded,
    setExpanded,
    moveCard,
    onEdit,
    onDelete,
    contentAsJSX,
    externalAccordionSummaryClassName,
    externalAccordionDetailsClassName,
    externalAccordionClassName,
}): JSX.Element => {
    const accordionClasses = useAccordionStyle();
    const accordionSummaryClasses = useAccordionSummaryStyle({ readonly });
    const accordionActionsClasses = useAccordionActionsStyle();
    const accordionDetailsClasses = useAccordionDetailsStyle();

    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: 'card',
        hover(item: { id: string; index: number }, monitor) {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            const clientOffset = monitor.getClientOffset() || { y: 0 };

            const hoverClientY = clientOffset?.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
        drop(item: { id: string; index: number }) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            moveCard(dragIndex, hoverIndex, true);
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'card',
        item: () => ({ id: panel?.id, index }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const handleChange = (panel: string) => (_: SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleDelete =
        (panel: string) =>
        (event: SyntheticEvent): void => {
            event.stopPropagation();
            onDelete(panel);
        };

    const handleEdit = (event: SyntheticEvent): void => {
        event.stopPropagation();
        onEdit(panel);
    };

    if (index || index === 0) {
        drag(drop(ref));
    } else drag(drop(null));

    const opacity = isDragging ? 0 : 1;

    return (
        <div ref={readonly ? null : ref}>
            <Accordion
                style={{ opacity }}
                elevation={0}
                classes={accordionClasses}
                className={externalAccordionClassName}
                key={panel.id}
                expanded={expanded === panel.id}
                onChange={handleChange(panel.id)}
            >
                <AccordionSummary
                    classes={accordionSummaryClasses}
                    className={externalAccordionSummaryClassName}
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant='h5' className={panel.headerClassName}>
                        {panel.header}
                    </Typography>
                    {!readonly && (
                        <>
                            <div style={{ flexGrow: 1 }} />
                            <AccordionActions
                                disableSpacing
                                classes={accordionActionsClasses}
                            >
                                <IconButton onClick={handleEdit}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={handleDelete(panel.id)}>
                                    {<DeleteIcon />}
                                </IconButton>
                            </AccordionActions>
                        </>
                    )}
                </AccordionSummary>
                <AccordionDetails
                    classes={accordionDetailsClasses}
                    className={externalAccordionDetailsClassName}
                >
                    {!contentAsJSX ? renderHtml(panel.content) : panel.content}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export const ThemeExpansionPanel = (props: ThemeExpansionPanelProps): JSX.Element => {
    const {
        data,
        readonly,
        onEdit,
        onDelete,
        onDrop,
        contentAsJSX,
        externalAccordionSummaryClassName,
        externalAccordionDetailsClassName,
        externalAccordionClassName,
    } = props;

    const [expanded, setExpanded] = useState('');
    const [panels, setPanels] = useState(data);

    const classes = useStyle();

    useEffect(() => {
        setPanels(data);
    }, [data]);

    const moveCard = useCallback(
        (dragIndex, hoverIndex, isDrop) => {
            const dragCard = panels[dragIndex];
            const newPanels = update(panels, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragCard],
                ],
            });
            if (isDrop) {
                onDrop(panels);
            }
            setPanels(newPanels);
        },
        [panels]
    );

    return (
        <div className={classes.expansionPanelContainer}>
            {panels.map((panel, index) => (
                <ThemeExpansionItem
                    contentAsJSX={contentAsJSX}
                    key={panel?.id || index}
                    panel={panel || {}}
                    readonly={readonly}
                    index={index}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    moveCard={moveCard}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    externalAccordionSummaryClassName={externalAccordionSummaryClassName}
                    externalAccordionDetailsClassName={externalAccordionDetailsClassName}
                    externalAccordionClassName={externalAccordionClassName}
                />
            ))}
        </div>
    );
};

ThemeExpansionPanel.defaultProps = {
    readonly: true,
    contentAsJSX: false,
};
