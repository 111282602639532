import React from 'react';

import { IconProps } from './index';

export const HumanCaneIcon = (props: IconProps): JSX.Element => {
    const { fill } = props;
    return (
        <>
            <svg width={28} height={40} fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M28 20.48V40h-2.025V20.48c0-.3-.129-.48-.28-.7a.931.931 0 00-.667-.26c-.323 0-.56.08-.754.26-.194.22-.28.42-.28.7v1.84h-1.96V21c-1.508-.34-2.865-.92-4.05-1.74a12.708 12.708 0 01-2.993-2.88l-.754 2.4C14 19.62 14 20.48 14 21.36v1.3l3.985 5.22V40H14v-9.32L10.382 26l-.367 6.5L4.006 40 .818 37.74l5.148-6.46v-9.92c0-1.06.108-2.1.302-3.14L7 15.08l-2.994 1.56v6.62H0v-8.8L10.77 8.8c.624-.28 1.27-.44 1.96-.44.688 0 1.356.18 1.98.52.69.36 1.207.88 1.595 1.58l1.593 3.14c.625 1.16 1.573 2.14 2.865 2.92 1.27.78 2.692 1.18 4.265 1.18.818 0 1.507.3 2.11.78.603.52.862 1.18.862 2zM15.077 0c2.39 0 4.308 1.8 4.308 4 0 2.22-1.917 4-4.308 4-2.37 0-4.308-1.78-4.308-4 0-2.2 1.939-4 4.308-4z'
                    fill={fill}
                />
            </svg>
        </>
    );
};
