import { makeStyles, Theme } from '@material-ui/core/styles';

import {
    isAonMotorInstance,
    isAonDigitalInstance,
    isHectorDemoInstance,
} from 'src/environment';
import { THEME_MODES } from '../themes';

export const useDropDownZoneStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        root: {
            minHeight: 80,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return isDarkMode
                        ? theme.palette.primary.dark
                        : theme.palette.primary.light;
                }
                return theme.palette.primary.light;
            },
            borderColor: ({ error }: { error: boolean }) => {
                if (error) {
                    return theme.palette.error.main;
                }
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return isDarkMode
                        ? theme.palette.primary.light
                        : theme.palette.primary.dark;
                }
                return theme.palette.primary.dark;
            },
            '& .MuiDropzonePreviewList-root': {
                margin: 0,
            },
            '& .MuiDropzonePreviewList-imageContainer': {
                '& .MuiSvgIcon-root': {
                    width: 50,
                    height: 50,
                },
                '& .MuiTypography-root': {
                    fontSize: '1.2rem',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                },
                '& .MuiButtonBase-root': {
                    top: 10,
                    left: 30,
                    width: 25,
                    height: 25,
                    minHeight: 25,
                    '& .MuiSvgIcon-root': {
                        width: 20,
                        height: 20,
                    },
                },
            },
        },
        textContainer: {
            '& .MuiDropzoneArea-text': {
                fontSize: '1.4rem',
                fontWeight: theme.typography.fontWeightRegular,
                color: theme.palette.primary.contrastText,
                opacity: 0.6,
            },
            '& .MuiSvgIcon-root': {
                display: 'none',
            },
        },
        invalid: {
            backgroundColor: theme.palette.primary.main,
            backgroundImage: `repeating-linear-gradient(-45deg, ${theme.palette.info.light}, ${theme.palette.info.light} 25px, ${theme.palette.secondary.main} 25px, ${theme.palette.secondary.main} 50px)`,
            borderColor: theme.palette.info.main,
        },
    };
});
