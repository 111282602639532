import React from 'react';

import { makeStyles, useTheme, Theme } from '@material-ui/core';

import {
    isAonInstance,
    isAonKompositInstance,
    isHectorInstance,
    isHectorDemoInstance,
    isAonMotorInstance,
    isAonDigitalInstance,
} from 'src/environment';
import { THEME_MODES } from '.';

const useStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        cls: {
            fill: 'none',
            stroke:
                (isAonInstance ||
                    isAonKompositInstance ||
                    isHectorInstance ||
                    isHectorDemoInstance ||
                    isAonMotorInstance ||
                    isAonDigitalInstance) &&
                !isDarkMode
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.contrastText,
            strokeWidth: '2px',
        },
    };
});

export const ThemeGearIcon = (): JSX.Element => {
    const classes = useStyle();
    const theme = useTheme();
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return (
        <svg data-name='layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 153 127'>
            <path
                className={classes.cls}
                d='m39.67 8.69-.18-.51-2.3-6.35A1.26 1.26 0 0 0 36 1h-6.8a1.26 1.26 0 0 0-.73.23 1.3 1.3 0 0 0-.46.6l-2.15 6-.16.45-.44.16a20.27 20.27 0 0 0-2.55 1.06c-.52.28-1.11.63-1.77 1.07l-.44.3-.5-.21-6.17-2.57a1.3 1.3 0 0 0-1.37.26l-4.56 4.3a1.11 1.11 0 0 0-.36.66 1.22 1.22 0 0 0 .09.75l2.65 5.59.2.43-.21.44c-.36.73-.69 1.47-1 2.22-.2.55-.44 1.32-.72 2.34l-.12.44-.41.2-6.1 2.93a1.32 1.32 0 0 0-.5.44 1.3 1.3 0 0 0-.2.63L1 36a1.22 1.22 0 0 0 .23.76 1.28 1.28 0 0 0 .65.47L7.7 39l.49.15.17.49c.29.86.61 1.72 1 2.57.29.68.61 1.36.95 2l.22.43-.2.44-2.71 5.78a1.34 1.34 0 0 0-.09.74 1.19 1.19 0 0 0 .37.65l4.57 4.29a1.35 1.35 0 0 0 .65.33 1.3 1.3 0 0 0 .73-.09L20 54.14l.43-.18.42.22c.64.32 1.28.63 1.94.91.81.33 1.63.64 2.46.92l.44.15.17.45 2.49 6.58a1.28 1.28 0 0 0 .48.6 1.25 1.25 0 0 0 .73.21l6.18-.14a1.36 1.36 0 0 0 .71-.23 1.22 1.22 0 0 0 .45-.6l2.1-5.85.16-.43.43-.16L42 55.7a22 22 0 0 0 2.47-1.12l.52-.27.46.25 5.45 2.88a1.25 1.25 0 0 0 .76.14 1.31 1.31 0 0 0 .7-.31l5.28-4.72a1.23 1.23 0 0 0 .38-.66 1.18 1.18 0 0 0-.08-.76L55.16 45l-.16-.42.18-.41c.34-.79.66-1.6.93-2.42a18.45 18.45 0 0 0 .47-1.86l.12-.55.53-.19 5.53-2a1.24 1.24 0 0 0 .83-1.09l.41-6.71a1.25 1.25 0 0 0-.76-1.21l-5.84-2.5-.4-.17-.15-.39c-.4-1-.73-1.84-1-2.41s-.56-1.15-1-1.9l-.23-.43.19-.44 2.5-5a2.12 2.12 0 0 0-.53-2.4l-3.22-3.23a1.79 1.79 0 0 0-1.77-.36l-7.38 2.42ZM39.67 8.69l.52.13M39.67 8.69l.52.13'
            />
            <path
                className={classes.cls}
                d='M32.58 21.81a10.7 10.7 0 1 0 10.8 10.7 10.76 10.76 0 0 0-10.8-10.7Z'
            />
            <path
                d='M110.62 33a3.31 3.31 0 0 1 3.12 2.15l3.35 9.2a32.16 32.16 0 0 1 3.11.9c1 .34 2.18.85 3.7 1.55l7.81-4.09a3.41 3.41 0 0 1 2.08-.34 3.28 3.28 0 0 1 1.87 1l6.87 7a3.18 3.18 0 0 1 .65 3.5l-3.66 8.49c.61 1.11 1.1 2 1.47 2.84s.89 2 1.48 3.57l8.53 3.6a3.21 3.21 0 0 1 2 3.17l-.62 9.75a3.35 3.35 0 0 1-.66 1.75 3.39 3.39 0 0 1-1.53 1.1L142.1 91c-.23 1.1-.48 2-.73 2.85-.42 1.23-.89 2.44-1.42 3.63l4.06 8.89a3.1 3.1 0 0 1 .22 2 3.14 3.14 0 0 1-1 1.73l-7.72 6.83a3.37 3.37 0 0 1-3.78.45l-8-4.17a34 34 0 0 1-3.85 1.72l-3.47 1.29-3.09 8.46a3.23 3.23 0 0 1-1.19 1.54 3.4 3.4 0 0 1-1.85.61l-9 .2a3.36 3.36 0 0 1-1.92-.55 3.27 3.27 0 0 1-1.25-1.55l-3.64-9.53c-1.25-.4-2.47-.9-3.7-1.4-1-.43-2-.89-2.93-1.38l-9 3.82a3.38 3.38 0 0 1-3.59-.63l-6.67-6.2a3.17 3.17 0 0 1-1-1.72 3.23 3.23 0 0 1 .23-2l3.88-8.36c-.52-1-1-2-1.43-3-.52-1.26-1-2.53-1.43-3.81l-8.5-2.56A3.36 3.36 0 0 1 58.61 87a3.23 3.23 0 0 1-.61-2l.33-9a3.24 3.24 0 0 1 .54-1.65 3.19 3.19 0 0 1 1.33-1.14l8.92-4.24c.41-1.5.77-2.67 1.09-3.52.45-1.13.94-2.25 1.49-3.34L67.83 54a3.19 3.19 0 0 1 .72-3.71L75.22 44a3.33 3.33 0 0 1 1.68-.85 3.46 3.46 0 0 1 1.88.2l9 3.68a28.89 28.89 0 0 1 2.72-1.61 29.21 29.21 0 0 1 3.91-1.63l3.14-8.65a3.24 3.24 0 0 1 1.21-1.57 3.31 3.31 0 0 1 1.9-.59Zm-5 33a14 14 0 1 0 14.33 14 14.18 14.18 0 0 0-14.34-14Z'
                style={{
                    fill:
                        (isAonInstance ||
                            isAonKompositInstance ||
                            isHectorInstance ||
                            isHectorDemoInstance) &&
                        !isDarkMode
                            ? theme.palette.secondary.main
                            : theme.palette.secondary.contrastText,
                }}
            />
        </svg>
    );
};
