import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { List, ListItem, ListItemText, Grid } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { ThemeNestedMenu, ThemeLoader } from 'src/theming';
import { useAbilityCtx } from 'src/roleAccesses';

import {
    useStyle,
    useContractDetailsViewDataStyle,
} from '../contracts-details-view-styles';
import { usetGeschStyle } from './contract-details-view-gesch-style';

import { ContractDetailsListItem } from '../contract-details-list-item';

import { ContractDetailsViewGESCHResponseBody } from './contract-details-view-gesh-response-body';

import { generateContractsDetailsAdditionalMenu } from '../contract-details-view-configs';

interface ContractDetailsViewGeschProps {
    handleOpenContractEdit: () => void;
    hideThemeNestedMenu: boolean;
    contractNumber: string;
}

export const ContractDetailsViewGesch = (
    props: ContractDetailsViewGeschProps
): JSX.Element => {
    const ability = useAbilityCtx();
    const classes = useStyle();
    const contractDetailsViewDataClasses = useContractDetailsViewDataStyle();
    const geschClasses = usetGeschStyle();

    const { handleOpenContractEdit, hideThemeNestedMenu, contractNumber } = props;

    const { t } = useTranslation([
        'contract-details-view',
        'formatted-values',
        'damages-list',
        'common',
    ]);

    const router = useHistory();
    const { id } = useParams<{ id: string }>();
    const httpClient = useHttpClient();

    const [isLoadingDetails, setLoadingDetails] = useState(false);
    const [detailsData, setDetailsData] =
        useState<ContractDetailsViewGESCHResponseBody>();

    const makeRequestForDetails =
        async (): Promise<ContractDetailsViewGESCHResponseBody> => {
            setLoadingDetails(true);
            return httpClient.get<ContractDetailsViewGESCHResponseBody>(
                `contracts/${id}/gesch`
            );
        };

    useEffect(() => {
        makeRequestForDetails()
            .then((data: ContractDetailsViewGESCHResponseBody) => {
                setDetailsData(data);
            })
            .finally(() => {
                setLoadingDetails(false);
            });
    }, []);

    const goTo = (pathname: string): void => {
        router.push(pathname);
    };

    const menuItems = useMemo(
        () =>
            generateContractsDetailsAdditionalMenu(
                t,
                null,
                handleOpenContractEdit,
                goTo,
                ability,
                id,
                contractNumber
            ),
        [t, contractNumber, ability]
    );

    const isDetailDataEmpty = useMemo(() => {
        if (detailsData) {
            const detailsDataKeys = Object.keys(detailsData);
            return !detailsDataKeys
                ?.map((x) => detailsData[x])
                ?.filter((x) => x || typeof x === 'boolean')?.length;
        }
        return true;
    }, [detailsData]);

    return (
        <Grid container>
            <Grid container className={classes.titleAndMenuContainer}>
                <div style={{ flexGrow: 1 }} />
                {!hideThemeNestedMenu &&
                    Array.isArray(menuItems) &&
                    menuItems.length > 0 && (
                        <ThemeNestedMenu
                            menuItems={menuItems}
                            buttonClass={classes.additionalMenuButton}
                            buttonIcon={<MenuIcon className={classes.menuIcon} />}
                        />
                    )}
            </Grid>
            {isLoadingDetails && <ThemeLoader />}
            {isDetailDataEmpty && !isLoadingDetails && (
                <ListItem>
                    <ListItemText data-title>
                        {t('contract-details-view:noContractDetails')}
                    </ListItemText>
                </ListItem>
            )}
            {!isLoadingDetails && detailsData && !isDetailDataEmpty && (
                <>
                    <List
                        className={contractDetailsViewDataClasses.list}
                        style={{ width: '100%' }}
                    >
                        <ListItem className={geschClasses.subtitle}>
                            <ListItemText data-title>
                                {t('contract-details-view:contractDetails.ecElementary')}
                            </ListItemText>
                        </ListItem>
                    </List>
                    <List
                        className={contractDetailsViewDataClasses.list}
                        style={{ width: '50%' }}
                    >
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.year'
                            value={t('formatted-values:formattedDate', {
                                date: detailsData?.year,
                            })}
                            hidden={!detailsData?.year}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.maliciousDamage'
                            value={
                                detailsData?.maliciousDamage
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            hidden={detailsData?.maliciousDamage}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.vehicleImpactAndSmoke'
                            value={
                                detailsData?.vehicleImpactAndSmoke
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            hidden={detailsData?.vehicleImpactAndSmoke}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.sprinklerSystem'
                            value={
                                detailsData?.sprinklerSystem
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            hidden={detailsData?.vehicleImpactAndSmoke}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.tapWater'
                            value={
                                detailsData?.tapWater ? t('common:yes') : t('common:no')
                            }
                            hidden={detailsData?.tapWater}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.tapWater'
                            value={
                                detailsData?.tapWater ? t('common:yes') : t('common:no')
                            }
                            hidden={detailsData?.tapWater}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.storm'
                            value={detailsData?.storm ? t('common:yes') : t('common:no')}
                            hidden={detailsData?.storm}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.hail'
                            value={detailsData?.hail ? t('common:yes') : t('common:no')}
                            hidden={detailsData?.hail}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.floodingOfInsuredLocation'
                            value={
                                detailsData?.floodingOfInsuredLocation
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            hidden={
                                typeof detailsData?.floodingOfInsuredLocation !==
                                'boolean'
                            }
                        />
                    </List>
                    <List
                        className={contractDetailsViewDataClasses.list}
                        style={{ width: '50%' }}
                    >
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.earthquake'
                            value={
                                detailsData?.earthquake ? t('common:yes') : t('common:no')
                            }
                            hidden={detailsData?.earthquake}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.subsidenceOrLandslide'
                            value={
                                detailsData?.subsidenceOrLandslide
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            hidden={detailsData?.subsidenceOrLandslide}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.snowPressureOrAvalanches'
                            value={
                                detailsData?.snowPressureOrAvalanches
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            hidden={detailsData?.snowPressureOrAvalanches}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.volcanicEruption'
                            value={
                                detailsData?.volcanicEruption
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            hidden={detailsData?.volcanicEruption}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.backwater'
                            value={
                                detailsData?.backwater ? t('common:yes') : t('common:no')
                            }
                            hidden={detailsData?.backwater}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.graffiti'
                            value={
                                detailsData?.graffiti ? t('common:yes') : t('common:no')
                            }
                            hidden={detailsData?.graffiti}
                        />
                    </List>
                </>
            )}
        </Grid>
    );
};
