import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useDarkMode from 'use-dark-mode';
import { BrowserRouter } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import moment from 'moment';
import { QueryClient, QueryClientProvider } from 'react-query';

import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { AbilityContextProvider } from './roleAccesses';

import {
    GlobalFilterProvider,
    ContractListSettingsProvider,
    DamagesListSettingsProvider,
    DashboardContextProvider,
    CustomersListSettingsProvider,
    NotificationCenterProvider,
} from './shared/contexts';
import { AuthProvider } from './user-management/context-auth';
import { useDocumentParameters, useInIframe } from './shared/hooks';

import { ConnectivityIndicatorInfrastructure } from './lib/connectivity-indicator/connectivity-indicator-infrastructure';
import { HttpInfrastructure } from './lib/http-client/http-infrastructure';
import ErrorBoundary from './lib/error-boundary';

import { themes } from './theming';

import { RoutedContent } from './routing';

import 'moment/locale/de';
import { NivoThemeProvider } from './theming/theme-nivo/nivo-theme-provider';

const queryClient = new QueryClient();

export default function App(): JSX.Element {
    const darkMode = useDarkMode(true);
    const inIFrame = useInIframe();
    const { i18n } = useTranslation(['common']);

    useDocumentParameters();

    const currentTheme = useMemo(() => {
        if (inIFrame) {
            return themes.lightTheme;
        }
        return darkMode.value ? themes.darkTheme : themes.lightTheme;
    }, [darkMode, inIFrame]);

    useEffect(() => {
        moment.locale(i18n.language);
    }, [i18n.language]);

    return (
        <BrowserRouter>
            <ErrorBoundary>
                <QueryClientProvider client={queryClient}>
                    <ConnectivityIndicatorInfrastructure>
                        <HttpInfrastructure>
                            <ThemeProvider theme={currentTheme}>
                                <NivoThemeProvider theme={currentTheme}>
                                    <CssBaseline />
                                    <SnackbarProvider
                                        style={{ maxWidth: 500 }}
                                        anchorOrigin={{
                                            horizontal: 'right',
                                            vertical: 'bottom',
                                        }}
                                    >
                                        <AuthProvider>
                                            <AbilityContextProvider>
                                                <GlobalFilterProvider>
                                                    <MuiPickersUtilsProvider
                                                        utils={MomentUtils}
                                                        locale={i18n.language}
                                                    >
                                                        <ContractListSettingsProvider>
                                                            <DamagesListSettingsProvider>
                                                                <DashboardContextProvider>
                                                                    <CustomersListSettingsProvider>
                                                                        <NotificationCenterProvider>
                                                                            <RoutedContent
                                                                                darkMode={
                                                                                    darkMode
                                                                                }
                                                                            />
                                                                        </NotificationCenterProvider>
                                                                    </CustomersListSettingsProvider>
                                                                </DashboardContextProvider>
                                                            </DamagesListSettingsProvider>
                                                        </ContractListSettingsProvider>
                                                    </MuiPickersUtilsProvider>
                                                </GlobalFilterProvider>
                                            </AbilityContextProvider>
                                        </AuthProvider>
                                    </SnackbarProvider>
                                </NivoThemeProvider>
                            </ThemeProvider>
                        </HttpInfrastructure>
                    </ConnectivityIndicatorInfrastructure>
                </QueryClientProvider>
            </ErrorBoundary>
        </BrowserRouter>
    );
}
