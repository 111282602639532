import { TFunction } from 'i18next';
import { DamageReportDTO } from './damage-report-dto';

export const damageListDamageReportAdapter = (
    data: DamageReportDTO,
    t: TFunction
): DamageReportDTO => {
    const { fullyCoverage, partialCoverage, liability } = data;
    const formattedItem: DamageReportDTO = { ...data };
    if (typeof fullyCoverage === 'boolean') {
        formattedItem.fullyCoverage = (fullyCoverage ? t('yes') : t('no')) as string;
    }
    if (typeof partialCoverage === 'boolean') {
        formattedItem.partialCoverage = (partialCoverage ? t('yes') : t('no')) as string;
    }
    if (typeof liability === 'boolean') {
        formattedItem.liability = (liability ? t('yes') : t('no')) as string;
    }
    return formattedItem;
};
