import { makeStyles, Theme } from '@material-ui/core';

export const useStyle = makeStyles((theme: Theme) => {
    return {
        headerContainer: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        pdfContainer: {
            backgroundColor: 'white',
            alignContent: 'space-between',
        },
        divider: {
            borderTop: '1px solid black',
            marginTop: theme.spacing(3),
        },
        customerLogoContainer: {
            flexGrow: 1,
            minWidth: 100,
            maxWidth: 250,

            '& img': {
                maxWidth: '100%',
            },
        },
    };
});
