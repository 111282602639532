import * as yup from 'yup';

const checkCustomers = (customers): boolean => {
    if (customers.length > 0) {
        return Boolean(customers[0].name && customers[0].name?.length <= 20);
    }
    return true;
};

const checkPostCode = (postcode): boolean => {
    if (postcode) {
        return String(postcode).length === 5;
    }
    return false;
};

export const customerAddFormValidationSchema = yup.object().shape({
    customerName: yup.string().when('customers', {
        is: (val: string) => val.length === 0,
        then: () => {
            return yup.string().required().nullable();
        },
        otherwise: () => yup.string().nullable(),
    }),
    id: yup.string(),
    name: yup.string().required().nullable(),
    street: yup.string().required().nullable(),
    postCode: yup.string().test(checkPostCode).required().nullable(),
    city: yup.string().required().nullable(),
    countryCode: yup.string().required().nullable(),
    customers: yup.array().test(checkCustomers),
});
