import React, {
    useState,
    memo,
    useCallback,
    BaseSyntheticEvent,
    useMemo,
    useEffect,
} from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';

import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { FileObject } from 'material-ui-dropzone';

import { useGlobalFilter } from 'src/shared/contexts';
import { APP_ROUTES } from 'src/routing';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useQuery, useValidateContractByDate } from 'src/shared/hooks';
import { DamageReportGroups } from 'src/shared/constants';

import {
    DamageReportFormAONKompositUniversalTypes,
    DamageReportFormAONKompositUniversalMutation,
} from '../../damage-report-form-utils/damage-report-form-types/damage-report-form-AON-Komposit-types//damage-report-form-AON-Komposit-universal-types';
import { DamageReportFormGetRecordDTO } from '../../damage-report-form-utils/damage-report-form-types/damage-report-form-get-record-dto';
import { damageReportFormAONKompositUniversalDefaultState } from '../../damage-report-form-utils/damage-report-form-default-states/damage-report-form-AON-Komposit-default-state/damage-report-form-AON-default-state-universal';
import { generateDisabledInputs } from '../../damage-report-form-config/damage-report-form-AON-komposit-config/damage-report-form-config-AON-Komposit-transport';
import { DamageReportFormDeleteConfirmationModalWindow } from '../../damage-report-form-components/damage-report-form-delete-confirmation';
import { DamageReportFormView } from '../damage-report-form-view';
import { DamageReportFormConfigAonKompositUniversal } from '../../damage-report-form-config/damage-report-form-AON-komposit-config/damage-report-form-config-AON-komposit-universal';
import { damageReportFormGeneralAONKompositSchema } from '../../damage-report-form-utils/damage-report-form-schemas/damage-report-form-schemas-AON-Komposit/damage-report-form-general-AON-Komposit-schema';
import { damageReportFormUniversalGetAdapter } from '../../damage-report-form-utils/damage-report-form-request-body-adapters/damage-report-form-adapters-AON-Komposit/damage-report-form-universal-get-adapter';
import { damageReportFormUniversalMutateAdapter } from '../../damage-report-form-utils/damage-report-form-request-body-adapters/damage-report-form-adapters-AON-Komposit/damage-report-form-universal-mutate-adapter';
import { useDamageReportFormSaveReport } from '../../damage-report-form-hooks';

import { useStyle } from '../../damage-report-form-styles/damage-report-form-styles';
import { useStyle as useComponentsStyle } from '../../damage-report-form-components/damage-report-form-components-styled';

interface DamageReportFormONKompositUniversalProps {
    setDivision: (value: string) => void;
    hideButtons: boolean;
    data: DamageReportFormGetRecordDTO;
    divisionState?: string;
}

export const DamageReportFormUniversalDamageNotification = memo(
    (props: DamageReportFormONKompositUniversalProps): JSX.Element => {
        const { setDivision, hideButtons, data, divisionState } = props;

        const router = useHistory();
        const httpClient = useHttpClient();
        const { t } = useTranslation(['common', 'damages-report', 'errors']);
        const { enqueueSnackbar } = useSnackbar();
        const contractId = useQuery().get('contractId');
        const { id } = useParams<{ id?: string }>();
        const classes = useStyle();
        const componentClasses = useComponentsStyle();

        const { changeDivision } = useGlobalFilter();

        const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
        const [isSendingToAmsLoading, setSendingToAmsLoading] = useState(false);

        const {
            watch,
            setValue,
            control,
            handleSubmit,
            formState,
            reset,
            trigger,
            getValues,
        } = useForm<DamageReportFormAONKompositUniversalTypes>({
            mode: 'onChange',
            reValidateMode: 'onChange',
            defaultValues: {
                ...damageReportFormAONKompositUniversalDefaultState(contractId),
                division: divisionState,
            },
            resolver: yupResolver(damageReportFormGeneralAONKompositSchema),
        });

        const { fields: files, remove } = useFieldArray({ control, name: 'files' });
        const { fields: fileInfos } = useFieldArray({
            control,
            name: 'fileInfos',
        });

        const [
            division,
            dayOfDamage,
            timeOfDamage,
            risk,
            contractNumber,
            policyHolder,
            insuranceCompany,
            damage,
            damageDescription,
            rootCause,
        ] = watch([
            'division',
            'dayOfDamage',
            'timeOfDamage',
            'risk',
            'contractNumber',
            'policyHolder',
            'insuranceCompany',
            'damage',
            'damageDescription',
            'rootCause',
        ]);

        const {
            isLoading: isSaveLoading,
            setLoading: setSaveLoading,
            saveReport,
            handleSaveReport,
        } = useDamageReportFormSaveReport<
            DamageReportFormAONKompositUniversalTypes,
            DamageReportFormAONKompositUniversalMutation
        >({
            id,
            pathname: 'universal',
            data: { ...getValues(), files },
            adapter: damageReportFormUniversalMutateAdapter,
            division: divisionState,
        });

        const isContractValidByDate = useValidateContractByDate(dayOfDamage, contractId);
        const isDivisionFromDataIsFromState = data?.division === division;

        useEffect(() => {
            trigger();
        }, []);

        useEffect(() => {
            if (data) {
                reset(damageReportFormUniversalGetAdapter(data, divisionState));
                trigger();
            }
        }, [data]);

        useEffect(() => {
            setDivision(division);
        }, [division]);

        const handleChangeValue = useCallback(
            (e: BaseSyntheticEvent): void => {
                setValue(e.target.name, e.target.value);
                trigger();
            },
            [setValue]
        );

        const handleCancel = (): void => {
            history.back();
        };

        const handleReadOnlyFields = (data: {
            licenseNumber: string;
            contractNumber: string;
            policyHolder: string;
            insuranceCompany: string;
            contractId: string;
        }): void => {
            setValue('risk', data.licenseNumber);
            setValue('contractNumber', data.contractNumber);
            setValue('policyHolder', data.policyHolder);
            setValue('insuranceCompany', data.insuranceCompany);
            setValue('contractId', data.contractId);
            trigger();
        };

        const handleChangeDate = useCallback(
            (name, value: ParsableDate): void => {
                setValue(name, value);
                trigger();
            },
            [setValue]
        );

        const makeRequestForSavingDataToAms = async (
            data: DamageReportFormAONKompositUniversalTypes
        ): Promise<void | string> => {
            setSendingToAmsLoading(true);
            try {
                const { id: newId } = await saveReport(data);
                return httpClient
                    .post<Promise<void | string>>(
                        `damage-reports/${newId || id}/send-to-ams`
                    )
                    .catch(() => {
                        return Promise.reject(newId);
                    });
            } catch {
                return Promise.reject();
            }
        };

        const saveReportToAms = (): void => {
            const data: DamageReportFormAONKompositUniversalTypes = getValues();
            makeRequestForSavingDataToAms(data)
                .then(() => {
                    enqueueSnackbar(t(`damages-report:sentSuccessfully`), {
                        variant: 'success',
                    });
                    changeDivision(divisionState);
                    router.push(`${APP_ROUTES.DAMAGES}?filterValue=created`);
                })
                .catch((newId: string) => {
                    if (newId) {
                        router.push(`${APP_ROUTES.DAMAGE_REPORT}/${newId}`);
                    }
                    enqueueSnackbar(t(`errors:unknownError`), {
                        variant: 'error',
                    });
                })
                .finally(() => {
                    setSendingToAmsLoading(false);
                    setSaveLoading(false);
                });
        };

        const onSubmit = (): void => {
            saveReportToAms();
        };

        const handleAddFile = useCallback(
            (newFiles: FileObject[]): void => {
                setValue('files', [...files, ...newFiles]);
            },
            [setValue, files]
        );

        const handleDeleteFile = useCallback(
            (index: number): void => {
                remove(index);
            },
            [setValue]
        );

        const onDeleteFile = (id: string): void => {
            const newFiles = fileInfos.filter((file) => {
                return file.id !== id;
            });
            setValue('fileInfos', newFiles);
        };

        const headerConfig = useMemo(
            () =>
                generateDisabledInputs({
                    licenseNumber: isDivisionFromDataIsFromState || !id ? risk : '',
                    contractNumber:
                        isDivisionFromDataIsFromState || !id ? contractNumber : '',
                    policyHolder:
                        isDivisionFromDataIsFromState || !id ? policyHolder : '',
                    insuranceCompany:
                        isDivisionFromDataIsFromState || !id ? insuranceCompany : '',
                    errors: formState.errors,
                }),
            [
                risk,
                contractNumber,
                policyHolder,
                insuranceCompany,
                division,
                data?.division,
                formState.errors.contractNumber,
                formState.errors.insuranceCompany,
                formState.errors.policyHolder,
            ]
        );

        const configs = useMemo(
            () =>
                DamageReportFormConfigAonKompositUniversal({
                    division,
                    damage,
                    damageDescription,
                    rootCause,
                    fullWidthClass: componentClasses?.fullWidth,
                }),
            [damage, damageDescription, rootCause, division]
        );

        return (
            <>
                <DamageReportFormDeleteConfirmationModalWindow
                    id={id}
                    isOpen={isDeleteConfirmOpen}
                    onClose={() => setDeleteConfirmOpen(false)}
                />
                {
                    <DamageReportFormView
                        damageReportGroup={DamageReportGroups.UNIVERSAL}
                        setDeleteConfirmOpen={setDeleteConfirmOpen}
                        deleteText={t('damages-report:deleteReport')}
                        deleteButtonClass={classes.deleteButton}
                        hideButtons={hideButtons}
                        boxContainer={classes.boxContainer}
                        sectionContainer={classes.sectionContainer}
                        formState={formState}
                        handleChangeValue={handleChangeValue}
                        footerConfig={[]}
                        headerConfig={headerConfig}
                        configs={configs}
                        sendText={t('send')}
                        formBlockElementClass={classes.formBlockElement}
                        containerClassName={classes.formBlockContainer}
                        saveText={t('save')}
                        saveButtonClass={clsx(classes.button, classes.saveButton)}
                        sendButtonClass={clsx(classes.button, classes.sendButton)}
                        isSendDisabled={
                            !formState.isValid ||
                            isSendingToAmsLoading ||
                            isSaveLoading ||
                            (contractId && !isContractValidByDate)
                        }
                        isSendingToAmsLoading={isSendingToAmsLoading}
                        isSaveLoading={isSaveLoading}
                        dayOfDamage={dayOfDamage}
                        IsSaveDisabled={
                            isSaveLoading ||
                            isSendingToAmsLoading ||
                            !division ||
                            !dayOfDamage ||
                            !contractNumber ||
                            !insuranceCompany ||
                            !policyHolder ||
                            (contractId && !isContractValidByDate)
                        }
                        handleCancel={handleCancel}
                        cancelText={t('cancel')}
                        handleReadOnlyFields={handleReadOnlyFields}
                        timeOfDamage={timeOfDamage}
                        handleChangeDate={handleChangeDate}
                        division={division}
                        divisionFromRecord={data?.division}
                        mainFormContainer={classes.mainFormContainer}
                        isReceivingDataLoading={false}
                        handleSubmit={handleSubmit(onSubmit)}
                        footerClassName={clsx(
                            classes.buttonsContainer,
                            classes.formBlockContainer
                        )}
                        handleAddFile={handleAddFile}
                        files={files}
                        fileInfos={fileInfos}
                        onDeleteFile={onDeleteFile}
                        handleDeleteFile={handleDeleteFile}
                        handleSaveReport={handleSaveReport}
                    />
                }
            </>
        );
    }
);
