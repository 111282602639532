import React, { useMemo, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';

import { useNotificationCenterContext } from 'src/shared/contexts';
import { MessageDTO } from 'src/shared/contexts/notification-center-context/notification-center-message-dto';
import { useAuth } from 'src/user-management/context-auth';

import { NotificationCenterMessage } from './notification-center-message';

const sortByDate = (a: MessageDTO, b: MessageDTO): number =>
    moment(b.createdDate).valueOf() - moment(a.createdDate).valueOf();

export const NotificationSectionMessages = (): JSX.Element => {
    const { t } = useTranslation(['notification-center']);
    const { messages, notificationSection, getMessages } = useNotificationCenterContext();

    const {
        userData: {
            userInfo: { id },
        },
    } = useAuth();

    const currentMessages = useMemo(() => {
        return messages.sort(sortByDate);
    }, [notificationSection, messages]);

    useEffect(() => {
        getMessages(notificationSection, id);
    }, [notificationSection, id]);

    return (
        <>
            {!currentMessages?.length && (
                <Box p={4}>
                    <Typography variant='body2'>
                        {t('notification-center:noMessages')}
                    </Typography>
                </Box>
            )}
            {currentMessages?.length > 0 &&
                currentMessages.map((item) => (
                    <NotificationCenterMessage key={item.id} {...item} />
                ))}
        </>
    );
};

NotificationSectionMessages.defaultProps = {
    messages: [],
};
