import React, {
    BaseSyntheticEvent,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { FileObject } from 'material-ui-dropzone';
import uniqid from 'uniqid';
import { yupResolver } from '@hookform/resolvers/yup';

import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { APP_ROUTES } from 'src/routing';
import { useGlobalFilter } from 'src/shared/contexts';
import { useQuery, useValidateContractByDate } from 'src/shared/hooks';
import { DamageReportGroups } from 'src/shared/constants';

import { DamageReportFormDeleteConfirmationModalWindow } from '../../damage-report-form-components/damage-report-form-delete-confirmation';
import { generateDisabledInputs } from '../../damage-report-form-config/damage-report-form-config-travel';
import { DamageReportFormGetRecordDTO } from '../../damage-report-form-utils/damage-report-form-types/damage-report-form-get-record-dto';
import {
    DamageReportFormPropertyTypes,
    DamageReportFormPropertyMutateDTO,
} from '../../damage-report-form-utils/damage-report-form-types/damage-report-form-AON-Komposit-types/damage-report-form-AON-Komposit-property-types';
import { DamageReportFormView } from '../damage-report-form-view';
import { damageReportFormGlasHrGeschDefaultState } from '../../damage-report-form-utils/damage-report-form-default-states/damage-report-form-AON-Komposit-default-state/damage-report-form-glas-hr-gesch-default-state';
import { DamageReportFormConfigTravel } from '../../damage-report-form-config/damage-report-form-config-travel';
import { useStyle } from '../../damage-report-form-styles/damage-report-form-styles';
import { damageReportFormGenerateTravelDamageTypeOptions } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-generate-travel-options';
import { damageReportFormGeneralAONKompositSchema } from '../../damage-report-form-utils/damage-report-form-schemas/damage-report-form-schemas-AON-Komposit/damage-report-form-general-AON-Komposit-schema';
import { damageReportFormPropertyMutateAdapter } from '../../damage-report-form-utils/damage-report-form-request-body-adapters/damage-report-form-adapters-AON-Komposit/damage-report-form-property-mutate-adapter';
import { damageReportFormPropertyGetAdapter } from '../../damage-report-form-utils/damage-report-form-request-body-adapters/damage-report-form-adapters-AON-Komposit/damage-report-form-property-get-adapter';
import { useDamageReportFormSaveReport } from '../../damage-report-form-hooks';

interface DamageReportFormGlasHrGeschProps {
    setDivision: (value: string) => void;
    hideButtons: boolean;
    data: DamageReportFormGetRecordDTO;
    divisionState?: string;
}

export const DamageReportFormGlasHrGesch = memo(
    (props: DamageReportFormGlasHrGeschProps) => {
        const { setDivision, hideButtons, data, divisionState } = props;

        const { t } = useTranslation(['common', 'damages-report', 'errors']);
        const contractId = useQuery().get('contractId');

        const {
            watch,
            setValue,
            control,
            handleSubmit,
            formState,
            reset,
            trigger,
            getValues,
        } = useForm<DamageReportFormPropertyTypes>({
            mode: 'onChange',
            reValidateMode: 'onChange',
            resolver: yupResolver(damageReportFormGeneralAONKompositSchema),
            defaultValues: {
                ...damageReportFormGlasHrGeschDefaultState(contractId),
                division: divisionState,
            },
        });

        const { changeDivision } = useGlobalFilter();

        const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);

        const { id } = useParams<{ id?: string }>();
        const classes = useStyle();
        const httpClient = useHttpClient();
        const router = useHistory();

        const [isSendingToAmsLoading, setSendingToAmsLoading] = useState(false);
        const { enqueueSnackbar } = useSnackbar();

        const { fields: itemsAffectedByTheDamageFields, prepend: prependRow } =
            useFieldArray({
                control,
                name: 'itemsAffectedByTheDamage',
            });

        const { fields: files, remove } = useFieldArray({ control, name: 'files' });

        const { fields: fileInfos } = useFieldArray({
            control,
            name: 'fileInfos',
        });

        const [
            division,
            dayOfDamage,
            timeOfDamage,
            risk,
            contractNumber,
            policyHolder,
            insuranceCompany,
            fileNumberInsuredCompany,
            inputTaxDeductionForTheDamage,
            damageBeenReportedToThePolice,
            existenceForItemAffectedByTheDamage,
            insurer,
            areBusinessInterruptionLossesToBeExpected,
            policeStation,
            fileNumber,
            typeOfDamage,
            causedByThirdParty,
        ] = watch([
            'division',
            'dayOfDamage',
            'timeOfDamage',
            'risk',
            'contractNumber',
            'policyHolder',
            'insuranceCompany',
            'fileNumberInsuredCompany',
            'inputTaxDeductionForTheDamage',
            'damageBeenReportedToThePolice',
            'existenceForItemAffectedByTheDamage',
            'insurer',
            'areBusinessInterruptionLossesToBeExpected',
            'policeStation',
            'fileNumber',
            'typeOfDamage',
            'causedByThirdParty',
        ]);

        const {
            isLoading: isSaveLoading,
            setLoading: setSaveLoading,
            saveReport,
            handleSaveReport,
        } = useDamageReportFormSaveReport<
            DamageReportFormPropertyTypes,
            DamageReportFormPropertyMutateDTO
        >({
            id,
            pathname: 'property',
            data: { ...getValues(), files },
            adapter: damageReportFormPropertyMutateAdapter,
            division: divisionState,
        });

        const isContractValidByDate = useValidateContractByDate(dayOfDamage, contractId);

        useEffect(() => {
            trigger();
        }, []);

        useEffect(() => {
            if (data) {
                reset(damageReportFormPropertyGetAdapter(data, divisionState));
                trigger();
            }
        }, [data]);

        useEffect(() => {
            setDivision(division);
        }, [division]);

        const handleChangeValue = useCallback(
            (e: BaseSyntheticEvent): void => {
                setValue(e.target.name, e.target.value);
                trigger();
            },
            [setValue]
        );

        const onDeleteFile = (id: string): void => {
            const newFiles = fileInfos.filter((file) => {
                return file.id !== id;
            });
            setValue('fileInfos', newFiles);
        };

        const makeRequestForSavingDataToAms = async (
            data: DamageReportFormPropertyTypes
        ): Promise<void | string> => {
            setSendingToAmsLoading(true);
            try {
                const { id: newId } = await saveReport(data);
                return httpClient
                    .post<Promise<void | string>>(
                        `damage-reports/${newId || id}/send-to-ams`
                    )
                    .catch(() => {
                        return Promise.reject(newId);
                    });
            } catch {
                return Promise.reject();
            }
        };

        const saveReportToAms = (): void => {
            const data: DamageReportFormPropertyTypes = getValues();
            makeRequestForSavingDataToAms(data)
                .then(() => {
                    enqueueSnackbar(t(`damages-report:sentSuccessfully`), {
                        variant: 'success',
                    });
                    changeDivision(divisionState);
                    router.push(`${APP_ROUTES.DAMAGES}?filterValue=created`);
                })
                .catch((newId: string) => {
                    if (newId) {
                        router.push(`${APP_ROUTES.DAMAGE_REPORT}/${newId}`);
                    }
                    enqueueSnackbar(t(`errors:unknownError`), {
                        variant: 'error',
                    });
                })
                .finally(() => {
                    setSendingToAmsLoading(false);
                    setSaveLoading(false);
                });
        };

        const handleAddRow = useCallback((): void => {
            const newRow = {
                id: uniqid(),
                numberAndTypeOfThings: '',
                replacementPrice: null,
                repairCosts: null,
            };
            prependRow(newRow);
        }, [itemsAffectedByTheDamageFields?.length]);

        const handleDeleteRow = useCallback(
            (id: string): void => {
                const filteredRows = itemsAffectedByTheDamageFields?.filter(
                    (item) => item.id !== id
                );
                setValue('itemsAffectedByTheDamage', filteredRows);
            },
            [itemsAffectedByTheDamageFields]
        );

        const handleCancel = (): void => {
            history.back();
        };

        const handleReadOnlyFields = (data: {
            licenseNumber: string;
            contractNumber: string;
            policyHolder: string;
            insuranceCompany: string;
            contractId: string;
        }): void => {
            setValue('risk', data.licenseNumber);
            setValue('contractNumber', data.contractNumber);
            setValue('policyHolder', data.policyHolder);
            setValue('insuranceCompany', data.insuranceCompany);
            setValue('contractId', data.contractId);
            trigger();
        };

        const handleChangeDate = useCallback(
            (name, value: ParsableDate): void => {
                setValue(name, value);
                trigger();
            },
            [setValue]
        );

        const handleAddFile = useCallback(
            (newFiles: FileObject[]): void => {
                setValue('files', [...files, ...newFiles]);
            },
            [setValue, files]
        );

        const handleDeleteFile = useCallback(
            (index: number): void => {
                remove(index);
            },
            [setValue]
        );

        const onSubmit = (): void => {
            saveReportToAms();
        };

        const handleChangeSwitcher = useCallback(
            (name, value: boolean): void => {
                setValue(name, value);
                trigger();
            },

            [setValue]
        );

        const handleOnChangeRow = useCallback(
            (id, row) => {
                const newRows = itemsAffectedByTheDamageFields?.map((item) => {
                    if (item.id === id) {
                        return { ...row };
                    }
                    return item;
                });
                setValue('itemsAffectedByTheDamage', newRows);
            },
            [itemsAffectedByTheDamageFields]
        );

        const isDivisionFromDataIsFromState = data?.division === division;

        const headerConfig = useMemo(
            () =>
                generateDisabledInputs({
                    licenseNumber: isDivisionFromDataIsFromState || !id ? risk : '',
                    contractNumber:
                        isDivisionFromDataIsFromState || !id ? contractNumber : '',
                    policyHolder:
                        isDivisionFromDataIsFromState || !id ? policyHolder : '',
                    insuranceCompany:
                        isDivisionFromDataIsFromState || !id ? insuranceCompany : '',
                    errors: formState.errors,
                }),
            [
                risk,
                contractNumber,
                policyHolder,
                insuranceCompany,
                division,
                data?.division,
                formState.errors.contractNumber,
                formState.errors.insuranceCompany,
                formState.errors.policyHolder,
            ]
        );

        const damageTypeOptions = useMemo(
            () => damageReportFormGenerateTravelDamageTypeOptions(t),
            [t]
        );

        const configs = useMemo(
            () =>
                DamageReportFormConfigTravel({
                    fileNumberInsuredCompany,
                    inputTaxDeductionForTheDamage,
                    damageBeenReportedToThePolice,
                    existenceForItemAffectedByTheDamage,
                    insurer,
                    areBusinessInterruptionLossesToBeExpected,
                    policeStation,
                    fileNumber,
                    damageTypeOptions,
                    typeOfDamage,
                    causedByThirdParty,
                    handleChangeSwitcher,
                    itemsAffectedByTheDamageFields,
                    handleAddRow,
                    handleDeleteRow,
                    handleOnChangeRow,
                }),
            [
                fileNumberInsuredCompany,
                inputTaxDeductionForTheDamage,
                damageBeenReportedToThePolice,
                existenceForItemAffectedByTheDamage,
                insurer,
                areBusinessInterruptionLossesToBeExpected,
                policeStation,
                fileNumber,
                damageTypeOptions,
                typeOfDamage,
                causedByThirdParty,
                handleChangeSwitcher,
                itemsAffectedByTheDamageFields,
                handleAddRow,
                handleDeleteRow,
                handleOnChangeRow,
            ]
        );

        return (
            <>
                <DamageReportFormDeleteConfirmationModalWindow
                    id={id}
                    isOpen={isDeleteConfirmOpen}
                    onClose={() => setDeleteConfirmOpen(false)}
                />
                {
                    <DamageReportFormView
                        damageReportGroup={DamageReportGroups.PROPERTY_INSURANCE}
                        setDeleteConfirmOpen={setDeleteConfirmOpen}
                        deleteText={t('damages-report:deleteReport')}
                        deleteButtonClass={classes.deleteButton}
                        hideButtons={hideButtons}
                        boxContainer={classes.boxContainer}
                        sectionContainer={classes.sectionContainer}
                        formState={formState}
                        handleChangeValue={handleChangeValue}
                        footerConfig={[]}
                        headerConfig={headerConfig}
                        configs={configs}
                        sendText={t('send')}
                        formBlockElementClass={classes.formBlockElement}
                        containerClassName={classes.formBlockContainer}
                        saveText={t('save')}
                        saveButtonClass={clsx(classes.button, classes.saveButton)}
                        sendButtonClass={clsx(classes.button, classes.sendButton)}
                        isSendDisabled={
                            !formState.isValid ||
                            isSendingToAmsLoading ||
                            isSaveLoading ||
                            (contractId && !isContractValidByDate)
                        }
                        isSendingToAmsLoading={isSendingToAmsLoading}
                        isSaveLoading={isSaveLoading}
                        dayOfDamage={dayOfDamage}
                        IsSaveDisabled={
                            isSaveLoading ||
                            isSendingToAmsLoading ||
                            !division ||
                            !dayOfDamage ||
                            !contractNumber ||
                            !insuranceCompany ||
                            !policyHolder ||
                            (contractId && !isContractValidByDate)
                        }
                        handleCancel={handleCancel}
                        cancelText={t('cancel')}
                        handleReadOnlyFields={handleReadOnlyFields}
                        timeOfDamage={timeOfDamage}
                        handleChangeDate={handleChangeDate}
                        division={division}
                        divisionFromRecord={data?.division}
                        mainFormContainer={classes.mainFormContainer}
                        isReceivingDataLoading={false}
                        handleSubmit={handleSubmit(onSubmit)}
                        footerClassName={clsx(
                            classes.buttonsContainer,
                            classes.formBlockContainer
                        )}
                        handleAddFile={handleAddFile}
                        files={files}
                        fileInfos={fileInfos}
                        onDeleteFile={onDeleteFile}
                        handleDeleteFile={handleDeleteFile}
                        handleSaveReport={handleSaveReport}
                    />
                }
            </>
        );
    }
);
