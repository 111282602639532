import { TFunction } from 'i18next';
import { SelectItem } from 'src/theming';

export enum ARIA_OF_LIABILITY_OPTIONS {
    HGB = 'HGB',
    CMR = 'CMR',
    OTHER = 'other',
}

export const damageReportFormGenerateAriaLiabilityOptions = (
    t: TFunction
): SelectItem[] => {
    return [
        {
            title: t('damages-report:noChoice'),
            value: 'No choice',
        },
        {
            title: t('damages-report:liabilityAreaOptions.hgb'),
            value: ARIA_OF_LIABILITY_OPTIONS.HGB,
        },
        {
            title: t('damages-report:liabilityAreaOptions.cmr'),
            value: ARIA_OF_LIABILITY_OPTIONS.CMR,
        },
        {
            title: t('damages-report:liabilityAreaOptions.other'),
            value: ARIA_OF_LIABILITY_OPTIONS.OTHER,
        },
    ];
};
