import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { Grid, List, ListItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { ThemeNestedMenu, ThemeLoader } from 'src/theming';

import { useAbilityCtx } from 'src/roleAccesses';

import {
    useStyle,
    useContractDetailsViewDataStyle,
} from '../contracts-details-view-styles';

import { ContractDetailsListItem } from '../contract-details-list-item';

import { ContractDetailsViewDreiseDTO } from './contract-details-view-dreise-dto';

import { generateContractsDetailsAdditionalMenu } from '../contract-details-view-configs';

interface ContractDetailsViewDREISEProps {
    handleOpenContractEdit: () => void;
    hideThemeNestedMenu: boolean;
    contractNumber: string;
}

export const ContractDetailsViewDREISE = (
    props: ContractDetailsViewDREISEProps
): JSX.Element => {
    const classes = useStyle();
    const contractDetailsViewDataClasses = useContractDetailsViewDataStyle();

    const { t } = useTranslation([
        'contract-details-view',
        'formatted-values',
        'damages-list',
        'common',
    ]);

    const ability = useAbilityCtx();
    const router = useHistory();
    const { id } = useParams<{ id: string }>();
    const httpClient = useHttpClient();

    const { handleOpenContractEdit, hideThemeNestedMenu, contractNumber } = props;

    const [isLoadingDetails, setLoadingDetails] = useState(false);
    const [detailsData, setDetailsData] = useState<ContractDetailsViewDreiseDTO>();

    const makeRequestForDetails = async (): Promise<ContractDetailsViewDreiseDTO> => {
        setLoadingDetails(true);
        return httpClient.get<ContractDetailsViewDreiseDTO>(`contracts/${id}/dreise`);
    };

    useEffect(() => {
        makeRequestForDetails()
            .then((data: ContractDetailsViewDreiseDTO) => {
                setDetailsData(data);
            })
            .finally(() => {
                setLoadingDetails(false);
            });
    }, []);

    const goTo = (pathname: string): void => {
        router.push(pathname);
    };

    const menuItems = useMemo(
        () =>
            generateContractsDetailsAdditionalMenu(
                t,
                null,
                handleOpenContractEdit,
                goTo,
                ability,
                id,
                contractNumber
            ),
        [t, contractNumber, ability]
    );

    const isDetailDataEmpty = useMemo(() => {
        if (detailsData) {
            const detailsDataKeys = Object.keys(detailsData);
            return !detailsDataKeys
                ?.map((x) => detailsData[x])
                ?.filter((x) => x || typeof x === 'boolean')?.length;
        }
        return true;
    }, [detailsData]);

    return (
        <Grid container>
            <Grid container className={classes.titleAndMenuContainer}>
                <div style={{ flexGrow: 1 }} />
                {!hideThemeNestedMenu &&
                    Array.isArray(menuItems) &&
                    menuItems.length > 0 && (
                        <ThemeNestedMenu
                            menuItems={menuItems}
                            buttonClass={classes.additionalMenuButton}
                            buttonIcon={<MenuIcon className={classes.menuIcon} />}
                        />
                    )}
            </Grid>
            {isLoadingDetails && <ThemeLoader />}
            {isDetailDataEmpty && !isLoadingDetails && (
                <ListItem>
                    <ListItemText data-title>
                        {t('contract-details-view:noContractDetails')}
                    </ListItemText>
                </ListItem>
            )}
            {detailsData && !isLoadingDetails && !isDetailDataEmpty && (
                <List className={contractDetailsViewDataClasses.list}>
                    <ContractDetailsListItem
                        value={detailsData?.insuredGroupOfPersons}
                        hidden={!detailsData?.insuredGroupOfPersons}
                        title='contract-details-view:contractDetails.insuredGroupOfPersons'
                    />
                    <ContractDetailsListItem
                        value={detailsData?.scope}
                        hidden={!detailsData?.scope}
                        title='contract-details-view:contractDetails.scope'
                    />
                    <ContractDetailsListItem
                        value={t('formatted-values:formattedCurrency', {
                            currency: detailsData?.fullyComprehensive,
                        })}
                        hidden={
                            detailsData?.fullyComprehensive === null ||
                            detailsData?.fullyComprehensive === 0
                        }
                        title='contract-details-view:contractDetails.fullyComprehensiveSBInEUR'
                    />
                    <ContractDetailsListItem
                        value={t('formatted-values:formattedCurrency', {
                            currency: detailsData.partialCoverage,
                        })}
                        hidden={
                            detailsData?.partialCoverage === null ||
                            detailsData?.partialCoverage === 0
                        }
                        title='contract-details-view:contractDetails.partialCoverageSumInEUR'
                    />
                    <ContractDetailsListItem
                        value={
                            detailsData?.sfrLossInsurance
                                ? t('common:yes')
                                : t('common:no')
                        }
                        hidden={!detailsData?.sfrLossInsurance}
                        title='contract-details-view:contractDetails.sfrLossInsurance'
                    />
                    <ContractDetailsListItem
                        value={
                            detailsData?.businessTravelVehicleLiability
                                ? t('common:yes')
                                : t('common:no')
                        }
                        hidden={
                            typeof detailsData?.businessTravelVehicleLiability !==
                            'boolean'
                        }
                        title='contract-details-view:contractDetails.businessTravelVehicleLiability'
                    />
                </List>
            )}
        </Grid>
    );
};
