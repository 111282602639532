export enum LIGHT_HECTOR_THEME_COLORS {
    PRIMARY_MAIN_COLOR = '#F9F9FB',
    PRIMARY_LIGHT_COLOR = '#FFFFFF',
    PRIMARY_DARK_COLOR = '#F2F0F4',
    PRIMARY_CONTRAST_TEXT = '#5B5B5B',
    SECONDARY_MAIN_COLOR = '#734FCE',
    SECONDARY_LIGHT_COLOR = '#F5F2FF',
    SECONDARY_DARK_COLOR = '#4D388C',
    SECONDARY_CONTRAST_TEXT = '#FFFFFF',
    INFO_MAIN_COLOR = '#734FCE',
    INFO_LIGHT_COLOR = '#F5F2FF',
    INFO_CONTRAST_TEXT = '#FFFFFF',
    ERROR_MAIN_COLOR = '#CB362D',
    ERROR_LIGHT_COLOR = 'rgba(203, 54, 45, 0.1)',
}

export enum DARK_HECTOR_THEME_COLORS {
    PRIMARY_MAIN_COLOR = '#1A1C28',
    PRIMARY_LIGHT_COLOR = '#3E3E3E',
    PRIMARY_DARK_COLOR = '#212121',
    PRIMARY_CONTRAST_TEXT = '#FFFFFF',
    SECONDARY_MAIN_COLOR = '#302356',
    SECONDARY_LIGHT_COLOR = '#1F1442',
    SECONDARY_DARK_COLOR = '#150D2E',
    SECONDARY_CONTRAST_TEXT = '#FFFFFF',
    INFO_MAIN_COLOR = '#734FCE',
    INFO_LIGHT_COLOR = '#F5F2FF',
    INFO_CONTRAST_TEXT = '#FFFFFF',
    ERROR_MAIN_COLOR = '#CB362D',
    ERROR_LIGHT_COLOR = 'rgba(203, 54, 45, 0.1)',
}
