import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Grid, Tooltip } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DraftsIcon from '@material-ui/icons/Drafts';
import ArchiveIcon from '@material-ui/icons/Archive';
import MarkunreadIcon from '@material-ui/icons/Markunread';

import { useNotificationCenterContext } from 'src/shared/contexts';
import { useAuth } from 'src/user-management/context-auth';

import { useUpdateMessage } from './notification-center-use-update-message';
import { useDeleteMessage } from './notification-center-use-delete-message';
import {
    NotificationCenterSections,
    NotificationCenterMessageStatuses,
    NotificationCenterMessageTypes,
} from '../shared/contexts/notification-center-context/notification-center-enums';

interface Props {
    id: string;
    messageType: string;
}

export const NotificationCenterMessageActions = (props: Props): JSX.Element => {
    const { t } = useTranslation(['notification-center']);
    const { id, messageType } = props;

    const { notificationSection, getMessages, updateNumberOfUnreadMessages } =
        useNotificationCenterContext();

    const {
        userData: {
            userInfo: { id: userId },
        },
    } = useAuth();

    const onSuccess = (): void => {
        getMessages(notificationSection, userId);
    };

    const { isLoading: isUpdateLoading, updateMessage } = useUpdateMessage(onSuccess);
    const { isLoading: isDeleteLoading, deleteMessage } = useDeleteMessage(onSuccess);

    const onDelete = async (): Promise<void> => {
        await deleteMessage(id);
        updateNumberOfUnreadMessages();
    };

    const onRead = async (): Promise<void> => {
        await updateMessage(id, NotificationCenterMessageStatuses.READ);
        updateNumberOfUnreadMessages();
    };

    const onUnread = async (): Promise<void> => {
        await updateMessage(id, NotificationCenterMessageStatuses.UNREAD);
        updateNumberOfUnreadMessages();
    };

    const onArchive = async (): Promise<void> => {
        await updateMessage(id, NotificationCenterMessageStatuses.ARCHIVED);
        updateNumberOfUnreadMessages();
    };

    return (
        <Grid container justify='flex-end'>
            <Grid item md={4}>
                <IconButton
                    disabled={isUpdateLoading || isDeleteLoading}
                    size='small'
                    onClick={onDelete}
                >
                    <Tooltip title={t('delete')}>
                        <DeleteForeverIcon />
                    </Tooltip>
                </IconButton>
            </Grid>

            {notificationSection === NotificationCenterSections.UNREAD_MESSAGES && (
                <Grid item md={4}>
                    <IconButton
                        disabled={isUpdateLoading || isDeleteLoading}
                        size='small'
                        onClick={onRead}
                    >
                        <Tooltip title={t('markAsRead')}>
                            <DraftsIcon />
                        </Tooltip>
                    </IconButton>
                </Grid>
            )}
            {(notificationSection === NotificationCenterSections.READ_MESSAGES ||
                notificationSection === NotificationCenterSections.ARCHIVED_MESSAGES) &&
                messageType !== NotificationCenterMessageTypes.OWN_MESSAGE && (
                    <Grid item md={4}>
                        <IconButton
                            disabled={isUpdateLoading || isDeleteLoading}
                            size='small'
                            onClick={onUnread}
                        >
                            <Tooltip title={t('markAsUnread')}>
                                <MarkunreadIcon />
                            </Tooltip>
                        </IconButton>
                    </Grid>
                )}

            <Grid item md={4}>
                {(notificationSection === NotificationCenterSections.UNREAD_MESSAGES ||
                    notificationSection === NotificationCenterSections.READ_MESSAGES) && (
                    <IconButton
                        disabled={isUpdateLoading || isDeleteLoading}
                        size='small'
                        onClick={onArchive}
                    >
                        <Tooltip title={t('markAsArchived')}>
                            <ArchiveIcon />
                        </Tooltip>
                    </IconButton>
                )}
                {notificationSection === NotificationCenterSections.ARCHIVED_MESSAGES && (
                    <>
                        <IconButton
                            disabled={isUpdateLoading || isDeleteLoading}
                            size='small'
                            onClick={onRead}
                        >
                            <Tooltip title={t('markAsRead')}>
                                <DraftsIcon />
                            </Tooltip>
                        </IconButton>
                    </>
                )}
            </Grid>
        </Grid>
    );
};
