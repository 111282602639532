import React from 'react';
import { MenuItem } from 'src/theming/theme-nested-menu/theme-nested-menu';
import { TFunction } from 'i18next';
import { AnyAbility } from '@casl/ability';

import { DataBasesDto } from 'src/shared/hooks';
import { LANGUAGES } from 'src/shared/constants';
import { AbilityActions, AbilitySubjects } from 'src/roleAccesses';

import StarRateIcon from '@material-ui/icons/StarRate';

import { APP_ROUTES } from 'src/routing';

const getFAQMenuItem = (
    t: TFunction,
    currentPath: string,
    homePath: string,
    ability: AnyAbility
): { title: string; path: string } => {
    let title: string;
    let path: string;
    if (
        currentPath !== APP_ROUTES.FAQ ||
        ability.can(AbilityActions.see, AbilitySubjects.dashboard)
    ) {
        title =
            currentPath !== APP_ROUTES.FAQ
                ? t('profileMenu.faq')
                : t('profileMenu.mainPortal');
        path = currentPath === APP_ROUTES.FAQ ? APP_ROUTES.HOME : APP_ROUTES.FAQ;
    } else {
        title = t('profileMenu.administration');
        path = homePath;
    }
    return {
        title,
        path,
    };
};

export const generateThemeHeaderProfileMenuConfig = (
    t: TFunction,
    handleGoTo: (path: string) => void,
    handleLogout: () => void,
    changeLanguage: (path: string) => void,
    currentPath: string,
    ability: AnyAbility,
    homePath: string,
    changeDataBase: (favouriteDatabase: string, currentDatabase: string) => void,
    dataBases: DataBasesDto[],
    favouriteDatabase: string,
    currentDatabase: string,
    updateDashboardAction: () => void
): MenuItem[] => {
    let profileMenu = [];
    if (ability.can(AbilityActions.update, AbilitySubjects.dashboardData)) {
        profileMenu.push({
            title: t('profileMenu.updateDashboardData'),
            action: updateDashboardAction,
        });
    }

    if (
        ability.can(AbilityActions.see, AbilitySubjects.userManagement) &&
        ability.can(AbilityActions.see, AbilitySubjects.dashboard)
    ) {
        profileMenu.push({
            title:
                currentPath !== APP_ROUTES.USER_MANAGEMENT &&
                currentPath !== APP_ROUTES.PRIVILEGES &&
                currentPath !== APP_ROUTES.MASTER_DATA &&
                currentPath !== APP_ROUTES.NOTIFICATIONS
                    ? t('profileMenu.administration')
                    : t('profileMenu.mainPortal'),
            action: () =>
                handleGoTo(
                    currentPath !== APP_ROUTES.USER_MANAGEMENT &&
                        currentPath !== APP_ROUTES.PRIVILEGES &&
                        currentPath !== APP_ROUTES.MASTER_DATA &&
                        currentPath !== APP_ROUTES.NOTIFICATIONS
                        ? APP_ROUTES.USER_MANAGEMENT
                        : homePath
                ),
        });
    }
    if (
        ability.can(AbilityActions.change, AbilitySubjects.dataBase) &&
        dataBases.length > 1
    ) {
        profileMenu.push({
            title: t('profileMenu.database'),
            nestedMenu: dataBases?.map((item) => {
                return {
                    title: item.name,
                    action: () => changeDataBase(favouriteDatabase, item.name),
                    icon: <StarRateIcon />,
                    isAdditionalActionActive: favouriteDatabase === item.name,
                    isActive: currentDatabase === item.name,
                    additionalAction: () => changeDataBase(item.name, currentDatabase),
                };
            }),
        });
    }
    const faqMenuItem = getFAQMenuItem(t, currentPath, homePath, ability);
    profileMenu = [
        ...profileMenu,
        {
            title: faqMenuItem.title,
            action: () => handleGoTo(faqMenuItem.path),
        },
        {
            title: t('profileMenu.passwordChange'),
            action: () => handleGoTo('change-password'),
        },
        {
            title: t('profileMenu.language'),
            nestedMenu: [
                {
                    title: t('profileMenu.german'),
                    action: () => changeLanguage(LANGUAGES.DE),
                },
                {
                    title: t('profileMenu.english'),
                    action: () => changeLanguage(LANGUAGES.EN),
                },
            ],
        },
        { title: t('profileMenu.logout'), action: handleLogout },
    ];
    return profileMenu;
};
