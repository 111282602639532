import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
    Grid,
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
    IconButton,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DeleteIcon from '@material-ui/icons/Delete';

import { CheckboxItem, ThemeSelectInput } from 'src/theming';
import { moveOptionToNewPosition } from 'src/shared/utils';

import { useFormControlStyles, useFormControlLabelStyles } from '../styles';
import { useCardStyle } from './user-editing-form-styles';

import { userEditFormBuildDataForCardWithBranches } from './user-edit-form-build-data-for-card-with-branches';

import { IClientConnections, WithBranchFormCard } from './user-edit-form-types';
import { ChangeEvent } from 'react';

interface UserEditingFormWithBranchCardProps {
    clientCard: WithBranchFormCard;
    editMode?: boolean;
    onChange?: (id: string, card: WithBranchFormCard) => void;
    initialStateOfCardsWithBranches: IClientConnections[] | void;
    cardsWithBranches: WithBranchFormCard[];
    setValue: (cards: WithBranchFormCard[]) => void;
}

export const UserEditingFormWithBranchCard = (
    props: UserEditingFormWithBranchCardProps
): JSX.Element => {
    const {
        editMode,
        clientCard,
        onChange,
        initialStateOfCardsWithBranches,
        cardsWithBranches,
        setValue,
    } = props;
    const { clientConnectionValue, clientConnectionDisabled } = clientCard;

    const classes = useCardStyle();
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();

    const { t } = useTranslation(['common', 'user-management']);

    const handleChange = (
        e: ChangeEvent<HTMLInputElement>,
        checkboxesGroupName: string
    ): void => {
        const isAllChecked = e.target.name === 'all' && e.target.checked;
        const newClientCard = { ...clientCard };
        if (checkboxesGroupName === 'clients') {
            newClientCard.clients = newClientCard.clients.map((client) => {
                if (isAllChecked && client.value !== 'all') {
                    return { ...client, checked: false };
                }
                if (!isAllChecked && client.value === 'all') {
                    return { ...client, checked: false };
                }
                if (client.value === e.target.name) {
                    return { ...client, checked: e.target.checked };
                }
                return client;
            });
        }
        if (checkboxesGroupName === 'divisions') {
            newClientCard.divisions = newClientCard.divisions.map((client) => {
                if (isAllChecked && client.value !== 'all') {
                    return { ...client, checked: false };
                }
                if (!isAllChecked && client.value === 'all') {
                    return { ...client, checked: false };
                }
                if (client.value === e.target.name) {
                    return { ...client, checked: e.target.checked };
                }
                return client;
            });
        }
        onChange(clientCard.id, newClientCard);
    };

    const handleDeleteCardWithBranches = (): void => {
        const filteredCards = cardsWithBranches.filter(
            (card) => card.clientConnectionValue !== clientCard.clientConnectionValue
        );
        const lastCard = filteredCards.pop();
        if (lastCard) {
            const {
                filteredClientConnections,
                clients,
                clientConnectionValue,
                divisions,
            } = userEditFormBuildDataForCardWithBranches(
                initialStateOfCardsWithBranches,
                filteredCards,
                lastCard.clientConnectionValue,
                null,
                lastCard
            );
            const newCard = {
                ...lastCard,
                clients,
                divisions,
                clientConnectionValue,
                clientConnections: filteredClientConnections,
                clientConnectionDisabled: false,
            };
            setValue([...filteredCards, newCard]);
        } else {
            setValue([]);
        }
    };

    const handleChangeClientConnection = (e: ChangeEvent<HTMLInputElement>): void => {
        const { clients, clientConnectionValue, divisions } =
            userEditFormBuildDataForCardWithBranches(
                initialStateOfCardsWithBranches,
                cardsWithBranches,
                e.target.value
            );
        const newCard = {
            ...clientCard,
            clients,
            divisions,
            clientConnectionValue,
        };
        onChange(clientCard.id, newCard);
    };

    return (
        <Grid container className={classes.cardContainer}>
            <Grid item xs={10} md={8} xl={5}>
                <FormControl fullWidth classes={formControlClasses} variant='outlined'>
                    <FormControlLabel
                        label={t('user-management:umSection.form.clientConnection')}
                        labelPlacement='top'
                        classes={formControlLabelClasses}
                        control={
                            <ThemeSelectInput
                                editMode={clientConnectionDisabled ? false : editMode}
                                name='client'
                                value={clientConnectionValue}
                                items={clientCard.clientConnections.sort((a, b) =>
                                    a.title.localeCompare(b.title)
                                )}
                                onChange={handleChangeClientConnection}
                            />
                        }
                    />
                </FormControl>
                <FormControl component='fieldset' className={classes.formControl}>
                    <FormLabel>{t('user-management:umSection.form.client')}</FormLabel>
                    <FormGroup>
                        {moveOptionToNewPosition<CheckboxItem>(
                            clientCard.clients.sort((a, b) =>
                                a.title.localeCompare(b.title)
                            ),
                            0,
                            'value',
                            'all'
                        ).map((client) => (
                            <FormControlLabel
                                key={client.value}
                                control={
                                    <Checkbox
                                        disabled={!editMode}
                                        checked={client.checked}
                                        onChange={(e) => handleChange(e, 'clients')}
                                        name={client.value}
                                        icon={
                                            <CheckBoxOutlineBlankIcon
                                                className={classes.icon}
                                            />
                                        }
                                        checkedIcon={
                                            <CheckBoxIcon
                                                className={clsx(
                                                    classes.icon,
                                                    classes.checkedIcon
                                                )}
                                            />
                                        }
                                    />
                                }
                                label={
                                    client.labelAsKeyForTFunction
                                        ? t(client.title)
                                        : client.title
                                }
                            />
                        ))}
                    </FormGroup>
                </FormControl>
                <FormControl component='fieldset' className={classes.formControl}>
                    <FormLabel>{t('user-management:umSection.form.branch')}</FormLabel>
                    <FormGroup>
                        {moveOptionToNewPosition<CheckboxItem>(
                            clientCard.divisions.sort((a, b) =>
                                a.title.localeCompare(b.title)
                            ),
                            0,
                            'value',
                            'all'
                        ).map((client) => (
                            <FormControlLabel
                                key={client.value}
                                control={
                                    <Checkbox
                                        disabled={!editMode}
                                        checked={client.checked}
                                        onChange={(e) => handleChange(e, 'divisions')}
                                        name={client.value}
                                        icon={
                                            <CheckBoxOutlineBlankIcon
                                                className={classes.icon}
                                            />
                                        }
                                        checkedIcon={
                                            <CheckBoxIcon
                                                className={clsx(
                                                    classes.icon,
                                                    classes.checkedIcon
                                                )}
                                            />
                                        }
                                    />
                                }
                                label={
                                    client.labelAsKeyForTFunction
                                        ? t(client.title)
                                        : client.title
                                }
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            </Grid>
            {editMode && (
                <Grid item xs={2} md={4} xl={7}>
                    <IconButton
                        className={classes.deleteCardButton}
                        onClick={handleDeleteCardWithBranches}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            )}
        </Grid>
    );
};
