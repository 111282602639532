import React, { BaseSyntheticEvent, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@material-ui/core';
import { ThemeOutlineInput } from '../../../../theming/theme-input/theme-outline-input';
import clsx from 'clsx';
import {
    useFormControlLabelStyles,
    useFormControlStyles,
    useInputStyles,
    useStyle,
} from '../damage-report-form-components-styled';

interface DamageReportFormFullInputProps {
    value: unknown;
    handleChangeValue: (event: BaseSyntheticEvent) => void;
    label: string;
    name: string;
    disabled?: boolean;
    additionalLabel?: string;
    error?: boolean;
}

export const DamageReportFormFullInput = memo(
    ({
        value,
        handleChangeValue,
        label,
        name,
        disabled,
        additionalLabel,
        error,
    }: DamageReportFormFullInputProps) => {
        const classes = useStyle();
        const formControlClasses = useFormControlStyles();
        const formControlLabelClasses = useFormControlLabelStyles();
        const inputClasses = useInputStyles();
        const { t } = useTranslation();
        return (
            <Grid item className={clsx(classes.fullWidth)}>
                {additionalLabel && (
                    <p className={classes.additionalLabel}>{t(additionalLabel)}</p>
                )}
                <ThemeOutlineInput
                    disabled={disabled}
                    inputClasses={inputClasses}
                    value={value}
                    label={label}
                    formControlLabelClasses={formControlLabelClasses}
                    formControlClasses={formControlClasses}
                    handleChangeValue={handleChangeValue}
                    name={name}
                    error={error}
                />
            </Grid>
        );
    }
);
