import React, { BaseSyntheticEvent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
    FormControl,
    FormControlLabel,
    Grid,
    OutlinedInput,
    Typography,
} from '@material-ui/core';

import { ThemeSwitcher, ThemePhoneNumberInput } from 'src/theming';

import {
    useStyle,
    useFormControlStyles,
    useFormControlLabelStyles,
    useInputStyles,
} from '../../damage-report-form-styles/damage-report-form-styles';

interface DamageReportFormWitnessesInformationProps {
    readonly: boolean;
    areWitnesses: boolean;
    surname: string;
    road: string;
    postCode: string;
    place: string;
    phoneNumber: string;
    email: string;
    handleChangeValue: (e: BaseSyntheticEvent) => void;
    handleChangeWitnesses: () => void;
    errors: {
        witnessesEmail: boolean;
    };
}

export const DamageReportFormWitnessesInformation = memo(
    (props: DamageReportFormWitnessesInformationProps): JSX.Element => {
        const { t } = useTranslation(['common', 'damage-report']);

        const classes = useStyle();
        const formControlLabelClasses = useFormControlLabelStyles();
        const formControlClasses = useFormControlStyles();
        const inputClasses = useInputStyles();

        const {
            handleChangeWitnesses,
            handleChangeValue,
            areWitnesses,
            surname,
            road,
            postCode,
            place,
            phoneNumber,
            email,
            errors,
            readonly,
        } = props;

        return (
            <Grid container spacing={2}>
                <Typography variant='h6' className={classes.formBlockTitle}>
                    {t('damages-report:witnesses.title')}
                </Typography>
                <Grid item className={clsx(classes.fullWidth)}>
                    <div style={{ width: 'fit-content' }}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <ThemeSwitcher
                                        disabled={readonly}
                                        checked={!areWitnesses}
                                        onChange={handleChangeWitnesses}
                                        leftLabel={t('yes')}
                                        rightLabel={t('no')}
                                    />
                                }
                                label={t('damages-report:witnesses.areThereWitnesses')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </div>
                </Grid>
                {areWitnesses && (
                    <>
                        <Grid item className={clsx(classes.thirdPart)}>
                            <FormControl
                                fullWidth
                                classes={formControlClasses}
                                variant='outlined'
                            >
                                <FormControlLabel
                                    classes={formControlLabelClasses}
                                    control={
                                        <OutlinedInput
                                            disabled={readonly}
                                            classes={inputClasses}
                                            value={surname}
                                            onChange={handleChangeValue}
                                            name='witnessesNameAndSurname'
                                        />
                                    }
                                    label={t('damages-report:witnesses.surname')}
                                    labelPlacement='top'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item className={clsx(classes.thirdPart)}>
                            <FormControl
                                fullWidth
                                classes={formControlClasses}
                                variant='outlined'
                            >
                                <FormControlLabel
                                    classes={formControlLabelClasses}
                                    control={
                                        <OutlinedInput
                                            disabled={readonly}
                                            classes={inputClasses}
                                            value={road}
                                            onChange={handleChangeValue}
                                            name='witnessesStreet'
                                        />
                                    }
                                    label={t('damages-report:witnesses.road')}
                                    labelPlacement='top'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item className={clsx(classes.thirdPart)}>
                            <FormControl
                                fullWidth
                                classes={formControlClasses}
                                variant='outlined'
                            >
                                <FormControlLabel
                                    classes={formControlLabelClasses}
                                    control={
                                        <OutlinedInput
                                            disabled={readonly}
                                            classes={inputClasses}
                                            value={postCode}
                                            onChange={handleChangeValue}
                                            name='witnessesPostcode'
                                        />
                                    }
                                    label={t('damages-report:witnesses.postCode')}
                                    labelPlacement='top'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item className={clsx(classes.thirdPart)}>
                            <FormControl
                                fullWidth
                                classes={formControlClasses}
                                variant='outlined'
                            >
                                <FormControlLabel
                                    classes={formControlLabelClasses}
                                    control={
                                        <OutlinedInput
                                            disabled={readonly}
                                            classes={inputClasses}
                                            value={place}
                                            onChange={handleChangeValue}
                                            name='witnessesCity'
                                        />
                                    }
                                    label={t('damages-report:witnesses.place')}
                                    labelPlacement='top'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item className={clsx(classes.thirdPart)}>
                            <FormControl
                                fullWidth
                                classes={formControlClasses}
                                variant='outlined'
                            >
                                <FormControlLabel
                                    classes={formControlLabelClasses}
                                    control={
                                        <ThemePhoneNumberInput
                                            disabled={readonly}
                                            value={phoneNumber}
                                            onChange={handleChangeValue}
                                            name='witnessesPhoneNumber'
                                        />
                                    }
                                    label={t('damages-report:team.phoneNumber')}
                                    labelPlacement='top'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item className={clsx(classes.thirdPart)}>
                            <FormControl
                                fullWidth
                                error={errors.witnessesEmail}
                                classes={formControlClasses}
                                variant='outlined'
                            >
                                <FormControlLabel
                                    classes={formControlLabelClasses}
                                    control={
                                        <OutlinedInput
                                            disabled={readonly}
                                            classes={inputClasses}
                                            value={email}
                                            onChange={handleChangeValue}
                                            name='witnessesEmail'
                                        />
                                    }
                                    label={t('damages-report:witnesses.emailAddress')}
                                    labelPlacement='top'
                                />
                            </FormControl>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }
);
