import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, Button, Theme } from '@material-ui/core';

import { ThemeCircularProgress } from 'src/theming';
import { ROLES } from 'src/shared/constants';
import { withComponentAccessDependingOnRole } from 'src/shared/hocs';

import { UserEditFormSuccessActivationModalWindow } from './user-edit-form-success-activation-modal-window';
import { useManualUserActivation } from './user-edit-form-use-manual-user-activation';
import { UserManagementSectionResponseBody } from '../uset-management-section/user-management-section-response-body';

interface Props {
    id: string;
    activated: boolean;
    roles: ROLES[];
    makeRequestForUsersList: () => Promise<UserManagementSectionResponseBody>;
    setActiveUser: (value: string) => void;
}

const useStyle = makeStyles((theme: Theme) => {
    return {
        activationButton: {
            height: 40,
            padding: `0 ${theme.spacing(4)}px`,
            backgroundColor: theme.palette.info.main,
            color: theme.palette.info.contrastText,
            transition: '0.2s',
            '&:hover': {
                backgroundColor: theme.palette.info.main,
                opacity: 0.6,
            },
        },
    };
});

const UserActivation = (props: Props): JSX.Element => {
    const classes = useStyle();
    const { t } = useTranslation(['user-management']);
    const [password, setPassword] = useState<string>('');

    const { activated, id, makeRequestForUsersList, setActiveUser } = props;

    const onSuccess = (password: string): void => {
        setPassword(password);
    };

    const closeSuccessModalWindow = (): void => {
        setPassword('');
        makeRequestForUsersList().then((data) => {
            setActiveUser(data?.result[0]?.id);
        });
    };

    const [isLoading, activateUser] = useManualUserActivation(id, onSuccess);

    if (activated) {
        return null;
    }

    return (
        <>
            <Button
                disabled={isLoading}
                startIcon={<ThemeCircularProgress isLoading={isLoading} />}
                className={classes.activationButton}
                onClick={activateUser}
            >
                {t('userActivation.activateUser')}
            </Button>
            <UserEditFormSuccessActivationModalWindow
                password={password}
                onClose={closeSuccessModalWindow}
            />
        </>
    );
};

UserActivation.defaultProps = {
    activated: true,
};

export default withComponentAccessDependingOnRole(UserActivation);
