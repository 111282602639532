import { Dispatch, SetStateAction, useLayoutEffect, useCallback } from 'react';
import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useAxios } from 'src/lib/http-client/use-http-client';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { UserSettingsDto, TokenResponse } from '../context-auth-types';
import { localStorageServices } from 'src/lib/local-storage-services/local-storage-services';

interface useAuthInterceptorProps {
    isAuth: boolean;
    setAuth: Dispatch<SetStateAction<boolean>>;
    setUserData: (data: UserSettingsDto) => void;
}

const throttleFun = (
    fn: (refreshToken: string) => Promise<TokenResponse>,
    ms: number
): ((refreshToken: string) => Promise<TokenResponse>) => {
    let currentTime = 0;
    let result: Promise<TokenResponse>;
    return (refreshToken: string): Promise<TokenResponse> => {
        const now = Date.now();
        if (now - currentTime >= ms) {
            currentTime = now;
            result = fn(refreshToken);
            return result;
        }
        return result;
    };
};

export const useAuthInterceptor = ({
    isAuth,
    setAuth,
    setUserData,
}: useAuthInterceptorProps): void => {
    const axios = useAxios();
    const httpClient = useHttpClient();

    const makeRequestForUpdatingTokens = useCallback(
        async (refreshToken: string): Promise<TokenResponse> => {
            return await httpClient.post('users/refresh-token', {
                refreshToken,
            });
        },
        []
    );

    const updateTokensThrottle = throttleFun(makeRequestForUpdatingTokens, 10000);

    useLayoutEffect(() => {
        let reqInterceptor: number;
        let resInterceptor: number;
        if (isAuth) {
            const tokenInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
                const accessToken = localStorage.getItem('accessToken');
                if (accessToken && config.headers) {
                    config.headers.Authorization = `Bearer ${accessToken}`;
                }
                return config;
            };
            const responseIntercepor = {
                response: (response: AxiosResponse) => {
                    return response;
                },
                error: async (
                    error: AxiosError & {
                        config: {
                            _isRetry: boolean;
                        };
                        response: {
                            errors: { refreshToken: string[] };
                        };
                    }
                ) => {
                    const originalRequest = error.config;
                    if (error.response.status === 403) {
                        localStorageServices.clearAuthData(); // If a refresh token exists, but error occurs
                        setAuth(false); // during updating tokens
                        setUserData(null);
                    }
                    // If any request complete with 401 error and this request is not retrying update tokens request
                    if (error.response.status === 401 && !error.config._isRetry) {
                        error.config._isRetry = true;
                        const refreshToken = localStorage.getItem('refreshToken');
                        if (refreshToken) {
                            // If a refresh token exists, try to update pair of tokens
                            try {
                                const response: TokenResponse =
                                    await updateTokensThrottle(refreshToken);
                                localStorage.setItem('accessToken', response.accessToken);
                                localStorage.setItem(
                                    'refreshToken',
                                    response.refreshToken
                                );
                                return axios(originalRequest);
                            } catch {
                                localStorageServices.clearAuthData(); // If a refresh token exists, but error occurs
                                setAuth(false); // during updating tokens
                                setUserData(null);
                            }
                        } else {
                            localStorageServices.clearAuthData(); // If the error occurs, but a user doesn't have a refresh token
                            setAuth(false); // Then unauthorize the user
                            setUserData(null);
                        }
                        return Promise.reject(error);
                    } else {
                        return Promise.reject(error);
                    }
                },
            };
            reqInterceptor = axios.interceptors.request.use(tokenInterceptor);
            resInterceptor = axios.interceptors.response.use(
                responseIntercepor.response,
                responseIntercepor.error
            );
        }
        return () => {
            if (reqInterceptor && resInterceptor) {
                axios.interceptors.request.eject(reqInterceptor);
                axios.interceptors.response.eject(resInterceptor);
            }
        };
    }, [isAuth]);
};
