import React from 'react';

import { IconProps } from './index';

export const HardHatIcon = (props: IconProps): JSX.Element => {
    const { fill } = props;
    return (
        <>
            <svg width='34' height='40' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M17 27.368c-9.393 0-17 3.769-17 8.421V40h34v-4.21c0-4.653-7.608-8.422-17-8.422zM8.5 14.737c0 2.233.896 4.375 2.49 5.954A8.54 8.54 0 0017 23.158a8.54 8.54 0 006.01-2.467 8.381 8.381 0 002.49-5.954h-17zM15.938 0c-.638 0-1.063.442-1.063 1.053v6.315H12.75V2.105S7.969 3.915 7.969 10c0 0-1.594.295-1.594 2.632h21.25C27.519 10.295 26.031 10 26.031 10c0-6.084-4.781-7.895-4.781-7.895v5.263h-2.125V1.053c0-.61-.404-1.053-1.063-1.053h-2.125z'
                    fill={fill}
                />
            </svg>
        </>
    );
};
