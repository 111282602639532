import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TFunction } from 'i18next';

import { Grid, Typography, Link as MaterialUiLink } from '@material-ui/core';

import { ThemeOverflowTooltip, ThemeLongText } from 'src/theming';
import { useNotificationCenterContext } from 'src/shared/contexts';
import {
    NotificationCenterMessageTypes,
    NotificationCenterSections,
} from 'src/shared/contexts/notification-center-context/notification-center-enums';

import { NotificationCenterMessageActions } from './notification-center-message-actions';
import { useNotificationCenterStyle } from './notification-center-styles';

import { MessageDTO } from 'src/shared/contexts/notification-center-context/notification-center-message-dto';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

const getNotificationTitle = (
    messageType: string,
    t: TFunction,
    title: string,
    date: ParsableDate
): string => {
    switch (messageType) {
        case NotificationCenterMessageTypes.NOTIFICATION_TRIGGER: {
            return t(`notification-center:triggers.${title}`);
        }
        case NotificationCenterMessageTypes.PRIVATE_MESSAGE: {
            return `${t('notification-center:messageFrom')} ${title} 
            ${t('formatted-values:formattedDate', {
                date,
            })}`;
        }
        case NotificationCenterMessageTypes.OWN_MESSAGE: {
            return `${t('notification-center:messageTo')} ${title} 
            ${t('formatted-values:formattedDate', {
                date,
            })}`;
        }
        default: {
            return '';
        }
    }
};

const getNotificationBody = (
    t: TFunction,
    contractNumber: string,
    customerName: string
): string => {
    if (contractNumber && customerName) {
        return `${t('notification-center:customer')} ${customerName} - ${t(
            'notification-center:contractNumber'
        )} ${contractNumber}`;
    }
    if (contractNumber) {
        return `${t('notification-center:contractNumber')} ${contractNumber}`;
    }
    if (customerName) {
        return `${t('notification-center:customer')} ${customerName}`;
    }
    return '';
};

export const NotificationCenterMessage = memo((props: MessageDTO): JSX.Element => {
    const classes = useNotificationCenterStyle();
    const { t } = useTranslation(['formatted-values', 'notification-center']);
    const {
        id,
        title,
        body,
        createdDate,
        link,
        messageType,
        senderId,
        linkType,
        contractNumber,
        customerName,
    } = props;

    const { setNotificationsSection, setUserReplyId, setUserReplyName } =
        useNotificationCenterContext();

    const onMessageReply = (): void => {
        setUserReplyId(senderId);
        setUserReplyName(title);
        setNotificationsSection(NotificationCenterSections.NEW_MESSAGE);
    };

    const bodyDependOnMessageType =
        messageType === NotificationCenterMessageTypes.NOTIFICATION_TRIGGER
            ? getNotificationBody(t, contractNumber, customerName)
            : body;

    const titleToShow = getNotificationTitle(messageType, t, title, createdDate);

    return (
        <Grid container spacing={2} className={classes.messageContainer}>
            <Grid item md={10}>
                <ThemeOverflowTooltip label={titleToShow}>
                    <Typography variant='h6'>{titleToShow}</Typography>
                </ThemeOverflowTooltip>

                <ThemeLongText
                    limit={70}
                    text={bodyDependOnMessageType}
                    className={classes.messageText}
                />
                {NotificationCenterMessageTypes.NOTIFICATION_TRIGGER === messageType &&
                    link && (
                        <Link className={classes.link} to={link}>
                            {linkType
                                ? t(`notification-center:${linkType}`)
                                : t('notification-center:link')}
                        </Link>
                    )}
                {NotificationCenterMessageTypes.PRIVATE_MESSAGE === messageType && (
                    <MaterialUiLink role='button' onClick={onMessageReply}>
                        {t('notification-center:reply')}
                    </MaterialUiLink>
                )}
            </Grid>
            <Grid item md={2}>
                <NotificationCenterMessageActions id={id} messageType={messageType} />
            </Grid>
        </Grid>
    );
});
