import React, { ReactElement, useState, BaseSyntheticEvent } from 'react';
import { useParams } from 'react-router-dom';
import { GridCellParams } from '@material-ui/data-grid';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { IconButton, Typography, Button, Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { ThemeCircularProgress, ThemeModalWindow } from 'src/theming';
import { useDeleteDummyDocument } from 'src/shared/hooks';

import { useContractDetailsViewDataStyle } from './contracts-details-view-styles';

interface DeleteCellProps {
    entity: string;
    onSuccess: () => void;
    approveMessage: string;
}

export const DeleteCell = (props: GridCellParams & DeleteCellProps): ReactElement => {
    const { t } = useTranslation([
        'common',
        'contract-details-view',
        'customer-details-view',
        'damage-details-view',
    ]);
    const [deleteId, setDeleteId] = useState<string>('');
    const { entity, onSuccess, approveMessage } = props;
    const { id: entityId } = useParams<{ id: string }>();
    const { remove, isLoading } = useDeleteDummyDocument({
        entity,
        entityId,
        onSuccess,
    });

    const classes = useContractDetailsViewDataStyle();

    const onRemove = (): void => {
        setDeleteId(props.row.id || props.row.amsIdNr);
    };

    const approveRemove = (): void => {
        remove(deleteId);
    };

    const handleClose = (e: BaseSyntheticEvent): void => {
        e.stopPropagation();
        setDeleteId('');
    };

    const isOpen = Boolean(deleteId);

    return (
        <IconButton disabled={isLoading} onClick={onRemove}>
            {!isLoading && <DeleteIcon color='error' />}
            <ThemeCircularProgress isLoading={isLoading} />
            <ThemeModalWindow isOpen={isOpen} onClose={handleClose}>
                <></>
                <Typography variant='body1'>{t(approveMessage)}</Typography>
                <Grid container className={classes.modalActionsContainer}>
                    <Button
                        disabled={isLoading}
                        variant='outlined'
                        className={clsx(classes.modalButton, classes.cancelButton)}
                        onClick={handleClose}
                    >
                        {t('common:cancel')}
                    </Button>
                    <Button
                        disableElevation
                        variant='contained'
                        className={clsx(classes.modalButton, classes.okButton)}
                        onClick={approveRemove}
                        disabled={isLoading}
                        startIcon={<ThemeCircularProgress isLoading={isLoading} />}
                    >
                        {t('common:yes')}
                    </Button>
                </Grid>
            </ThemeModalWindow>
        </IconButton>
    );
};
