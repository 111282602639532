import * as yup from 'yup';

export const damageReportFormValidationSchema = yup.object().shape({
    division: yup.string().required().nullable().required(),
    dayOfDamage: yup.string().nullable().required(),
    accidentDamageDetailsInformationChangeOfVehicle: yup.string().required().nullable(),
    contractNumber: yup.string().nullable().required(),
    policyHolder: yup.string().nullable().required(),
    insuranceCompany: yup.string().nullable().required(),
    driverInformationEmail: yup.string().email().nullable(),
    teamDamageEmail: yup.string().email().nullable(),
    witnessesEmail: yup.string().email().nullable(),
    otherPartyInformationCityEmail: yup.string().email().nullable(),
    typeOfDamage: yup.array().min(1).required(),
});
