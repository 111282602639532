import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { APP_ROUTES } from 'src/routing';
import { useAuth } from 'src/user-management/context-auth';

import { CustomerAddingFormData, DummyCustomerDTO } from './customer-add-form-types';

const mutateFormatter = (customerData: CustomerAddingFormData): DummyCustomerDTO => {
    return {
        name: customerData.name,
        street: customerData.street,
        city: customerData.city,
        countryCode: customerData.countryCode,
        postcode: String(customerData.postCode),
        matchCode: customerData.customerName || customerData.customers[0]?.name,
    };
};

export const useMutateCustomer = (
    id: string,
    onSuccess: () => void
): [boolean, (data: CustomerAddingFormData) => void] => {
    const httpClient = useHttpClient();
    const { t } = useTranslation(['customer-form', 'errors']);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { getUserData } = useAuth();

    const [isLoading, setLoading] = useState<boolean>(false);

    const mutateCustomer = async (data: CustomerAddingFormData): Promise<void> => {
        try {
            setLoading(true);
            const formattedData = mutateFormatter(data);
            if (id) {
                await httpClient.put(`customers/dummy/${id}`, formattedData);
                enqueueSnackbar(t(`customer-form:customerUpdatedSuccessfully`), {
                    variant: 'success',
                });
                onSuccess();
            } else {
                await httpClient.post('customers/dummy', formattedData);
                history.push(APP_ROUTES.CUSTOMERS);
                enqueueSnackbar(t(`customer-form:customerCreatedSuccessfully`), {
                    variant: 'success',
                });
            }
            getUserData();
        } catch {
            enqueueSnackbar(t(`errors:unknownError`), {
                variant: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    return [isLoading, mutateCustomer];
};
