import React, { useMemo, useState, useEffect, useCallback } from 'react';
import useDarkMode from 'use-dark-mode';
import { TFunction } from 'react-i18next';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { useAbilityCtx, AbilityActions, AbilitySubjects } from 'src/roleAccesses';
import { useAuth } from 'src/user-management/context-auth';
import { useGlobalFilter } from 'src/shared/contexts';

import { Button } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import LaunchIcon from '@material-ui/icons/Launch';

import { ThemeNestedMenu } from 'src/theming';

import { useStyle } from './contract-dashboard-styles';

interface ContractsDashboardDamageLinkQuickActionProps {
    division: string;
    editMode: boolean;
    t: TFunction<string[]>;
    setLoading: (value: boolean) => void;
}

const generateHref = (t: TFunction<string[]>, url: string, password: string): string => {
    return `mailto:?subject=${t('contract-types:emailSubject')}&body=${t(
        'contract-types:emailMainBody',
        {
            link: `${location.origin}/damage-report-form/${url}`,
        }
    )}${
        password
            ? '%0D%0A' +
              t('contract-types:emailAdditionalPart', {
                  password,
              })
            : ''
    }`;
};

export const ContractsDashboardDamageLinkQuickAction = (
    props: ContractsDashboardDamageLinkQuickActionProps
): JSX.Element => {
    const { editMode, division, t, setLoading } = props;
    const ability = useAbilityCtx();
    const darkMode = useDarkMode();
    const classes = useStyle({ editMode, isDarkMode: darkMode.value });

    const httpClient = useHttpClient();

    const { userData } = useAuth();
    const { filter } = useGlobalFilter();

    const [ccLinks, setCCLinks] = useState<
        { customerConnection: string; linkActive: boolean }[]
    >([]);

    const makeRequestForCCByDivision = useCallback((): Promise<
        { customerConnection: string; linkActive: boolean }[]
    > => {
        return httpClient.get<{ customerConnection: string; linkActive: boolean }[]>(
            `users/client-connections/${division}`
        );
    }, [division]);

    const makeRequestForExternalLinkByCC = (
        cc: string
    ): Promise<{
        url: 'string';
        password: 'string';
    }> => {
        return httpClient.get(`external-links/by-customer-connection/${cc}`);
    };

    const handleExternalLink = (): void => {
        setLoading(true);
        makeRequestForExternalLinkByCC(ccLinks[0].customerConnection)
            .then((data) => {
                if (data) {
                    location.href = generateHref(t, data.url, data.password);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (ability.can(AbilityActions.use, AbilitySubjects.damageLink)) {
            setLoading(true);
            makeRequestForCCByDivision()
                .then((data: { customerConnection: string; linkActive: boolean }[]) => {
                    setCCLinks(data);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [makeRequestForCCByDivision, ability]);

    const selectedCC = useMemo(() => {
        if (ccLinks?.length !== 0) {
            return ccLinks
                .filter(
                    (item) =>
                        (filter.customerConnections.find(
                            (cc) => cc.value === item.customerConnection
                        )?.checked ||
                            filter.customerConnections.find((cc) => cc.value === 'all')
                                ?.checked) &&
                        item.linkActive
                )
                .map((item) => {
                    return {
                        title: item.customerConnection,
                        action: () => {
                            setLoading(true);
                            makeRequestForExternalLinkByCC(item.customerConnection)
                                .then((data) => {
                                    if (data) {
                                        location.href = generateHref(
                                            t,
                                            data.url,
                                            data.password
                                        );
                                    }
                                })
                                .finally(() => {
                                    setLoading(false);
                                });
                        },
                    };
                });
        }
        return [];
    }, [userData, ccLinks]);

    return (
        <>
            {selectedCC?.length === 1 && (
                <>
                    <Button
                        disableElevation
                        disabled={editMode}
                        variant='contained'
                        startIcon={<OpenInNewIcon />}
                        className={classes.quickActionsButton}
                        onClick={handleExternalLink}
                    >
                        {t('contract-types:damageLink')}
                    </Button>
                </>
            )}
            {selectedCC?.length > 1 && (
                <ThemeNestedMenu
                    title={t('contract-types:damageLink')}
                    menuItems={selectedCC}
                    buttonClass={classes.damageLinkButton}
                    startIcon={<LaunchIcon />}
                />
            )}
        </>
    );
};
