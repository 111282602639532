import { makeStyles, Theme } from '@material-ui/core/styles';
import { isCeatInstance, isHectorInstance } from 'src/environment';
import { THEME_MODES } from 'src/theming';
import { LIGHT_CEAT_THEME_COLORS } from '../themes/ceat';
import { LIGHT_HECTOR_THEME_COLORS } from '../themes/hector/hector-theme-colors';

export const useAonMotorTabsStyle = makeStyles(
    () => {
        return {
            root: {
                height: '100%',
            },
            indicator: {
                opacity: '0',
            },
            fixed: {
                display: 'flex',
                alignItems: 'center',
            },
        };
    },
    { index: 1 }
);

export const useSgiffoxxkTabsStyle = makeStyles(
    () => {
        return {
            root: {
                height: '100%',
            },
            indicator: {
                opacity: '0',
            },
            fixed: {
                display: 'flex',
                alignItems: 'center',
            },
        };
    },
    { index: 1 }
);

export const useHectorTabsStyle = makeStyles(
    () => {
        return {
            root: {
                height: '100%',
            },
            indicator: {
                opacity: '0',
            },
            fixed: {
                display: 'flex',
                alignItems: 'center',
            },
        };
    },
    { index: 1 }
);

export const useTabsStyle = makeStyles(
    (theme: Theme) => {
        return {
            root: {
                height: '100%',
            },
            indicator: {
                backgroundColor: theme.palette.info.main,
            },
            fixed: {
                display: 'flex',
                alignItems: 'center',
            },
        };
    },
    { index: 1 }
);

export const useAonMotorTabStyles = makeStyles(
    (theme: Theme) => {
        return {
            root: {
                height: 50,
                minHeight: 50,
                border: `1px solid ${theme.palette.secondary.main}`,
                borderRight: 'none',
                color: theme.palette.primary.contrastText,
                fontSize: '1.8rem',
                fontWeight: theme.typography.fontWeightRegular,
                opacity: 1,
                textTransform: 'none',
                '&:last-child': {
                    borderRight: `1px solid ${theme.palette.secondary.main}`,
                },
                '@media (max-width:1550px)': {
                    minWidth: 'auto',
                    fontSize: '1.6rem',
                },
                '@media (max-width:1400px)': {
                    height: 40,
                    minHeight: 40,
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2),
                    fontSize: '1.4rem',
                },
            },
            selected: {
                color: theme.palette.error.main,
                backgroundColor: theme.palette.secondary.contrastText,
                fontWeight: 'bold',
                '& span': {
                    width: 'fit-content',
                    borderBottom: `1px solid ${theme.palette.error.main}`,
                },
            },
        };
    },
    { index: 1 }
);

export const useSgiffoxxkTabStyles = makeStyles(
    (theme: Theme) => {
        return {
            root: {
                height: 50,
                minHeight: 50,
                border: `1px solid ${theme.palette.secondary.contrastText}`,
                borderRight: 'none',
                color: theme.palette.secondary.contrastText,
                fontSize: '1.8rem',
                fontWeight: theme.typography.fontWeightRegular,
                opacity: 1,
                '&:last-child': {
                    borderRight: `1px solid ${theme.palette.secondary.contrastText}`,
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                },
                '&:first-child': {
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5,
                },
                '@media (max-width:1550px)': {
                    minWidth: 'auto',
                    fontSize: '1.6rem',
                },
                '@media (max-width:1400px)': {
                    height: 40,
                    minHeight: 40,
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2),
                    fontSize: '1.4rem',
                },
            },
            selected: {
                color: theme.palette.secondary.main,
                backgroundColor: theme.palette.secondary.contrastText,
                fontWeight: 'bold',
            },
        };
    },
    { index: 1 }
);

export const useHectorTabStyles = makeStyles(
    (theme: Theme) => {
        return {
            root: {
                height: 50,
                minHeight: 50,
                border: `1px solid ${theme.palette.secondary.contrastText}`,
                borderRight: 'none',
                color: theme.palette.secondary.contrastText,
                fontSize: '1.8rem',
                fontWeight: theme.typography.fontWeightRegular,
                opacity: 1,
                '&:last-child': {
                    borderRight: `1px solid ${theme.palette.secondary.contrastText}`,
                },
                '@media (max-width:1500px)': {
                    minWidth: 'auto',
                    fontSize: '1.6rem',
                },
                '@media (max-width:1550px)': {
                    height: 40,
                    minHeight: 40,
                    paddingRight: 8,
                    paddingLeft: 8,
                    fontSize: '1.4rem',
                },
            },
            selected: {
                color: () => {
                    const isDark = theme.palette.type === THEME_MODES.DARK;
                    if (isHectorInstance) {
                        return isDark
                            ? LIGHT_HECTOR_THEME_COLORS.SECONDARY_MAIN_COLOR
                            : theme.palette.secondary.main;
                    }
                    if (isCeatInstance) {
                        return isDark
                            ? LIGHT_CEAT_THEME_COLORS.SECONDARY_MAIN_COLOR
                            : theme.palette.secondary.main;
                    }
                },
                backgroundColor: theme.palette.secondary.contrastText,
            },
        };
    },
    { index: 1 }
);

export const useTabStyle = makeStyles(
    (theme: Theme) => {
        return {
            root: {
                height: 40,
                minHeight: 40,
                fontSize: '1.4rem',
                minWidth: 'auto',
                padding: `0 ${theme.spacing(2)}px`,
                textTransform: 'none',
                color: theme.palette.primary.contrastText,
                fontWeight: theme.typography.fontWeightRegular,
                opacity: 1,
                borderRight: `1px solid ${
                    theme.palette.type === THEME_MODES.DARK
                        ? theme.palette.secondary.main
                        : theme.palette.grey['300']
                }`,
            },
            selected: {
                fontWeight: theme.typography.fontWeightBold,
            },
        };
    },
    { index: 1 }
);
