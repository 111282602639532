import uniqid from 'uniqid';

import { DamageReportFormPropertyTypes } from '../../damage-report-form-types/damage-report-form-AON-Komposit-types/damage-report-form-AON-Komposit-property-types';

export const damageReportFormGlasHrGeschDefaultState = (
    contractId: string
): DamageReportFormPropertyTypes => {
    return {
        contractId,
        id: '',
        division: '',
        dayOfDamage: null,
        timeOfDamage: null,
        risk: '',
        contractNumber: '',
        policyHolder: '',
        insuranceCompany: '',
        fileNumberInsuredCompany: '',
        inputTaxDeductionForTheDamage: false,
        damageBeenReportedToThePolice: false,
        existenceForItemAffectedByTheDamage: false,
        insurer: '',
        areBusinessInterruptionLossesToBeExpected: false,
        policeStation: '',
        fileNumber: '',
        typeOfDamage: 'No choice',
        causedByThirdParty: '',
        files: [],
        fileInfos: [],
        itemsAffectedByTheDamage: [
            {
                id: uniqid(),
                numberAndTypeOfThings: '',
                replacementPrice: '',
                repairCosts: '',
            },
        ],
    };
};
