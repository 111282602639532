import { makeStyles, Theme } from '@material-ui/core';
import { THEME_MODES } from 'src/theming';

export const useSgiffoxxkStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        pageContainer: {
            height: '100%',
            alignContent: 'flex-start',
            padding: `0 140px`,
            backgroundColor: theme.palette.primary.main,
            [theme.breakpoints.down('lg')]: {
                padding: `0 ${theme.spacing(1)}px!important`,
            },
        },
        titleContainer: {
            width: '100%',
            height: 75,
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
                height: 50,
            },
        },
        backButton: {
            marginRight: theme.spacing(2),
            '& .MuiSvgIcon-root': {
                width: 25,
                height: 20,
            },
        },
        title: {
            color: theme.palette.primary.contrastText,
            fontWeight: theme.typography.fontWeightMedium,
        },
        mainFormContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.primary.main,
            boxShadow: 'none',
            borderRadius: theme.spacing(2),
            gap: theme.spacing(2),
        },
        boxContainer: {
            display: 'flex',
        },
        sectionContainer: {
            width: '100%',
            padding: theme.spacing(6),
            backgroundColor: theme.palette.primary.light,
            borderRadius: 10,
        },
        formBlockContainer: {
            width: '80%',
        },
        formBlockElement: {
            width: '100%',
            marginTop: theme.spacing(4),
        },
        formBlockTitle: {
            width: '100%',
            fontSize: '1.6rem',
            marginBottom: theme.spacing(3),
            marginTop: theme.spacing(3),
            color: theme.palette.primary.contrastText,
        },
        thirdPart: {
            width: `${100 / 3}%`,
        },
        fullWidth: {
            width: '100%',
        },
        dateField: {
            '& .MuiInputBase-root': {
                height: 40,
            },
        },
        icon: {
            width: 20,
            height: 20,
            color: theme.palette.primary.contrastText,
            opacity: 0.6,
        },
        helpText: {
            fontSize: '1.4rem',
        },
        checkboxIcon: {
            display: 'flex',
            alignItems: 'center',
            width: 16,
            height: 16,
            color: isDarkMode
                ? theme.palette.primary.contrastText
                : theme.palette.secondary.main,
            opacity: 0.6,
        },
        checkedIcon: {
            width: 16,
            height: 16,
            color: isDarkMode
                ? theme.palette.primary.contrastText
                : theme.palette.secondary.main,
            opacity: 1,
        },
        labelTitle: {
            fontSize: '1.4rem',
            '&.Mui-focused': {
                color: isDarkMode
                    ? theme.palette.primary.contrastText
                    : theme.palette.secondary.main,
            },
        },
        damageTypesContainer: {
            alignItems: 'flex-end',
            display: 'flex',
        },
        damageTypesGroup: {
            flexDirection: 'row',
            '& .MuiTypography-root': {
                fontSize: '1.4rem',
            },
        },
        buttonsContainer: {
            marginTop: theme.spacing(4),
        },
        groupContainer: {
            paddingTop: theme.spacing(4),
        },
        button: {
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(4),
        },
        saveButton: {
            transition: '0.2s',
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            '&:hover': {
                opacity: 0.8,
                backgroundColor: theme.palette.secondary.main,
            },
        },
        deleteButton: {
            border: 'none',
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.main,
            '& .MuiCircularProgress-svg': {
                color: theme.palette.error.main,
            },
        },
        sendButton: {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.secondary.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.info.main,
                color: theme.palette.info.contrastText,
            },
            '& .MuiCircularProgress-root': {
                color: `${theme.palette.primary.main}!important`,
            },
        },
        modalButton: {
            color: theme.palette.primary.contrastText,
        },
        cancelButton: {
            borderColor: isDarkMode
                ? theme.palette.info.main
                : theme.palette.secondary.main,
        },
        modalActionsContainer: {
            gap: theme.spacing(3),
        },
        switcherLabel: {
            fontSize: '1.4rem',
            opacity: 0.6,
        },
    };
});

export const useFormControlSgiffoxxkStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            '& label': {
                fontSize: '2.4rem',
                backgroundColor: `${theme.palette.primary.light}!important`,
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.info.main,
                },
                '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.error.main,
                },
            },
        },
    };
});

export const useFormControlLabelSgiffoxxkStyles = makeStyles(() => {
    return {
        root: {
            display: 'flex!important',
            alignItems: 'flex-start',
            margin: 0,
            padding: `0!important`,
            '&>div': {
                width: '100%',
                '& .MuiFormControl-root': {
                    width: '100%',
                },
            },
        },
        label: {
            fontSize: '1.4rem',
            opacity: 0.6,
        },
    };
});

export const useAutocompleteSgiffoxxkStyles = makeStyles(
    (theme: Theme) => {
        const isDarkMode = THEME_MODES.DARK === theme.palette.type;
        return {
            root: {
                height: 40,
                '& .MuiCircularProgress-root': {
                    marginRight: theme.spacing(2),
                    color: `${theme.palette.info.main}!important`,
                },
                '& .Mui-focused fieldset': {
                    borderColor: `${theme.palette.info.main}!important`,
                },
                '& fieldset': {
                    borderColor: ({ error }: { error: boolean }) =>
                        error
                            ? `${theme.palette.error.main}!important`
                            : 'rgba(0, 0, 0, 0.26)',
                },
            },
            inputRoot: {
                height: 40,
                padding: `0 ${theme.spacing(4)}px 0 ${theme.spacing(2)}px!important`,
            },
            paper: {
                backgroundColor: !isDarkMode
                    ? theme.palette.primary.light
                    : theme.palette.primary.main,
            },
            listbox: {
                padding: 0,
            },
            option: {
                fontSize: '1.4rem',
            },
        };
    },
    { index: 1 }
);

export const useInputSgiffoxxkStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            width: '100%',
            height: 40,
            backgroundColor: theme.palette.primary.light,
        },
        focused: {
            '& fieldSet': {
                borderColor: `${theme.palette.info.main}!important`,
            },
        },
        input: {
            '&:-internal-autofill-selected': {
                boxShadow: `inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px ${theme.palette.primary.main}`,
            },
        },
    };
});
