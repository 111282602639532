import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles(() => {
    return {
        timePicker: {
            '& .MuiButtonBase-root': {
                display: ({ editMode }: { editMode: boolean }) =>
                    !editMode ? 'none' : 'block',
                padding: 0,
            },
            '& .MuiSvgIcon-root': {
                width: 15,
                height: 15,
            },
        },
    };
});
