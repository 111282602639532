import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { Grid, List, ListItem, ListItemText } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

import { ThemeNestedMenu, ThemeLoader } from 'src/theming';

import { VehicleDeregistration } from 'src/contracts/contract-vehicle-deregistration-form/contract-vehicle-deregistration-form';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useAbilityCtx } from 'src/roleAccesses';

import { ContractDetailsListItem } from '../contract-details-list-item';

import {
    useStyle,
    useContractDetailsViewDataStyle,
} from '../contracts-details-view-styles';

import { generateContractsDetailsAdditionalMenu } from '../contract-details-view-configs';

import { ContractDetailsViewDetailsResponseBodyKfzw } from '../contract-details-view-kfzw/contract-details-view-details-response-body-kfzw';

export enum SECTIONS {
    CONTRACTS = 'contracts',
    DAMAGES = 'damages',
}

interface ContractDetailsViewKFZWDetailsProps {
    handleOpenContractEdit: () => void;
    hideThemeNestedMenu: boolean;
    documentFormats: string[];
    contractNumber: string;
}

export const ContractDetailsViewKFZWDetails = (
    props: ContractDetailsViewKFZWDetailsProps
): JSX.Element => {
    const { t } = useTranslation([
        'contract-details-view',
        'formatted-values',
        'damages-list',
        'common',
    ]);
    const classes = useStyle();
    const contractDetailsViewDataClasses = useContractDetailsViewDataStyle();
    const ability = useAbilityCtx();

    const {
        handleOpenContractEdit,
        hideThemeNestedMenu,
        documentFormats,
        contractNumber,
    } = props;

    const router = useHistory();
    const { id } = useParams<{ id: string }>();
    const httpClient = useHttpClient();

    const [isVehicleDeregistrationOpen, setVehicleDeregistrationOpen] = useState(false);
    const [isLoadingDetails, setLoadingDetails] = useState(false);
    const [detailsData, setDetailsData] =
        useState<ContractDetailsViewDetailsResponseBodyKfzw>();

    const makeRequestForDetails =
        async (): Promise<ContractDetailsViewDetailsResponseBodyKfzw> => {
            setLoadingDetails(true);
            return httpClient.get<ContractDetailsViewDetailsResponseBodyKfzw>(
                `contracts/${id}/kfzw`
            );
        };

    useEffect(() => {
        makeRequestForDetails()
            .then((data: ContractDetailsViewDetailsResponseBodyKfzw) => {
                setDetailsData(data);
            })
            .finally(() => {
                setLoadingDetails(false);
            });
    }, []);

    const handleCloseVehicleDeregistration = (): void => {
        setVehicleDeregistrationOpen(false);
    };

    const handleOpenVehicleDeregistration = (): void => {
        setVehicleDeregistrationOpen(true);
    };

    const goTo = (pathname: string): void => {
        router.push(pathname);
    };

    const menuItems = useMemo(
        () =>
            generateContractsDetailsAdditionalMenu(
                t,
                handleOpenVehicleDeregistration,
                handleOpenContractEdit,
                goTo,
                ability,
                id,
                contractNumber
            ),
        [t, contractNumber, ability]
    );

    const isDetailDataEmpty = useMemo(() => {
        if (detailsData) {
            const detailsDataKeys = Object.keys(detailsData);
            return !detailsDataKeys
                ?.map((x) => detailsData[x])
                ?.filter((x) => x || typeof x === 'boolean')?.length;
        }
        return true;
    }, [detailsData]);

    return (
        <Grid container>
            <Grid container className={classes.titleAndMenuContainer}>
                <div style={{ flexGrow: 1 }} />
                {!hideThemeNestedMenu &&
                    Array.isArray(menuItems) &&
                    menuItems.length > 0 && (
                        <ThemeNestedMenu
                            menuItems={menuItems}
                            buttonClass={classes.additionalMenuButton}
                            buttonIcon={<MenuIcon className={classes.menuIcon} />}
                        />
                    )}
            </Grid>
            {isLoadingDetails && <ThemeLoader />}
            {isDetailDataEmpty && !isLoadingDetails && (
                <ListItem>
                    <ListItemText data-title>
                        {t('contract-details-view:noContractDetails')}
                    </ListItemText>
                </ListItem>
            )}
            {detailsData && !isLoadingDetails && !isDetailDataEmpty && (
                <>
                    <List className={contractDetailsViewDataClasses.list}>
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.mark'
                            value={detailsData?.licenseNumber}
                            hidden={!detailsData?.licenseNumber}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.markType'
                            value={detailsData?.licenseNumberType}
                            hidden={!detailsData?.licenseNumberType}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.manufacturer'
                            value={detailsData?.manufacturer}
                            hidden={!detailsData?.manufacturer}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.wkz'
                            value={detailsData?.wkz}
                            hidden={!detailsData?.wkz}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.hsn'
                            value={detailsData?.hzn}
                            hidden={!detailsData?.hzn}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.tsn'
                            value={detailsData?.tsn}
                            hidden={!detailsData?.tsn}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.firstRegistration'
                            value={t('formatted-values:formattedDate', {
                                date: detailsData.firstRegistration,
                            })}
                            hidden={!detailsData?.firstRegistration}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.chassisNumber'
                            value={detailsData?.chassisNumber}
                            hidden={!detailsData?.chassisNumber}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.likeNewValue'
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.likeNewValue,
                            })}
                            hidden={typeof detailsData?.likeNewValue !== 'number'}
                        />
                    </List>
                    <List className={contractDetailsViewDataClasses.list}>
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.leasingFinancing'
                            value={detailsData?.leasingOrFinancing}
                            hidden={!detailsData?.leasingOrFinancing}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.lessor'
                            value={detailsData?.lessor}
                            hidden={!detailsData?.lessor}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.liability'
                            value={
                                detailsData?.liability ? t('common:yes') : t('common:no')
                            }
                            hidden={!detailsData?.lessor}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.fullyComprehensive'
                            value={
                                detailsData?.fullyCoverage
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            hidden={!detailsData?.fullyCoverage}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.partialCoverage'
                            value={
                                detailsData?.partialCoverage
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            hidden={!detailsData?.partialCoverage}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.agreedSBVK'
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.agreedSbVk,
                            })}
                            hidden={typeof detailsData?.agreedSbVk !== 'number'}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.agreedSBTK'
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.agreedSbTk,
                            })}
                            hidden={typeof detailsData?.agreedSbTk !== 'number'}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.vehicleUsers'
                            value={detailsData?.vehicleUsers}
                            hidden={!detailsData?.vehicleUsers}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.costCenterVN'
                            value={detailsData?.costCenterVn}
                            hidden={!detailsData?.costCenterVn}
                        />
                    </List>
                    <List className={contractDetailsViewDataClasses.list}>
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.accident'
                            value={
                                detailsData?.accident ? t('common:yes') : t('common:no')
                            }
                            hidden={!detailsData?.accident}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.luggage'
                            value={
                                detailsData?.luggage ? t('common:yes') : t('common:no')
                            }
                            hidden={!detailsData?.luggage}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.coverLetter'
                            value={
                                detailsData?.coverLetter
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            hidden={!detailsData?.coverLetter}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.driverProtection'
                            value={
                                detailsData?.driverProtection
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            hidden={!detailsData?.driverProtection}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.embezzlement'
                            value={
                                detailsData?.embezzlement
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            hidden={!detailsData?.embezzlement}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.CAPCoverage'
                            value={
                                detailsData?.gapCoverage
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            hidden={!detailsData?.gapCoverage}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.BBBShame'
                            value={
                                detailsData?.bbbDamage ? t('common:yes') : t('common:no')
                            }
                            hidden={!detailsData?.bbbDamage}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.lossRepurchase'
                            value={
                                detailsData?.lossRepurchase
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            hidden={!detailsData?.lossRepurchase}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.ergoLegalProtection'
                            value={
                                detailsData?.ergoLegalProtectionSV74539221
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            hidden={!detailsData?.ergoLegalProtectionSV74539221}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.ergoCoverLetter'
                            value={
                                detailsData?.ergoProtectionLetterSV74518810_0
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            hidden={!detailsData?.ergoProtectionLetterSV74518810_0}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.fee'
                            value={detailsData?.fee ? t('common:yes') : t('common:no')}
                            hidden={!detailsData?.fee}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.damageServicePlus'
                            value={
                                detailsData?.damageServicePlus
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            hidden={!detailsData?.damageServicePlus}
                        />
                    </List>
                </>
            )}
            <VehicleDeregistration
                documentFormats={documentFormats}
                isOpen={isVehicleDeregistrationOpen}
                handleCloseModalWindow={handleCloseVehicleDeregistration}
            />
        </Grid>
    );
};
