import React from 'react';
import { ThemeOptions, ThemeProvider } from '@material-ui/core';
import {
    darkPaletteForHector,
    lightPaletteForHector,
} from 'src/theming/themes/hector/hector-theme-palettes';
import { THEME_MODES } from 'src/theming/themes/theme-modes';

interface HectorThemeProviderProps {
    children: JSX.Element;
}

export const HectorThemeProvider = (props: HectorThemeProviderProps): JSX.Element => {
    return (
        <ThemeProvider
            theme={(outerTheme): ThemeOptions => {
                const hectorTheme: ThemeOptions = { ...outerTheme };
                if (hectorTheme.palette.type === THEME_MODES.DARK) {
                    hectorTheme.palette = {
                        ...hectorTheme.palette,
                        ...darkPaletteForHector,
                    };
                } else {
                    hectorTheme.palette = {
                        ...hectorTheme.palette,
                        ...lightPaletteForHector,
                    };
                }
                return hectorTheme;
            }}
        >
            {props.children}
        </ThemeProvider>
    );
};
