import { TFunction } from 'i18next';

interface PrivilegesRolesTableHeader {
    id: string;
    headerName: string;
    className?: string;
}

export const generatePrivilegesListTableHeader = (
    t: TFunction
): PrivilegesRolesTableHeader[] => {
    return [
        {
            headerName: t('user-management:privilegesSection.table.fieldName'),
            id: '1',
            className: 'fieldNameCell',
        },
        {
            headerName: t('user-management:roles.adminTechnical'),
            id: '2',
        },
        {
            headerName: t('user-management:roles.adminDomain'),
            id: '3',
        },
        {
            headerName: t('user-management:roles.customerAdviser'),
            id: '4',
        },
        {
            headerName: t('user-management:roles.accountOwner'),
            id: '5',
        },
        {
            headerName: t('user-management:roles.administrator'),
            id: '6',
        },
        {
            headerName: t('user-management:roles.clerk'),
            id: '7',
        },
        {
            headerName: t('user-management:roles.insurance'),
            id: '8',
        },
        {
            headerName: t('user-management:roles.supplier'),
            id: '9',
        },
        {
            headerName: t('user-management:roles.presentation'),
            id: '10',
        },
    ];
};
