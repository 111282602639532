import {
    LIGHT_HECTOR_THEME_COLORS,
    DARK_HECTOR_THEME_COLORS,
} from './hector-theme-colors';
import { THEME_MODES } from '..';

export const lightPaletteForHector = {
    type: THEME_MODES.LIGHT,
    primary: {
        main: LIGHT_HECTOR_THEME_COLORS.PRIMARY_MAIN_COLOR,
        light: LIGHT_HECTOR_THEME_COLORS.PRIMARY_LIGHT_COLOR,
        dark: LIGHT_HECTOR_THEME_COLORS.PRIMARY_DARK_COLOR,
        contrastText: LIGHT_HECTOR_THEME_COLORS.PRIMARY_CONTRAST_TEXT,
    },
    secondary: {
        main: LIGHT_HECTOR_THEME_COLORS.SECONDARY_MAIN_COLOR,
        light: LIGHT_HECTOR_THEME_COLORS.SECONDARY_LIGHT_COLOR,
        dark: LIGHT_HECTOR_THEME_COLORS.SECONDARY_DARK_COLOR,
        contrastText: LIGHT_HECTOR_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
    },
    info: {
        main: LIGHT_HECTOR_THEME_COLORS.INFO_MAIN_COLOR,
        light: LIGHT_HECTOR_THEME_COLORS.INFO_LIGHT_COLOR,
        contrastText: LIGHT_HECTOR_THEME_COLORS.INFO_CONTRAST_TEXT,
    },
    error: {
        main: LIGHT_HECTOR_THEME_COLORS.ERROR_MAIN_COLOR,
        light: LIGHT_HECTOR_THEME_COLORS.ERROR_LIGHT_COLOR,
    },
};

export const darkPaletteForHector = {
    type: THEME_MODES.DARK,
    primary: {
        main: DARK_HECTOR_THEME_COLORS.PRIMARY_MAIN_COLOR,
        light: DARK_HECTOR_THEME_COLORS.PRIMARY_LIGHT_COLOR,
        dark: DARK_HECTOR_THEME_COLORS.PRIMARY_DARK_COLOR,
        contrastText: DARK_HECTOR_THEME_COLORS.PRIMARY_CONTRAST_TEXT,
    },
    secondary: {
        main: DARK_HECTOR_THEME_COLORS.SECONDARY_MAIN_COLOR,
        light: DARK_HECTOR_THEME_COLORS.SECONDARY_LIGHT_COLOR,
        dark: DARK_HECTOR_THEME_COLORS.SECONDARY_DARK_COLOR,
        contrastText: DARK_HECTOR_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
    },
    info: {
        main: DARK_HECTOR_THEME_COLORS.INFO_MAIN_COLOR,
        light: DARK_HECTOR_THEME_COLORS.INFO_LIGHT_COLOR,
        contrastText: DARK_HECTOR_THEME_COLORS.INFO_CONTRAST_TEXT,
    },
    error: {
        main: DARK_HECTOR_THEME_COLORS.ERROR_MAIN_COLOR,
        light: DARK_HECTOR_THEME_COLORS.ERROR_LIGHT_COLOR,
    },
};
