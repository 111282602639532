import { makeStyles, Theme } from '@material-ui/core';

export const useGlobalAutocompleteSearchBadgeCeatStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            width: '100%',
        },
        badge: {
            width: 'fit-content',
            minWidth: 22,
            height: 22,
            right: 68,
            top: 17,
            backgroundColor: theme.palette.secondary.contrastText,
            color: theme.palette.secondary.main,
            fontSize: '1.4rem',
        },
    };
});

export const useGlobalSearchAutocompliteFilterCeatStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            borderRight: `1px solid ${theme.palette.primary.dark}`,
            '& .MuiAutocomplete-root': {
                height: 33,
                marginBottom: 1,
                '& fieldset': {
                    border: 'none',
                },
                '& .MuiAutocomplete-endAdornment': {
                    top: 5,
                    right: 33,
                    '& .MuiSvgIcon-root': {
                        fontSize: 20,
                        width: '1em',
                        height: '1em',
                        color: theme.palette.secondary.contrastText,
                    },
                },
            },
            '& .MuiInputBase-root': {
                height: 33,
                paddingLeft: 27,
            },
            '& .MuiInputBase-input': {
                paddingTop: `${0}!important`,
                paddingBottom: `${0}!important`,
                paddingRight: `${45}px!important`,
                height: 33,
                caretColor: theme.palette.secondary.contrastText,
                color: theme.palette.secondary.contrastText,
                fontSize: '1.4rem',
                '&::placeholder': {
                    opacity: 1,
                    color: `${theme.palette.secondary.contrastText}!important`,
                },
            },
        },
    };
});
