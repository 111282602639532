import React, { FC } from 'react';

import { useDashboardContext } from 'src/shared/contexts';
import LoadingIndicator from 'src/shared/loading-indicator';

import { ValueWithTrendPercentage } from '..';
import { ValueChangeColorScheme } from '../common';
import { DashboardCard } from '../dashboard-card/dashboard-card';
import { DashboardNumericValue } from '../dashboard-numeric-value/dashboard-numeric-value';
import { DashboardListOfNumericValues } from '../dashboard-list-of-numeric-values/dashboard-list-of-numeric-values';

interface Props {
    title: string;
    editMode: boolean;
    expense: ValueWithTrendPercentage<{ year: string; value: number }[]>;
    isShown: boolean;
    handleChangeShown?: (name: string, value: boolean) => void;
    name?: string;
}

export const DashboardEsaExpense: FC<Props> = ({
    title,
    editMode,
    isShown,
    handleChangeShown,
    name,
    expense,
}): JSX.Element => {
    const { isGetDashboardDataLoading } = useDashboardContext();

    return (
        <DashboardCard
            header={title}
            editMode={editMode}
            isShown={isShown}
            handleChangeShown={handleChangeShown}
            name={name}
        >
            <>
                {isGetDashboardDataLoading && <LoadingIndicator />}
                {!isGetDashboardDataLoading &&
                    Array.isArray(expense?.dashboardValues) &&
                    expense.dashboardValues.length <= 1 && (
                        <DashboardNumericValue
                            value={expense?.dashboardValues[0]?.value || 0}
                            offset={
                                expense
                                    ? {
                                          offset: expense.trendPercentage,
                                          unit: '%',
                                          colorScheme:
                                              ValueChangeColorScheme.IncreaseBadDecreaseGood,
                                      }
                                    : undefined
                            }
                            unit='€'
                        />
                    )}
                {!isGetDashboardDataLoading &&
                    Array.isArray(expense?.dashboardValues) &&
                    expense?.dashboardValues.length > 1 && (
                        <DashboardListOfNumericValues
                            values={expense?.dashboardValues}
                            unit='€'
                        />
                    )}
            </>
        </DashboardCard>
    );
};
