import uniqid from 'uniqid';

import { DamageReportFormAONKompositLiabilityTypes } from '../../damage-report-form-types/damage-report-form-AON-Komposit-types/damage-report-form-AON-Komposit-liability-types';

export const damageReportFormAONKompositBhvPhvDefaultState = (
    contractId: string
): DamageReportFormAONKompositLiabilityTypes => {
    return {
        contractId,
        id: '',
        division: '',
        dayOfDamage: null,
        timeOfDamage: null,
        risk: '',
        contractNumber: '',
        policyHolder: '',
        insuranceCompany: '',
        fileNumberInsuredCompany: '',
        damageLocation: '',
        damageCause: '',
        courseOfTheDamage: '',
        expectedAmountOfDamage: '',
        blameForTheDamage: false,
        because: '',
        relationshipBetweenYouAndTheInjuredPerson: false,
        wasTheDamagedItemRentedLeasedLoaned: false,
        affectedByTheDamageAlsoThroughAnotherContract: false,
        itemsAffectedByTheDamage: [
            {
                id: uniqid(),
                numberAndTypeOfItems: '',
                acquisitionOrReplacementPrice: '',
                repairCosts: '',
            },
        ],
        injuredPersonsOrClaimants: '',
        investigationToBeExpected: false,
        productLiabilityIncludingRecallDamage: false,
        financialLossIncludingFidelityOrComputerAbuse: false,
        descriptionOfTheProductCausingTheDamage: '',
        purposeOfUse: '',
        manufacturerSellerDealerOfTheProduct: '',
        damageCausedByYourSubSupplier: '',
        howManyOfYourProductsAreDefective: '',
        deliveryDate: '',
        batchBasedOnSerialNumberingRecognizable: '',
        etainedSamplesExist: '',
        exitInspectionBeenCarriedOut: '',
        defectProduct: '',
        blameForTheDefect: '',
        elationshipBetweenYouAndTheClaimant: [],
        files: [],
        fileInfos: [],
    };
};
