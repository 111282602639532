import { useMemo } from 'react';

import { SelectItem } from 'src/theming';

import { useGlobalFilter } from 'src/shared/contexts';

export const useCustomerConnections = (): SelectItem[] => {
    const { filter } = useGlobalFilter();
    return useMemo(
        () =>
            filter.customerConnections
                .filter((customer) => customer.value !== 'all')
                .map((customer) => ({ title: customer.title, value: customer.value }))
                .sort((a, b) => a.title.localeCompare(b.title)),
        [filter]
    );
};
