import { TFunction } from 'i18next';
import { CheckboxItem } from 'src/theming';

export enum Documents {
    FORWARDING_ORDER = 'forwardingOrder',
    DAMAGE_LOG = 'damageLog',
    DAMAGE_ACCOUNT = 'damageAccount',
    INSURANCE_DECLARATION_FROM_RECIPIENT = 'insuranceDeclarationFromRecipient',
    AVERAGE_CERTIFICATE_AND_EXPERT_OPINION = 'averageCertificateAndExpertOpinion',
    SEARCH_MESSAGES = 'searchMessages',
    DELIVERY_ORDER = 'deliveryOrder',
    PROOF_OF_VALUE_SALES_INVOICE = 'proofOfValueSalesInvoice',
    INSURANCE_DECLARATION_FROM_SENDER = 'insuranceDeclarationFromSender',
    ROLL_CARD = 'rollCard',
    RESULT_OF_POLICE_INVESTIGATION = 'resultOfPoliceInvestigation',
    DAMAGE_PHOTOS = 'damagePhotos',
}

export const damageReportFormTRANSPDocumentsOptions = (
    t: TFunction,
    chosenDocuments: string[]
): CheckboxItem[] => {
    return [
        {
            title: t('damages-report:documentOptions.forwardingOrder'),
            value: Documents.FORWARDING_ORDER,
            checked: chosenDocuments.includes(Documents.FORWARDING_ORDER),
            data: undefined,
        },
        {
            title: t('damages-report:documentOptions.damageLog'),
            value: Documents.DAMAGE_LOG,
            checked: chosenDocuments.includes(Documents.DAMAGE_LOG),
            data: undefined,
        },
        {
            title: t('damages-report:documentOptions.damageAccount'),
            value: Documents.DAMAGE_ACCOUNT,
            checked: chosenDocuments.includes(Documents.DAMAGE_ACCOUNT),
            data: undefined,
        },
        {
            title: t('damages-report:documentOptions.insuranceDeclarationFromRecipient'),
            value: Documents.INSURANCE_DECLARATION_FROM_RECIPIENT,
            checked: chosenDocuments.includes(
                Documents.INSURANCE_DECLARATION_FROM_RECIPIENT
            ),
            data: undefined,
        },
        {
            title: t('damages-report:documentOptions.averageCertificateAndExpertOpinion'),
            value: Documents.AVERAGE_CERTIFICATE_AND_EXPERT_OPINION,
            checked: chosenDocuments.includes(
                Documents.AVERAGE_CERTIFICATE_AND_EXPERT_OPINION
            ),
            data: undefined,
        },
        {
            title: t('damages-report:documentOptions.searchMessages'),
            value: Documents.SEARCH_MESSAGES,
            checked: chosenDocuments.includes(Documents.SEARCH_MESSAGES),
            data: undefined,
        },
        {
            title: t('damages-report:documentOptions.deliveryOrder'),
            value: Documents.DELIVERY_ORDER,
            checked: chosenDocuments.includes(Documents.DELIVERY_ORDER),
            data: undefined,
        },
        {
            title: t('damages-report:documentOptions.proofOfValueSalesInvoice'),
            value: Documents.PROOF_OF_VALUE_SALES_INVOICE,
            checked: chosenDocuments.includes(Documents.PROOF_OF_VALUE_SALES_INVOICE),
            data: undefined,
        },
        {
            title: t('damages-report:documentOptions.insuranceDeclarationFromSender'),
            value: Documents.INSURANCE_DECLARATION_FROM_SENDER,
            checked: chosenDocuments.includes(
                Documents.INSURANCE_DECLARATION_FROM_SENDER
            ),
            data: undefined,
        },
        {
            title: t('damages-report:documentOptions.rollCard'),
            value: Documents.ROLL_CARD,
            checked: chosenDocuments.includes(Documents.ROLL_CARD),
            data: undefined,
        },
        {
            title: t('damages-report:documentOptions.resultOfPoliceInvestigation'),
            value: Documents.RESULT_OF_POLICE_INVESTIGATION,
            checked: chosenDocuments.includes(Documents.RESULT_OF_POLICE_INVESTIGATION),
            data: undefined,
        },
        {
            title: t('damages-report:documentOptions.damagePhotos'),
            value: Documents.DAMAGE_PHOTOS,
            checked: chosenDocuments.includes(Documents.DAMAGE_PHOTOS),
            data: undefined,
        },
    ];
};
