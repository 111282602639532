import React, { SyntheticEvent } from 'react';
import { TFunction } from 'i18next';

import { ThemeDraggableElement } from 'src/theming';

import { GridColDef } from '@material-ui/data-grid';
import { IconButton } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';

import { CheckboxItem } from 'src/theming';
import { CellWithSearch } from 'src/theming/theme-table/cell-with-search';
import {
    renderCellAsDateExpand,
    renderCellExpand,
} from 'src/theming/theme-table/theme-table-cell-expand';

import { ColumnHeaderWithInnerRef } from 'src/contracts/contracts-list/contracts-list-configs';

export enum ColumnKeysForDamageListWithCreatedStatus {
    OPEN = '',
    VNR = 'contractNumber',
    CLIENT = 'customer',
    RISK = 'kfzLicenseNumber',
    INSURANCE_COMPANY = 'insuranceCompany',
    LIABILITY = 'liability',
    FULLY_COMPREHENSIVE = 'fullyCoverage',
    PARTIAL_COVERAGE = 'partialCoverage',
    DAMAGE_DAY = 'dayOfDamage',
}

export const generateDamageListTableHeaderForCreatedStatus = (
    t: TFunction,
    vnr: string,
    handleChangeSearchValue: (e: SyntheticEvent) => void,
    columnsSettings: CheckboxItem[],
    handleGoToTheDetailView: (gridRowParameter: { id: string | number }) => void,
    hoverPosition: number,
    startPosition: number,
    isDragAndDropLocked: boolean
): GridColDef[] => {
    return [
        {
            field: ColumnKeysForDamageListWithCreatedStatus.OPEN,
            width: 60,
            renderCell: ({ id }) => (
                <IconButton
                    onClick={() => {
                        handleGoToTheDetailView({ id });
                    }}
                >
                    <Visibility />
                </IconButton>
            ),
        },
        {
            field: ColumnKeysForDamageListWithCreatedStatus.VNR,
            headerName: t('damages-list:tableHeaders.vnr'),
            flex: 1.5,
            renderHeader: (props) => (
                <ThemeDraggableElement
                    isDragAndDropLocked={isDragAndDropLocked}
                    draggableId={ColumnKeysForDamageListWithCreatedStatus.VNR}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value ===
                                ColumnKeysForDamageListWithCreatedStatus.VNR
                        )?.data?.position
                    }
                >
                    <CellWithSearch
                        {...props}
                        title={t('damages-list:tableHeaders.vnr')}
                        value={vnr}
                        handleChange={handleChangeSearchValue}
                        name='vnr'
                    />
                </ThemeDraggableElement>
            ),
            hide: !columnsSettings.find(
                (column: CheckboxItem) =>
                    column.value === ColumnKeysForDamageListWithCreatedStatus.VNR
            )?.checked,
            renderCell: renderCellExpand,
        },
        {
            field: ColumnKeysForDamageListWithCreatedStatus.CLIENT,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) =>
                    column.value === ColumnKeysForDamageListWithCreatedStatus.CLIENT
            )?.checked,
            renderCell: renderCellExpand,
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeysForDamageListWithCreatedStatus.CLIENT}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value ===
                                ColumnKeysForDamageListWithCreatedStatus.CLIENT
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('damages-list:tableHeaders.client')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeysForDamageListWithCreatedStatus.RISK,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) =>
                    column.value === ColumnKeysForDamageListWithCreatedStatus.RISK
            )?.checked,
            renderCell: renderCellExpand,
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeysForDamageListWithCreatedStatus.RISK}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value ===
                                ColumnKeysForDamageListWithCreatedStatus.RISK
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('damages-list:tableHeaders.risk')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeysForDamageListWithCreatedStatus.INSURANCE_COMPANY,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) =>
                    column.value ===
                    ColumnKeysForDamageListWithCreatedStatus.INSURANCE_COMPANY
            )?.checked,
            renderCell: renderCellExpand,
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={
                        ColumnKeysForDamageListWithCreatedStatus.INSURANCE_COMPANY
                    }
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value ===
                                ColumnKeysForDamageListWithCreatedStatus.INSURANCE_COMPANY
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('damages-list:tableHeaders.insuranceCompany')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeysForDamageListWithCreatedStatus.DAMAGE_DAY,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) =>
                    column.value === ColumnKeysForDamageListWithCreatedStatus.DAMAGE_DAY
            )?.checked,
            renderCell: renderCellAsDateExpand,
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeysForDamageListWithCreatedStatus.DAMAGE_DAY}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value ===
                                ColumnKeysForDamageListWithCreatedStatus.DAMAGE_DAY
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('damages-list:tableHeaders.claimDay')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeysForDamageListWithCreatedStatus.LIABILITY,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) =>
                    column.value === ColumnKeysForDamageListWithCreatedStatus.LIABILITY
            )?.checked,
            renderCell: renderCellExpand,
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeysForDamageListWithCreatedStatus.LIABILITY}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value ===
                                ColumnKeysForDamageListWithCreatedStatus.LIABILITY
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('damages-list:tableHeaders.liability')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeysForDamageListWithCreatedStatus.FULLY_COMPREHENSIVE,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) =>
                    column.value ===
                    ColumnKeysForDamageListWithCreatedStatus.FULLY_COMPREHENSIVE
            )?.checked,
            renderCell: renderCellExpand,
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={
                        ColumnKeysForDamageListWithCreatedStatus.FULLY_COMPREHENSIVE
                    }
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value ===
                                ColumnKeysForDamageListWithCreatedStatus.FULLY_COMPREHENSIVE
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('damages-list:tableHeaders.fullyComprehensive')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeysForDamageListWithCreatedStatus.PARTIAL_COVERAGE,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) =>
                    column.value ===
                    ColumnKeysForDamageListWithCreatedStatus.PARTIAL_COVERAGE
            )?.checked,
            renderCell: renderCellExpand,
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={
                        ColumnKeysForDamageListWithCreatedStatus.PARTIAL_COVERAGE
                    }
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value ===
                                ColumnKeysForDamageListWithCreatedStatus.PARTIAL_COVERAGE
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('damages-list:tableHeaders.partialCoverage')}
                    />
                </ThemeDraggableElement>
            ),
        },
    ];
};
