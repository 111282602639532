import React, { BaseSyntheticEvent, memo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';

import { Grid, IconButton, Typography } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import { FormElement } from 'src/contracts/damage-report-form/damage-report-form-config/damage-report-form-config-GBW-HR-GESCH';
import { useCustomerAddFormStyle } from './customer-add-form-styles';

interface Props {
    rows: { id: string; formElements: FormElement[] }[];
    handleAddRow: () => void;
    handleDeleteRow: (id: string) => void;
    handleOnChangeRow: (id: string, row: unknown) => void;
    needToDeleteFirstRow?: boolean;
    classes?: string;
    className?: string;
    t?: TFunction<string[]>;
}

const Row = ({
    rowsLength,
    row,
    id,
    handleDeleteRow,
    handleOnChangeRow,
}): JSX.Element => {
    const { t } = useTranslation();
    const classes = useCustomerAddFormStyle();

    const { setValue, getValues, watch } = useForm({ defaultValues: { ...row } });

    const handleChangeValue = (e: BaseSyntheticEvent): void => {
        setValue(e.target.name, e.target.value);
    };

    const names = row.formElements.map(({ name }) => name);

    const values = watch(names);

    const onBlur = (): void => {
        const row: { id: string; [key: string]: string | number } = {
            id,
        };
        names.forEach((item, index) => {
            row[item] = values[index];
        });
        handleOnChangeRow(id, row);
    };
    return (
        <>
            <Grid
                container
                key={row.id}
                alignItems='center'
                justify='space-between'
                wrap='nowrap'
            >
                <Grid item justify='space-between' spacing={2} md={10}>
                    {row.formElements.map(({ Item, name, label, error, maxlength }) => {
                        const valueFromInnerState = getValues(name);
                        return (
                            <Item
                                key={`${name}-${row.id}`}
                                name={name}
                                label={t(label)}
                                value={valueFromInnerState}
                                handleChangeValue={handleChangeValue}
                                onBlur={onBlur}
                                error={error}
                                maxlength={maxlength}
                            />
                        );
                    })}
                </Grid>
                <Grid item container justify='center' spacing={2} md={2}>
                    {handleDeleteRow && rowsLength === 1 && (
                        <IconButton
                            onClick={() => handleDeleteRow(row.id)}
                            className={clsx(classes.iconButton, classes.iconDeleteButton)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export const DamageReportFormDynamicFieldsRow = memo(
    ({
        rows,
        handleAddRow,
        handleDeleteRow,
        handleOnChangeRow,
        classes,
        className,
        t,
    }: Props): JSX.Element => {
        if (!Array.isArray(rows)) {
            return null;
        }
        return (
            <>
                {rows.length === 0 && (
                    <Grid container alignItems='center' wrap='nowrap'>
                        <Grid container item justify='space-between' spacing={2} md={2}>
                            <IconButton onClick={handleAddRow} className={classes}>
                                <AddIcon />
                            </IconButton>
                        </Grid>
                        <Grid container item justify='space-between' spacing={2} md={10}>
                            <Typography variant='h6' className={className}>
                                {t('customer-form:newCustomerConnectionName')}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {rows.map((row, index, arr) => {
                    return (
                        <Row
                            key={row.id}
                            row={row}
                            id={row.id}
                            rowsLength={arr.length}
                            handleDeleteRow={handleDeleteRow}
                            handleOnChangeRow={handleOnChangeRow}
                        />
                    );
                })}
            </>
        );
    }
);
