import React from 'react';
import { TFunction } from 'i18next';
import { AnyAbility } from '@casl/ability';

import { GridCellParams, GridColDef, GridRowId } from '@material-ui/data-grid';
import { IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Visibility } from '@material-ui/icons';

import { AbilityActions, AbilitySubjects } from 'src/roleAccesses';
import { MenuItem, ThemeNestedMenu } from 'src/theming';
import { renderCellExpand } from 'src/theming/theme-table/theme-table-cell-expand';
import { APP_ROUTES } from 'src/routing';
import {
    AppInstances,
    appInstancesThatHaveAccessToCreateContract,
} from 'src/shared/constants';
import { appInstance } from 'src/environment';

enum CustomersListColumnKeys {
    open = '',
    customerConnection = 'customerConnection',
    client = 'customer',
    secondName = 'name2',
    thirdName = 'name3',
    plz = 'postcode',
    place = 'city',
    street = 'street',
    actions = 'actions',
}

export const generateCustomerListItemAdditionalMenu = (
    t: TFunction,
    goTo: (pathname: string, customers?: string[], search?: string) => void,
    id: GridRowId,
    ability: AnyAbility,
    isDummy?: boolean
): MenuItem[] => {
    return [
        {
            title: t('customers-list:displayMasterData'),
            action: () => goTo(`${APP_ROUTES.CUSTOMERS}/${id}`),
        },
        ...(appInstancesThatHaveAccessToCreateContract.includes(
            appInstance as AppInstances
        ) &&
        ability.can(AbilityActions.edit, AbilitySubjects.customer) &&
        isDummy
            ? [
                  {
                      title: t('customers-list:editCustomer'),
                      action: () => goTo(`customer-form/${id}`),
                  },
              ]
            : []),
        ...(appInstancesThatHaveAccessToCreateContract.includes(
            appInstance as AppInstances
        ) &&
        ability.can(AbilityActions.create, AbilitySubjects.dummyContract) &&
        isDummy
            ? [
                  {
                      title: t('customers-list:createContract'),
                      action: () =>
                          goTo(APP_ROUTES.DUMMY_CONTRACT, null, `customerId=${id}`),
                  },
              ]
            : []),
    ];
};

export const generateCustomerListTable = (
    t: TFunction,
    goTo: (pathname: string, customers?: string[]) => void,
    openDetailsView: (pathname: string) => void,
    ability: AnyAbility
): GridColDef[] => {
    return [
        {
            field: CustomersListColumnKeys.open,
            width: 60,
            renderCell: ({ id }) => (
                <IconButton
                    onClick={() => {
                        openDetailsView(`${APP_ROUTES.CUSTOMERS}/${id}`);
                    }}
                >
                    <Visibility />
                </IconButton>
            ),
        },
        {
            field: CustomersListColumnKeys.customerConnection,
            headerName: t('customers-list:clientConnection'),
            flex: 1.5,
            renderCell: renderCellExpand,
        },
        {
            field: CustomersListColumnKeys.client,
            headerName: t('customers-list:client'),
            flex: 1.5,
            renderCell: renderCellExpand,
        },
        {
            field: CustomersListColumnKeys.secondName,
            headerName: t('customers-list:name', { add: 2 }),
            flex: 1,
            renderCell: renderCellExpand,
        },
        {
            field: CustomersListColumnKeys.thirdName,
            headerName: t('customers-list:name', { add: 3 }),
            flex: 1,
            renderCell: renderCellExpand,
        },
        {
            field: CustomersListColumnKeys.plz,
            headerName: t('customers-list:plz'),
            flex: 0.5,
            renderCell: renderCellExpand,
        },
        {
            field: CustomersListColumnKeys.place,
            headerName: t('customers-list:place'),
            flex: 1,
            renderCell: renderCellExpand,
        },
        {
            field: CustomersListColumnKeys.street,
            headerName: t('customers-list:street'),
            flex: 1,
            renderCell: renderCellExpand,
        },
        {
            field: CustomersListColumnKeys.actions,
            headerName: t('customers-list:actions'),
            flex: 0.5,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params: GridCellParams) => {
                return (
                    <ThemeNestedMenu
                        menuItems={generateCustomerListItemAdditionalMenu(
                            t,
                            goTo,
                            params.id,
                            ability,
                            params.row.isDummy
                        )}
                        buttonIcon={<MoreVertIcon />}
                    />
                );
            },
        },
    ];
};
