export enum LIGHT_SGIFFOXX_THEME_COLORS {
    PRIMARY_MAIN_COLOR = '#F9F9FB',
    PRIMARY_LIGHT_COLOR = '#FFFFFF',
    PRIMARY_DARK_COLOR = '#EFEFEF',
    PRIMARY_CONTRAST_TEXT = '#000000',
    SECONDARY_MAIN_COLOR = '#05492C',
    SECONDARY_LIGHT_COLOR = '#05492C',
    SECONDARY_CONTRAST_TEXT = '#FFFFFF',
    INFO_MAIN_COLOR = '#05492C',
    INFO_CONTRAST_TEXT = '#ffffff',
    ERROR_MAIN_COLOR = '#CB362D',
    ERROR_LIGHT_COLOR = 'rgba(203, 54, 45, 0.1)',
    PLACE_HOLDER_TEXT_COLOR = '#4B4B4B',
}

export enum DARK_SGIFFOXX_THEME_COLORS {
    PRIMARY_MAIN_COLOR = '#293430',
    PRIMARY_LIGHT_COLOR = '#212624',
    PRIMARY_DARK_COLOR = '#181C1A',
    PRIMARY_CONTRAST_TEXT = '#FFFFFF',
    SECONDARY_MAIN_COLOR = '#05492C',
    SECONDARY_LIGHT_COLOR = '#05492C',
    SECONDARY_CONTRAST_TEXT = '#FFFFFF',
    INFO_MAIN_COLOR = '#05492C',
    INFO_CONTRAST_TEXT = '#ffffff',
    ERROR_MAIN_COLOR = '#CB362D',
    ERROR_LIGHT_COLOR = 'rgba(203, 54, 45, 0.1)',
}
