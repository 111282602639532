import React, { useMemo, BaseSyntheticEvent, ChangeEvent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    OutlinedInput,
    Typography,
    useTheme,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { ThemeSelectInput, ThemeDatePicker, ThemeTimePicker } from 'src/theming';

import { useAuth } from 'src/user-management/context-auth';
import { useQuery } from 'src/shared/hooks';
import { DamageReportGroups } from 'src/shared/constants';

import {
    useStyle,
    useFormControlStyles,
    useFormControlLabelStyles,
    useInputStyles,
} from '../../damage-report-form-styles/damage-report-form-styles';
import { filterDivisionsDependingOnAppInstance } from '../../damage-report-form-components/damage-report-form-header-block';

import { DamageReportFormSearchContracts } from '../../damage-report-form-components/damage-report-form-search-contracts';
import { damageReportFormGenerateDamageTypesOptions } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-generate-damage-types-options';

interface DamageReportFormGeneralBlockProps {
    readonly: boolean;
    divisionFromRecord: string;
    division: string;
    dayOfDamage: ParsableDate;
    timeOfDamage: ParsableDate;
    licenseNumber: string;
    contractNumber: string;
    policyHolder: string;
    insuranceCompany: string;
    handleChangeValue: (e: BaseSyntheticEvent) => void;
    handleChangeDate: (name, value: ParsableDate) => void;
    handleChangeDamageType: (e: ChangeEvent<HTMLInputElement>) => void;
    handleReadOnlyFields: (data: {
        licenseNumber: string;
        contractNumber: string;
        policyHolder: string;
        insuranceCompany: string;
        contractId: string;
    }) => void;
    damageTypeFields: { name: string }[];
    errors: {
        dayOfDamage: boolean;
        timeOfDamage: boolean;
        kfzLicenseNumber: boolean;
        contractNumber: boolean;
        policyHolder: boolean;
        insuranceCompany: boolean;
        typeOfDamage: boolean;
    };
}

export const DamageReportFormGeneralBlock = memo(
    (props: DamageReportFormGeneralBlockProps) => {
        const classes = useStyle();
        const formControlClasses = useFormControlStyles();
        const formControlLabelClasses = useFormControlLabelStyles();
        const inputClasses = useInputStyles();
        const theme = useTheme();

        const contractIdFromQuery = useQuery().get('contractId');

        const {
            dayOfDamage,
            timeOfDamage,
            division,
            handleChangeValue,
            handleChangeDate,
            handleChangeDamageType,
            handleReadOnlyFields,
            damageTypeFields,
            licenseNumber,
            contractNumber,
            policyHolder,
            insuranceCompany,
            errors,
            readonly,
            divisionFromRecord,
        } = props;

        const { t } = useTranslation(['common', 'damage-report']);

        const { userData } = useAuth();

        const divisionSelectionItems = useMemo(
            () =>
                filterDivisionsDependingOnAppInstance(userData?.divisions).map(
                    (item) => ({
                        title: item.label,
                        value: item.value,
                        checked: false,
                    })
                ),
            [userData]
        );

        const chosenDamageTypes = useMemo(
            () => damageTypeFields?.map((damageType) => damageType.name) || [],
            [damageTypeFields]
        );

        const damagesTypes = useMemo(
            () => damageReportFormGenerateDamageTypesOptions(t, chosenDamageTypes),
            [t, chosenDamageTypes]
        );

        return (
            <Grid container spacing={2}>
                <Typography variant='h6' className={classes.formBlockTitle}>
                    {t('damages-report:damageReport')}
                </Typography>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <ThemeSelectInput
                                    editMode={contractIdFromQuery ? false : !readonly}
                                    value={division}
                                    name='division'
                                    onChange={handleChangeValue}
                                    items={divisionSelectionItems}
                                />
                            }
                            label={t('damages-report:generalFormBlock.division')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                        className={classes.dateField}
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <ThemeDatePicker
                                    editMode={!readonly}
                                    error={errors.dayOfDamage}
                                    inputVariant='outlined'
                                    value={dayOfDamage}
                                    name='dayOfDamage'
                                    onChange={handleChangeDate}
                                    maxDate={Date.now()}
                                    maxDateErrorMessage={t(
                                        'damages-report:dateMayNotBeInTheFuture'
                                    )}
                                />
                            }
                            label={t('damages-report:generalFormBlock.dayOfDamage')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                        className={classes.dateField}
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <ThemeTimePicker
                                    editMode={!readonly}
                                    error={errors.timeOfDamage}
                                    value={timeOfDamage}
                                    onChange={handleChangeDate}
                                    name='timeOfDamage'
                                />
                            }
                            label={t('damages-report:generalFormBlock.timeOfDamage')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.fullWidth)}>
                    <DamageReportFormSearchContracts
                        damageReportGroup={DamageReportGroups.MOTORING}
                        key={String(dayOfDamage)}
                        error={errors.kfzLicenseNumber}
                        disabled={!dayOfDamage || readonly}
                        dayOfDamage={dayOfDamage}
                        division={division}
                        divisionFromRecord={divisionFromRecord}
                        handleReadOnlyFields={handleReadOnlyFields}
                    />
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled
                                    classes={inputClasses}
                                    value={licenseNumber}
                                />
                            }
                            label={t('damages-report:generalFormBlock.licenseNumber')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                        error={errors.contractNumber}
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled
                                    classes={inputClasses}
                                    value={contractNumber}
                                />
                            }
                            label={t('damages-report:generalFormBlock.contractNumber')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                        error={errors.policyHolder}
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled
                                    classes={inputClasses}
                                    value={policyHolder}
                                />
                            }
                            label={t('damages-report:generalFormBlock.policyHolder')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                        error={errors.insuranceCompany}
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled
                                    classes={inputClasses}
                                    value={insuranceCompany}
                                />
                            }
                            label={t('damages-report:generalFormBlock.client')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid
                    item
                    className={clsx(classes.thirdPart, classes.damageTypesContainer)}
                >
                    <FormControl component='fieldset'>
                        <FormLabel
                            className={classes.labelTitle}
                            style={{
                                color: errors?.typeOfDamage && theme.palette.error.main,
                            }}
                        >
                            {t('damages-report:generalFormBlock.typeOfDamage')}
                        </FormLabel>
                        <FormGroup className={classes.damageTypesGroup}>
                            {damagesTypes.map((damageType) => (
                                <FormControlLabel
                                    key={damageType.value}
                                    label={damageType.title}
                                    control={
                                        <Checkbox
                                            disabled={readonly}
                                            checked={damageType.checked}
                                            name={damageType.value}
                                            onChange={handleChangeDamageType}
                                            icon={
                                                <CheckBoxOutlineBlankIcon
                                                    className={classes.checkboxIcon}
                                                />
                                            }
                                            checkedIcon={
                                                <CheckBoxIcon
                                                    className={clsx(
                                                        classes.icon,
                                                        classes.checkedIcon
                                                    )}
                                                />
                                            }
                                        />
                                    }
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>
        );
    }
);
