import { makeStyles, Theme } from '@material-ui/core';
import { THEME_MODES } from 'src/theming';

export const useStyle = makeStyles((theme: Theme) => {
    return {
        modalActionsContainer: {
            gap: theme.spacing(3),
            marginTop: theme.spacing(4),
        },
        modalButton: {
            color: theme.palette.primary.contrastText,
        },
        saveButton: {
            color: theme.palette.secondary.contrastText,
            backgroundColor:
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.info.main
                    : theme.palette.secondary.main,
        },
        cancelButton: {
            borderColor:
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.info.main
                    : theme.palette.secondary.main,
        },
    };
});
