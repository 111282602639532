import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useGlobalFilter } from 'src/shared/contexts';
import { CheckboxItem } from 'src/theming';

import { generateContractsListMenuForTableSettings } from './contracts-list-configs';
import { ContractsListTableSettings } from './contracts-list-table-settings';

interface UseContractsListSettings {
    columnsSettingsList: CheckboxItem[];
    isColumnSettingsLoading: boolean;
    setColumnsSettingsList: (list: CheckboxItem[]) => void;
    setColumnSettingsResponse: (data: ContractsListTableSettings) => void;
    setColumnSettingsLoading: (value: boolean) => void;
    columnSettingsResponse: ContractsListTableSettings;
    updateTableSettings: (data: CheckboxItem[]) => Promise<ContractsListTableSettings>;
    onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
    onDragUpdate: (result) => void;
    onDragStart: (result) => void;
    getTableSettings: () => Promise<ContractsListTableSettings>;
    hoverPosition: number;
    startPosition: number;
    handleResetSettings: () => void;
}

export const useSettings = (): UseContractsListSettings => {
    const httpClient = useHttpClient();
    const { t } = useTranslation();

    const { division } = useGlobalFilter();

    const [columnSettingsResponse, setColumnSettingsResponse] = useState(null);
    const [columnsSettingsList, setColumnsSettingsList] = useState(
        generateContractsListMenuForTableSettings(t, null, division)
    );
    const [isColumnSettingsLoading, setColumnSettingsLoading] = useState(true);
    const [hoverPosition, setHoverPosition] = useState<number>(null);
    const [startPosition, setStartPosition] = useState<number>(null);

    const getTableSettings = (): Promise<ContractsListTableSettings> => {
        return httpClient.get(`users/tables/Contracts/table-settings`);
    };

    const handleResetSettings = async (): Promise<void> => {
        try {
            await updateTableSettings([]);
            const data = await getTableSettings();
            setColumnSettingsResponse({
                columns: data.columns?.sort((a, b) => (a.position > b.position ? 1 : -1)),
            });
        } finally {
            setColumnSettingsLoading(false);
        }
    };

    const updateTableSettings = (
        data: CheckboxItem[]
    ): Promise<ContractsListTableSettings> => {
        const requestBody = {
            columns: data.map((item) => ({
                name: item.value,
                isShown: Boolean(item.checked),
            })),
        };
        return httpClient.put('users/tables/Contracts/table-settings', requestBody);
    };

    const onDragStart = (result): void => {
        if (result.source) {
            setStartPosition(result?.source?.index);
        }
    };

    const onDragUpdate = (result): void => {
        if (result.destination) {
            setHoverPosition(result.destination.index);
        }
    };

    const onDragEnd = async (result: DropResult): Promise<void> => {
        setHoverPosition(null);
        setStartPosition(null);
        const { destination, source } = result;
        if (!destination) {
            return;
        }
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }
        const newSettingsList = [...columnSettingsResponse.columns];
        const settingsListIndex = newSettingsList.findIndex(
            (item) => item.position === source.index
        );
        if (settingsListIndex !== -1) {
            const column = newSettingsList.splice(settingsListIndex, 1);
            newSettingsList.splice(destination.index, 0, ...column);
        }
        setColumnSettingsLoading(true);
        try {
            await updateTableSettings(
                newSettingsList.map((item) => ({
                    title: '',
                    value: item.name,
                    checked: item.isShown,
                    data: undefined,
                }))
            );
            const data = await getTableSettings();
            setColumnSettingsResponse({
                columns: data.columns?.sort((a, b) => (a.position > b.position ? 1 : -1)),
            });
        } finally {
            setColumnSettingsLoading(false);
        }
    };

    useEffect(() => {
        getTableSettings()
            .then((data) => {
                setColumnSettingsResponse({
                    columns: data.columns?.sort((a, b) =>
                        a.position > b.position ? 1 : -1
                    ),
                });
            })
            .finally(() => {
                setColumnSettingsLoading(false);
            });
    }, []);

    useEffect(() => {
        if (columnSettingsResponse) {
            setColumnsSettingsList(
                generateContractsListMenuForTableSettings(
                    t,
                    columnSettingsResponse,
                    division
                )
            );
        }
    }, [columnSettingsResponse, t, setColumnsSettingsList, division]);

    return {
        columnsSettingsList,
        isColumnSettingsLoading,
        setColumnsSettingsList,
        setColumnSettingsResponse,
        setColumnSettingsLoading,
        columnSettingsResponse,
        updateTableSettings,
        onDragEnd,
        onDragUpdate,
        onDragStart,
        getTableSettings,
        hoverPosition,
        startPosition,
        handleResetSettings,
    };
};
