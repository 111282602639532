import { makeStyles } from '@material-ui/core';

export const useErrorPageStyles = makeStyles(() => {
    return {
        container: {
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
    };
});
