import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

type UseNotificationDeleteRowReturn = [boolean, (id: string) => Promise<void>];

export const useNotificationDeleteRow = (
    refetchNotifications: () => Promise<void>
): UseNotificationDeleteRowReturn => {
    const httpClient = useHttpClient();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation(['user-management', 'errors']);

    const [isLoading, setLoading] = useState<boolean>(false);
    const deleteRow = async (id: string): Promise<void> => {
        if (id) {
            setLoading(true);
            try {
                await httpClient.delete(`notification-triggers/${id}`);
                refetchNotifications();
            } catch {
                enqueueSnackbar(t('errors:unknownError'), { variant: 'error' });
            } finally {
                setLoading(false);
            }
        }
    };

    return [isLoading, deleteRow];
};
