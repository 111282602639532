import { useState, useEffect, BaseSyntheticEvent, useCallback } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import { useGlobalFilter } from '..';
import { useFetchDashboardData } from './use-fetch-dashboard-data';
import { DashboardDataResponseType } from './dashboard-data-response-type';

export interface UseDashboardData {
    dashboardData: DashboardDataResponseType;
    selectedYears: number[];
    yearsForRequest: number[];
    handleChangeYear: (e: BaseSyntheticEvent) => void;
    isGetDashboardDataLoading: boolean;
    updateYearsForRequest: () => void;
}

export const useDashboardData = (): UseDashboardData => {
    const location = useLocation();
    const { division, filter } = useGlobalFilter();

    const [selectedYears, setYears] = useState<number[]>([moment().year()]);
    const [yearsForRequest, setYearsForRequest] = useState<number[]>([moment().year()]);
    const [dashboardData, setDashboardData] = useState(null);
    /* This state is needed for updating dashboard data if dependent values are changed */
    const [needToGetUpdatedDashboardData, setNeedToGetUpdatedDashboardData] =
        useState<boolean>(true);

    const {
        mutate,
        isLoading: isGetDashboardDataLoading,
        data,
    } = useFetchDashboardData();

    const updateData = useCallback(async (): Promise<void> => {
        await mutate({
            division,
            years: yearsForRequest,
            customers: filter.customers,
        });
        setNeedToGetUpdatedDashboardData(false);
    }, [division, yearsForRequest, filter.customers, setNeedToGetUpdatedDashboardData]);

    useEffect(() => {
        setDashboardData(data);
    }, [data]);

    useEffect(() => {
        /* If it is needed to update dashboard data then do it only if a user is on the dashboard location path */
        if (location.pathname === '/' && needToGetUpdatedDashboardData) {
            updateData();
        }
    }, [needToGetUpdatedDashboardData, updateData, location.pathname]);

    useEffect(() => {
        setNeedToGetUpdatedDashboardData(true);
    }, [division, yearsForRequest, filter.customers, setNeedToGetUpdatedDashboardData]);

    const handleChangeYear = (e: BaseSyntheticEvent): void => {
        setYears(e.target.value.map((item: string) => item));
    };

    const updateYearsForRequest = (): void => {
        setYearsForRequest(selectedYears);
    };

    return {
        dashboardData,
        selectedYears,
        handleChangeYear,
        isGetDashboardDataLoading,
        updateYearsForRequest,
        yearsForRequest,
    };
};
