import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Typography, Grid, Button } from '@material-ui/core';

import { ThemeModalWindow, ThemeCircularProgress } from 'src/theming';

import { useStyle } from './update-dashboard-data-modal-window-styles';

interface UpdateDashboardDataModalWindowProps {
    isOpen: boolean;
    isUpdatingLoading: boolean;
    onClose: () => void;
    handleUpdate: () => void;
}

export const UpdateDashboardDataModalWindow = ({
    isOpen,
    isUpdatingLoading,
    onClose,
    handleUpdate,
}: UpdateDashboardDataModalWindowProps): JSX.Element => {
    const { t } = useTranslation(['common']);

    const classes = useStyle();

    return (
        <ThemeModalWindow isOpen={isOpen} onClose={onClose}>
            <></>
            <Typography variant='body1'>
                {t('updateDashboardConfirmationMessage')}
            </Typography>
            <Grid container className={classes.modalActionsContainer}>
                <Button
                    disableElevation
                    variant='contained'
                    className={clsx(classes.modalButton, classes.saveButton)}
                    onClick={handleUpdate}
                    disabled={isUpdatingLoading}
                    startIcon={<ThemeCircularProgress isLoading={isUpdatingLoading} />}
                >
                    {t('common:yes')}
                </Button>
                <Button
                    disabled={isUpdatingLoading}
                    variant='outlined'
                    className={clsx(classes.modalButton, classes.cancelButton)}
                    onClick={onClose}
                >
                    {t('common:no')}
                </Button>
            </Grid>
        </ThemeModalWindow>
    );
};
