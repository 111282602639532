import { NotificationsDto } from './notifications-dto';

import { appInstance } from 'src/environment';
import { AppInstances } from 'src/shared/constants';

export const notificationPutAdapter = (
    id: string,
    trigger: string,
    division: string,
    watchedFurtherReceivers: { name: string }[],
    watchedFallbackEmails: { name: string }[],
    customerAdvisers: { name: string }[],
    customers: { name: string }[],
    accountManager: string,
    sendNotificationToCustomer: boolean,
    sendNotificationToIntern: boolean
): NotificationsDto => {
    const formattedData: NotificationsDto = {
        subRows: [],
        customerRoles: customers.map((item) => item.name),
        fallbackEmails: watchedFallbackEmails.map((item) => {
            return {
                email: item.name,
            };
        }),
        furtherRecieverEmails: watchedFurtherReceivers.map((item) => ({
            email: item.name,
        })),
        sendNotificationToCustomer,
        sendNotificationToIntern,
    };
    if (division) {
        formattedData.division = division;
    }
    if (trigger) {
        formattedData.notificationTriggerType = trigger;
    }
    if (appInstance === AppInstances.AON_KOMPOSIT) {
        formattedData.accountManager = accountManager;
    } else {
        formattedData.customerAdvisors = customerAdvisers.map((item) => item.name);
    }
    return formattedData;
};
