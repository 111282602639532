import { useState, useEffect } from 'react';
import * as signalR from '@microsoft/signalr';

import { baseUrl } from 'src/environment';

interface SocketConnectionProps {
    isAccessToEstablishConnection: boolean;
    url: string;
    accessToken: string;
}

export const useSocketConnection = ({
    isAccessToEstablishConnection,
    url,
    accessToken,
}: SocketConnectionProps): signalR.HubConnection => {
    const [connection, setConnection] = useState<signalR.HubConnection>(null);

    const startConnection = async (): Promise<void> => {
        await connection.start();
    };

    useEffect(() => {
        if (isAccessToEstablishConnection && accessToken) {
            try {
                const newConnection = new signalR.HubConnectionBuilder()
                    .withUrl(`${baseUrl}/${url}`, {
                        accessTokenFactory: () => accessToken,
                    })
                    .withAutomaticReconnect()
                    .build();
                setConnection(newConnection);
            } catch (err) {
                Promise.reject(err);
            }
        } else {
            setConnection(null);
        }
    }, [isAccessToEstablishConnection, accessToken]);

    useEffect(() => {
        if (connection) {
            try {
                startConnection();
            } catch (err) {
                Promise.reject(err);
            }
        }
    }, [connection]);

    return connection;
};
