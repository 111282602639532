import React, { memo, BaseSyntheticEvent, useMemo } from 'react';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyle = makeStyles((theme) => {
    return {
        currencyField: {
            width: '100%',
            '& .MuiFormLabel-root.Mui-focused': {
                color: theme.palette.primary.contrastText,
            },
            '& .MuiInputBase-root.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.secondary.main,
                },
            },
            '& .Mui-disabled': {
                color: theme.palette.primary.contrastText,
            },
            '& .MuiFormLabel-root': {
                color: 'rgba(0, 0, 0, 0.38)!important',
                '& .Mui-disabled': {
                    color: 'rgba(0, 0, 0, 0.38)!important',
                },
            },
            '& .MuiSvgIcon-root': {
                color: 'rgba(0, 0, 0, 0.38)',
            },
            '& .MuiInputAdornment-root': {
                display: 'none',
            },
        },
    };
});

interface ThemeCurrencyFieldProps {
    disabled?: boolean;
    label?: string;
    value: string;
    name: string;
    decimalPlaces: number;
    onChange: (e: BaseSyntheticEvent) => void;
    className?: string;
    onBlur?: () => void;
    error?: boolean;
}

export const ThemeCurrencyField = memo((props: ThemeCurrencyFieldProps): JSX.Element => {
    const classes = useStyle();
    const {
        label,
        value,
        onChange,
        name,
        className,
        decimalPlaces,
        disabled,
        onBlur,
        ...rest
    } = props;

    const handleChange = (event: BaseSyntheticEvent): void => {
        if (/\d$/.test(event.target.value) || !event.target.value) {
            onChange(event);
        }
    };

    const formattedValue = useMemo(() => {
        if (value && typeof value === 'string') {
            return value.replace(/\./g, '').replace(/,/g, '.') || '';
        }
        return value;
    }, [value]);

    return (
        <CurrencyTextField
            noStyle
            disabled={disabled}
            modifyValueOnWheel={false}
            outputFormat='string'
            className={clsx(classes.currencyField, className)}
            variant='outlined'
            currencySymbol=''
            label={label}
            decimalCharacter=','
            digitGroupSeparator='.'
            decimalPlaces={decimalPlaces}
            name={name}
            value={formattedValue}
            onChange={handleChange}
            onKeyDown={handleChange}
            textAlign='left'
            InputProps={{ endAdornment: null, inputProps: { autoComplete: 'off' } }}
            onBlur={onBlur}
            {...rest}
        />
    );
});
