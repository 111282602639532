export enum USER_GROUPS {
    INTERNAL = 'Internal',
    EXTERNAL = 'External',
    CUSTOMER = 'Customer',
}

export enum INTERNAL_ROLES {
    ADMIN_DOMAIN = 'AdminDomain',
    ADMIN_TECHNICAL = 'AdminTechnical',
    CUSTOMER_ADVISER = 'CustomerAdvisor',
    CA_BROKER = 'CABroker',
}

export enum EXTERNAL_ROLES {
    INSURANCE = 'Insurance',
    SUPPLIER = 'Supplier',
    PRESENTATION = 'Presentation',
}

export enum CUSTOMER_ROLES {
    ACCOUNT_OWNER = 'AccountOwner',
    ADMINISTRATOR = 'Admin',
    ADMINISTRATOR_PLUS = 'AdminWithExternDamageReport',
    CLERK = 'Clerk',
    CLERK_PLUS = 'ClerkWithExternDamageReport',
}

export type ROLES = INTERNAL_ROLES | EXTERNAL_ROLES | CUSTOMER_ROLES;
