import React from 'react';
import useDarkMode from 'use-dark-mode';

import { ThemeLayout } from 'src/theming';
import { ContractsCreateContractForm } from 'src/contracts';

export const CreateContractPage = (): JSX.Element => {
    const darkMode = useDarkMode();
    return (
        <ThemeLayout darkMode={darkMode}>
            <ContractsCreateContractForm />
        </ThemeLayout>
    );
};
