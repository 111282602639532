import { SelectItem } from 'src/theming';
import { DamageReportFormDynamicFieldsRow } from '../../damage-report-form-components/damage-report-form-dynimic-fields-row/damage-report-form-dynimic-fields-row';
import { DamageFormConditionalBlock } from '../../damage-report-form-components/damage-report-form-input-containers/damage-form-conditional-block';
import { DamageReportFormContainer } from '../../damage-report-form-components/damage-report-form-input-containers/damage-report-form-container';
import { DamageReportFormCurrencyInput } from '../../damage-report-form-components/damage-report-form-items/damage-form-currency-input';
import { DamageFormDisabledInput } from '../../damage-report-form-components/damage-report-form-items/damage-form-disabled-input';
import { DamageReportFormFullInput } from '../../damage-report-form-components/damage-report-form-items/damage-report-form-full-input';
import { DamageReportFormFullwidthInput } from '../../damage-report-form-components/damage-report-form-items/damage-report-form-fullwidth-input';
import { DamageReportFormInput } from '../../damage-report-form-components/damage-report-form-items/damage-report-form-input';
import { DamageReportFormPhoneInput } from '../../damage-report-form-components/damage-report-form-items/damage-report-form-phone-input';
import { DamageReportFormSelect } from '../../damage-report-form-components/damage-report-form-items/damage-report-form-select';
import { ThingAffected } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-generate-AON-tecnical-thing-affected-options';
import {
    FormContainerProps,
    FormElement,
    generateDisabledInputsProps,
} from '../damage-report-form-config-GBW-HR-GESCH';

export interface DamageReportFormConfigAONKompositTechnicalReturnType {
    title?: string;
    titleData?: string;
    id: string;
    isCombination?: boolean;
    showIfFalse?: boolean;
    toggleValue?: () => void;
    Container: (props: FormContainerProps) => JSX.Element;
    formElements: FormElement[];
    formElementsRows?: {
        RowComponent: React.FC<{ rows: { id: string; formElements: FormElement[] }[] }>;
        rows: { id: string; formElements: FormElement[] }[];
        handleAddRow?: () => void;
        handleDeleteRow?: (id: string) => void;
        handleOnChangeRow: (
            id: string,
            row: { id: string; formElements: FormElement[] }
        ) => void;
    };
}

interface DamageReportFormConfigAONKompositTechnicalProps {
    division: string;
    placeOfDamage: string;
    itemsAffectedByTheDamage: {
        id: string;
        numberAndTypeOfThings: string;
        acquisitionOrReplacementPrice: number | string;
        repairCosts: number | string;
    }[];
    damageAndCause: string;
    estimatedLossAmountInEUR: string;
    shouldDamagedItemsBeOperatedProvisionally: boolean;
    namely: string;
    isARepairPossibleAndPlanned: boolean;
    through: string;
    whoCanAnswerQuestionsAboutTheTechnicalDetails: string;
    isWitnesses: boolean;
    witnessesSurname: string;
    witnessesRoad: string;
    witnessesPostcode: string;
    witnessesPlace: string;
    witnessesPhoneNumber: string;
    witnessesEmail: string;
    damageInformationAreDamagedItemsOtherwiseInsured: boolean;
    indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance: string;
    warrantyPeriodForTheDamagedObjectExpired: boolean;
    namelyBy: string;
    lossOfInsuredItemsDueToTheftBurglaryOrArson: string;
    isRecordedByThePolice: boolean;
    policeStation: string;
    fileNumber: string;
    interruptionInOperationOrDelayIncommissioning: boolean;
    interruptionInOperationNamely: string;
    interruptionInOperationStandsSince: string;
    expectedDamageInEurosPerDayOrWeekOrMonth: number | string;
    mitigationMeasuresHaveAlreadyBeenTaken: string;
    additionalCostsAreIncurredForBridgingMeasures: number | string;
    whoCreatedTheServicesAffectedByTheDamage: string;
    projectPhase: string;
    projectPhaseOptions: SelectItem[];
    thingAffectedByTheDamage: string;
    thingAffectedByTheDamageOptions: SelectItem[];
    thingAffectedByTheDamageSince: string;
    thingAffectedAcceptedSince: string;
    thingAffectedAcceptedBy: string;
    insuredConstructionOrAssemblyProject: string;
    insuredConstructionOrAssemblyProjectOptions: SelectItem[];
    insuredConstructionOrAssemblyProjectSince: string;
    insuredConstructionOrAssemblyProjectAcceptedSince: string;
    insuredConstructionOrAssemblyProjectAcceptedBy: string;
    caseOfTheftForConstructionInsurance: boolean;
    handleChangeSwitcher: (name: string, value: boolean) => void;
    handleAddRow: () => void;
    handleDeleteRow: (id: string) => void;
    handleOnChangeRow?: (
        id: string,
        row: { id: string; formElements: FormElement[] }
    ) => void;
    fullWidthClass?: string;
}

export const generateDisabledInputs = ({
    licenseNumber,
    contractNumber,
    policyHolder,
    insuranceCompany,
    errors,
}: generateDisabledInputsProps): DamageReportFormConfigAONKompositTechnicalReturnType[] => [
    {
        Container: DamageReportFormContainer,
        title: '',
        id: 'generalFormBlock',
        formElements: [
            {
                Item: DamageFormDisabledInput,
                value: licenseNumber,
                label: 'damages-report:generalFormBlock.risk',
                name: 'risk',
            },
            {
                Item: DamageFormDisabledInput,
                value: contractNumber,
                label: 'damages-report:generalFormBlock.contractNumber',
                name: 'contractNumber',
                error: Boolean(errors.contractNumber),
            },
            {
                Item: DamageFormDisabledInput,
                value: policyHolder,
                label: 'damages-report:generalFormBlock.policyHolder',
                name: 'policyHolder',
                error: Boolean(errors.policyHolder),
            },
            {
                Item: DamageFormDisabledInput,
                value: insuranceCompany,
                label: 'damages-report:generalFormBlock.client',
                name: 'insuranceCompany',
                error: Boolean(errors.insuranceCompany),
            },
        ],
    },
];

export const DamageReportFormConfigAONKompositTechnical = ({
    placeOfDamage,
    itemsAffectedByTheDamage,
    damageAndCause,
    estimatedLossAmountInEUR,
    shouldDamagedItemsBeOperatedProvisionally,
    namely,
    isARepairPossibleAndPlanned,
    through,
    whoCanAnswerQuestionsAboutTheTechnicalDetails,
    isWitnesses,
    witnessesSurname,
    witnessesRoad,
    witnessesPostcode,
    witnessesPlace,
    witnessesPhoneNumber,
    witnessesEmail,
    damageInformationAreDamagedItemsOtherwiseInsured,
    indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance,
    warrantyPeriodForTheDamagedObjectExpired,
    namelyBy,
    lossOfInsuredItemsDueToTheftBurglaryOrArson,
    isRecordedByThePolice,
    policeStation,
    fileNumber,
    interruptionInOperationOrDelayIncommissioning,
    interruptionInOperationNamely,
    interruptionInOperationStandsSince,
    expectedDamageInEurosPerDayOrWeekOrMonth,
    mitigationMeasuresHaveAlreadyBeenTaken,
    additionalCostsAreIncurredForBridgingMeasures,
    whoCreatedTheServicesAffectedByTheDamage,
    projectPhase,
    projectPhaseOptions,
    thingAffectedByTheDamage,
    thingAffectedByTheDamageOptions,
    thingAffectedByTheDamageSince,
    thingAffectedAcceptedSince,
    thingAffectedAcceptedBy,
    insuredConstructionOrAssemblyProject,
    insuredConstructionOrAssemblyProjectOptions,
    insuredConstructionOrAssemblyProjectSince,
    insuredConstructionOrAssemblyProjectAcceptedSince,
    insuredConstructionOrAssemblyProjectAcceptedBy,
    caseOfTheftForConstructionInsurance,
    handleChangeSwitcher,
    handleAddRow,
    handleDeleteRow,
    handleOnChangeRow,
    fullWidthClass,
    division,
}: DamageReportFormConfigAONKompositTechnicalProps): DamageReportFormConfigAONKompositTechnicalReturnType[][] => {
    return [
        [
            {
                title: 'damages-report:technicalInsuranceClaims.title',
                titleData: `divisions:${division}`,
                Container: DamageReportFormContainer,
                id: 'placeOfDamage',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:technicalInsuranceClaims.placeOfDamage',
                        name: 'placeOfDamage',
                        value: placeOfDamage,
                    },
                ],
            },
            {
                title: 'damages-report:technicalInsuranceClaims.itemsAffectedByTheDamage',
                Container: DamageReportFormContainer,
                id: 'itemsAffectedByTheDamage',
                formElementsRows: {
                    handleAddRow,
                    handleDeleteRow,
                    handleOnChangeRow,
                    RowComponent: DamageReportFormDynamicFieldsRow,
                    rows: itemsAffectedByTheDamage?.map((item) => ({
                        id: item.id,
                        formElements: [
                            {
                                Item: DamageReportFormInput,
                                label: 'damages-report:technicalInsuranceClaims.numberAndTypeOfThings',
                                name: 'numberAndTypeOfThings',
                                value: item.numberAndTypeOfThings,
                            },
                            {
                                Item: DamageReportFormInput,
                                label: 'damages-report:technicalInsuranceClaims.machineDirectoryOrDeclaration',
                                name: 'acquisitionOrReplacementPrice',
                                value: item.acquisitionOrReplacementPrice,
                            },
                            {
                                Item: DamageReportFormCurrencyInput,
                                label: 'damages-report:technicalInsuranceClaims.extentOfDamage',
                                name: 'repairCosts',
                                value: item.repairCosts,
                            },
                        ],
                    })),
                },
                formElements: [],
            },
            {
                title: '  ',
                Container: DamageReportFormContainer,
                id: 'damageAndCause',
                formElements: [
                    {
                        Item: DamageReportFormFullwidthInput,
                        label: 'damages-report:technicalInsuranceClaims.damageAndCause',
                        name: 'damageAndCause',
                        value: damageAndCause,
                    },
                    {
                        Item: DamageReportFormCurrencyInput,
                        label: 'damages-report:technicalInsuranceClaims.estimatedLossAmountInEUR',
                        name: 'estimatedLossAmountInEUR',
                        value: estimatedLossAmountInEUR,
                        className: fullWidthClass,
                    },
                ],
            },
            {
                title: 'damages-report:technicalInsuranceClaims.shouldDamagedItemsBeOperatedProvisionally',
                id: 'shouldDamagedItemsBeOperatedProvisionally',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:technicalInsuranceClaims.namely',
                        name: 'namely',
                        value: namely,
                    },
                ],
                isCombination: shouldDamagedItemsBeOperatedProvisionally,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'shouldDamagedItemsBeOperatedProvisionally',
                        !shouldDamagedItemsBeOperatedProvisionally
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: 'damages-report:technicalInsuranceClaims.isARepairPossibleAndPlanned',
                id: 'isARepairPossibleAndPlanned',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:technicalInsuranceClaims.through',
                        name: 'through',
                        value: through,
                    },
                ],
                isCombination: isARepairPossibleAndPlanned,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'isARepairPossibleAndPlanned',
                        !isARepairPossibleAndPlanned
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'whoCanAnswerQuestionsAboutTheTechnicalDetails',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:technicalInsuranceClaims.whoCanAnswerQuestionsAboutTheTechnicalDetails',
                        name: 'whoCanAnswerQuestionsAboutTheTechnicalDetails',
                        value: whoCanAnswerQuestionsAboutTheTechnicalDetails,
                    },
                ],
            },
            {
                title: 'damages-report:damageData.areDamagedItemsOtherwiseInsured',
                id: 'areDamagedItemsOtherwiseInsured',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:damageData.indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance',
                        value: indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance,
                        name: 'indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance',
                    },
                ],
                isCombination: damageInformationAreDamagedItemsOtherwiseInsured,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'damageInformationAreDamagedItemsOtherwiseInsured',
                        !damageInformationAreDamagedItemsOtherwiseInsured
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: 'damages-report:technicalInsuranceClaims.warrantyPeriodForTheDamagedObjectExpired',
                id: 'warrantyPeriodForTheDamagedObjectExpired',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:technicalInsuranceClaims.namelyBy',
                        name: 'namelyBy',
                        value: namelyBy,
                    },
                ],
                isCombination: warrantyPeriodForTheDamagedObjectExpired,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'warrantyPeriodForTheDamagedObjectExpired',
                        !warrantyPeriodForTheDamagedObjectExpired
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'lossOfInsuredItemsDueToTheftBurglaryOrArson',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:technicalInsuranceClaims.lossOfInsuredItemsDueToTheftBurglaryOrArson',
                        name: 'lossOfInsuredItemsDueToTheftBurglaryOrArson',
                        value: lossOfInsuredItemsDueToTheftBurglaryOrArson,
                    },
                ],
            },
        ],
        [
            {
                title: 'damages-report:followingInformationForBusiness.title',
                Container: DamageReportFormContainer,
                id: 'followingInformation',
                formElements: [],
            },
            {
                title: 'damages-report:followingInformationForBusiness.interruptionInOperationOrDelayIncommissioning',
                id: 'interruptionInOperationOrDelayIncommissioning',
                formElements: [
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:followingInformationForBusiness.interruptionInOperationNamely',
                        name: 'interruptionInOperationNamely',
                        value: interruptionInOperationNamely,
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:followingInformationForBusiness.interruptionInOperationStandsSince',
                        name: 'interruptionInOperationStandsSince',
                        value: interruptionInOperationStandsSince,
                    },
                ],
                isCombination: interruptionInOperationOrDelayIncommissioning,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'interruptionInOperationOrDelayIncommissioning',
                        !interruptionInOperationOrDelayIncommissioning
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'expectedDamageInEurosPerDayOrWeekOrMonth',
                formElements: [
                    {
                        Item: DamageReportFormCurrencyInput,
                        label: 'damages-report:followingInformationForBusiness.expectedDamageInEurosPerDayOrWeekOrMonth',
                        name: 'expectedDamageInEurosPerDayOrWeekOrMonth',
                        value: expectedDamageInEurosPerDayOrWeekOrMonth,
                        className: fullWidthClass,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:followingInformationForBusiness.mitigationMeasuresHaveAlreadyBeenTaken',
                        name: 'mitigationMeasuresHaveAlreadyBeenTaken',
                        value: mitigationMeasuresHaveAlreadyBeenTaken,
                    },
                    {
                        Item: DamageReportFormCurrencyInput,
                        label: 'damages-report:followingInformationForBusiness.additionalCostsAreIncurredForBridgingMeasures',
                        name: 'additionalCostsAreIncurredForBridgingMeasures',
                        value: additionalCostsAreIncurredForBridgingMeasures,
                        className: fullWidthClass,
                    },
                ],
            },
        ],
        [
            {
                title: 'damages-report:followingInformationOrConstructionPerformance.title',
                Container: DamageReportFormContainer,
                id: 'followingInformationOrConstructionPerformance',
                formElements: [],
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'whoCreatedTheServicesAffectedByTheDamage',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:followingInformationOrConstructionPerformance.whoCreatedTheServicesAffectedByTheDamage',
                        name: 'whoCreatedTheServicesAffectedByTheDamage',
                        value: whoCreatedTheServicesAffectedByTheDamage,
                    },
                ],
            },
        ],
        [
            {
                title: 'damages-report:projectPhaseAtTimeClaimEntry.title',
                Container: DamageReportFormContainer,
                id: 'projectPhase',
                formElements: [
                    {
                        Item: DamageReportFormSelect,
                        label: 'damages-report:projectPhaseAtTimeClaimEntry.projectPhaseOptions',
                        name: 'projectPhase',
                        value: projectPhase,
                        items: projectPhaseOptions,
                    },
                ],
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'thingAffectedByTheDamage',
                formElements: [
                    {
                        Item: DamageReportFormSelect,
                        label: 'damages-report:projectPhaseAtTimeClaimEntry.thingAffectedByTheDamage',
                        name: 'thingAffectedByTheDamage',
                        value: thingAffectedByTheDamage,
                        items: thingAffectedByTheDamageOptions,
                    },
                ],
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'thingAffectedByTheDamageSince',
                formElements:
                    thingAffectedByTheDamage === ThingAffected.FINISHED_ASSEMBLED ||
                    thingAffectedByTheDamage === ThingAffected.OPERATION ||
                    thingAffectedByTheDamage === ThingAffected.TESTING
                        ? [
                              {
                                  Item: DamageReportFormFullInput,
                                  label: 'damages-report:projectPhaseAtTimeClaimEntry.thingAffectedByTheDamageSince',
                                  name: 'thingAffectedByTheDamageSince',
                                  value: thingAffectedByTheDamageSince,
                              },
                          ]
                        : [],
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'thingAffectedAcceptedBy',
                formElements:
                    thingAffectedByTheDamage === ThingAffected.TAKEN_DOWN
                        ? [
                              {
                                  Item: DamageReportFormFullInput,
                                  label: 'damages-report:projectPhaseAtTimeClaimEntry.thingAffectedAcceptedSince',
                                  name: 'thingAffectedAcceptedSince',
                                  value: thingAffectedAcceptedSince,
                              },
                              {
                                  Item: DamageReportFormFullInput,
                                  label: 'damages-report:projectPhaseAtTimeClaimEntry.thingAffectedAcceptedBy',
                                  name: 'thingAffectedAcceptedBy',
                                  value: thingAffectedAcceptedBy,
                              },
                          ]
                        : [],
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'insuredConstructionOrAssemblyProject',
                formElements: [
                    {
                        Item: DamageReportFormSelect,
                        label: 'damages-report:projectPhaseAtTimeClaimEntry.insuredConstructionOrAssemblyProject',
                        name: 'insuredConstructionOrAssemblyProject',
                        value: insuredConstructionOrAssemblyProject,
                        items: insuredConstructionOrAssemblyProjectOptions,
                    },
                ],
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'insuredConstructionOrAssemblyProjectSince',
                formElements:
                    insuredConstructionOrAssemblyProject === ThingAffected.OPERATION ||
                    insuredConstructionOrAssemblyProject ===
                        ThingAffected.FINISHED_ASSEMBLED ||
                    insuredConstructionOrAssemblyProject === ThingAffected.TESTING
                        ? [
                              {
                                  Item: DamageReportFormFullInput,
                                  label: 'damages-report:projectPhaseAtTimeClaimEntry.insuredConstructionOrAssemblyProjectSince',
                                  name: 'insuredConstructionOrAssemblyProjectSince',
                                  value: insuredConstructionOrAssemblyProjectSince,
                              },
                          ]
                        : [],
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'insuredConstructionOrAssemblyProjectAcceptedSince',
                formElements:
                    insuredConstructionOrAssemblyProject === ThingAffected.TAKEN_DOWN
                        ? [
                              {
                                  Item: DamageReportFormFullInput,
                                  label: 'damages-report:projectPhaseAtTimeClaimEntry.insuredConstructionOrAssemblyProjectAcceptedSince',
                                  name: 'insuredConstructionOrAssemblyProjectAcceptedSince',
                                  value: insuredConstructionOrAssemblyProjectAcceptedSince,
                              },
                              {
                                  Item: DamageReportFormFullInput,
                                  label: 'damages-report:projectPhaseAtTimeClaimEntry.insuredConstructionOrAssemblyProjectAcceptedBy',
                                  name: 'insuredConstructionOrAssemblyProjectAcceptedBy',
                                  value: insuredConstructionOrAssemblyProjectAcceptedBy,
                              },
                          ]
                        : [],
            },
            {
                title: 'damages-report:projectPhaseAtTimeClaimEntry.caseOfTheftForConstructionInsurance',
                id: 'caseOfTheftForConstructionInsurance',
                formElements: [],
                isCombination: caseOfTheftForConstructionInsurance,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'caseOfTheftForConstructionInsurance',
                        !caseOfTheftForConstructionInsurance
                    ),
                Container: DamageFormConditionalBlock,
            },
        ],
        [
            {
                title: 'damages-report:recordedByThePolicy.title',
                Container: DamageReportFormContainer,
                id: 'recordedByThePolicy',
                formElements: [],
            },
            {
                title: 'damages-report:recordedByThePolicy.wasIncidentRecordedByPolice',
                id: 'isRecordedByThePolice',
                formElements: [
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:recordedByThePolicy.receivingPoliceStation',
                        value: policeStation,
                        name: 'policeStation',
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:recordedByThePolicy.policeFileNumber',
                        value: fileNumber,
                        name: 'fileNumber',
                    },
                ],
                isCombination: isRecordedByThePolice,
                toggleValue: () =>
                    handleChangeSwitcher('isRecordedByThePolice', !isRecordedByThePolice),
                Container: DamageFormConditionalBlock,
            },
        ],
        [
            {
                title: 'damages-report:witnesses.title',
                Container: DamageReportFormContainer,
                id: 'witnesses',
                formElements: [],
            },
            {
                title: 'damages-report:witnesses.areThereWitnesses',
                id: 'witnesses',
                formElements: [
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:witnesses.surname',
                        value: witnessesSurname,
                        name: 'witnessesSurname',
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:witnesses.road',
                        value: witnessesRoad,
                        name: 'witnessesRoad',
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:witnesses.postCode',
                        value: witnessesPostcode,
                        name: 'witnessesPostcode',
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:witnesses.place',
                        value: witnessesPlace,
                        name: 'witnessesPlace',
                    },
                    {
                        Item: DamageReportFormPhoneInput,
                        label: 'damages-report:witnesses.phoneNumber',
                        value: witnessesPhoneNumber,
                        name: 'witnessesPhoneNumber',
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:witnesses.emailAddress',
                        value: witnessesEmail,
                        name: 'witnessesEmail',
                    },
                ],
                isCombination: isWitnesses,
                toggleValue: () => handleChangeSwitcher('isWitnesses', !isWitnesses),
                Container: DamageFormConditionalBlock,
            },
        ],
    ];
};
