import { LIGHT_CEAT_THEME_COLORS, DARK_CEAT_THEME_COLORS } from './ceat-theme-colors';
import { THEME_MODES } from '..';

export const lightPaletteForCeat = {
    type: THEME_MODES.LIGHT,
    primary: {
        main: LIGHT_CEAT_THEME_COLORS.PRIMARY_MAIN_COLOR,
        light: LIGHT_CEAT_THEME_COLORS.PRIMARY_LIGHT_COLOR,
        dark: LIGHT_CEAT_THEME_COLORS.PRIMARY_DARK_COLOR,
        contrastText: LIGHT_CEAT_THEME_COLORS.PRIMARY_CONTRAST_TEXT,
    },
    secondary: {
        main: LIGHT_CEAT_THEME_COLORS.SECONDARY_MAIN_COLOR,
        light: LIGHT_CEAT_THEME_COLORS.SECONDARY_LIGHT_COLOR,
        dark: LIGHT_CEAT_THEME_COLORS.SECONDARY_DARK_COLOR,
        contrastText: LIGHT_CEAT_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
    },
    info: {
        main: LIGHT_CEAT_THEME_COLORS.INFO_MAIN_COLOR,
        light: LIGHT_CEAT_THEME_COLORS.INFO_LIGHT_COLOR,
        contrastText: LIGHT_CEAT_THEME_COLORS.INFO_CONTRAST_TEXT,
    },
    error: {
        main: LIGHT_CEAT_THEME_COLORS.ERROR_MAIN_COLOR,
        light: LIGHT_CEAT_THEME_COLORS.ERROR_LIGHT_COLOR,
    },
};

export const darkPaletteForCeat = {
    type: THEME_MODES.DARK,
    primary: {
        main: DARK_CEAT_THEME_COLORS.PRIMARY_MAIN_COLOR,
        light: DARK_CEAT_THEME_COLORS.PRIMARY_LIGHT_COLOR,
        dark: DARK_CEAT_THEME_COLORS.PRIMARY_DARK_COLOR,
        contrastText: DARK_CEAT_THEME_COLORS.PRIMARY_CONTRAST_TEXT,
    },
    secondary: {
        main: DARK_CEAT_THEME_COLORS.SECONDARY_MAIN_COLOR,
        light: DARK_CEAT_THEME_COLORS.SECONDARY_LIGHT_COLOR,
        dark: DARK_CEAT_THEME_COLORS.SECONDARY_DARK_COLOR,
        contrastText: DARK_CEAT_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
    },
    info: {
        main: DARK_CEAT_THEME_COLORS.INFO_MAIN_COLOR,
        light: DARK_CEAT_THEME_COLORS.INFO_LIGHT_COLOR,
        contrastText: DARK_CEAT_THEME_COLORS.INFO_CONTRAST_TEXT,
    },
    error: {
        main: DARK_CEAT_THEME_COLORS.ERROR_MAIN_COLOR,
        light: DARK_CEAT_THEME_COLORS.ERROR_LIGHT_COLOR,
    },
};
