import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AppInstances, ROLES } from 'src/shared/constants';
import ErrorBoundary from 'src/lib/error-boundary';
import { appInstance } from 'src/environment';

import { APP_ROUTES } from './app-routes';

import { useAuth } from 'src/user-management/context-auth';

interface ProtectedRouteProps {
    path: string | string[];
    render: () => JSX.Element;
    isAllowed: boolean;
    instances?: AppInstances[];
    roles?: ROLES[];
    withoutAuthValidation?: boolean;
}

export function ProtectedRoute({
    path,
    render,
    isAllowed,
    roles,
    instances,
    withoutAuthValidation,
}: ProtectedRouteProps): JSX.Element {
    const { userData, authorized, homePath } = useAuth();
    const userRoles = userData?.userInfo?.roles;

    if (Array.isArray(instances) && !instances.includes(appInstance as AppInstances)) {
        return null;
    }

    if ((!authorized || !homePath) && !withoutAuthValidation) {
        return <Redirect to={APP_ROUTES.LOGIN} />;
    }

    if (roles && userRoles) {
        const roleExist = Boolean(roles.find((role) => userRoles.includes(role)));
        if (!roleExist) {
            return null;
        }
    }

    return (
        <ErrorBoundary>
            <Route
                exact
                path={path}
                render={() => {
                    if (isAllowed) {
                        return render();
                    }

                    return <Redirect to={APP_ROUTES.LOGIN} />;
                }}
            />
        </ErrorBoundary>
    );
}
