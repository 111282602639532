import React from 'react';
import clsx from 'clsx';
import { DarkMode } from 'use-dark-mode';
import { useHistory } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useStyle } from '../styles';
import { useTranslation } from 'react-i18next';
import { ThemeSwitcher } from 'src/theming';
import { ThemeFooter } from 'src/theming/theme-footer';

const logoWidth = 140;

interface UserAONLayoutProps {
    children: JSX.Element;
    darkMode: DarkMode;
    backPath?: string;
}

export const UserAONLayout = (props: UserAONLayoutProps): JSX.Element => {
    const { darkMode, children, backPath } = props;
    const router = useHistory();
    const { t } = useTranslation(['common', 'user-management']);
    const classes = useStyle({ isDarkMode: darkMode.value });
    const handleGoBack = (): void => {
        router.push(backPath);
    };

    return (
        <Grid container className={classes.pageContainer}>
            <Grid item className={classes.aonPreviewContainer}>
                <div className={classes.aonMask} />
                <div className={clsx(classes.logo, classes.aonLogo)}>
                    <img
                        width={logoWidth}
                        height={logoWidth * 0.6}
                        className={classes.darkElement}
                        alt='logo'
                        src='/static/images/aon.png'
                    />
                    <img
                        width={logoWidth}
                        height={logoWidth * 0.52}
                        className={classes.lightElement}
                        alt='logo'
                        src='/static/images/aon.png'
                    />
                </div>
                <div className={classes.dots}>
                    <img
                        className={classes.darkElement}
                        alt='background-mask'
                        src='/static/images/login-background-mask-dark-aon.svg'
                    />
                    <img
                        className={classes.lightElement}
                        alt='background-mask'
                        src='/static/images/login-background-mask-aon.svg'
                    />
                </div>
            </Grid>
            <Grid item className={classes.functionalityContainer}>
                <div className={classes.toolbarContainer}>
                    <Grid item>
                        {backPath && (
                            <Button
                                disableElevation
                                disableRipple
                                onClick={handleGoBack}
                                startIcon={<KeyboardBackspaceIcon />}
                                className={classes.backButton}
                            >
                                {t('common:back')}
                            </Button>
                        )}
                    </Grid>
                    <div style={{ flexGrow: 1 }} />
                    <Grid item className={classes.switcherContainer}>
                        <ThemeSwitcher
                            leftLabel={t('common:light')}
                            rightLabel={t('common:dark')}
                            checked={darkMode.value}
                            onChange={darkMode.toggle}
                        />
                    </Grid>
                </div>
                <div className={classes.form}>{children}</div>
                <div className={clsx(classes.footer, classes.aonFooter)}>
                    <ThemeFooter />
                </div>
            </Grid>
        </Grid>
    );
};
