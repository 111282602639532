import { TFunction } from 'i18next';
import { SelectItem } from 'src/theming';

export enum ARIA_OF_LIABILITY_OPTIONS {
    SENDER = 'Sender',
    DRIVER = 'Driver',
    OTHER = 'Other',
}

export const damageReportFormGenerateWhoLoadedOptions = (t: TFunction): SelectItem[] => {
    return [
        {
            title: t('damages-report:noChoice'),
            value: 'No choice',
        },
        {
            title: t('damages-report:whoLoadedOptions.sender'),
            value: ARIA_OF_LIABILITY_OPTIONS.SENDER,
        },
        {
            title: t('damages-report:whoLoadedOptions.driver'),
            value: ARIA_OF_LIABILITY_OPTIONS.DRIVER,
        },
        {
            title: t('damages-report:whoLoadedOptions.other'),
            value: ARIA_OF_LIABILITY_OPTIONS.OTHER,
        },
    ];
};
