import { Divisions } from 'src/shared/constants';

export interface DamageReportFormSettingsForBHVAndPHVMutation {
    pathname: string;
}

export const damageReportFormBHVAndPHVGetSettingsForMutation = (
    division: string
): DamageReportFormSettingsForBHVAndPHVMutation => {
    switch (division) {
        case Divisions.PHV: {
            return {
                pathname: 'phv',
            };
        }
        case Divisions.BHV: {
            return {
                pathname: 'bhv',
            };
        }
        default: {
            return null;
        }
    }
};
