import { useState, useEffect, RefObject } from 'react';

export const useOverflowActive = (ref: RefObject<HTMLDivElement>, label): boolean => {
    const [isEllipsisActive, setElipsis] = useState<boolean>(false);

    useEffect(() => {
        if (ref && label) {
            setElipsis(ref.current?.offsetWidth < ref.current?.scrollWidth);
        }
    }, [ref, label]);

    return isEllipsisActive;
};
