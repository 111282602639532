import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

import { useTheme } from '@material-ui/core';

import { appInstance } from 'src/environment';
import { AppInstances } from 'src/shared/constants';
import { APP_ROUTES } from 'src/routing';
import { HectorLogo } from '../theme-logos';
import { THEME_MODES } from '../themes';

import { useThemeSimpleHeaderStyles } from './theme-simple-header-styles';

export const ThemeSimpleHeader = (): ReactElement => {
    const history = useHistory();
    const classes = useThemeSimpleHeaderStyles();
    const theme = useTheme();

    const onClick = (): void => {
        history.push(APP_ROUTES.HOME);
    };

    switch (appInstance) {
        case AppInstances.AON:
        case AppInstances.AON_KOMPOSIT: {
            return (
                <img
                    alt='logo'
                    width={100}
                    height={0.6 * 100}
                    className={classes.logo}
                    src='/static/images/aon.png'
                    onClick={onClick}
                />
            );
        }
        case AppInstances.AON_DIGITAL:
        case AppInstances.AON_MOTOR: {
            return (
                <img
                    alt='logo'
                    width={130}
                    height={0.6 * 130}
                    className={classes.logo}
                    src='/static/images/aon.png'
                    onClick={onClick}
                />
            );
        }
        case AppInstances.HECTOR:
        case AppInstances.HECTOR_DEMO: {
            return (
                <div
                    className={classes.logoWrapper}
                    onClick={onClick}
                    role='link'
                    tabIndex={0}
                >
                    <HectorLogo />
                </div>
            );
        }
        case AppInstances.CEAT: {
            return (
                <img
                    alt='logo'
                    width={150}
                    height={0.38 * 150}
                    className={classes.logo}
                    src='/static/images/ceat-logo.png'
                    onClick={onClick}
                />
            );
        }
        default: {
            return (
                <img
                    alt='logo'
                    width={150}
                    height={22}
                    className={classes.logo}
                    src={`/static/images/sgi-logo${
                        theme.palette.type === THEME_MODES.DARK ? '-light' : '-dark'
                    }.png`}
                    onClick={onClick}
                />
            );
        }
    }
    return <div>ThemeSimpleHeader</div>;
};
