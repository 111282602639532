import React, { BaseSyntheticEvent, memo } from 'react';
import {
    FormControl,
    FormControlClassKey,
    FormControlLabel,
    FormControlLabelClassKey,
    OutlinedInput,
    OutlinedInputClassKey,
} from '@material-ui/core';
import { ClassNameMap } from 'notistack';

interface ThemeOutlineInputProps {
    value: unknown;
    label: string;
    name: string;
    formControlClasses?: Partial<ClassNameMap<FormControlClassKey>>;
    formControlLabelClasses?: Partial<ClassNameMap<FormControlLabelClassKey>>;
    inputClasses?: Partial<ClassNameMap<OutlinedInputClassKey>>;
    handleChangeValue?: (event: BaseSyntheticEvent) => void;
    disabled?: boolean;
    regexp?: RegExp;
    onBlur?: () => void;
    onFocus?: () => void;
    error?: boolean;
    maxlength?: number;
}

export const ThemeOutlineInput = memo(
    ({
        formControlClasses,
        formControlLabelClasses,
        inputClasses,
        value,
        handleChangeValue,
        label,
        name,
        disabled,
        regexp,
        error,
        onBlur,
        onFocus,
        maxlength,
    }: ThemeOutlineInputProps) => {
        const onChange = (e: BaseSyntheticEvent): void => {
            if (regexp) {
                if (regexp.test(e.target.value)) {
                    handleChangeValue(e);
                } else {
                    e.stopPropagation();
                    e.preventDefault();
                    e.target.value = '';
                }
            } else {
                handleChangeValue(e);
            }
        };

        return (
            <FormControl
                fullWidth
                classes={formControlClasses}
                variant='outlined'
                error={error}
            >
                <FormControlLabel
                    classes={formControlLabelClasses}
                    control={
                        <OutlinedInput
                            disabled={disabled}
                            classes={inputClasses}
                            value={value}
                            onChange={onChange}
                            name={name}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            inputProps={{
                                maxLength: maxlength,
                            }}
                        />
                    }
                    label={label}
                    labelPlacement='top'
                />
            </FormControl>
        );
    }
);
