import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { CheckboxItem } from 'src/theming';
import { useGlobalFilter } from '../contexts';

export const useProductsDropDownValues = (isAccess: boolean): CheckboxItem[] => {
    const [values, setValues] = useState<CheckboxItem[]>([]);
    const httpClient = useHttpClient();

    const { t } = useTranslation('common');
    const { division } = useGlobalFilter();

    useEffect(() => {
        if (isAccess) {
            const selectedDivisions = division ? [division] : null;
            const divisionsParameter = selectedDivisions
                ? selectedDivisions?.reduce(
                      (sum, curr) => sum + `?AllowedDivisions=${curr}`,
                      ''
                  )
                : '';
            httpClient
                .get(`products/select-items${divisionsParameter}`)
                .then((data: { label: string; value: string }[]) => {
                    const formattedValues = data
                        ? data?.map((item) => ({
                              title: item.label,
                              value: item.value,
                              checked: false,
                              data: undefined,
                          }))
                        : [];
                    setValues([
                        { title: t('all'), value: 'all', checked: true, data: undefined },
                        ...formattedValues,
                    ]);
                });
        }
    }, [isAccess, division]);

    return values;
};
