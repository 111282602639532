import React from 'react';

import { useAuth } from 'src/user-management/context-auth';
import { ROLES } from 'src/shared/constants';

interface Props {
    roles?: string[];
}

export const withComponentAccessDependingOnRole = <T extends Props>(
    Component: React.ComponentType<T>
): React.FC<T> => {
    return (props: T): JSX.Element => {
        const { roles = [] } = props;
        const {
            userData: {
                userInfo: { roles: userRoles },
            },
        } = useAuth();

        const intersection = new Set(
            [...roles].filter((x) => new Set(userRoles).has(x as ROLES))
        );

        if (!intersection.size) {
            return null;
        }

        return <Component {...props} />;
    };
};
