import { makeStyles, Theme } from '@material-ui/core/styles';
import { THEME_MODES } from 'src/theming';

export const useAonStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        pageContainer: {
            height: '100%',
            alignContent: 'flex-start',
            padding: `0 ${theme.spacing(1)}rem!important`,
            backgroundColor: theme.palette.primary.main,
        },
        titleContainer: {
            width: '100%',
            height: 75,
            alignItems: 'center',
            [theme.breakpoints.down('lg')]: {
                height: 50,
            },
        },
        backButton: {
            marginRight: theme.spacing(2),
            borderRadius: 0,
            '& .MuiSvgIcon-root': {
                width: 25,
                height: 20,
            },
        },
        title: {
            color: theme.palette.primary.contrastText,
            fontWeight: theme.typography.fontWeightMedium,
        },
        cardsContainer: {
            height: '90%',
            '&>div': {
                width: '50%',
            },
        },
        card: {
            backgroundColor: theme.palette.primary.light,
        },
        header: {
            flexDirection: 'row',
            width: '100%',
        },
        cardTitle: {
            fontSize: '1.8rem',
            color: !isDarkMode
                ? theme.palette.secondary.main
                : theme.palette.secondary.contrastText,
        },
        menuIcon: {
            width: 20,
            height: 20,
            color: theme.palette.info.main,
        },
        additionalMenuButton: {
            width: 40,
            height: 40,
            borderRadius: 0,
            backgroundColor: theme.palette.info.light,
            transition: '0.2s',
            '&:hover': {
                backgroundColor: theme.palette.info.light,
                opacity: 0.8,
            },
        },
        list: {
            width: '40%',
            '& .MuiListItem-root': {
                padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
                    1
                )}px ${theme.spacing(2)}px`,
            },
            '& .MuiListItemText-root': {
                width: '100%',
                display: 'flex',
                margin: 0,
                wordBreak: 'break-all',
                color: theme.palette.primary.contrastText,
                '&[data-title]': {
                    opacity: 0.8,
                },
                '&[data-value]': {
                    paddingLeft: theme.spacing(2),
                },
            },
            '& .MuiTypography-root': {
                fontSize: '1.6rem',
            },
        },
        tableContainer: {
            width: '100%',
            height: '90%',
            paddingTop: theme.spacing(5),
        },
        dateSearchFieldClass: {
            '& .MuiInputBase-root': {
                display: 'none',
            },
        },
    };
});

export const usePaperAonStyle = makeStyles((theme: Theme) => {
    return {
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'flex-start',
            height: '100%',
            padding: theme.spacing(4),
            borderRadius: 4,
            border: `1px solid ${
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.grey['400']
                    : theme.palette.grey['200']
            }`,
            backgroundColor: theme.palette.primary.light,
            boxShadow: 'none',
        },
    };
});
