import React from 'react';
import { ThemeOptions, ThemeProvider } from '@material-ui/core';
import {
    darkPaletteForCeat,
    lightPaletteForCeat,
} from 'src/theming/themes/ceat/ceat-theme-palettes';
import { THEME_MODES } from 'src/theming/themes/theme-modes';

interface CeatThemeProviderProps {
    children: JSX.Element;
}

export const CeatThemeProvider = (props: CeatThemeProviderProps): JSX.Element => {
    return (
        <ThemeProvider
            theme={(outerTheme): ThemeOptions => {
                const hectorTheme: ThemeOptions = { ...outerTheme };
                if (hectorTheme.palette.type === THEME_MODES.DARK) {
                    hectorTheme.palette = {
                        ...hectorTheme.palette,
                        ...darkPaletteForCeat,
                    };
                } else {
                    hectorTheme.palette = {
                        ...hectorTheme.palette,
                        ...lightPaletteForCeat,
                    };
                }
                return hectorTheme;
            }}
        >
            {props.children}
        </ThemeProvider>
    );
};
