import React from 'react';
import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { CheckboxItem } from 'src/theming';
import { useStyle } from './header-column-style';
import { useTranslation } from 'react-i18next';

interface Props {
    columnName: string;
    columnValues: CheckboxItem[];
    customers?: boolean;
}

export const HeaderColumn = ({
    columnName,
    columnValues,
    customers,
}: Props): JSX.Element => {
    const classes = useStyle();
    const { t } = useTranslation(['common']);
    return (
        <>
            <Typography variant='h5'>{columnName}</Typography>
            <List>
                {columnValues.map((value) => (
                    <ListItem key={value.value} className={classes.columnValueContainer}>
                        <ListItemText className={classes.columnValue}>
                            {t(value.title)}
                        </ListItemText>
                        {customers && value.data && (
                            <>
                                {value.data.customerName2 && (
                                    <ListItemText className={classes.columnSubValueBlock}>
                                        <span className={classes.columnSubValue}>
                                            {value.data.customerName2}
                                        </span>
                                    </ListItemText>
                                )}
                                {value.data.customerName3 && (
                                    <ListItemText className={classes.columnSubValueBlock}>
                                        <span className={classes.columnSubValue}>
                                            {value.data.customerName3}
                                        </span>
                                    </ListItemText>
                                )}
                            </>
                        )}
                    </ListItem>
                ))}
            </List>
        </>
    );
};
