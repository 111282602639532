import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Grid, IconButton, Typography } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { DamagesDetailsViewDetails } from './damages-details-view-details';
import { DamagesDetailsViewDocuments } from './damages-details-view-documents';

import { useStyle } from './damages-details-view-style/damages-details-view-style';

export const DamagesDetailsView = (): JSX.Element => {
    const classes = useStyle();
    const router = useHistory();
    const location = useLocation<{ previousPath: string; prevState: unknown }>();

    const previousPath = location.state?.previousPath;
    const prevState = location.state?.prevState;

    const [title, setTitle] = useState<string>('');
    const [needToRefetch, setNeedToRefetch] = useState<boolean>(true);

    const handleGoBack = (): void => {
        if (previousPath) {
            router.push(previousPath, prevState);
        } else {
            router.goBack();
        }
    };

    return (
        <Grid container className={classes.pageContainer}>
            <Grid container className={classes.titleContainer}>
                <IconButton
                    size='small'
                    className={classes.backButton}
                    onClick={handleGoBack}
                >
                    <KeyboardBackspaceIcon />
                </IconButton>
                <Typography variant='h5' className={classes.title}>
                    {title}
                </Typography>
            </Grid>
            <Grid container spacing={4} className={classes.cardsContainer}>
                <Grid item>
                    <DamagesDetailsViewDetails
                        setTitle={setTitle}
                        setNeedToRefetch={setNeedToRefetch}
                    />
                </Grid>
                <Grid item>
                    <DamagesDetailsViewDocuments
                        needToRefetch={needToRefetch}
                        setNeedToRefetch={setNeedToRefetch}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
