import React, { useCallback } from 'react';
import { appInstance } from 'src/environment';
import { AppInstances } from 'src/shared/constants';
import { useHistory } from 'react-router-dom';

import { useTheme } from '@material-ui/core';

import { APP_ROUTES } from 'src/routing';
import { useAuth } from 'src/user-management/context-auth';

import { HectorLogo, HectorDemoLogo } from '../theme-logos';
import { useStyle } from './theme-header-styles/theme-header-styles';
import { THEME_MODES } from '..';

export const ThemeHeaderLogo = (): JSX.Element => {
    const classes = useStyle();

    const theme = useTheme();

    const router = useHistory();
    const { homePath } = useAuth();

    const handleGoTo = useCallback(
        (pathname: APP_ROUTES): void => {
            router.push(pathname);
        },
        [router]
    );

    switch (appInstance) {
        case AppInstances.AON:
        case AppInstances.AON_KOMPOSIT: {
            return (
                <img
                    alt='logo'
                    width={100}
                    height={0.6 * 100}
                    className={classes.logo}
                    src='/static/images/aon.png'
                    onClick={() => handleGoTo(homePath)}
                />
            );
        }
        case AppInstances.AON_DIGITAL:
        case AppInstances.AON_MOTOR: {
            return (
                <img
                    alt='logo'
                    width={130}
                    height={0.6 * 130}
                    className={classes.logo}
                    src='/static/images/aon.png'
                    onClick={() => handleGoTo(homePath)}
                />
            );
        }
        case AppInstances.HECTOR: {
            return (
                <div
                    role='link'
                    tabIndex={0}
                    className={classes.logoWrapper}
                    onClick={() => handleGoTo(homePath)}
                >
                    <HectorLogo />
                </div>
            );
        }
        case AppInstances.HECTOR_DEMO: {
            return (
                <div
                    role='link'
                    tabIndex={0}
                    className={classes.logoWrapper}
                    onClick={() => handleGoTo(homePath)}
                >
                    <HectorDemoLogo />
                </div>
            );
        }

        case AppInstances.CEAT: {
            return (
                <img
                    alt='logo'
                    width={150}
                    height={0.38 * 150}
                    className={classes.logo}
                    src='/static/images/ceat-logo.png'
                    onClick={() => handleGoTo(homePath)}
                />
            );
        }
        default: {
            return (
                <img
                    alt='logo'
                    width={150}
                    height={22}
                    className={classes.logo}
                    src={`/static/images/sgi-logo${
                        theme.palette.type === THEME_MODES.DARK ? '-light' : '-dark'
                    }.png`}
                    onClick={() => handleGoTo(homePath)}
                />
            );
        }
    }
};
