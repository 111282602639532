import { TFunction } from 'i18next';
import { SelectItem } from 'src/theming';

export enum DamageTypes {
    BUSINESS_TRIP = 'Business Trip',
    PRIVATE_TRIP = 'Private Trip',
}

export const damageReportFormGenerateDreiseKindOfTripOptions = (
    t: TFunction
): SelectItem[] => {
    return [
        {
            title: t('damages-report:noChoice'),
            value: 'No choice',
        },
        {
            title: t('damages-report:damageTypeOptions.transportationAccident'),
            value: DamageTypes.BUSINESS_TRIP,
        },
        {
            title: t('damages-report:damageTypeOptions.cargoShift'),
            value: DamageTypes.PRIVATE_TRIP,
        },
    ];
};
