import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '@material-ui/core';

import { appInstance, applicationVersion } from 'src/environment';
import { AppInstances } from 'src/shared/constants';
import { APP_ROUTES } from 'src/routing';

import { useStyle } from './theme-footer-styles';

interface FooterLinks {
    imprintLink: string;
    dataProtectionLink: string;
}

const getFooterLinks = (locale: string): FooterLinks => {
    switch (appInstance) {
        case AppInstances.SGIFFOXXK: {
            return {
                imprintLink: 'https://www.sgia.de/impressum.php',
                dataProtectionLink: 'https://www.sgia.de/datenschutz.php',
            };
        }
        case AppInstances.AON:
        case AppInstances.AON_KOMPOSIT:
        case AppInstances.AON_MOTOR:
        case AppInstances.AON_DIGITAL: {
            return {
                imprintLink: 'https://www.aon.com/germany/ueber-aon/impressum.jsp',
                dataProtectionLink:
                    'https://www.aon.com/germany/ueber-aon/datenschutz.jsp',
            };
        }
        case AppInstances.HECTOR: {
            return {
                imprintLink: `https://www.hector.global/${locale}/impressum-2/`,
                dataProtectionLink: `https://www.hector.global/${locale}/datenschutz-2/`,
            };
        }
        case AppInstances.CEAT: {
            return {
                imprintLink: APP_ROUTES.IMPRINT,
                dataProtectionLink: APP_ROUTES.PRIVACY,
            };
        }
        default: {
            return {
                imprintLink: '',
                dataProtectionLink: '',
            };
        }
    }
};

export const ThemeFooter = (): JSX.Element => {
    const classes = useStyle();
    const { t, i18n } = useTranslation(['common']);

    const footerLinks = getFooterLinks(i18n.language);

    const { imprintLink, dataProtectionLink } = footerLinks;

    return (
        <div className={classes.footer}>
            <span>
                <Link
                    className={classes.footerLink}
                    target='_blank'
                    href={dataProtectionLink}
                >
                    {t('privacy')}
                </Link>
                <span className={classes.separator}>|</span>
                <Link className={classes.footerLink} target='_blank' href={imprintLink}>
                    {t('imprint')}
                </Link>
                <span className={classes.separator}>|</span>
                <span>ZEUSS Plattform - powered by bestdata</span>
                <span className={classes.separator}>|</span>
                <span>Version {applicationVersion}</span>
            </span>
        </div>
    );
};
