import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { useFieldArray, useForm } from 'react-hook-form';
import { FileObject } from 'material-ui-dropzone';
import clsx from 'clsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useParams } from 'react-router';

import {
    ThemeDatePicker,
    ThemeDropZone,
    ThemeModalWindow,
    ThemeCircularProgress,
} from 'src/theming';
import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    OutlinedInput,
    Typography,
} from '@material-ui/core';

import {
    useFormControlLabelStyles,
    useFormControlStyles,
    useInputStyles,
    useStyle,
} from './contract-vehicle-deregistration-form-styles';

import { buildFormData } from 'src/shared/utils';

import { contractVehicleDeregistrationFormValidationSchema } from './contract-vehicle-deregistration-form-validation-schema';

interface VehicleDeregistrationProps {
    isOpen: boolean;
    handleCloseModalWindow: () => void;
    documentFormats: string[];
}

type VehicleDeregistrationFormData = {
    unsubscribeDate: ParsableDate;
    files: FileObject[];
    comment: string;
};

export const VehicleDeregistration = (props: VehicleDeregistrationProps): JSX.Element => {
    const { isOpen, handleCloseModalWindow, documentFormats } = props;

    const [isDeregistrationLoading, setDeregistrationLoading] = useState<boolean>(false);

    const { t } = useTranslation(['common', 'contract-details-view', 'errors']);

    const { id } = useParams<{ id: string }>();

    const httpClient = useHttpClient();
    const { enqueueSnackbar } = useSnackbar();

    const classes = useStyle();
    const formControlLabelClasses = useFormControlLabelStyles();
    const formControlClasses = useFormControlStyles();
    const inputClasses = useInputStyles();

    const { watch, setValue, control, reset, formState, trigger } =
        useForm<VehicleDeregistrationFormData>({
            mode: 'onChange',
            defaultValues: { unsubscribeDate: null, files: [], comment: '' },
            resolver: yupResolver(contractVehicleDeregistrationFormValidationSchema),
        });

    const { fields, remove } = useFieldArray({ control, name: 'files' });

    useEffect(() => {
        if (isOpen) {
            trigger();
        }
    }, [isOpen]);

    const handleChangeValue = (name, value): void => {
        setValue(name, value);
        trigger();
    };

    const [unsubscribeDate, comment, files] = watch([
        'unsubscribeDate',
        'comment',
        'files',
    ]);

    const handleAddFile = (newFiles: FileObject[]): void => {
        setValue('files', [...fields, ...newFiles]);
        trigger();
    };

    const handleDeleteFile = (index: number): void => {
        remove(index);
        trigger();
    };

    const handleClose = (): void => {
        reset({ unsubscribeDate: null, files: [], comment: '' });
        handleCloseModalWindow();
    };

    const handleSave = async (): Promise<void> => {
        try {
            setDeregistrationLoading(true);
            const formattedData = {
                Comment: comment,
                DeregistrationDate: moment(unsubscribeDate).toISOString(),
                DeregistrationCertificates: files?.map((item: FileObject) => item.file),
            };
            const formData = new FormData();
            buildFormData(formData, formattedData, null);
            await httpClient.post(`contracts/${id}/deregister-vehicle`, formData);
            handleCloseModalWindow();
            enqueueSnackbar(
                t(
                    'contract-details-view:contractDetails.vehicleDeregistration.successDeregistration'
                ),
                { variant: 'success' }
            );
        } catch (err) {
            enqueueSnackbar(t('errors:unknownError'), { variant: 'error' });
        } finally {
            setDeregistrationLoading(false);
        }
    };

    return (
        <ThemeModalWindow
            isOpen={isOpen}
            onClose={handleClose}
            modalWindowClass={classes.modalWindowClass}
        >
            <Typography variant='h5' className={classes.modalTitle}>
                {t('contract-details-view:contractDetails.vehicleDeregistration.title')}
            </Typography>
            <Grid container>
                <FormControl
                    fullWidth
                    classes={formControlClasses}
                    className={classes.dateField}
                    variant='outlined'
                >
                    <FormControlLabel
                        classes={formControlLabelClasses}
                        control={
                            <ThemeDatePicker
                                error={Boolean(formState?.errors?.unsubscribeDate)}
                                placeholder={t(
                                    'contract-details-view:contractDetails.vehicleDeregistration.selectDate'
                                )}
                                inputVariant='outlined'
                                value={unsubscribeDate}
                                name='unsubscribeDate'
                                onChange={handleChangeValue}
                            />
                        }
                        label={t(
                            'contract-details-view:contractDetails.vehicleDeregistration.unsubscribeDate'
                        )}
                        labelPlacement='top'
                    />
                </FormControl>
                <FormControl fullWidth classes={formControlClasses} variant='outlined'>
                    <FormControlLabel
                        classes={formControlLabelClasses}
                        control={
                            <ThemeDropZone
                                onAdd={handleAddFile}
                                onRemove={handleDeleteFile}
                                fileObjects={fields}
                                formats={documentFormats}
                                error={Boolean(formState.errors?.files)}
                            />
                        }
                        label={t(
                            'contract-details-view:contractDetails.vehicleDeregistration.deregistrationCertificate'
                        )}
                        labelPlacement='top'
                    />
                </FormControl>
                <FormControl fullWidth classes={formControlClasses} variant='outlined'>
                    <FormControlLabel
                        classes={formControlLabelClasses}
                        control={
                            <OutlinedInput
                                multiline
                                rows={5}
                                classes={inputClasses}
                                name='question'
                                value={comment}
                                onChange={(e) =>
                                    handleChangeValue('comment', e.target.value)
                                }
                                placeholder={t(
                                    'contract-details-view:contractDetails.vehicleDeregistration.enterText'
                                )}
                            />
                        }
                        label={t(
                            'contract-details-view:contractDetails.vehicleDeregistration.comment'
                        )}
                        labelPlacement='top'
                    />
                </FormControl>
            </Grid>
            <Grid container className={classes.modalActionsContainer}>
                <Button
                    disableElevation
                    variant='contained'
                    className={clsx(classes.modalButton, classes.saveButton)}
                    onClick={handleSave}
                    disabled={!formState.isValid || isDeregistrationLoading}
                    startIcon={
                        <ThemeCircularProgress isLoading={isDeregistrationLoading} />
                    }
                >
                    {t('common:send')}
                </Button>
                <Button
                    disabled={isDeregistrationLoading}
                    variant='outlined'
                    className={clsx(classes.modalButton, classes.cancelButton)}
                    onClick={handleClose}
                >
                    {t('common:cancel')}
                </Button>
            </Grid>
        </ThemeModalWindow>
    );
};
