import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { GridSortModel, GridSortModelParams } from '@material-ui/data-grid';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';

import { CheckboxItem, ThemeTable } from 'src/theming';
import { useGlobalFilter } from 'src/shared/contexts';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useDebounce } from 'src/lib/custom-hooks/use-debounce';

import { contractDetailsViewDamagesGenerateDamageStatusValues } from './contract-details-view-configs';

import { generateContractDetailsViewDamagesTableHeader } from './contract-details-view-configs';

import { ContractsDetailsViewDamagesResponseBody } from './contracts-details-view-damages-response-body';
import { contractDetailsViewDamagesAdapter } from './contract-details-view-damages-adapter';
import { contractDetailsViewPrepareQueryForDamagesRequest } from './contract-details-view-prepare-query-for-damages-request';
import { useStyle } from './contracts-details-view-styles';

interface DamagesFormData {
    damageNR: string;
    damageNREstateAgent: string;
    damageNRVU: string;
    sortModel: GridSortModel;
}

export const ContractDetailsViewDamages = (): JSX.Element => {
    const { t } = useTranslation(['damage-details-view', 'assignments']);
    const classes = useStyle();

    const httpClient = useHttpClient();

    const { division } = useGlobalFilter();
    const { state } = useLocation<{ prevPath: string; prevState: unknown }>();

    const [sortModel, setSortModel] = useState(null);

    const [damageStatuses, setDamageStatuses] = useState<CheckboxItem[]>(
        contractDetailsViewDamagesGenerateDamageStatusValues(t)
    );
    const [triggerDamageStatuses, setTriggerDamageStatuses] = useState(false);

    const { id } = useParams<{ id: string }>();

    const [damages, setDamages] = useState(null);
    const [isGetDamagesLoading, setGetDamagesLoading] = useState(false);

    const { setValue, watch } = useForm<DamagesFormData>({
        mode: 'onChange',
    });

    const [damageNR, damageNREstateAgent, damageNRVU] = watch([
        'damageNR',
        'damageNREstateAgent',
        'damageNRVU',
    ]);

    const history = useHistory();

    const debouncedDamageNR = useDebounce(damageNR, 1000);
    const debouncedDamageNREstateAgent = useDebounce(damageNREstateAgent, 1000);
    const debouncedDamageNRVU = useDebounce(damageNRVU, 1000);

    const makeRequestForDamagesList = async (): Promise<
        ContractsDetailsViewDamagesResponseBody[]
    > => {
        setGetDamagesLoading(true);
        const queriesString = contractDetailsViewPrepareQueryForDamagesRequest(
            debouncedDamageNR,
            damageNREstateAgent,
            damageNRVU,
            sortModel,
            damageStatuses
        );
        return httpClient.get<ContractsDetailsViewDamagesResponseBody[]>(
            `contracts/${id}/damages?${queriesString}`
        );
    };

    useEffect(() => {
        setDamageStatuses(contractDetailsViewDamagesGenerateDamageStatusValues(t));
    }, [t]);

    useEffect(() => {
        makeRequestForDamagesList()
            .then((data: ContractsDetailsViewDamagesResponseBody[]) => {
                setDamages(contractDetailsViewDamagesAdapter(data));
            })
            .finally(() => {
                setGetDamagesLoading(false);
            });
    }, [
        debouncedDamageNR,
        debouncedDamageNREstateAgent,
        debouncedDamageNRVU,
        sortModel,
        triggerDamageStatuses,
    ]);

    const changeValue = useCallback(
        (e) => {
            setValue(e.target.name, e.target.value);
        },
        [setValue]
    );

    const handleChangeList = useCallback(
        (newList) => {
            setDamageStatuses(newList);
        },
        [setValue]
    );

    const handleChangeSort = useCallback(
        (sortOrderParams: GridSortModelParams): void => {
            setSortModel(sortOrderParams.sortModel);
        },
        [setValue]
    );

    const columns = useMemo(
        () =>
            generateContractDetailsViewDamagesTableHeader(
                t,
                id,
                damageNR,
                damageNREstateAgent,
                damageNRVU,
                damageStatuses,
                changeValue,
                handleChangeList,
                division,
                () => setTriggerDamageStatuses((value) => !value),
                history.push,
                { prevPath: state?.prevPath, prevState: state?.prevState }
            ),
        [
            t,
            damageNR,
            damageNREstateAgent,
            damageNRVU,
            changeValue,
            damageStatuses,
            handleChangeList,
            division,
            history,
            id,
        ]
    );

    return (
        <div className={classes.damageTableContainer}>
            <ThemeTable
                isLoading={isGetDamagesLoading}
                dataLoadingMessage={t('contract-details-view:damages.dataLoading')}
                withoutPagination
                columns={columns}
                rows={damages}
                noRowsMessage={t('contract-details-view:damages.noDamages')}
                sortModel={sortModel}
                handleChangeSort={handleChangeSort}
            />
        </div>
    );
};
