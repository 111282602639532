import { ChangeEvent } from 'react';
import { CheckboxItem, SelectItem } from 'src/theming';
import { DamageFormConditionalBlock } from '../../damage-report-form-components/damage-report-form-input-containers/damage-form-conditional-block';
import { DamageReportFormContainer } from '../../damage-report-form-components/damage-report-form-input-containers/damage-report-form-container';
import { DamageFormDisabledInput } from '../../damage-report-form-components/damage-report-form-items/damage-form-disabled-input';
import { DamageReportFormCheckboxesGroup } from '../../damage-report-form-components/damage-report-form-items/damage-report-form-checkboxes-group';
import { DamageReportFormFullInput } from '../../damage-report-form-components/damage-report-form-items/damage-report-form-full-input';
import { DamageReportFormFullwidthInput } from '../../damage-report-form-components/damage-report-form-items/damage-report-form-fullwidth-input';
import { DamageReportFormInput } from '../../damage-report-form-components/damage-report-form-items/damage-report-form-input';
import { DamageReportFormSelect } from '../../damage-report-form-components/damage-report-form-items/damage-report-form-select';
import { DamageReportFormCurrencyInput } from '../../damage-report-form-components/damage-report-form-items/damage-form-currency-input';
import { DamageAssessment } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-generate-AON-transp-damage-assessment-selection-options';
import { TransportInfo } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-generate-AON-transp-information-selection-options';
import {
    FormContainerProps,
    FormElement,
    generateDisabledInputsProps,
} from '../damage-report-form-config-GBW-HR-GESCH';

export interface DamageReportFormConfigAONKompositTransportReturnType {
    title?: string;
    id: string;
    isCombination?: boolean;
    showIfFalse?: boolean;
    toggleValue?: () => void;
    Container: (props: FormContainerProps) => JSX.Element;
    formElements: FormElement[];
}

interface FooterElementsProps {
    documentsAttachedOptions: CheckboxItem[];
    handleChangedocumentsAttached: (e: ChangeEvent<HTMLInputElement>) => void;
}

interface DamageReportFormConfigAONKompositTransportProps {
    policyOrCertificateNumber: string;
    insuredCompanyFileNumber: string;
    ibanCompensation: string;
    bic: string;
    bank: string;
    accountOwner: string;
    typeOfDamage: string;
    damageTypeOptions: SelectItem[];
    departureDate: string;
    deliveringCarrier: string;
    senderOrRecipient: string;
    insuredGoods: string;
    damagedGoods: string;
    totalLoss: boolean;
    consignmentComplete: boolean;
    outwardlyDamageShipment: boolean;
    containerNumber: string;
    leadSealOrSeal: string;
    availableUponArrival: boolean;
    damaged: boolean;
    damageAssessment: string;
    damageAssessmentOptions: SelectItem[];
    atThe: string;
    carrierForwardingAgentBeenGivenPureReceipt: boolean;
    averageCommissionerWasCalledIn: boolean;
    damageDescription: string;
    expectedAmountOfDamage: string;
    transportInformationOptions: CheckboxItem[];
    transportFrom: string;
    transportVia: string;
    transportAfter: string;
    storageIn: string;
    storageInOrAt: string;
    exhibition: string;
    handleChangeTransportInformationOptions: (e: ChangeEvent<HTMLInputElement>) => void;
    handleChangeSwitcher: (name: string, value: boolean) => void;
}

export const generateDisabledInputs = ({
    licenseNumber,
    contractNumber,
    policyHolder,
    insuranceCompany,
    errors,
}: generateDisabledInputsProps): DamageReportFormConfigAONKompositTransportReturnType[] => [
    {
        Container: DamageReportFormContainer,
        title: '',
        id: 'generalFormBlock',
        formElements: [
            {
                Item: DamageFormDisabledInput,
                value: licenseNumber,
                label: 'damages-report:generalFormBlock.risk',
                name: 'risk',
            },
            {
                Item: DamageFormDisabledInput,
                value: contractNumber,
                label: 'damages-report:generalFormBlock.contractNumber',
                name: 'contractNumber',
                error: Boolean(errors.contractNumber),
            },
            {
                Item: DamageFormDisabledInput,
                value: policyHolder,
                label: 'damages-report:generalFormBlock.policyHolder',
                name: 'policyHolder',
                error: Boolean(errors.policyHolder),
            },
            {
                Item: DamageFormDisabledInput,
                value: insuranceCompany,
                label: 'damages-report:generalFormBlock.client',
                name: 'insuranceCompany',
                error: Boolean(errors.insuranceCompany),
            },
        ],
    },
];

export const DamageReportFormConfigAONKompositTransp = ({
    policyOrCertificateNumber,
    insuredCompanyFileNumber,
    ibanCompensation,
    bic,
    bank,
    accountOwner,
    typeOfDamage,
    damageTypeOptions,
    departureDate,
    deliveringCarrier,
    senderOrRecipient,
    insuredGoods,
    damagedGoods,
    totalLoss,
    consignmentComplete,
    outwardlyDamageShipment,
    containerNumber,
    leadSealOrSeal,
    availableUponArrival,
    damaged,
    damageAssessment,
    damageAssessmentOptions,
    atThe,
    carrierForwardingAgentBeenGivenPureReceipt,
    averageCommissionerWasCalledIn,
    damageDescription,
    expectedAmountOfDamage,
    transportInformationOptions,
    transportFrom,
    transportVia,
    transportAfter,
    storageIn,
    storageInOrAt,
    exhibition,
    handleChangeTransportInformationOptions,
    handleChangeSwitcher,
}: DamageReportFormConfigAONKompositTransportProps): DamageReportFormConfigAONKompositTransportReturnType[][] => {
    const isTransportChecked = transportInformationOptions?.find(
        (item) => item.value === TransportInfo.TRANSPORT
    )?.checked;
    const isStorageChecked = transportInformationOptions?.find(
        (item) => item.value === TransportInfo.STORAGE
    )?.checked;
    const isExhibitionChecked = transportInformationOptions?.find(
        (item) => item.value === TransportInfo.EXHIBITION
    )?.checked;
    return [
        [
            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'clientInfo',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:clientInfo.policyOrCertificateNumber',
                        name: 'policyOrCertificateNumber',
                        value: policyOrCertificateNumber,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:clientInfo.insuredCompanyFileNumber',
                        name: 'insuredCompanyFileNumber',
                        value: insuredCompanyFileNumber,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:clientInfo.ibanCompensation',
                        name: 'ibanCompensation',
                        value: ibanCompensation,
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:clientInfo.bic',
                        name: 'bic',
                        value: bic,
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:clientInfo.bank',
                        name: 'bank',
                        value: bank,
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:clientInfo.accountOwner',
                        name: 'accountOwner',
                        value: accountOwner,
                    },
                ],
            },
        ],
        [
            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'notificationOfDamageTransportInsurance',
                formElements: [
                    {
                        Item: DamageReportFormSelect,
                        label: 'damages-report:notificationOfDamageTransportInsurance.typeOfDamage',
                        name: 'typeOfDamage',
                        value: typeOfDamage,
                        items: damageTypeOptions,
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:notificationOfDamageTransportInsurance.departureDate',
                        name: 'departureDate',
                        value: departureDate,
                    },
                    {
                        Item: DamageReportFormCheckboxesGroup,
                        label: '',
                        value: [],
                        name: 'transportInformationOptions',
                        options: transportInformationOptions,
                        handleChangeCheckboxesGroup:
                            handleChangeTransportInformationOptions,
                    },
                ],
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: '',
                formElements: isTransportChecked
                    ? [
                          {
                              Item: DamageReportFormInput,
                              label: 'damages-report:notificationOfDamageTransportInsurance.transportFrom',
                              name: 'transportFrom',
                              value: transportFrom,
                          },
                          {
                              Item: DamageReportFormInput,
                              label: 'damages-report:notificationOfDamageTransportInsurance.transportVia',
                              name: 'transportVia',
                              value: transportVia,
                          },
                          {
                              Item: DamageReportFormInput,
                              label: 'damages-report:notificationOfDamageTransportInsurance.transportAfter',
                              name: 'transportAfter',
                              value: transportAfter,
                          },
                      ]
                    : [],
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: '',
                formElements: isStorageChecked
                    ? [
                          {
                              Item: DamageReportFormInput,
                              label: 'damages-report:notificationOfDamageTransportInsurance.storageIn',
                              name: 'storageIn',
                              value: storageIn,
                          },
                          {
                              Item: DamageReportFormInput,
                              label: 'damages-report:notificationOfDamageTransportInsurance.storageInOrAt',
                              name: 'storageInOrAt',
                              value: storageInOrAt,
                          },
                      ]
                    : [],
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: '',
                formElements: isExhibitionChecked
                    ? [
                          {
                              Item: DamageReportFormInput,
                              label: 'damages-report:notificationOfDamageTransportInsurance.exhibition',
                              name: 'exhibition',
                              value: exhibition,
                          },
                      ]
                    : [],
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'notificationOfLiabilityInsurance',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:notificationOfDamageTransportInsurance.deliveringCarrier',
                        name: 'deliveringCarrier',
                        value: deliveringCarrier,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:notificationOfDamageTransportInsurance.senderOrRecipient',
                        name: 'senderOrRecipient',
                        value: senderOrRecipient,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:notificationOfDamageTransportInsurance.insuredGoods',
                        name: 'insuredGoods',
                        value: insuredGoods,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:notificationOfDamageTransportInsurance.damagedGoods',
                        name: 'damagedGoods',
                        value: damagedGoods,
                    },
                ],
            },
        ],
        [
            {
                title: 'damages-report:brodcast.title',
                Container: DamageReportFormContainer,
                id: 'brodcast',
                formElements: [],
            },
            {
                title: 'damages-report:brodcast.totalLoss',
                id: 'totalLoss',
                formElements: [],
                isCombination: totalLoss,
                toggleValue: () => handleChangeSwitcher('totalLoss', !totalLoss),
                Container: DamageFormConditionalBlock,
            },
            {
                title: 'damages-report:brodcast.consignmentComplete',
                id: 'consignmentComplete',
                formElements: [],
                isCombination: consignmentComplete,
                toggleValue: () =>
                    handleChangeSwitcher('consignmentComplete', !consignmentComplete),
                Container: DamageFormConditionalBlock,
            },
            {
                title: 'damages-report:brodcast.outwardlyDamageShipment',
                id: 'outwardlyDamageShipment',
                formElements: [],
                isCombination: outwardlyDamageShipment,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'outwardlyDamageShipment',
                        !outwardlyDamageShipment
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'containerNumber',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:brodcast.containerNumber',
                        name: 'containerNumber',
                        value: containerNumber,
                    },
                ],
            },
        ],
        [
            {
                title: 'damages-report:originalSeal.title',
                Container: DamageReportFormContainer,
                id: 'originalSeal',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:brodcast.leadSealOrSeal',
                        name: 'leadSealOrSeal',
                        value: leadSealOrSeal,
                    },
                ],
            },
            {
                title: 'damages-report:originalSeal.availableUponArrival',
                id: 'availableUponArrival',
                formElements: [],
                isCombination: availableUponArrival,
                toggleValue: () =>
                    handleChangeSwitcher('availableUponArrival', !availableUponArrival),
                Container: DamageFormConditionalBlock,
            },
            {
                title: 'damages-report:originalSeal.damaged',
                id: 'damaged',
                formElements: [],
                isCombination: damaged,
                toggleValue: () => handleChangeSwitcher('damaged', !damaged),
                Container: DamageFormConditionalBlock,
            },
        ],
        [
            {
                title: 'damages-report:damageAssessment.title',
                Container: DamageReportFormContainer,
                id: 'damageAssessment',
                formElements: [
                    {
                        Item: DamageReportFormSelect,
                        label: '',
                        name: 'damageAssessment',
                        value: damageAssessment,
                        items: damageAssessmentOptions,
                    },
                ],
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'atThe',
                formElements:
                    damageAssessment === DamageAssessment.LATER
                        ? [
                              {
                                  Item: DamageReportFormFullInput,
                                  label: 'damages-report:damageAssessment.atThe',
                                  name: 'atThe',
                                  value: atThe,
                              },
                          ]
                        : [],
            },
            {
                title: 'damages-report:damageAssessment.carrierForwardingAgentBeenGivenPureReceipt',
                id: 'carrierForwardingAgentBeenGivenPureReceipt',
                formElements: [],
                isCombination: carrierForwardingAgentBeenGivenPureReceipt,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'carrierForwardingAgentBeenGivenPureReceipt',
                        !carrierForwardingAgentBeenGivenPureReceipt
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: 'damages-report:damageAssessment.averageCommissionerWasCalledIn',
                id: 'averageCommissionerWasCalledIn',
                formElements: [],
                isCombination: averageCommissionerWasCalledIn,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'averageCommissionerWasCalledIn',
                        !averageCommissionerWasCalledIn
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'damageDescription',
                formElements: [
                    {
                        Item: DamageReportFormFullwidthInput,
                        label: 'damages-report:damageAssessment.damageDescription',
                        name: 'damageDescription',
                        value: damageDescription,
                    },
                    {
                        Item: DamageReportFormCurrencyInput,
                        label: 'damages-report:damageAssessment.expectedAmountOfDamage',
                        name: 'expectedAmountOfDamage',
                        value: expectedAmountOfDamage,
                    },
                ],
            },
        ],
    ];
};

export const generateFooter = ({
    documentsAttachedOptions,
    handleChangedocumentsAttached,
}: FooterElementsProps): DamageReportFormConfigAONKompositTransportReturnType[] => {
    return [
        {
            title: 'damages-report:theFollowingDocumentsAreAttached.title',
            Container: DamageReportFormContainer,
            id: 'footer',
            formElements: [
                {
                    Item: DamageReportFormCheckboxesGroup,
                    label: '',
                    value: [],
                    name: 'documentsAttached',
                    options: documentsAttachedOptions,
                    handleChangeCheckboxesGroup: handleChangedocumentsAttached,
                },
            ],
        },
    ];
};
