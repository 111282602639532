import { makeStyles, Theme } from '@material-ui/core';

import { THEME_MODES } from 'src/theming';
import {
    isHectorInstance,
    isSGIFFOXXKInstance,
    isAonDigitalInstance,
    isAonMotorInstance,
    isCeatInstance,
    isHectorDemoInstance,
} from 'src/environment';

export const useReportCenterStyles = makeStyles((theme: Theme) => {
    const isLightMode = THEME_MODES.LIGHT === theme.palette.type;
    return {
        reportSettingsContainer: {
            paddingTop: theme.spacing(4),
            paddingLeft: theme.spacing(2),
            justifyContent: 'flex-start',
            gap: theme.spacing(2),
            [theme.breakpoints.down(
                isHectorInstance || isCeatInstance || isSGIFFOXXKInstance ? 'lg' : 'md'
            )]: {
                padding: `${theme.spacing(2)}px 0`,
            },
        },
        settingItem: {
            width: '30%',
        },
        exportClaimsItem: {
            minWidth: 430,
        },
        settingHeader: {
            fontSize: '1.6rem',
        },
        externalAccordionSummary: {
            flexDirection: 'row',
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
        },
        externalAccordionDetails: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            paddingTop: 0,
        },
        externalAccordionClassName: {
            border: `1px solid ${theme.palette.grey['300']}`,
            borderRadius: '4px!important',
        },
        formContainer: {
            paddingTop: theme.spacing(2),
            borderTop: `1px solid ${theme.palette.grey['300']}`,
        },
        dateField: {
            '& .MuiInputBase-root': {
                height: 40,
            },
        },
        generateReportButton: () => {
            if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
                return {
                    height: 50,
                    marginTop: theme.spacing(2),
                    padding: `0 ${theme.spacing(4)}px`,
                    backgroundColor: isLightMode
                        ? theme.palette.secondary.dark
                        : 'rgba(0, 0, 0, 0)',
                    color: theme.palette.secondary.contrastText,
                    borderRadius: 0,
                    border: isLightMode
                        ? 'none'
                        : `1px solid ${theme.palette.secondary.contrastText}`,
                    transition: '0.2s',
                    '&:hover': {
                        opacity: 0.8,
                        backgroundColor: isLightMode
                            ? theme.palette.secondary.dark
                            : 'rgba(0, 0, 0, 0)',
                        color: theme.palette.secondary.contrastText,
                    },
                    [theme.breakpoints.down('md')]: {
                        height: 40,
                    },
                };
            }
            if (isSGIFFOXXKInstance) {
                return {
                    height: 50,
                    marginTop: theme.spacing(2),
                    padding: `0 ${theme.spacing(4)}px`,
                    backgroundColor: isLightMode
                        ? theme.palette.secondary.light
                        : 'rgba(0, 0, 0, 0)',
                    color: isLightMode
                        ? theme.palette.secondary.contrastText
                        : theme.palette.secondary.contrastText,
                    borderRadius: 5,
                    border: isLightMode
                        ? 'none'
                        : `1px solid ${theme.palette.secondary.contrastText}`,
                    transition: '0.2s',
                    '&:hover': {
                        opacity: 0.8,
                        backgroundColor: isLightMode
                            ? theme.palette.secondary.light
                            : 'rgba(0, 0, 0, 0)',
                        color: isLightMode
                            ? theme.palette.secondary.contrastText
                            : theme.palette.secondary.contrastText,
                    },
                    [theme.breakpoints.down('md')]: {
                        height: 40,
                    },
                };
            }
            if (isHectorInstance || isCeatInstance) {
                return {
                    height: 50,
                    marginTop: theme.spacing(2),
                    padding: `0 ${theme.spacing(4)}px`,
                    backgroundColor: isLightMode
                        ? theme.palette.secondary.light
                        : 'rgba(0, 0, 0, 0)',
                    color: isLightMode
                        ? theme.palette.info.main
                        : theme.palette.secondary.contrastText,
                    borderRadius: 0,
                    border: isLightMode
                        ? 'none'
                        : `1px solid ${theme.palette.secondary.contrastText}`,
                    [theme.breakpoints.down('md')]: {
                        height: 40,
                    },
                };
            }
            return {
                height: 40,
                marginTop: theme.spacing(2),
                padding: `0 ${theme.spacing(4)}px`,
                backgroundColor: theme.palette.info.light,
                color: theme.palette.info.main,
            };
        },
    };
});

export const useFormControlStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            margin: `${theme.spacing(2)}px 0`,
            '& label': {
                fontSize: '2.4rem',
                backgroundColor: `rgba(0, 0, 0, 0)!important`,
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.info.main,
            },
        },
    };
});

export const useFormControlLabelStyles = makeStyles(() => {
    return {
        root: {
            display: 'flex!important',
            alignItems: 'flex-start',
            margin: 0,
            padding: `0!important`,
            '&>div': {
                width: '100%',
                '& .MuiFormControl-root': {
                    width: '100%',
                },
            },
        },
        label: {
            fontSize: '1.4rem',
            opacity: 0.6,
        },
    };
});
