import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import React from 'react';
import i18n from 'i18next';
import { useDashboardNumericValueStyle } from './dashboard-numeric-value-style';
import LoadingIndicator from 'src/shared/loading-indicator';
import { ValueChangeColorScheme } from '../common';

interface OffsetChange {
    offset: number;
    unit?: string;
    colorScheme: ValueChangeColorScheme;
}

interface Props {
    value?: number;
    unit?: string;
    offset?: OffsetChange;
}

export const DashboardNumericValue = ({ value, unit, offset }: Props): JSX.Element => {
    const classes = useDashboardNumericValueStyle({
        offset: offset?.offset,
        colorScheme: offset?.colorScheme,
    });
    const formattedValue = value != null ? Math.round(value) : value || 0;

    return (
        <div className={classes.container}>
            {offset && offset.offset != null && (
                <div className={classes.offsetContainer}>
                    {offset.offset !== 0 && (
                        <div className={classes.offsetIndicatorContainer}>
                            {offset.offset > 0 ? (
                                <ArrowDropUp className={classes.offsetIndicator} />
                            ) : (
                                <ArrowDropDown className={classes.offsetIndicator} />
                            )}
                        </div>
                    )}
                    <span className={classes.offsetValue}>
                        {Math.abs(offset.offset)}
                        {offset.unit && (
                            <span className={classes.offsetUnit}>{offset.unit}</span>
                        )}
                    </span>
                </div>
            )}
            {formattedValue == null ? (
                <LoadingIndicator />
            ) : (
                <div>
                    <span className={classes.value}>
                        {Intl.NumberFormat(i18n.languages).format(formattedValue)}
                    </span>
                    {unit && <span className={classes.unit}>{unit}</span>}
                </div>
            )}
        </div>
    );
};
