import React from 'react';

import { useNotificationCenterContext } from 'src/shared/contexts';
import { NotificationCenterSections } from 'src/shared/contexts/notification-center-context/notification-center-enums';

import { NotificationSectionNewMessageForm } from './notification-section-new-message-form';
import { NotificationSectionMessages } from './notification-center-messages';

export const NotificationCenterBody = (): JSX.Element => {
    const { notificationSection } = useNotificationCenterContext();

    const messagesSection =
        notificationSection === NotificationCenterSections.READ_MESSAGES ||
        notificationSection === NotificationCenterSections.UNREAD_MESSAGES ||
        notificationSection === NotificationCenterSections.ARCHIVED_MESSAGES;

    const newMessageSection =
        notificationSection === NotificationCenterSections.NEW_MESSAGE;

    return (
        <div>
            {messagesSection && <NotificationSectionMessages />}
            {newMessageSection && <NotificationSectionNewMessageForm />}
        </div>
    );
};
