import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { DropzoneAreaBase, FileObject } from 'material-ui-dropzone';

import { useDropDownZoneStyle } from './theme-drop-zone-styles';

import { useCheckTotalFilesSize } from 'src/shared/hooks';

interface ThemeDropZoneProps {
    maxFiles?: number;
    maxLimitInBytes?: number;
    onAdd: (file: FileObject[]) => void;
    onRemove: (index: number) => void;
    fileObjects: FileObject[];
    formats: string[];
    error?: boolean;
    maxTotalFilesSize?: number;
}

export const ThemeDropZone = (props: ThemeDropZoneProps): JSX.Element => {
    const {
        maxFiles,
        maxLimitInBytes,
        onAdd,
        onRemove,
        fileObjects,
        formats,
        error,
        maxTotalFilesSize,
    } = props;

    const checkTotalFileSize = useCheckTotalFilesSize();

    const { t } = useTranslation(['common', 'errors']);

    const dropDownZoneClasses = useDropDownZoneStyle({ error });

    const preventDefault = (e: SyntheticEvent): void => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleChangeFiles = (
        files: FileObject[],
        action: string,
        index?: number
    ): void => {
        if (action === 'add') {
            const isTotalFilesSizeValid = checkTotalFileSize(
                [...fileObjects, ...files],
                maxTotalFilesSize
            );
            if (isTotalFilesSizeValid) {
                onAdd(files);
            }
        }
        if (action === 'delete') {
            setTimeout(() => onRemove(index), 0);
        }
    };

    return (
        <div onClick={preventDefault} role='button' tabIndex={0}>
            <DropzoneAreaBase
                showFileNames
                alertSnackbarProps={{ autoHideDuration: 99999 }}
                dropzoneText={
                    fileObjects?.length > 0 ? null : t('dragAndDropPreviewText')
                }
                classes={dropDownZoneClasses}
                filesLimit={maxFiles}
                maxFileSize={maxLimitInBytes}
                onAdd={(newFiles) => handleChangeFiles(newFiles, 'add')}
                onDelete={(deletedFile, index) =>
                    handleChangeFiles([deletedFile], 'delete', index)
                }
                fileObjects={fileObjects}
                acceptedFiles={formats}
                getFileAddedMessage={(fileName) => t('fileAdded', { fileName })}
                getFileRemovedMessage={(fileName) => t('fileRemoved', { fileName })}
                getDropRejectMessage={(file, acceptedFiles, maxFileSize) => {
                    if (!acceptedFiles.includes(`.${file.name.split('.').pop()}`)) {
                        return t('fileLoadingRejected', {
                            fileName: file.name,
                        });
                    }
                    if (file.size > maxFileSize) {
                        return t('errors:fileSizeOverTheLimit');
                    }
                    return null;
                }}
            />
        </div>
    );
};

ThemeDropZone.defaultProps = {
    maxFiles: 10,
    maxLimitInBytes: 10e6,
    maxTotalFilesSize: 30e6,
};
