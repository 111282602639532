import React, {
    ChangeEvent,
    SyntheticEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import useDarkMode from 'use-dark-mode';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
    GridRowParams,
    GridSortModel,
    GridSortModelParams,
} from '@material-ui/data-grid';
import { Grid, Typography } from '@material-ui/core';

import { ThemeCheckboxesDropdown, ThemeTable } from 'src/theming';
import { useGlobalFilter } from 'src/shared/contexts';
import { useAuth } from 'src/user-management/context-auth';
import { CheckboxItem } from 'src/theming';

import { useGlobalSearchStyle } from './contracts-global-search-styles/contracts-global-search-styles';
import { useStyle } from '../contracts-styles/contracts-styles';

import {
    generateGlobalSearchListDependOnGlobalFilter,
    generateGlobalSearchContractsTableHeader,
    ContractColumnKeys,
} from './global-search-list-configs';

import { useContractsDetailsSearch } from '../contracts-hooks';

import { globalSearchListGetAllowedFilterValues } from './global-search-list-get-allowed-filter-values';
import { globalSearchListGetFilterValuesFromSearchResult } from './global-search-list-get-filter-values-from-search-result';
import { APP_ROUTES } from 'src/routing';

import { GlobalSearchAutocompliteFilter } from './components';

type ContractsGlobalSearchListFormData = {
    page: number;
    rowsPerPage: number;
};

interface ContractsGlobalSearchListProps {
    globalSearchTerm: string;
}

const contractStatusOptions: CheckboxItem[] = [
    {
        title: 'common:all',
        value: 'all',
        checked: true,
        data: undefined,
        labelAsKeyForTFunction: true,
    },
    {
        title: `global-search-list:statuses.Inactive`,
        value: 'Inactive',
        checked: false,
        data: undefined,
        labelAsKeyForTFunction: true,
    },
    {
        title: `global-search-list:statuses.Active`,
        value: 'Active',
        checked: false,
        data: undefined,
        labelAsKeyForTFunction: true,
    },
];

export const ContractsGlobalSearchList = (
    props: ContractsGlobalSearchListProps
): JSX.Element => {
    const darkMode = useDarkMode();
    const { t } = useTranslation(['common', 'global-search-list', 'contract-types']);

    const { globalSearchTerm } = props;

    const classes = useStyle({ isDarkMode: darkMode.value });
    const globalSearchClasses = useGlobalSearchStyle({ filtersCount: 6 });

    const { filter } = useGlobalFilter();
    const { userData } = useAuth();
    const history = useHistory();

    function sortByTitle(a, b): number {
        if (a.title < b.title) {
            return -1;
        }
        if (a.title > b.title) {
            return 1;
        }
        return 0;
    }

    const shallowEqual = (checkboxItems1, checkboxItems2): boolean => {
        const isAllSelected = checkboxItems1?.find((x) => x.value === 'all')?.checked;
        if (checkboxItems2 === null && isAllSelected) {
            return true;
        }
        if (!checkboxItems1?.length || !checkboxItems2?.length || !checkboxItems2) {
            return false;
        }
        for (let i = 1; i < checkboxItems1.length; i++) {
            if (checkboxItems1[i]?.checked !== checkboxItems2[i]?.checked) {
                return false;
            }
        }
        return true;
    };

    const updateData = function (): (
        value: unknown[],
        setFn: React.Dispatch<React.SetStateAction<boolean>>
    ) => void {
        let valueState: unknown[] = null;
        return function (
            value: unknown[],
            setFn: React.Dispatch<React.SetStateAction<boolean>>
        ) {
            if (!shallowEqual(value, valueState)) {
                setFn((x) => !x);
            }
            valueState = [...value];
        };
    };

    const [clientConnections, setClientConnections] = useState([]);
    const [triggerClientConnection, setTriggerClientConnection] = useState(false);
    const updateClientConnections = useMemo(updateData, []);

    const [clients, setClients] = useState([]);
    const [triggerClients, setTriggerClients] = useState(false);
    const updateClients = useMemo(updateData, []);

    const [divisions, setDivisions] = useState([]);
    const [triggerDivisions, setTriggerDivisions] = useState(false);
    const updateDivisions = useMemo(updateData, []);

    const [products, setProducts] = useState([]);
    const [triggerProducts, setTriggerProducts] = useState(false);
    const updateProducts = useMemo(updateData, []);

    const [risks, setRisks] = useState([]);
    const [triggerRisks, setTriggerRisks] = useState(false);
    const updateRisks = useMemo(updateData, []);

    const [statuses, setStatuses] = useState([]);
    const [triggerStatuses, setTriggerStatuses] = useState(false);
    const updateStatuses = useMemo(updateData, []);

    const [sortModel, setSortModel] = useState<GridSortModel>();

    useEffect(() => {
        if (Array.isArray(userData.divisions) && userData.divisions.length > 0) {
            const mappedDivisionsForState = userData.divisions
                .map((item) => ({
                    title: item.label,
                    value: item.value,
                    checked: false,
                }))
                .sort(sortByTitle);
            setDivisions([
                { title: t('all'), value: 'all', checked: true },
                ...mappedDivisionsForState,
            ]);
        }
    }, [userData.divisions, t]);

    const { handleSubmit, watch, setValue } = useForm<ContractsGlobalSearchListFormData>({
        mode: 'onChange',
        defaultValues: {
            page: 0,
            rowsPerPage: 10,
        },
    });

    const handleChangeList = useCallback(
        (newList, name) => {
            const allItemIndex = newList.findIndex((x) => x.value === 'all');
            const allItem = newList[allItemIndex];

            const otherItems = newList.slice(0);
            otherItems?.splice(allItemIndex, 1);
            otherItems?.sort(sortByTitle);
            const sortedItems = [allItem, ...otherItems];

            switch (name) {
                case 'clientConnectionList': {
                    setClientConnections(sortedItems);
                    break;
                }
                case 'clientsList': {
                    setClients(sortedItems);
                    break;
                }
                case 'divisionList': {
                    setDivisions(sortedItems);
                    break;
                }
                case 'statusList': {
                    setStatuses(sortedItems);
                    break;
                }
                case 'productList': {
                    setProducts(sortedItems);
                    break;
                }
                case 'riskList': {
                    setRisks(sortedItems);
                    break;
                }
                default: {
                    setValue(name, sortedItems);
                }
            }
        },
        [setValue, t]
    );

    const onSubmit = (): void => undefined;

    const handleChangePage = (_: SyntheticEvent, newPage: number): void => {
        setValue('page', newPage);
    };

    const handleChangeRowsPerPage = (e: ChangeEvent<HTMLInputElement>): void => {
        setValue('rowsPerPage', Number(e.target.value));
    };
    const handleChangeSort = useCallback(
        (sortOrderParams: GridSortModelParams): void => {
            setSortModel(sortOrderParams.sortModel);
        },
        [setValue]
    );

    const goToDetailsPage = (params: GridRowParams): void => {
        history.push(`${APP_ROUTES.CONTRACTS}/${params.id}`);
    };

    useEffect(() => {
        handleChangeList(
            generateGlobalSearchListDependOnGlobalFilter(t, filter.customerConnections),
            'clientConnectionList'
        );
    }, [filter.customerConnections, t, handleChangeList]);

    useEffect(() => {
        handleChangeList(
            generateGlobalSearchListDependOnGlobalFilter(t, filter.customers),
            'clientsList'
        );
    }, [filter.customers, t, handleChangeList]);

    const [page, rowsPerPage] = watch(['page', 'rowsPerPage']);

    const allowedClientConnections = useMemo(
        () => globalSearchListGetAllowedFilterValues(clientConnections),
        [triggerClientConnection]
    );

    const allowedClients = useMemo(
        () => globalSearchListGetAllowedFilterValues(clients),
        [triggerClients]
    );

    const allowedDivisions = useMemo(
        () => globalSearchListGetAllowedFilterValues(divisions),
        [triggerDivisions]
    );

    const allowedProducts = useMemo(
        () => globalSearchListGetAllowedFilterValues(products),
        [triggerProducts, t]
    );

    const allowedRisks = useMemo(
        () => globalSearchListGetAllowedFilterValues(risks),
        [triggerRisks]
    );

    const allowedStatuses = useMemo(
        () => globalSearchListGetAllowedFilterValues(statuses),
        [triggerStatuses]
    );

    const contracts = useContractsDetailsSearch(
        clients.length !== 0 && clientConnections.length !== 0,
        globalSearchTerm,
        allowedClients,
        allowedDivisions,
        allowedProducts,
        allowedClientConnections,
        allowedRisks,
        allowedStatuses,
        sortModel,
        page,
        rowsPerPage
    );

    useEffect(() => {
        if (
            Array.isArray(contracts?.searchResults?.result) &&
            contracts.searchResults?.result?.length > 0 &&
            risks.length === 0 &&
            products.length === 0 &&
            statuses.length === 0
        ) {
            const productsFromResult = globalSearchListGetFilterValuesFromSearchResult(
                t,
                ContractColumnKeys.PRODUCT,
                contracts.searchResults?.result,
                'productKey'
            ).sort(sortByTitle);
            const risksFromResult = globalSearchListGetFilterValuesFromSearchResult(
                t,
                ContractColumnKeys.RISK,
                contracts.searchResults?.result
            ).sort(sortByTitle);
            setProducts([
                { title: t('all'), value: 'all', checked: true, data: undefined },
                ...productsFromResult,
            ]);
            setRisks([
                { title: t('all'), value: 'all', checked: true, data: undefined },
                ...risksFromResult,
            ]);
            setStatuses(contractStatusOptions);
        }
    }, [contracts, risks, statuses, products]);

    useEffect(() => {
        if (
            Array.isArray(contracts?.searchResults?.result) &&
            contracts.searchResults?.result?.length > 0 &&
            risks.length !== 0 &&
            products.length !== 0 &&
            statuses.length !== 0
        ) {
            setProducts((value) => [
                { title: t('all'), value: 'all', checked: true, data: undefined },
                ...value.slice(1),
            ]);
            setRisks((value) => [
                { title: t('all'), value: 'all', checked: true, data: undefined },
                ...value.slice(1),
            ]);
        }
    }, [t]);

    const columns = useMemo(
        () => generateGlobalSearchContractsTableHeader(t, goToDetailsPage),
        [t]
    );

    const rows = useMemo(
        () =>
            contracts?.searchResults?.result?.map((row) => ({
                ...row,
                status: t(`global-search-list:statuses.${row?.status}`),
            })),
        [contracts?.searchResults?.result, t]
    );

    return (
        <Grid container className={classes.contractsListContainer}>
            <Grid
                container
                item
                component='form'
                className={classes.toolBar}
                onSubmit={handleSubmit(onSubmit)}
            >
                <ThemeCheckboxesDropdown
                    withBadgeNumber
                    buttonTitle={t('global-search-list:clientConnection')}
                    itemsList={clientConnections}
                    setItemsList={handleChangeList}
                    onCloseHandler={() =>
                        updateClientConnections(
                            clientConnections,
                            setTriggerClientConnection
                        )
                    }
                    titleButtonClass={globalSearchClasses.toolBarDropDownButton}
                    name='clientConnectionList'
                />
                <ThemeCheckboxesDropdown
                    withBadgeNumber
                    buttonTitle={t('global-search-list:client')}
                    itemsList={clients}
                    setItemsList={handleChangeList}
                    titleButtonClass={globalSearchClasses.toolBarDropDownButton}
                    onCloseHandler={() => updateClients(clients, setTriggerClients)}
                    name='clientsList'
                />
                <ThemeCheckboxesDropdown
                    withBadgeNumber
                    buttonTitle={t('global-search-list:division')}
                    itemsList={divisions}
                    setItemsList={handleChangeList}
                    onCloseHandler={() => updateDivisions(divisions, setTriggerDivisions)}
                    titleButtonClass={globalSearchClasses.toolBarDropDownButton}
                    name='divisionList'
                />
                <GlobalSearchAutocompliteFilter
                    placeholder='global-search-list:product'
                    name='productList'
                    options={products}
                    className={globalSearchClasses.autocompleteFilter}
                    onChange={handleChangeList}
                    triggerExternalCallback={() =>
                        updateProducts(products, setTriggerProducts)
                    }
                />
                <GlobalSearchAutocompliteFilter
                    placeholder='global-search-list:risk'
                    name='riskList'
                    options={risks}
                    className={globalSearchClasses.autocompleteFilter}
                    onChange={handleChangeList}
                    triggerExternalCallback={() => updateRisks(risks, setTriggerRisks)}
                />
                <ThemeCheckboxesDropdown
                    withBadgeNumber
                    buttonTitle={t('global-search-list:contracts.status')}
                    itemsList={statuses}
                    setItemsList={handleChangeList}
                    titleButtonClass={globalSearchClasses.toolBarDropDownButton}
                    onCloseHandler={() => updateStatuses(statuses, setTriggerStatuses)}
                    name='statusList'
                />
            </Grid>
            <Typography variant='h5' className={globalSearchClasses.title}>
                {t('global-search-list:contracts.title')}
            </Typography>
            <Grid item className={globalSearchClasses.tableContainer}>
                <ThemeTable
                    infinite
                    isLoading={contracts.searchLoading}
                    noRowsMessage={t('common:noContracts')}
                    dataLoadingMessage={t('common:dataLoading')}
                    columns={columns}
                    rows={rows}
                    pageSize={rowsPerPage}
                    page={page}
                    rowsPerPageOptions={[10, 25, 50]}
                    rowCount={contracts?.searchResults?.result?.length}
                    sortModel={sortModel}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleChangeSort={handleChangeSort}
                    onChangePage={handleChangePage}
                    getRowId={(r) => r.amsIdNr}
                    amountRecordsToShow={contracts.generalAmount}
                    amountRecordsToShowLoading={contracts.generalAmountLoading}
                />
            </Grid>
        </Grid>
    );
};
