import { AppInstances } from 'src/shared/constants';
import { appInstance } from 'src/environment';

import { DamageReportFormsAonKompositInstance } from './damage-report-form-aon-komposit-factory';
import { DamageReportFormsSgiffoxxkAndAonIntances } from './damage-report-form-sgi-aon-factory';
import { DamageReportFormsHectorIntance } from './damage-report-form-hector-factory';
import { FormCollection } from './damage-report-form-factory-types';

export const getDamageReportForms = (): FormCollection => {
    switch (appInstance) {
        case AppInstances.AON:
        case AppInstances.SGIFFOXXK:
        case AppInstances.AON_DIGITAL:
        case AppInstances.AON_MOTOR: {
            return DamageReportFormsSgiffoxxkAndAonIntances;
        }
        case AppInstances.AON_KOMPOSIT: {
            return DamageReportFormsAonKompositInstance;
        }
        case AppInstances.HECTOR:
        case AppInstances.CEAT:
        case AppInstances.HECTOR_DEMO: {
            return DamageReportFormsHectorIntance;
        }
        default: {
            return {};
        }
    }
};
