import { NotificationRow } from './notifications-row';
import { NotificationsDto } from './notifications-dto';

export const notificationGetAdapter = (data: NotificationsDto[]): NotificationRow[] => {
    return data.map((item) => {
        return {
            division: item.division,
            id: item.id,
            trigger: item.notificationTriggerType,
            customerAdviser: item.customerAdvisors || [],
            fallbackEmail: item.fallbackEmails,
            customers: item.customerRoles || [],
            furtherReceiversEmail: item.furtherRecieverEmails,
            displayNotificationClerk: item.sendNotificationToCustomer,
            displayNotificationCustomerAdviser: item.sendNotificationToIntern,
            accountManager: item.accountManager,
            subrows:
                item.subRows?.length > 0
                    ? notificationGetAdapter(item.subRows)
                    : item.subRows,
        };
    });
};
