import { makeStyles, Theme } from '@material-ui/core/styles';

import {
    isHectorInstance,
    isCeatInstance,
    isSGIFFOXXKInstance,
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorDemoInstance,
} from 'src/environment';
import { THEME_MODES } from 'src/theming';

export const useStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
        return {
            customerDetailsContainer: {
                height: '100%',
                alignContent: 'flex-start',
                flexDirection: 'column',
                padding: `0 140px`,
                backgroundColor: theme.palette.primary.main,
                [theme.breakpoints.down('lg')]: {
                    padding: `0 ${theme.spacing(1)}px`,
                },
            },
            header: {
                width: '100%',
                height: 75,
                alignItems: 'center',
            },
            backButton: {
                width: 50,
                height: 50,
                marginRight: theme.spacing(4),
                backgroundColor: isDarkMode
                    ? 'rgba(0, 0, 0, 0)'
                    : theme.palette.secondary.dark,
                border: isDarkMode
                    ? `1px solid ${theme.palette.primary.contrastText}`
                    : 'none',
                borderRadius: 0,
                transition: '0.2s',
                '&:hover': {
                    backgroundColor: isDarkMode
                        ? 'rgba(0, 0, 0, 0)'
                        : theme.palette.secondary.dark,
                    opacity: 0.8,
                },
                '& .MuiSvgIcon-root': {
                    width: 40,
                    height: 30,
                    color: theme.palette.secondary.contrastText,
                },
                [theme.breakpoints.down('md')]: {
                    width: 40,
                    height: 40,
                    '& .MuiSvgIcon-root': {
                        width: 25,
                        height: 25,
                    },
                },
            },
            title: {
                fontSize: '2.7rem',
                color: theme.palette.primary.contrastText,
                fontWeight: theme.typography.fontWeightMedium,
                [theme.breakpoints.down('lg')]: {
                    fontSize: '2.2rem',
                },
                [theme.breakpoints.down('md')]: {
                    fontSize: '1.8rem',
                },
            },
            cardsContainer: {
                width: '100%',
                margin: 0,
            },
            customerDataContainer: {
                width: '65%',
                minHeight: '320px',
                [theme.breakpoints.down('lg')]: {
                    width: '60%',
                },
            },
            documentsContainer: {
                width: '35%',
                height: '75vh',
                '& .MuiDataGrid-footer': {
                    display: 'none',
                },
                '& .MuiDataGrid-root': {
                    height: '90%',
                    marginTop: theme.spacing(2),
                },
                '& .MuiDataGrid-row': {
                    backgroundColor: theme.palette.primary.main,
                },
                [theme.breakpoints.down('lg')]: {
                    width: '40%',
                },
            },
            detailsCardContainer: {
                position: 'relative',
                flexGrow: 1,
                alignContent: 'flex-start',
                marginBottom: theme.spacing(4),
                padding: theme.spacing(5),
                backgroundColor: isDarkMode
                    ? theme.palette.primary.dark
                    : theme.palette.primary.light,
            },
            additionalMenuButton: {
                width: 40,
                height: 40,
                borderRadius: isSGIFFOXXKInstance ? 5 : 0,
                backgroundColor: theme.palette.secondary.dark,
                transition: '0.2s',
                '&:hover': {
                    backgroundColor: theme.palette.secondary.dark,
                    opacity: 0.8,
                },
            },
            menuIcon: {
                width: 20,
                height: 20,
                color: theme.palette.secondary.contrastText,
            },
            table: {
                marginLeft: -15,
                borderSpacing: '15px 5px',
            },
            tableCell: {
                textAlign: 'left',
                color: theme.palette.primary.contrastText,
                opacity: 0.8,
            },
            additionalMenuButtonContainer: {
                position: 'absolute',
                top: theme.spacing(4),
                right: theme.spacing(4),
            },
            cardTitle: {
                width: '100%',
                fontSize: '1.8rem',
                color:
                    theme.palette.type === THEME_MODES.LIGHT
                        ? theme.palette.secondary.main
                        : theme.palette.secondary.contrastText,
            },
            halfSize: {
                width: '50%',
            },
            dateSearchFieldClass: {
                '& .MuiInputBase-root': {
                    display: 'none',
                },
                '& .MuiSvgIcon-root': {
                    color: theme.palette.info.contrastText,
                },
            },
            documentLink: {
                display: 'flex',
                justifyContent: 'flex-end',
                textAlign: 'right',
                '& .MuiSvgIcon-root': {
                    width: 20,
                    height: 20,
                    marginRight: 5,
                    transform: 'rotate(45deg)',
                },
                '&:hover': {
                    textDecoration: 'none',
                    opacity: 0.8,
                },
            },
        };
    }
    if (isSGIFFOXXKInstance) {
        return {
            customerDetailsContainer: {
                height: '100%',
                alignContent: 'flex-start',
                flexDirection: 'column',
                padding: `0 140px`,
                backgroundColor: theme.palette.primary.main,
                [theme.breakpoints.down('lg')]: {
                    padding: `0 ${theme.spacing(1)}px`,
                },
            },
            header: {
                width: '100%',
                height: 75,
                alignItems: 'center',
            },
            backButton: {
                width: 50,
                height: 50,
                marginRight: theme.spacing(2),
                backgroundColor: isDarkMode
                    ? 'rgba(0, 0, 0, 0)'
                    : theme.palette.secondary.main,
                border: isDarkMode
                    ? `1px solid ${theme.palette.primary.contrastText}`
                    : 'none',
                borderRadius: 5,
                transition: '0.2s',
                '&:hover': {
                    backgroundColor: isDarkMode
                        ? 'rgba(0, 0, 0, 0)'
                        : theme.palette.secondary.main,
                    opacity: 0.8,
                },
                '& .MuiSvgIcon-root': {
                    width: 40,
                    height: 30,
                    color: theme.palette.secondary.contrastText,
                },
                [theme.breakpoints.down('md')]: {
                    width: 40,
                    height: 40,
                    '& .MuiSvgIcon-root': {
                        width: 25,
                        height: 25,
                    },
                },
            },
            title: {
                fontSize: '2.7rem',
                color: theme.palette.primary.contrastText,
                fontWeight: theme.typography.fontWeightMedium,
                [theme.breakpoints.down('lg')]: {
                    fontSize: '2.2rem',
                },
                [theme.breakpoints.down('md')]: {
                    fontSize: '1.8rem',
                },
            },
            cardsContainer: {
                width: '100%',
                margin: 0,
            },
            customerDataContainer: {
                width: '65%',
                minHeight: '320px',
                [theme.breakpoints.down('lg')]: {
                    width: '60%',
                },
            },
            documentsContainer: {
                width: '35%',
                height: '75vh',
                '& .MuiDataGrid-footer': {
                    display: 'none',
                },
                '& .MuiDataGrid-root': {
                    height: '90%',
                    marginTop: theme.spacing(2),
                },
                '& .MuiDataGrid-row': {
                    backgroundColor: theme.palette.primary.main,
                },
                [theme.breakpoints.down('lg')]: {
                    width: '40%',
                },
            },
            detailsCardContainer: {
                position: 'relative',
                flexGrow: 1,
                alignContent: 'flex-start',
                marginBottom: theme.spacing(4),
                padding: theme.spacing(5),
                backgroundColor: theme.palette.primary.light,
            },
            additionalMenuButton: {
                width: 40,
                height: 40,
                borderRadius: isSGIFFOXXKInstance ? 5 : 0,
                backgroundColor: theme.palette.info.light,
                transition: '0.2s',
                '&:hover': {
                    backgroundColor: theme.palette.info.light,
                    opacity: 0.8,
                },
            },
            menuIcon: {
                width: 20,
                height: 20,
                color: theme.palette.secondary.contrastText,
            },
            table: {
                marginLeft: -15,
                borderSpacing: '15px 5px',
            },
            tableCell: {
                textAlign: 'left',
                color: theme.palette.primary.contrastText,
                opacity: 0.8,
            },
            additionalMenuButtonContainer: {
                position: 'absolute',
                top: theme.spacing(4),
                right: theme.spacing(4),
            },
            cardTitle: {
                width: '100%',
                fontSize: '1.8rem',
                color:
                    theme.palette.type === THEME_MODES.LIGHT
                        ? theme.palette.secondary.main
                        : theme.palette.secondary.contrastText,
            },
            halfSize: {
                width: '50%',
            },
            dateSearchFieldClass: {
                '& .MuiInputBase-root': {
                    display: 'none',
                },
                '& .MuiSvgIcon-root': {
                    color: theme.palette.info.contrastText,
                },
            },
            documentLink: {
                display: 'flex',
                justifyContent: 'flex-end',
                textAlign: 'right',
                '& .MuiSvgIcon-root': {
                    width: 20,
                    height: 20,
                    marginRight: 5,
                    transform: 'rotate(45deg)',
                },
                '&:hover': {
                    textDecoration: 'none',
                    opacity: 0.8,
                },
            },
        };
    }
    if (isHectorInstance || isCeatInstance) {
        return {
            customerDetailsContainer: {
                height: '100%',
                alignContent: 'flex-start',
                flexDirection: 'column',
                padding: `0 140px`,
                backgroundColor: theme.palette.primary.main,
                [theme.breakpoints.down('lg')]: {
                    padding: `0 ${theme.spacing(1)}px`,
                },
            },
            header: {
                width: '100%',
                height: 75,
                alignItems: 'center',
            },
            backButton: {
                width: 50,
                height: 50,
                borderRadius: 0,
                backgroundColor: theme.palette.secondary.main,
                marginRight: theme.spacing(6),
                transition: '0.2s',
                '& .MuiSvgIcon-root': {
                    width: 30,
                    height: 30,
                    color: theme.palette.secondary.contrastText,
                },
                '&:hover': {
                    opacity: 0.8,
                    backgroundColor: theme.palette.secondary.main,
                },
                [theme.breakpoints.down('md')]: {
                    width: 40,
                    height: 40,
                    '& .MuiSvgIcon-root': {
                        width: 25,
                        height: 25,
                    },
                },
            },
            title: {
                fontSize: '2.7rem',
                color: theme.palette.primary.contrastText,
                fontWeight: theme.typography.fontWeightMedium,
                [theme.breakpoints.down('lg')]: {
                    fontSize: '2.2rem',
                },
                [theme.breakpoints.down('md')]: {
                    fontSize: '1.8rem',
                },
            },
            cardsContainer: {
                width: '100%',
                margin: 0,
            },
            customerDataContainer: {
                width: '65%',
                minHeight: '320px',
                [theme.breakpoints.down('lg')]: {
                    width: '60%',
                },
            },
            documentsContainer: {
                width: '35%',
                height: '75vh',
                '& .MuiDataGrid-footer': {
                    display: 'none',
                },
                '& .MuiDataGrid-root': {
                    height: '90%',
                    marginTop: theme.spacing(2),
                },
                '& .MuiDataGrid-row': {
                    backgroundColor: theme.palette.primary.main,
                },
                [theme.breakpoints.down('lg')]: {
                    width: '40%',
                },
            },
            detailsCardContainer: {
                position: 'relative',
                flexGrow: 1,
                alignContent: 'flex-start',
                marginBottom: theme.spacing(4),
                padding: theme.spacing(5),
                backgroundColor: theme.palette.primary.light,
            },
            additionalMenuButton: {
                width: 40,
                height: 40,
                borderRadius: 0,
                backgroundColor: theme.palette.secondary.light,
            },
            menuIcon: {
                width: 20,
                height: 20,
                color: theme.palette.info.main,
            },
            table: {
                marginLeft: -15,
                borderSpacing: '15px 5px',
            },
            tableCell: {
                textAlign: 'left',
                color: theme.palette.primary.contrastText,
                opacity: 0.8,
            },
            additionalMenuButtonContainer: {
                position: 'absolute',
                top: theme.spacing(4),
                right: theme.spacing(4),
            },
            cardTitle: {
                width: '100%',
                fontSize: '1.8rem',
                color:
                    theme.palette.type === THEME_MODES.LIGHT
                        ? theme.palette.secondary.main
                        : theme.palette.secondary.contrastText,
            },
            halfSize: {
                width: '50%',
            },
            dateSearchFieldClass: {
                '& .MuiInputBase-root': {
                    display: 'none',
                },
                '& .MuiSvgIcon-root': {
                    color: theme.palette.info.contrastText,
                },
            },
            documentLink: {
                display: 'flex',
                justifyContent: 'flex-end',
                textAlign: 'right',
                '& .MuiSvgIcon-root': {
                    width: 20,
                    height: 20,
                    marginRight: 5,
                    transform: 'rotate(45deg)',
                },
                '&:hover': {
                    textDecoration: 'none',
                    opacity: 0.8,
                },
            },
        };
    }
    return {
        customerDetailsContainer: {
            height: '100%',
            alignContent: 'flex-start',
            flexDirection: 'column',
            padding: `0 ${theme.spacing(1)}rem`,
            backgroundColor: theme.palette.primary.main,
            [theme.breakpoints.down('md')]: {
                padding: `0 ${theme.spacing(1)}px`,
            },
        },
        header: {
            width: '100%',
            height: 75,
            alignItems: 'center',
        },
        backButton: {
            marginRight: theme.spacing(2),
            '& .MuiSvgIcon-root': {
                width: 25,
                height: 20,
            },
        },
        title: {
            color: theme.palette.primary.contrastText,
            fontWeight: theme.typography.fontWeightMedium,
        },
        cardsContainer: {
            width: '100%',
            margin: 0,
        },
        customerDataContainer: {
            width: '65%',
            minHeight: '320px',
            [theme.breakpoints.down('lg')]: {
                width: '60%',
            },
        },
        documentsContainer: {
            width: '35%',
            height: '75vh',
            '& .MuiDataGrid-footer': {
                display: 'none',
            },
            '& .MuiDataGrid-root': {
                height: '90%',
                marginTop: theme.spacing(2),
            },
            '& .MuiDataGrid-row': {
                backgroundColor: theme.palette.primary.main,
            },
            [theme.breakpoints.down('lg')]: {
                width: '40%',
            },
        },
        detailsCardContainer: {
            position: 'relative',
            flexGrow: 1,
            alignContent: 'flex-start',
            marginBottom: theme.spacing(4),
            padding: theme.spacing(5),
            backgroundColor: theme.palette.primary.light,
        },
        additionalMenuButton: {
            width: 40,
            height: 40,
            borderRadius: 0,
            backgroundColor: theme.palette.info.light,
        },
        menuIcon: {
            width: 20,
            height: 20,
            color: theme.palette.info.main,
        },
        table: {
            marginLeft: -15,
            borderSpacing: '15px 5px',
        },
        tableCell: {
            textAlign: 'left',
            color: theme.palette.primary.contrastText,
            opacity: 0.8,
        },
        additionalMenuButtonContainer: {
            position: 'absolute',
            top: theme.spacing(4),
            right: theme.spacing(4),
        },
        cardTitle: {
            width: '100%',
            fontSize: '1.8rem',
            color:
                theme.palette.type === THEME_MODES.LIGHT
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.contrastText,
        },
        halfSize: {
            width: '50%',
        },
        dateSearchFieldClass: {
            '& .MuiInputBase-root': {
                display: 'none',
            },
            '& .MuiSvgIcon-root': {
                color: theme.palette.info.contrastText,
            },
        },
        documentLink: {
            display: 'flex',
            justifyContent: 'flex-end',
            textAlign: 'right',
            '& .MuiSvgIcon-root': {
                width: 20,
                height: 20,
                marginRight: 5,
                transform: 'rotate(45deg)',
            },
            '&:hover': {
                textDecoration: 'none',
                opacity: 0.8,
            },
        },
    };
});

export const useCustomerDetailsViewStyle = makeStyles((theme: Theme) => {
    return {
        list: {
            width: '30%',
            '&:not(:nth-child(2))': {
                marginLeft: theme.spacing(8),
            },
            '& .MuiListItem-root': {
                padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
                    1
                )}px ${theme.spacing(2)}px`,
            },
            '& .MuiListItemText-root': {
                width: '100%',
                display: 'flex',
                margin: 0,
                wordBreak: 'break-all',
                color: theme.palette.primary.contrastText,
                '&[data-title]': {
                    opacity: 0.8,
                },
                '&[data-value]': {
                    paddingLeft: theme.spacing(2),
                },
            },
            '& .MuiTypography-root': {
                fontSize: '1.6rem',
            },
        },
    };
});

export const usePaperStyle = makeStyles((theme: Theme) => {
    const isLightMode = THEME_MODES.LIGHT === theme.palette.type;
    return {
        root: {
            display: 'flex',
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            height: '100%',
            padding: theme.spacing(4),
            borderRadius: 4,
            border: `1px solid ${
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.grey['400']
                    : theme.palette.grey['200']
            }`,
            backgroundColor: () => {
                if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
                    return isLightMode
                        ? theme.palette.primary.light
                        : theme.palette.primary.dark;
                }
                return theme.palette.primary.light;
            },
            boxShadow: 'none',
        },
    };
});
