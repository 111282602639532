import { makeStyles, Theme } from '@material-ui/core/styles';
import {
    isHectorInstance,
    isSGIFFOXXKInstance,
    isAonDigitalInstance,
    isAonMotorInstance,
    isCeatInstance,
    isHectorDemoInstance,
} from 'src/environment';
import { THEME_MODES } from '..';

export const useStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    if (
        isHectorInstance ||
        isSGIFFOXXKInstance ||
        isAonDigitalInstance ||
        isAonMotorInstance ||
        isCeatInstance ||
        isHectorDemoInstance
    ) {
        return {
            itemsContainer: {
                display: 'flex',
                flexDirection: 'column',
                '& .MuiPaper-root': {
                    backgroundColor: () => {
                        if (isHectorInstance || isSGIFFOXXKInstance || isCeatInstance) {
                            return theme.palette.secondary.contrastText;
                        }
                        return isDarkMode
                            ? theme.palette.primary.main
                            : theme.palette.secondary.contrastText;
                    },
                },
            },
            button: {
                width: '100%',
                color: theme.palette.primary.contrastText,
                '& .MuiSvgIcon-root': {
                    width: 30,
                    height: 30,
                    color: () => {
                        if (isHectorInstance || isSGIFFOXXKInstance || isCeatInstance) {
                            return theme.palette.type === THEME_MODES.LIGHT
                                ? theme.palette.secondary.main
                                : theme.palette.secondary.contrastText;
                        }
                        return isDarkMode
                            ? theme.palette.primary.contrastText
                            : theme.palette.secondary.dark;
                    },
                    [theme.breakpoints.down('md')]: {
                        width: 25,
                        height: 25,
                    },
                },
            },
            buttonText: {
                fontSize: '1.8rem',
                [theme.breakpoints.down('md')]: {
                    fontSize: '1.4rem',
                },
            },
            menuItem: {
                display: 'flex',
                color: () => {
                    if (
                        isAonMotorInstance ||
                        isAonDigitalInstance ||
                        isHectorDemoInstance
                    ) {
                        return isDarkMode
                            ? theme.palette.secondary.contrastText
                            : theme.palette.secondary.dark;
                    }
                    return theme.palette.secondary.main;
                },
            },
            menuItemText: {
                flexGrow: 1,
                fontSize: '1.4rem',
            },
            iconButton: {
                '& .MuiSvgIcon-root': {
                    width: 20,
                    height: 20,
                },
            },
            additionalActive: {
                marginLeft: theme.spacing(2),
                '&:hover': {
                    backgroundColor: theme.palette.info.light,
                },
            },
            additionalActionActive: {
                '& .MuiSvgIcon-root': {
                    color: theme.palette.info.main,
                },
            },
        };
    }
    return {
        itemsContainer: {
            display: 'flex',
            flexDirection: 'column',
            '& .MuiPaper-root': {
                backgroundColor: theme.palette.secondary.contrastText,
            },
        },
        button: {
            width: '100%',
            color: theme.palette.secondary.contrastText,
        },
        buttonText: {
            fontSize: '1.4rem',
        },
        menuItem: {
            display: 'flex',
            color: theme.palette.secondary.main,
        },
        menuItemText: {
            flexGrow: 1,
            fontSize: '1.4rem',
        },
        iconButton: {
            '& .MuiSvgIcon-root': {
                width: 20,
                height: 20,
            },
        },
        additionalActive: {
            marginLeft: theme.spacing(2),
            '&:hover': {
                backgroundColor: theme.palette.info.light,
            },
        },
        additionalActionActive: {
            '& .MuiSvgIcon-root': {
                color: theme.palette.info.main,
            },
        },
    };
});
