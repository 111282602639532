import { TFunction } from 'i18next';
import { CheckboxItem } from 'src/theming';

export enum TransportInfo {
    TRANSPORT = 'transport',
    STORAGE = 'storage',
    EXHIBITION = 'exhibition',
}

export const damageReportFormAonTranspInformationOptions = (
    t: TFunction,
    chosenDocuments: string[]
): CheckboxItem[] => {
    return [
        {
            title: t('damages-report:transportInfo.transport'),
            value: TransportInfo.TRANSPORT,
            checked: chosenDocuments.includes(TransportInfo.TRANSPORT),
            data: undefined,
        },
        {
            title: t('damages-report:transportInfo.storage'),
            value: TransportInfo.STORAGE,
            checked: chosenDocuments.includes(TransportInfo.STORAGE),
            data: undefined,
        },
        {
            title: t('damages-report:transportInfo.exhibition'),
            value: TransportInfo.EXHIBITION,
            checked: chosenDocuments.includes(TransportInfo.EXHIBITION),
            data: undefined,
        },
    ];
};
