import { makeStyles, Theme } from '@material-ui/core/styles';
import { THEME_MODES } from 'src/theming';

export const useAonMotorStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        modalWindowClass: {
            '& .MuiPaper-root': {
                width: 900,
                paddingRight: theme.spacing(1),
            },
        },
        modalTitle: {
            color: theme.palette.primary.contrastText,
        },
        fieldsColumn: {
            width: '50%',
            paddingBottom: `0!important`,
        },
        fullWidthFieldsContainer: {
            width: '100%',
            paddingLeft: '0!important',
            paddingTop: '0!important',
            paddingBottom: '0!important',
        },
        multiRowTextField: {
            height: 'auto!important',
        },
        modalContentContainer: {
            width: 'auto',
            maxHeight: 700,
            alignItems: 'flex-start',
            marginLeft: 'auto',
            marginRight: 'auto',
            overflowY: 'auto',
        },
        modalActionsContainer: {
            gap: theme.spacing(3),
            marginTop: theme.spacing(4),
        },
        modalButton: {
            color: theme.palette.primary.contrastText,
        },
        saveButton: {
            color: theme.palette.secondary.contrastText,
            backgroundColor: isDarkMode
                ? theme.palette.info.main
                : theme.palette.secondary.main,
        },
        cancelButton: {
            borderColor: isDarkMode
                ? theme.palette.info.main
                : theme.palette.secondary.main,
        },
        radioButtonsLabel: {
            marginRight: theme.spacing(6),
            color: theme.palette.primary.contrastText,
        },
        contactByPhoneContainer: {
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            paddingLeft: '0!important',

            '& label': {
                backgroundColor: isDarkMode
                    ? theme.palette.primary.dark
                    : theme.palette.primary.light,
                '& .MuiFormControlLabel-label': {
                    color: theme.palette.primary.contrastText,
                },
            },
        },
        radioIcon: () => {
            return null;
        },
        radioCheckedIcon: () => {
            return null;
        },
    };
});

export const useFormControlAonMotorStyles = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        root: {
            margin: `${theme.spacing(2)}px 0`,
            '& label': {
                fontSize: '2.4rem',
                backgroundColor: isDarkMode
                    ? `${theme.palette.primary.dark}!important`
                    : `${theme.palette.primary.light}!important`,
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.info.main,
            },
        },
    };
});

export const useFormControlLabelAonMotorStyles = makeStyles(() => {
    return {
        root: {
            display: 'flex!important',
            alignItems: 'flex-start',
            margin: 0,
            padding: `0!important`,
            '&>div': {
                width: '100%',
                '& .MuiFormControl-root': {
                    width: '100%',
                },
            },
        },
        label: {
            fontSize: '1.4rem',
            opacity: 0.6,
        },
    };
});

export const useInputAonMotorStyles = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        root: {
            width: '100%',
            height: 40,
            backgroundColor: isDarkMode
                ? theme.palette.primary.dark
                : theme.palette.primary.light,
        },
        focused: {
            '& fieldSet': {
                borderColor: `${theme.palette.info.main}!important`,
            },
        },
        input: {
            '&:-internal-autofill-selected': {
                boxShadow: `inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px ${theme.palette.primary.main}`,
            },
        },
    };
});
