import { AbilityActions, AbilitySubjects } from '../constants';

export function adminDomainRoleAccessGenerator(
    can: (actions: string | string[], subjects: string | string[]) => void
): void {
    can(AbilityActions.update, AbilitySubjects.dashboardData);

    can(AbilityActions.see, [
        AbilitySubjects.damages,
        AbilitySubjects.customers,
        AbilitySubjects.contractListProductsDropDown,
        AbilitySubjects.customerConnectionLogo,
        AbilitySubjects.dashboard,
    ]);

    can(AbilityActions.change, AbilitySubjects.dataBase);
    can(
        [AbilityActions.create, AbilityActions.edit, AbilityActions.change],
        [AbilitySubjects.caBroker, AbilitySubjects.internalRoleGroup]
    );
}
