import React, { Ref } from 'react';
import { Typography, makeStyles, Theme, useTheme } from '@material-ui/core';

import { ThemeFilterIcon, ThemeCheckboxesDropdown, CheckboxItem } from '..';

import { useCallback } from 'react';

interface StyleProps {
    leftSeparation: boolean;
    rightSeparation: boolean;
}

const useStyle = makeStyles((theme: Theme) => {
    return {
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            margin: 0,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            borderLeft: ({ leftSeparation }: StyleProps) =>
                leftSeparation ? '1px solid white' : 'none',
            borderRight: ({ rightSeparation }: StyleProps) =>
                rightSeparation ? '1px solid white' : 'none',
        },
        filterIcon: {
            width: 25,
            height: 25,
        },
    };
});

interface CellWithFilterCheckboxesProps {
    title: string;
    itemList: CheckboxItem[];
    setItemList: (list: CheckboxItem[], name?: string) => void;
    name: string;
    onCloseHandler?: () => void;
    innerRef?: Ref<HTMLDivElement>;
    isDragDisabled?: boolean;
    leftSeparation?: boolean;
    rightSeparation?: boolean;
}

export const CellWithFilterCheckboxes = (
    props: CellWithFilterCheckboxesProps
): JSX.Element => {
    const theme = useTheme();
    const {
        title,
        itemList,
        setItemList,
        name,
        onCloseHandler,
        innerRef,
        isDragDisabled,
        leftSeparation,
        rightSeparation,
        ...rest
    } = props;

    const classes = useStyle({ leftSeparation, rightSeparation });

    const handleSetNewList = useCallback(
        (newList): void => {
            setItemList(newList, name);
        },
        [setItemList, name]
    );

    return (
        <div ref={innerRef} {...rest}>
            {isDragDisabled && (
                <ThemeCheckboxesDropdown
                    itemsList={itemList}
                    setItemsList={handleSetNewList}
                    buttonIcon={
                        <ThemeFilterIcon color={theme.palette.info.contrastText} />
                    }
                    onCloseHandler={onCloseHandler}
                />
            )}

            <Typography variant='body2' component='span' className={classes.title}>
                {title}
            </Typography>
        </div>
    );
};
