import { SelectItem } from 'src/theming';

import { DamageReportFormContainer } from '../damage-report-form-components/damage-report-form-input-containers/damage-report-form-container';
import { DamageReportFormInput } from '../damage-report-form-components/damage-report-form-items/damage-report-form-input';
import { DamageFormDisabledInput } from '../damage-report-form-components/damage-report-form-items/damage-form-disabled-input';
import { DamageReportFormSelect } from '../damage-report-form-components/damage-report-form-items/damage-report-form-select';
import { DamageReportFormFullwidthInput } from '../damage-report-form-components/damage-report-form-items/damage-report-form-fullwidth-input';
import { DamageFormConditionalBlock } from '../damage-report-form-components/damage-report-form-input-containers/damage-form-conditional-block';
import { DamageReportFormFullInput } from '../damage-report-form-components/damage-report-form-items/damage-report-form-full-input';
import { DamageReportFormPhoneInput } from '../damage-report-form-components/damage-report-form-items/damage-report-form-phone-input';

import {
    FormContainerProps,
    FormElement,
    generateDisabledInputsProps,
} from './damage-report-form-config-GBW-HR-GESCH';
import { DamageReportFormBHVAndPHVType } from '../damage-report-form-utils/damage-report-form-types/damage-report-form-BHV-PHV-types';
import { FieldErrors } from 'react-hook-form';

export interface DamageReportFormConfigBHVAndPHVReturnType {
    title?: string;
    id: string;
    isCombination?: boolean;
    showIfFalse?: boolean;
    toggleValue?: () => void;
    Container: (props: FormContainerProps) => JSX.Element;
    formElements: FormElement[];
}

interface DamageReportFormConfigBHVAndPHVProps {
    injuredPartySurname: string;
    injuredPartyRoad: string;
    injuredPartyPostcodeAndPlace: string;
    injuredPartyPhonenumber: string;
    injuredPartyEmail: string;
    damageOriginatorSurname: string;
    damageOriginatorRoad: string;
    damageOriginatorPostCodeAndPlace: string;
    damageOriginatorPhoneNumber: string;
    damageOriginatorEmail: string;
    damageOriginatorRelationshipBetweenPolluterAndPolicyholder: string;
    damageInformationDamageType: string;
    damageInformationDamagePlace: string;
    damageInformationWhatWasDamaged: string;
    damageInformationAreDamagedItemsOtherwiseInsured: boolean;
    indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance: string;
    claimsForDamagesMadeAgainstYou: boolean;
    estimatedDamageAmount: string;
    hasDamageAssessed: boolean;
    ifYouAreAtFaultForThatIncident: boolean;
    itWasYourTurnToBeAtFault: boolean;
    existenceBetweenYouAndCoInsured: boolean;
    whichExistenceBetweenYouAndCoInsured: string;
    doYouOrCoInsuredPersonPerformedActivity: boolean;
    whichActivity: string;
    detailedDescription: string;
    areYouEntitledToDeductInputTax: boolean;
    isRecordedByThePolice: boolean;
    policeStation: string;
    fileNumber: string;
    isWitnesses: boolean;
    witnessesSurname: string;
    witnessesRoad: string;
    witnessesPostcode: string;
    witnessesPlace: string;
    witnessesPhoneNumber: string;
    witnessesEmail: string;
    damageTypeOptions: SelectItem[];
    handleChangeSwitcher: (name: string, value: boolean) => void;
    errors: FieldErrors<DamageReportFormBHVAndPHVType>;
}

export const generateDisabledInputs = ({
    licenseNumber,
    contractNumber,
    policyHolder,
    insuranceCompany,
    errors,
}: generateDisabledInputsProps): DamageReportFormConfigBHVAndPHVReturnType[] => {
    return [
        {
            Container: DamageReportFormContainer,
            title: '',
            id: 'generalFormBlock',
            formElements: [
                {
                    Item: DamageFormDisabledInput,
                    value: licenseNumber,
                    label: 'damages-report:generalFormBlock.risk',
                    name: 'risk',
                },
                {
                    Item: DamageFormDisabledInput,
                    value: contractNumber,
                    label: 'damages-report:generalFormBlock.contractNumber',
                    name: 'contractNumber',
                    error: Boolean(errors.contractNumber),
                },
                {
                    Item: DamageFormDisabledInput,
                    value: policyHolder,
                    label: 'damages-report:generalFormBlock.policyHolder',
                    name: 'policyHolder',
                    error: Boolean(errors.policyHolder),
                },
                {
                    Item: DamageFormDisabledInput,
                    value: insuranceCompany,
                    label: 'damages-report:generalFormBlock.client',
                    name: 'insuranceCompany',
                    error: Boolean(errors.insuranceCompany),
                },
            ],
        },
    ];
};

export const DamageReportFormConfigBHVAndPHV = ({
    injuredPartySurname,
    injuredPartyRoad,
    injuredPartyPostcodeAndPlace,
    injuredPartyPhonenumber,
    injuredPartyEmail,
    damageOriginatorSurname,
    damageOriginatorRoad,
    damageOriginatorPostCodeAndPlace,
    damageOriginatorPhoneNumber,
    damageOriginatorEmail,
    damageOriginatorRelationshipBetweenPolluterAndPolicyholder,
    damageInformationDamageType,
    damageInformationDamagePlace,
    damageInformationWhatWasDamaged,
    damageInformationAreDamagedItemsOtherwiseInsured,
    indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance,
    claimsForDamagesMadeAgainstYou,
    estimatedDamageAmount,
    hasDamageAssessed,
    ifYouAreAtFaultForThatIncident,
    itWasYourTurnToBeAtFault,
    existenceBetweenYouAndCoInsured,
    whichExistenceBetweenYouAndCoInsured,
    doYouOrCoInsuredPersonPerformedActivity,
    whichActivity,
    detailedDescription,
    areYouEntitledToDeductInputTax,
    isRecordedByThePolice,
    policeStation,
    fileNumber,
    isWitnesses,
    witnessesSurname,
    witnessesRoad,
    witnessesPostcode,
    witnessesPlace,
    witnessesPhoneNumber,
    witnessesEmail,
    damageTypeOptions,
    handleChangeSwitcher,
    errors,
}: DamageReportFormConfigBHVAndPHVProps): DamageReportFormConfigBHVAndPHVReturnType[][] => {
    return [
        [
            {
                title: 'damages-report:injuredParty.title',
                Container: DamageReportFormContainer,
                id: 'injuredParty',
                formElements: [
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:injuredParty.surname',
                        name: 'injuredPartySurname',
                        value: injuredPartySurname,
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:injuredParty.road',
                        name: 'injuredPartyRoad',
                        value: injuredPartyRoad,
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:injuredParty.postCodeAndTown',
                        name: 'injuredPartyPostcodeAndPlace',
                        value: injuredPartyPostcodeAndPlace,
                    },
                    {
                        Item: DamageReportFormPhoneInput,
                        label: 'damages-report:injuredParty.phoneNumber',
                        name: 'injuredPartyPhonenumber',
                        value: injuredPartyPhonenumber,
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:injuredParty.emailAddress',
                        name: 'injuredPartyEmail',
                        value: injuredPartyEmail,
                        error: Boolean(errors.injuredPartyEmail),
                    },
                ],
            },
        ],
        [
            {
                title: 'damages-report:damageOriginator.title',
                Container: DamageReportFormContainer,
                id: 'damageOriginator',
                formElements: [
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:damageOriginator.surname',
                        name: 'damageOriginatorSurname',
                        value: damageOriginatorSurname,
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:damageOriginator.road',
                        name: 'damageOriginatorRoad',
                        value: damageOriginatorRoad,
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:damageOriginator.postCodeAndTown',
                        name: 'damageOriginatorPostCodeAndPlace',
                        value: damageOriginatorPostCodeAndPlace,
                    },
                    {
                        Item: DamageReportFormPhoneInput,
                        label: 'damages-report:damageOriginator.phoneNumber',
                        name: 'damageOriginatorPhoneNumber',
                        value: damageOriginatorPhoneNumber,
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:damageOriginator.emailAddress',
                        name: 'damageOriginatorEmail',
                        value: damageOriginatorEmail,
                        error: Boolean(errors.damageOriginatorEmail),
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:damageOriginator.relationshipBetweenPolluterAndPolicyholder',
                        name: 'damageOriginatorRelationshipBetweenPolluterAndPolicyholder',
                        value: damageOriginatorRelationshipBetweenPolluterAndPolicyholder,
                    },
                ],
            },
        ],
        [
            {
                title: 'damages-report:damageData.title',
                Container: DamageReportFormContainer,
                id: 'damageData',
                formElements: [
                    {
                        Item: DamageReportFormSelect,
                        label: 'damages-report:damageData.typeOfDamageRequired',
                        name: 'damageInformationDamageType',
                        value: damageInformationDamageType,
                        items: damageTypeOptions,
                        error: Boolean(errors?.damageInformationDamageType),
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:damageData.streetPostcodeCity',
                        name: 'damageInformationDamagePlace',
                        value: damageInformationDamagePlace,
                        error: Boolean(errors?.damageInformationDamagePlace),
                    },
                    {
                        Item: DamageReportFormFullwidthInput,
                        label: 'damages-report:damageData.whatWasDamaged',
                        name: 'damageInformationWhatWasDamaged',
                        value: damageInformationWhatWasDamaged,
                        error: Boolean(errors?.damageInformationWhatWasDamaged),
                    },
                ],
            },
            {
                title: 'damages-report:damageData.areDamagedItemsOtherwiseInsured',
                id: 'areDamagedItemsOtherwiseInsured',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:damageData.indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance',
                        value: indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance,
                        name: 'indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance',
                    },
                ],
                isCombination: damageInformationAreDamagedItemsOtherwiseInsured,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'damageInformationAreDamagedItemsOtherwiseInsured',
                        !damageInformationAreDamagedItemsOtherwiseInsured
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: 'damages-report:damageData.claimsForDamagesMadeAgainstYou',
                id: 'claimsForDamagesMadeAgainstYou',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:damageData.estimatedDamageAmount',
                        value: estimatedDamageAmount,
                        name: 'estimatedDamageAmount',
                    },
                ],
                isCombination: claimsForDamagesMadeAgainstYou,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'claimsForDamagesMadeAgainstYou',
                        !claimsForDamagesMadeAgainstYou
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: 'damages-report:damageData.hasDamageAssessed',
                id: 'hasDamageAssessed',
                formElements: [],
                isCombination: hasDamageAssessed,
                toggleValue: () =>
                    handleChangeSwitcher('hasDamageAssessed', !hasDamageAssessed),
                Container: DamageFormConditionalBlock,
            },
            {
                title: 'damages-report:damageData.ifYouAreAtFaultForThatIncident',
                id: 'ifYouAreAtFaultForThatIncident',
                formElements: [],
                isCombination: ifYouAreAtFaultForThatIncident,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'ifYouAreAtFaultForThatIncident',
                        !ifYouAreAtFaultForThatIncident
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: 'damages-report:damageData.itWasYourTurnToBeAtFault',
                id: 'itWasYourTurnToBeAtFault',
                formElements: [],
                isCombination: itWasYourTurnToBeAtFault,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'itWasYourTurnToBeAtFault',
                        !itWasYourTurnToBeAtFault
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: 'damages-report:damageData.existenceBetweenYouAndCoInsured',
                id: 'existenceBetweenYouAndCoInsured',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:damageData.whichWithS',
                        value: whichExistenceBetweenYouAndCoInsured,
                        name: 'whichExistenceBetweenYouAndCoInsured',
                        error: Boolean(errors.whichExistenceBetweenYouAndCoInsured),
                    },
                ],
                isCombination: existenceBetweenYouAndCoInsured,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'existenceBetweenYouAndCoInsured',
                        !existenceBetweenYouAndCoInsured
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: 'damages-report:damageData.doYouOrCoInsuredPersonPerformedActivity',
                id: 'doYouOrCoInsuredPersonPerformedActivity',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:damageData.which',
                        value: whichActivity,
                        name: 'whichActivity',
                        error: Boolean(errors.whichActivity),
                    },
                ],
                isCombination: doYouOrCoInsuredPersonPerformedActivity,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'doYouOrCoInsuredPersonPerformedActivity',
                        !doYouOrCoInsuredPersonPerformedActivity
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'damageData',
                formElements: [
                    {
                        Item: DamageReportFormFullwidthInput,
                        label: 'damages-report:damageData.detailedDescriptionWithHint',
                        name: 'detailedDescription',
                        value: detailedDescription,
                        error: Boolean(errors.detailedDescription),
                    },
                ],
            },
            {
                title: 'damages-report:damageData.areYouEntitledToDeductInputTax',
                id: 'areYouEntitledToDeductInputTax',
                formElements: [],
                isCombination: areYouEntitledToDeductInputTax,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'areYouEntitledToDeductInputTax',
                        !areYouEntitledToDeductInputTax
                    ),
                Container: DamageFormConditionalBlock,
            },
        ],
        [
            {
                title: 'damages-report:recordedByThePolicy.title',
                Container: DamageReportFormContainer,
                id: 'recordedByThePolicy',
                formElements: [],
            },
            {
                title: 'damages-report:recordedByThePolicy.wasIncidentRecordedByPolice',
                id: 'isRecordedByThePolice',
                formElements: [
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:recordedByThePolicy.receivingPoliceStation',
                        value: policeStation,
                        name: 'policeStation',
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:recordedByThePolicy.policeFileNumber',
                        value: fileNumber,
                        name: 'fileNumber',
                    },
                ],
                isCombination: isRecordedByThePolice,
                toggleValue: () =>
                    handleChangeSwitcher('isRecordedByThePolice', !isRecordedByThePolice),
                Container: DamageFormConditionalBlock,
            },
        ],
        [
            {
                title: 'damages-report:witnesses.title',
                Container: DamageReportFormContainer,
                id: 'witnesses',
                formElements: [],
            },
            {
                title: 'damages-report:witnesses.areThereWitnesses',
                id: 'witnesses',
                formElements: [
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:witnesses.surname',
                        value: witnessesSurname,
                        name: 'witnessesSurname',
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:witnesses.road',
                        value: witnessesRoad,
                        name: 'witnessesRoad',
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:witnesses.postCode',
                        value: witnessesPostcode,
                        name: 'witnessesPostcode',
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:witnesses.place',
                        value: witnessesPlace,
                        name: 'witnessesPlace',
                    },
                    {
                        Item: DamageReportFormPhoneInput,
                        label: 'damages-report:witnesses.phoneNumber',
                        value: witnessesPhoneNumber,
                        name: 'witnessesPhoneNumber',
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:witnesses.emailAddress',
                        value: witnessesEmail,
                        name: 'witnessesEmail',
                        error: Boolean(errors.witnessesEmail),
                    },
                ],
                isCombination: isWitnesses,
                toggleValue: () => handleChangeSwitcher('isWitnesses', !isWitnesses),
                Container: DamageFormConditionalBlock,
            },
        ],
    ];
};

export const generateFooter = (): DamageReportFormConfigBHVAndPHVReturnType[] => {
    return [
        {
            title: 'damages-report:addDocuments.title',
            Container: DamageReportFormContainer,
            id: 'footer',
            formElements: [],
        },
    ];
};
