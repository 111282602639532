import { makeStyles, Theme } from '@material-ui/core';
import { THEME_MODES } from 'src/theming';
import {
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorDemoInstance,
} from 'src/environment';

export const useStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        card: {
            flexGrow: 1,

            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            border: `1px solid ${
                isDarkMode ? theme.palette.grey['400'] : theme.palette.grey['200']
            }`,
            backgroundColor: () => {
                if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
                    return isDarkMode
                        ? theme.palette.primary.main
                        : theme.palette.primary.light;
                }
                return theme.palette.primary.light;
            },
        },
        headerContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: 12,
            borderBottom: `1px solid ${
                isDarkMode ? theme.palette.grey['400'] : theme.palette.grey['200']
            }`,
        },
        header: {
            fontSize: 16,
            fontWeight: 'bold',
        },
        subHeader: {
            fontSize: 12,
            color: theme.palette.text.hint,
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            padding: 8,
            flexGrow: 1,
            maxHeight: 300,
        },
        icon: {
            display: 'flex',
            alignItems: 'center',
            width: 16,
            height: 16,
            color: () => {
                if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
                    return isDarkMode
                        ? theme.palette.secondary.contrastText
                        : theme.palette.info.main;
                }
                return isDarkMode
                    ? theme.palette.secondary.contrastText
                    : theme.palette.secondary.main;
            },
            opacity: 0.6,
        },
        checkedIcon: {
            width: 16,
            height: 16,
            color: theme.palette.info.main,
            opacity: 1,
        },
    };
});
