import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import moment from 'moment';

import { Typography } from '@material-ui/core';

import { NonUsersDamageReportFormNoValidModalWindow } from './non-users-damage-report-form-no-valid-modal-window';
import { NonUsersDamageReportPasswordForm } from './non-users-damage-report-form-password-form';
import { NonUsersDamageReportForm } from './non-users-damage-report-form';
import { NonUsersDamageReportFormSuccessModalWindow } from './non-users-damage-report-form-success-sent-modal-window';

import { ThemeLoader } from 'src/theming';

import { useStyle } from './non-users-damage-report-form-styles';

interface LinkDTO {
    id: string;
    validTo: ParsableDate;
    passwordRequired: boolean;
    status: string;
    customerConnection: string;
    url: string;
}

export const NonUsersDamageReport = (): JSX.Element => {
    const classes = useStyle();
    const { t, i18n } = useTranslation(['common', 'non-users-damage-report-form']);

    const httpClient = useHttpClient();

    const [token, setToken] = useState<string>(null);
    const [linkData, setLinkData] = useState<LinkDTO>(null);
    const [isLinkValid, setLinkValid] = useState<boolean>();
    const [isLocked, setLocked] = useState<boolean>(true);
    const [isValidationLoading, setValidationLoading] = useState(false);
    const [isSuccessModalWindowOpen, setSuccessModalWindowOpen] = useState(false);
    const [isMounted, setMounted] = useState(false);

    const { link } = useParams<{ link: string }>();

    const makeRequestForToken = (
        password?: string
    ): Promise<{ confirmationToken: string }> => {
        return httpClient.post<{ confirmationToken: string }>(
            `external-links/${linkData.id}/confirmation`,
            {
                password,
            }
        );
    };

    const makeRequestForLinkData = useCallback((): Promise<LinkDTO> => {
        setValidationLoading(true);
        return httpClient.get(`external-links/by-url/${link}`);
    }, []);

    useEffect(() => {
        i18n.changeLanguage('de').then(() => {
            setMounted(true);
        });
        makeRequestForLinkData()
            .then((data: LinkDTO) => {
                if (
                    data.validTo
                        ? moment(data.validTo).valueOf() - moment().valueOf() > 0 &&
                          data.status === 'Active'
                        : data.status === 'Active'
                ) {
                    setLinkData(data);
                } else {
                    setLinkValid(false);
                }
            })
            .finally(() => {
                setValidationLoading(false);
            });
    }, []);

    useEffect(() => {
        if (linkData && linkData?.passwordRequired) {
            setLinkValid(true);
        } else if (linkData && !linkData.passwordRequired) {
            setLocked(false);
            setLinkValid(true);
            makeRequestForToken().then((data) => {
                setToken(data.confirmationToken);
            });
        }
    }, [linkData]);

    const handleCloseSuccessModalWindow = (): void => {
        setSuccessModalWindowOpen(false);
    };

    const handleOpenSuccessModalWindow = (): void => {
        setSuccessModalWindowOpen(true);
    };

    return (
        <main className={classes.pageContainer}>
            {isMounted && (
                <>
                    <header className={classes.header}>
                        <Typography variant='h5' className={classes.title}>
                            {t('non-users-damage-report-form:title')}
                        </Typography>
                    </header>
                    {typeof isLinkValid === 'boolean' && !isLinkValid && (
                        <NonUsersDamageReportFormNoValidModalWindow />
                    )}
                    {isLocked && isLinkValid && (
                        <NonUsersDamageReportPasswordForm
                            setLocked={setLocked}
                            setToken={setToken}
                            id={linkData?.id}
                            makeRequestForToken={makeRequestForToken}
                        />
                    )}
                    {isValidationLoading && (
                        <div className={classes.loadingContainer}>
                            <ThemeLoader />
                        </div>
                    )}
                    {isLinkValid && !isLocked && (
                        <NonUsersDamageReportForm
                            token={token}
                            handleOpenSuccessModalWindow={handleOpenSuccessModalWindow}
                        />
                    )}
                    <NonUsersDamageReportFormSuccessModalWindow
                        isOpen={isSuccessModalWindowOpen}
                        handleClose={handleCloseSuccessModalWindow}
                    />
                </>
            )}
        </main>
    );
};
