import React from 'react';
import useDarkMode from 'use-dark-mode';

import { ThemeLayout } from 'src/theming';
import { APP_MODULES } from 'src/shared/constants';
import { Relink } from 'src/user-management';

export const TranscodeDocumentsPage = (): JSX.Element => {
    const darkMode = useDarkMode();
    return (
        <ThemeLayout darkMode={darkMode} module={APP_MODULES.ADMINISTRATION}>
            <Relink />
        </ThemeLayout>
    );
};
