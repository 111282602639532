import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { AppInstances } from 'src/shared/constants';
import { appInstance } from 'src/environment';

import {
    useAonMotorStyle,
    useInputAonMotorStyles,
    useFormControlAonMotorStyles,
    useFormControlLabelAonMotorStyles,
} from './contract-edit-form-aon-motor-styles';

import {
    useAonStyle,
    useFormControlAonStyles,
    useFormControlLabelAonStyles,
    useInputAonStyles,
} from './contract-edit-form-aon-styles';

import {
    useHectorStyle,
    useFormControlHectorStyles,
    useFormControlLabelHectorStyles,
    useInputHectorStyles,
} from './contract-edit-form-hector-styles';
import {
    useSgiffoxxkStyle,
    useFormControlLabelSgiffoxxkStyles,
    useFormControlSgiffoxxkStyles,
    useInputSgiffoxxkStyles,
} from './contract-edit-form-sgiffoxxk-styles';

const styleInstances = {
    [AppInstances.AON]: useAonStyle,
    [AppInstances.AON_KOMPOSIT]: useAonStyle,
    [AppInstances.AON_MOTOR]: useAonMotorStyle,
    [AppInstances.AON_DIGITAL]: useAonMotorStyle,
    [AppInstances.HECTOR_DEMO]: useAonMotorStyle,
    [AppInstances.HECTOR]: useHectorStyle,
    [AppInstances.CEAT]: useHectorStyle,
    [AppInstances.SGIFFOXXK]: useSgiffoxxkStyle,
};

const formControlLabelInstances = {
    [AppInstances.AON]: useFormControlLabelAonStyles,
    [AppInstances.AON_KOMPOSIT]: useFormControlLabelAonStyles,
    [AppInstances.AON_MOTOR]: useFormControlLabelAonMotorStyles,
    [AppInstances.AON_DIGITAL]: useFormControlLabelAonMotorStyles,
    [AppInstances.HECTOR_DEMO]: useFormControlLabelAonMotorStyles,
    [AppInstances.HECTOR]: useFormControlLabelHectorStyles,
    [AppInstances.CEAT]: useFormControlLabelHectorStyles,
    [AppInstances.SGIFFOXXK]: useFormControlLabelSgiffoxxkStyles,
};

const formControlInstances = {
    [AppInstances.AON]: useFormControlAonStyles,
    [AppInstances.AON_KOMPOSIT]: useFormControlAonStyles,
    [AppInstances.AON_MOTOR]: useFormControlAonMotorStyles,
    [AppInstances.AON_DIGITAL]: useFormControlAonMotorStyles,
    [AppInstances.HECTOR_DEMO]: useFormControlAonMotorStyles,
    [AppInstances.HECTOR]: useFormControlHectorStyles,
    [AppInstances.CEAT]: useFormControlHectorStyles,
    [AppInstances.SGIFFOXXK]: useFormControlSgiffoxxkStyles,
};

const inputInstances = {
    [AppInstances.AON]: useInputAonStyles,
    [AppInstances.AON_KOMPOSIT]: useInputAonStyles,
    [AppInstances.AON_MOTOR]: useInputAonMotorStyles,
    [AppInstances.AON_DIGITAL]: useInputAonMotorStyles,
    [AppInstances.HECTOR_DEMO]: useInputAonMotorStyles,
    [AppInstances.HECTOR]: useInputHectorStyles,
    [AppInstances.CEAT]: useInputHectorStyles,
    [AppInstances.SGIFFOXXK]: useInputSgiffoxxkStyles,
};

export const useStyle = (): ClassNameMap => {
    return styleInstances[appInstance]();
};

export const useFormControlStyles = (): ClassNameMap => {
    return formControlInstances[appInstance]();
};

export const useFormControlLabelStyles = (): ClassNameMap => {
    return formControlLabelInstances[appInstance]();
};

export const useInputStyles = (): ClassNameMap => {
    return inputInstances[appInstance]();
};
