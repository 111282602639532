import { useState, useEffect } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

interface Item {
    id: string;
    name: string;
}

export const useAllDivisions = <T>(formatter?: (item: Item) => T): T[] | Item[] => {
    const httpClient = useHttpClient();

    const [data, setData] = useState<Item[] | T[]>(null);

    useEffect(() => {
        if (!data) {
            (async () => {
                const data = await httpClient.get<Item[]>('divisions');
                setData(formatter ? data.map(formatter) : data);
            })();
        }
    }, []);

    return data;
};
