import { makeStyles, Theme } from '@material-ui/core';

export const useThemeAutocompleteMultipleValuesTooltipStyles = makeStyles(
    (theme: Theme) => {
        return {
            tooltip: {
                maxWidth: 'initial',

                '& p': {
                    maxWidth: 'initial',
                    whiteSpace: 'nowrap',
                    color: theme.palette.secondary.contrastText,
                    '&:first-child': {
                        marginTop: '0.5em',
                    },
                    marginBottom: '0.5em',
                },
            },
        };
    }
);
