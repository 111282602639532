import { makeStyles, Theme } from '@material-ui/core/styles';
import {
    isAonDigitalInstance,
    isHectorDemoInstance,
    isAonMotorInstance,
} from 'src/environment';
import { THEME_MODES } from 'src/theming';

interface StyleProps {
    editMode?: boolean;
    isDarkMode?: boolean;
    isActive?: boolean;
}

export const useDivisionSectionAonMotorStyles = makeStyles((theme: Theme) => {
    const isLightMode = THEME_MODES.LIGHT === theme.palette.type;

    return {
        contractTypes: {
            alignContent: 'flex-start',
            backgroundColor: isLightMode
                ? theme.palette.primary.light
                : theme.palette.primary.dark,
        },
        contractType: {
            position: 'relative',
            width: 300,
            height: 130,
            marginBottom: theme.spacing(2),
            marginRight: theme.spacing(3),
        },
        dragContractType: {
            '&:after': {
                content: '""',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundImage: `repeating-linear-gradient(-45deg, ${theme.palette.info.light}, ${theme.palette.info.light} 25px, ${theme.palette.secondary.main} 25px, ${theme.palette.secondary.main} 50px)`,
                border: `2px dashed ${theme.palette.info.main}`,
                zIndex: 1000,
            },
        },
        cardContent: {
            height: '100%',
        },
        cardData: {
            flexDirection: 'column',
            width: '80%',
            paddingRight: theme.spacing(3),
        },
        cardIcon: {
            width: '20%',
            paddingTop: theme.spacing(5),
            '& .MuiSvgIcon-root': {
                width: 40,
                height: 40,
            },
        },
        cardTitle: {
            fontSize: '1.4rem',
            paddingTop: theme.spacing(1),
        },
        cardValue: {
            fontSize: '2.4rem',
            paddingBottom: theme.spacing(2),
        },
        editModeContainer: {
            alignItems: 'center',
            marginBottom: `${theme.spacing(2)}px`,
        },
        editModeButtons: {
            width: '50%',
            marginLeft: theme.spacing(3),
            '&>button': {
                height: 50,
                padding: `0 ${theme.spacing(4)}px`,
                marginRight: theme.spacing(3),
                backgroundColor:
                    theme.palette.type === THEME_MODES.LIGHT
                        ? theme.palette.secondary.dark
                        : 'rgba(0, 0, 0, 0)',
                color: theme.palette.secondary.contrastText,
                borderRadius: 0,
                fontSize: '1.8rem',
                transition: '0.2s',
                border:
                    theme.palette.type === THEME_MODES.LIGHT
                        ? 'none'
                        : `1px solid ${theme.palette.secondary.contrastText}`,
                '&[data-mode=edit]': {
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    color: isLightMode
                        ? theme.palette.secondary.dark
                        : theme.palette.secondary.contrastText,
                    border: `1px solid ${theme.palette.grey.A100}`,
                    '&:hover': {
                        opacity: 0.6,
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                    },
                },
                '&:hover': {
                    opacity: 0.6,
                    backgroundColor: isLightMode
                        ? theme.palette.secondary.dark
                        : 'rgba(0, 0, 0, 0)',
                },
                '& .MuiSvgIcon-root': {
                    width: 30,
                    height: 30,
                },
                [theme.breakpoints.down('md')]: {
                    height: 40,
                    fontSize: '1.6rem',
                    '& .MuiSvgIcon-root': {
                        width: 25,
                        height: 25,
                    },
                },
            },
        },
        showMoreLink: {
            display: 'inline-block',
            marginLeft: 'auto',
            marginRight: `${theme.spacing(1)}rem`,
            marginTop: theme.spacing(1),
            fontWeight: theme.typography.fontWeightMedium,
            color: isLightMode
                ? theme.palette.secondary.dark
                : theme.palette.primary.contrastText,
        },
        indicatorContainer: {
            position: 'absolute',
            top: 5,
            right: 5,
        },
        indicatorButton: {
            width: 25,
            height: 25,
            borderRadius: 0,
            backgroundColor: ({ isActive }: StyleProps) => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return isActive
                        ? theme.palette.info.main
                        : theme.palette.primary.light;
                }
                return isActive
                    ? theme.palette.secondary.main
                    : theme.palette.primary.dark;
            },
            '& .MuiSvgIcon-root': {
                width: 20,
                height: 20,
                '&>path': {
                    fill: ({ isActive }: StyleProps) =>
                        isActive ? theme.palette.primary.light : theme.palette.info.main,
                    opacity: 1,
                },
            },
        },
        divisionsContainer: {
            width: '100%',
        },
        defaultBlock: {
            position: 'absolute',
            bottom: 0,
            right: 0,
            zIndex: 1000,
            '& .MuiFormControlLabel-root': {
                marginRight: 0,
            },
            '& .MuiSvgIcon-root path': {
                fill: ({ isActive }: StyleProps) =>
                    isActive ? theme.palette.primary.main : theme.palette.info.main,
                opacity: 1,
            },
        },
        saveButton: {
            '& .MuiCircularProgress-svg': {
                color: theme.palette.info.main,
            },
        },
    };
});
