import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { Grid, List, ListItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { ThemeNestedMenu, ThemeLoader } from 'src/theming';
import { useAbilityCtx } from 'src/roleAccesses';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

import {
    useStyle,
    useContractDetailsViewDataStyle,
} from '../contracts-details-view-styles';
import { useBHVStyles } from './contract-details-view-bhv.styles';

import { ContractDetailsListItem } from '../contract-details-list-item';

import { ContractDetailsViewDetailsResponseBodyBHV } from './contract-details-view-details-response-body-bhv';

import { generateContractsDetailsAdditionalMenu } from '../contract-details-view-configs';

interface ContractDetailsViewBhvProps {
    handleOpenContractEdit: () => void;
    hideThemeNestedMenu: boolean;
    contractNumber: string;
}

export const ContractDetailsViewBhv = (
    props: ContractDetailsViewBhvProps
): JSX.Element => {
    const { t } = useTranslation([
        'contract-details-view',
        'formatted-values',
        'damages-list',
        'common',
    ]);

    const ability = useAbilityCtx();
    const classes = useStyle();
    const bhvClasses = useBHVStyles();
    const contractDetailsViewDataClasses = useContractDetailsViewDataStyle();

    const { handleOpenContractEdit, hideThemeNestedMenu, contractNumber } = props;

    const router = useHistory();
    const { id } = useParams<{ id: string }>();
    const httpClient = useHttpClient();

    const [isLoadingDetails, setLoadingDetails] = useState(false);
    const [detailsData, setDetailsData] =
        useState<ContractDetailsViewDetailsResponseBodyBHV>();

    const makeRequestForDetails =
        async (): Promise<ContractDetailsViewDetailsResponseBodyBHV> => {
            setLoadingDetails(true);
            return httpClient.get<ContractDetailsViewDetailsResponseBodyBHV>(
                `contracts/${id}/bhv`
            );
        };

    useEffect(() => {
        makeRequestForDetails()
            .then((data: ContractDetailsViewDetailsResponseBodyBHV) => {
                setDetailsData(data);
            })
            .finally(() => {
                setLoadingDetails(false);
            });
    }, []);

    const goTo = (pathname: string): void => {
        router.push(pathname);
    };

    const menuItems = useMemo(
        () =>
            generateContractsDetailsAdditionalMenu(
                t,
                null,
                handleOpenContractEdit,
                goTo,
                ability,
                id,
                contractNumber
            ),
        [t, contractNumber, ability]
    );

    const atLeastOneRiskLocationExist = useMemo(
        () => !detailsData?.riskLocations.every((item) => item.riskLocation === null),
        [detailsData]
    );
    const atLeastOnePostalcodeExist = useMemo(
        () => !detailsData?.riskLocations.every((item) => item.postalCode === null),
        [detailsData]
    );
    const atLeastOneCityExist = useMemo(
        () => !detailsData?.riskLocations.every((item) => item.city === null),
        [detailsData]
    );
    const atLeastOneStreetExist = useMemo(
        () => !detailsData?.riskLocations.every((item) => item.street === null),
        [detailsData]
    );
    const atLeastOneValueInUHVUSVTable = useMemo(
        () => detailsData?.fuelDatas.some((item) => item.layerQuantity || item.number),
        [detailsData]
    );

    const isDetailDataEmpty = useMemo(() => {
        if (detailsData) {
            const detailsDataKeys = Object.keys(detailsData);
            return !detailsDataKeys
                ?.map((x) => detailsData[x])
                ?.filter((x) => (x || typeof x === 'boolean') && !Array.isArray(x))
                ?.length;
        }
        return true;
    }, [detailsData]);

    return (
        <Grid container>
            <Grid container className={classes.titleAndMenuContainer}>
                <div style={{ flexGrow: 1 }} />
                {!hideThemeNestedMenu &&
                    Array.isArray(menuItems) &&
                    menuItems.length > 0 && (
                        <ThemeNestedMenu
                            menuItems={menuItems}
                            buttonClass={classes.additionalMenuButton}
                            buttonIcon={<MenuIcon className={classes.menuIcon} />}
                        />
                    )}
            </Grid>
            {isLoadingDetails && <ThemeLoader />}
            {isDetailDataEmpty &&
                !atLeastOneCityExist &&
                !atLeastOnePostalcodeExist &&
                !atLeastOnePostalcodeExist &&
                !atLeastOneRiskLocationExist &&
                !atLeastOneValueInUHVUSVTable &&
                !isLoadingDetails && (
                    <ListItem>
                        <ListItemText data-title>
                            {t('contract-details-view:noContractDetails')}
                        </ListItemText>
                    </ListItem>
                )}
            {!isLoadingDetails && detailsData && (
                <>
                    <List className={contractDetailsViewDataClasses.list}>
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.riskLocation'
                            value={detailsData.riskLocation}
                            hidden={!detailsData.riskLocation}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.street'
                            value={detailsData?.street}
                            hidden={!detailsData?.street}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.postCodeTown'
                            value={`${detailsData.postalCode} / ${detailsData.city}`}
                            hidden={!detailsData?.postalCode}
                        />
                        {(detailsData?.salutation ||
                            detailsData?.firstname ||
                            detailsData?.surname) && (
                            <ListItem className={bhvClasses.subtitle}>
                                <ListItemText>
                                    {t(
                                        'contract-details-view:contractDetails.insuredPerson'
                                    )}
                                </ListItemText>
                            </ListItem>
                        )}
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.salutation'
                            value={detailsData?.salutation}
                            hidden={!detailsData?.salutation}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.firstName'
                            value={detailsData?.firstname}
                            hidden={!detailsData?.firstname}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.surname'
                            value={detailsData?.surname}
                            hidden={!detailsData?.surname}
                        />
                        {(detailsData?.insuredModules ||
                            detailsData?.insuredModulesText ||
                            detailsData?.extendedProdHV) && (
                            <ListItem className={bhvClasses.subtitle}>
                                <ListItemText>
                                    {t('contract-details-view:contractDetails.prodMV')}
                                </ListItemText>
                            </ListItem>
                        )}
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.insuredBuildingBlocks'
                            value={detailsData?.insuredModules}
                            hidden={!detailsData?.insuredModules}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.insuredBuildingBlocksText'
                            value={detailsData?.insuredModulesText}
                            hidden={!detailsData?.insuredModulesText}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.advancedProdHV'
                            value={detailsData?.extendedProdHV}
                            hidden={!detailsData?.extendedProdHV}
                        />
                    </List>
                    <List className={contractDetailsViewDataClasses.list}>
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.advancedProdHV'
                            additionalTitle={{ mark: 1 }}
                            value={detailsData?.extendedProdHV}
                            hidden={!detailsData?.extendedProdHV}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.coverageType'
                            additionalTitle={{ mark: 1 }}
                            value={detailsData.coverageType1}
                            hidden={!detailsData?.coverageType1}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.coverageAmount'
                            additionalTitle={{ mark: 1 }}
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData.amountOfCoverage1,
                            })}
                            hidden={
                                detailsData?.amountOfCoverage1 === null ||
                                detailsData?.amountOfCoverage1 === 0
                            }
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.maximization'
                            additionalTitle={{ mark: 1 }}
                            value={detailsData.maximization1}
                            hidden={!detailsData?.maximization1}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.coverageType'
                            additionalTitle={{ mark: 2 }}
                            value={detailsData.coverageType2}
                            hidden={!detailsData?.coverageType2}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.coverageAmount'
                            additionalTitle={{ mark: 2 }}
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData.amountOfCoverage2,
                            })}
                            hidden={
                                detailsData?.amountOfCoverage2 === null ||
                                detailsData?.amountOfCoverage2 === 0
                            }
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.maximization'
                            additionalTitle={{ mark: 2 }}
                            value={detailsData.maximization2}
                            hidden={!detailsData?.maximization2}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.coverageType'
                            additionalTitle={{ mark: 3 }}
                            value={detailsData.coverageType3}
                            hidden={!detailsData?.coverageType3}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.coverageAmount'
                            additionalTitle={{ mark: 3 }}
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData.amountOfCoverage3,
                            })}
                            hidden={
                                detailsData?.amountOfCoverage3 === null ||
                                detailsData?.amountOfCoverage3 === 0
                            }
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.maximization'
                            additionalTitle={{ mark: 3 }}
                            value={detailsData.maximization3}
                            hidden={!detailsData?.maximization3}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.coverageType'
                            additionalTitle={{ mark: 4 }}
                            value={detailsData.coverageType4}
                            hidden={!detailsData?.coverageType4}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.coverageAmount'
                            additionalTitle={{ mark: 4 }}
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData.amountOfCoverage4,
                            })}
                            hidden={
                                detailsData?.amountOfCoverage4 === null ||
                                detailsData?.amountOfCoverage4 === 0
                            }
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.maximization'
                            additionalTitle={{ mark: 4 }}
                            value={detailsData.maximization4}
                            hidden={!detailsData?.maximization4}
                        />
                    </List>
                    <List className={contractDetailsViewDataClasses.list}>
                        {Array.isArray(detailsData?.fuelDatas) &&
                            atLeastOneValueInUHVUSVTable &&
                            detailsData.fuelDatas.length > 0 && (
                                <table className={bhvClasses.table}>
                                    <thead className={bhvClasses.tableHeader}>
                                        {t(
                                            'contract-details-view:contractDetails.uhvUsv'
                                        )}
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>
                                                {t(
                                                    'contract-details-view:contractDetails.type'
                                                )}
                                            </th>
                                            <th>
                                                {t(
                                                    'contract-details-view:contractDetails.number'
                                                )}
                                            </th>
                                            <th>
                                                {t(
                                                    'contract-details-view:contractDetails.layerQuantity'
                                                )}
                                            </th>
                                        </tr>
                                        {detailsData?.fuelDatas
                                            ?.filter((item) => {
                                                return item.layerQuantity || item.number;
                                            })
                                            .map((item) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td
                                                            className={
                                                                bhvClasses.tableCell
                                                            }
                                                        >
                                                            {item.type &&
                                                                t(
                                                                    `contract-details-view:contractDetails.${item.type}`
                                                                )}
                                                        </td>
                                                        <td
                                                            className={
                                                                bhvClasses.tableCell
                                                            }
                                                        >
                                                            {item.number}
                                                        </td>
                                                        <td
                                                            className={
                                                                bhvClasses.tableCell
                                                            }
                                                        >
                                                            {item.layerQuantity}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            )}
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.approvalRequiredInvestments'
                            value={detailsData.systemsSubjectToApproval}
                            hidden={!detailsData.systemsSubjectToApproval}
                        />
                        {Array.isArray(detailsData?.riskLocations) &&
                            (atLeastOneRiskLocationExist ||
                                atLeastOneStreetExist ||
                                atLeastOnePostalcodeExist ||
                                atLeastOneCityExist) &&
                            detailsData.riskLocations.length > 0 && (
                                <table className={bhvClasses.table}>
                                    <tbody>
                                        <tr>
                                            {atLeastOneRiskLocationExist && (
                                                <th>
                                                    {t(
                                                        'contract-details-view:contractDetails.riskLocations'
                                                    )}
                                                </th>
                                            )}
                                            {atLeastOnePostalcodeExist && (
                                                <th>
                                                    {t(
                                                        'contract-details-view:contractDetails.postCode'
                                                    )}
                                                </th>
                                            )}
                                            {atLeastOneCityExist && (
                                                <th>
                                                    {t(
                                                        'contract-details-view:contractDetails.place'
                                                    )}
                                                </th>
                                            )}
                                            {atLeastOneStreetExist && (
                                                <th>
                                                    {t(
                                                        'contract-details-view:contractDetails.street'
                                                    )}
                                                </th>
                                            )}
                                        </tr>
                                        {detailsData?.riskLocations?.map((item) => {
                                            return (
                                                <tr key={item.id}>
                                                    {atLeastOneRiskLocationExist && (
                                                        <td
                                                            className={
                                                                bhvClasses.tableCell
                                                            }
                                                        >
                                                            {item.riskLocation}
                                                        </td>
                                                    )}
                                                    {atLeastOnePostalcodeExist && (
                                                        <td
                                                            className={
                                                                bhvClasses.tableCell
                                                            }
                                                        >
                                                            {item.postalCode}
                                                        </td>
                                                    )}
                                                    {atLeastOneCityExist && (
                                                        <td
                                                            className={
                                                                bhvClasses.tableCell
                                                            }
                                                        >
                                                            {item.city}
                                                        </td>
                                                    )}
                                                    {atLeastOneStreetExist && (
                                                        <td
                                                            className={
                                                                bhvClasses.tableCell
                                                            }
                                                        >
                                                            {item.street}
                                                        </td>
                                                    )}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            )}
                        {(typeof detailsData?.carRecall === 'boolean' ||
                            typeof detailsData?.selfRecall === 'boolean' ||
                            typeof detailsData?.thirdPartyRecall === 'boolean' ||
                            detailsData?.productDescription) && (
                            <ListItem className={bhvClasses.subtitle}>
                                <ListItemText>
                                    {t('contract-details-view:contractDetails.rrkv')}
                                </ListItemText>
                            </ListItem>
                        )}
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.carRecall'
                            value={
                                detailsData?.carRecall ? t('common:yes') : t('common:no')
                            }
                            hidden={!detailsData?.carRecall}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.selfRecall'
                            value={
                                detailsData?.selfRecall ? t('common:yes') : t('common:no')
                            }
                            hidden={!detailsData?.selfRecall}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.thirdPartyRecall'
                            value={
                                detailsData?.thirdPartyRecall
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            hidden={!detailsData?.thirdPartyRecall}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.productDescription'
                            value={detailsData.productDescription}
                            hidden={!detailsData?.productDescription}
                        />
                    </List>
                </>
            )}
        </Grid>
    );
};
