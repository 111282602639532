import React, { ChangeEvent, useState, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';

import {
    useMediaQuery,
    Typography,
    Grid,
    FormControl,
    OutlinedInput,
    FormControlLabel,
    Button,
} from '@material-ui/core';

import { ThemeModalWindow, ThemeCircularProgress } from 'src/theming';

import {
    useStyle,
    useFormControlLabelStyles,
    useFormControlStyles,
    useInputStyles,
} from './non-users-damage-report-form-styles';

import { passwordSchema } from './non-users-damage-report-form-password-validation-schema';

interface NonUsersDamageReportPasswordFormProps {
    setLocked: (value: boolean) => void;
    setToken: (value: string) => void;
    makeRequestForToken: (password: string) => Promise<{ confirmationToken: string }>;
    id: string;
}

export const NonUsersDamageReportPasswordForm = (
    props: NonUsersDamageReportPasswordFormProps
): JSX.Element => {
    const [isOpen, setOpen] = useState(true);
    const classes = useStyle();
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();
    const inputClasses = useInputStyles();

    const { enqueueSnackbar } = useSnackbar();

    const { setLocked, setToken, makeRequestForToken } = props;

    const { t } = useTranslation(['non-users-damage-report-form', 'common', 'errors']);

    const isSmallModalWindow = useMediaQuery('(max-width:600px)');

    const [isConfirmLoading, setConfirmLoading] = useState(false);

    const { formState, watch, setValue, trigger } = useForm<{ password: string }>({
        mode: 'onChange',
        resolver: yupResolver(passwordSchema),
    });

    const password = watch('password');

    const onClose = (): void => {
        setOpen(false);
    };

    const onSubmit = (): void => {
        setConfirmLoading(true);
        makeRequestForToken(password)
            .then((data: { confirmationToken: string }) => {
                setLocked(false);
                setToken(data?.confirmationToken);
            })
            .catch((response) => {
                response?.error?.response?.data?.errors?.password.forEach((error) => {
                    enqueueSnackbar(t(`non-users-damage-report-form:${error}`), {
                        variant: 'error',
                    });
                });
            })
            .finally(() => {
                setConfirmLoading(false);
            });
    };

    const handleChangePassword = (e: ChangeEvent<HTMLInputElement>): void => {
        setValue('password', e.target.value);
        trigger();
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === 'Enter') {
            onSubmit();
        }
    };

    return (
        <ThemeModalWindow
            width={isSmallModalWindow ? 300 : 400}
            isOpen={isOpen}
            onClose={onClose}
        >
            <Typography variant='h5'>{t('enterPassword')}</Typography>
            <div>
                <FormControl fullWidth classes={formControlClasses} variant='outlined'>
                    <FormControlLabel
                        classes={formControlLabelClasses}
                        label={t('passwordLabel')}
                        labelPlacement='top'
                        control={
                            <OutlinedInput
                                placeholder={t('passwordPlaceholder')}
                                type='password'
                                classes={inputClasses}
                                value={password}
                                onChange={handleChangePassword}
                                onKeyPress={handleKeyPress}
                            />
                        }
                    />
                </FormControl>
            </div>
            <Grid container className={classes.modalActionsContainer}>
                <Button
                    disableElevation
                    variant='contained'
                    className={clsx(classes.modalButton, classes.saveButton)}
                    disabled={!formState.isValid || isConfirmLoading}
                    onClick={onSubmit}
                    startIcon={<ThemeCircularProgress isLoading={isConfirmLoading} />}
                >
                    {t('toConfirm')}
                </Button>
                <Button
                    variant='outlined'
                    disabled={isConfirmLoading}
                    className={clsx(classes.modalButton, classes.cancelButton)}
                    onClick={onClose}
                >
                    {t('cancel')}
                </Button>
            </Grid>
        </ThemeModalWindow>
    );
};
