import React from 'react';
import { INTERNAL_ROLES, EXTERNAL_ROLES, CUSTOMER_ROLES } from 'src/shared/constants';

import { InternalUserIcon } from './user-management-section-icons/internal-user-icon';
import { ExternalUserIcon } from './user-management-section-icons/external-user-icon';
import { CustomerUserIcon } from './user-management-section-icons/customer-user-icon';

export const userManagementSectionGetUserIcon = (
    roles: string[],
    isActive: boolean
): JSX.Element => {
    if (Object.values(INTERNAL_ROLES).includes(roles[0] as INTERNAL_ROLES)) {
        return <InternalUserIcon isActive={isActive} />;
    }
    if (Object.values(EXTERNAL_ROLES).includes(roles[0] as EXTERNAL_ROLES)) {
        return <ExternalUserIcon isActive={isActive} />;
    }
    if (Object.values(CUSTOMER_ROLES).includes(roles[0] as CUSTOMER_ROLES)) {
        return <CustomerUserIcon isActive={isActive} />;
    }
};
