export enum LIGHT_THEME_COLORS {
    PRIMARY_MAIN_COLOR = '#F9F9FB',
    PRIMARY_LIGHT_COLOR = '#FFFFFF',
    PRIMARY_CONTRAST_TEXT = '#3B4751',
    SECONDARY_MAIN_COLOR = '#112A3E',
    SECONDARY_LIGHT_COLOR = 'rgba(17, 42, 62, 0.94)',
    SECONDARY_CONTRAST_TEXT = '#FFFFFF',
    INFO_MAIN_COLOR = '#2196F3',
    INFO_LIGHT_COLOR = 'rgba(33, 150, 243, 0.1)',
    INFO_CONTRAST_TEXT = '#FFFFFF',
    ERROR_MAIN_COLOR = '#CB362D',
    ERROR_LIGHT_COLOR = 'rgba(203, 54, 45, 0.1)',
}

export enum DARK_THEME_COLORS {
    PRIMARY_MAIN_COLOR = '#112A3E',
    PRIMARY_LIGHT_COLOR = '#1C3E59',
    PRIMARY_CONTRAST_TEXT = '#FFFFFF',
    SECONDARY_MAIN_COLOR = '#0A1B29',
    SECONDARY_LIGHT_COLOR = 'rgba(12, 63, 102, 0.9)',
    SECONDARY_CONTRAST_TEXT = '#FFFFFF',
    INFO_MAIN_COLOR = '#1F7FCB',
    INFO_LIGHT_COLOR = 'rgba(33, 150, 243, 0.1)',
    INFO_CONTRAST_TEXT = '#FFFFFF',
    ERROR_MAIN_COLOR = '#CB362D',
    ERROR_LIGHT_COLOR = 'rgba(203, 54, 45, 0.1)',
}
