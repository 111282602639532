import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

interface UseUpdateMessageReturn {
    isLoading: boolean;
    updateMessage: (id: string, status: string) => Promise<void>;
}

export const useUpdateMessage = (onSuccess: () => void): UseUpdateMessageReturn => {
    const httpClient = useHttpClient();
    const { t } = useTranslation(['errors']);
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState<boolean>(false);

    const updateMessage = async (id: string, status: string): Promise<void> => {
        try {
            setLoading(true);
            await httpClient.put(`notifications/${id}`, { status });
            onSuccess();
        } catch {
            enqueueSnackbar(t('errors:unknownError'), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return {
        isLoading,
        updateMessage,
    };
};
