import * as yup from 'yup';

const checkIfThereIsValueInSelector = (value: string): boolean => {
    return value !== 'No choice';
};

export const damageReportFormTRANSPSchema = yup.object().shape({
    division: yup.string().required().nullable().required(),
    dayOfDamage: yup.string().nullable().required(),
    contractNumber: yup.string().nullable().required(),
    policyHolder: yup.string().nullable().required(),
    insuranceCompany: yup.string().nullable().required(),
    ariaOfLiability: yup
        .string()
        .test(checkIfThereIsValueInSelector)
        .nullable()
        .required(),
    typeOfDamage: yup.string().test(checkIfThereIsValueInSelector).nullable().required(),
    doesDriverHaveNumberOfPiecesAtTakeoverChecked: yup.bool().nullable().required(),
    doesDriverHaveNumberOfPiecesAtTakeoverAcknowledged: yup.bool().nullable().required(),
    hasItBeenReloaded: yup.bool().nullable().required(),
    whichGoodsAreMissingOrDamaged: yup.string().nullable().required(),
    grantedRecipientReceipt: yup.bool().nullable().required(),
    textofReservation: yup.string().when('grantedRecipientReceipt', {
        is: (val: string) => !val,
        then: () => {
            return yup.string().required().nullable();
        },
        otherwise: () => yup.string().nullable(),
    }),
    whereWasReservationMade: yup
        .string()
        .test(checkIfThereIsValueInSelector)
        .nullable()
        .required(),
    damageAmountReducedByFreight: yup.bool().nullable().required(),
    detailedDamageDescription: yup.string().when('damageAmountReducedByFreight', {
        is: (val: string) => val,
        then: () => {
            return yup.string().required().nullable();
        },
        otherwise: () => yup.string().nullable(),
    }),
    isRecordedByThePolice: yup.bool().nullable().required(),
});
