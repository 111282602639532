import { ContractsDetailsViewDamagesResponseBody } from './contracts-details-view-damages-response-body';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { APP_ROUTES } from 'src/routing';

interface ContractDetailsViewDamagesForState {
    id: string;
    risk: string;
    damageNumber: string;
    damageNumberE: string;
    damageNumberI: string;
    dayOfDamage: ParsableDate;
    damageHeight: number;
    reserve: number;
    paymentInEuro: number;
    status: string;
    deductible: string;
    assignment: string;
    caused: string;
}

export const contractDetailsViewDamagesAdapter = (
    data: ContractsDetailsViewDamagesResponseBody[]
): ContractDetailsViewDamagesForState[] => {
    return data?.map((item, index) => ({
        id: String(index),
        risk: item.risk,
        damageNumber: item.damageNumber,
        damageNumberE: item.damageNumberE,
        damageNumberI: item.damageNumberI,
        dayOfDamage: item.dayOfDamage,
        damageHeight: item.damageHeight,
        reserve: item.reserve,
        paymentInEuro: item.paymentInEuro,
        status: item.status
            ? `damage-details-view:shadenstatus.${item.status.toLowerCase().trim()}`
            : '',
        deductible: item.deductible,
        assignment: item.assignment ? `assignments:${item.assignment}` : '',
        caused: item.caused,
        links: {
            damageNumberE: `${APP_ROUTES.DAMAGES}/${item.amsIdNr}`,
        },
    }));
};
