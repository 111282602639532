import moment from 'moment';

import { SelectItem } from 'src/theming';

export const generateYears = (): SelectItem[] => {
    const currentYear = moment().year();
    return [
        {
            title: String(currentYear - 2),
            value: String(currentYear - 2),
        },
        {
            title: String(currentYear - 1),
            value: String(currentYear - 1),
        },
        {
            title: String(currentYear),
            value: String(currentYear),
        },
    ];
};
