import { makeStyles, Theme } from '@material-ui/core';

export const useStyle = makeStyles((theme: Theme) => {
    return {
        columnValueContainer: {
            padding: 0,
            flexDirection: 'column',
            alignItems: 'inherit',
        },
        columnValue: {
            margin: 0,
        },
        columnSubValueBlock: {
            marginTop: 0,
            marginBottom: 0,
            paddingLeft: theme.spacing(2),
        },
        columnSubValue: {
            fontSize: '1.4rem',
            lineHeight: 1.2,
        },
    };
});
