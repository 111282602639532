import { AbilityActions, AbilitySubjects } from '../constants';

export function administratorRoleAccessGenerator(
    can: (actions: string | string[], subjects: string | string[]) => void
): void {
    can(AbilityActions.establish, AbilitySubjects.socketConnection);
    can(AbilityActions.use, AbilitySubjects.notificationCenter);

    can(
        [
            AbilityActions.create,
            AbilityActions.edit,
            AbilityActions.delete,
            AbilityActions.change,
        ],
        [AbilitySubjects.clerk, AbilitySubjects.clerkWithExternDamageReport]
    );
    can([AbilityActions.edit, AbilityActions.change], AbilitySubjects.admin);
    can([AbilityActions.create, AbilityActions.edit], AbilitySubjects.customerRoleGroup);
    can(AbilityActions.edit, AbilitySubjects.userManagementCardsWithBranches);

    can(AbilityActions.see, [
        AbilitySubjects.notificationNewMessageCustomerAdviserForm,
        AbilitySubjects.userManagement,
    ]);
}
