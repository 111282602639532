import { useState, useEffect } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

export interface DocumentFormatsDto {
    availableFormats: string[];
}

export const useDocumentFormats = (): string[] => {
    const [mount, setMount] = useState(false);
    const httpClient = useHttpClient();
    const [availableDocumentFormats, setAvailableDocumentFormats] = useState<string[]>();

    useEffect(() => {
        setMount(true);
    }, []);

    useEffect(() => {
        if (mount && !availableDocumentFormats) {
            httpClient
                .get('documents/available-formats')
                .then((data: DocumentFormatsDto) => {
                    setAvailableDocumentFormats(
                        data.availableFormats.map((item) => `.${item}`)
                    );
                });
        }
    }, [mount]);

    return availableDocumentFormats;
};
