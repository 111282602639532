export enum AppInstances {
    AON = 'AON',
    AON_KOMPOSIT = 'AON_Komposit',
    SGIFFOXXK = 'SGIFFOXXK',
    HECTOR = 'HECTOR',
    HECTOR_DEMO = 'HECTOR_DEMO',
    AON_MOTOR = 'AON_MOTOR',
    AON_DIGITAL = 'AON_DIGITAL',
    CEAT = 'CEAT',
}
