import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { IconButton, TablePagination, Typography, useTheme } from '@material-ui/core';

import {
    KeyboardArrowDown,
    KeyboardArrowLeft,
    KeyboardArrowRight,
} from '@material-ui/icons';

import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';

import { ThemeCircularProgress } from 'src/theming';

import {
    usePaginationStyle,
    useStyle,
    useRowsPerPageMenuStyle,
} from './theme-pagination-styles';

interface InfiniteProps {
    currentRowsCount: number;
}
interface CustomTablePaginationActionsProps {
    onChangePage: (event: SyntheticEvent, page: number) => void;
    page: number;
    count: number;
    rowsPerPage: number;
    withoutMinMaxButtons?: boolean;
    infinite?: InfiniteProps;
}

const CustomTablePaginationActions = (
    props: CustomTablePaginationActionsProps
): JSX.Element => {
    const classes = usePaginationStyle();
    const theme = useTheme();
    const { onChangePage, page, count, rowsPerPage, withoutMinMaxButtons, infinite } =
        props;

    const handleFirstPageButtonClick = (event: SyntheticEvent): void => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event: SyntheticEvent): void => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event: SyntheticEvent): void => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event: SyntheticEvent): void => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    const isNextButtonEnabled = infinite
        ? infinite.currentRowsCount > rowsPerPage
        : rowsPerPage * (page + 1) < count;

    return (
        <div className={classes.root}>
            {!withoutMinMaxButtons && !infinite && (
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label='first page'
                    disableRipple
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
            )}
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label='previous page'
                disableRipple
            >
                {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={!isNextButtonEnabled}
                aria-label='next page'
                disableRipple
            >
                {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            {!withoutMinMaxButtons && !infinite && (
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label='last page'
                    disableRipple
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            )}
        </div>
    );
};

interface ThemePaginationProps {
    count: number;
    page: number;
    infinite?: InfiniteProps;
    rowsPerPage: number;
    onChangePage: (event: SyntheticEvent, page: number) => void;
    rowsPerPageOptions: number[];
    handleChangeRowsPerPage: React.ChangeEventHandler<
        HTMLTextAreaElement | HTMLInputElement
    >;
    withoutMinMaxButtons?: boolean;
    paginationClassName?: string;
    amountRecordsToShow?: number;
    amountRecordsToShowLoading?: boolean;
}

export const ThemePagination = (props: ThemePaginationProps): JSX.Element => {
    const classes = useStyle();
    const rowsPerPageMenuClasses = useRowsPerPageMenuStyle();
    const { t } = useTranslation(['common']);
    const {
        count,
        page,
        rowsPerPage,
        onChangePage,
        rowsPerPageOptions,
        handleChangeRowsPerPage,
        withoutMinMaxButtons,
        paginationClassName,
        infinite,
        amountRecordsToShow,
        amountRecordsToShowLoading,
    } = props;

    return (
        <TablePagination
            className={clsx(classes.pagination, paginationClassName)}
            rowsPerPageOptions={rowsPerPageOptions}
            rowsPerPage={rowsPerPage}
            count={count}
            page={page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={`${t('pagination')}`}
            labelDisplayedRows={infinite ? () => <React.Fragment /> : undefined}
            ActionsComponent={(props) => (
                <>
                    {Number.isInteger(amountRecordsToShow) &&
                        !amountRecordsToShowLoading && (
                            <Typography variant='body2'>
                                {t('generalRecordsAmount', {
                                    count: amountRecordsToShow,
                                })}
                            </Typography>
                        )}
                    <ThemeCircularProgress isLoading={amountRecordsToShowLoading} />
                    <CustomTablePaginationActions
                        {...props}
                        withoutMinMaxButtons={withoutMinMaxButtons}
                        infinite={infinite}
                    />
                </>
            )}
            component='div'
            SelectProps={{
                IconComponent: (innerProps) => (
                    <KeyboardArrowDown {...innerProps} fontSize='small' />
                ),
                MenuProps: {
                    disableScrollLock: true,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    classes: rowsPerPageMenuClasses,
                },
            }}
        />
    );
};
