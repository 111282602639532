import { TFunction } from 'i18next';
import { SelectItem, CheckboxItem } from 'src/theming';

export const reportCenterGetDivisionsFromGlobalFilter = (
    customerConnections: CheckboxItem[],
    t: TFunction
): SelectItem[] => {
    const setOfDivisions = new Set();
    const areAllCCChecked = customerConnections.find((cc) => cc.value === 'all')?.checked;
    customerConnections.forEach((cc) => {
        cc.data?.divisions?.forEach((division) => {
            if (areAllCCChecked || cc.checked) {
                setOfDivisions.add(division.division);
            }
        });
    });
    return [
        {
            title: t('common:all'),
            value: 'all',
        },
        ...Array.from(setOfDivisions)
            .map((division: string) => ({
                title: t(`divisions:${division}`),
                value: division,
            }))
            .sort((a, b) => (a.value > b.value ? 1 : -1)),
    ];
};
