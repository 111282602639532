import { makeStyles, Theme } from '@material-ui/core/styles';
import { THEME_MODES } from 'src/theming';
import { LIGHT_HECTOR_THEME_COLORS } from '../themes/hector/hector-theme-colors';
import {
    isHectorInstance,
    isSGIFFOXXKInstance,
    isAonDigitalInstance,
    isAonMotorInstance,
    isCeatInstance,
    isHectorDemoInstance,
} from 'src/environment';
import { LIGHT_CEAT_THEME_COLORS } from '../themes/ceat';

export const useToggleButtonsGroupStyle = makeStyles((theme: Theme) => {
    const isLightMode = theme.palette.type === THEME_MODES.LIGHT;
    if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
        return {
            root: {
                backgroundColor: isLightMode
                    ? theme.palette.primary.light
                    : 'rgba(0, 0, 0, 0)',
            },
        };
    }
    if (isSGIFFOXXKInstance) {
        return {
            root: {
                backgroundColor: isLightMode
                    ? theme.palette.primary.light
                    : 'rgba(0, 0, 0, 0)',
            },
        };
    }
    if (isHectorInstance || isCeatInstance) {
        return {
            root: {
                backgroundColor: isLightMode
                    ? theme.palette.secondary.light
                    : 'rgba(0, 0, 0, 0)',
            },
        };
    }
    return {
        root: {
            backgroundColor: theme.palette.info.light,
        },
    };
});

export const useToggleButtonStyle = makeStyles((theme: Theme) => {
    const isLightMode = theme.palette.type === THEME_MODES.LIGHT;
    if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
        return {
            root: {
                height: 50,
                padding: `0 ${theme.spacing(3)}px`,
                borderRadius: 0,
                borderColor: `${
                    !isLightMode
                        ? theme.palette.secondary.contrastText
                        : theme.palette.secondary.main
                }!important`,
                color: !isLightMode
                    ? theme.palette.secondary.contrastText
                    : theme.palette.primary.contrastText,
                fontSize: '1.8rem',
                textTransform: 'none',

                [theme.breakpoints.down('md')]: {
                    height: 40,
                    fontSize: '1.6rem',
                },
            },
            selected: {
                backgroundColor: isLightMode
                    ? `${theme.palette.secondary.light}!important`
                    : `${theme.palette.secondary.contrastText}!important`,
                color: `${theme.palette.error.main}!important`,
                '& span:first-child': {
                    borderBottom: `1px solid ${theme.palette.error.main}`,
                },
            },
        };
    }
    if (isSGIFFOXXKInstance) {
        return {
            root: {
                height: 50,
                padding: `0 ${theme.spacing(3)}px`,
                borderRadius: 0,
                borderColor: !isLightMode
                    ? theme.palette.secondary.contrastText
                    : theme.palette.secondary.main,
                color: !isLightMode
                    ? theme.palette.secondary.contrastText
                    : theme.palette.secondary.main,
                fontSize: '1.8rem',
                fontWeight: theme.typography.fontWeightBold,
                textTransform: 'none',
                '&:first-child': {
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5,
                },
                '&:last-child': {
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                },
                [theme.breakpoints.down('md')]: {
                    height: 40,
                    fontSize: '1.6rem',
                },
            },
            selected: {
                backgroundColor: isLightMode
                    ? `${theme.palette.secondary.main}!important`
                    : `${theme.palette.secondary.contrastText}!important`,
                color: isLightMode
                    ? `${theme.palette.secondary.contrastText}!important`
                    : `${theme.palette.secondary.main}!important`,
            },
        };
    }
    if (isHectorInstance || isCeatInstance) {
        return {
            root: {
                height: 50,
                padding: `0 ${theme.spacing(3)}px`,
                borderRadius: 0,
                borderColor: !isLightMode
                    ? theme.palette.secondary.contrastText
                    : theme.palette.secondary.main,
                color:
                    theme.palette.type === THEME_MODES.DARK
                        ? theme.palette.secondary.contrastText
                        : theme.palette.secondary.main,
                fontSize: '1.8rem',
                textTransform: 'none',
                [theme.breakpoints.down('md')]: {
                    height: 40,
                    fontSize: '1.6rem',
                },
            },
            selected: {
                backgroundColor: isLightMode
                    ? `${theme.palette.secondary.main}!important`
                    : `${theme.palette.secondary.contrastText}!important`,
                color: isLightMode
                    ? `${theme.palette.secondary.contrastText}!important`
                    : `${
                          isHectorInstance
                              ? LIGHT_HECTOR_THEME_COLORS.SECONDARY_MAIN_COLOR
                              : LIGHT_CEAT_THEME_COLORS.SECONDARY_MAIN_COLOR
                      }!important`,
            },
        };
    }
});
