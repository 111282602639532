import { makeStyles, Theme } from '@material-ui/core';

export const useContractsCreateContractsFormStyles = makeStyles((theme: Theme) => {
    return {
        pageContainer: {
            height: '100%',
            alignContent: 'flex-start',
            padding: `${theme.spacing(4)}px ${theme.spacing(1)}rem`,
            backgroundColor: theme.palette.primary.main,
            [theme.breakpoints.down('md')]: {
                padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
            },
        },
        fieldsContainer: {
            width: '80%',
            backgroundColor: theme.palette.primary.light,
            marginTop: theme.spacing(4),
            padding: theme.spacing(4),
        },
        actionButton: {
            height: 40,
            width: 40,
            borderRadius: 4,
        },
        addButton: {
            backgroundColor: theme.palette.info.light,
            '& .MuiSvgIcon-root': {
                color: theme.palette.info.main,
            },
        },
        deleteButton: {
            backgroundColor: theme.palette.error.light,
            '& .MuiSvgIcon-root': {
                color: theme.palette.error.main,
            },
        },
        dateField: {
            '& .MuiInputBase-root': {
                height: 40,
            },
        },
        addFieldPreviewText: {
            paddingLeft: theme.spacing(4),
            paddingBottom: theme.spacing(1),
        },
        actionsContainer: {
            paddingTop: `${theme.spacing(4)}px!important`,
            gap: theme.spacing(2),
        },
        saveButton: {
            backgroundColor: theme.palette.info.light,
            color: theme.palette.info.main,
        },
    };
});

export const useFormControlStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            '& label': {
                fontSize: '2.4rem',
                backgroundColor: `${theme.palette.primary.light}!important`,
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.info.main,
            },
        },
    };
});

export const useFormControlLabelStyles = makeStyles(() => {
    return {
        root: {
            display: 'flex!important',
            alignItems: 'flex-start',
            margin: 0,
            padding: `0!important`,
            '&>div': {
                width: '100%',
                '& .MuiFormControl-root': {
                    width: '100%',
                },
            },
        },
        label: {
            fontSize: '1.4rem',
            opacity: 0.6,
        },
    };
});

export const useInputStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            width: '100%',
            height: 40,
            backgroundColor: theme.palette.primary.light,
        },
        focused: {
            '& fieldSet': {
                borderColor: `${theme.palette.info.main}!important`,
            },
        },
        input: {
            '&:-internal-autofill-selected': {
                boxShadow: `inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px ${theme.palette.primary.main}`,
            },
        },
    };
});
