import React, {
    useCallback,
    useMemo,
    useEffect,
    useState,
    SetStateAction,
    Dispatch,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { GridSortModel, GridSortModelParams } from '@material-ui/data-grid';
import { useParams } from 'react-router';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import moment from 'moment';
import qs from 'querystring';

import { Grid, Paper, Typography } from '@material-ui/core';

import { ThemeTable } from 'src/theming';

import { useDebounce } from 'src/lib/custom-hooks/use-debounce';

import {
    useStyle,
    usePaperStyle,
} from './damages-details-view-style/damages-details-view-style';

import { generateDamagesDetailsTableHeader } from './damages-details-view-config';

import { DamageDetailsViewDocumentsDto } from './damage-details-view-documents-dto';

type DamagesDetailsViewDocumentsFormData = {
    title: string;
    date: ParsableDate;
    sortModel: GridSortModel;
};

interface Props {
    needToRefetch: boolean;
    setNeedToRefetch: Dispatch<SetStateAction<boolean>>;
}

export const DamagesDetailsViewDocuments = ({
    needToRefetch,
    setNeedToRefetch,
}: Props): JSX.Element => {
    const classes = useStyle();
    const paperClasses = usePaperStyle();
    const { t } = useTranslation('damage-details-view');
    const httpClient = useHttpClient();

    const { id } = useParams<{ id: string }>();

    const [documents, setDocuments] = useState<DamageDetailsViewDocumentsDto[]>([]);
    const [isDocumentsLoading, setDocumentsLoading] = useState(false);

    const { watch, setValue } = useForm<DamagesDetailsViewDocumentsFormData>({
        mode: 'onChange',
    });

    const handleChangeValue = useCallback(
        (e) => {
            setValue(e.target.name, e.target.value);
            setNeedToRefetch(true);
        },
        [setValue]
    );

    const handleChangeDate = useCallback(
        (name: 'date', value: ParsableDate) => {
            setValue(name, value);
            setNeedToRefetch(true);
        },
        [setValue]
    );

    const handleChangeSort = useCallback(
        (sortOrderParams: GridSortModelParams): void => {
            setValue('sortModel', sortOrderParams.sortModel);
            setNeedToRefetch(true);
        },
        [setValue]
    );

    const [title, date, sortModel] = watch(['title', 'date', 'sortModel']);

    const debouncedTitle = useDebounce(title);
    const debouncedDate = useDebounce(date);

    const makeRequestForDamageDocuments = (
        debouncedDate: ParsableDate,
        debouncedTitle: string,
        sortModel: GridSortModel
    ): Promise<DamageDetailsViewDocumentsDto[]> => {
        setDocumentsLoading(true);
        const queryParams: {
            titleSearchTerm?: string;
            searchDate?: string;
            sortField?: string;
            isAscendingSortOrder?: boolean;
        } = {};
        if (debouncedTitle) {
            queryParams.titleSearchTerm = debouncedTitle;
        }
        if (debouncedDate) {
            queryParams.searchDate = moment(debouncedDate).toISOString();
        }
        if (sortModel && sortModel.length !== 0) {
            queryParams.sortField = sortModel[0].field;
            queryParams.isAscendingSortOrder = sortModel[0].sort === 'asc';
        }
        return httpClient.get<DamageDetailsViewDocumentsDto[]>(
            `damages/${id}/documents?${qs.stringify(queryParams)}`
        );
    };

    useEffect(() => {
        if (needToRefetch) {
            makeRequestForDamageDocuments(debouncedDate, debouncedTitle, sortModel)
                .then((data: DamageDetailsViewDocumentsDto[]) => {
                    setDocuments(data);
                })
                .finally(() => {
                    setDocumentsLoading(false);
                    setNeedToRefetch(false);
                });
        }
    }, [debouncedDate, debouncedTitle, sortModel, needToRefetch]);

    const onRemoveSuccess = useCallback((): void => {
        makeRequestForDamageDocuments(debouncedDate, debouncedTitle, sortModel)
            .then((data: DamageDetailsViewDocumentsDto[]) => {
                setDocuments(data);
            })
            .finally(() => {
                setDocumentsLoading(false);
            });
    }, [debouncedDate, debouncedTitle, sortModel]);

    const columns = useMemo(
        () =>
            generateDamagesDetailsTableHeader(
                t,
                title,
                date,
                handleChangeValue,
                handleChangeDate,
                classes.dateSearchFieldClass,
                (letterId) =>
                    httpClient.download(`download-damages/${id}/attachments/${letterId}`),
                onRemoveSuccess
            ),
        [
            t,
            title,
            date,
            handleChangeValue,
            handleChangeDate,
            classes.dateSearchFieldClass,
        ]
    );

    return (
        <>
            {/*{isDocumentsLoading && <ThemeLoader />}*/}
            <Paper classes={paperClasses}>
                <Grid container className={classes.header}>
                    <Typography variant='h6' className={classes.cardTitle}>
                        {t('damage-details-view:documents.title')}
                    </Typography>
                </Grid>
                <div className={classes.tableContainer}>
                    <ThemeTable
                        isLoading={isDocumentsLoading}
                        dataLoadingMessage={t(
                            'damage-details-view:documents.dataLoading'
                        )}
                        withoutPagination
                        columns={columns}
                        rows={documents}
                        sortModel={sortModel}
                        handleChangeSort={handleChangeSort}
                        getRowId={(item) => item.amsIdNr}
                        noRowsMessage={t('damage-details-view:documents.noDocuments')}
                    />
                </div>
            </Paper>
        </>
    );
};
