import { Divisions } from 'src/shared/constants';

import { DamageReportFormAONKompositDREISETypes } from '../../../damage-report-form-utils/damage-report-form-types/damage-report-form-AON-Komposit-types/damage-report-form-AON-Komposit-dreise-types';

export const damageReportFormAONKompositDREISEDefaultState = (
    contractId: string
): DamageReportFormAONKompositDREISETypes => {
    return {
        contractId,
        id: '',
        division: Divisions.DREISE,
        dayOfDamage: null,
        timeOfDamage: null,
        risk: '',
        contractNumber: '',
        policyHolder: '',
        insuranceCompany: '',
        insuredCompanyFileNumber: '',
        creditInstitute: '',
        iban: '',
        bic: '',
        accounOwner: '',
        nameOfTheInsured: '',
        addressOfTheInsured: '',
        postCodeAndPlaceResidenceInsuredPerson: '',
        damageLocation: '',
        damageAssessmentDayAndTime: '',
        kindOfTrip: 'No choice',
        whereAndHowWasTheLostOrDamagedBaggageStored: '',
        exactDescriptionOfTheCourseOfTheDamage: '',
        witnessesOfTheDamage: '',
        nameAndAddress: '',
        telephoneOrFax: '',
        possiblyPoliceDiaryNumber: '',
        whenWasTheDamageReported: '',
        whoBecameLiableForTheDamageWhenAndHowHeld: '',
        whoHasAlreadyPaidWhatCompensationAndWhen: '',
        whatMeasuresHaveBeenTakenToRegain: '',
        typeOrInsuranceCertificate: '',
        affectedItem: '',
        ownAllOfTheAboveObjects: true,
        includeTheOwner: '',
        files: [],
        fileInfos: [],
    };
};
