import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { GridRowParams } from '@material-ui/data-grid';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { ThemeTable, CheckboxItem, ThemeLoader } from 'src/theming';

import { useDebounce } from 'src/lib/custom-hooks/use-debounce';

import { APP_ROUTES } from 'src/routing';
import { useGlobalFilter } from 'src/shared/contexts';
import { useAuth } from 'src/user-management/context-auth';

import { DamageDto } from './damage-dto';
import { DamagesListTableSettings } from './damages-list-table-settings';

import { damagesListRequestBodyPreparing } from './damages-list-request-body-preparing';
import { generateDamageListTableHeader } from './damage-list-configs';
import { Divisions } from 'src/shared/constants';
import { useDamagesListSettings } from 'src/shared/contexts';

interface DamagesListOpenClosedStatusesTableProps {
    columnsSettingsList: CheckboxItem[];
    filterValue: string;
    setNeedAdditionalColumnsForKFZW: (value: boolean) => void;
    division: string;
    hoverPosition: number;
    startPosition: number;
    isDragAndDropLocked: boolean;
    columnSettingsResponse: DamagesListTableSettings;
}

export const DamagesListOpenClosedStatusesTable = (
    props: DamagesListOpenClosedStatusesTableProps
): JSX.Element => {
    const {
        columnsSettingsList,
        filterValue,
        setNeedAdditionalColumnsForKFZW,
        division,
        hoverPosition,
        startPosition,
        isDragAndDropLocked,
        columnSettingsResponse,
    } = props;

    const {
        page,
        rowsPerPage,
        sortModel,
        vnrValue,
        damageNRValue,
        damageNREstateAgentsValue,
        damageNRVUValue,
        handleChangePage,
        handleChangeRowsPerPage,
        handleChangeSort,
        handleChangeTableSearchField,
    } = useDamagesListSettings();

    const history = useHistory();
    const location =
        useLocation<{ dontResetDamageListContext: boolean; customers?: string[] }>();
    const { t } = useTranslation([
        'common',
        'damages-list',
        'contract-types',
        'assignments',
    ]);
    const httpClient = useHttpClient();

    const { filter, availableDivisions } = useGlobalFilter();
    const { userData } = useAuth();

    const { state } = location;

    const stateCustomers = state?.customers;
    const dontResetDamageListContext = state?.dontResetDamageListContext;

    const [damages, setDamages] = useState<{ result: DamageDto[]; count: number }>(null);
    const [isDamagesLoading, setDamagesLoading] = useState(false);
    const [damagesGeneralAmountData, setDamagesGeneralAmountData] = useState<{
        isLoading: boolean;
        count: number;
    }>({ count: null, isLoading: false });

    const debouncedVNR = useDebounce(vnrValue, 1000);
    const debouncedDamageNR = useDebounce(damageNRValue, 1000);
    const debouncedDamageNREstateAgents = useDebounce(damageNREstateAgentsValue, 1000);
    const debouncedDamageNRVUValue = useDebounce(damageNRVUValue, 1000);

    const handleGoToTheDetailView = useCallback(
        (params: GridRowParams): void => {
            const { id } = params;
            history.push(`${APP_ROUTES.DAMAGES}/${id}`, {
                previousPath: `${APP_ROUTES.DAMAGES}?filterValue=${filterValue}`,
                prevState: {
                    filterValue,
                    dontResetDamageListContext: true,
                },
            });
        },
        [history, filterValue]
    );

    const makeRequestForDamagesList = (): Promise<{
        result: DamageDto[];
        count: number;
    }> => {
        const requestBody = damagesListRequestBodyPreparing(
            userData.userInfo.roles,
            debouncedVNR,
            debouncedDamageNR,
            debouncedDamageNREstateAgents,
            debouncedDamageNRVUValue,
            sortModel,
            filterValue,
            page,
            rowsPerPage,
            division,
            filter.customerConnections,
            filter.customers,
            availableDivisions.map((item) => item.division)
        );
        return httpClient.post<{
            result: DamageDto[];
            count: number;
        }>(`get-damages`, requestBody);
    };

    const makeRequestForDamageGeneralAmount = useCallback(async () => {
        try {
            setDamagesGeneralAmountData({ count: null, isLoading: true });
            const requestBody = damagesListRequestBodyPreparing(
                userData.userInfo.roles,
                debouncedVNR,
                debouncedDamageNR,
                debouncedDamageNREstateAgents,
                debouncedDamageNRVUValue,
                null,
                filterValue,
                null,
                null,
                division,
                filter.customerConnections,
                filter.customers,
                availableDivisions.map((item) => item.division)
            );
            const data = await httpClient.post<{ count: number }>(
                'get-damages/count',
                requestBody
            );
            setDamagesGeneralAmountData({ count: data.count, isLoading: false });
        } catch {
            setDamagesGeneralAmountData({ count: null, isLoading: false });
        }
    }, [
        filterValue,
        debouncedVNR,
        debouncedDamageNR,
        debouncedDamageNREstateAgents,
        debouncedDamageNRVUValue,
        division,
        userData,
        filter.customerConnections,
        filter.customers,
    ]);

    useEffect(() => {
        if (!dontResetDamageListContext) {
            handleChangePage(null, 0);
        }
    }, [division, filterValue, rowsPerPage]);

    useEffect(() => {
        const needAdditionalColumnsForKFZW = division === Divisions.KFZW;
        setNeedAdditionalColumnsForKFZW(needAdditionalColumnsForKFZW);
    }, [division]);

    useEffect(() => {
        if (stateCustomers) {
            delete (state as { customers: string[] }).customers;
        }
    }, [filter.customers, t]);

    useEffect(() => {
        setDamagesLoading(true);
        if (division) {
            makeRequestForDamagesList()
                .then((data: { result: DamageDto[]; count: number }) => {
                    setDamages({
                        result: data.result.map((item) => ({
                            ...item,
                            assignment: item.assignment
                                ? `assignments:${item.assignment}`
                                : '',
                        })),
                        count: data.count,
                    });
                })
                .finally(() => {
                    setDamagesLoading(false);
                });
        }
    }, [
        sortModel,
        filterValue,
        debouncedVNR,
        debouncedDamageNR,
        debouncedDamageNREstateAgents,
        debouncedDamageNRVUValue,
        page,
        rowsPerPage,
        division,
        userData,
        filter.customerConnections,
        filter.customers,
    ]);

    useEffect(() => {
        makeRequestForDamageGeneralAmount();
    }, [makeRequestForDamageGeneralAmount]);

    const columns = useMemo(
        () =>
            generateDamageListTableHeader(
                t,
                vnrValue,
                damageNRValue,
                damageNREstateAgentsValue,
                damageNRVUValue,
                handleChangeTableSearchField,
                columnsSettingsList,
                handleGoToTheDetailView,
                hoverPosition,
                startPosition,
                isDragAndDropLocked
            ).sort((a, b) => {
                const aPosition = columnSettingsResponse.columns.find(
                    (item) => item.name === a.field
                )?.position;
                const bPosition = columnSettingsResponse.columns.find(
                    (item) => item.name === b.field
                )?.position;
                if (typeof aPosition !== 'number' || typeof bPosition !== 'number') {
                    return 1;
                }
                return aPosition > bPosition ? 1 : -1;
            }),
        [
            t,
            vnrValue,
            damageNRValue,
            damageNREstateAgentsValue,
            damageNRVUValue,
            columnsSettingsList,
            handleChangeTableSearchField,
            handleGoToTheDetailView,
            hoverPosition,
            startPosition,
            isDragAndDropLocked,
        ]
    );

    return (
        <>
            {columns.length && (
                <ThemeTable
                    infinite
                    minTableHeight={600}
                    isLoading={isDamagesLoading}
                    dataLoadingMessage={t('damages-list:dataLoading')}
                    columns={columns}
                    rows={damages?.result || []}
                    rowCount={damages?.count || 0}
                    rowsPerPageOptions={[10, 25, 50]}
                    pageSize={rowsPerPage}
                    sortModel={sortModel}
                    handleChangeSort={handleChangeSort}
                    page={page}
                    onChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    getRowId={(r) => r.amsIdNr}
                    noRowsMessage={t('damages-list:noDamages')}
                    amountRecordsToShow={damagesGeneralAmountData.count}
                    amountRecordsToShowLoading={damagesGeneralAmountData.isLoading}
                />
            )}
            {!columns.length && <ThemeLoader />}
        </>
    );
};
