import * as yup from 'yup';

const validationOfArrayOfEmails = yup
    .array()
    .of(
        yup.object().shape({
            name: yup.string().email().required(),
        })
    )
    .required();

export const notificationValidationSchema = yup.object().shape({
    fallbackEmails: validationOfArrayOfEmails,
    furtherReceiversEmails: validationOfArrayOfEmails,
});
