import { FormState } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { NotificationsRowFormData } from './notifications-row';

interface UseNotificationHandleErrors {
    formState: FormState<NotificationsRowFormData>;
}

export const useNotificationHandleErrors = ({
    formState,
}: UseNotificationHandleErrors): (() => void) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation(['user-management']);

    const handleErrors = (): void => {
        const errorKeys = Object.keys(formState.errors);
        errorKeys.forEach((item) => {
            if (
                Array.isArray(formState.errors[item]) &&
                formState.errors[item].length > 0
            ) {
                if (formState.errors[item][0]?.name?.type === 'email') {
                    formState.errors[item][0]?.name?.ref?.querySelector('input')?.focus();
                    enqueueSnackbar(
                        t('user-management:notificationsSection.shouldBeValidEmail'),
                        { variant: 'error' }
                    );
                }
            }
        });
    };

    return handleErrors;
};
