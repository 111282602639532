import * as yup from 'yup';

export const contractsCreateContractFormSchema = yup.object().shape({
    division: yup.string().required().nullable(),
    insuranceCompany: yup.string().when('isNewInsuranceCompany', {
        is: (val) => val,
        then: yup.string().nullable(),
        otherwise: yup.string().required().nullable(),
    }),
    addInsuranceCompany: yup.string().when('isNewInsuranceCompany', {
        is: (val) => !val,
        then: yup.string().nullable(),
        otherwise: yup.string().required().nullable(),
    }),
    contractNumber: yup.string().required().nullable(),
    startOfContract: yup.string().required().nullable(),
    contractExpiration: yup.string().required().nullable(),
    netPremium: yup.string().required().nullable(),
    currency: yup.string().required().nullable(),
    risk: yup.string().required().nullable(),
    isNewInsuranceCompany: yup.bool(),
});
