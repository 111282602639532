import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Button, Box } from '@material-ui/core';

import { ThemeModalWindow } from 'src/theming';

import { useStyle } from '../uset-management-section/user-management-section-styles';

interface Props {
    password: string;
    onClose: () => void;
}

export const UserEditFormSuccessActivationModalWindow = ({
    password,
    onClose,
}: Props): JSX.Element => {
    const { t } = useTranslation(['user-management', 'common']);
    const classes = useStyle();
    return (
        <ThemeModalWindow isOpen={Boolean(password)} onClose={onClose}>
            <></>
            <Box mt={4}>
                <Typography variant='body1'>
                    {t('userActivation.message', { password })}
                </Typography>
            </Box>
            <Box ml='auto' width='fit-content'>
                <Button className={classes.addUserButton} onClick={onClose}>
                    {t('common:ok')}
                </Button>
            </Box>
        </ThemeModalWindow>
    );
};
