import React from 'react';

import { IconProps } from './index';

export const CashIcon = (props: IconProps): JSX.Element => {
    const { fill } = props;
    return (
        <>
            <svg width='40' height='31' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M7.273 0H40v23.25H7.273V0zm16.363 5.813c1.447 0 2.834.612 3.857 1.702 1.023 1.09 1.598 2.568 1.598 4.11 0 1.542-.575 3.02-1.598 4.11-1.023 1.09-2.41 1.703-3.857 1.703-1.446 0-2.834-.613-3.857-1.703-1.023-1.09-1.597-2.568-1.597-4.11 0-1.542.575-3.02 1.597-4.11 1.023-1.09 2.41-1.702 3.857-1.702zm-9.09-1.938a4.008 4.008 0 01-1.066 2.74 3.527 3.527 0 01-2.57 1.135v7.75c.963 0 1.888.408 2.57 1.135a4.008 4.008 0 011.066 2.74h18.181c0-1.028.383-2.013 1.065-2.74a3.527 3.527 0 012.572-1.135V7.75c-.965 0-1.89-.408-2.572-1.135a4.008 4.008 0 01-1.065-2.74H14.546zM0 7.75h3.636v19.375h29.091V31H0V7.75z'
                    fill={fill}
                />
            </svg>
        </>
    );
};
