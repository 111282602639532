import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useTranslation } from 'react-i18next';

import { Typography, Button, makeStyles, Theme } from '@material-ui/core';

import {
    DashboardPdfView,
    DashboardPdfViewProps,
} from 'src/dashboard/dahboard-pdf-view/dashboard-pdf-view';
import { ThemeModalWindow } from 'src/theming';
import { useGlobalFilter, useDashboardContext } from 'src/shared/contexts';
import { useAuth } from 'src/user-management/context-auth';

import {
    isAonMotorInstance,
    isAonDigitalInstance,
    isHectorDemoInstance,
} from 'src/environment';
import { useInIframe } from 'src/shared/hooks';

import { INTERNAL_ROLES } from 'src/shared/constants';
import { DashboardDataResponseType } from 'src/shared/contexts/dashboard-context/dashboard-data-response-type';

const useStyle = makeStyles((theme: Theme) => {
    return {
        okButton: {
            height: 40,
            marginRight: 'auto',
            padding: `0 ${theme.spacing(2)}px`,
            backgroundColor: theme.palette.info.light,
            color: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return theme.palette.secondary.contrastText;
                }
                return theme.palette.info.main;
            },
        },
    };
});

interface Props {
    isOpen: boolean;
    onClose: () => void;
    years: string[];
    dashboardData: DashboardDataResponseType;
    setAvailableToPrint: Dispatch<SetStateAction<boolean>>;
    settings: {
        dashboardWidget: string;
        position: number;
        isShown: boolean;
        className?: string;
    }[];
}

export const ContractsDashboardPrintModalWindow = (props: Props): JSX.Element => {
    const { isOpen, onClose, years, settings, setAvailableToPrint } = props;

    const classes = useStyle();
    const pdfContainerRef = useRef<HTMLDivElement>();

    const { t } = useTranslation(['common', 'contract-types']);
    const httpClient = useHttpClient();
    const { filter, division } = useGlobalFilter();
    const { dashboardData } = useDashboardContext();
    const [iframeAppIsMounted, setIFrameAppIsMounted] = useState<boolean>(false);
    const [parentModalWindowOpened, setParentModalWindowOpened] = useState(false);
    const [propertiesForDashboard, setPropertiesForDashboard] =
        useState<Omit<DashboardPdfViewProps, 'onContentLoaded'>>();
    const iframeRef = useRef<HTMLIFrameElement>();

    const { customerConnections, customers } = filter;

    const inIFrame = useInIframe();

    const {
        userData: {
            userInfo: { roles },
        },
    } = useAuth();

    const allowedClientConnections = useMemo(
        () => customerConnections.filter((item) => item.checked),
        [filter]
    );
    const allowedClients = useMemo(
        () => customers.filter((item) => item.checked),
        [filter]
    );

    const isAllChecked = propertiesForDashboard?.customers.find(
        (item) => item.value === 'all'
    )?.checked;

    const isAllCustomerConnectionsChecked =
        propertiesForDashboard?.customerConnections.find(
            (item) => item.value === 'all'
        )?.checked;

    const hasAccessToPrint = useMemo(() => {
        return !(
            isAllCustomerConnectionsChecked &&
            isAllChecked &&
            roles.includes(INTERNAL_ROLES.ADMIN_TECHNICAL)
        );
    }, [isAllChecked, isAllCustomerConnectionsChecked, JSON.stringify(roles)]);

    const generatePdf = (html: string): void => {
        if (html) {
            httpClient.downloadExcel('dashboard/export-pdf/rawHtml', {
                html,
            });
            setPropertiesForDashboard(null);
        }
    };

    const sendHtmlToParentWindow = (): void => {
        const head = document.getElementsByTagName('head')[0].outerHTML;

        const pdfHTML = `
                    <html>
                        ${head}
                        <body>
                            ${pdfContainerRef.current?.innerHTML}
                        </body>
                    </html>
                `;

        window.parent.postMessage({ type: 'generatePdf', html: pdfHTML }, window.origin);
    };

    const onContentLoaded = (): void => {
        if (!hasAccessToPrint || !pdfContainerRef?.current || !inIFrame) {
            return;
        }

        sendHtmlToParentWindow();
    };

    useEffect(() => {
        if (!inIFrame) {
            const handler = ({
                data: { type, html },
            }: {
                data: { type: string; html: string };
            }): void => {
                if (type === 'generatePdf') {
                    generatePdf(html);
                }
                if (type === 'iframe mounted') {
                    setIFrameAppIsMounted(true);
                    setAvailableToPrint(true);
                }
            };

            window.addEventListener('message', handler, false);

            return () => window.removeEventListener('message', handler);
        }
    }, [inIFrame]);

    useEffect(() => {
        if (inIFrame) {
            const handler = ({
                data: { pdfDashboardProperties, isOpen },
            }: {
                data: { pdfDashboardProperties: DashboardPdfViewProps; isOpen: boolean };
            }): void => {
                setPropertiesForDashboard(pdfDashboardProperties);
                setParentModalWindowOpened(isOpen);
            };

            window.addEventListener('message', handler, false);

            return () => window.removeEventListener('message', handler);
        }
    }, [inIFrame]);

    useEffect(() => {
        if (inIFrame) {
            window.parent.postMessage({ type: 'iframe mounted' }, window.origin);
        }
    }, [inIFrame]);

    useEffect(() => {
        if (iframeRef.current && settings && dashboardData && iframeAppIsMounted) {
            const properiesForIframe = {
                settings,
                customerConnections: allowedClientConnections,
                customers: allowedClients,
                division: {
                    title: division,
                    value: division,
                    checked: true,
                    data: null,
                },
                years: years.map((item) => ({
                    title: item,
                    value: item,
                    checked: true,
                    data: null,
                })),
                chartsData: dashboardData,
            };

            iframeRef.current.contentWindow.postMessage(
                { pdfDashboardProperties: properiesForIframe, isOpen },
                window.origin
            );
        }
    }, [
        allowedClientConnections,
        allowedClients,
        division,
        years,
        dashboardData,
        iframeRef.current,
        isOpen,
        settings,
        iframeAppIsMounted,
    ]);

    return (
        <>
            <ThemeModalWindow isOpen={isOpen} onClose={onClose}>
                <></>
                <Typography variant='body2' style={{ paddingTop: 20, maxWidth: 500 }}>
                    {hasAccessToPrint
                        ? t('contract-types:reportWillBeCreated')
                        : t('contract-types:reportCannotBeCreated')}
                </Typography>
                <Button className={classes.okButton} onClick={onClose}>
                    {t('ok')}
                </Button>
            </ThemeModalWindow>
            {propertiesForDashboard && inIFrame && (
                <div key={JSON.stringify(propertiesForDashboard)} ref={pdfContainerRef}>
                    <DashboardPdfView
                        {...propertiesForDashboard}
                        onContentLoaded={onContentLoaded}
                        parentModalWindowOpened={parentModalWindowOpened}
                    />
                </div>
            )}
            {!inIFrame && (
                <iframe
                    ref={iframeRef}
                    style={{
                        width: '100%',

                        position: 'fixed',
                        top: 0,
                        left: 0,
                        zIndex: -10000,
                    }}
                    title='dashboard-pdf-generating'
                    src={window.location.href}
                />
            )}
        </>
    );
};
