import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import { useCardContentStyle, useCardStyle } from './theme-hover-card-styles';
import { DarkMode } from 'use-dark-mode';

interface ThemeHoverCardProps {
    children: JSX.Element | JSX.Element[];
    darkMode: DarkMode;
    withoutHover?: boolean;
    draggable?: boolean;
    isActive?: boolean;
}

export const ThemeHoverCard = (props: ThemeHoverCardProps): JSX.Element => {
    const { children, darkMode, withoutHover, draggable, isActive } = props;
    const cardClasses = useCardStyle({
        withoutHover,
        draggable,
        isDarkMode: darkMode.value,
        isActive,
    });
    const cardContentClasses = useCardContentStyle();

    return (
        <Card elevation={0} classes={cardClasses}>
            <CardContent classes={cardContentClasses}>{children}</CardContent>
        </Card>
    );
};
