import moment from 'moment';
import { DamageReportFormGetRecordDTO } from '../../damage-report-form-types/damage-report-form-get-record-dto';
import { DamageReportFormAONKompositDREISETypes } from '../../damage-report-form-types/damage-report-form-AON-Komposit-types/damage-report-form-AON-Komposit-dreise-types';

export const damageReportFormDreiseGetAdapter = (
    data: DamageReportFormGetRecordDTO
): DamageReportFormAONKompositDREISETypes => {
    const offset = new Date().getTimezoneOffset() * 60000;

    return {
        id: data?.id,
        division: data?.division,
        dayOfDamage: data.dayOfDamage
            ? moment(moment(data.dayOfDamage).valueOf() + offset).toISOString()
            : null,
        timeOfDamage: data.timeOfDamage
            ? moment(moment(data.timeOfDamage).valueOf() + offset).toISOString()
            : null,

        risk: data.risk,
        contractNumber: data.contractNumber,
        contractId: data.contractId,
        policyHolder: data.policyHolder,
        insuranceCompany: data.insuranceCompany,
        insuredCompanyFileNumber: data?.luggageDamageInfo?.insuredCompanyFileNumber,
        creditInstitute: data?.luggageDamageInfo?.creditInstitute,
        iban: data?.luggageDamageInfo?.iban,
        bic: data?.luggageDamageInfo?.bic,
        accounOwner: data?.luggageDamageInfo?.accounOwner,
        nameOfTheInsured: data?.luggageDamageInfo?.insuredName,
        addressOfTheInsured: data?.luggageDamageInfo?.insuredAddress,
        postCodeAndPlaceResidenceInsuredPerson:
            data?.luggageDamageInfo?.insuredPersonPostCode,
        damageLocation: data?.luggageDamageInfo?.damageLocation,
        damageAssessmentDayAndTime: data?.luggageDamageInfo?.damageAssessmentDayAndTime,
        kindOfTrip: data?.luggageDamageInfo?.kindOfTrip,
        whereAndHowWasTheLostOrDamagedBaggageStored:
            data?.luggageDamageInfo?.whereAndHowDamagedBaggageStored,
        exactDescriptionOfTheCourseOfTheDamage:
            data?.luggageDamageInfo?.damageCourseExactDescription,
        witnessesOfTheDamage: data?.luggageDamageInfo?.damageWitnesses,
        nameAndAddress: data?.luggageDamageInfo?.nameAndAddress,
        telephoneOrFax: data?.luggageDamageInfo?.telephoneOrFax,
        possiblyPoliceDiaryNumber: data?.luggageDamageInfo?.possiblyPoliceDiaryNumber,
        whenWasTheDamageReported: data?.luggageDamageInfo?.damageReportedDateOrTime,
        whoBecameLiableForTheDamageWhenAndHowHeld:
            data?.luggageDamageInfo?.claimLiableInfo,
        whoHasAlreadyPaidWhatCompensationAndWhen:
            data?.luggageDamageInfo?.compensationInfo,
        whatMeasuresHaveBeenTakenToRegain:
            data?.luggageDamageInfo?.recoverInsuredPropertyMeasures,
        typeOrInsuranceCertificate: data?.luggageDamageInfo?.typeOrInsuranceCertificate,
        affectedItem: data?.damagedItemInfo?.itemInfo,
        ownAllOfTheAboveObjects: data?.damagedItemInfo?.isOwnAllObjects,
        includeTheOwner: data?.damagedItemInfo?.ownerInfo,
        files: [],
        fileInfos: data.fileInfos,
    };
};
