import React from 'react';

import { IconProps } from './index';

export const GlasIcon = (props: IconProps): JSX.Element => {
    const { fill } = props;
    return (
        <svg width='40' height='40' fill={fill} xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M36.364 34.286V0H3.636v34.286H0V40h40v-5.714h-3.636zM32.727 3.81v13.333H21.818V3.81h10.91zm-25.454 0h10.909v13.333H7.272V3.81zm0 30.476V20.952h10.909v13.334H7.272zm14.545 0V20.952h10.91v13.334h-10.91z'
                fill='#1581C1'
            />
        </svg>
    );
};
