import React, { useMemo, BaseSyntheticEvent } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Checkbox, TextField, FormControlLabel } from '@material-ui/core';
import { Autocomplete, FilterOptionsState } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { List, AutoSizer } from 'react-virtualized';

import {
    useStyle,
    useAutocompleteStyles,
    useFormControlLabelStyle,
} from './theme-autocomplete-styles';
import { MultipleValues } from './theme-autocomplete-components';

export interface ThemeAutocompleteOptions {
    title: string;
    value: string;
    checked: boolean;
    // eslint-disable-next-line
    data: any;
    additionalTitles?: string[];
    labelAsKeyForTFunction?: boolean;
}

interface ThemeAutocompleteProps {
    multiple: boolean;
    title: string;
    options: ThemeAutocompleteOptions[];
    triggerExternalCallback?: () => void;
    onChange: (name: string, value: ThemeAutocompleteOptions[]) => void;
    name: string;
    popperMinWidth?: number;
    placeholder?: string;
    withoutTags?: boolean;
    filterOptions?: (
        options: ThemeAutocompleteOptions[],
        state: FilterOptionsState<ThemeAutocompleteOptions>
    ) => ThemeAutocompleteOptions[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const VirtualizedListbox = (props: any): JSX.Element => {
    const { children, role, ref, ...other } = props;
    const itemCount = Array.isArray(children) ? children.length : 0;

    return (
        <div ref={ref}>
            <div {...other}>
                <AutoSizer disableHeight>
                    {({ width }) => {
                        return (
                            <List
                                height={200}
                                width={width}
                                rowHeight={({ index }) => {
                                    const [, additionalTitleNodes] =
                                        children[index]?.props?.children?.props?.label
                                            ?.props?.children;

                                    const multiplicationFactor =
                                        additionalTitleNodes?.length || 0;
                                    return 40 + multiplicationFactor * 20;
                                }}
                                overscanCount={5}
                                rowCount={itemCount}
                                rowRenderer={(props) => {
                                    return React.cloneElement(children[props.index], {
                                        style: props.style,
                                    });
                                }}
                                role={role}
                            />
                        );
                    }}
                </AutoSizer>
            </div>
        </div>
    );
};

export const ThemeAutocomplete = (props: ThemeAutocompleteProps): JSX.Element => {
    const {
        multiple,
        options,
        title,
        onChange,
        name,
        triggerExternalCallback,
        placeholder,
        popperMinWidth = 200,
        withoutTags = false,
        filterOptions,
    } = props;

    const classes = useStyle();
    const autocompleteClasses = useAutocompleteStyles({ popperMinWidth });
    const formControlLabelClasses = useFormControlLabelStyle();

    const { t } = useTranslation(['common']);

    const handleClose = (): void => {
        if (triggerExternalCallback) {
            triggerExternalCallback();
        }
    };

    const handleChange = (e: BaseSyntheticEvent, changedValue, reason: string): void => {
        const isAll =
            (e.target.name === 'all' || e.target.outerText === 'All') &&
            reason === 'select-option';
        if (isAll) {
            const newItemsList = options.map(({ title, value, data, ...rest }) => {
                if (value === 'all') {
                    return { ...rest, title, value, checked: true, data };
                }
                return { title, value, checked: false, data };
            });
            onChange(name, newItemsList);
        } else {
            const newItemsList = options.map(({ title, value, data, ...rest }) => {
                if (value === 'all') {
                    return { ...rest, title, value, checked: false, data };
                }
                if (changedValue?.find((item) => item.value === value)) {
                    return { ...rest, title, value, checked: true, data };
                } else {
                    return { ...rest, title, value, checked: false, data };
                }
            });
            onChange(name, newItemsList);
        }
    };

    const value = useMemo(() => options.filter((item) => item.checked), [options]);

    return (
        <Autocomplete
            disableCloseOnSelect
            disableClearable
            multiple={multiple}
            getOptionLabel={(option) => option.title}
            options={options}
            classes={autocompleteClasses}
            popupIcon={<ExpandMoreIcon className={classes.expandMoreIcon} />}
            onClose={handleClose}
            onChange={handleChange}
            value={value}
            noOptionsText={t('noOptions')}
            ListboxComponent={(props) => (
                <VirtualizedListbox key={JSON.stringify(options)} {...props} />
            )}
            filterOptions={filterOptions}
            renderTags={(values: ThemeAutocompleteOptions[]) => {
                if (withoutTags) {
                    return null;
                }
                if (values?.length === 1) {
                    return values.map((item) => {
                        return (
                            <p key={item.value} className={classes.tag}>
                                {item.labelAsKeyForTFunction ? t(item.title) : item.title}
                            </p>
                        );
                    });
                }
                return <MultipleValues t={t} values={values} className={classes.tag} />;
            }}
            renderOption={(option, { selected }) => {
                return (
                    <FormControlLabel
                        label={
                            <div style={{ marginBottom: '1rem' }}>
                                <p
                                    style={{ marginBottom: 0 }}
                                    className={classes.mainOptionTitle}
                                >
                                    {option?.labelAsKeyForTFunction
                                        ? t(option.title)
                                        : option.title}
                                </p>
                                {option.additionalTitles
                                    ?.filter((item) => item)
                                    ?.map((addTitle: string) => (
                                        <p
                                            key={addTitle}
                                            style={{
                                                fontSize: '1.2rem',
                                                marginTop: 0,
                                                marginBottom: 0,
                                            }}
                                        >
                                            {addTitle}
                                        </p>
                                    ))}
                            </div>
                        }
                        classes={formControlLabelClasses}
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                        control={
                            <Checkbox
                                icon={
                                    <CheckBoxOutlineBlankIcon className={classes.icon} />
                                }
                                checkedIcon={
                                    <CheckBoxIcon
                                        className={clsx(
                                            classes.icon,
                                            classes.checkedIcon
                                        )}
                                    />
                                }
                                style={{ marginRight: 8 }}
                                checked={selected}
                                name={option.value}
                            />
                        }
                    />
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    variant='outlined'
                    label={title}
                    placeholder={placeholder}
                />
            )}
        />
    );
};
