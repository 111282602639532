import React, { ReactElement } from 'react';
import { DarkMode } from 'use-dark-mode';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Grid, Button, Typography } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { appInstance, isHectorDemoInstance } from 'src/environment';

import { ThemeSwitcher } from 'src/theming';
import { ThemeFooter } from 'src/theming/theme-footer';
import { useUserAonMotorLayoutStyles } from './user-aon-motor-layout-styles';
import { useStyle } from '../styles';
import { AppInstances } from 'src/shared/constants';

import { HectorDemoLogo } from 'src/theming/theme-logos';

interface UserAonMotorLayoutProps {
    children: JSX.Element;
    darkMode: DarkMode;
    backPath?: string;
}

const getLogo = (hectorLogoContainerClass: string): ReactElement => {
    switch (appInstance) {
        case AppInstances.AON_MOTOR:
        case AppInstances.AON_DIGITAL: {
            return <img alt='logo' src='/static/images/aon.png' width={130} />;
        }
        case AppInstances.HECTOR_DEMO: {
            return (
                <div className={hectorLogoContainerClass}>
                    <HectorDemoLogo />
                </div>
            );
        }
        default: {
            return <img alt='logo' src='/static/images/aon.png' width={130} />;
        }
    }
};

export const UserAonMotorLayout = (props: UserAonMotorLayoutProps): ReactElement => {
    const { darkMode, children, backPath } = props;
    const router = useHistory();
    const { t } = useTranslation(['common', 'user-management']);
    const classes = useUserAonMotorLayoutStyles();
    const mainUserLayoutClasses = useStyle();

    const handleGoBack = (): void => {
        router.push(backPath);
    };

    return (
        <Grid container className={classes.pageContainer}>
            <Grid item className={classes.previewContainer}>
                <div className={classes.logo}>{getLogo(classes.hectorLogoContainer)}</div>
                <div className={classes.dots}>
                    <img
                        className={mainUserLayoutClasses.lightElement}
                        alt='background-mask'
                        src='/static/images/login-background-mask-aon-motor.svg'
                    />
                    <img
                        className={mainUserLayoutClasses.darkElement}
                        alt='background-mask'
                        src='/static/images/login-background-mask.svg'
                    />
                </div>
                {!isHectorDemoInstance && (
                    <div className={classes.portalInformation}>
                        <Typography
                            variant='body1'
                            className={classes.portalInformationMessage}
                        >
                            {t('user-management:sgiffoxxkPortalDescription')}
                        </Typography>
                    </div>
                )}
            </Grid>
            <Grid item className={classes.functionalityContainer}>
                <div className={classes.toolbarContainer}>
                    <Grid item>
                        {backPath && (
                            <Button
                                disableElevation
                                disableRipple
                                onClick={handleGoBack}
                                startIcon={<KeyboardBackspaceIcon />}
                                className={classes.backButton}
                            >
                                {t('common:back')}
                            </Button>
                        )}
                    </Grid>
                    <div style={{ flexGrow: 1 }} />
                    <Grid item className={classes.switcherContainer}>
                        <ThemeSwitcher
                            leftLabel={t('common:light')}
                            rightLabel={t('common:dark')}
                            checked={darkMode.value}
                            onChange={darkMode.toggle}
                        />
                    </Grid>
                </div>
                <div className={classes.form}>{children}</div>
            </Grid>
            <div className={classes.footerWrapper}>
                <div className={classes.footer}>
                    <ThemeFooter />
                </div>
            </div>
        </Grid>
    );
};
