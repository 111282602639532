import { DamageReportFormAONKompositUniversalTypes } from '../../../damage-report-form-utils/damage-report-form-types/damage-report-form-AON-Komposit-types/damage-report-form-AON-Komposit-universal-types';

export const damageReportFormAONKompositUniversalDefaultState = (
    contractId: string
): DamageReportFormAONKompositUniversalTypes => {
    return {
        contractId,
        id: '',
        division: '',
        dayOfDamage: null,
        timeOfDamage: null,
        risk: '',
        contractNumber: '',
        policyHolder: '',
        insuranceCompany: '',
        rootCause: '',
        damage: '',
        damageDescription: '',
        files: [],
        fileInfos: [],
    };
};
