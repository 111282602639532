import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, generatePath } from 'react-router';

import { Button, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { ROLES } from 'src/shared/constants';
import { APP_ROUTES } from 'src/routing';

import { MasterDataApproveDeleteModalWindow } from './master-data-approve-delete-modal-window';

import { useStyle } from './master-data-style';

interface Props {
    isDummyCustomers: boolean;
    isDocuments: boolean;
    activeCC: string;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    refetchClientConnections: () => void;
    roles?: ROLES[];
}

const DocumentTranscodeActions = ({
    isDummyCustomers,
    isDocuments,
    activeCC,
    page,
    rowsPerPage,
    searchValue,
    refetchClientConnections,
}: Props): JSX.Element => {
    const classes = useStyle();
    const { t } = useTranslation(['user-management']);
    const history = useHistory();

    const [isOpen, setOpen] = useState<boolean>(false);

    const handleOpenApproveModalWindow = (): void => {
        setOpen(true);
    };

    const handleCloseApproveModalWindow = (): void => {
        setOpen(false);
    };

    const goToTranscodeDocumentsPage = (): void => {
        history.push({
            pathname: generatePath(APP_ROUTES.TRANSCODE_DOCUMENTS, {
                customerConnection: activeCC,
            }),
            state: { preselectedCC: activeCC, page, rowsPerPage, searchValue },
        });
    };

    return isDummyCustomers ? (
        <>
            {!isDocuments ? (
                <>
                    <IconButton
                        className={classes.deleteButton}
                        onClick={handleOpenApproveModalWindow}
                    >
                        <DeleteIcon />
                    </IconButton>
                    <MasterDataApproveDeleteModalWindow
                        isOpen={isOpen}
                        activeCC={activeCC}
                        onClose={handleCloseApproveModalWindow}
                        onSuccess={refetchClientConnections}
                    />
                </>
            ) : null}
            {isDocuments ? (
                <Button
                    className={classes.containedButton}
                    onClick={goToTranscodeDocumentsPage}
                >
                    {t('masterData.transcodeDocuments')}
                </Button>
            ) : null}
        </>
    ) : null;
};

export default DocumentTranscodeActions;
