import { createStyles, makeStyles, Theme } from '@material-ui/core';
import {
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorDemoInstance,
} from 'src/environment';
import { THEME_MODES } from 'src/theming';

export const useDashboardPieTachoStyle = makeStyles<Theme>((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return createStyles({
        container: {
            position: 'relative',
            height: '100%',
            width: '100%',
            '& svg rect': {
                fill: () => {
                    if (
                        (isAonDigitalInstance ||
                            isAonMotorInstance ||
                            isHectorDemoInstance) &&
                        isDarkMode
                    ) {
                        return theme.palette.primary.main;
                    }
                    return null;
                },
            },
        },
        value: {
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            zIndex: 10,
            fontSize: 40,
        },
    });
});
