import { makeStyles, Theme } from '@material-ui/core';

export const useHrStyles = makeStyles((theme: Theme) => {
    return {
        table: {
            width: '100%',
            marginBottom: theme.spacing(2),
            textAlign: 'left',
        },
        tableCell: {
            color: theme.palette.primary.contrastText,
        },
        tableHeader: {
            color: theme.palette.primary.contrastText,
            fontWeight: theme.typography.fontWeightBold,
        },
        listItem: {
            width: '800px',
        },
        listItem2: {
            width: '200%',
        },
        subtitle: {
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightBold,
            },
        },
    };
});
