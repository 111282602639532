import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { SelectItem } from 'src/theming';
import { RelinkMenu } from './relink-generate-menu';

import { RelinkDTO } from './relink-contract-dto';

export const useOptionsForDynamicFields = (
    type: RelinkMenu,
    customerId: string
): SelectItem[] => {
    const httpClient = useHttpClient();
    const { t } = useTranslation(['relink']);

    const [options, setOptions] = useState<SelectItem[]>([]);

    const getRealContracts = async (customerId: string): Promise<void> => {
        if (customerId) {
            const data = await httpClient.get<RelinkDTO[]>(
                `get-contracts/relink?customerId=${customerId}&isDummy=false`
            );
            if (Array.isArray(data)) {
                setOptions([
                    {
                        title: t('relink:noChoice'),
                        value: 'No choice',
                    },
                    ...data
                        .map((item) => ({
                            title: item.contractNumber,
                            value: item.amsIdNr,
                        }))
                        .sort((a, b) => a.title.localeCompare(b.title)),
                ]);
            }
        } else {
            setOptions([
                {
                    title: t('relink:noChoice'),
                    value: 'No choice',
                },
            ]);
        }
    };

    const getRealDamages = async (customerId: string): Promise<void> => {
        if (customerId) {
            const data = await httpClient.get<RelinkDTO>(
                `get-damages/relink?customerId=${customerId}&isDummy=false`
            );
            if (Array.isArray(data)) {
                setOptions([
                    {
                        title: t('relink:noChoice'),
                        value: 'No choice',
                    },
                    ...data
                        .map((item) => ({
                            title: item.damageNumber,
                            value: item.amsIdNr,
                        }))
                        .sort((a, b) => a.title.localeCompare(b.title)),
                ]);
            }
        } else {
            setOptions([
                {
                    title: t('relink:noChoice'),
                    value: 'No choice',
                },
            ]);
        }
    };

    useEffect(() => {
        if (type === RelinkMenu.CONTRACTS) {
            getRealContracts(customerId);
        }
        if (type === RelinkMenu.DAMAGES) {
            getRealDamages(customerId);
        }
    }, [type, customerId]);

    return options;
};
