import moment from 'moment';
import {
    DamageReportFormTRANSPTypes,
    DamageReportFormTRANSPMutation,
} from '../damage-report-form-types/damage-report-form-TRANSP-types';

export const damageReportFormTranspMutateAdapter = (
    data: DamageReportFormTRANSPTypes
): DamageReportFormTRANSPMutation => {
    const offset = new Date().getTimezoneOffset() * 60000;
    return {
        id: data.id,
        dayOfDamage: data.dayOfDamage
            ? moment(moment(data.dayOfDamage).valueOf() - offset).toISOString()
            : null,
        timeOfDamage: data.timeOfDamage
            ? moment(moment(data.timeOfDamage).valueOf() - offset).toISOString()
            : null,
        contractId: data.contractId,
        risk: data.risk,
        contractNumber: data.contractNumber,
        policyHolder: data.policyHolder,
        insuranceCompany: data.insuranceCompany,
        liabilityRange: data.ariaOfLiability,
        vehicleDetails: {
            policeLicensePlate: data.policeLicensePlate,
            vehicleBody: data.vehicleBody,
        },
        freightLoadingAndDamageInfo: {
            damageType: data.typeOfDamage,
            client: data.whoWasTheClient,
            driver: data.driver,
            placeOfDeparture: data.departurePlace,
            destination: data.destination,
            whoLoaded: data.whoLoaded,
            haveAgreementsAboutLoadingMet: data.haveAgreementsAboutLoadingMet,
            whichAgreements: data.whichAgreement,
            wasRequestedInTermsOfNumberOfItems: data.wasInQuantityTakeoverRequested,
            whichTerms: data.whichQuantityTakeoverRequested,
            driverCheckNumberOfUnitsUponAcceptance:
                data.doesDriverHaveNumberOfPiecesAtTakeoverChecked,
            driverConfirmNumberOfUnitsUponAcceptance:
                data.doesDriverHaveNumberOfPiecesAtTakeoverAcknowledged,
            whoDischarged: data.whoDischarged,
            hasItBeenReloaded: data.hasItBeenReloaded,
            whichGoodsAreMissingOrDamaged: data.whichGoodsAreMissingOrDamaged,
            estimatedAmountOfDamage: data.estimatedDamageAmount,
            totalWeightOfTheCompleteLoad: data.totalWeightOfCompleteCharge,
            totalWeightOfTheCargoAffectedByTheDamage:
                data.totalWeightOfDamageConcernedCharge,
            wasTheGoodsPacked: data.wasGoodsPacked,
            kindOfPackage: data.kindOfPackage,
            whenDamageOccured: data.whenIsDamageDeveloped,
            recipientIssuedReceipt: data.grantedRecipientReceipt,
            textOfReservation: data.textofReservation,
            whereWasReservationMade: data.whereWasReservationMade,
            amountOfDamageAlreadyBeenReducedByFreight: data.damageAmountReducedByFreight,
            detailedDamageDescription: data.detailedDamageDescription,
            whereDamagedItemLocated: data.whereIsDamagedItemLocated,
            whichAverageAdjusterWasCalledInAndWhen: data.averageAndCommissioner,
        },
        policeRecordTransp: {
            incidentRecordedByPolice: data.isRecordedByThePolice,
            policeStation: data.policeStation,
            policeFileNumber: data.fileNumber,
        },
        documentsInfo: {
            attachedDocuments:
                Array.isArray(data.attachedDocuments) && data.attachedDocuments.length > 0
                    ? data.attachedDocuments.map((item) => item.name)
                    : null,
        },
        newFiles: data.files?.map((item) => item.file),
        fileInfos: data.fileInfos,
    };
};
