import { FieldErrors } from 'react-hook-form';
import { DamageReportFormContainer } from '../damage-report-form-components/damage-report-form-input-containers/damage-report-form-container';
import { DamageReportFormInput } from '../damage-report-form-components/damage-report-form-items/damage-report-form-input';
import { DamageFormDisabledInput } from '../damage-report-form-components/damage-report-form-items/damage-form-disabled-input';
import { DamageReportFormSelect } from '../damage-report-form-components/damage-report-form-items/damage-report-form-select';
import { CheckboxItem, SelectItem } from '../../../theming';
import { DamageReportFormFullwidthInput } from '../damage-report-form-components/damage-report-form-items/damage-report-form-fullwidth-input';
import { DamageFormConditionalBlock } from '../damage-report-form-components/damage-report-form-input-containers/damage-form-conditional-block';
import { DamageReportFormFullInput } from '../damage-report-form-components/damage-report-form-items/damage-report-form-full-input';
import { DamageReportFormPhoneInput } from '../damage-report-form-components/damage-report-form-items/damage-report-form-phone-input';
import { BaseSyntheticEvent, ChangeEvent } from 'react';

export interface FormContainerProps {
    className?: string;
    title: string;
    children: JSX.Element;
    isCombination?: boolean;
    toggleValue?: () => void;
    disabled?: boolean;
}

export interface FormItemProps {
    label: string;
    value: unknown | string;
    name: string;
    items?: SelectItem[];
    handleChangeValue?: (e: BaseSyntheticEvent) => void;
    disabled?: boolean;
    editMode?: boolean;
    values?: string[];
    handleChangeCheckboxesGroup?: (e: ChangeEvent<HTMLInputElement>) => void;
    options?: CheckboxItem[];
    regexp?: RegExp;
    className?: string;
}

export interface FormElement {
    id?: string;
    Item: (props: FormItemProps) => JSX.Element;
    label: string;
    name: string;
    value: unknown;
    items?: SelectItem[];
    options?: CheckboxItem[];
    regexp?: RegExp;
    handleChangeCheckboxesGroup?: (e: ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    additionalLabel?: string;
    error?: boolean;
}

export interface DamageReportFormConfigGBWWReturnType {
    title?: string;
    titleData?: string;
    id: string;
    isCombination?: boolean;
    showIfFalse?: boolean;
    toggleValue?: () => void;
    Container: (props: FormContainerProps) => JSX.Element;
    formElements: FormElement[];
    formElementsRows?: {
        RowComponent: React.FC<{
            rows: { id: string; formElements: FormElement[] }[];
            handleAddRow?: () => void;
            handleDeleteRow?: (id: string) => void;
            handleOnChangeRow?: <T extends null>(id: string, row: T) => void;
        }>;
        rows: { id: string; formElements: FormElement[] }[];
        handleAddRow?: () => void;
        handleDeleteRow?: (id: string) => void;
        handleOnChangeRow?: <T extends null>(id: string, row: T) => void;
    };
}

interface DamageReportFormConfigGBWProps {
    road: string;
    plz: string;
    country: string;
    place: string;
    typeofDamageGBW: string;
    items: SelectItem[];
    detailedDamageDescription: string;
    amountOfDamage: string;
    damagedOwner: string;
    toggleWhere: () => void;
    isToggleWhere: boolean;
    where: string;
    isTogglePolice: boolean;
    togglePolice: () => void;
    policeStation: string;
    fileNumber: string;
    initialMeasures: string;
}

export interface generateDisabledInputsProps {
    licenseNumber: string;
    contractNumber: string;
    policyHolder: string;
    insuranceCompany: string;
    errors?: FieldErrors<{
        contractNumber: string;
        insuranceCompany: string;
        policyHolder: string;
    }>;
}

interface generateFooterElementsProps {
    costCentre: number;
    firm: string;
    localName: string;
    phone: string;
    claimNumber: string;
    email: string;
    isDeductVat: boolean;
    iban: string;
    bic: string;
    handleChangeDeduct: () => void;
    localNameError: boolean;
    emailError: boolean;
}

export const generateDisabledInputs = ({
    licenseNumber,
    contractNumber,
    policyHolder,
    insuranceCompany,
    errors,
}: generateDisabledInputsProps): DamageReportFormConfigGBWWReturnType[] => [
    {
        Container: DamageReportFormContainer,
        title: '',
        id: 'generalFormBlock',
        formElements: [
            {
                Item: DamageFormDisabledInput,
                value: licenseNumber,
                label: 'damages-report:generalFormBlock.risk',
                name: 'risk',
            },
            {
                Item: DamageFormDisabledInput,
                value: contractNumber,
                label: 'damages-report:generalFormBlock.contractNumber',
                name: 'contractNumber',
                error: Boolean(errors.contractNumber),
            },
            {
                Item: DamageFormDisabledInput,
                value: policyHolder,
                label: 'damages-report:generalFormBlock.policyHolder',
                name: 'policyHolder',
                error: Boolean(errors.policyHolder),
            },
            {
                Item: DamageFormDisabledInput,
                value: insuranceCompany,
                label: 'damages-report:generalFormBlock.client',
                name: 'insuranceCompany',
                error: Boolean(errors.insuranceCompany),
            },
        ],
    },
];

export const generateFooterElements = ({
    costCentre,
    firm,
    localName,
    phone,
    claimNumber,
    email,
    isDeductVat,
    iban,
    bic,
    handleChangeDeduct,
    localNameError,
    emailError,
}: generateFooterElementsProps): DamageReportFormConfigGBWWReturnType[] => [
    {
        title: 'damages-report:localContact.title',
        Container: DamageReportFormContainer,
        id: 'localContact',
        formElements: [
            {
                Item: DamageReportFormInput,
                label: 'damages-report:localContact.name',
                name: 'localName',
                value: localName,
                error: localNameError,
            },
            {
                Item: DamageReportFormInput,
                label: 'damages-report:localContact.firm',
                name: 'firm',
                value: firm,
            },
            {
                Item: DamageReportFormPhoneInput,
                label: 'damages-report:localContact.phone',
                name: 'phone',
                value: phone,
            },
            {
                Item: DamageReportFormInput,
                label: 'damages-report:localContact.email',
                name: 'email',
                value: email,
                error: emailError,
            },
            {
                Item: DamageReportFormInput,
                label: 'damages-report:localContact.claimNumber',
                name: 'claimNumber',
                value: claimNumber,
            },
            {
                Item: DamageReportFormInput,
                label: 'damages-report:localContact.costCentre',
                name: 'costCentre',
                value: costCentre,
                regexp: new RegExp('^[0-9]*$'),
            },
        ],
    },
    {
        title: 'damages-report:deductVAT.title',
        id: 'deductVAT',
        Container: DamageFormConditionalBlock,
        isCombination: isDeductVat,
        formElements: [],
        toggleValue: handleChangeDeduct,
    },
    {
        title: '',
        id: 'vat',
        Container: DamageReportFormContainer,
        formElements: [
            {
                Item: DamageReportFormFullInput,
                name: 'bic',
                label: 'damages-report:deductVAT.bic',
                value: bic,
            },
            {
                Item: DamageReportFormFullInput,
                name: 'iban',
                label: 'damages-report:deductVAT.iban',
                value: iban,
            },
        ],
    },
];

export const DamageReportFormConfigGBW = ({
    road,
    plz,
    place,
    country,
    typeofDamageGBW,
    items,
    detailedDamageDescription,
    amountOfDamage,
    damagedOwner,
    toggleWhere,
    isToggleWhere,
    where,
    isTogglePolice,
    policeStation,
    togglePolice,
    fileNumber,
    initialMeasures,
}: DamageReportFormConfigGBWProps): DamageReportFormConfigGBWWReturnType[][] => {
    return [
        [
            {
                title: 'damages-report:damageData.title',
                Container: DamageReportFormContainer,
                id: 'accidentScene',
                formElements: [
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:accidentScene.road',
                        name: 'road',
                        value: road,
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:accidentScene.postCode',
                        name: 'plz',
                        value: plz,
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:accidentScene.place',
                        name: 'place',
                        value: place,
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:accidentScene.country',
                        name: 'country',
                        value: country,
                    },
                ],
            },
            {
                title: '',
                id: 'typeOfDamage',
                Container: DamageReportFormContainer,
                formElements: [
                    {
                        Item: DamageReportFormSelect,
                        label: 'damages-report:damageData.typeOfDamage',
                        name: 'typeofDamageGBW',
                        value: typeofDamageGBW,
                        items,
                    },
                    {
                        Item: DamageReportFormFullwidthInput,
                        name: 'detailedDamageDescription',
                        value: detailedDamageDescription,
                        label: 'damages-report:driverInformation.damageDetailsDescription',
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:damageData.amountOfDamage',
                        name: 'amountOfDamage',
                        value: amountOfDamage,
                    },
                    {
                        Item: DamageReportFormFullwidthInput,
                        label: 'damages-report:damageData.damagedOwner',
                        name: 'damagedOwner',
                        value: damagedOwner,
                    },
                ],
            },
            {
                title: 'damages-report:damageData.insuredOtherwise',
                id: 'insuredOtherwise',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:damageData.where',
                        value: where,
                        name: 'where',
                    },
                ],
                isCombination: isToggleWhere,
                toggleValue: toggleWhere,
                Container: DamageFormConditionalBlock,
            },
        ],
        [
            {
                title: 'damages-report:recordedByThePolicy.title',
                id: 'policeTitle',
                Container: DamageReportFormContainer,
                formElements: [],
            },
            {
                title: 'damages-report:damageData.policeTitle',
                id: 'policeTitle',
                isCombination: isTogglePolice,
                toggleValue: togglePolice,
                formElements: [
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:damageData.policeStation',
                        name: 'policeStation',
                        value: policeStation,
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:damageData.fileNumber',
                        name: 'fileNumber',
                        value: fileNumber,
                    },
                    {
                        Item: DamageReportFormFullwidthInput,
                        label: 'damages-report:damageData.initialMeasures',
                        name: 'initialMeasures',
                        value: initialMeasures,
                    },
                ],
                Container: DamageFormConditionalBlock,
            },
        ],
    ];
};
