import { GridSortModel } from '@material-ui/data-grid';
import qs from 'querystring';
import { CheckboxItem } from 'src/theming';

export const contractDetailsViewPrepareQueryForDamagesRequest = (
    damageNR?: string,
    damageNREstateAgent?: string,
    damageNRVU?: string,
    sortModel?: GridSortModel,
    damageStatus?: CheckboxItem[]
): string => {
    const queryParams: {
        damageNumber?: string;
        damageNumberE?: string;
        damageNumberI?: string;
        sortField?: string;
        isAscending?: boolean;
        allowedStatuses?: string[];
    } = {};
    if (damageNR) {
        queryParams.damageNumber = damageNR;
    }
    if (damageNREstateAgent) {
        queryParams.damageNumberE = damageNREstateAgent;
    }
    if (damageNRVU) {
        queryParams.damageNumberI = damageNRVU;
    }
    if (sortModel && sortModel.length !== 0) {
        queryParams.isAscending = sortModel[0].sort === 'asc';
        queryParams.sortField = sortModel[0].field;
    }
    const isAllDamageStatusesChecked = damageStatus?.find(
        (item) => item.value === 'all'
    )?.checked;
    if (isAllDamageStatusesChecked) {
        queryParams.allowedStatuses = damageStatus
            .filter((item) => item.value !== 'all')
            .map((item) => item.value);
    } else {
        queryParams.allowedStatuses = damageStatus
            .filter((item) => item.value !== 'all' && item.checked)
            .map((item) => item.value);
    }
    return qs.stringify(queryParams);
};
