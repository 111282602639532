import { DamageReportFormGBWTypes } from '../../damage-report-form-utils/damage-report-form-types/damage-report-form-GBW-HR-GESCH-types';

export const damageReportFormGBWDefaultState = (
    contractId: string
): DamageReportFormGBWTypes => {
    return {
        contractId,
        division: '',
        dayOfDamage: null,
        timeOfDamage: null,
        detailedDamageDescription: '',
        amountOfDamage: '',
        typeofDamageGBW: '',
        road: '',
        plz: '',
        place: '',
        country: '',
        licenseNumber: '',
        contractNumber: '',
        policyHolder: '',
        insuranceCompany: '',
        damagedOwner: '',
        isToggleWhere: false,
        where: '',
        isTogglePolice: false,
        policeStation: '',
        fileNumber: '',
        initialMeasures: '',
        localName: '',
        firm: '',
        phone: '',
        email: '',
        claimNumber: '',
        costCentre: null,
        isDeductVat: false,
        iban: '',
        bic: '',
        files: [],
    };
};
