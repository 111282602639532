import { TFunction } from 'i18next';
import { SelectItem } from 'src/theming';

export enum StatusSelectionItems {
    OPEN = 'Offen',
    CLOSED = 'Geschlossen',
    ALL = 'Alle',
}

export const reportCenterGenerateStatusSelectionItems = (t: TFunction): SelectItem[] => {
    return [
        {
            title: t('statuses.all'),
            value: StatusSelectionItems.ALL,
        },
        {
            title: t('statuses.closed'),
            value: StatusSelectionItems.CLOSED,
        },
        {
            title: t('statuses.open'),
            value: StatusSelectionItems.OPEN,
        },
    ];
};
