import moment from 'moment';
import { Divisions } from 'src/shared/constants';

import { DamageReportFormGetRecordDTO } from '../damage-report-form-types/damage-report-form-get-record-dto';
import { DamageReportFormTRANSPTypes } from '../damage-report-form-types/damage-report-form-TRANSP-types';
export const damageReportFormTRANSPGetReportRecordAdapter = (
    data: DamageReportFormGetRecordDTO
): DamageReportFormTRANSPTypes => {
    const offset = new Date().getTimezoneOffset() * 60000;
    return {
        id: data.id,
        division: Divisions.TRANSP,
        dayOfDamage: data.dayOfDamage
            ? moment(moment(data.dayOfDamage).valueOf() + offset).toISOString()
            : null,
        timeOfDamage: data.timeOfDamage
            ? moment(moment(data.timeOfDamage).valueOf() + offset).toISOString()
            : null,
        contractId: data.contractId,
        risk: data.risk,
        contractNumber: data.contractNumber,
        policyHolder: data.policyHolder,
        insuranceCompany: data.insuranceCompany,
        ariaOfLiability: data.liabilityRange,
        policeLicensePlate: data.vehicleDetails?.policeLicensePlate,
        vehicleBody: data.vehicleDetails?.vehicleBody,
        typeOfDamage: data.freightLoadingAndDamageInfo?.damageType,
        whoWasTheClient: data.freightLoadingAndDamageInfo?.client,
        driver: data.freightLoadingAndDamageInfo?.driver,
        departurePlace: data.freightLoadingAndDamageInfo?.placeOfDeparture,
        destination: data.freightLoadingAndDamageInfo?.destination,
        whoLoaded: data.freightLoadingAndDamageInfo?.whoLoaded,
        haveAgreementsAboutLoadingMet:
            data.freightLoadingAndDamageInfo?.haveAgreementsAboutLoadingMet,
        whichAgreement: data.freightLoadingAndDamageInfo?.whichAgreements,
        wasInQuantityTakeoverRequested:
            data.freightLoadingAndDamageInfo?.wasRequestedInTermsOfNumberOfItems,
        whichQuantityTakeoverRequested: data?.freightLoadingAndDamageInfo?.whichTerms,
        doesDriverHaveNumberOfPiecesAtTakeoverChecked:
            data.freightLoadingAndDamageInfo?.driverCheckNumberOfUnitsUponAcceptance,
        doesDriverHaveNumberOfPiecesAtTakeoverAcknowledged:
            data.freightLoadingAndDamageInfo?.driverConfirmNumberOfUnitsUponAcceptance,
        whoDischarged: data.freightLoadingAndDamageInfo?.whoDischarged,
        hasItBeenReloaded: data.freightLoadingAndDamageInfo?.hasItBeenReloaded,
        whichGoodsAreMissingOrDamaged:
            data.freightLoadingAndDamageInfo?.whichGoodsAreMissingOrDamaged,
        estimatedDamageAmount:
            Number(data.freightLoadingAndDamageInfo?.estimatedAmountOfDamage) || null,
        totalWeightOfCompleteCharge:
            data.freightLoadingAndDamageInfo?.totalWeightOfTheCompleteLoad,
        totalWeightOfDamageConcernedCharge:
            data.freightLoadingAndDamageInfo?.totalWeightOfTheCargoAffectedByTheDamage,
        wasGoodsPacked: data.freightLoadingAndDamageInfo?.wasTheGoodsPacked,
        kindOfPackage: data.freightLoadingAndDamageInfo?.kindOfPackage,
        whenIsDamageDeveloped: data.freightLoadingAndDamageInfo?.whenDamageOccured,
        grantedRecipientReceipt: data.freightLoadingAndDamageInfo?.recipientIssuedReceipt,
        textofReservation: data.freightLoadingAndDamageInfo?.textOfReservation,
        whereWasReservationMade:
            data.freightLoadingAndDamageInfo?.whereWasReservationMade,
        damageAmountReducedByFreight:
            data.freightLoadingAndDamageInfo?.amountOfDamageAlreadyBeenReducedByFreight,
        detailedDamageDescription:
            data.freightLoadingAndDamageInfo?.detailedDamageDescription,
        whereIsDamagedItemLocated:
            data.freightLoadingAndDamageInfo?.whereDamagedItemLocated,
        averageAndCommissioner:
            data.freightLoadingAndDamageInfo?.whichAverageAdjusterWasCalledInAndWhen,
        isRecordedByThePolice: data.policeRecordTransp?.incidentRecordedByPolice,
        policeStation: data.policeRecordTransp?.policeStation,
        fileNumber: data.policeRecordTransp?.policeFileNumber,
        attachedDocuments:
            Array.isArray(data.documentsInfo?.attachedDocuments) &&
            data.documentsInfo.attachedDocuments.length > 0
                ? data.documentsInfo.attachedDocuments.map((item) => ({ name: item }))
                : [],
        fileInfos: data.fileInfos,
    };
};
