import { DashboardWidgets, Divisions } from 'src/shared/constants';

import {
    DashboardBookedPremium,
    DashboardContractsActiveAmount,
    DashboardContractsYearlyPremium,
    DashboardDamageAmountByMonth,
    DashboardDamagesByStatus,
    DashboardDamagesRatio,
    DashboardEsaExpense,
    DashboardEsaExpenseReserve,
} from '..';
import { DashbaordDamageExpenseByMonth } from '../dashboard-elements/dashboard-damage-expense-by-month';
import { DashboardDamagesByState } from '../dashboard-elements/dashboard-damages-by-state';
import { DashboardDataResponseType } from 'src/shared/contexts/dashboard-context/dashboard-data-response-type';
import { ValueWithTrendPercentage } from 'src/dashboard/value-with-trend-percentage';
import { PeriodValue, EsaStatusAmounts, EsaStateAmounts } from 'src/dashboard/common';

interface Value {
    year: string;
    value: number;
}

interface GenerateDashboardConfigProps {
    division: string;
    years: number[];
    dashboardData?: DashboardDataResponseType;
}

export interface DashboardConfigItem {
    Item: React.ElementType;
    title?: string;
    className?: string;
    expense?: ValueWithTrendPercentage<Value[]>;
    bookedPremium?: ValueWithTrendPercentage<Value[]>;
    partialCoverage?: Value[];
    damageRatio?: Value[];
    years?: number[];
    amount?: number;
    amounts?: PeriodValue<number>[] | EsaStatusAmounts | EsaStateAmounts;
}

interface DashboardConfig {
    [key: string]: DashboardConfigItem;
}

export const generateDashboardConfig = ({
    dashboardData,
    division,
    years,
}: GenerateDashboardConfigProps): DashboardConfig => {
    return {
        [`${DashboardWidgets.DAMAGE_RATIO_GENERAL}`]: {
            className: years?.length === 1 && 'widgetXL',
            title: 'damageRatio.general',
            Item: DashboardDamagesRatio,
            damageRatio: dashboardData?.damageRatioGeneral,
        },
        [`${DashboardWidgets.DAMAGE_RATIO_OVERALL}`]: {
            className: years?.length === 1 && 'widgetXL',
            title: 'damageRatio.overall',
            Item: DashboardDamagesRatio,
            damageRatio: dashboardData?.damageRatioOverall,
        },
        [`${DashboardWidgets.DAMAGE_RATIO_LIABILITY}`]: {
            className: years?.length === 1 && 'widgetXL',
            title: 'damageRatio.liability',
            Item: DashboardDamagesRatio,
            damageRatio: dashboardData?.damageRatioLiability,
        },
        [`${DashboardWidgets.DAMAGE_RATIO_COMPREHENSIVE_INSURANCE}`]: {
            className: years?.length === 1 && 'widgetXL',
            title: 'damageRatio.comprehensiveInsurance',
            Item: DashboardDamagesRatio,
            damageRatio: dashboardData?.damageRatioComprehensiveInsurance,
        },
        [`${DashboardWidgets.BOOKED_PREMIUM_GENERAL}`]: {
            title: 'premium.general',
            Item: DashboardBookedPremium,
            bookedPremium: dashboardData?.bookedPremiumGeneral,
        },
        [`${DashboardWidgets.BOOKED_PREMIUM_OVERALL}`]: {
            title: 'premium.overall',
            Item: DashboardBookedPremium,
            bookedPremium: dashboardData?.bookedPremiumOverall,
        },
        [`${DashboardWidgets.BOOKED_PREMIUM_LIABILITY}`]: {
            title: 'premium.liability',
            Item: DashboardBookedPremium,
            bookedPremium: dashboardData?.bookedPremiumLiability,
        },
        [`${DashboardWidgets.BOOKED_PREMIUM_COMPREHENSIVE_INSURANCE}`]: {
            title: 'premium.comprehensiveInsurance',
            Item: DashboardBookedPremium,
            bookedPremium: dashboardData?.bookedPremiumComprehensiveInsurance,
        },
        [`${DashboardWidgets.AMOUNT_ACTIVE_CONTRACTS}`]: years?.length === 1 && {
            title: 'contracts.activeAmount',
            Item: DashboardContractsActiveAmount,
            amount: dashboardData?.activeAmount,
        },
        [`${DashboardWidgets.PARTIAL_COVERAGE}`]: {
            title: 'premium.partialCoverage',
            Item: DashboardBookedPremium,
            bookedPremium: dashboardData?.partialCoverage,
        },
        [`${DashboardWidgets.EXPENSE_GENERAL}`]: {
            title: 'expense.general',
            Item: DashboardEsaExpense,
            expense: dashboardData?.expenseGeneral,
        },
        [`${DashboardWidgets.EXPENSE_OVERALL}`]: {
            title: 'expense.overall',
            Item: DashboardEsaExpense,
            expense: dashboardData?.expenseOverall,
        },
        [`${DashboardWidgets.EXPENSE_LIABILITY}`]: {
            title: 'expense.liability',
            Item: DashboardEsaExpense,
            expense: dashboardData?.expenseLiability,
        },
        [`${DashboardWidgets.EXPENSE_COMPREHENSIVE_INSURANCE}`]: {
            title: 'expense.comprehensiveInsurance',
            Item: DashboardEsaExpense,
            expense: dashboardData?.expenseComprehensiveInsurance,
        },
        [`${DashboardWidgets.SUM_YEARLY_NET_PREMIUM}`]: {
            title: 'contracts.sumYearlyNetPremium',
            Item: DashboardContractsYearlyPremium,
            amount: dashboardData?.annualNetPremium,
        },
        [`${DashboardWidgets.TOTAL_RESERVE_ALL_OPEN_CLAIMS}`]: (division ===
            Divisions.GBW ||
            division === Divisions.KFZW) && {
            title: 'expense.reserveOpenDamages',
            Item: DashboardEsaExpenseReserve,
            expense: dashboardData?.expenseReserve,
        },
        [`${DashboardWidgets.OPEN_CLOSED_DAMAGES}`]: {
            title: 'expense.openAndClosedDamages',
            Item: DashboardEsaExpense,
            expense: dashboardData?.openClosedDamages,
        },
        [`${DashboardWidgets.DAMAGES_EXPENSE_BY_MONTH}`]: {
            Item: DashbaordDamageExpenseByMonth,
            className: 'widgetXXL',
            amounts: dashboardData?.expenseSums,
        },
        [`${DashboardWidgets.DAMAGES_COUNT_BY_MONTH}`]: {
            Item: DashboardDamageAmountByMonth,
            className: 'widgetXXL',
            amounts: dashboardData?.damageMonthAmounts,
        },
        [`${DashboardWidgets.DAMAGES_AMOUNT_BY_STATUS}`]: {
            title: 'damages.amountByStatus',
            Item: DashboardDamagesByStatus,
            className: 'widgetXXL',
            amounts: dashboardData?.damageStatusAmounts,
        },
        [`${DashboardWidgets.OPEN_DAMAGES_AMOUNT_BY_STATUS}`]: {
            title: 'damages.amountByState',
            Item: DashboardDamagesByState,
            className: 'widgetXXL',
            amounts: dashboardData?.damageStateAmounts,
        },
    };
};
