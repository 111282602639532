import {
    UpdateUserSettingsFromState,
    UpdateUserSettingsBody,
} from './context-auth-types';

export const authContextUpdateUserSettingsFormatter = (
    data: UpdateUserSettingsFromState
): UpdateUserSettingsBody => {
    const allClientConnectionsChecked = data.clientConnections.find(
        (clientConnection) => clientConnection.value === 'all'
    )?.checked;
    const allClientsChecked = data.clients.find(
        (client) => client.value === 'all'
    )?.checked;

    return {
        customerConnections: data.clientConnections
            .filter((item) =>
                allClientConnectionsChecked
                    ? item.value !== 'all'
                    : item.checked && item.value !== 'all'
            )
            .map((item) => {
                return item.value;
            }),
        customers: data.clients
            .filter((item) =>
                allClientsChecked
                    ? item.value !== 'all'
                    : item.checked && item.value !== 'all'
            )
            .map((item) => {
                return item.value;
            }),
        allCustomerConnections: allClientConnectionsChecked,
        allCustomers: allClientsChecked,
        defaultDivision: data.defaultDivision,
        darkTheme: data.darkMode,
        divisionWidgets: data.divisions
            .sort((a, b) => a.position - b.position)
            .map((item) => {
                return {
                    division: item.value,
                    favourite: item.favourite,
                };
            }),
        favouriteDatabase: data.favouriteDatabase,
        currentDatabase: data.currentDatabase,
    };
};
