import React, { useMemo } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { AnyAbility } from '@casl/ability';
import { AbilityActions, AbilitySubjects, useAbilityCtx } from 'src/roleAccesses';

import { IThemeTab, ThemeTabs } from 'src/theming';
import { APP_ROUTES } from 'src/routing';
import {
    isSGIFFOXXKInstance,
    isHectorInstance,
    isAonMotorInstance,
    isAonDigitalInstance,
    isCeatInstance,
    isHectorDemoInstance,
} from 'src/environment';

interface UserManagementNavigationPanelProps {
    activeMenu?: string;
}

const generateUserManagementNavigationPanel = (
    t: TFunction,
    ability: AnyAbility
): IThemeTab[] => {
    const userManagementNavigationPanel = [
        {
            title: t('umSection.nav.um'),
            value: 'user-management',
            path: APP_ROUTES.USER_MANAGEMENT,
        },
    ];
    if (ability.can(AbilityActions.see, AbilitySubjects.umMasterData)) {
        userManagementNavigationPanel.push({
            title: t('umSection.nav.masterData'),
            value: 'master-data',
            path: APP_ROUTES.MASTER_DATA,
        });
    }
    if (ability.can(AbilityActions.see, AbilitySubjects.umNotifications)) {
        userManagementNavigationPanel.push({
            title: t('umSection.nav.notifications'),
            value: 'notifications',
            path: APP_ROUTES.NOTIFICATIONS,
        });
    }
    return userManagementNavigationPanel;
};

export const UserManagementNavigationPanel = (
    props: UserManagementNavigationPanelProps
): JSX.Element => {
    const { activeMenu } = props;
    const { t } = useTranslation(['user-management']);
    const ability = useAbilityCtx();

    const tabs = useMemo(
        () => generateUserManagementNavigationPanel(t, ability),
        [t, ability]
    );

    return (
        <ThemeTabs
            isAonMotorStyles={
                isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance
            }
            isHectorStyles={isHectorInstance || isCeatInstance}
            isSgiffoxxkStyles={isSGIFFOXXKInstance}
            tabs={tabs}
            activeTab={activeMenu}
        />
    );
};
