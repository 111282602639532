import {
    IClient,
    IClientConnections,
    IDivision,
    WithBranchFormCard,
} from './user-edit-form-types';
import { CheckboxItem, SelectItem } from 'src/theming';

type DataForCardWithBranches = {
    filteredClientConnections: SelectItem[];
    clients: CheckboxItem[];
    divisions: CheckboxItem[];
    clientConnectionValue: string;
};

export const userEditFormBuildDataForCardWithBranches = (
    initialStateOfCardsWithBranches: IClientConnections[] | void,
    cardsWithBranches: WithBranchFormCard[] | void,
    initialClientConnectionValue?: string,
    chosenClientConnectionValues?: IClientConnections,
    filteredCardState?: WithBranchFormCard
): DataForCardWithBranches => {
    if (Array.isArray(initialStateOfCardsWithBranches)) {
        const filteredClientConnections = [];
        const clients: CheckboxItem[] = filteredCardState?.clients || [];
        const divisions = filteredCardState?.divisions || [];
        initialStateOfCardsWithBranches.forEach((item) => {
            if (!cardsWithBranches) {
                filteredClientConnections.push({
                    title: item.customerConnection,
                    value: item.id,
                });
            } else {
                const isClientConnectionAlreadyInUse = cardsWithBranches.find(
                    (card) => card.clientConnectionValue === item.id
                );
                if (!isClientConnectionAlreadyInUse) {
                    filteredClientConnections.push({
                        title: item.customerConnection,
                        value: item.id,
                    });
                }
            }
        });
        const clientConnectionValue =
            initialClientConnectionValue || filteredClientConnections[0]?.value || '';
        initialStateOfCardsWithBranches.forEach((item: IClientConnections) => {
            if (item.id === clientConnectionValue) {
                if (clients.length === 0) {
                    clients.push({
                        title: 'common:all',
                        value: 'all',
                        checked: false,
                        data: [],
                        labelAsKeyForTFunction: true,
                    });
                    item.customers?.forEach((customer: IClient) => {
                        clients.push({
                            title: `${customer.customer} 
                            ${
                                customer.customerName2
                                    ? ' - ' + customer.customerName2
                                    : ''
                            } ${
                                customer.customerName3
                                    ? ' - ' + customer.customerName3
                                    : ''
                            }`,
                            value: customer.id,
                            checked: Boolean(
                                chosenClientConnectionValues?.customers?.find(
                                    (chosenCustomer: IClient) =>
                                        chosenCustomer.id === customer.id
                                )
                            ),
                            data: customer,
                        });
                    });
                }
                if (divisions.length === 0) {
                    divisions.push({
                        title: 'common:all',
                        value: 'all',
                        checked: false,
                        data: [],
                        labelAsKeyForTFunction: true,
                    });
                    item.divisions?.forEach((division: IDivision) => {
                        divisions.push({
                            title: division.division,
                            value: division.id,
                            checked: Boolean(
                                chosenClientConnectionValues?.divisions?.find(
                                    (chosenDivision: IDivision) =>
                                        chosenDivision.id === division.id
                                )
                            ),
                            data: division,
                        });
                    });
                }
            }
        });
        return {
            filteredClientConnections,
            clients,
            divisions,
            clientConnectionValue,
        };
    }
};
