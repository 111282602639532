import React, { ReactElement } from 'react';

interface HectorLogoProps {
    color?: string;
}

export const HectorLogo = ({ color }: HectorLogoProps): ReactElement => {
    return (
        <svg
            version='1.0'
            xmlns='http://www.w3.org/2000/svg'
            width='1000.000000pt'
            height='196.000000pt'
            viewBox='0 0 1000.000000 196.000000'
            preserveAspectRatio='xMidYMid meet'
        >
            <g
                transform='translate(0.000000,196.000000) scale(0.100000,-0.100000)'
                fill={color}
                stroke='none'
            >
                <path
                    d='M10 975 l0 -975 145 0 145 0 0 550 0 550 410 0 410 0 0 -550 0 -550
    150 0 150 0 0 700 0 700 -560 0 -560 0 0 275 0 275 -145 0 -145 0 0 -975z'
                />
                <path
                    d='M5480 975 l0 -975 575 0 575 0 0 145 0 145 -425 0 -425 0 0 405 0
    405 425 0 425 0 0 150 0 150 -425 0 -425 0 0 275 0 275 -150 0 -150 0 0 -975z'
                />
                <path d='M1830 1250 l0 -150 710 0 710 0 0 150 0 150 -710 0 -710 0 0 -150z' />
                <path
                    d='M3650 700 l0 -700 710 0 710 0 0 145 0 145 -560 0 -560 0 0 405 0
    405 560 0 560 0 0 150 0 150 -710 0 -710 0 0 -700z'
                />
                <path
                    d='M7030 700 l0 -700 710 0 710 0 0 700 0 700 -710 0 -710 0 0 -700z
    m1120 -5 l0 -405 -410 0 -410 0 0 405 0 405 410 0 410 0 0 -405z'
                />
                <path
                    d='M8860 700 l0 -700 150 0 150 0 0 550 0 550 420 0 420 0 0 150 0 150
    -570 0 -570 0 0 -700z'
                />
                <path d='M1830 695 l0 -145 710 0 710 0 0 145 0 145 -710 0 -710 0 0 -145z' />
                <path d='M1830 145 l0 -145 710 0 710 0 0 145 0 145 -710 0 -710 0 0 -145z' />
            </g>
        </svg>
    );
};

HectorLogo.defaultProps = {
    color: '#000000',
};
