import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { GridSortModel, GridSortModelParams } from '@material-ui/data-grid';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useParams } from 'react-router-dom';
import qs from 'querystring';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import moment from 'moment';

import {
    Grid,
    Paper,
    Typography,
    Link,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';

import AttachFileIcon from '@material-ui/icons/AttachFile';

import { ContractUploadDocumentForm } from 'src/contracts/contracts-upload-documents-form/contracts-upload-documents-form';

import { ThemeTable } from 'src/theming';
import { useDebounce } from 'src/lib/custom-hooks/use-debounce';

import { usePaperStyle, useStyle } from './contracts-details-view-styles';

import { generateContractDetailsViewTableHeader } from './contract-details-view-configs';

import { ContractDetailsViewDocumentsResponseBody } from './contract-details-view-documents-response-body';

import { getUploadDocumentDescription } from './get-upload-document-description';

type ContractsDetailsViewDocumentsForm = {
    title: string;
    date: ParsableDate;
    sortModel: GridSortModel;
    hideUploadDocument: boolean;
};

interface ContractsDetailsViewDocumentsProps {
    hideUploadDocument: boolean;
    documentFormats: string[];
    contractId: string;
}

export const ContractsDetailsViewDocuments = ({
    hideUploadDocument,
    documentFormats,
    contractId,
}: ContractsDetailsViewDocumentsProps): JSX.Element => {
    const theme = useTheme();
    const matchUpLargeSize = useMediaQuery(theme.breakpoints.up('lg'));
    const classes = useStyle();
    const paperClasses = usePaperStyle();
    const { t } = useTranslation(['contract-details-view']);
    const httpClient = useHttpClient();

    const { id } = useParams<{ id: string }>();

    const [isOpen, setOpen] = useState(false);
    const [isGetDocumentsLoading, setGetDocumentsLoading] = useState(false);
    const [documents, setDocuments] = useState(null);

    const { watch, setValue } = useForm<ContractsDetailsViewDocumentsForm>({
        mode: 'onChange',
        defaultValues: { title: '', date: '' },
    });

    const [title, date, sortModel] = watch(['title', 'date', 'sortModel']);

    const debouncedTitle = useDebounce(title);
    const debouncedDate = useDebounce(date);

    const makeRequestToGetListOfDocuments = async (): Promise<void> => {
        setGetDocumentsLoading(true);
        const queryParams: {
            titleSearchTerm?: string;
            searchDate?: string;
            sortField?: string;
            isAscendingSortOrder?: boolean;
        } = {};
        if (debouncedTitle) {
            queryParams.titleSearchTerm = debouncedTitle;
        }
        if (debouncedDate) {
            queryParams.searchDate = moment(debouncedDate).toISOString();
        }
        if (sortModel && sortModel.length !== 0) {
            queryParams.sortField = sortModel[0].field;
            queryParams.isAscendingSortOrder = sortModel[0].sort === 'asc';
        }
        try {
            const data = await httpClient.get<ContractDetailsViewDocumentsResponseBody[]>(
                `contracts/${id}/documents?${qs.stringify(queryParams)}`
            );
            setDocuments(
                data?.map((item) => ({
                    id: item.amsIdNr,
                    title: item.title,
                    date: item.date,
                    type: item.type,
                }))
            );
        } finally {
            setGetDocumentsLoading(false);
        }
    };

    useEffect(() => {
        makeRequestToGetListOfDocuments();
    }, [debouncedTitle, debouncedDate, sortModel]);

    const handleChangeTableSearchField = useCallback(
        (e): void => {
            setValue(e.target.name, e.target.value);
        },
        [setValue]
    );

    const handleChangeDate = useCallback(
        (name, value) => {
            setValue(name, value);
        },
        [setValue]
    );

    const handleChangeSort = useCallback(
        (sortOrderParams: GridSortModelParams): void => {
            setValue('sortModel', sortOrderParams.sortModel);
        },
        [setValue]
    );

    const handleOpenModalWindow = (): void => {
        setOpen(true);
    };

    const handleCloseModalWindow = (): void => {
        setOpen(false);
        makeRequestToGetListOfDocuments();
    };

    const onRemoveSuccess = useCallback((): void => {
        makeRequestToGetListOfDocuments();
    }, []);

    const columns = useMemo(
        () =>
            generateContractDetailsViewTableHeader(
                t,
                title,
                date,
                handleChangeTableSearchField,
                handleChangeDate,
                classes.dateSearchFieldClass,
                (letterId) =>
                    httpClient.download(
                        `download-contracts/${contractId}/attachments/${letterId}`
                    ),
                onRemoveSuccess,
                matchUpLargeSize
            ),
        [
            t,
            title,
            date,
            handleChangeTableSearchField,
            classes,
            handleChangeDate,
            contractId,
            onRemoveSuccess,
            matchUpLargeSize,
        ]
    );

    return (
        <>
            {/* {isGetDocumentsLoading && <ThemeLoader />} */}
            <Paper classes={paperClasses}>
                <Grid container>
                    <Typography
                        variant='h6'
                        className={clsx(classes.cardTitle, classes.halfSize)}
                    >
                        {t('contract-details-view:documents.title')}
                    </Typography>
                    {!hideUploadDocument && (
                        <Link
                            className={clsx(classes.halfSize, classes.documentLink)}
                            onClick={handleOpenModalWindow}
                        >
                            <AttachFileIcon />
                            {t('contract-details-view:documents.upload')}
                        </Link>
                    )}
                </Grid>
                <ThemeTable
                    isLoading={isGetDocumentsLoading}
                    dataLoadingMessage={t('contract-details-view:damages.dataLoading')}
                    withoutPagination
                    columns={columns}
                    rows={documents}
                    sortModel={sortModel}
                    handleChangeSort={handleChangeSort}
                    noRowsMessage={t('contract-details-view:documents.noDocuments')}
                />
                <ContractUploadDocumentForm
                    isOpen={isOpen}
                    documentFormats={documentFormats}
                    handleCloseModalWindow={handleCloseModalWindow}
                    pathForRequest={`contracts/${id}/upload-documents`}
                    formTitle={t(
                        'contract-details-view:contractDetails.uploadDocument.title'
                    )}
                    formDescription={t(getUploadDocumentDescription())}
                    dragAndDropLabel={t(
                        'contract-details-view:contractDetails.uploadDocument.dragAndDropLabel'
                    )}
                />
            </Paper>
        </>
    );
};
