import { Box, Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import React, { BaseSyntheticEvent, memo } from 'react';
import { ThemeCircularProgress, ThemeFilesPanel } from 'src/theming';

import { DamageReportFormAttachmentsField } from './damage-report-form-items/damage-report-form-attachments-field';
import { FileObject } from 'material-ui-dropzone';
import { DamageReportFormConfigGBWWReturnType } from '../damage-report-form-config/damage-report-form-config-GBW-HR-GESCH';
import { DamageReportFormConfigGenerator } from '../damage-report-form-config/damage-report-form-config-generator';

interface DamageReportFormFooterProps {
    containerClassName: string;
    saveButtonClass: string;
    sendButtonClass: string;
    deleteText: string;
    IsSaveDisabled: boolean;
    isSendDisabled: boolean;
    isSaveLoading: boolean;
    isSendingToAmsLoading: boolean;
    saveText: string;
    sendText: string;
    cancelText: string;
    deleteButtonClass: string;
    handleCancel: () => void;
    files: FileObject[];
    fileInfos: { id: string; fileName: string }[];
    onDeleteFile: (id: string) => void;
    handleAddFile: (newFiles: FileObject[]) => void;
    handleDeleteFile: (index: number) => void;
    sectionContainer: string;
    footerConfig: DamageReportFormConfigGBWWReturnType[];
    handleChangeValue: (e: BaseSyntheticEvent) => void;
    formBlockContainer: string;
    boxContainer: string;
    hideButtons: boolean;
    handleSaveReport: () => void;
    setDeleteConfirmOpen: (value: boolean) => void;
}

export const DamageReportFormFooter = memo(
    ({
        hideButtons,
        containerClassName,
        isSendDisabled,
        IsSaveDisabled,
        isSaveLoading,
        saveButtonClass,
        saveText,
        cancelText,
        isSendingToAmsLoading,
        handleCancel,
        sendText,
        sendButtonClass,
        files,
        fileInfos,
        handleAddFile,
        handleDeleteFile,
        sectionContainer,
        footerConfig,
        handleChangeValue,
        formBlockContainer,
        boxContainer,
        handleSaveReport,
        onDeleteFile,
        deleteButtonClass,
        deleteText,
        setDeleteConfirmOpen,
    }: DamageReportFormFooterProps) => {
        const { t } = useTranslation(['common']);
        const { id } = useParams<{ id: string }>();
        return (
            <Grid container className={sectionContainer}>
                <div className={formBlockContainer}>
                    <DamageReportFormConfigGenerator
                        readonly={hideButtons}
                        config={footerConfig}
                        handleChangeValue={handleChangeValue}
                        formBlockElementClass={formBlockContainer}
                        containerClassName=''
                    />
                    {Array.isArray(fileInfos) && fileInfos.length > 0 && (
                        <Box pb={4} pt={4}>
                            <ThemeFilesPanel
                                route='damage-reports'
                                routeId={id}
                                onDeleteFile={onDeleteFile}
                                title={t('documents')}
                                readonly={hideButtons}
                                files={fileInfos}
                            />
                        </Box>
                    )}
                    {!hideButtons && (
                        <>
                            <div
                                className={containerClassName}
                                style={{ width: '100%', marginBottom: '20px' }}
                            >
                                <Box mt={4}>
                                    <DamageReportFormAttachmentsField
                                        files={files}
                                        handleAddFile={handleAddFile}
                                        handleDeleteFile={handleDeleteFile}
                                    />
                                </Box>
                            </div>
                            <div className={boxContainer}>
                                <Box mr={4}>
                                    <Button
                                        onClick={handleSaveReport}
                                        disabled={IsSaveDisabled}
                                        className={saveButtonClass}
                                        startIcon={
                                            <ThemeCircularProgress
                                                isLoading={isSaveLoading}
                                            />
                                        }
                                    >
                                        {saveText}
                                    </Button>
                                </Box>
                                {id && (
                                    <Box mr={4}>
                                        <Button
                                            className={deleteButtonClass}
                                            disabled={
                                                isSaveLoading || isSendingToAmsLoading
                                            }
                                            variant='outlined'
                                            onClick={() => setDeleteConfirmOpen(true)}
                                        >
                                            {deleteText}
                                        </Button>
                                    </Box>
                                )}
                                <Button
                                    disabled={isSendingToAmsLoading || isSaveLoading}
                                    variant='outlined'
                                    onClick={handleCancel}
                                >
                                    {cancelText}
                                </Button>
                                <div style={{ flexGrow: 1 }} />
                                <Button
                                    type='submit'
                                    startIcon={
                                        <ThemeCircularProgress
                                            isLoading={isSendingToAmsLoading}
                                        />
                                    }
                                    disabled={isSendDisabled}
                                    className={sendButtonClass}
                                >
                                    {sendText}
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </Grid>
        );
    }
);
