import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { DarkMode } from 'use-dark-mode';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import {
    Button,
    FormControl,
    FormControlLabel,
    OutlinedInput,
    Typography,
} from '@material-ui/core';

import {
    useStyle,
    useFormControlLabelStyles,
    useFormControlStyles,
    useInputStyles,
} from '../styles';

import { ThemeCircularProgress } from 'src/theming';

import { useDebounce } from 'src/lib/custom-hooks/use-debounce';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import PasswordStrengthBar from 'src/lib/password-strength-bar/password-strength-bar';

import { changePasswordSchema } from './change-password-form-validation-schema';

interface ChangePasswordFormProps {
    darkMode: DarkMode;
}

type ChangePasswordFormData = {
    oldPassword: string;
    newPassword: string;
    repeatPassword: string;
};

export const ChangePasswordForm = (props: ChangePasswordFormProps): JSX.Element => {
    const { darkMode } = props;

    const classes = useStyle({ isDarkMode: darkMode.value });
    const inputClasses = useInputStyles();
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();
    const { t } = useTranslation(['user-management', 'common', 'password']);

    const httpClient = useHttpClient();
    const { enqueueSnackbar } = useSnackbar();

    const [isStrong, setIsStrong] = useState(false);
    const [isRequestLoading, setRequestLoading] = useState(false);

    const {
        watch,
        register,
        handleSubmit,
        formState: { isValid, errors },
        reset,
    } = useForm<ChangePasswordFormData>({
        mode: 'onChange',
        resolver: yupResolver(changePasswordSchema),
    });

    const onSubmit = async (vals: ChangePasswordFormData): Promise<void> => {
        try {
            setRequestLoading(true);
            await httpClient.put('users/change-password', {
                oldPassword: vals.oldPassword,
                newPassword: vals.newPassword,
            });
            setRequestLoading(false);
            enqueueSnackbar(
                t('user-management:successResponses.passwordChangedSuccessfully'),
                { variant: 'success' }
            );
            reset({});
        } catch (err) {
            setRequestLoading(false);
            enqueueSnackbar(
                t(
                    `user-management:errorResponses.${err.error.response.data.errors.oldPassword[0]}`
                ),
                { variant: 'error' }
            );
        }
    };

    const handleSetStrong = useCallback((value) => {
        setIsStrong(value);
    }, []);

    const password = watch('newPassword');
    const debouncedPassword = useDebounce(password, 400);

    return (
        <form
            autoComplete='off'
            onSubmit={handleSubmit(onSubmit)}
            className={classes.formContainer}
        >
            <Typography variant='h3' className={classes.formTitle}>
                {t('changePassword')}
            </Typography>
            <Typography variant='body1' className={classes.formMessage}>
                {t('changePasswordMessage')}
            </Typography>
            <hr style={{ width: '100%' }} />
            <FormControl
                error={Boolean(errors?.oldPassword)}
                fullWidth
                classes={formControlClasses}
                variant='outlined'
            >
                <FormControlLabel
                    classes={formControlLabelClasses}
                    control={
                        <OutlinedInput
                            type='password'
                            placeholder={t('placeholders.enterOldPassword')}
                            classes={inputClasses}
                            {...register('oldPassword')}
                        />
                    }
                    label={t('fields.oldPassword')}
                    labelPlacement='top'
                />
            </FormControl>
            <FormControl
                error={Boolean(errors?.newPassword)}
                fullWidth
                classes={formControlClasses}
                variant='outlined'
            >
                <FormControlLabel
                    classes={formControlLabelClasses}
                    control={
                        <OutlinedInput
                            type='password'
                            placeholder={t('placeholders.enterNewPassword')}
                            classes={inputClasses}
                            {...register('newPassword')}
                        />
                    }
                    label={t('fields.password')}
                    labelPlacement='top'
                />
            </FormControl>
            <PasswordStrengthBar
                className={classes.strengthBar}
                password={debouncedPassword}
                onStrong={handleSetStrong}
                pathToCheckPassword='users/check-password-strength'
            />
            <FormControl
                error={Boolean(errors?.repeatPassword)}
                fullWidth
                classes={formControlClasses}
                variant='outlined'
            >
                <FormControlLabel
                    classes={formControlLabelClasses}
                    control={
                        <OutlinedInput
                            type='password'
                            placeholder={t('placeholders.repeatNewPassword')}
                            classes={inputClasses}
                            {...register('repeatPassword')}
                        />
                    }
                    label={t('fields.confirmPassword')}
                    labelPlacement='top'
                />
            </FormControl>
            <Button
                disableElevation
                disabled={!isValid || !isStrong || isRequestLoading}
                className={classes.submitButton}
                startIcon={<ThemeCircularProgress isLoading={isRequestLoading} />}
                variant='contained'
                type='submit'
            >
                {t('changePassword')}
            </Button>
        </form>
    );
};
