import React from 'react';
import { useTranslation } from 'react-i18next';
import { ResponsivePie } from '@nivo/pie';

import { useDashboardContext } from 'src/shared/contexts';
import LoadingIndicator from 'src/shared/loading-indicator';
import { NivoTheme } from 'src/theming/theme-nivo/nivo-theme-context';

import { EsaStatusAmounts } from '../common';
import { DashboardCard } from '../dashboard-card/dashboard-card';

function mapAmountsToCakeData(
    amounts: EsaStatusAmounts
): { id: string; label: string; value: number }[] | undefined | null {
    if (amounts == null) {
        return undefined;
    }

    const data = [];

    if (amounts.openAmount > 0) {
        data.push({
            id: 'openAmount',
            label: 'Offen',
            value: amounts.openAmount,
        });
    }

    if (amounts.closedAmount > 0) {
        data.push({
            id: 'closedAmount',
            label: 'Geschlossen',
            value: amounts.closedAmount,
        });
    }

    if (amounts.unknownAmount > 0) {
        data.push({
            id: 'unknownAmount',
            label: 'Unbekannt',
            value: amounts.unknownAmount,
        });
    }

    return data.length > 0 ? data : null;
}

interface Props {
    nivoTheme: NivoTheme;
    title: string;
    editMode: boolean;
    amounts: EsaStatusAmounts;
    isShown?: boolean;
    handleChangeShown?: (name: string, value: boolean) => void;
    name?: string;
}

export const DashboardDamagesByStatus = ({
    nivoTheme,
    title,
    editMode,
    isShown,
    handleChangeShown,
    name,
    amounts,
}: Props): JSX.Element => {
    const { t } = useTranslation(['dashboard']);

    const { isGetDashboardDataLoading } = useDashboardContext();

    const data = mapAmountsToCakeData(amounts);

    return (
        <DashboardCard
            header={title}
            subHeader={t('dashboard:damages.lastDamage')}
            editMode={editMode}
            isShown={isShown}
            handleChangeShown={handleChangeShown}
            name={name}
        >
            {isGetDashboardDataLoading && <LoadingIndicator />}
            {!isGetDashboardDataLoading && data === null && (
                <div
                    style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    {t('emptyMsg')}
                </div>
            )}
            {!isGetDashboardDataLoading && data && (
                <ResponsivePie
                    data={data}
                    theme={nivoTheme.theme}
                    colors={nivoTheme.themedColors}
                    isInteractive={false}
                    margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    innerRadius={0.2}
                    padAngle={10}
                    arcLabelsTextColor={{ theme: 'textColor' }}
                    arcLabelsRadiusOffset={0.7}
                    arcLinkLabel='label'
                    arcLinkLabelsColor={{ from: 'color' }}
                />
            )}
        </DashboardCard>
    );
};
