export enum LIGHT_AON_MOTOR_THEME_COLORS {
    PRIMARY_MAIN_COLOR = '#F9FCFC',
    PRIMARY_LIGHT_COLOR = '#FFFFFF',
    PRIMARY_DARK_COLOR = '#D6D6D6',
    PRIMARY_CONTRAST_TEXT = '#46535E',

    SECONDARY_MAIN_COLOR = '#ACC0C4',
    SECONDARY_LIGHT_COLOR = '#E5EFF0',
    SECONDARY_DARK_COLOR = '#007585',
    SECONDARY_CONTRAST_TEXT = '#FFFFFF',

    INFO_MAIN_COLOR = '#007585',
    INFO_LIGHT_COLOR = '#007585',
    INFO_DARK_COLOR = '#007585',
    INFO_CONTRAST_TEXT = '#FFFFFF',

    ERROR_MAIN_COLOR = '#EB0017',
    ERROR_LIGHT_COLOR = 'rgba(203, 54, 45, 0.1)',
}

export enum DARK_AON_MOTOR_THEME_COLORS {
    PRIMARY_MAIN_COLOR = '#6C757D',
    PRIMARY_LIGHT_COLOR = '#ACC0C4',
    PRIMARY_DARK_COLOR = '#46535E',
    PRIMARY_CONTRAST_TEXT = '#FFFFFF',

    SECONDARY_MAIN_COLOR = '#ACC0C4',
    SECONDARY_LIGHT_COLOR = '#E5EFF0',
    SECONDARY_DARK_COLOR = '#007585',
    SECONDARY_CONTRAST_TEXT = '#FFFFFF',

    INFO_MAIN_COLOR = '#007585',
    INFO_LIGHT_COLOR = '#007585',
    INFO_DARK_COLOR = '#007585',
    INFO_CONTRAST_TEXT = '#FFFFFF',

    ERROR_MAIN_COLOR = '#EB0017',
    ERROR_LIGHT_COLOR = 'rgba(203, 54, 45, 0.1)',
}
