import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { appInstance } from 'src/environment';
import { AppInstances } from 'src/shared/constants';
import { useThemeHeaderAonStyle } from './theme-header-aon-styles';
import { useThemeHeaderHectorStyle } from './theme-header-hector-styles';
import { useThemeHeaderSgiffoxxkStyle } from './theme-header-sgiffoxxk-styles';
import { useThemeHeaderAonMotorStyle } from './theme-header-aon-motor-styles';

const instanceHeaderThemeStylesHooks = {
    [AppInstances.AON]: useThemeHeaderAonStyle,
    [AppInstances.AON_KOMPOSIT]: useThemeHeaderAonStyle,
    [AppInstances.HECTOR]: useThemeHeaderHectorStyle,
    [AppInstances.CEAT]: useThemeHeaderHectorStyle,
    [AppInstances.SGIFFOXXK]: useThemeHeaderSgiffoxxkStyle,
    [AppInstances.AON_MOTOR]: useThemeHeaderAonMotorStyle,
    [AppInstances.AON_DIGITAL]: useThemeHeaderAonMotorStyle,
    [AppInstances.HECTOR_DEMO]: useThemeHeaderAonMotorStyle,
};

export const useStyle = (): ClassNameMap => {
    const useThemeHeaderCurrentStyles =
        instanceHeaderThemeStylesHooks[appInstance] || useThemeHeaderAonStyle;
    return useThemeHeaderCurrentStyles();
};
