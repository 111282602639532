import { TFunction } from 'i18next';
import { SelectItem } from 'src/theming';

export enum DamageTypes {
    TRANSPORT_ACCIDENT = 'Transport accident',
    CARGO_SHIFT = 'Cargo shift',
    ERROR_AMOUNT = 'Error amount',
    COOLING_DAMAGE = 'Cooling damage',
    OTHER = 'other',
}

export const damageReportFormGenerateTRANSPDamageTypeOptions = (
    t: TFunction
): SelectItem[] => {
    return [
        {
            title: t('damages-report:noChoice'),
            value: 'No choice',
        },
        {
            title: t('damages-report:damageTypeOptions.transportationAccident'),
            value: DamageTypes.TRANSPORT_ACCIDENT,
        },
        {
            title: t('damages-report:damageTypeOptions.cargoShift'),
            value: DamageTypes.CARGO_SHIFT,
        },
        {
            title: t('damages-report:damageTypeOptions.shortage'),
            value: DamageTypes.ERROR_AMOUNT,
        },
        {
            title: t('damages-report:damageTypeOptions.coolingDamage'),
            value: DamageTypes.COOLING_DAMAGE,
        },
        {
            title: t('damages-report:damageTypeOptions.other'),
            value: DamageTypes.OTHER,
        },
    ];
};
