import React, { SyntheticEvent, useEffect, useState, Ref } from 'react';

import {
    IconButton,
    OutlinedInput,
    Theme,
    makeStyles,
    Typography,
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { isSGIFFOXXKInstance } from 'src/environment';

interface StyleProps {
    leftSeparation: boolean;
    rightSeparation: boolean;
    value: string;
}

const useStyle = makeStyles((theme: Theme) => {
    if (isSGIFFOXXKInstance) {
        return {
            title: {
                maxWidth: 130,
                paddingTop: 1,
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                margin: 0,
                fontWeight: theme.typography.fontWeightMedium,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                borderLeft: ({ leftSeparation }: StyleProps) =>
                    leftSeparation ? '1px solid white' : 'none',
                borderRight: ({ rightSeparation }: StyleProps) =>
                    rightSeparation ? '1px solid white' : 'none',
            },
            input: {
                height: 35,
                '& input': {
                    caretColor: theme.palette.info.contrastText,
                    color: theme.palette.info.contrastText,
                },
                '& fieldset': {
                    borderColor: `${theme.palette.secondary.contrastText}!important`,
                },
                '&.Mui-focused': {
                    '& fieldset': {
                        borderColor: `${theme.palette.secondary.contrastText}!important`,
                    },
                },
            },
            searchIcon: {
                width: 18,
                height: 18,
                color: ({ value }: StyleProps) =>
                    value?.length > 0
                        ? theme.palette.info.main
                        : theme.palette.info.contrastText,
            },
            container: {
                display: 'flex',
            },
        };
    }
    return {
        title: {
            maxWidth: 130,
            paddingTop: 1,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            margin: 0,
            fontWeight: theme.typography.fontWeightMedium,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            borderLeft: ({ leftSeparation }: StyleProps) =>
                leftSeparation ? '1px solid white' : 'none',
            borderRight: ({ rightSeparation }: StyleProps) =>
                rightSeparation ? '1px solid white' : 'none',
        },
        input: {
            height: 35,
            '& input': {
                caretColor: theme.palette.info.contrastText,
                color: theme.palette.info.contrastText,
            },
            '& fieldset': {
                borderColor: `${theme.palette.info.contrastText}!important`,
            },
            '&.Mui-focused': {
                '& fieldset': {
                    borderColor: `${theme.palette.info.main}!important`,
                },
            },
        },
        searchIcon: {
            width: 18,
            height: 18,
            color: ({ value }: StyleProps) =>
                value?.length > 0
                    ? theme.palette.info.main
                    : theme.palette.info.contrastText,
        },
        container: {
            display: 'flex',
        },
    };
});

interface CellWithSearchProps {
    title: string;
    value: string;
    handleChange: (e: SyntheticEvent) => void;
    name?: string;
    innerRef?: Ref<HTMLDivElement>;
    isDragDisabled?: boolean;
    leftSeparation?: boolean;
    rightSeparation?: boolean;
    style?: CSSProperties;
}

export const CellWithSearch = (props: CellWithSearchProps): JSX.Element => {
    const {
        title,
        value,
        handleChange,
        name,
        innerRef,
        isDragDisabled,
        leftSeparation,
        rightSeparation,
        style,
        ...rest
    } = props;
    const classes = useStyle({ value, leftSeparation, rightSeparation });

    const [isSearchMode, setSearchMode] = useState(false);

    useEffect(() => {
        window.addEventListener('mousedown', handleCloseSearch);
        return () => window.removeEventListener('mousedown', handleCloseSearch);
    }, []);

    const handleOpenSearch = (e: SyntheticEvent): void => {
        e.preventDefault();
        e.stopPropagation();
        setSearchMode(true);
    };

    const handleCloseSearch = (): void => {
        setSearchMode(false);
    };

    const handleSearchStopBubbling = (e: SyntheticEvent): void => {
        e.stopPropagation();
    };

    return isSearchMode && isDragDisabled ? (
        <OutlinedInput
            autoComplete='off'
            color='secondary'
            className={classes.input}
            onMouseDown={handleSearchStopBubbling}
            onClick={handleSearchStopBubbling}
            value={value}
            onChange={handleChange}
            name={name}
        />
    ) : (
        <div className={classes.container} ref={innerRef} {...rest} style={style}>
            {isDragDisabled && (
                <IconButton size='small' onClick={handleOpenSearch}>
                    <SearchIcon className={classes.searchIcon} />
                </IconButton>
            )}
            <Typography variant='body2' component='span' className={classes.title}>
                {title}
            </Typography>
        </div>
    );
};
