import { AbilityActions, AbilitySubjects } from '../constants';

import { clerkRoleAccessGenerator } from './clerkRoleAccessGenerator';

// A Clerk plus can everything what can a simple clerk with additional abilities

export function clerkPlusRoleAccessGenerator(
    can: (actions: string | string[], subjects: string | string[]) => void
): void {
    clerkRoleAccessGenerator(can);

    can(AbilityActions.use, AbilitySubjects.damageLink);
}
