import React, { BaseSyntheticEvent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormControlLabel, Grid, GridSize } from '@material-ui/core';

import { SelectItem, ThemeSelectInput } from 'src/theming';

import {
    useFormControlLabelStyles,
    useFormControlStyles,
} from '../contracts-create-contract-form-styles';

interface DamageReportFormSelectProps {
    value: string | string[];
    handleChangeValue: (name, value) => void;
    label: string;
    items: SelectItem[];
    name: string;
    disabled?: boolean;
    md?: GridSize;
    error?: boolean;
}

export const CreateContractSelectField = memo(
    ({
        handleChangeValue,
        value,
        items,
        label,
        name,
        disabled,
        md,
        error,
    }: DamageReportFormSelectProps): JSX.Element => {
        const formControlClasses = useFormControlStyles();
        const formControlLabelClasses = useFormControlLabelStyles();

        const { t } = useTranslation(['create-contract']);

        const onChange = (e: BaseSyntheticEvent): void => {
            handleChangeValue(e?.target?.name, e?.target?.value);
        };

        return (
            <Grid item md={md}>
                <FormControl
                    fullWidth
                    classes={formControlClasses}
                    variant='outlined'
                    error={error}
                >
                    <FormControlLabel
                        classes={formControlLabelClasses}
                        control={
                            <ThemeSelectInput
                                editMode={!disabled}
                                value={value}
                                name={name}
                                onChange={onChange}
                                items={items}
                            />
                        }
                        label={t(label)}
                        labelPlacement='top'
                    />
                </FormControl>
            </Grid>
        );
    }
);
