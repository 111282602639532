import React, { memo } from 'react';
import clsx from 'clsx';
import { FormControl, FormControlLabel, Grid } from '@material-ui/core';
import {
    useFormControlLabelStyles,
    useFormControlStyles,
    useStyle,
} from '../damage-report-form-components-styled';
import { ThemePhoneNumberInput } from '../../../../theming';

interface DamageReportFormPhoneInputProps {
    value: string;
    handleChangeValue: () => void;
    label: string;
    name: string;
    disabled?: boolean;
}

export const DamageReportFormPhoneInput = memo(
    ({
        handleChangeValue,
        value,
        label,
        name,
        disabled,
    }: DamageReportFormPhoneInputProps) => {
        const classes = useStyle();
        const formControlClasses = useFormControlStyles();
        const formControlLabelClasses = useFormControlLabelStyles();
        return (
            <Grid item className={clsx(classes.thirdPart)}>
                <FormControl fullWidth classes={formControlClasses} variant='outlined'>
                    <FormControlLabel
                        classes={formControlLabelClasses}
                        control={
                            <ThemePhoneNumberInput
                                disabled={disabled}
                                value={value}
                                onChange={handleChangeValue}
                                name={name}
                            />
                        }
                        label={label}
                        labelPlacement='top'
                    />
                </FormControl>
            </Grid>
        );
    }
);
