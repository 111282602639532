import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@material-ui/core';

import { ThemeHeaderLogo } from 'src/theming/theme-header/theme-header-get-logo';
import { ThemeGearIcon } from 'src/theming';

import { useMaintanancePageStyles } from './maintanance-page-styles';

import { AppInstances } from 'src/shared/constants';
import { appInstance } from 'src/environment';

interface Props {
    errorNumber: number;
    message: string;
}

export const MaintanancePage = ({ errorNumber, message }: Props): JSX.Element => {
    const classes = useMaintanancePageStyles();
    const { t } = useTranslation(['maintanance']);

    return (
        <Grid
            container
            justify='center'
            alignItems='center'
            className={clsx([
                classes.maintanancePageContainer,
                (appInstance === AppInstances.AON ||
                    appInstance === AppInstances.AON_KOMPOSIT) &&
                    classes.aonContainer,
                appInstance === AppInstances.SGIFFOXXK && classes.sgiContainer,
            ])}
        >
            <div className={classes.logoContainer}>
                <ThemeHeaderLogo />
            </div>
            <Grid
                item
                container
                direction='column'
                alignItems='center'
                justify='center'
                className={classes.infoContainer}
            >
                <div className={classes.iconContainer}>
                    <ThemeGearIcon />
                </div>
                <Typography variant='body1' className={classes.errorNumber}>
                    {errorNumber}
                </Typography>
                <Typography variant='body2' className={classes.message}>
                    {t(message)}
                </Typography>
            </Grid>
        </Grid>
    );
};
