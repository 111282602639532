import React from 'react';
import { ThemeOptions, ThemeProvider } from '@material-ui/core';
import { THEME_MODES } from 'src/theming';
import {
    darkPaletteForAon,
    lightPaletteForAon,
} from 'src/theming/themes/aon/aon-theme-palettes';

interface AonThemeProviderProps {
    children: JSX.Element;
}

export const AonThemeProvider = (props: AonThemeProviderProps): JSX.Element => {
    return (
        <ThemeProvider
            theme={(outerTheme): ThemeOptions => {
                const aonTheme: ThemeOptions = { ...outerTheme };
                if (aonTheme.palette.type === THEME_MODES.DARK) {
                    aonTheme.palette = { ...aonTheme.palette, ...darkPaletteForAon };
                } else {
                    aonTheme.palette = { ...aonTheme.palette, ...lightPaletteForAon };
                }
                return aonTheme;
            }}
        >
            {props.children}
        </ThemeProvider>
    );
};
