import React, { ChangeEvent } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import {
    FormGroup,
    FormControl,
    FormLabel,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { useStyle } from '../damage-report-form-components-styled';

import { CheckboxItem } from 'src/theming';

interface DamageReportFormCheckboxesGroupProps {
    label?: string;
    handleChangeCheckboxesGroup?: (e: ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    options?: CheckboxItem[];
}

export const DamageReportFormCheckboxesGroup = (
    props: DamageReportFormCheckboxesGroupProps
): JSX.Element => {
    const { disabled, options, handleChangeCheckboxesGroup, label } = props;

    const { t } = useTranslation(['damage-report']);

    const classes = useStyle();

    return (
        <FormControl component='fieldset' className={classes.checkboxesFormControl}>
            <FormLabel className={classes.checkboxesGroupLabel}>{t(label)}</FormLabel>
            <FormGroup className={classes.checkboxesGroup}>
                {Array.isArray(options) &&
                    options.map((item) => (
                        <FormControlLabel
                            className={classes.checkboxItemLabel}
                            key={item.value}
                            label={item.title}
                            control={
                                <Checkbox
                                    disabled={disabled}
                                    checked={item.checked}
                                    name={item.value}
                                    onChange={handleChangeCheckboxesGroup}
                                    icon={
                                        <CheckBoxOutlineBlankIcon
                                            className={classes.checkboxIcon}
                                        />
                                    }
                                    checkedIcon={
                                        <CheckBoxIcon
                                            className={clsx(
                                                classes.icon,
                                                classes.checkedIcon
                                            )}
                                        />
                                    }
                                />
                            }
                        />
                    ))}
            </FormGroup>
        </FormControl>
    );
};
