import { GridSortModel } from '@material-ui/data-grid';
import { INTERNAL_ROLES } from 'src/shared/constants';
import { CheckboxItem } from 'src/theming';

interface ContractsListDataForBuildQueries {
    filterValue: string;
    productsList: CheckboxItem[];
    customers: CheckboxItem[];
    customerConnections: CheckboxItem[];
    userRoles: string[];
    availableDivisions: string[];
    sortModel?: GridSortModel;
    vnrValue?: string;
    page?: number;
    rowsPerPage?: number;
    division?: string;
}

interface contractsListRequestBodyPreparingReturn {
    isAscendingSortOrder?: boolean;
    sortField?: string;
    contractNumberSearchTerm?: string;
    active?: boolean;
    allowedProducts?: string[];
    allowedDivisions?: string[];
    allowedCustomerConnections?: string[];
    allowedCustomers?: string[];
    skip?: number;
    take?: number;
}

export const contractsListRequestBodyPreparing = (
    data: ContractsListDataForBuildQueries
): contractsListRequestBodyPreparingReturn => {
    const {
        sortModel,
        vnrValue,
        filterValue,
        productsList,
        customerConnections,
        customers,
        page,
        rowsPerPage,
        division,
        userRoles,
        availableDivisions,
    } = data;
    const requestBody: contractsListRequestBodyPreparingReturn = {
        allowedDivisions: division ? [division] : availableDivisions,
    };
    if (Number.isInteger(page) && Number.isInteger(rowsPerPage)) {
        const skip = (page + 1) * rowsPerPage - rowsPerPage;
        const take = rowsPerPage + 1; // +1 to understand there are items on the next page or not. Because we don't know general count
        requestBody.skip = skip;
        requestBody.take = take;
    }
    if (sortModel && sortModel.length !== 0) {
        requestBody.isAscendingSortOrder = sortModel[0].sort === 'asc';
        requestBody.sortField = sortModel[0].field;
    }
    if (vnrValue) {
        requestBody.contractNumberSearchTerm = vnrValue;
    }
    if (filterValue) {
        requestBody.active = filterValue === 'active';
    }

    const isAllProductListChecked = productsList.find(
        (item) => item.value === 'all'
    )?.checked;
    const nothingCheckedInProductsFilter = productsList.every((item) => !item.checked);
    const isAllClientConnectionsChecked = customerConnections.find(
        (item) => item.value === 'all'
    )?.checked;
    const isAllClientsChecked = customers.find((item) => item.value === 'all')?.checked;

    if (productsList?.length > 1) {
        if (!isAllProductListChecked && !nothingCheckedInProductsFilter) {
            requestBody.allowedProducts = productsList
                .filter((item) => item.checked)
                .map((item) => item.value);
        }
    }

    const isUserAdminDomain = userRoles.some((r) => r === INTERNAL_ROLES.ADMIN_DOMAIN);

    requestBody.allowedCustomerConnections = customerConnections
        .filter((item) => isAllClientConnectionsChecked || item.checked)
        .map((item) => item.title);

    requestBody.allowedCustomers = customers
        .filter((item) => isAllClientsChecked || item.checked)
        .map((item) => item.value);

    if (isAllClientConnectionsChecked && isAllClientsChecked && isUserAdminDomain) {
        requestBody.allowedCustomerConnections = undefined;
        requestBody.allowedCustomers = undefined;
    } else if (isAllClientConnectionsChecked && isUserAdminDomain) {
        requestBody.allowedCustomerConnections = undefined;
    }

    return requestBody;
};
