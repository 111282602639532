import { TFunction } from 'i18next';
import { SelectItem, RadioButton } from 'src/theming';
import { VehicleTypes, InsuranceCover } from 'src/shared/constants';

export const generateVehicleTypeItems = (t: TFunction): SelectItem[] => {
    return [
        {
            title: t('vehicle-types:pendant'),
            value: VehicleTypes.PENDANT,
        },
        {
            title: t('vehicle-types:deliveryTrucks'),
            value: VehicleTypes.DELIVERY_TRUCKS,
        },
        {
            title: t('vehicle-types:trucksFrom3and5t'),
            value: VehicleTypes.TRUCKS_FROM_3_AND_5T,
        },
        {
            title: t('vehicle-types:car'),
            value: VehicleTypes.CAR,
        },
        {
            title: t('vehicle-types:others'),
            value: VehicleTypes.OTHERS,
        },
        {
            title: t('vehicle-types:tractor'),
            value: VehicleTypes.TRACTOR,
        },
    ];
};

export const generateCoverItems = (t: TFunction): SelectItem[] => {
    return [
        {
            title: t('cover:noComprehensiveInsurance'),
            value: InsuranceCover.NO_COMPREHENSIVE_INSURANCE,
        },
        {
            title: t('cover:partiallyComprehensive'),
            value: InsuranceCover.PARTIALLY_COMPREHENSIVE,
        },
        {
            title: t('cover:fullyComprehensive'),
            value: InsuranceCover.FULLY_COMPREHENSIVE,
        },
    ];
};

export const generateContactByPhoneOptions = (t: TFunction): RadioButton[] => {
    return [
        {
            label: t('common:yes'),
            value: 'yes',
        },
        {
            label: t('common:no'),
            value: 'no',
        },
    ];
};
