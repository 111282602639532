import React, { BaseSyntheticEvent, memo } from 'react';
import clsx from 'clsx';
import { FormControl, FormControlLabel, Grid } from '@material-ui/core';
import { SelectItem, ThemeSelectInput } from '../../../../theming';
import {
    useFormControlLabelStyles,
    useFormControlStyles,
    useStyle,
} from '../damage-report-form-components-styled';

interface DamageReportFormSelectProps {
    value: string | string[];
    handleChangeValue: (event: BaseSyntheticEvent) => void;
    label: string;
    items: SelectItem[];
    name: string;
    disabled?: boolean;
    error?: boolean;
}

export const DamageReportFormSelect = memo(
    ({
        handleChangeValue,
        value,
        items,
        label,
        name,
        disabled,
        error,
    }: DamageReportFormSelectProps): JSX.Element => {
        const classes = useStyle();
        const formControlClasses = useFormControlStyles();
        const formControlLabelClasses = useFormControlLabelStyles();

        return (
            <Grid item className={clsx(classes.thirdPart)}>
                <FormControl
                    fullWidth
                    classes={formControlClasses}
                    variant='outlined'
                    error={error}
                >
                    <FormControlLabel
                        classes={formControlLabelClasses}
                        control={
                            <ThemeSelectInput
                                editMode={!disabled}
                                value={value}
                                name={name}
                                onChange={handleChangeValue}
                                items={items}
                            />
                        }
                        label={label}
                        labelPlacement='top'
                    />
                </FormControl>
            </Grid>
        );
    }
);
