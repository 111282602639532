import { THEME_MODES } from '../theme-modes';
import {
    LIGHT_SGIFFOXX_THEME_COLORS,
    DARK_SGIFFOXX_THEME_COLORS,
} from './sgiffoxx-colors';

export const lightPaletteForSGIFFOXXK = {
    type: THEME_MODES.LIGHT,
    primary: {
        main: LIGHT_SGIFFOXX_THEME_COLORS.PRIMARY_MAIN_COLOR,
        light: LIGHT_SGIFFOXX_THEME_COLORS.PRIMARY_LIGHT_COLOR,
        dark: LIGHT_SGIFFOXX_THEME_COLORS.PRIMARY_DARK_COLOR,
        contrastText: LIGHT_SGIFFOXX_THEME_COLORS.PRIMARY_CONTRAST_TEXT,
    },
    secondary: {
        main: LIGHT_SGIFFOXX_THEME_COLORS.SECONDARY_MAIN_COLOR,
        contrastText: LIGHT_SGIFFOXX_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
    },
    info: {
        main: LIGHT_SGIFFOXX_THEME_COLORS.SECONDARY_MAIN_COLOR,
        contrastText: LIGHT_SGIFFOXX_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
    },
    error: {
        main: LIGHT_SGIFFOXX_THEME_COLORS.ERROR_MAIN_COLOR,
        light: LIGHT_SGIFFOXX_THEME_COLORS.ERROR_LIGHT_COLOR,
    },
};

export const darkPaletteForSGIFFOXXK = {
    type: THEME_MODES.DARK,
    primary: {
        main: DARK_SGIFFOXX_THEME_COLORS.PRIMARY_MAIN_COLOR,
        light: DARK_SGIFFOXX_THEME_COLORS.PRIMARY_LIGHT_COLOR,
        dark: DARK_SGIFFOXX_THEME_COLORS.PRIMARY_DARK_COLOR,
        contrastText: DARK_SGIFFOXX_THEME_COLORS.PRIMARY_CONTRAST_TEXT,
    },
    secondary: {
        main: DARK_SGIFFOXX_THEME_COLORS.SECONDARY_MAIN_COLOR,
        contrastText: DARK_SGIFFOXX_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
    },
    info: {
        main: DARK_SGIFFOXX_THEME_COLORS.SECONDARY_MAIN_COLOR,
        contrastText: DARK_SGIFFOXX_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
    },
    error: {
        main: DARK_SGIFFOXX_THEME_COLORS.ERROR_MAIN_COLOR,
        light: DARK_SGIFFOXX_THEME_COLORS.ERROR_LIGHT_COLOR,
    },
};
