import moment from 'moment';
import {
    DamageReportFormBHVAndPHVMutation,
    DamageReportFormBHVAndPHVType,
} from '../damage-report-form-types/damage-report-form-BHV-PHV-types';

export const damageReportFormBHVAndPHVMutateAdapter = (
    data: DamageReportFormBHVAndPHVType
): DamageReportFormBHVAndPHVMutation => {
    const offset = new Date().getTimezoneOffset() * 60000;
    return {
        id: data.id,
        dayOfDamage: data.dayOfDamage
            ? moment(moment(data.dayOfDamage).valueOf() - offset).toISOString()
            : null,
        timeOfDamage: data.timeOfDamage
            ? moment(moment(data.timeOfDamage).valueOf() - offset).toISOString()
            : null,
        contractId: data.contractId,
        risk: data.risk,
        contractNumber: data.contractNumber,
        policyHolder: data.policyHolder,
        insuranceCompany: data.insuranceCompany,
        victim: {
            name: data.injuredPartySurname,
            street: data.injuredPartyRoad,
            postcodeAndCity: data.injuredPartyPostcodeAndPlace,
            phone: data.injuredPartyPhonenumber,
            emailAddress: data.injuredPartyEmail,
        },
        damageCauser: {
            name: data.damageOriginatorSurname,
            street: data.damageOriginatorRoad,
            postcodeAndCity: data.damageOriginatorPostCodeAndPlace,
            phone: data.damageOriginatorPhoneNumber,
            emailAddress: data.damageOriginatorEmail,
            polluterRelateToThePolicyholder:
                data.damageOriginatorRelationshipBetweenPolluterAndPolicyholder,
        },
        damageInformationBhv: {
            damageType: data.damageInformationDamageType,
            damagePlace: data.damageInformationDamagePlace,
            damageObject: data.damageInformationWhatWasDamaged,
            damagedItemsInsuredElsewhere:
                data.damageInformationAreDamagedItemsOtherwiseInsured,
            insuranceCompanyOrNumberAndTypeInsurance:
                data.indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance,
            claimsForDamagesAlreadyBeenAssertedAgainst:
                data.claimsForDamagesMadeAgainstYou,
            estimatedAmountOfDamage: Number(data.estimatedDamageAmount),
            damageAlreadyBeenAssessed: data.hasDamageAssessed,
            quiltyOfWhatHappened: data.ifYouAreAtFaultForThatIncident,
            injuredPartyAlreadyBeenAcknowledged: data.itWasYourTurnToBeAtFault,
            isAnyRelationshipWithInsureOrInjuredParty:
                data.existenceBetweenYouAndCoInsured,
            whichIsAnyRelationshipWithInsureOrInjuredParty:
                data.whichExistenceBetweenYouAndCoInsured,
            performAnyActionsWithTheDamagedObject:
                data.doYouOrCoInsuredPersonPerformedActivity,
            whichPerformAnyActionsWithTheDamagedObject: data.whichActivity,
            detailedDamageDescription: data.detailedDescription,
            entitledDeductInputTax: data.areYouEntitledToDeductInputTax,
        },
        policeRecordBhv: {
            incidentRecordedByPolice: data.isRecordedByThePolice,
            policeStation: data.policeStation,
            policeFileNumber: data.fileNumber,
        },
        witnessesInformationBhv: {
            isAnyWitnesses: data.isWitnesses,
            name: data.witnessesSurname,
            street: data.witnessesRoad,
            postCode: data.witnessesPostcode,
            ort: data.witnessesPlace,
            phoneNumber: data.witnessesPhoneNumber,
            email: data.witnessesEmail,
        },
        newFiles: data.files?.map((item) => item.file),
        fileInfos: data.fileInfos,
    };
};
