import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

import { Typography, Grid, Button } from '@material-ui/core';

import { ThemeModalWindow, ThemeCircularProgress } from 'src/theming';

import { useStyle } from './master-data-style';

interface Props {
    isOpen: boolean;
    activeCC: string;
    onClose: () => void;
    onSuccess: () => void;
}

export const MasterDataApproveDeleteModalWindow = ({
    activeCC,
    onClose,
    isOpen,
    onSuccess,
}: Props): JSX.Element => {
    const { t } = useTranslation(['user-management', 'errors']);
    const classes = useStyle();
    const httpClient = useHttpClient();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const [isLoading, setLoading] = useState<boolean>(false);
    const handleApprove = async (): Promise<void> => {
        try {
            setLoading(true);
            await httpClient.delete(`customerconnections/dummy/${activeCC}`);
            enqueueSnackbar(t('masterData.clientConnectionDeleted'), {
                variant: 'success',
            });
            history.push({ state: null });
            onSuccess();
        } catch {
            enqueueSnackbar(t('errors:unknownError'), { variant: 'error' });
        } finally {
            setLoading(false);
            onClose();
        }
    };

    return (
        <ThemeModalWindow isOpen={isOpen} onClose={onClose}>
            <></>
            <Typography variant='body1'>
                {t('masterData.approveDeletingMessage')}
            </Typography>
            <Grid container className={classes.modalActionsContainer}>
                <Button
                    disabled={isLoading}
                    variant='outlined'
                    className={clsx(classes.modalButton, classes.cancelButton)}
                    onClick={onClose}
                >
                    {t('common:cancel')}
                </Button>
                <Button
                    disableElevation
                    variant='contained'
                    className={clsx(classes.modalButton, classes.okButton)}
                    onClick={handleApprove}
                    disabled={isLoading}
                    startIcon={<ThemeCircularProgress isLoading={isLoading} />}
                >
                    {t('common:yes')}
                </Button>
            </Grid>
        </ThemeModalWindow>
    );
};
