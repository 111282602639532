import React, { useState, useCallback, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useStyle } from './dashboard-pdf-view-style';
import { HeaderColumn } from './dashboard-pdf-header/header-column/header-column';
import { DashboardPdfHeader } from './dashboard-pdf-header/dashboard-pdf-header';
import { ContractsDashboardVariantSelection } from 'src/contracts/contracts-dashboard/contracts-dashboard-variant-selection';
import { HeaderLogo } from './dashboard-pdf-header/header-logo/header-logo';
import { CheckboxItem } from 'src/theming';
import { useTranslation } from 'react-i18next';
import { DashboardDataResponseType } from 'src/shared/contexts/dashboard-context/dashboard-data-response-type';
import { ThemeCustomerHeaderLogo } from 'src/theming/theme-header/theme-header-get-customer-logo';

export type DashboardPdfViewProps = {
    customerConnections: CheckboxItem[];
    customers: CheckboxItem[];
    division: CheckboxItem;
    years: CheckboxItem[];
    chartsData: DashboardDataResponseType;
    onContentLoaded: (contentLoaded: boolean) => void;
    parentModalWindowOpened?: boolean;
    settings: {
        dashboardWidget: string;
        position: number;
        isShown: boolean;
        className?: string;
    }[];
};

export const DashboardPdfView = ({
    customerConnections,
    customers,
    division,
    years,
    chartsData,
    parentModalWindowOpened,
    onContentLoaded,
    settings,
}: DashboardPdfViewProps): JSX.Element => {
    const { t } = useTranslation(['dashboard-pdf']);
    const classes = useStyle();
    const [logoLoaded, setLogoLoaded] = useState(false);

    const onLogoLoaded = useCallback(() => {
        setLogoLoaded(true);
    }, [setLogoLoaded]);

    useEffect(() => {
        if (logoLoaded && parentModalWindowOpened) {
            onContentLoaded(true);
        }
    }, [logoLoaded, parentModalWindowOpened]);

    return (
        <Grid id='main' container className={classes.pdfContainer}>
            <Grid item container justify='space-between' alignItems='center'>
                <div className={classes.customerLogoContainer}>
                    <ThemeCustomerHeaderLogo
                        customerConnections={customerConnections}
                        onLogoLoaded={onLogoLoaded}
                    />
                </div>
                <HeaderLogo />
            </Grid>
            <Grid item container className={classes.headerContainer}>
                <DashboardPdfHeader />
            </Grid>
            <Grid item container justify='space-between'>
                <Grid item>
                    <HeaderColumn
                        columnName={t('clientConnection')}
                        columnValues={customerConnections}
                    />
                </Grid>
                <Grid item>
                    <HeaderColumn
                        columnName={t('client')}
                        columnValues={customers}
                        customers={true}
                    />
                </Grid>
                <Grid item>
                    <HeaderColumn columnName={t('branch')} columnValues={[division]} />
                </Grid>
                <Grid item>
                    <HeaderColumn columnName={t('years')} columnValues={years} />
                </Grid>
            </Grid>
            <Grid item>
                <ContractsDashboardVariantSelection
                    division={division.value}
                    dashboardData={chartsData}
                    years={years.map((item) => parseInt(item.value))}
                    settings={settings}
                />
            </Grid>
        </Grid>
    );
};
