import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { Grid, List, ListItem, ListItemText } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

import { ThemeNestedMenu, ThemeLoader } from 'src/theming';
import { useAbilityCtx } from 'src/roleAccesses';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { ContractDetailsListItem } from '../contract-details-list-item';

import {
    useStyle,
    useContractDetailsViewDataStyle,
} from '../contracts-details-view-styles';

import { generateContractsDetailsAdditionalMenu } from '../contract-details-view-configs';

import { ContractDetailsViewPrvResponseBody } from './contract-details-view-prv-response-body';

interface ContractDetailsViewPrvDetailsProps {
    handleOpenContractEdit: () => void;
    hideThemeNestedMenu: boolean;
    contractNumber: string;
}
export const ContractDetailsViewPrvDetails = (
    props: ContractDetailsViewPrvDetailsProps
): JSX.Element => {
    const ability = useAbilityCtx();
    const { t } = useTranslation(['contract-details-view']);

    const classes = useStyle();
    const contractDetailsViewDataClasses = useContractDetailsViewDataStyle();

    const { handleOpenContractEdit, hideThemeNestedMenu, contractNumber } = props;

    const router = useHistory();
    const { id } = useParams<{ id: string }>();
    const httpClient = useHttpClient();
    const [isLoadingDetails, setLoadingDetails] = useState(false);
    const [detailsData, setDetailsData] = useState<ContractDetailsViewPrvResponseBody>();
    const makeRequestForDetails =
        async (): Promise<ContractDetailsViewPrvResponseBody> => {
            setLoadingDetails(true);
            return httpClient.get<ContractDetailsViewPrvResponseBody>(
                `contracts/${id}/prv`
            );
        };
    useEffect(() => {
        makeRequestForDetails()
            .then((data: ContractDetailsViewPrvResponseBody) => {
                setDetailsData(data);
            })
            .finally(() => {
                setLoadingDetails(false);
            });
    }, []);

    const goTo = (pathname: string): void => {
        router.push(pathname);
    };

    const menuItems = useMemo(
        () =>
            generateContractsDetailsAdditionalMenu(
                t,
                null,
                handleOpenContractEdit,
                goTo,
                ability,
                id,
                contractNumber
            ),
        [t, contractNumber, ability]
    );

    const isDetailDataEmpty = useMemo(() => {
        if (detailsData) {
            const detailsDataKeys = Object.keys(detailsData);
            return !detailsDataKeys?.map((x) => detailsData[x])?.filter((x) => x)?.length;
        }
        return true;
    }, [detailsData]);

    return (
        <Grid container>
            <Grid container className={classes.titleAndMenuContainer}>
                <div style={{ flexGrow: 1 }} />
                {!hideThemeNestedMenu &&
                    Array.isArray(menuItems) &&
                    menuItems.length > 0 && (
                        <ThemeNestedMenu
                            menuItems={menuItems}
                            buttonClass={classes.additionalMenuButton}
                            buttonIcon={<MenuIcon className={classes.menuIcon} />}
                        />
                    )}
            </Grid>
            {isLoadingDetails && <ThemeLoader />}
            {isDetailDataEmpty && (
                <ListItem>
                    <ListItemText data-title>
                        {t('contract-details-view:noContractDetails')}
                    </ListItemText>
                </ListItem>
            )}
            {detailsData && !isLoadingDetails && (
                <>
                    <List className={contractDetailsViewDataClasses.list}>
                        <ContractDetailsListItem
                            value={detailsData?.insuredPerson}
                            title='contract-details-view:contractDetails.insuredPerson'
                            hidden={!detailsData?.insuredPerson}
                        />
                        <ContractDetailsListItem
                            value={detailsData?.intendedUse}
                            title='contract-details-view:contractDetails.intendedUse'
                            hidden={!detailsData?.intendedUse}
                        />
                        <ContractDetailsListItem
                            value={t('formatted-values:formattedDate', {
                                date: detailsData.startOfPensionPayment,
                            })}
                            title='contract-details-view:contractDetails.startOfPensionPayment'
                            hidden={!detailsData?.startOfPensionPayment}
                        />
                        <ContractDetailsListItem
                            value={
                                detailsData?.guaranteeInterest
                                    ? String(detailsData?.guaranteeInterest)
                                    : ''
                            }
                            title='contract-details-view:contractDetails.guaranteeInterest'
                            hidden={
                                detailsData?.guaranteeInterest === null ||
                                detailsData?.guaranteeInterest === 0
                            }
                        />
                    </List>
                </>
            )}
        </Grid>
    );
};
