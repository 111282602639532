import qs from 'querystring';

export const customerAddFormPrepareQuaryForCountriesRequest = (
    language: string,
    filter: string
): string => {
    const queryParams: {
        Filter?: string;
        Language?: string;
    } = {};
    if (language) {
        queryParams.Language = language;
    }
    if (language) {
        queryParams.Filter = filter;
    }

    return qs.stringify(queryParams);
};
