import React from 'react';
import { DarkMode } from 'use-dark-mode';

import { ContractsDashboard } from 'src/contracts';
import { ThemeLayout } from 'src/theming';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

interface Props {
    darkMode: DarkMode;
}

export const HomePage = ({ darkMode }: Props): JSX.Element => {
    return (
        <div>
            <DndProvider backend={HTML5Backend}>
                <ThemeLayout darkMode={darkMode}>
                    <ContractsDashboard />
                </ThemeLayout>
            </DndProvider>
        </div>
    );
};
