import moment from 'moment';
import {
    DamageReportFormAONKompositLiabilityTypes,
    DamageReportFormAONKompositLiabilityMutation,
} from '../../damage-report-form-types/damage-report-form-AON-Komposit-types/damage-report-form-AON-Komposit-liability-types';

export const damageReportFormLiabilityMutateAdapter = (
    data: DamageReportFormAONKompositLiabilityTypes
): DamageReportFormAONKompositLiabilityMutation => {
    const offset = new Date().getTimezoneOffset() * 60000;
    return {
        id: data.id,
        division: data.division,
        dayOfDamage: data.dayOfDamage
            ? moment(moment(data.dayOfDamage).valueOf() - offset).toISOString()
            : null,
        timeOfDamage: data.timeOfDamage
            ? moment(moment(data.timeOfDamage).valueOf() - offset).toISOString()
            : null,
        contractId: data.contractId,
        risk: data.risk,
        contractNumber: data.contractNumber,
        policyHolder: data.policyHolder,
        insuranceCompany: data.insuranceCompany,
        newFiles: data.files?.map((item) => item.file),
        fileInfos: data.fileInfos,
        liabilityInsuranceNotification: {
            insuredCompanyFileNumber: data?.fileNumberInsuredCompany,
            damageLocation: data?.damageLocation,
            damageOriginator: data?.damageCause,
            damageCause: data?.courseOfTheDamage,
            expectedDamageAmount:
                data?.expectedAmountOfDamage &&
                typeof data?.expectedAmountOfDamage === 'string'
                    ? Number(
                          data?.expectedAmountOfDamage
                              .replaceAll('.', '')
                              .replace(',', '.')
                      )
                    : null,
            isBlameForTheDamage: data?.blameForTheDamage,
            blameBecause: data?.because,
            isRelationshipBetweenYouAndInjuredPerson:
                data?.relationshipBetweenYouAndTheInjuredPerson,
            hasTheDamagedItemRentedLeasedLoaned:
                data?.wasTheDamagedItemRentedLeasedLoaned,
            isAffectedByDamageThroughAnotherContract:
                data?.affectedByTheDamageAlsoThroughAnotherContract,
            liabilityDamagedItems: data?.itemsAffectedByTheDamage?.map((item) => ({
                numberAndTypeOfThings: item.numberAndTypeOfItems,
                purchaseOrReplacementPrice:
                    item.acquisitionOrReplacementPrice &&
                    typeof item.acquisitionOrReplacementPrice === 'string'
                        ? Number(
                              item.acquisitionOrReplacementPrice
                                  .replaceAll('.', '')
                                  .replace(',', '.')
                          )
                        : 0,
                repairCost:
                    item.repairCosts && typeof item.repairCosts === 'string'
                        ? Number(item.repairCosts.replaceAll('.', '').replace(',', '.'))
                        : 0,
            })),
            injuredPersonsOrClaimants: data?.injuredPersonsOrClaimants,
            isInvestigationExpected: data?.investigationToBeExpected,
            isProductLiabilityIncludingRecallDamage:
                data?.productLiabilityIncludingRecallDamage,
            isFinancialLossIncludingFidelityOrComputerAbuse:
                data?.financialLossIncludingFidelityOrComputerAbuse,
        },
        productLiabilityClaims: {
            productCausingDamageDescription:
                data?.descriptionOfTheProductCausingTheDamage,
            purposeOfUse: data?.purposeOfUse,
            productManufacturerSellerDealer: data?.manufacturerSellerDealerOfTheProduct,
            damageCausedByYourSubSupplier: data?.damageCausedByYourSubSupplier,
            defectiveProductsCount: data?.howManyOfYourProductsAreDefective,
            deliveryDate: data?.deliveryDate,
            batchBasedOnSerialNumberingRecognizable:
                data?.batchBasedOnSerialNumberingRecognizable,
            retainedSamplesExist: data?.etainedSamplesExist,
            exitInspectionBeenCarriedOut: data?.exitInspectionBeenCarriedOut,
            defectProduct: data?.defectProduct,
            blameForTheDefect: data?.blameForTheDefect,
        },
        liabilityDocumentsInfo: {
            liabilityAttachedDocuments: data?.elationshipBetweenYouAndTheClaimant?.map(
                (item) => item.name
            ),
        },
    };
};
