import React, { BaseSyntheticEvent, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
    FormControl,
    FormControlLabel,
    Grid,
    OutlinedInput,
    Typography,
} from '@material-ui/core';

import { ThemeSelectInput } from 'src/theming';

import {
    useStyle,
    useFormControlStyles,
    useFormControlLabelStyles,
    useInputStyles,
} from '../../damage-report-form-styles/damage-report-form-styles';

import { generateDamageReportFormRootCauseSelectionValues } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-root-cause-selection-values';
import { generateTravelPurpouseOptions } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-generate-travel-purpouse-options';

interface AccidentDamageDetailsInformation {
    readonly: boolean;
    rootCause: string;
    changeOfVehicle: string;
    estimatedAmountOfDamageToYourOwnVehicle: string;
    damageNumberCustomer: string;
    detailedDamageDescription: string;
    handleChangeValue: (e: BaseSyntheticEvent) => void;
    errors: {
        accidentDamageDetailsInformationChangeOfVehicle: boolean;
    };
}

export const DamageReportFormAccidentDamageDetailsInformation = memo(
    (props: AccidentDamageDetailsInformation) => {
        const classes = useStyle();
        const formControlClasses = useFormControlStyles();
        const formControlLabelClasses = useFormControlLabelStyles();
        const inputClasses = useInputStyles();

        const { t } = useTranslation();

        const {
            rootCause,
            changeOfVehicle,
            estimatedAmountOfDamageToYourOwnVehicle,
            damageNumberCustomer,
            detailedDamageDescription,
            readonly,
            handleChangeValue,
            errors,
        } = props;

        const rootCauseOptions = useMemo(
            () => generateDamageReportFormRootCauseSelectionValues(t),
            [t]
        );

        const travelPurpouseOptions = useMemo(
            () => generateTravelPurpouseOptions(t),
            [t]
        );

        return (
            <Grid container spacing={2}>
                <Typography variant='h6' className={classes.formBlockTitle}>
                    {t('damages-report:accidentDamageDetailsInformation.title')}
                </Typography>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <ThemeSelectInput
                                    editMode={!readonly}
                                    value={rootCause}
                                    name='rootCause'
                                    onChange={handleChangeValue}
                                    items={rootCauseOptions}
                                />
                            }
                            label={t('damages-report:generalFormBlock.rootCause')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid className={clsx(classes.thirdPart)} />
                <Grid className={clsx(classes.thirdPart)} />
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                        error={errors.accidentDamageDetailsInformationChangeOfVehicle}
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <ThemeSelectInput
                                    editMode={!readonly}
                                    value={changeOfVehicle || null}
                                    name='accidentDamageDetailsInformationChangeOfVehicle'
                                    onChange={handleChangeValue}
                                    items={travelPurpouseOptions}
                                />
                            }
                            label={t('damages-report:driverInformation.travelPurpose')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled={readonly}
                                    classes={inputClasses}
                                    value={estimatedAmountOfDamageToYourOwnVehicle}
                                    onChange={handleChangeValue}
                                    name='accidentDamageDetailsInformationEstimatedAmountOfDamageToYourOwnVehicle'
                                />
                            }
                            label={t(
                                'damages-report:driverInformation.estimatedDamageAmount'
                            )}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled={readonly}
                                    classes={inputClasses}
                                    value={damageNumberCustomer}
                                    onChange={handleChangeValue}
                                    name='accidentDamageDetailsInformationDamageNumberCustomer'
                                />
                            }
                            label={t(
                                'damages-report:driverInformation.customerDamageNumber'
                            )}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.fullWidth)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    multiline
                                    rows={4}
                                    disabled={readonly}
                                    value={detailedDamageDescription}
                                    onChange={handleChangeValue}
                                    name='accidentDamageDetailsInformationDetailedDamageDescription'
                                />
                            }
                            label={t(
                                'damages-report:driverInformation.damageDetailsDescription'
                            )}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
            </Grid>
        );
    }
);
