import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { appInstance, companyName } from 'src/environment';
import { AppInstances } from 'src/shared/constants';

export const useDocumentParameters = (): void => {
    const { t } = useTranslation(['common']);

    useEffect(() => {
        const favicon: unknown = document.getElementById('favicon');
        switch (appInstance) {
            case AppInstances.AON_KOMPOSIT: {
                document.title = t('titleAONKomposit');
                (favicon as { href: string }).href = './static/images/aon-favicon.png';
                break;
            }
            case AppInstances.HECTOR: {
                document.title = `${companyName} ${t('title')}`;
                (favicon as { href: string }).href = './static/images/hector-favicon.png';
                break;
            }
            case AppInstances.CEAT: {
                document.title = `${companyName} ${t('title')}`;
                (favicon as { href: string }).href = './static/images/ceat-favicon.png';
                break;
            }
            case AppInstances.AON:
            case AppInstances.AON_DIGITAL:
            case AppInstances.AON_MOTOR: {
                document.title = `${companyName} ${t('titleAON')}`;
                (favicon as { href: string }).href = './static/images/aon-favicon.png';
                break;
            }
            default: {
                document.title = `${companyName} ${t('title')}`;
                (favicon as { href: string }).href = './static/images/sgi-favicon.png';
            }
        }
    }, []);
};
