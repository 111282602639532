import React from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';

import { makeStyles, Theme } from '@material-ui/core';

const useStyle = makeStyles((theme: Theme) => {
    return {
        containerClass: {
            '& .MuiInputBase-root.Mui-disabled': {
                color: theme.palette.primary.contrastText,
            },
            '& .MuiInputBase-root': {
                height: 40,
                fontSize: '1.4rem',
            },
        },
    };
});

interface ThemePhoneNumberInputProps {
    value: string;
    name: string;
    onChange: (e: { target: { name: string; value: string } }) => void;
    label?: string;
    disabled?: boolean;
    error?: boolean;
}

export const ThemePhoneNumberInput = (props: ThemePhoneNumberInputProps): JSX.Element => {
    const { label, onChange, value, disabled, name, error, ...rest } = props;
    const classes = useStyle();

    const handleChange = (value: string): void => {
        onChange({ target: { name, value } });
    };

    return (
        <MuiPhoneNumber
            fullWidth
            error={error}
            disabled={disabled}
            disableDropdown={disabled}
            variant='outlined'
            color='secondary'
            defaultCountry='de'
            label={label}
            value={value}
            onChange={handleChange}
            className={classes.containerClass}
            {...rest}
        />
    );
};
