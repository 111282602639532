import React, { BaseSyntheticEvent, memo } from 'react';
import { DamageReportFormHeaderBlock } from '../damage-report-form-components/damage-report-form-header-block';
import { DamageReportFormFooter } from '../damage-report-form-components/damage-report-form-footer';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { DamageReportFormConfigGBWWReturnType } from '../damage-report-form-config/damage-report-form-config-GBW-HR-GESCH';
import { DamageReportFormConfigGenerator } from '../damage-report-form-config/damage-report-form-config-generator';
import { ThemeLoader } from '../../../theming/theme-loader/theme-loader';
import { Paper } from '@material-ui/core';
import { FileObject } from 'material-ui-dropzone';
import { FormState } from 'react-hook-form';

import { DamageReportFormGBWTypes } from '../damage-report-form-utils/damage-report-form-types/damage-report-form-GBW-HR-GESCH-types';
import { DamageReportGroups } from 'src/shared/constants';

interface DamageReportFormViewProps {
    handleChangeValue: (e: BaseSyntheticEvent) => void;
    division: string;
    divisionFromRecord: string;
    dayOfDamage: ParsableDate;
    timeOfDamage: ParsableDate;
    handleChangeDate: (name, value: ParsableDate) => void;
    handleReadOnlyFields: (data: {
        licenseNumber: string;
        contractNumber: string;
        policyHolder: string;
        insuranceCompany: string;
        contractId: string;
    }) => void;
    containerClassName: string;
    cancelText: string;
    handleCancel: () => void;
    IsSaveDisabled: boolean;
    isSendingToAmsLoading: boolean;
    isSaveLoading: boolean;
    isSendDisabled: boolean;
    saveButtonClass: string;
    sendText: string;
    sendButtonClass: string;
    saveText: string;
    configs: DamageReportFormConfigGBWWReturnType[][];
    isReceivingDataLoading: boolean;
    mainFormContainer: string;
    handleSubmit: React.FormEventHandler<HTMLDivElement>;
    footerClassName: string;
    files: FileObject[];
    fileInfos: { id: string; fileName: string }[];
    onDeleteFile: (id: string) => void;
    handleAddFile: (newFiles: FileObject[]) => void;
    handleDeleteFile: (index: number) => void;
    formState: FormState<DamageReportFormGBWTypes>;
    formBlockElementClass: string;
    sectionContainer: string;
    headerConfig: DamageReportFormConfigGBWWReturnType[];
    footerConfig: DamageReportFormConfigGBWWReturnType[];
    boxContainer: string;
    hideButtons: boolean;
    handleSaveReport: () => void;
    deleteText: string;
    deleteButtonClass: string;
    setDeleteConfirmOpen: (value: boolean) => void;
    universalDivision?: boolean;
    damageReportGroup: DamageReportGroups;
}
export const DamageReportFormView = memo(
    ({
        damageReportGroup,
        hideButtons,
        headerConfig,
        formState,
        division,
        divisionFromRecord,
        handleChangeValue,
        dayOfDamage,
        timeOfDamage,
        handleChangeDate,
        handleReadOnlyFields,
        handleCancel,
        isSendDisabled,
        IsSaveDisabled,
        isSaveLoading,
        isSendingToAmsLoading,
        cancelText,
        saveButtonClass,
        sendButtonClass,
        saveText,
        sendText,
        configs,
        isReceivingDataLoading,
        mainFormContainer,
        handleSubmit,
        footerClassName,
        files,
        fileInfos,
        onDeleteFile,
        handleAddFile,
        handleDeleteFile,
        formBlockElementClass,
        sectionContainer,
        footerConfig,
        containerClassName,
        boxContainer,
        handleSaveReport,
        deleteText,
        deleteButtonClass,
        setDeleteConfirmOpen,
    }: DamageReportFormViewProps) => {
        return (
            <>
                {!isReceivingDataLoading && (
                    <Paper
                        className={mainFormContainer}
                        component='form'
                        onSubmit={handleSubmit}
                    >
                        <DamageReportFormHeaderBlock
                            damageReportGroup={damageReportGroup}
                            readonly={hideButtons}
                            headerConfig={headerConfig}
                            sectionContainer={sectionContainer}
                            handleChangeValue={handleChangeValue}
                            dayOfDamage={dayOfDamage}
                            division={division}
                            divisionFromRecord={divisionFromRecord}
                            timeOfDamage={timeOfDamage}
                            handleChangeDate={handleChangeDate}
                            handleReadOnlyFields={handleReadOnlyFields}
                            errors={{
                                dayOfDamage: Boolean(formState?.errors?.dayOfDamage),
                                timeOfDamage: Boolean(formState?.errors?.timeOfDamage),
                                contractNumber: Boolean(formState?.errors?.licenseNumber),
                            }}
                        />
                        {configs.map((config, index) => {
                            return (
                                <div key={index} className={sectionContainer}>
                                    <DamageReportFormConfigGenerator
                                        readonly={hideButtons}
                                        config={config}
                                        handleChangeValue={handleChangeValue}
                                        formBlockElementClass={formBlockElementClass}
                                        containerClassName={containerClassName}
                                    />
                                </div>
                            );
                        })}
                        <DamageReportFormFooter
                            hideButtons={hideButtons}
                            boxContainer={boxContainer}
                            formBlockContainer={containerClassName}
                            footerConfig={footerConfig}
                            handleChangeValue={handleChangeValue}
                            sectionContainer={sectionContainer}
                            containerClassName={footerClassName}
                            cancelText={cancelText}
                            handleCancel={handleCancel}
                            IsSaveDisabled={IsSaveDisabled}
                            isSaveLoading={isSaveLoading}
                            isSendDisabled={isSendDisabled}
                            isSendingToAmsLoading={isSendingToAmsLoading}
                            saveText={saveText}
                            sendButtonClass={sendButtonClass}
                            sendText={sendText}
                            saveButtonClass={saveButtonClass}
                            files={files}
                            fileInfos={fileInfos}
                            onDeleteFile={onDeleteFile}
                            handleAddFile={handleAddFile}
                            handleDeleteFile={handleDeleteFile}
                            handleSaveReport={handleSaveReport}
                            deleteText={deleteText}
                            deleteButtonClass={deleteButtonClass}
                            setDeleteConfirmOpen={setDeleteConfirmOpen}
                        />
                    </Paper>
                )}
                {isReceivingDataLoading && <ThemeLoader />}
            </>
        );
    }
);
