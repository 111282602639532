import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import {
    useInputAonMotorStyles,
    useGlobalSearchAonMotorStyle,
} from './contracts-global-search-aon-motor-styles';

import {
    useInputAonStyles,
    useGlobalSearchAonStyle,
} from './contracts-global-search-aon-styles';

import {
    useInputHectorStyles,
    useGlobalSearchHectorStyle,
} from './contracts-global-search-hector-styles';

import {
    useInputSgiffoxxkStyles,
    useGlobalSearchSgiffoxxkStyle,
} from './contracts-global-search-sgiffoxxk-styles';

import { appInstance } from 'src/environment';
import { AppInstances } from 'src/shared/constants';

const globalSearchInstancesStyles = {
    [AppInstances.AON]: useGlobalSearchAonStyle,
    [AppInstances.AON_KOMPOSIT]: useGlobalSearchAonStyle,
    [AppInstances.AON_DIGITAL]: useGlobalSearchAonMotorStyle,
    [AppInstances.AON_MOTOR]: useGlobalSearchAonMotorStyle,
    [AppInstances.HECTOR_DEMO]: useGlobalSearchAonMotorStyle,
    [AppInstances.HECTOR]: useGlobalSearchHectorStyle,
    [AppInstances.CEAT]: useGlobalSearchHectorStyle,
    [AppInstances.SGIFFOXXK]: useGlobalSearchSgiffoxxkStyle,
};

const inputInstancesStyles = {
    [AppInstances.AON]: useInputAonStyles,
    [AppInstances.AON_KOMPOSIT]: useInputAonStyles,
    [AppInstances.AON_DIGITAL]: useInputAonMotorStyles,
    [AppInstances.AON_MOTOR]: useInputAonMotorStyles,
    [AppInstances.HECTOR_DEMO]: useInputAonMotorStyles,
    [AppInstances.HECTOR]: useInputHectorStyles,
    [AppInstances.CEAT]: useInputHectorStyles,
    [AppInstances.SGIFFOXXK]: useInputSgiffoxxkStyles,
};

export const useGlobalSearchStyle = (props: unknown): ClassNameMap => {
    return globalSearchInstancesStyles[appInstance](props);
};

export const useInputStyles = (props: unknown): ClassNameMap => {
    return inputInstancesStyles[appInstance](props);
};
