import React, { useRef, ReactNode, useEffect } from 'react';
import clsx from 'clsx';
import { useDrag, useDrop } from 'react-dnd';
import { XYCoord } from 'dnd-core';

import { useDashboardContext } from 'src/shared/contexts';

import { useForceUpdate } from 'src/shared/hooks';

import { useDashboardStyle } from './dashboard-style';

interface Props {
    className: string;
    children: JSX.Element;
    moveCard?: (dragIndex: number, hoverIndex: number, isDrop?: boolean) => void;
    index: number;
    card: { dashboardWidget: string; position: number; isShown: boolean };
}

export const DashboardDraggableItem = ({
    children,
    className,
    moveCard,
    index,
    card,
}: Props): JSX.Element => {
    const { editMode } = useDashboardContext();
    const classes = useDashboardStyle({ editMode });

    const forceUpdate = useForceUpdate();

    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: 'card',
        hover(item: { id: string; index: number }, monitor) {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            const clientOffset = monitor.getClientOffset();

            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
        drop(item: { id: string; index: number }) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            moveCard(dragIndex, hoverIndex, true);
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'card',
        item: () => ({ id: card?.dashboardWidget, index }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    if (index || index === 0) {
        drag(drop(ref));
    } else drag(drop(null));

    const chilrenWithProps = React.Children.map<ReactNode, ReactNode>(
        children,
        (child) => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, { editMode });
            }
            return child;
        }
    );

    useEffect(() => {
        if (editMode) {
            forceUpdate();
        }
    }, [editMode]);

    return (
        <div
            key={card.dashboardWidget + editMode}
            ref={editMode ? ref : null}
            className={clsx([
                classes.widget,
                classes[className],
                isDragging ? classes.drag : null,
            ])}
        >
            {chilrenWithProps}
        </div>
    );
};
