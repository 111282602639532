import React, { SetStateAction, Dispatch, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, IconButton, Grid, Tooltip } from '@material-ui/core';
import TelegramIcon from '@material-ui/icons/Telegram';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { IThemeTab, ThemeTabs, ThemeCircularProgress } from 'src/theming';
import { useNotificationCenterContext } from 'src/shared/contexts';

import { NotificationCenterSections } from '../shared/contexts/notification-center-context/notification-center-enums';

import { useNotificationCenterStyle } from './notification-center-styles';
import { defaultValues } from 'src/shared/contexts/notification-center-context/notification-center-use-new-message';

const generateNotificationCenterNavigationPanel = (
    action: Dispatch<SetStateAction<NotificationCenterSections>>
): IThemeTab[] => [
    {
        title: 'notification-center:unread',
        value: NotificationCenterSections.UNREAD_MESSAGES,
        titleAsKeyForTranslation: true,
        action: () => action(NotificationCenterSections.UNREAD_MESSAGES),
    },
    {
        title: 'notification-center:read',
        value: NotificationCenterSections.READ_MESSAGES,
        titleAsKeyForTranslation: true,
        action: () => action(NotificationCenterSections.READ_MESSAGES),
    },
    {
        title: 'notification-center:archived',
        value: NotificationCenterSections.ARCHIVED_MESSAGES,
        titleAsKeyForTranslation: true,
        action: () => action(NotificationCenterSections.ARCHIVED_MESSAGES),
    },
];

export const NotificationCenterHeader = (): JSX.Element => {
    const classes = useNotificationCenterStyle();
    const { t } = useTranslation();

    const {
        notificationSection,
        setNotificationsSection,
        reset,
        setUserReplyId,
        userReplyId,
        isReceiveLoading,
    } = useNotificationCenterContext();

    const tabs = useMemo(
        () => generateNotificationCenterNavigationPanel(setNotificationsSection),
        []
    );

    const onNewMessage = (): void => {
        setNotificationsSection(NotificationCenterSections.NEW_MESSAGE);
    };

    const getBackToMessages = (): void => {
        setNotificationsSection(NotificationCenterSections.UNREAD_MESSAGES);
        reset(defaultValues);
        if (userReplyId) {
            setUserReplyId('');
        }
    };

    return (
        <div className={classes.header}>
            {notificationSection === NotificationCenterSections.NEW_MESSAGE ? (
                <Grid container alignItems='center'>
                    <IconButton
                        size='small'
                        className={classes.backButton}
                        onClick={getBackToMessages}
                    >
                        <KeyboardBackspaceIcon />
                    </IconButton>
                    <Typography variant='body1'>
                        {t('notification-center:newMessage')}
                    </Typography>
                </Grid>
            ) : (
                <Grid container>
                    <Grid item sm={11}>
                        <ThemeTabs
                            tabs={tabs}
                            disabled={isReceiveLoading}
                            activeTab={notificationSection}
                            tabsClassName={classes.tabs}
                        />
                    </Grid>
                    <Grid item sm={1}>
                        <div style={{ width: 25, height: 25 }}>
                            <IconButton
                                disabled={isReceiveLoading}
                                size='small'
                                className={classes.messageButton}
                                onClick={onNewMessage}
                            >
                                <Tooltip title={t('notification-center:newMessage')}>
                                    {isReceiveLoading ? (
                                        <ThemeCircularProgress
                                            isLoading={isReceiveLoading}
                                        />
                                    ) : (
                                        <TelegramIcon />
                                    )}
                                </Tooltip>
                            </IconButton>
                        </div>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};
