import { AppInstances } from './shared/constants';

export const isDevelopment = process.env.NODE_ENV === 'development';
export const baseUrl = isDevelopment
    ? process.env.REACT_APP_PUBLIC_HTTP_BASE_URL
    : window.location.origin;
export const fallbackLanguage = process.env.REACT_APP_FALLBACK_LANG;
export const appInstance = process.env.REACT_APP_APP_INSTANCE;
export const companyName = process.env.REACT_APP_COMPANY_NAME || '';
export const applicationVersion = process.env.REACT_APP_VERSION;
export const isHectorInstance = appInstance === AppInstances.HECTOR;
export const isSGIFFOXXKInstance = appInstance === AppInstances.SGIFFOXXK;
export const isAonMotorInstance = appInstance === AppInstances.AON_MOTOR;
export const isAonDigitalInstance = appInstance === AppInstances.AON_DIGITAL;
export const isCeatInstance = appInstance === AppInstances.CEAT;
export const isHectorDemoInstance = appInstance === AppInstances.HECTOR_DEMO;
export const isAonInstance = appInstance === AppInstances.AON;
export const isAonKompositInstance = appInstance === AppInstances.AON_KOMPOSIT;
