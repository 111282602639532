import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ValueChangeColorScheme } from '../common';

interface Props {
    offset?: number;
    colorScheme: ValueChangeColorScheme;
}

export const useDashboardNumericValueStyle = makeStyles<Theme, Props>((theme: Theme) =>
    createStyles({
        container: {
            position: 'relative',
            display: 'flex',
            marginLeft: 30,
            alignItems: 'center',
            flexGrow: 1,
            width: '100%',
        },
        value: {
            fontSize: 36,
            fontWeight: 'bold',
        },
        unit: {
            fontSize: 30,
            marginTop: 6,
            marginLeft: 8,
            color: theme.palette.text.secondary,
        },
        offsetContainer: {
            position: 'absolute',
            top: 0,
            left: -24,
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            color: ({ offset, colorScheme }) =>
                getOffsetColor(offset, colorScheme, theme),
        },
        offsetIndicatorContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        offsetIndicator: {
            fontSize: 22,
        },
        offsetValue: {
            fontSize: 22,
        },
        offsetUnit: {
            marginLeft: 4,
        },
    })
);

function getOffsetColor(
    offset: number | undefined,
    colorScheme: ValueChangeColorScheme,
    theme: Theme
): string {
    const noChangeColor = theme.palette.text.primary;
    let increaseColor = noChangeColor;
    let decreaseColor = noChangeColor;

    if (colorScheme === ValueChangeColorScheme.IncreaseBadDecreaseGood) {
        increaseColor = theme.palette.error.main;
        decreaseColor = theme.palette.success.main;
    } else if (colorScheme === ValueChangeColorScheme.IncreaseGoodDecreaseBad) {
        increaseColor = theme.palette.success.main;
        decreaseColor = theme.palette.error.main;
    }

    if (!offset || offset === 0) {
        return noChangeColor;
    }

    return offset > 0 ? increaseColor : decreaseColor;
}
