import { makeStyles, Theme } from '@material-ui/core/styles';
import { THEME_MODES } from 'src/theming';

export const useStyle = makeStyles((theme: Theme) => {
    return {
        pageContainer: {
            width: '100vw',
            height: '100vh',
            backgroundColor: theme.palette.primary.light,
            overflow: 'hidden',
        },
        previewContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            position: 'relative',
            width: '45vw',
            height: '100vh',
            padding: `${theme.spacing(10)}px`,
            backgroundImage: `url('/static/images/login-background.png')`,
            backgroundSize: 'cover',
            backgroundPosition: '0 0',
            backgroundRepeat: 'no-repeat',
        },
        aonPreviewContainer: {
            display: 'flex',
            position: 'relative',
            justifyContent: 'flex-start',
            width: '45vw',
            height: '100vh',
            padding: `${theme.spacing(10)}px`,
        },
        mask: {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.secondary.light,
        },
        aonMask: {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.primary.main,
        },
        functionalityContainer: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '55vw',
            height: '100vh',
        },
        logo: {
            maxWidth: 200,
            position: 'absolute',
            top: 50,
            left: 50,
            zIndex: 10,
            '& img': {
                width: '100%',
            },
        },
        aonLogo: {
            top: 60,
            left: 60,
        },
        dots: {
            width: '100%',
            position: 'absolute',
            right: 0,
            top: 0,
            '& img': {
                width: '100%',
            },
        },
        portalInformation: {
            position: 'relative',
            width: 300,
            alignSelf: 'flex-end',
            padding: `${theme.spacing(1)}rem 0`,
            zIndex: 100,
            '&:before, &:after': {
                content: 'url(/static/images/border-corner.svg)',
                position: 'absolute',
                background: 'transparent',
            },
            '&:before': {
                top: 0,
                left: 0,
                transform: 'rotate(180deg)',
            },
            '&:after': {
                bottom: 0,
                right: 0,
            },
        },
        portalInformationMessage: {
            position: 'relative',
            color: theme.palette.info.contrastText,
            fontSize: '2.4rem',
            zIndex: 5,
        },
        toolbarContainer: {
            display: 'flex',
            position: 'absolute',
            top: 0,
            right: 0,
            alignItems: 'center',
            width: '100%',
            marginBottom: 'auto',
            marginTop: `${theme.spacing(1)}rem`,
            padding: `0 ${theme.spacing(10)}px`,
        },
        switcherContainer: {
            width: 'fit-content',
        },
        backButton: {
            opacity: 0.6,
        },
        form: {
            width: 400,
        },
        footer: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: 28,
            backgroundColor: theme.palette.secondary.main,
        },
        aonFooter: {
            '& a': {
                color: theme.palette.info.contrastText,
            },
        },
        darkElement: {
            display: theme.palette.type === THEME_MODES.DARK ? 'initial' : 'none',
        },
        lightElement: {
            display: theme.palette.type === THEME_MODES.DARK ? 'none' : 'initial',
        },
    };
});
