import React, { BaseSyntheticEvent } from 'react';

import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    DialogActions,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import {
    useStyle,
    useModalClasses,
    useStyleDialogContent,
    useStyleDialogTitle,
    useStyleDialogActions,
} from './theme-modal-window-styles';

interface ThemeModalWindowProps {
    isOpen: boolean;
    onClose: (e?: BaseSyntheticEvent) => void;
    children: JSX.Element | JSX.Element[];
    width?: number;
    modalWindowClass?: string;
    withoutActions?: boolean;
}

export const ThemeModalWindow = (props: ThemeModalWindowProps): JSX.Element => {
    const { width, isOpen, onClose, children, modalWindowClass, withoutActions } = props;

    const classes = useStyle();
    const modalWindowClasses = useModalClasses({ width });
    const modalTitleClasses = useStyleDialogTitle();
    const modalContent = useStyleDialogContent();
    const modalActionsClasses = useStyleDialogActions();
    return (
        <Dialog
            disableScrollLock
            open={isOpen}
            onEscapeKeyDown={onClose}
            classes={modalWindowClasses}
            className={modalWindowClass}
        >
            <DialogTitle disableTypography classes={modalTitleClasses}>
                {children[0]}
            </DialogTitle>
            <DialogContent classes={modalContent}>{children[1]}</DialogContent>
            {!withoutActions && (
                <DialogActions classes={modalActionsClasses}>{children[2]}</DialogActions>
            )}
            <IconButton className={classes.closeButton} onClick={onClose} disableRipple>
                <CloseIcon />
            </IconButton>
        </Dialog>
    );
};

ThemeModalWindow.defaultProps = {
    isOpen: false,
    header: 'Header',
};
