import React, { BaseSyntheticEvent, ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { useAutoCompleteStyles } from './customer-add-form-styles';

import { customerAddFormPrepareQuaryForCountriesRequest } from '../customer-requests/customer-add-form-prepare-quary-for-countries-request';
import { useHttpClient } from '../../lib/http-client/use-http-client';
import { useDebounce } from '../../lib/custom-hooks/use-debounce';

export interface CustomerAddFormAutoComplete {
    countryCode: string;
    setValue: (fieldName: string, value: string) => void;
    trigger: () => void;
    error: boolean;
}

interface CustomerCountries {
    name: string;
    code: string;
}

export const CustomerAddFormAutoComplete = (
    props: CustomerAddFormAutoComplete
): JSX.Element => {
    const { setValue, trigger, error, countryCode } = props;
    const { i18n } = useTranslation(['customer-form']);
    const httpClient = useHttpClient();
    const [open, setOpen] = useState<boolean>(false);
    const autoCompleteClasses = useAutoCompleteStyles();
    const [inputValue, setInputValue] = useState<string>('');
    const [customerCountries, setCustomerCountries] = useState<CustomerCountries[]>([]);

    const debouncedInputValue = useDebounce(inputValue);
    const debouncedI18Language = useDebounce(i18n.language);

    const makeRequestForCountries = async (): Promise<CustomerCountries[]> => {
        const queriesString = customerAddFormPrepareQuaryForCountriesRequest(
            debouncedI18Language,
            debouncedInputValue
        );
        return httpClient.get<CustomerCountries[]>(
            `counries/dropdown-search?${queriesString}`
        );
    };
    const handleInputChange = (e: BaseSyntheticEvent, value: string): void => {
        const currentCountryCode = customerCountries.filter((el) => el.name === value)[0]
            ?.code;
        if (currentCountryCode) {
            setValue('countryCode', currentCountryCode);
        }
        setInputValue(value);
        trigger();
    };

    const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement>): void => {
        if (!e.target.value) {
            setValue('countryCode', '');
            trigger();
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const data = await makeRequestForCountries();
                if (Array.isArray(data)) {
                    setCustomerCountries(data);
                } else {
                    setCustomerCountries([]);
                }
            } catch {
                setCustomerCountries([]);
            }
        })();
    }, [debouncedInputValue, debouncedI18Language]);

    useEffect(() => {
        if (countryCode && Array.isArray(customerCountries)) {
            const countryName =
                customerCountries?.find((country) => country.code === countryCode)
                    ?.name || '';
            setInputValue(countryName);
        }
    }, [countryCode, customerCountries]);

    return (
        <Autocomplete
            fullWidth
            open={open}
            classes={
                autoCompleteClasses && {
                    clearIndicator: autoCompleteClasses.clearIndicator,
                    popupIndicator: autoCompleteClasses.popupIndicator,
                }
            }
            filterOptions={(options) => options}
            onInputChange={handleInputChange}
            getOptionLabel={(option: CustomerCountries) => option.name}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            options={customerCountries}
            inputValue={inputValue}
            renderInput={(params) => (
                <TextField
                    required
                    error={error}
                    onChange={handleTextFieldChange}
                    className={autoCompleteClasses.textField}
                    variant='outlined'
                    {...params}
                />
            )}
        />
    );
};
