import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { Grid, List, ListItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { ThemeNestedMenu, ThemeLoader } from 'src/theming';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useAbilityCtx } from 'src/roleAccesses';

import { ContractDetailsListItem } from '../contract-details-list-item';

import { ContractDetailsViewConstructionResponseBody } from './contract-details-view-contruction-response-body';

import {
    useStyle,
    useContractDetailsViewDataStyle,
} from '../contracts-details-view-styles';

import { generateContractsDetailsAdditionalMenu } from '../contract-details-view-configs';

interface ContractDetailsViewConstructionProps {
    handleOpenContractEdit: () => void;
    hideThemeNestedMenu: boolean;
    contractNumber: string;
}

export const ContractDetailsViewConstruction = (
    props: ContractDetailsViewConstructionProps
): JSX.Element => {
    const { t } = useTranslation([
        'contract-details-view',
        'formatted-values',
        'damages-list',
        'common',
    ]);
    const ability = useAbilityCtx();
    const classes = useStyle();
    const contractDetailsViewDataClasses = useContractDetailsViewDataStyle();

    const { handleOpenContractEdit, hideThemeNestedMenu, contractNumber } = props;

    const router = useHistory();
    const { id } = useParams<{ id: string }>();
    const httpClient = useHttpClient();

    const [isLoadingDetails, setLoadingDetails] = useState(false);
    const [detailsData, setDetailsData] =
        useState<ContractDetailsViewConstructionResponseBody>();

    const makeRequestForDetails =
        async (): Promise<ContractDetailsViewConstructionResponseBody> => {
            setLoadingDetails(true);
            return httpClient.get<ContractDetailsViewConstructionResponseBody>(
                `contracts/${id}/construction`
            );
        };

    useEffect(() => {
        makeRequestForDetails()
            .then((data: ContractDetailsViewConstructionResponseBody) => {
                setDetailsData(data);
            })
            .finally(() => {
                setLoadingDetails(false);
            });
    }, []);

    const goTo = (pathname: string): void => {
        router.push(pathname);
    };

    const menuItems = useMemo(
        () =>
            generateContractsDetailsAdditionalMenu(
                t,
                null,
                handleOpenContractEdit,
                goTo,
                ability,
                id,
                contractNumber
            ),
        [t, contractNumber, ability]
    );

    const isDetailDataEmpty = useMemo(() => {
        if (detailsData) {
            const detailsDataKeys = Object.keys(detailsData);
            return !detailsDataKeys?.map((x) => detailsData[x])?.filter((x) => x)?.length;
        }
        return true;
    }, [detailsData]);

    return (
        <Grid container>
            <Grid container className={classes.titleAndMenuContainer}>
                <div style={{ flexGrow: 1 }} />
                {!hideThemeNestedMenu &&
                    Array.isArray(menuItems) &&
                    menuItems.length > 0 && (
                        <ThemeNestedMenu
                            menuItems={menuItems}
                            buttonClass={classes.additionalMenuButton}
                            buttonIcon={<MenuIcon className={classes.menuIcon} />}
                        />
                    )}
            </Grid>
            {isLoadingDetails && <ThemeLoader />}
            {isDetailDataEmpty && (
                <ListItem>
                    <ListItemText data-title>
                        {t('contract-details-view:noContractDetails')}
                    </ListItemText>
                </ListItem>
            )}
            {detailsData && !isLoadingDetails && (
                <List className={contractDetailsViewDataClasses.list}>
                    <ContractDetailsListItem
                        title='contract-details-view:contractDetails.contractType'
                        value={detailsData.contractType}
                        hidden={!detailsData?.contractType}
                    />
                    <ContractDetailsListItem
                        title='contract-details-view:contractDetails.builder'
                        value={detailsData.builder}
                        hidden={!detailsData?.builder}
                    />
                    <ContractDetailsListItem
                        title='contract-details-view:contractDetails.riskStreetPostCodeCity'
                        value={detailsData.riskLocation}
                        hidden={!detailsData?.riskLocation}
                    />
                    <ContractDetailsListItem
                        title='contract-details-view:contractDetails.startOfBuilding'
                        value={t('formatted-values:formattedDate', {
                            date: detailsData.startOfConstruction,
                        })}
                        hidden={!detailsData?.startOfConstruction}
                    />
                    <ContractDetailsListItem
                        title='contract-details-view:contractDetails.estimatedEnd'
                        value={t('formatted-values:formattedDate', {
                            date: detailsData.approximateEnd,
                        })}
                        hidden={!detailsData?.approximateEnd}
                    />
                    <ContractDetailsListItem
                        title='contract-details-view:contractDetails.locationOfTheConstructionSite'
                        value={detailsData?.constructionSiteLocation}
                        hidden={!detailsData?.constructionSiteLocation}
                    />
                    <ContractDetailsListItem
                        title='contract-details-view:contractDetails.construction'
                        value={detailsData.construction}
                        hidden={!detailsData?.construction}
                    />
                </List>
            )}
        </Grid>
    );
};
