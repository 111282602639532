import moment from 'moment';

import { DamageReportFormGetRecordDTO } from '../damage-report-form-types/damage-report-form-get-record-dto';
import { DamageReportFormBHVAndPHVType } from '../damage-report-form-types/damage-report-form-BHV-PHV-types';
export const damageReportFormBHVAndPHVGetReportRecordAdapter = (
    data: DamageReportFormGetRecordDTO,
    divisionState: string
): DamageReportFormBHVAndPHVType => {
    const offset = new Date().getTimezoneOffset() * 60000;
    return {
        id: data.id,
        division: divisionState,
        dayOfDamage: data.dayOfDamage
            ? moment(moment(data.dayOfDamage).valueOf() + offset).toISOString()
            : null,
        timeOfDamage: data.timeOfDamage
            ? moment(moment(data.timeOfDamage).valueOf() + offset).toISOString()
            : null,
        contractId: data.contractId,
        risk: data.risk,
        contractNumber: data.contractNumber,
        policyHolder: data.policyHolder,
        insuranceCompany: data.insuranceCompany,
        injuredPartySurname: data.victim?.name,
        injuredPartyRoad: data.victim?.street,
        injuredPartyPostcodeAndPlace: data.victim?.postcodeAndCity,
        injuredPartyPhonenumber: data.victim?.phone,
        injuredPartyEmail: data.victim?.emailAddress,
        damageOriginatorSurname: data.damageCauser?.name,
        damageOriginatorRoad: data.damageCauser?.street,
        damageOriginatorPostCodeAndPlace: data.damageCauser?.postcodeAndCity,
        damageOriginatorPhoneNumber: data.damageCauser?.phone,
        damageOriginatorEmail: data.damageCauser?.emailAddress,
        damageOriginatorRelationshipBetweenPolluterAndPolicyholder:
            data.damageCauser?.polluterRelateToThePolicyholder,
        damageInformationDamageType: data.damageInformationBhv?.damageType,
        damageInformationDamagePlace: data.damageInformationBhv?.damagePlace,
        damageInformationWhatWasDamaged: data.damageInformationBhv?.damageObject,
        damageInformationAreDamagedItemsOtherwiseInsured:
            data?.damageInformationBhv?.damagedItemsInsuredElsewhere,
        indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance:
            data?.damageInformationBhv?.insuranceCompanyOrNumberAndTypeInsurance,
        claimsForDamagesMadeAgainstYou:
            data.damageInformationBhv?.claimsForDamagesAlreadyBeenAssertedAgainst,
        estimatedDamageAmount:
            data.damageInformationBhv?.estimatedAmountOfDamage &&
            String(data.damageInformationBhv.estimatedAmountOfDamage),
        hasDamageAssessed: data.damageInformationBhv?.damageAlreadyBeenAssessed,
        ifYouAreAtFaultForThatIncident: data.damageInformationBhv?.quiltyOfWhatHappened,
        itWasYourTurnToBeAtFault:
            data.damageInformationBhv?.injuredPartyAlreadyBeenAcknowledged,
        existenceBetweenYouAndCoInsured:
            data.damageInformationBhv?.isAnyRelationshipWithInsureOrInjuredParty,
        whichExistenceBetweenYouAndCoInsured:
            data.damageInformationBhv?.whichIsAnyRelationshipWithInsureOrInjuredParty,
        doYouOrCoInsuredPersonPerformedActivity:
            data.damageInformationBhv?.performAnyActionsWithTheDamagedObject,
        whichActivity:
            data.damageInformationBhv?.whichPerformAnyActionsWithTheDamagedObject,
        detailedDescription: data.damageInformationBhv?.detailedDamageDescription,
        areYouEntitledToDeductInputTax: data.damageInformationBhv?.entitledDeductInputTax,
        isRecordedByThePolice: data.policeRecordBhv?.incidentRecordedByPolice,
        policeStation: data.policeRecordBhv?.policeStation,
        fileNumber: data.policeRecordBhv?.policeFileNumber,
        isWitnesses: data.witnessesInformationBhv?.isAnyWitnesses,
        witnessesSurname: data.witnessesInformationBhv?.name,
        witnessesRoad: data.witnessesInformationBhv?.street,
        witnessesPostcode: data.witnessesInformationBhv?.postCode,
        witnessesPlace: data.witnessesInformationBhv?.ort,
        witnessesPhoneNumber: data.witnessesInformationBhv?.phoneNumber,
        witnessesEmail: data.witnessesInformationBhv?.email,
        fileInfos: data.fileInfos,
    };
};
