import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, useMediaQuery } from '@material-ui/core';

import { ThemeModalWindow } from 'src/theming';

import { useStyle } from './non-users-damage-report-form-styles';

interface NonUsersDamageReportFormProps {
    isOpen: boolean;
    handleClose: () => void;
}

export const NonUsersDamageReportFormSuccessModalWindow = (
    props: NonUsersDamageReportFormProps
): JSX.Element => {
    const { isOpen, handleClose } = props;

    const isSmallModalWindow = useMediaQuery('(max-width:600px)');

    const classes = useStyle();

    const { t } = useTranslation(['non-users-damage-report-form']);

    return (
        <ThemeModalWindow
            withoutActions
            isOpen={isOpen}
            onClose={handleClose}
            width={isSmallModalWindow ? 300 : 400}
        >
            <Typography variant='h5'>{t('manyThanks')}</Typography>
            <Typography variant='body1' className={classes.successMessage}>
                {t('successMessage')}
            </Typography>
        </ThemeModalWindow>
    );
};
