import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    SyntheticEvent,
    useCallback,
    ChangeEvent,
    BaseSyntheticEvent,
} from 'react';
import { CheckboxItem } from 'src/theming';
import { useAbilityCtx, AbilityActions, AbilitySubjects } from 'src/roleAccesses';
import {
    useForm,
    UseFormReset,
    UseFormHandleSubmit,
    UseFormSetValue,
} from 'react-hook-form';
import { GridSortModel, GridSortModelParams } from '@material-ui/data-grid';

import { useAuth } from 'src/user-management/context-auth';

import { useProductsDropDownValues } from '../hooks';

interface ContractListContextType {
    search: string;
    vnrValue: string;
    page: number;
    filterValue: string;
    rowsPerPage: number;
    sortModel: GridSortModel;
    productsList: CheckboxItem[];
    handleChangeVNR: (e: BaseSyntheticEvent) => void;
    handleChangeList: (
        list: CheckboxItem[],
        name?: string,
        dontUpdatePage?: boolean
    ) => void;
    isSubmitted: boolean;
    reset: UseFormReset<ContractListFormData>;
    handleSubmit: UseFormHandleSubmit<ContractListFormData>;
    setValue: UseFormSetValue<ContractListFormData>;
    handleFilter: (_: SyntheticEvent, newFilter: string) => void;
    handleChangeSort: (params: GridSortModelParams) => void;
    handleChangePage: (_: SyntheticEvent, newPage: number) => void;
    handleChangeRowsPerPage: (e: ChangeEvent<HTMLInputElement>) => void;
}

type ContractListFormData = {
    search: string;
    vnr: string;
    page: number;
    rowsPerPage: number;
};

export const defaultContractListSettingsFormData = {
    page: 0,
    rowsPerPage: 10,
    vnr: '',
    search: '',
};

export const ContractListSettings = createContext<ContractListContextType>(null);

export const ContractListSettingsProvider = ({ children }): JSX.Element => {
    const { handleSubmit, watch, setValue, formState, reset } =
        useForm<ContractListFormData>({
            mode: 'onChange',
            defaultValues: defaultContractListSettingsFormData,
        });
    const ability = useAbilityCtx();
    const [filterValue, setFilterValue] = useState('active');
    const [sortModel, setSortModel] = useState<GridSortModel>();
    const [productsList, setProductsList] = useState<CheckboxItem[]>([]);
    const [isMounted, setMounted] = useState<boolean>(false);

    const { authorized } = useAuth();

    const productDropDownValues = useProductsDropDownValues(
        isMounted &&
            authorized &&
            ability.can(AbilityActions.see, AbilitySubjects.contractListProductsDropDown)
    );

    const [vnrValue, page, rowsPerPage, search] = watch([
        'vnr',
        'page',
        'rowsPerPage',
        'search',
    ]);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (Array.isArray(productDropDownValues) && productDropDownValues.length > 0) {
            handleChangeList(productDropDownValues);
        }
    }, [productDropDownValues]);

    const handleFilter = (_: SyntheticEvent, newFilter: string): void => {
        if (newFilter) {
            setValue('page', 0);
            setFilterValue(newFilter);
        }
    };

    const handleChangeList = useCallback(
        (newList: CheckboxItem[], name?: string, dontUpdatePage?: boolean): void => {
            if (!dontUpdatePage) {
                setValue('page', 0);
            }
            setProductsList(newList);
        },
        [setValue]
    );

    const handleChangeVNR = useCallback(
        (e: BaseSyntheticEvent): void => {
            setValue('page', 0);
            setValue('vnr', e.target.value);
        },
        [setValue]
    );

    const handleChangePage = (_: SyntheticEvent, newPage: number): void => {
        setValue('page', newPage);
    };

    const handleChangeRowsPerPage = (e: ChangeEvent<HTMLInputElement>): void => {
        setValue('rowsPerPage', Number(e.target.value));
        setValue('page', 0);
    };

    const handleChangeSort = useCallback((sortOrderParams: GridSortModelParams): void => {
        setSortModel(sortOrderParams.sortModel);
    }, []);

    const contextProps: ContractListContextType = {
        reset,
        search,
        vnrValue,
        page,
        rowsPerPage,
        filterValue,
        sortModel,
        productsList,
        handleChangeVNR,
        handleChangeList,
        handleSubmit,
        handleFilter,
        setValue,
        handleChangeSort,
        handleChangePage,
        handleChangeRowsPerPage,
        isSubmitted: formState.isSubmitted,
    };

    return (
        <ContractListSettings.Provider value={contextProps}>
            {children}
        </ContractListSettings.Provider>
    );
};

export const useContractsListSettings = (): ContractListContextType =>
    useContext<ContractListContextType>(ContractListSettings);
