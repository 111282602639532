import { Divisions } from 'src/shared/constants';

import { DamageReportFormKFZWControls } from '../damage-report-form-divisions/damage-report-form-KFZW/damage-report-form-KFZW-controls';
import { DamageReportFormGBW } from '../damage-report-form-divisions/damage-report-form-GBW-HR-GESCH/damage-report-form-GBW-HR-GESCH';
import { DamageReportFormTRANSP } from '../damage-report-form-divisions/damage-report-form-TRANSP/damage-report-form-TRANSP';
import { DamageReportFormBHVAndPHV } from '../damage-report-form-divisions/damage-report-form-BHV-PHV/damage-report-form-BHV-PHV';
import { FormCollection } from './damage-report-form-factory-types';

export const DamageReportFormsSgiffoxxkAndAonIntances: FormCollection = {
    [Divisions.KFZW]: DamageReportFormKFZWControls,
    [Divisions.GBW]: DamageReportFormGBW,
    [Divisions.HR]: DamageReportFormGBW,
    [Divisions.GESCH]: DamageReportFormGBW,
    [Divisions.TRANSP]: DamageReportFormTRANSP,
    [Divisions.BHV]: DamageReportFormBHVAndPHV,
    [Divisions.PHV]: DamageReportFormBHVAndPHV,
};
