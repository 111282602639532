import React, { SyntheticEvent } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {
    useToggleButtonsGroupStyle,
    useToggleButtonStyle,
} from './theme-toggle-buttons-style';

export interface ToggleButtonItem {
    title: string;
    value: string;
}

interface ThemeToggleButtonsProps {
    buttonsList: ToggleButtonItem[];
    value: string;
    handleChange: (event: SyntheticEvent, newFilter: string) => void;
    injectedClassName?: string;
}

export const ThemeToggleButtons = (props: ThemeToggleButtonsProps): JSX.Element => {
    const toggleButtonsGroupClasses = useToggleButtonsGroupStyle();
    const toggleButtonClasses = useToggleButtonStyle();

    const { value, handleChange, buttonsList, injectedClassName } = props;

    return (
        <ToggleButtonGroup
            exclusive
            value={value}
            onChange={handleChange}
            classes={toggleButtonsGroupClasses}
            className={injectedClassName}
        >
            {buttonsList.map((button) => {
                return (
                    <ToggleButton
                        key={button.value}
                        value={button.value}
                        classes={toggleButtonClasses}
                    >
                        {button.title}
                    </ToggleButton>
                );
            })}
        </ToggleButtonGroup>
    );
};
