import { TFunction } from 'i18next';
import { SelectItem } from 'src/theming';

export enum DAMAGE_ITEMS_SELECTION_VALUES {
    FIRE = 'fire',
    BURGLARY = 'burglary',
    STORM = 'storm',
    TAP_WATER = 'tapWater',
    GLASS_BREAKAGE = 'glassBreakage',
    LIGHTNING_STRIKE = 'lightningStrike',
    ELEMENTARY = 'elementary',
    LOSS_OF_RENT = 'lossOfRent',
    OTHERS = 'others',
    SELECT_REQUEST = 'selectRequest',
}

export const generateDamageReportFormDamageItemsSelectionValues = (
    t: TFunction
): SelectItem[] => {
    return [
        {
            title: t('damages-report:damageItemsOptions.selectRequest'),
            value: DAMAGE_ITEMS_SELECTION_VALUES.SELECT_REQUEST,
        },
        {
            title: t('damages-report:damageItemsOptions.fire'),
            value: DAMAGE_ITEMS_SELECTION_VALUES.FIRE,
        },
        {
            title: t('damages-report:damageItemsOptions.burglary'),
            value: DAMAGE_ITEMS_SELECTION_VALUES.BURGLARY,
        },
        {
            title: t('damages-report:damageItemsOptions.storm'),
            value: DAMAGE_ITEMS_SELECTION_VALUES.STORM,
        },
        {
            title: t('damages-report:damageItemsOptions.tapWater'),
            value: DAMAGE_ITEMS_SELECTION_VALUES.TAP_WATER,
        },
        {
            title: t('damages-report:damageItemsOptions.glassBreakage'),
            value: DAMAGE_ITEMS_SELECTION_VALUES.GLASS_BREAKAGE,
        },
        {
            title: t('damages-report:damageItemsOptions.lightningStrike'),
            value: DAMAGE_ITEMS_SELECTION_VALUES.LIGHTNING_STRIKE,
        },
        {
            title: t('damages-report:damageItemsOptions.elementary'),
            value: DAMAGE_ITEMS_SELECTION_VALUES.ELEMENTARY,
        },
        {
            title: t('damages-report:damageItemsOptions.lossOfRent'),
            value: DAMAGE_ITEMS_SELECTION_VALUES.LOSS_OF_RENT,
        },
        {
            title: t('damages-report:damageItemsOptions.others'),
            value: DAMAGE_ITEMS_SELECTION_VALUES.OTHERS,
        },
    ];
};
