import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import {
    FormControl,
    FormControlLabel,
    OutlinedInput,
    Box,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import TelegramIcon from '@material-ui/icons/Telegram';

import { ThemeSelectInput } from 'src/theming';
import { NotificationCenterSections } from 'src/shared/contexts/notification-center-context/notification-center-enums';
import { useNotificationCenterContext } from 'src/shared/contexts/notification-center-context/contexts-notification-center';
import { NotificationCenterNewMessageFormData } from 'src/shared/contexts/notification-center-context/notification-center-use-new-message';
import { useGlobalFilter } from 'src/shared/contexts';
import { useCustomerAdvisors, useUsers } from 'src/shared/hooks';
import { useAbilityCtx, AbilityActions, AbilitySubjects } from 'src/roleAccesses';

import { defaultValues } from 'src/shared/contexts/notification-center-context/notification-center-use-new-message';
import { useSendMessage } from './notification-center-use-send-message';
import {
    useNotificationCenterStyle,
    useFormControlLabelStyles,
    useFormControlStyles,
    useInputStyles,
} from './notification-center-styles';

export const NotificationSectionNewMessageForm = (): JSX.Element => {
    const classes = useNotificationCenterStyle();
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();
    const ability = useAbilityCtx();
    const inputClasses = useInputStyles();
    const { t } = useTranslation(['notification-center', 'errors']);

    const {
        values,
        setValue,
        setNotificationsSection,
        userReplyName,
        userReplyId,
        setUserReplyId,
        setUserReplyName,
        reset,
        formState,
    } = useNotificationCenterContext();
    const { filter } = useGlobalFilter();

    const { customerAdvisor, customerConnection, customer, user, message } = values;

    const accessToCustomerConnectionForm = ability.can(
        AbilityActions.see,
        AbilitySubjects.notificationNewMessageCustomerConnectionForm
    );
    const accessToCustomerAdviserForm = ability.can(
        AbilityActions.see,
        AbilitySubjects.notificationNewMessageCustomerAdviserForm
    );

    const customerAdvisors = useCustomerAdvisors(accessToCustomerAdviserForm);
    const users = useUsers(
        values.customerConnection,
        values.customer,
        accessToCustomerConnectionForm
    );

    const onSuccess = (): void => {
        if (userReplyId) {
            setUserReplyId('');
            setUserReplyName('');
        }
        reset(defaultValues);
        setNotificationsSection(NotificationCenterSections.UNREAD_MESSAGES);
    };

    const { isLoading: sendLoading, send } = useSendMessage(onSuccess);

    const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setValue(
            e.target.name as keyof NotificationCenterNewMessageFormData,
            e.target.value
        );
    };

    const sendMessage = (): void => {
        send(values);
    };

    const customerAdvisorItems = customerAdvisors?.map((item) => ({
        title: `${item.name} ${item.surname}`,
        value: item.id,
    }));

    const userItems = users.map((item) => ({
        title: `${item.name} ${item.surname}`,
        value: item.id,
    }));

    const customerConnectionItems = filter?.customerConnections?.filter(
        (item) => item.value !== 'all'
    );

    const customerItems =
        filter?.customerConnections
            ?.find((item) => item.value === customerConnection)
            ?.data?.customers?.map((item) => ({
                title: item.customer,
                value: item.id,
            })) || [];

    return (
        <div className={classes.formContainer}>
            {!userReplyId && accessToCustomerAdviserForm && (
                <FormControl fullWidth classes={formControlClasses} variant='outlined'>
                    <FormControlLabel
                        classes={formControlLabelClasses}
                        control={
                            <ThemeSelectInput
                                editMode
                                value={customerAdvisor}
                                name='customerAdvisor'
                                items={customerAdvisorItems}
                                onChange={onChange}
                            />
                        }
                        label={t('notification-center:newMessageFields.customerAdvisor')}
                        labelPlacement='top'
                    />
                </FormControl>
            )}
            {userReplyId && (
                <FormControl fullWidth classes={formControlClasses} variant='outlined'>
                    <FormControlLabel
                        classes={formControlLabelClasses}
                        control={
                            <OutlinedInput
                                disabled
                                classes={inputClasses}
                                value={userReplyName}
                            />
                        }
                        labelPlacement='top'
                        label=''
                    />
                </FormControl>
            )}
            {accessToCustomerConnectionForm && (
                <>
                    {!userReplyId && (
                        <>
                            <FormControl
                                fullWidth
                                classes={formControlClasses}
                                variant='outlined'
                            >
                                <FormControlLabel
                                    classes={formControlLabelClasses}
                                    control={
                                        <ThemeSelectInput
                                            editMode
                                            value={customerConnection}
                                            name='customerConnection'
                                            items={customerConnectionItems}
                                            onChange={onChange}
                                        />
                                    }
                                    label={t(
                                        'notification-center:newMessageFields.customerConnection'
                                    )}
                                    labelPlacement='top'
                                />
                            </FormControl>
                            <FormControl
                                fullWidth
                                classes={formControlClasses}
                                variant='outlined'
                            >
                                <FormControlLabel
                                    classes={formControlLabelClasses}
                                    control={
                                        <ThemeSelectInput
                                            editMode
                                            value={customer}
                                            name='customer'
                                            items={customerItems}
                                            onChange={onChange}
                                        />
                                    }
                                    label={t(
                                        'notification-center:newMessageFields.customer'
                                    )}
                                    labelPlacement='top'
                                />
                            </FormControl>
                            <FormControl
                                fullWidth
                                classes={formControlClasses}
                                variant='outlined'
                            >
                                <FormControlLabel
                                    classes={formControlLabelClasses}
                                    control={
                                        <ThemeSelectInput
                                            editMode
                                            value={user}
                                            name='user'
                                            items={userItems}
                                            onChange={onChange}
                                        />
                                    }
                                    label={t('notification-center:newMessageFields.user')}
                                    labelPlacement='top'
                                />
                            </FormControl>
                        </>
                    )}
                </>
            )}
            <Box pt={4}>
                <FormControl fullWidth classes={formControlClasses} variant='outlined'>
                    <FormControlLabel
                        classes={formControlLabelClasses}
                        control={
                            <OutlinedInput
                                multiline
                                rows={4}
                                value={message}
                                onChange={onChange}
                                name='message'
                                placeholder={t(
                                    'notification-center:newMessageFields.addTextHere'
                                )}
                            />
                        }
                        labelPlacement='top'
                        label=''
                    />
                </FormControl>
            </Box>
            <Box width='fit-content' ml='auto' pt={4}>
                <IconButton
                    disabled={userReplyId ? !message : !formState.isValid || sendLoading}
                    size='small'
                    className={classes.messageButton}
                    onClick={sendMessage}
                >
                    <Tooltip title={t('notification-center:sendMessage')}>
                        <TelegramIcon />
                    </Tooltip>
                </IconButton>
            </Box>
        </div>
    );
};
