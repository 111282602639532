import React from 'react';

import { IconProps } from './index';

export const TranspIcon = (props: IconProps): JSX.Element => {
    const { fill } = props;
    return (
        <svg width='55' height='55' fill={fill} xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M 45.832031 18.332031 L 38.957031 18.332031 L 38.957031 9.167969 L 6.875 9.167969 C 4.355469 9.167969 2.292969 11.230469 2.292969 13.75 L 2.292969 38.957031 L 6.875 38.957031 C 6.875 42.761719 9.945312 45.832031 13.75 45.832031 C 17.554688 45.832031 20.625 42.761719 20.625 38.957031 L 34.375 38.957031 C 34.375 42.761719 37.445312 45.832031 41.25 45.832031 C 45.054688 45.832031 48.125 42.761719 48.125 38.957031 L 52.707031 38.957031 L 52.707031 27.5 Z M 13.75 42.394531 C 11.847656 42.394531 10.3125 40.859375 10.3125 38.957031 C 10.3125 37.054688 11.847656 35.519531 13.75 35.519531 C 15.652344 35.519531 17.1875 37.054688 17.1875 38.957031 C 17.1875 40.859375 15.652344 42.394531 13.75 42.394531 Z M 44.6875 21.769531 L 49.179688 27.5 L 38.957031 27.5 L 38.957031 21.769531 Z M 41.25 42.394531 C 39.347656 42.394531 37.8125 40.859375 37.8125 38.957031 C 37.8125 37.054688 39.347656 35.519531 41.25 35.519531 C 43.152344 35.519531 44.6875 37.054688 44.6875 38.957031 C 44.6875 40.859375 43.152344 42.394531 41.25 42.394531 Z M 41.25 42.394531'
                fill='#1581C1'
            />
        </svg>
    );
};
