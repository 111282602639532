import React, { BaseSyntheticEvent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
    FormControl,
    FormControlLabel,
    Grid,
    OutlinedInput,
    Typography,
} from '@material-ui/core';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

import { ThemePhoneNumberInput, ThemeSwitcher, ThemeDatePicker } from 'src/theming';

import {
    useFormControlLabelStyles,
    useFormControlStyles,
    useInputStyles,
    useStyle,
} from '../../damage-report-form-styles/damage-report-form-styles';

interface DamageReportFormDriverInformationBlockProps {
    readonly: boolean;
    firstName: string;
    surname: string;
    tenant: string;
    street: string;
    postCode: string;
    city: string;
    phoneNumber: string;
    email: string;
    drivingLicenseAvailable: boolean;
    drivingLicenseClass: string;
    dateOfIssue: ParsableDate;
    handleChangeValue: (e: BaseSyntheticEvent) => void;
    handleChangeDate: (name, value: ParsableDate) => void;
    handleChangeHaveLicenseNumber: () => void;
    errors: {
        driverInformationEmail: boolean;
    };
}

export const DamageReportFormDriverInformationBlock = memo(
    (props: DamageReportFormDriverInformationBlockProps): JSX.Element => {
        const classes = useStyle();
        const formControlClasses = useFormControlStyles();
        const formControlLabelClasses = useFormControlLabelStyles();
        const inputClasses = useInputStyles();

        const {
            firstName,
            surname,
            tenant,
            street,
            postCode,
            city,
            phoneNumber,
            email,
            drivingLicenseAvailable,
            drivingLicenseClass,
            dateOfIssue,
            handleChangeValue,
            handleChangeDate,
            handleChangeHaveLicenseNumber,
            errors,
            readonly,
        } = props;

        const { t } = useTranslation(['common', 'damage-report']);

        return (
            <Grid container spacing={2}>
                <Typography variant='h6' className={classes.formBlockTitle}>
                    {t('damages-report:driverInformation.title')}
                </Typography>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled={readonly}
                                    classes={inputClasses}
                                    value={firstName}
                                    onChange={handleChangeValue}
                                    name='driverInformationFirstName'
                                />
                            }
                            label={t('damages-report:driverInformation.firstName')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled={readonly}
                                    classes={inputClasses}
                                    value={surname}
                                    onChange={handleChangeValue}
                                    name='driverInformationSurname'
                                />
                            }
                            label={t('damages-report:driverInformation.surname')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled={readonly}
                                    classes={inputClasses}
                                    value={tenant}
                                    onChange={handleChangeValue}
                                    name='driverInformationTenant'
                                />
                            }
                            label={t('damages-report:driverInformation.tenant')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled={readonly}
                                    classes={inputClasses}
                                    value={street}
                                    onChange={handleChangeValue}
                                    name='driverInformationStreet'
                                />
                            }
                            label={t('damages-report:driverInformation.road')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled={readonly}
                                    classes={inputClasses}
                                    value={postCode}
                                    onChange={handleChangeValue}
                                    name='driverInformationPostCode'
                                />
                            }
                            label={t('damages-report:driverInformation.postCode')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled={readonly}
                                    classes={inputClasses}
                                    value={city}
                                    onChange={handleChangeValue}
                                    name='driverInformationCity'
                                />
                            }
                            label={t('damages-report:driverInformation.place')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <ThemePhoneNumberInput
                                    disabled={readonly}
                                    value={phoneNumber}
                                    onChange={handleChangeValue}
                                    name='driverInformationPhoneNumber'
                                />
                            }
                            label={t('damages-report:driverInformation.phoneNumber')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        error={Boolean(errors.driverInformationEmail)}
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled={readonly}
                                    type='email'
                                    classes={inputClasses}
                                    value={email}
                                    onChange={handleChangeValue}
                                    name='driverInformationEmail'
                                />
                            }
                            label={t('damages-report:driverInformation.emailAddress')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.fullWidth)}>
                    <div style={{ width: 'fit-content' }}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <ThemeSwitcher
                                        disabled={readonly}
                                        checked={!drivingLicenseAvailable}
                                        onChange={handleChangeHaveLicenseNumber}
                                        leftLabel={t('yes')}
                                        rightLabel={t('no')}
                                    />
                                }
                                label={t(
                                    'damages-report:driverInformation.haveLicenseNumber'
                                )}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </div>
                </Grid>
                {drivingLicenseAvailable && (
                    <>
                        <Grid item className={clsx(classes.thirdPart)}>
                            <FormControl
                                fullWidth
                                classes={formControlClasses}
                                variant='outlined'
                            >
                                <FormControlLabel
                                    classes={formControlLabelClasses}
                                    control={
                                        <OutlinedInput
                                            disabled={readonly}
                                            classes={inputClasses}
                                            value={drivingLicenseClass}
                                            onChange={handleChangeValue}
                                            name='driverInformationDrivingLicenseClass'
                                        />
                                    }
                                    label={t(
                                        'damages-report:driverInformation.drivingLicenseClass'
                                    )}
                                    labelPlacement='top'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item className={clsx(classes.thirdPart)}>
                            <FormControl
                                fullWidth
                                classes={formControlClasses}
                                variant='outlined'
                                className={classes.dateField}
                            >
                                <FormControlLabel
                                    classes={formControlLabelClasses}
                                    control={
                                        <ThemeDatePicker
                                            editMode={!readonly}
                                            inputVariant='outlined'
                                            value={dateOfIssue}
                                            onChange={handleChangeDate}
                                            name='driverInformationDateOfIssue'
                                            maxDate={Date.now()}
                                            maxDateErrorMessage={t(
                                                'damages-report:dateMayNotBeInTheFuture'
                                            )}
                                        />
                                    }
                                    label={t(
                                        'damages-report:driverInformation.dateOfIssue'
                                    )}
                                    labelPlacement='top'
                                />
                            </FormControl>
                        </Grid>
                        <div className={clsx(classes.thirdPart)} />
                    </>
                )}
            </Grid>
        );
    }
);
