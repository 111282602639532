import React from 'react';
import useDarkMode from 'use-dark-mode';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { ThemeLayout } from 'src/theming';
import { FAQList } from 'src/faq';
import { APP_MODULES } from 'src/shared/constants';

export const FAQPage = (): JSX.Element => {
    const darkMode = useDarkMode();
    return (
        <ThemeLayout darkMode={darkMode} module={APP_MODULES.FAQ}>
            <DndProvider backend={HTML5Backend}>
                <FAQList />
            </DndProvider>
        </ThemeLayout>
    );
};
