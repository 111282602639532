import React, { BaseSyntheticEvent, memo } from 'react';
import clsx from 'clsx';

import { Grid } from '@material-ui/core';

import { ThemeOutlineInput } from 'src/theming/theme-input/theme-outline-input';

import {
    useFormControlLabelStyles,
    useFormControlStyles,
    useInputStyles,
} from '../customer-add-form/customer-add-form-styles';

interface DamageReportFormFullInputProps {
    value: unknown;
    handleChangeValue: (event: BaseSyntheticEvent) => void;
    label: string;
    name: string;
    disabled?: boolean;
    error?: boolean;
}

export const CustomerFormFullInput = memo(
    ({
        value,
        handleChangeValue,
        label,
        name,
        disabled,
        error,
        ...rest
    }: DamageReportFormFullInputProps) => {
        const formControlClasses = useFormControlStyles();
        const formControlLabelClasses = useFormControlLabelStyles();
        const inputClasses = useInputStyles();
        return (
            <Grid item className={clsx(inputClasses.fullWidth)}>
                <ThemeOutlineInput
                    disabled={disabled}
                    inputClasses={inputClasses}
                    value={value}
                    label={label}
                    formControlLabelClasses={formControlLabelClasses}
                    formControlClasses={formControlClasses}
                    handleChangeValue={handleChangeValue}
                    name={name}
                    error={error}
                    {...rest}
                />
            </Grid>
        );
    }
);
