import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { Grid, List, ListItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { ThemeLoader, ThemeNestedMenu } from 'src/theming';
import { useAbilityCtx } from 'src/roleAccesses';

import { ContractDetailsListItem } from '../contract-details-list-item';

import {
    useStyle,
    useContractDetailsViewDataStyle,
} from '../contracts-details-view-styles';
import { useMaschineStyles } from './contract-details-view-maschine-styles';

import { generateContractsDetailsAdditionalMenu } from '../contract-details-view-configs';

import { ContractDetailsViewMaschineDTO } from './contract-details-view-maschine-dto';

interface ContractDetailsViewMaschineProps {
    handleOpenContractEdit: () => void;
    hideThemeNestedMenu: boolean;
    contractNumber: string;
}

export const ContractDetailsViewMaschine = (
    props: ContractDetailsViewMaschineProps
): JSX.Element => {
    const ability = useAbilityCtx();
    const classes = useStyle();
    const contractDetailsViewDataClasses = useContractDetailsViewDataStyle();
    const maschineClasses = useMaschineStyles();

    const { t } = useTranslation([
        'contract-details-view',
        'formatted-values',
        'damages-list',
        'common',
    ]);

    const router = useHistory();
    const { id } = useParams<{ id: string }>();
    const httpClient = useHttpClient();

    const { handleOpenContractEdit, hideThemeNestedMenu, contractNumber } = props;

    const [isLoadingDetails, setLoadingDetails] = useState(false);
    const [detailsData, setDetailsData] = useState<ContractDetailsViewMaschineDTO>();

    const makeRequestForDetails = async (): Promise<ContractDetailsViewMaschineDTO> => {
        setLoadingDetails(true);
        return httpClient.get<ContractDetailsViewMaschineDTO>(`contracts/${id}/maschine`);
    };

    useEffect(() => {
        makeRequestForDetails()
            .then((data: ContractDetailsViewMaschineDTO) => {
                setDetailsData(data);
            })
            .finally(() => {
                setLoadingDetails(false);
            });
    }, []);

    const goTo = (pathname: string): void => {
        router.push(pathname);
    };

    const menuItems = useMemo(
        () =>
            generateContractsDetailsAdditionalMenu(
                t,
                null,
                handleOpenContractEdit,
                goTo,
                ability,
                id,
                contractNumber
            ),
        [t, contractNumber, ability]
    );

    const atLeastOneValueInUHVUSVTable = useMemo(
        () =>
            detailsData?.insuredThings?.some(
                (item) =>
                    item.designation ||
                    item.type ||
                    item.constructionYear ||
                    item.machineNumber
            ),
        [detailsData]
    );

    const isDetailDataEmpty = useMemo(() => {
        if (detailsData) {
            const detailsDataKeys = Object.keys(detailsData);
            return !detailsDataKeys
                ?.map((x) => detailsData[x])
                ?.filter((x) => (x || typeof x === 'boolean') && !Array.isArray(x))
                ?.length;
        }
        return true;
    }, [detailsData]);

    return (
        <Grid container>
            <Grid container className={classes.titleAndMenuContainer}>
                <div style={{ flexGrow: 1 }} />
                {!hideThemeNestedMenu &&
                    Array.isArray(menuItems) &&
                    menuItems.length > 0 && (
                        <ThemeNestedMenu
                            menuItems={menuItems}
                            buttonClass={classes.additionalMenuButton}
                            buttonIcon={<MenuIcon className={classes.menuIcon} />}
                        />
                    )}
            </Grid>
            {isLoadingDetails && <ThemeLoader />}
            {isDetailDataEmpty && !isLoadingDetails && !atLeastOneValueInUHVUSVTable && (
                <List className={contractDetailsViewDataClasses.list}>
                    <ListItem>
                        <ListItemText data-title>
                            {t('contract-details-view:noContractDetails')}
                        </ListItemText>
                    </ListItem>
                </List>
            )}
            {detailsData &&
                !isLoadingDetails &&
                (!isDetailDataEmpty || atLeastOneValueInUHVUSVTable) && (
                    <>
                        <List className={contractDetailsViewDataClasses.list}>
                            <ContractDetailsListItem
                                hidden={!detailsData?.riskLocation}
                                title='contract-details-view:contractDetails.riskLocation'
                                value={detailsData?.riskLocation}
                            />
                            <ContractDetailsListItem
                                hidden={detailsData?.stationary}
                                title='contract-details-view:contractDetails.stationary'
                                value={
                                    detailsData?.stationary
                                        ? t('common:yes')
                                        : t('common:no')
                                }
                            />
                            <ContractDetailsListItem
                                hidden={detailsData?.mobile}
                                title='contract-details-view:contractDetails.mobile'
                                value={
                                    detailsData?.mobile ? t('common:yes') : t('common:no')
                                }
                            />
                            <ContractDetailsListItem
                                hidden={!detailsData?.internalOperationalDamage}
                                title='contract-details-view:contractDetails.internalOperationalDamage'
                                value={detailsData?.internalOperationalDamage}
                            />
                            <ContractDetailsListItem
                                hidden={typeof detailsData?.heightSB !== 'number'}
                                title='contract-details-view:contractDetails.heightSB'
                                value={t('formatted-values:formattedCurrency', {
                                    currency: detailsData?.heightSB,
                                })}
                            />
                            <ListItem>
                                {detailsData && atLeastOneValueInUHVUSVTable && (
                                    <table className={maschineClasses.table}>
                                        <thead className={maschineClasses.tableHeader}>
                                            {t(
                                                'contract-details-view:contractDetails.insuredThings'
                                            )}
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>
                                                    {t(
                                                        'contract-details-view:contractDetails.designation'
                                                    )}
                                                </th>
                                                <th>
                                                    {t(
                                                        'contract-details-view:contractDetails.machineType'
                                                    )}
                                                </th>
                                                <th>
                                                    {t(
                                                        'contract-details-view:contractDetails.constructionYear'
                                                    )}
                                                </th>
                                                <th>
                                                    {t(
                                                        'contract-details-view:contractDetails.machineNumber'
                                                    )}
                                                </th>
                                            </tr>
                                            {detailsData?.insuredThings
                                                ?.filter((item) => {
                                                    return (
                                                        item.designation ||
                                                        item.constructionYear ||
                                                        item.machineNumber ||
                                                        item.type
                                                    );
                                                })
                                                ?.map((item) => {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td
                                                                className={
                                                                    maschineClasses.tableCell
                                                                }
                                                            >
                                                                {item.designation}
                                                            </td>
                                                            <td
                                                                className={
                                                                    maschineClasses.tableCell
                                                                }
                                                            >
                                                                {item.type}
                                                            </td>
                                                            <td
                                                                className={
                                                                    maschineClasses.tableCell
                                                                }
                                                            >
                                                                {item.constructionYear}
                                                            </td>
                                                            <td
                                                                className={
                                                                    maschineClasses.tableCell
                                                                }
                                                            >
                                                                {item.machineNumber}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                )}
                            </ListItem>
                        </List>
                    </>
                )}
        </Grid>
    );
};
