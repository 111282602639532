import * as yup from 'yup';
import { AnyAbility } from '@casl/ability';

import { AbilityActions, AbilitySubjects } from 'src/roleAccesses';

interface NotificationCenterNewMessageValidationSchema {
    message: string;
    user: string;
    customerAdvisor: string;
}

export const notificationCenterNewMessageValidationSchema = (
    ability: AnyAbility
): yup.SchemaOf<NotificationCenterNewMessageValidationSchema> =>
    yup.object().shape({
        message: yup.string().nullable().required(),
        user: ability.can(
            AbilityActions.see,
            AbilitySubjects.notificationNewMessageCustomerConnectionForm
        )
            ? yup.string().nullable().required()
            : yup.string().nullable(),
        customerAdvisor: ability.can(
            AbilityActions.see,
            AbilitySubjects.notificationNewMessageCustomerAdviserForm
        )
            ? yup.string().nullable().required()
            : yup.string().nullable(),
    });
