import React, {
    BaseSyntheticEvent,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useFieldArray, useForm } from 'react-hook-form';
import { FileObject } from 'material-ui-dropzone';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';

import { APP_ROUTES } from 'src/routing';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useGlobalFilter } from 'src/shared/contexts';
import { Divisions } from 'src/shared/constants';
import { useQuery, useValidateContractByDate } from 'src/shared/hooks';
import { DamageReportGroups } from 'src/shared/constants';

import { DamageReportFormView } from '../damage-report-form-view';
import { useStyle } from '../../damage-report-form-styles/damage-report-form-styles';

import { DamageReportFormDeleteConfirmationModalWindow } from '../../damage-report-form-components/damage-report-form-delete-confirmation';

import { DamageReportFormGetRecordDTO } from '../../damage-report-form-utils/damage-report-form-types/damage-report-form-get-record-dto';
import {
    DamageReportFormAONKompositDREISETypes,
    DamageReportFormAonKompositDreiseMutation,
} from '../../damage-report-form-utils/damage-report-form-types/damage-report-form-AON-Komposit-types/damage-report-form-AON-Komposit-dreise-types';
import { damageReportFormGenerateDreiseKindOfTripOptions } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-generate-kindOfTrip-options';
import {
    DamageReportFormConfigAONKompositDreise,
    generateDisabledInputs,
} from '../../damage-report-form-config/damage-report-form-AON-komposit-config/damage-report-form-config-AON-komposit-dreise';
import { damageReportFormAONKompositDREISEDefaultState } from '../../damage-report-form-utils/damage-report-form-default-states/damage-report-form-AON-Komposit-default-state/damage-report-form-AON-default-state-draise';
import { damageReportFormDreiseGetAdapter } from '../../damage-report-form-utils/damage-report-form-request-body-adapters/damage-report-form-adapters-AON-Komposit/damage-report-form-dreise-get-adapter';
import { damageReportFormDreiseMutateAdapter } from '../../damage-report-form-utils/damage-report-form-request-body-adapters/damage-report-form-adapters-AON-Komposit/damage-report-form-dreise-mutate-adapter';
import { damageReportFormGeneralAONKompositSchema } from '../../damage-report-form-utils/damage-report-form-schemas/damage-report-form-schemas-AON-Komposit/damage-report-form-general-AON-Komposit-schema';
import { useDamageReportFormSaveReport } from '../../damage-report-form-hooks/';

interface DamageReportFormAONKompositDREISEProps {
    setDivision: (value: string) => void;
    hideButtons: boolean;
    data: DamageReportFormGetRecordDTO;
}

export const DamageReportFormAONKompositDREISE = memo(
    (props: DamageReportFormAONKompositDREISEProps) => {
        const { setDivision, hideButtons, data } = props;

        const { t } = useTranslation(['common', 'damages-report', 'errors']);
        const contractId = useQuery().get('contractId');

        const {
            watch,
            setValue,
            control,
            handleSubmit,
            formState,
            reset,
            trigger,
            getValues,
        } = useForm<DamageReportFormAONKompositDREISETypes>({
            mode: 'onChange',
            reValidateMode: 'onChange',
            resolver: yupResolver(damageReportFormGeneralAONKompositSchema),
            defaultValues: damageReportFormAONKompositDREISEDefaultState(contractId),
        });

        const { changeDivision } = useGlobalFilter();

        const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);

        const { id } = useParams<{ id?: string }>();

        const classes = useStyle();
        const httpClient = useHttpClient();
        const router = useHistory();

        const [isSendingToAmsLoading, setSendingToAmsLoading] = useState(false);
        const { enqueueSnackbar } = useSnackbar();

        const { fields: files, remove } = useFieldArray({ control, name: 'files' });

        const { fields: fileInfos } = useFieldArray({
            control,
            name: 'fileInfos',
        });

        const [
            division,
            dayOfDamage,
            timeOfDamage,
            risk,
            contractNumber,
            policyHolder,
            insuranceCompany,
            insuredCompanyFileNumber,
            creditInstitute,
            iban,
            bic,
            accounOwner,
            nameOfTheInsured,
            addressOfTheInsured,
            postCodeAndPlaceResidenceInsuredPerson,
            damageLocation,
            damageAssessmentDayAndTime,
            kindOfTrip,
            whereAndHowWasTheLostOrDamagedBaggageStored,
            exactDescriptionOfTheCourseOfTheDamage,
            witnessesOfTheDamage,
            nameAndAddress,
            telephoneOrFax,
            possiblyPoliceDiaryNumber,
            whenWasTheDamageReported,
            whoBecameLiableForTheDamageWhenAndHowHeld,
            whoHasAlreadyPaidWhatCompensationAndWhen,
            whatMeasuresHaveBeenTakenToRegain,
            typeOrInsuranceCertificate,
            affectedItem,
            ownAllOfTheAboveObjects,
            includeTheOwner,
        ] = watch([
            'division',
            'dayOfDamage',
            'timeOfDamage',
            'risk',
            'contractNumber',
            'policyHolder',
            'insuranceCompany',
            'insuredCompanyFileNumber',
            'creditInstitute',
            'iban',
            'bic',
            'accounOwner',
            'nameOfTheInsured',
            'addressOfTheInsured',
            'postCodeAndPlaceResidenceInsuredPerson',
            'damageLocation',
            'damageAssessmentDayAndTime',
            'kindOfTrip',
            'whereAndHowWasTheLostOrDamagedBaggageStored',
            'exactDescriptionOfTheCourseOfTheDamage',
            'witnessesOfTheDamage',
            'nameAndAddress',
            'telephoneOrFax',
            'possiblyPoliceDiaryNumber',
            'whenWasTheDamageReported',
            'whoBecameLiableForTheDamageWhenAndHowHeld',
            'whoHasAlreadyPaidWhatCompensationAndWhen',
            'whatMeasuresHaveBeenTakenToRegain',
            'typeOrInsuranceCertificate',
            'affectedItem',
            'ownAllOfTheAboveObjects',
            'includeTheOwner',
        ]);

        const {
            isLoading: isSaveLoading,
            setLoading: setSaveLoading,
            saveReport,
            handleSaveReport,
        } = useDamageReportFormSaveReport<
            DamageReportFormAONKompositDREISETypes,
            DamageReportFormAonKompositDreiseMutation
        >({
            id,
            pathname: 'luggage',
            data: { ...getValues(), files },
            adapter: damageReportFormDreiseMutateAdapter,
            division: Divisions.DREISE,
        });

        const isContractValidByDate = useValidateContractByDate(dayOfDamage, contractId);

        useEffect(() => {
            trigger();
        }, []);

        useEffect(() => {
            if (data) {
                reset(damageReportFormDreiseGetAdapter(data));
                trigger();
            }
        }, [data]);

        useEffect(() => {
            setDivision(division);
        }, [division]);

        const handleChangeValue = useCallback(
            (e: BaseSyntheticEvent): void => {
                setValue(e.target.name, e.target.value);
                trigger();
            },
            [setValue]
        );

        const onDeleteFile = (id: string): void => {
            const newFiles = fileInfos.filter((file) => {
                return file.id !== id;
            });
            setValue('fileInfos', newFiles);
        };

        const makeRequestForSavingDataToAms = async (
            data: DamageReportFormAONKompositDREISETypes
        ): Promise<void | string> => {
            setSendingToAmsLoading(true);
            try {
                const { id: newId } = await saveReport(data);
                return httpClient
                    .post<Promise<void | string>>(
                        `damage-reports/${newId || id}/send-to-ams`
                    )
                    .catch(() => {
                        return Promise.reject(newId);
                    });
            } catch {
                return Promise.reject();
            }
        };

        const saveReportToAms = (): void => {
            const data: DamageReportFormAONKompositDREISETypes = getValues();
            makeRequestForSavingDataToAms(data)
                .then(() => {
                    enqueueSnackbar(t(`damages-report:sentSuccessfully`), {
                        variant: 'success',
                    });
                    changeDivision(Divisions.DREISE);
                    router.push(`${APP_ROUTES.DAMAGES}?filterValue=created`);
                })
                .catch((newId: string) => {
                    if (newId) {
                        router.push(`${APP_ROUTES.DAMAGE_REPORT}/${newId}`);
                    }
                    enqueueSnackbar(t(`errors:unknownError`), {
                        variant: 'error',
                    });
                })
                .finally(() => {
                    setSendingToAmsLoading(false);
                    setSaveLoading(false);
                });
        };

        const handleCancel = (): void => {
            history.back();
        };

        const handleReadOnlyFields = (data: {
            licenseNumber: string;
            contractNumber: string;
            policyHolder: string;
            insuranceCompany: string;
            contractId: string;
        }): void => {
            setValue('risk', data.licenseNumber);
            setValue('contractNumber', data.contractNumber);
            setValue('policyHolder', data.policyHolder);
            setValue('insuranceCompany', data.insuranceCompany);
            setValue('contractId', data.contractId);
            trigger();
        };

        const handleChangeDate = useCallback(
            (name, value: ParsableDate): void => {
                setValue(name, value);
                trigger();
            },
            [setValue]
        );

        const handleAddFile = useCallback(
            (newFiles: FileObject[]): void => {
                setValue('files', [...files, ...newFiles]);
            },
            [setValue, files]
        );

        const handleDeleteFile = useCallback(
            (index: number): void => {
                remove(index);
            },
            [setValue]
        );

        const onSubmit = (): void => {
            saveReportToAms();
        };

        const handleChangeSwitcher = useCallback(
            (name, value: boolean): void => {
                setValue(name, value);
                trigger();
            },

            [setValue]
        );

        const isDivisionFromDataIsFromState = data?.division === division;

        const headerConfig = useMemo(
            () =>
                generateDisabledInputs({
                    licenseNumber: isDivisionFromDataIsFromState || !id ? risk : '',
                    contractNumber:
                        isDivisionFromDataIsFromState || !id ? contractNumber : '',
                    policyHolder:
                        isDivisionFromDataIsFromState || !id ? policyHolder : '',
                    insuranceCompany:
                        isDivisionFromDataIsFromState || !id ? insuranceCompany : '',
                    errors: formState.errors,
                }),
            [
                risk,
                contractNumber,
                policyHolder,
                insuranceCompany,
                division,
                data?.division,
                formState.errors.contractNumber,
                formState.errors.insuranceCompany,
                formState.errors.policyHolder,
            ]
        );

        const kindOfTripOptions = useMemo(
            () => damageReportFormGenerateDreiseKindOfTripOptions(t),
            [t]
        );

        const configs = useMemo(
            () =>
                DamageReportFormConfigAONKompositDreise({
                    insuredCompanyFileNumber,
                    creditInstitute,
                    iban,
                    bic,
                    accounOwner,
                    nameOfTheInsured,
                    addressOfTheInsured,
                    postCodeAndPlaceResidenceInsuredPerson,
                    damageLocation,
                    damageAssessmentDayAndTime,
                    kindOfTrip,
                    whereAndHowWasTheLostOrDamagedBaggageStored,
                    exactDescriptionOfTheCourseOfTheDamage,
                    witnessesOfTheDamage,
                    nameAndAddress,
                    telephoneOrFax,
                    possiblyPoliceDiaryNumber,
                    whenWasTheDamageReported,
                    whoBecameLiableForTheDamageWhenAndHowHeld,
                    whoHasAlreadyPaidWhatCompensationAndWhen,
                    whatMeasuresHaveBeenTakenToRegain,
                    typeOrInsuranceCertificate,
                    affectedItem,
                    ownAllOfTheAboveObjects,
                    includeTheOwner,
                    kindOfTripOptions,
                    handleChangeSwitcher,
                }),
            [
                insuredCompanyFileNumber,
                creditInstitute,
                iban,
                bic,
                accounOwner,
                nameOfTheInsured,
                addressOfTheInsured,
                postCodeAndPlaceResidenceInsuredPerson,
                damageLocation,
                damageAssessmentDayAndTime,
                kindOfTrip,
                whereAndHowWasTheLostOrDamagedBaggageStored,
                exactDescriptionOfTheCourseOfTheDamage,
                witnessesOfTheDamage,
                nameAndAddress,
                telephoneOrFax,
                possiblyPoliceDiaryNumber,
                whenWasTheDamageReported,
                whoBecameLiableForTheDamageWhenAndHowHeld,
                whoHasAlreadyPaidWhatCompensationAndWhen,
                whatMeasuresHaveBeenTakenToRegain,
                typeOrInsuranceCertificate,
                affectedItem,
                ownAllOfTheAboveObjects,
                includeTheOwner,
                kindOfTripOptions,
                handleChangeSwitcher,
            ]
        );

        return (
            <>
                <DamageReportFormDeleteConfirmationModalWindow
                    id={id}
                    isOpen={isDeleteConfirmOpen}
                    onClose={() => setDeleteConfirmOpen(false)}
                />
                {
                    <DamageReportFormView
                        damageReportGroup={DamageReportGroups.LUGGAGE}
                        setDeleteConfirmOpen={setDeleteConfirmOpen}
                        deleteText={t('damages-report:deleteReport')}
                        deleteButtonClass={classes.deleteButton}
                        hideButtons={hideButtons}
                        boxContainer={classes.boxContainer}
                        sectionContainer={classes.sectionContainer}
                        formState={formState}
                        handleChangeValue={handleChangeValue}
                        footerConfig={[]}
                        headerConfig={headerConfig}
                        configs={configs}
                        sendText={t('send')}
                        formBlockElementClass={classes.formBlockElement}
                        containerClassName={classes.formBlockContainer}
                        saveText={t('save')}
                        saveButtonClass={clsx(classes.button, classes.saveButton)}
                        sendButtonClass={clsx(classes.button, classes.sendButton)}
                        isSendDisabled={
                            !formState.isValid ||
                            isSendingToAmsLoading ||
                            isSaveLoading ||
                            (contractId && !isContractValidByDate)
                        }
                        isSendingToAmsLoading={isSendingToAmsLoading}
                        isSaveLoading={isSaveLoading}
                        dayOfDamage={dayOfDamage}
                        IsSaveDisabled={
                            isSaveLoading ||
                            isSendingToAmsLoading ||
                            !division ||
                            !dayOfDamage ||
                            !contractNumber ||
                            !insuranceCompany ||
                            !policyHolder ||
                            (contractId && !isContractValidByDate)
                        }
                        handleCancel={handleCancel}
                        cancelText={t('cancel')}
                        handleReadOnlyFields={handleReadOnlyFields}
                        timeOfDamage={timeOfDamage}
                        handleChangeDate={handleChangeDate}
                        division={division}
                        divisionFromRecord={data?.division}
                        mainFormContainer={classes.mainFormContainer}
                        isReceivingDataLoading={false}
                        handleSubmit={handleSubmit(onSubmit)}
                        footerClassName={clsx(
                            classes.buttonsContainer,
                            classes.formBlockContainer
                        )}
                        handleAddFile={handleAddFile}
                        files={files}
                        fileInfos={fileInfos}
                        onDeleteFile={onDeleteFile}
                        handleDeleteFile={handleDeleteFile}
                        handleSaveReport={handleSaveReport}
                    />
                }
            </>
        );
    }
);
