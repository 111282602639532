import { makeStyles, Theme } from '@material-ui/core/styles';
import { THEME_MODES } from 'src/theming/themes';

export const useThemeHeaderHectorStyle = makeStyles((theme: Theme) => {
    const isDark = THEME_MODES.DARK === theme.palette.type;
    return {
        header: {
            position: 'relative',
            display: 'flex',
            flexWrap: 'nowrap',
            width: '100%',
            height: 100,
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            padding: `0 140px`,
            overflow: 'hidden',
            [theme.breakpoints.down('lg')]: {
                padding: `0 ${theme.spacing(3)}px`,
            },
            [theme.breakpoints.down('md')]: {
                height: 80,
                padding: `0 ${theme.spacing(1)}px`,
            },
        },
        logoContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        portalLogo: {
            cursor: 'pointer',
        },
        departmentFiltersContainer: {
            flexWrap: 'nowrap',
            width: 'fit-content',
            minWidth: 850,
            padding: `0 ${theme.spacing(6)}px`,
            gap: 20,
            '& .MuiChip-root': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
            },
            '& .MuiAutocomplete-endAdornment': {
                top: 8,
                [theme.breakpoints.down('md')]: {
                    top: 4,
                },
            },
            [theme.breakpoints.down('lg')]: {
                minWidth: 700,
                gap: 10,
            },
            [theme.breakpoints.down('md')]: {
                padding: `0 ${theme.spacing(1)}px`,
                minWidth: 550,
                gap: 10,
            },
        },
        departmentFilter: {
            width: '50%',
            minWidth: 300,
            maxWidth: 400,
            alignSelf: 'center',
            [theme.breakpoints.down('md')]: {
                minWidth: 200,
                maxWidth: 300,
            },
            '& p': {
                maxWidth: '70%',
                paddingTop: 5,
                paddingBottom: 5,
                overflow: 'hidden',
            },
            '& .MuiInputBase-root,.MuiAutocomplete-root': {
                height: 'auto',
                minHeight: 50,
                [theme.breakpoints.down('md')]: {
                    minHeight: 40,
                },
            },
        },
        sectionsNavigationContainer: {},
        switcherLabel: {
            [theme.breakpoints.down('md')]: {
                fontSize: '1.4rem',
            },
        },
        switcherContainer: {
            width: 'fit-content',
            margin: `0 ${theme.spacing(5)}px`,
            [theme.breakpoints.down('md')]: {
                margin: `0 ${theme.spacing(1)}px`,
            },
        },
        openProfileButton: {},
        logo: {
            filter: isDark ? 'brightness(0) invert(1)' : null,
        },
        customerLogo: {
            cursor: 'pointer',
        },
        logoWrapper: {
            width: 208,
            height: 50,
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
            [theme.breakpoints.down('md')]: {
                width: 150,
                height: 35,
            },
            '& svg': {
                width: '100%',
                height: '100%',
                '& g': {
                    fill: isDark
                        ? theme.palette.primary.contrastText
                        : theme.palette.secondary.main,
                },
            },
        },
        customerLogoContainer: {
            flexGrow: 1,
            minWidth: 100,
            maxWidth: 250,

            '& img': {
                width: '100%',
            },
        },
        administrationNavigationContainer: {
            margin: '0 auto',
            minWidth: 'auto',
            maxWidth: 'fit-content',
            width: 'fit-content',
            '& .MuiButton-root': {
                margin: '0 auto',
            },
        },
        activeSectionButton: {},
    };
});
