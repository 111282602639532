import { makeStyles, Theme } from '@material-ui/core';
import { THEME_MODES } from 'src/theming';

export const useStyle = makeStyles((theme: Theme) => {
    return {
        privilegesContainer: {
            flexDirection: 'column',
            height: '100%',
            overflow: 'auto',
        },
        toolBar: {
            height: 75,
            alignItems: 'center',
            padding: `0 ${theme.spacing(1)}rem`,
            backgroundColor:
                THEME_MODES.DARK === theme.palette.type
                    ? theme.palette.primary.main
                    : theme.palette.primary.light,
            borderBottom:
                THEME_MODES.DARK === theme.palette.type
                    ? `1px solid rgba(255, 255, 255, 0.2)`
                    : 'none',
        },
        header: {
            padding: `${theme.spacing(5)}px ${theme.spacing(1)}rem`,
        },
        title: {
            display: 'flex',
            alignItems: 'center',
            height: 40,
            color: theme.palette.primary.contrastText,
            paddingLeft: 12,
        },
        content: {
            alignContent: 'flex-start',
            flexGrow: 1,
            marginBottom: theme.spacing(2),
            padding: `0 ${theme.spacing(1)}rem`,
        },
        tableContainer: {
            border: `1px solid ${theme.palette.grey.A100}`,
        },
        userGroupTitlesContainer: {
            height: 50,
            flexWrap: 'nowrap',
            backgroundColor: 'rgba(134, 153, 169, 0.5)',
        },
        groupTitle: {
            width: `${90 / 3}%`,
            '&:not(:first-child) .MuiTypography-root': {
                borderLeft: '1px solid rgba(67, 81, 92, 0.3)',
            },
            '& .MuiTypography-root': {
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '1.4rem',
                fontWeight: theme.typography.fontWeightBold,
                color: theme.palette.primary.contrastText,
            },
        },
        rolesContainer: {
            height: 50,
            alignItems: 'center',
            backgroundColor: theme.palette.secondary.main,
        },
        roleCell: {
            color: theme.palette.secondary.contrastText,
            padding: `0 ${theme.spacing(2)}px`,
        },
        cell: {
            width: '10%',
        },
        selectCell: {
            padding: `0 ${theme.spacing(2)}px`,
        },
        rowsContainer: {
            width: '100%',
            maxHeight: ({ rowsMaxHeight }: { rowsMaxHeight: number }) =>
                `${rowsMaxHeight}px`,
            overflow: 'auto',
        },
        row: {
            width: '100%',
            height: 45,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.primary.main,
            '&:nth-child(even)': {
                backgroundColor: `${theme.palette.primary.light}`,
            },
            '&:not(:last-child)': {
                borderBottom: `1px solid ${theme.palette.grey.A100}`,
            },
            '& .MuiInput-underline': {
                '&:before': {
                    borderBottom: 'none',
                },
                '&:after': {
                    borderBottom: 'none',
                },
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: 'none',
                },
            },
        },
    };
});
