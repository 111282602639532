import moment from 'moment';
import {
    DamageReportFormGBWTypes,
    DamageReportFormGBWMutation,
} from '../damage-report-form-types/damage-report-form-GBW-HR-GESCH-types';

export const damageReportFormGBWMutateAdapter = (
    data: DamageReportFormGBWTypes
): DamageReportFormGBWMutation => {
    const offset = new Date().getTimezoneOffset() * 60000;
    return {
        id: data.id,
        dayOfDamage: data.dayOfDamage
            ? moment(moment(data.dayOfDamage).valueOf() - offset).toISOString()
            : null,
        timeOfDamage: data.timeOfDamage
            ? moment(moment(data.timeOfDamage).valueOf() - offset).toISOString()
            : null,
        contractId: data.contractId,
        contractNumber: data.contractNumber,
        policyHolder: data.policyHolder,
        insuranceCompany: data.insuranceCompany,
        risk: data.licenseNumber,
        damageInformation: {
            street: data.road,
            postCode: data.plz,
            city: data.place,
            country: data.country,
            damageType: data.typeofDamageGBW,
            damageDescription: data.detailedDamageDescription,
            informationAboutAmountOfDamage: data.amountOfDamage,
            ownerOfDamagedProperty: data.damagedOwner,
            damageItemsInsured: data.isToggleWhere,
            insurer: data.where,
        },
        contactPerson: {
            name: data.localName,
            company: data.firm,
            phoneNumber: data.phone,
            email: data.email,
            damageNumber: data.claimNumber,
            costCentre: data.costCentre,
            entitledToDeductVat: data.isDeductVat,
            IBAN: data.iban,
            BIC: data.bic,
        },
        policeRecordGbw: {
            incidentRecordedByPolice: data.isTogglePolice,
            policeStation: data.policeStation,
            policeFileNumber: data.fileNumber,
            initialMeasures: data.initialMeasures,
        },
        newFiles: data.files?.map((item) => item.file),
        fileInfos: data.fileInfos,
    };
};
