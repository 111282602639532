import { makeStyles, Theme } from '@material-ui/core';

export const useGBWStyles = makeStyles((theme: Theme) => {
    return {
        subtitle: {
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightBold,
            },
        },
    };
});
