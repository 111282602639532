import React, { useEffect, useState } from 'react';
import { appInstance } from 'src/environment';
import { AppInstances } from 'src/shared/constants';
import blobToBase64 from 'src/shared/utils/blob-to-base64';
import { HectorLogo } from 'src/theming/theme-logos';

import { useStyle } from './header-logo-style';

export const HeaderLogo = (): JSX.Element => {
    const classes = useStyle();
    const [logoBase64, setLogoBase64] = useState<string>();

    useEffect(() => {
        const loadLogo = async (logoPath: string): Promise<void> => {
            const response = await fetch('/static/images/logo-with-subtext.png');
            const blob = await response.blob();
            const base64 = await blobToBase64(blob);
            setLogoBase64(base64);
        };

        if (!appInstance) {
            return;
        }

        switch (appInstance) {
            case AppInstances.AON:
            case AppInstances.AON_KOMPOSIT:
            case AppInstances.AON_DIGITAL:
            case AppInstances.AON_MOTOR: {
                loadLogo('/static/images/aon.png');
                return;
            }
            case AppInstances.CEAT: {
                loadLogo('/static/images/ceat-logo.png');
                return;
            }
            default: {
                loadLogo('/static/images/logo-with-subtext.png');
                return;
            }
        }
    }, [appInstance]);

    switch (appInstance) {
        case AppInstances.AON:
        case AppInstances.AON_KOMPOSIT: {
            return (
                <img
                    alt='logo'
                    width={160}
                    height={0.6 * 160}
                    className={classes.logo}
                    src={logoBase64}
                />
            );
        }
        case AppInstances.AON_DIGITAL:
        case AppInstances.AON_MOTOR: {
            return (
                <img
                    alt='logo'
                    width={130}
                    height={0.6 * 130}
                    className={classes.logo}
                    src={logoBase64}
                />
            );
        }
        case AppInstances.HECTOR:
        case AppInstances.HECTOR_DEMO: {
            return (
                <div className={classes.logoWrapper}>
                    <HectorLogo />
                </div>
            );
        }
        case AppInstances.CEAT: {
            return (
                <img
                    alt='logo'
                    width={150}
                    height={0.38 * 150}
                    className={classes.logo}
                    src={logoBase64}
                />
            );
        }
        default: {
            return (
                <img
                    alt='logo'
                    width={220}
                    height={0.34 * 220}
                    className={classes.logo}
                    src={logoBase64}
                />
            );
        }
    }
};
