import { DamageReportFormBHVAndPHVType } from '../../damage-report-form-utils/damage-report-form-types/damage-report-form-BHV-PHV-types';

export const damageReportFormBHVAndPHVDefaultState = (
    contractId: string
): DamageReportFormBHVAndPHVType => ({
    id: '',
    division: '',
    dayOfDamage: null,
    timeOfDamage: null,
    risk: '',
    contractId: contractId,
    contractNumber: '',
    policyHolder: '',
    insuranceCompany: '',
    injuredPartySurname: '',
    injuredPartyRoad: '',
    injuredPartyPostcodeAndPlace: '',
    injuredPartyPhonenumber: '',
    injuredPartyEmail: '',
    damageOriginatorSurname: '',
    damageOriginatorRoad: '',
    damageOriginatorPostCodeAndPlace: '',
    damageOriginatorPhoneNumber: '',
    damageOriginatorEmail: '',
    damageOriginatorRelationshipBetweenPolluterAndPolicyholder: '',
    damageInformationDamageType: 'No choice',
    damageInformationDamagePlace: '',
    damageInformationWhatWasDamaged: '',
    damageInformationAreDamagedItemsOtherwiseInsured: false,
    indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance: '',
    claimsForDamagesMadeAgainstYou: false,
    estimatedDamageAmount: '',
    hasDamageAssessed: false,
    ifYouAreAtFaultForThatIncident: false,
    itWasYourTurnToBeAtFault: false,
    existenceBetweenYouAndCoInsured: false,
    whichExistenceBetweenYouAndCoInsured: '',
    doYouOrCoInsuredPersonPerformedActivity: false,
    whichActivity: '',
    detailedDescription: '',
    areYouEntitledToDeductInputTax: false,
    isRecordedByThePolice: false,
    policeStation: '',
    fileNumber: '',
    isWitnesses: false,
    witnessesSurname: '',
    witnessesRoad: '',
    witnessesPostcode: '',
    witnessesPlace: '',
    witnessesPhoneNumber: '',
    witnessesEmail: '',
    files: [],
    fileInfos: [],
});
