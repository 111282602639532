import React from 'react';
import clsx from 'clsx';

import { Grid, Typography, IconButton, Tooltip } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';

import { useDownloadFile } from 'src/shared/hooks';

import { useStyle } from './theme-files-panel-style';

interface ThemeFilesPanelItemProps {
    file: { fileName: string; id: string };
    readonly: boolean;
    onDeleteFile: (id: string) => void;
    route: string;
    routeId: string;
}

export const ThemeFilesPanelItem = (props: ThemeFilesPanelItemProps): JSX.Element => {
    const { file, readonly, onDeleteFile, route, routeId } = props;

    const { download } = useDownloadFile();

    const classes = useStyle();

    const handleDeleteFile = (): void => {
        onDeleteFile(file.id);
    };

    const handleDownloadFile = (): void => {
        download(route, routeId, file.id);
    };

    return (
        <Tooltip title={file.fileName}>
            <Grid item container className={classes.fileItemContainer}>
                <AttachFileIcon className={classes.attachmentIcon} />
                <Typography variant='body2' className={classes.fileName}>
                    {file.fileName}
                </Typography>
                {!readonly && (
                    <IconButton
                        onClick={handleDeleteFile}
                        className={clsx(classes.iconButton, classes.deleteButton)}
                    >
                        <DeleteIcon />
                    </IconButton>
                )}
                <IconButton
                    onClick={handleDownloadFile}
                    className={clsx(classes.iconButton, classes.downloadButton)}
                >
                    <GetAppIcon />
                </IconButton>
            </Grid>
        </Tooltip>
    );
};
