import { ContractsEditFormData } from './customer-change-notification';

import { buildFormData } from 'src/shared/utils';

export const customerEditFormUpdateAdapter = (data: ContractsEditFormData): FormData => {
    const formattedData = {
        SurnameFirstname: data.name,
        PhoneNumber: data.phoneNumber,
        Customer: data.client,
        Email: data.email,
        CustomerConnection: data.customerConnection,
        Address: data.address,
        OtherRemarks: data.remarks,
        ChangeNotification: data.remarks,
        Attachments: data.files?.map((item) => item.file),
        PhoneContact: data.contactByPhone === 'yes',
    };
    const formData = new FormData();
    buildFormData(formData, formattedData, null);
    return formData;
};
