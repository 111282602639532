import { useMutation, UseMutationResult } from 'react-query';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { CheckboxItem } from 'src/theming';
import { DashboardDataResponseType } from './dashboard-data-response-type';

interface Params {
    years: number[];
    division: string;
    customers: CheckboxItem[];
}

export const useFetchDashboardData = (): UseMutationResult => {
    const httpClient = useHttpClient();

    return useMutation(async ({ years, customers, division }: Params) => {
        const isAllChecked = customers.find((item) => item.value === 'all')?.checked;
        const items = isAllChecked ? customers : customers.filter((i) => i.checked);
        const names = items.map((item) => item.value).filter((item) => item !== 'all');
        if (division && years.length > 0) {
            const data = await httpClient.post<DashboardDataResponseType>('dashboard', {
                division,
                customers: names,
                damageYears: years,
            });
            return data;
        }
        return null;
    });
};
