import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme: Theme) => {
    return {
        loaderContainer: {
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        loader: {
            color: theme.palette.info.main,
        },
    };
});
