import { ChangeEvent } from 'react';
import { DamageReportFormContainer } from '../damage-report-form-components/damage-report-form-input-containers/damage-report-form-container';
import { DamageReportFormInput } from '../damage-report-form-components/damage-report-form-items/damage-report-form-input';
import { DamageFormDisabledInput } from '../damage-report-form-components/damage-report-form-items/damage-form-disabled-input';
import { DamageReportFormSelect } from '../damage-report-form-components/damage-report-form-items/damage-report-form-select';
import { CheckboxItem, SelectItem } from '../../../theming';
import { DamageReportFormFullwidthInput } from '../damage-report-form-components/damage-report-form-items/damage-report-form-fullwidth-input';
import { DamageFormConditionalBlock } from '../damage-report-form-components/damage-report-form-input-containers/damage-form-conditional-block';
import { DamageReportFormFullInput } from '../damage-report-form-components/damage-report-form-items/damage-report-form-full-input';
import { DamageReportFormCheckboxesGroup } from '../damage-report-form-components/damage-report-form-items/damage-report-form-checkboxes-group';
import { DamageReportFormCurrencyInput } from '../damage-report-form-components/damage-report-form-items/damage-form-currency-input';
import {
    FormContainerProps,
    FormElement,
    generateDisabledInputsProps,
} from './damage-report-form-config-GBW-HR-GESCH';

export interface DamageReportFormConfigTRANSPWReturnType {
    title?: string;
    id: string;
    isCombination?: boolean;
    showIfFalse?: boolean;
    toggleValue?: () => void;
    Container: (props: FormContainerProps) => JSX.Element;
    formElements: FormElement[];
}

interface FooterElementsProps {
    attachedDocumentOptions: CheckboxItem[];
    handleChangeAttachedDocuments: (e: ChangeEvent<HTMLInputElement>) => void;
}

interface DamageReportFormConfigTRANSPProps {
    policeLicensePlate: string;
    vehicleBody: string;
    typeOfDamage: string;
    ariaOfLiability: string;
    whoWasTheClient: string;
    driver: string;
    departurePlace: string;
    destination: string;
    whoLoaded: string;
    haveAgreementsAboutLoadingMet: boolean;
    whichAgreement: string;
    wasInQuantityTakeoverRequested: boolean;
    whichQuantityTakeoverRequested: string;
    doesDriverHaveNumberOfPiecesAtTakeoverChecked: boolean;
    doesDriverHaveNumberOfPiecesAtTakeoverAcknowledged: boolean;
    whoDischarged: string;
    hasItBeenReloaded: boolean;
    whichGoodsAreMissingOrDamaged: string;
    estimatedDamageAmount: number;
    totalWeightOfCompleteCharge: string;
    totalWeightOfDamageConcernedCharge: string;
    wasGoodsPacked: boolean;
    kindOfPackage: string;
    whenIsDamageDeveloped: string;
    whoLoadedOptions: SelectItem[];
    grantedRecipientReceipt: boolean;
    textofReservation: string;
    whereWasReservationMade: string;
    damageAmountReducedByFreight: boolean;
    detailedDamageDescription: string;
    whereIsDamagedItemLocated: string;
    averageAndCommissioner: string;
    isRecordedByThePolice: boolean;
    policeStation: string;
    fileNumber: string;
    vehicleBodySelectOptions: SelectItem[];
    damageTypeSelectOptions: SelectItem[];
    ariaOfLiabilityOptions: SelectItem[];
    whoDischargedOptions: SelectItem[];
    whenDamageDevelopedOptions: SelectItem[];
    whereReservationMadeOptions: SelectItem[];
    handleChangeSwitcher: (name: string, value: boolean) => void;
    ariaOfLiabilityError: boolean;
}

export const generateDisabledInputs = ({
    licenseNumber,
    contractNumber,
    policyHolder,
    insuranceCompany,
    errors,
}: generateDisabledInputsProps): DamageReportFormConfigTRANSPWReturnType[] => [
    {
        Container: DamageReportFormContainer,
        title: '',
        id: 'generalFormBlock',
        formElements: [
            {
                Item: DamageFormDisabledInput,
                value: licenseNumber,
                label: 'damages-report:generalFormBlock.risk',
                name: 'risk',
            },
            {
                Item: DamageFormDisabledInput,
                value: contractNumber,
                label: 'damages-report:generalFormBlock.contractNumber',
                name: 'contractNumber',
                error: Boolean(errors.contractNumber),
            },
            {
                Item: DamageFormDisabledInput,
                value: policyHolder,
                label: 'damages-report:generalFormBlock.policyHolder',
                name: 'policyHolder',
                error: Boolean(errors.policyHolder),
            },
            {
                Item: DamageFormDisabledInput,
                value: insuranceCompany,
                label: 'damages-report:generalFormBlock.client',
                name: 'insuranceCompany',
                error: Boolean(errors.insuranceCompany),
            },
        ],
    },
];

export const DamageReportFormConfigTRANSP = ({
    policeLicensePlate,
    vehicleBody,
    typeOfDamage,
    ariaOfLiability,
    whoWasTheClient,
    driver,
    departurePlace,
    destination,
    whoLoaded,
    haveAgreementsAboutLoadingMet,
    whichAgreement,
    wasInQuantityTakeoverRequested,
    whichQuantityTakeoverRequested,
    doesDriverHaveNumberOfPiecesAtTakeoverChecked,
    doesDriverHaveNumberOfPiecesAtTakeoverAcknowledged,
    whoDischarged,
    hasItBeenReloaded,
    whichGoodsAreMissingOrDamaged,
    estimatedDamageAmount,
    totalWeightOfCompleteCharge,
    totalWeightOfDamageConcernedCharge,
    wasGoodsPacked,
    kindOfPackage,
    whenIsDamageDeveloped,
    grantedRecipientReceipt,
    textofReservation,
    whereWasReservationMade,
    damageAmountReducedByFreight,
    detailedDamageDescription,
    whereIsDamagedItemLocated,
    averageAndCommissioner,
    isRecordedByThePolice,
    policeStation,
    fileNumber,
    whereReservationMadeOptions,
    vehicleBodySelectOptions,
    damageTypeSelectOptions,
    ariaOfLiabilityOptions,
    whoLoadedOptions,
    whoDischargedOptions,
    whenDamageDevelopedOptions,
    handleChangeSwitcher,
    ariaOfLiabilityError,
}: DamageReportFormConfigTRANSPProps): DamageReportFormConfigTRANSPWReturnType[][] => {
    return [
        [
            {
                title: 'damages-report:vehicleDetails.title',
                Container: DamageReportFormContainer,
                id: 'vehicleDetails',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:vehicleDetails.policeLicensePlate',
                        name: 'policeLicensePlate',
                        value: policeLicensePlate,
                    },
                    {
                        items: vehicleBodySelectOptions,
                        Item: DamageReportFormSelect,
                        label: 'damages-report:vehicleDetails.vehicleBody',
                        name: 'vehicleBody',
                        value: vehicleBody,
                    },
                ],
            },
        ],
        [
            {
                title: 'damages-report:freightLoadingAndDamage.title',
                Container: DamageReportFormContainer,
                id: 'freightLoadingAndDamage',
                formElements: [
                    {
                        items: damageTypeSelectOptions,
                        Item: DamageReportFormSelect,
                        label: 'damages-report:freightLoadingAndDamage.damageType',
                        name: 'typeOfDamage',
                        value: typeOfDamage,
                    },
                    {
                        items: ariaOfLiabilityOptions,
                        Item: DamageReportFormSelect,
                        label: 'damages-report:freightLoadingAndDamage.liabilityArea',
                        name: 'ariaOfLiability',
                        value: ariaOfLiability,
                        error: ariaOfLiabilityError,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:freightLoadingAndDamage.whoWasClient',
                        name: 'whoWasTheClient',
                        value: whoWasTheClient,
                    },

                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:freightLoadingAndDamage.driver',
                        name: 'driver',
                        value: driver,
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:freightLoadingAndDamage.placeOfDeparture',
                        name: 'departurePlace',
                        value: departurePlace,
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:freightLoadingAndDamage.destination',
                        name: 'destination',
                        value: destination,
                    },
                    {
                        Item: DamageReportFormSelect,
                        items: whoLoadedOptions,
                        label: 'damages-report:freightLoadingAndDamage.whoLoaded',
                        name: 'whoLoaded',
                        value: whoLoaded,
                    },
                ],
            },
            {
                title: 'damages-report:freightLoadingAndDamage.haveAgreementsAboutLoadingMet',
                id: 'haveAgreementsAboutLoadingMet',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:freightLoadingAndDamage.which',
                        value: whichAgreement,
                        name: 'whichAgreement',
                    },
                ],
                isCombination: haveAgreementsAboutLoadingMet,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'haveAgreementsAboutLoadingMet',
                        !haveAgreementsAboutLoadingMet
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: 'damages-report:freightLoadingAndDamage.wasInQuantityTakeoverRequested',
                id: 'wasInQuantityTakeoverRequested',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:freightLoadingAndDamage.which',
                        value: whichQuantityTakeoverRequested,
                        name: 'whichQuantityTakeoverRequested',
                    },
                ],
                isCombination: wasInQuantityTakeoverRequested,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'wasInQuantityTakeoverRequested',
                        !wasInQuantityTakeoverRequested
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: 'damages-report:freightLoadingAndDamage.doesDriverHaveNumberOfPiecesAtTakeoverChecked',
                id: 'doesDriverHaveNumberOfPiecesAtTakeoverChecked',
                formElements: [],
                isCombination: doesDriverHaveNumberOfPiecesAtTakeoverChecked,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'doesDriverHaveNumberOfPiecesAtTakeoverChecked',
                        !doesDriverHaveNumberOfPiecesAtTakeoverChecked
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: 'damages-report:freightLoadingAndDamage.doesDriverHaveNumberOfPiecesAtTakeoverAcknowledged',
                id: 'doesDriverHaveNumberOfPiecesAtTakeoverAcknowledged',
                formElements: [],
                isCombination: doesDriverHaveNumberOfPiecesAtTakeoverAcknowledged,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'doesDriverHaveNumberOfPiecesAtTakeoverAcknowledged',
                        !doesDriverHaveNumberOfPiecesAtTakeoverAcknowledged
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'freightLoadingAndDamage',
                formElements: [
                    {
                        items: whoDischargedOptions,
                        Item: DamageReportFormSelect,
                        label: 'damages-report:freightLoadingAndDamage.whoDischarged',
                        name: 'whoDischarged',
                        value: whoDischarged,
                    },
                ],
            },
            {
                title: 'damages-report:freightLoadingAndDamage.hasItReloaded',
                id: 'hasItBeenReloaded',
                formElements: [],
                isCombination: hasItBeenReloaded,
                toggleValue: () =>
                    handleChangeSwitcher('hasItBeenReloaded', !hasItBeenReloaded),
                Container: DamageFormConditionalBlock,
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'freightLoadingAndDamage',
                formElements: [
                    {
                        Item: DamageReportFormFullwidthInput,
                        label: 'damages-report:freightLoadingAndDamage.whichGoodsAreMissingOrDamaged',
                        name: 'whichGoodsAreMissingOrDamaged',
                        value: whichGoodsAreMissingOrDamaged,
                    },
                    {
                        Item: DamageReportFormCurrencyInput,
                        label: 'damages-report:freightLoadingAndDamage.estimatedAmountOfDamage',
                        name: 'estimatedDamageAmount',
                        value: estimatedDamageAmount,
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:freightLoadingAndDamage.totalWeightOfCompleteCharge',
                        name: 'totalWeightOfCompleteCharge',
                        value: totalWeightOfCompleteCharge,
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:freightLoadingAndDamage.totalWeightOfDamageConcernedCharge',
                        name: 'totalWeightOfDamageConcernedCharge',
                        value: totalWeightOfDamageConcernedCharge,
                    },
                ],
            },
            {
                title: 'damages-report:freightLoadingAndDamage.wasTheGoodsPacked',
                id: 'hasItBeenReloaded',
                formElements: [],
                isCombination: wasGoodsPacked,
                toggleValue: () =>
                    handleChangeSwitcher('wasGoodsPacked', !wasGoodsPacked),
                Container: DamageFormConditionalBlock,
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'freightLoadingAndDamage',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:freightLoadingAndDamage.kindOfPackage',
                        name: 'kindOfPackage',
                        value: kindOfPackage,
                    },
                    {
                        items: whenDamageDevelopedOptions,
                        Item: DamageReportFormSelect,
                        label: 'damages-report:freightLoadingAndDamage.whenIsTheDamageDeveloped',
                        name: 'whenIsDamageDeveloped',
                        value: whenIsDamageDeveloped,
                    },
                ],
            },
            {
                title: 'damages-report:freightLoadingAndDamage.grantedRecipientReceipt',
                id: 'grantedRecipientReceipt',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:freightLoadingAndDamage.reservationText',
                        value: textofReservation,
                        name: 'textofReservation',
                    },
                ],
                showIfFalse: true,
                isCombination: grantedRecipientReceipt,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'grantedRecipientReceipt',
                        !grantedRecipientReceipt
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'freightLoadingAndDamage',
                formElements: [
                    {
                        items: whereReservationMadeOptions,
                        Item: DamageReportFormSelect,
                        label: 'damages-report:freightLoadingAndDamage.whereWasReservationMade',
                        name: 'whereWasReservationMade',
                        value: whereWasReservationMade,
                    },
                ],
            },
            {
                title: 'damages-report:freightLoadingAndDamage.hasAmountOfDamageAlreadyReducedByFreight',
                id: 'damageAmountReducedByFreight',
                formElements: [
                    {
                        Item: DamageReportFormFullwidthInput,
                        label: 'damages-report:freightLoadingAndDamage.detailedDescriptionOfDamage',
                        value: detailedDamageDescription,
                        name: 'detailedDamageDescription',
                    },
                ],
                isCombination: damageAmountReducedByFreight,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'damageAmountReducedByFreight',
                        !damageAmountReducedByFreight
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'freightLoadingAndDamage',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:freightLoadingAndDamage.whereIsDamagedItemLocated',
                        name: 'whereIsDamagedItemLocated',
                        value: whereIsDamagedItemLocated,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:freightLoadingAndDamage.whichAverageCommissionerWasCalledInAndWhen',
                        name: 'averageAndCommissioner',
                        value: averageAndCommissioner,
                    },
                ],
            },
        ],
        [
            {
                title: 'damages-report:recordedByThePolicy.title',
                Container: DamageReportFormContainer,
                id: 'recordedByThePolice',
                formElements: [],
            },
            {
                title: 'damages-report:recordedByThePolicy.wasIncidentRecordedByPolice',
                Container: DamageFormConditionalBlock,
                id: 'recordedByThePolice',
                isCombination: isRecordedByThePolice,
                toggleValue: () =>
                    handleChangeSwitcher('isRecordedByThePolice', !isRecordedByThePolice),
                formElements: [
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:damageData.policeStation',
                        value: policeStation,
                        name: 'policeStation',
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:recordedByThePolicy.policeFileNumber',
                        value: fileNumber,
                        name: 'fileNumber',
                    },
                ],
            },
        ],
    ];
};

export const generateFooter = ({
    attachedDocumentOptions,
    handleChangeAttachedDocuments,
}: FooterElementsProps): DamageReportFormConfigTRANSPWReturnType[] => {
    return [
        {
            title: 'damages-report:addDocuments.title',
            Container: DamageReportFormContainer,
            id: 'footer',
            formElements: [
                {
                    Item: DamageReportFormCheckboxesGroup,
                    label: 'damages-report:addDocuments.followingDocumentsAreAttached',
                    value: [],
                    name: 'attachedDocuments',
                    options: attachedDocumentOptions,
                    handleChangeCheckboxesGroup: handleChangeAttachedDocuments,
                },
            ],
        },
    ];
};
