import moment from 'moment';
import {
    DamageReportFormAONKompositUniversalTypes,
    DamageReportFormAONKompositUniversalMutation,
} from '../../damage-report-form-types/damage-report-form-AON-Komposit-types/damage-report-form-AON-Komposit-universal-types';

export const damageReportFormUniversalMutateAdapter = (
    data: DamageReportFormAONKompositUniversalTypes
): DamageReportFormAONKompositUniversalMutation => {
    const offset = new Date().getTimezoneOffset() * 60000;
    return {
        id: data.id,
        division: data.division,
        dayOfDamage: data.dayOfDamage
            ? moment(moment(data.dayOfDamage).valueOf() - offset).toISOString()
            : null,
        timeOfDamage: data.timeOfDamage
            ? moment(moment(data.timeOfDamage).valueOf() - offset).toISOString()
            : null,
        contractId: data.contractId,
        risk: data.risk,
        contractNumber: data.contractNumber,
        policyHolder: data.policyHolder,
        insuranceCompany: data.insuranceCompany,
        newFiles: data.files?.map((item) => item.file),
        fileInfos: data.fileInfos,
        universalDamageInfo: {
            damage: data?.damage,
            damageDescription: data.damageDescription,
            rootCause: data.rootCause,
        },
    };
};
