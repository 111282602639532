import { makeStyles } from '@material-ui/core';
import { AppInstances } from 'src/shared/constants';
import { appInstance } from 'src/environment';

import {
    useThemeAutocompleteAonMotorStyles,
    useThemeAonMotorStyles,
} from './theme-autocomplete-aon-motor-styles';
import {
    useThemeAutocompleteAonStyle,
    useThemeAonStyle,
} from './theme-autocomplete-aon-styles';
import {
    useThemeAutocompleteHectorStyle,
    useThemeHectorStyle,
} from './theme-autocomplete-hector-styles';
import {
    useThemeAutocompleteSgiffoxxkStyle,
    useThemeSgiffoxxkStyle,
} from './theme-autocomplete-sgiffoxxk-styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const instanceThemeAutocompleteStylesHooks = {
    [AppInstances.AON]: useThemeAutocompleteAonStyle,
    [AppInstances.AON_KOMPOSIT]: useThemeAutocompleteAonStyle,
    [AppInstances.HECTOR]: useThemeAutocompleteHectorStyle,
    [AppInstances.CEAT]: useThemeAutocompleteHectorStyle,
    [AppInstances.SGIFFOXXK]: useThemeAutocompleteSgiffoxxkStyle,
    [AppInstances.AON_MOTOR]: useThemeAutocompleteAonMotorStyles,
    [AppInstances.AON_DIGITAL]: useThemeAutocompleteAonMotorStyles,
    [AppInstances.HECTOR_DEMO]: useThemeAutocompleteAonMotorStyles,
};

const instanceThemeStylesHooks = {
    [AppInstances.AON]: useThemeAonStyle,
    [AppInstances.AON_KOMPOSIT]: useThemeAonStyle,
    [AppInstances.HECTOR]: useThemeHectorStyle,
    [AppInstances.CEAT]: useThemeHectorStyle,
    [AppInstances.SGIFFOXXK]: useThemeSgiffoxxkStyle,
    [AppInstances.AON_MOTOR]: useThemeAonMotorStyles,
    [AppInstances.AON_DIGITAL]: useThemeAonMotorStyles,
    [AppInstances.HECTOR_DEMO]: useThemeAonMotorStyles,
};

export const useStyle = (): ClassNameMap => {
    const useThemeCurrentStyles =
        instanceThemeStylesHooks[appInstance] || useThemeAonStyle;
    return useThemeCurrentStyles();
};

export const useAutocompleteStyles = (props): ClassNameMap => {
    const useThemeAutocompleteCurrentStyles =
        instanceThemeAutocompleteStylesHooks[appInstance] || useThemeAutocompleteAonStyle;
    return useThemeAutocompleteCurrentStyles(props);
};

export const useFormControlLabelStyle = makeStyles(() => {
    return {
        label: {
            fontSize: '1.4rem',
        },
    };
});
