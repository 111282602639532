import React, { BaseSyntheticEvent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
    FormControl,
    FormControlLabel,
    Grid,
    OutlinedInput,
    Typography,
} from '@material-ui/core';

import { ThemePhoneNumberInput, ThemeCurrencyField } from 'src/theming';

import {
    useStyle,
    useFormControlStyles,
    useInputStyles,
    useFormControlLabelStyles,
} from '../../damage-report-form-styles/damage-report-form-styles';

interface DamageReportFormOtherPartyInformationProps {
    readonly: boolean;
    surname: string;
    road: string;
    postCode: string;
    place: string;
    phoneNumber: string;
    emailAddress: string;
    licenseNumber: string;
    vehicleType: string;
    damageAmount: string;
    handleChangeValue: (e: BaseSyntheticEvent) => void;
    errors: {
        otherPartyInformationCityEmail: boolean;
    };
}

export const DamageReportFormOtherPartyInformation = memo(
    (props: DamageReportFormOtherPartyInformationProps): JSX.Element => {
        const classes = useStyle();
        const formControlClasses = useFormControlStyles();
        const formControlLabelClasses = useFormControlLabelStyles();
        const inputClasses = useInputStyles();
        const { t } = useTranslation(['common', 'damage-report']);

        const {
            handleChangeValue,
            damageAmount,
            vehicleType,
            emailAddress,
            licenseNumber,
            phoneNumber,
            place,
            postCode,
            road,
            surname,
            errors,
            readonly,
        } = props;

        return (
            <Grid container spacing={2}>
                <Typography variant='h6' className={classes.formBlockTitle}>
                    {t('damages-report:otherPartyInformation.title')}
                </Typography>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled={readonly}
                                    classes={inputClasses}
                                    value={surname}
                                    onChange={handleChangeValue}
                                    name='otherPartyInformationNameAndSurname'
                                />
                            }
                            label={t('damages-report:otherPartyInformation.surname')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled={readonly}
                                    classes={inputClasses}
                                    value={road}
                                    onChange={handleChangeValue}
                                    name='otherPartyInformationStreet'
                                />
                            }
                            label={t('damages-report:otherPartyInformation.road')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled={readonly}
                                    classes={inputClasses}
                                    value={postCode}
                                    onChange={handleChangeValue}
                                    name='otherPartyInformationPostCode'
                                />
                            }
                            label={t('damages-report:otherPartyInformation.postCode')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled={readonly}
                                    classes={inputClasses}
                                    value={place}
                                    onChange={handleChangeValue}
                                    name='otherPartyInformationCity'
                                />
                            }
                            label={t('damages-report:otherPartyInformation.place')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <ThemePhoneNumberInput
                                    disabled={readonly}
                                    value={phoneNumber}
                                    onChange={handleChangeValue}
                                    name='otherPartyInformationCityPhoneNumber'
                                />
                            }
                            label={t('damages-report:otherPartyInformation.phoneNumber')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        error={errors.otherPartyInformationCityEmail}
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled={readonly}
                                    classes={inputClasses}
                                    value={emailAddress}
                                    onChange={handleChangeValue}
                                    name='otherPartyInformationCityEmail'
                                />
                            }
                            label={t('damages-report:otherPartyInformation.emailAddress')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled={readonly}
                                    classes={inputClasses}
                                    value={licenseNumber}
                                    onChange={handleChangeValue}
                                    name='otherPartyInformationLicenseNumber'
                                />
                            }
                            label={t(
                                'damages-report:otherPartyInformation.licenseNumber'
                            )}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled={readonly}
                                    classes={inputClasses}
                                    value={vehicleType}
                                    onChange={handleChangeValue}
                                    name='otherPartyInformationVehicleType'
                                />
                            }
                            label={t('damages-report:otherPartyInformation.vehicleType')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                        className={classes.dateField}
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <ThemeCurrencyField
                                    disabled={readonly}
                                    name='otherPartyInformationDamageAmount'
                                    value={damageAmount}
                                    onChange={handleChangeValue}
                                    decimalPlaces={2}
                                />
                            }
                            label={t(
                                'damages-report:otherPartyInformation.estimatedAmountOfDamage'
                            )}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
            </Grid>
        );
    }
);
