import React, { BaseSyntheticEvent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
    FormControl,
    FormControlLabel,
    Grid,
    OutlinedInput,
    Typography,
} from '@material-ui/core';

import { ThemeSwitcher } from 'src/theming';

import {
    useStyle,
    useFormControlStyles,
    useInputStyles,
    useFormControlLabelStyles,
} from '../../damage-report-form-styles/damage-report-form-styles';

interface DamageReportFormRecordedByThePolicyProps {
    readonly: boolean;
    recordedByThePolice: boolean;
    alcoholDrugUse: boolean;
    receivingPoliceStation: string;
    policeFileNumber: string;
    isWarnedByThePolice: boolean;
    whoWasWarned: string;
    handleChangeValue: (e: BaseSyntheticEvent) => void;
    handleChangeRecordedByThePolice: () => void;
    handleChangeWarnedByThePolice: () => void;
    handleChangeAlcoholDrugSwitcher: () => void;
}

export const DamageReportFormRecordedByThePolicy = memo(
    (props: DamageReportFormRecordedByThePolicyProps): JSX.Element => {
        const { t } = useTranslation(['common', 'damage-report']);

        const classes = useStyle();
        const formControlClasses = useFormControlStyles();
        const inputClasses = useInputStyles();
        const formControlLabelClasses = useFormControlLabelStyles();

        const {
            handleChangeAlcoholDrugSwitcher,
            handleChangeRecordedByThePolice,
            handleChangeValue,
            handleChangeWarnedByThePolice,
            recordedByThePolice,
            alcoholDrugUse,
            receivingPoliceStation,
            policeFileNumber,
            isWarnedByThePolice,
            whoWasWarned,
            readonly,
        } = props;

        return (
            <Grid container spacing={2}>
                <Typography variant='h6' className={classes.formBlockTitle}>
                    {t('damages-report:recordedByThePolicy.title')}
                </Typography>
                <Grid item className={clsx(classes.fullWidth)}>
                    <div style={{ width: 'fit-content' }}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <ThemeSwitcher
                                        disabled={readonly}
                                        checked={!recordedByThePolice}
                                        onChange={handleChangeRecordedByThePolice}
                                        leftLabel={t('yes')}
                                        rightLabel={t('no')}
                                    />
                                }
                                label={t(
                                    'damages-report:recordedByThePolicy.wasIncidentRecordedByPolice'
                                )}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </div>
                </Grid>
                {recordedByThePolice && (
                    <>
                        <Grid item className={clsx(classes.thirdPart)}>
                            <FormControl
                                fullWidth
                                classes={formControlClasses}
                                variant='outlined'
                            >
                                <FormControlLabel
                                    classes={formControlLabelClasses}
                                    control={
                                        <OutlinedInput
                                            disabled={readonly}
                                            classes={inputClasses}
                                            value={receivingPoliceStation}
                                            onChange={handleChangeValue}
                                            name='policeRecordReceivingPoliceStation'
                                        />
                                    }
                                    label={t(
                                        'damages-report:recordedByThePolicy.receivingPoliceStation'
                                    )}
                                    labelPlacement='top'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item className={clsx(classes.thirdPart)}>
                            <FormControl
                                fullWidth
                                classes={formControlClasses}
                                variant='outlined'
                            >
                                <FormControlLabel
                                    classes={formControlLabelClasses}
                                    control={
                                        <OutlinedInput
                                            disabled={readonly}
                                            classes={inputClasses}
                                            value={policeFileNumber}
                                            onChange={handleChangeValue}
                                            name='policeRecordPoliceFileNumber'
                                        />
                                    }
                                    label={t(
                                        'damages-report:recordedByThePolicy.policeFileNumber'
                                    )}
                                    labelPlacement='top'
                                />
                            </FormControl>
                        </Grid>
                    </>
                )}
                {recordedByThePolice && (
                    <>
                        <Grid item className={clsx(classes.fullWidth)}>
                            <FormControl
                                fullWidth
                                classes={formControlClasses}
                                variant='outlined'
                            >
                                <FormControlLabel
                                    classes={formControlLabelClasses}
                                    control={
                                        <ThemeSwitcher
                                            disabled={readonly}
                                            checked={!alcoholDrugUse}
                                            onChange={handleChangeAlcoholDrugSwitcher}
                                            leftLabel={t('yes')}
                                            rightLabel={t('no')}
                                        />
                                    }
                                    label={t(
                                        'damages-report:recordedByThePolicy.anyoneInvolved'
                                    )}
                                    labelPlacement='top'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item className={clsx(classes.fullWidth)}>
                            <FormControl
                                fullWidth
                                classes={formControlClasses}
                                variant='outlined'
                            >
                                <FormControlLabel
                                    classes={formControlLabelClasses}
                                    control={
                                        <ThemeSwitcher
                                            disabled={readonly}
                                            checked={!isWarnedByThePolice}
                                            onChange={handleChangeWarnedByThePolice}
                                            leftLabel={t('yes')}
                                            rightLabel={t('no')}
                                        />
                                    }
                                    label={t(
                                        'damages-report:recordedByThePolicy.someoneWarnedByThePolicy'
                                    )}
                                    labelPlacement='top'
                                />
                            </FormControl>
                        </Grid>
                        {isWarnedByThePolice && (
                            <>
                                <Grid item className={clsx(classes.thirdPart)}>
                                    <FormControl
                                        fullWidth
                                        classes={formControlClasses}
                                        variant='outlined'
                                    >
                                        <FormControlLabel
                                            classes={formControlLabelClasses}
                                            control={
                                                <OutlinedInput
                                                    disabled={readonly}
                                                    classes={inputClasses}
                                                    value={whoWasWarned}
                                                    onChange={handleChangeValue}
                                                    name='policeRecordWhoWarnedByPolice'
                                                />
                                            }
                                            label={t(
                                                'damages-report:recordedByThePolicy.whoWasWarnedByThePolice'
                                            )}
                                            labelPlacement='top'
                                        />
                                    </FormControl>
                                </Grid>
                            </>
                        )}
                    </>
                )}
            </Grid>
        );
    }
);
