import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDebounce } from 'src/lib/custom-hooks/use-debounce';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThemeLoader, ThemeTable } from 'src/theming';
import {
    Grid,
    Link,
    Paper,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { GridSortModel, GridSortModelParams } from '@material-ui/data-grid';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useStyle, usePaperStyle } from './customer-details-view-styles';
import { CustomerDetailsViewDocumentsResponseBody } from './customer-details-view-documents-response-body';
import moment from 'moment';
import qs from 'querystring';
import clsx from 'clsx';
import { generateCustomerDetailsViewTableHeader } from './customer-details-view-configs';
import { getUploadDocumentDescription } from 'src/contracts/contracts-details-view/get-upload-document-description';
import { ContractUploadDocumentForm } from 'src/contracts/contracts-upload-documents-form/contracts-upload-documents-form';

type CustomerDetailsViewDocumentsForm = {
    title: string;
    date: ParsableDate;
    sortModel: GridSortModel;
    hideUploadDocument: boolean;
};

interface CustomerDetailsViewDocumentsProps {
    hideUploadDocument: boolean;
    documentFormats: string[];
    customerId: string;
}

export const CustomerDetailsViewDocuments = ({
    hideUploadDocument,
    documentFormats,
    customerId,
}: CustomerDetailsViewDocumentsProps): JSX.Element => {
    const httpClient = useHttpClient();
    const classes = useStyle();
    const paperClasses = usePaperStyle();
    const { t } = useTranslation(['customer-details-view']);
    const { id } = useParams<{ id: string }>();
    const theme = useTheme();
    const matchUpLargeSize = useMediaQuery(theme.breakpoints.up('lg'));

    const [isOpen, setOpen] = useState(false);
    const [isGetDocumentsLoading, setGetDocumentsLoading] = useState(false);
    const [documents, setDocuments] = useState(null);

    const { watch, setValue } = useForm<CustomerDetailsViewDocumentsForm>({
        mode: 'onChange',
        defaultValues: { title: '', date: '' },
    });

    const [title, date, sortModel] = watch(['title', 'date', 'sortModel']);

    const debouncedTitle = useDebounce(title);
    const debouncedDate = useDebounce(date);

    const makeRequestToGetListOfDocuments = async (): Promise<void> => {
        setGetDocumentsLoading(true);
        const queryParams: {
            titleSearchTerm?: string;
            searchDate?: string;
            sortField?: string;
            isAscendingSortOrder?: boolean;
        } = {};
        if (debouncedTitle) {
            queryParams.titleSearchTerm = debouncedTitle;
        }
        if (debouncedDate) {
            queryParams.searchDate = moment(debouncedDate).toISOString();
        }
        if (sortModel && sortModel.length !== 0) {
            queryParams.sortField = sortModel[0].field;
            queryParams.isAscendingSortOrder = sortModel[0].sort === 'asc';
        }
        try {
            const data = await httpClient.get<CustomerDetailsViewDocumentsResponseBody[]>(
                `customers/${id}/documents?${qs.stringify(queryParams)}`
            );
            if (Array.isArray(data)) {
                setDocuments(
                    data?.map((item) => ({
                        id: item.amsIdNr,
                        title: item.title,
                        date: item.date,
                        type: item.type,
                    }))
                );
            }
        } finally {
            setGetDocumentsLoading(false);
        }
    };

    useEffect(() => {
        makeRequestToGetListOfDocuments();
    }, [debouncedTitle, debouncedDate, sortModel]);

    const handleChangeTableSearchField = useCallback(
        (e): void => {
            setValue(e.target.name, e.target.value);
        },
        [setValue]
    );

    const handleChangeDate = useCallback(
        (name, value) => {
            setValue(name, value);
        },
        [setValue]
    );

    const handleChangeSort = useCallback(
        (sortOrderParams: GridSortModelParams): void => {
            setValue('sortModel', sortOrderParams.sortModel);
        },
        [setValue]
    );

    const handleOpenModalWindow = (): void => {
        setOpen(true);
    };

    const handleCloseModalWindow = (): void => {
        setOpen(false);
        makeRequestToGetListOfDocuments();
    };

    const onRemoveSuccess = useCallback((): void => {
        makeRequestToGetListOfDocuments();
    }, []);

    const columns = useMemo(
        () =>
            generateCustomerDetailsViewTableHeader(
                t,
                title,
                date,
                handleChangeTableSearchField,
                handleChangeDate,
                classes.dateSearchFieldClass,
                (letterId) =>
                    httpClient.download(
                        `download-customers/${customerId}/attachments/${letterId}`
                    ),
                onRemoveSuccess,
                matchUpLargeSize
            ),
        [
            t,
            title,
            date,
            handleChangeTableSearchField,
            classes,
            handleChangeDate,
            customerId,
            onRemoveSuccess,
            matchUpLargeSize,
        ]
    );

    return (
        <Paper classes={paperClasses}>
            <Grid container>
                <Typography
                    variant='h6'
                    className={clsx(classes.cardTitle, classes.halfSize)}
                >
                    {t('customer-details-view:documents.title')}
                </Typography>
                {!hideUploadDocument && (
                    <Link
                        className={clsx(classes.halfSize, classes.documentLink)}
                        onClick={handleOpenModalWindow}
                    >
                        <AttachFileIcon />
                        {t('customer-details-view:documents.upload')}
                    </Link>
                )}
            </Grid>
            {isGetDocumentsLoading && <ThemeLoader />}
            {!isGetDocumentsLoading && (
                <>
                    <ThemeTable
                        isLoading={isGetDocumentsLoading}
                        dataLoadingMessage={t(
                            'customer-details-view:documents.dataLoading'
                        )}
                        withoutPagination
                        columns={columns}
                        rows={documents}
                        sortModel={sortModel}
                        handleChangeSort={handleChangeSort}
                        noRowsMessage={t('customer-details-view:documents.noDocuments')}
                    />
                    <ContractUploadDocumentForm
                        isOpen={isOpen}
                        documentFormats={documentFormats}
                        handleCloseModalWindow={handleCloseModalWindow}
                        pathForRequest={`customers/${id}/upload-documents`}
                        formTitle={t(
                            'customer-details-view:documents.uploadDocument.title'
                        )}
                        formDescription={t(getUploadDocumentDescription())}
                        dragAndDropLabel={t(
                            'customer-details-view:documents.uploadDocument.dragAndDropLabel'
                        )}
                    />
                </>
            )}
        </Paper>
    );
};
