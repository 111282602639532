import { makeStyles, Theme, Badge, withStyles } from '@material-ui/core';
import { THEME_MODES } from 'src/theming';
import {
    isHectorInstance,
    isSGIFFOXXKInstance,
    isAonDigitalInstance,
    isAonMotorInstance,
    isCeatInstance,
    isHectorDemoInstance,
} from 'src/environment';

export const useNotificationCenterStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        openNotificationCenter: {
            '& .MuiSvgIcon-root': {
                width: 30,
                height: 30,
                color: () => {
                    if (isSGIFFOXXKInstance) {
                        return isDarkMode
                            ? theme.palette.primary.contrastText
                            : theme.palette.secondary.main;
                    }
                    if (isHectorInstance || isCeatInstance) {
                        return !isDarkMode
                            ? theme.palette.secondary.main
                            : theme.palette.secondary.contrastText;
                    }
                    if (
                        isAonDigitalInstance ||
                        isAonMotorInstance ||
                        isHectorDemoInstance
                    ) {
                        return !isDarkMode
                            ? theme.palette.secondary.dark
                            : theme.palette.secondary.contrastText;
                    }
                    return theme.palette.type === THEME_MODES.LIGHT
                        ? theme.palette.grey[200]
                        : theme.palette.grey[500];
                },
            },
        },
        header: {
            height: 42,
            padding: `${theme.spacing(1)}px ${theme.spacing(5)}px`,
            borderBottom: () => {
                if (isSGIFFOXXKInstance) {
                    return isDarkMode
                        ? `1px solid ${theme.palette.secondary.contrastText}`
                        : `1px solid ${theme.palette.secondary.contrastText}`;
                }
                return `1px solid ${theme.palette.info.main}`;
            },
        },
        headerLayout: {
            position: 'sticky',
            top: 0,
            backgroundColor: theme.palette.primary.main,
            zIndex: 10,
        },
        tabs: {
            minHeight: 'auto',
            '& .MuiButtonBase-root': {
                minHeight: 'auto',
                height: 'auto',
                borderRight: 'none',
            },
            '& .MuiTabs-indicator': {
                height: '1px',
                backgroundColor: theme.palette.primary.contrastText,
            },
        },
        messageContainer: {
            padding: theme.spacing(3),
            borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
        },
        messageButton: {
            '& .MuiSvgIcon-root': {
                width: 25,
                height: 25,
            },
        },
        messageText: {
            margin: 0,
        },
        formContainer: {
            padding: theme.spacing(3),
        },
        backButton: {
            marginRight: theme.spacing(2),
            '& .MuiSvgIcon-root': {
                width: 20,
                height: 20,
            },
        },
        link: {
            color: () => {
                if (isSGIFFOXXKInstance) {
                    return isDarkMode
                        ? theme.palette.secondary.contrastText
                        : theme.palette.secondary.main;
                }
                if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
                    return isDarkMode
                        ? theme.palette.secondary.light
                        : theme.palette.info.main;
                }
                return theme.palette.info.main;
            },
        },
    };
});

export const usePopoverStyles = makeStyles((theme: Theme) => {
    return {
        paper: {
            position: 'relative',
            width: 600,
            borderRadius: 0,
            backgroundColor: theme.palette.primary.main,
        },
    };
});

export const useFormControlStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            '& label': {
                fontSize: '2.4rem',
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.info.main,
            },
            '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.error.main,
            },
            '& .MuiInputBase-root': {
                backgroundColor: `${theme.palette.primary.light}!important`,
            },
        },
    };
});

export const useFormControlLabelStyles = makeStyles(() => {
    return {
        root: {
            display: 'flex!important',
            alignItems: 'flex-start',
            margin: 0,
            padding: `0!important`,
            '&>div': {
                width: '100%',
                '& .MuiFormControl-root': {
                    width: '100%',
                },
            },
        },
        label: {
            fontSize: '1.4rem',
            opacity: 0.6,
        },
    };
});

export const useInputStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            width: '100%',
            height: 40,
            backgroundColor: theme.palette.primary.light,
        },
        focused: {
            '& fieldSet': {
                borderColor: `${theme.palette.info.main}!important`,
            },
        },
        input: {
            '&:-internal-autofill-selected': {
                boxShadow: `inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px ${theme.palette.primary.main}`,
            },
        },
    };
});

export const StyledBadge = withStyles((theme: Theme) => {
    return {
        badge: {
            height: 15,
            minWidth: 15,
            padding: 0,
            fontSize: '1rem',
            top: 12,
            right: 8,
            border: `1px solid ${theme.palette.grey[50]}`,
        },
    };
})(Badge);
