import { GridSortModel } from '@material-ui/data-grid';
import { CheckboxItem } from 'src/theming';

export const damagesListDamageReportRequestBodyPreparing = (
    contractNumber?: string,
    sortModel?: GridSortModel,
    page?: number,
    rowsPerPage?: number,
    division?: string,
    rawCustomerConnections?: CheckboxItem[],
    rawCustomers?: CheckboxItem[],
    availableDivisions?: string[]
): {
    contractNumber?: string;
    allowedDivisions?: string[];
    sortField?: string;
    isAscending?: boolean;
    skip?: number;
    take?: number;
} => {
    const requestBody: {
        contractNumber?: string;
        allowedCustomerConnections?: string[];
        allowedCustomers?: string[];
        allowedDivisions?: string[];
        allowedStatuses?: string[];
        damageNumber?: string;
        damageNumberE?: string;
        damageNumberI?: string;
        sortField?: string;
        isAscending?: boolean;
        skip?: number;
        take?: number;
    } = {
        allowedDivisions: division ? [division] : availableDivisions,
    };
    if (Number.isInteger(page) && Number.isInteger(rowsPerPage)) {
        const skip = (page + 1) * rowsPerPage - rowsPerPage;
        const take = rowsPerPage + 1; // To understand there are values on the next page. Because we don't know general count
        requestBody.skip = skip;
        requestBody.take = take;
    }
    if (contractNumber) {
        requestBody.contractNumber = contractNumber;
    }
    if (sortModel && sortModel.length !== 0) {
        requestBody.isAscending = sortModel[0].sort === 'asc';
        requestBody.sortField = sortModel[0].field;
    }
    const areAllCustomersChecked = rawCustomers?.find(
        (item) => item.value === 'all'
    ).checked;
    const noCustomersChecked = rawCustomers?.every((item) => !item.checked);
    const areAllCustomerConnectionsChecked = rawCustomerConnections?.find(
        (item) => item.value === 'all'
    ).checked;
    const noCustomerConnectionsChecked = rawCustomerConnections?.every(
        (item) => !item.checked
    );
    if (!areAllCustomersChecked && !noCustomersChecked) {
        requestBody.allowedCustomers = rawCustomers
            .filter((item) => item.checked)
            .map((item) => item.value);
    }
    if (!areAllCustomerConnectionsChecked && !noCustomerConnectionsChecked) {
        requestBody.allowedCustomerConnections = rawCustomerConnections
            .filter((item) => item.checked)
            .map((item) => item.value);
    }
    return requestBody;
};
