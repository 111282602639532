import React, { SyntheticEvent } from 'react';
import { GridColDef, GridCellParams } from '@material-ui/data-grid';
import { TFunction } from 'i18next';

import { IconButton } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';

import { CheckboxItem, ToggleButtonItem, ThemeDraggableElement } from 'src/theming';
import { CellWithSearch } from 'src/theming/theme-table/cell-with-search';
import {
    renderCellAsDateExpand,
    renderCellExpand,
    renderCellAsCurrencyExpand,
    renderCellExpandValueAsKeyForTranslation,
} from 'src/theming/theme-table/theme-table-cell-expand';

import { ColumnHeaderWithInnerRef } from 'src/contracts/contracts-list/contracts-list-configs';
import { DamagesListTableSettings } from './damages-list-table-settings';
import { appInstance } from 'src/environment';
import { AppInstances } from 'src/shared/constants';

const isAONKompositInstance = AppInstances.AON_KOMPOSIT === appInstance;

export enum ColumnKeys {
    OPEN = '',
    VNR = 'contractNumber',
    CLIENT = 'customer',
    RISK = 'risk',
    DAMAGE_NR = 'damageNumber',
    DAMAGE_NR_ESTATE_AGENTS = 'damageNumberE',
    DAMAGE_NR_VU = 'damageNumberI',
    CLAIM_DAY = 'dayOfDamage',
    DAMAGE_AMOUNT = 'damageHeight',
    RESERVE = 'reserve',
    PAYMENT_VU = 'paymentInEuro',
    COST_CENTER_VN = 'costCenterVn',
    DEDUCTIBLE = 'deductible',
    ASSIGNMENT = 'assignment',
    CAUSED = 'caused',
    TOTAL_PAYMENT = 'totalPayment',
}

export enum DamageStatuses {
    CREATED = 'created',
    OPEN = 'open',
    CLOSED = 'closed',
}

export const generateDamageListMenuForTableSettings = (
    t: TFunction,
    data?: DamagesListTableSettings,
    needAdditionalColumnsForKFZW?: boolean
): CheckboxItem[] => {
    return [
        {
            title: t('damages-list:tableHeaders.vnr'),
            value: ColumnKeys.VNR,
            checked: data?.columns.find((item) => item.name === ColumnKeys.VNR)?.isShown,
            data: data?.columns?.find((item) => item.name === ColumnKeys.VNR),
        },
        {
            title: t('damages-list:tableHeaders.client'),
            value: ColumnKeys.CLIENT,
            checked: data?.columns.find((item) => item.name === ColumnKeys.CLIENT)
                ?.isShown,
            data: data?.columns?.find((item) => item.name === ColumnKeys.CLIENT),
        },
        {
            title: t('damages-list:tableHeaders.risk'),
            value: ColumnKeys.RISK,
            checked: data?.columns.find((item) => item.name === ColumnKeys.RISK)?.isShown,
            data: data?.columns?.find((item) => item.name === ColumnKeys.RISK),
        },
        {
            title: t('damages-list:tableHeaders.damageNR'),
            value: ColumnKeys.DAMAGE_NR,
            checked: data?.columns.find((item) => item.name === ColumnKeys.DAMAGE_NR)
                ?.isShown,
            data: data?.columns?.find((item) => item.name === ColumnKeys.DAMAGE_NR),
        },
        {
            title: t('damages-list:tableHeaders.damageNREstateAgent'),
            value: ColumnKeys.DAMAGE_NR_ESTATE_AGENTS,
            checked: data?.columns.find(
                (item) => item.name === ColumnKeys.DAMAGE_NR_ESTATE_AGENTS
            )?.isShown,
            data: data?.columns?.find(
                (item) => item.name === ColumnKeys.DAMAGE_NR_ESTATE_AGENTS
            ),
        },
        {
            title: t('damages-list:tableHeaders.damageNRVU'),
            value: ColumnKeys.DAMAGE_NR_VU,
            checked: data?.columns.find((item) => item.name === ColumnKeys.DAMAGE_NR_VU)
                ?.isShown,
            data: data?.columns?.find((item) => item.name === ColumnKeys.DAMAGE_NR_VU),
        },
        {
            title: t('damages-list:tableHeaders.claimDay'),
            value: ColumnKeys.CLAIM_DAY,
            checked: data?.columns.find((item) => item.name === ColumnKeys.CLAIM_DAY)
                ?.isShown,
            data: data?.columns?.find((item) => item.name === ColumnKeys.CLAIM_DAY),
        },
        {
            title: t('damages-list:tableHeaders.damageAmount'),
            value: ColumnKeys.DAMAGE_AMOUNT,
            checked: data?.columns.find((item) => item.name === ColumnKeys.DAMAGE_AMOUNT)
                ?.isShown,
            data: data?.columns?.find((item) => item.name === ColumnKeys.DAMAGE_AMOUNT),
        },
        {
            title: t('damages-list:tableHeaders.totalAmount'),
            value: ColumnKeys.TOTAL_PAYMENT,
            checked: data?.columns.find((item) => item.name === ColumnKeys.TOTAL_PAYMENT)
                ?.isShown,
            data: data?.columns?.find((item) => item.name === ColumnKeys.TOTAL_PAYMENT),
        },
        ...(isAONKompositInstance
            ? []
            : [
                  {
                      title: t('damages-list:tableHeaders.reserve'),
                      value: ColumnKeys.RESERVE,
                      checked: data?.columns.find(
                          (item) => item.name === ColumnKeys.RESERVE
                      )?.isShown,
                      data: data?.columns?.find(
                          (item) => item.name === ColumnKeys.RESERVE
                      ),
                  },
                  {
                      title: t('damages-list:tableHeaders.paymentVU'),
                      value: ColumnKeys.PAYMENT_VU,
                      checked: data?.columns.find(
                          (item) => item.name === ColumnKeys.PAYMENT_VU
                      )?.isShown,
                      data: data?.columns?.find(
                          (item) => item.name === ColumnKeys.PAYMENT_VU
                      ),
                  },
              ]),

        {
            title: t('damages-list:tableHeaders.deductible'),
            value: ColumnKeys.DEDUCTIBLE,
            checked: data?.columns.find((item) => item.name === ColumnKeys.DEDUCTIBLE)
                ?.isShown,
            data: data?.columns?.find((item) => item.name === ColumnKeys.DEDUCTIBLE),
        },
        {
            title: t('damages-list:tableHeaders.assignment'),
            value: ColumnKeys.ASSIGNMENT,
            checked: data?.columns.find((item) => item.name === ColumnKeys.ASSIGNMENT)
                ?.isShown,
            data: data?.columns?.find((item) => item.name === ColumnKeys.ASSIGNMENT),
        },
        ...(needAdditionalColumnsForKFZW
            ? [
                  {
                      title: t('damages-list:tableHeaders.costCenterVN'),
                      value: ColumnKeys.COST_CENTER_VN,
                      checked: data?.columns.find(
                          (item) => item.name === ColumnKeys.COST_CENTER_VN
                      )?.isShown,
                      data: data?.columns?.find(
                          (item) => item.name === ColumnKeys.COST_CENTER_VN
                      ),
                  },
                  {
                      title: t('damages-list:tableHeaders.caused'),
                      value: ColumnKeys.CAUSED,
                      checked: data?.columns.find(
                          (item) => item.name === ColumnKeys.CAUSED
                      )?.isShown,
                      data: data?.columns?.find(
                          (item) => item.name === ColumnKeys.CAUSED
                      ),
                  },
              ]
            : []),
    ];
};

export const generateDamageListTableHeader = (
    t: TFunction,
    vnrValue: string,
    damageNRValue: string,
    damageNREstateAgentsValue: string,
    damageNRVUValue: string,
    handleChangeSearchValue: (e: SyntheticEvent) => void,
    columnsSettings: CheckboxItem[],
    handleGoToTheDetailView: (gridRowParameter: { id: string | number }) => void,
    hoverPosition: number,
    startPosition: number,
    isDragAndDropLocked: boolean
): GridColDef[] => {
    return [
        {
            field: ColumnKeys.OPEN,
            width: 60,
            renderCell: ({ id }) => (
                <IconButton
                    onClick={() => {
                        handleGoToTheDetailView({ id });
                    }}
                >
                    <Visibility />
                </IconButton>
            ),
        },
        {
            field: ColumnKeys.VNR,
            headerName: t('damages-list:tableHeaders.vnr'),
            flex: 1.5,
            renderHeader: (props) => (
                <ThemeDraggableElement
                    isDragAndDropLocked={isDragAndDropLocked}
                    draggableId={ColumnKeys.VNR}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) => column.value === ColumnKeys.VNR
                        )?.data?.position
                    }
                >
                    <CellWithSearch
                        {...props}
                        title={t('damages-list:tableHeaders.vnr')}
                        value={vnrValue}
                        handleChange={handleChangeSearchValue}
                        name='vnr'
                    />
                </ThemeDraggableElement>
            ),
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.VNR
            )?.checked,
            renderCell: renderCellExpand,
        },
        {
            field: ColumnKeys.CLIENT,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.CLIENT
            )?.checked,
            renderCell: renderCellExpand,
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeys.CLIENT}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) => column.value === ColumnKeys.CLIENT
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('damages-list:tableHeaders.client')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeys.RISK,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.RISK
            )?.checked,
            renderCell: renderCellExpand,
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeys.RISK}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) => column.value === ColumnKeys.RISK
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('damages-list:tableHeaders.risk')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeys.DAMAGE_NR,
            headerName: t('damages-list:tableHeaders.damageNR'),
            flex: 1.5,
            renderHeader: (props) => (
                <ThemeDraggableElement
                    isDragAndDropLocked={isDragAndDropLocked}
                    draggableId={ColumnKeys.DAMAGE_NR}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value === ColumnKeys.DAMAGE_NR
                        )?.data?.position
                    }
                >
                    <CellWithSearch
                        {...props}
                        title={t('damages-list:tableHeaders.damageNR')}
                        value={damageNRValue}
                        handleChange={handleChangeSearchValue}
                        name='damageNR'
                    />
                </ThemeDraggableElement>
            ),
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.DAMAGE_NR
            )?.checked,
            renderCell: renderCellExpand,
        },
        {
            field: ColumnKeys.DAMAGE_NR_ESTATE_AGENTS,
            headerName: t('damages-list:tableHeaders.damageNREstateAgent'),
            flex: 1.5,
            renderHeader: (props) => (
                <ThemeDraggableElement
                    isDragAndDropLocked={isDragAndDropLocked}
                    draggableId={ColumnKeys.DAMAGE_NR_ESTATE_AGENTS}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value === ColumnKeys.DAMAGE_NR_ESTATE_AGENTS
                        )?.data?.position
                    }
                >
                    <CellWithSearch
                        {...props}
                        title={t('damages-list:tableHeaders.damageNREstateAgent')}
                        value={damageNREstateAgentsValue}
                        handleChange={handleChangeSearchValue}
                        name='damageNREstateAgents'
                    />
                </ThemeDraggableElement>
            ),

            hide: !columnsSettings.find(
                (column: CheckboxItem) =>
                    column.value === ColumnKeys.DAMAGE_NR_ESTATE_AGENTS
            )?.checked,
            renderCell: renderCellExpand,
        },
        {
            field: ColumnKeys.DAMAGE_NR_VU,
            headerName: t('damages-list:tableHeaders.damageNRVU'),
            flex: 1.5,
            renderHeader: (props) => (
                <ThemeDraggableElement
                    isDragAndDropLocked={isDragAndDropLocked}
                    draggableId={ColumnKeys.DAMAGE_NR_VU}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value === ColumnKeys.DAMAGE_NR_VU
                        )?.data?.position
                    }
                >
                    <CellWithSearch
                        {...props}
                        title={t('damages-list:tableHeaders.damageNRVU')}
                        value={damageNRVUValue}
                        handleChange={handleChangeSearchValue}
                        name='damageNRVU'
                    />
                </ThemeDraggableElement>
            ),

            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.DAMAGE_NR_VU
            )?.checked,
            renderCell: renderCellExpand,
        },
        {
            field: ColumnKeys.CLAIM_DAY,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.CLAIM_DAY
            )?.checked,
            renderCell: renderCellAsDateExpand,
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeys.CLAIM_DAY}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value === ColumnKeys.CLAIM_DAY
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('damages-list:tableHeaders.claimDay')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeys.DAMAGE_AMOUNT,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.DAMAGE_AMOUNT
            )?.checked,
            renderCell: renderCellAsCurrencyExpand,
            headerAlign: 'right',
            align: 'right',
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeys.DAMAGE_AMOUNT}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value === ColumnKeys.DAMAGE_AMOUNT
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('damages-list:tableHeaders.damageAmount')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeys.RESERVE,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.RESERVE
            )?.checked,
            renderCell: renderCellAsCurrencyExpand,
            headerAlign: 'right',
            align: 'right',
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeys.RESERVE}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) => column.value === ColumnKeys.RESERVE
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('damages-list:tableHeaders.reserve')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeys.PAYMENT_VU,

            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.PAYMENT_VU
            )?.checked,
            renderCell: renderCellAsCurrencyExpand,
            headerAlign: 'right',
            align: 'right',
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeys.PAYMENT_VU}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value === ColumnKeys.PAYMENT_VU
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('damages-list:tableHeaders.paymentVU')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeys.DEDUCTIBLE,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.DEDUCTIBLE
            )?.checked,
            renderCell: renderCellAsCurrencyExpand,
            headerAlign: 'right',
            align: 'right',
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeys.DEDUCTIBLE}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value === ColumnKeys.DEDUCTIBLE
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('damages-list:tableHeaders.deductible')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeys.ASSIGNMENT,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.ASSIGNMENT
            )?.checked,
            renderCell: (params: GridCellParams) =>
                renderCellExpandValueAsKeyForTranslation(params, ['assignments']),
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeys.ASSIGNMENT}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value === ColumnKeys.ASSIGNMENT
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('damages-list:tableHeaders.assignment')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeys.COST_CENTER_VN,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.COST_CENTER_VN
            )?.checked,
            renderCell: renderCellExpand,
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeys.COST_CENTER_VN}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value === ColumnKeys.COST_CENTER_VN
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('damages-list:tableHeaders.costCenterVN')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeys.CAUSED,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.CAUSED
            )?.checked,
            renderCell: renderCellExpand,
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeys.CAUSED}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) => column.value === ColumnKeys.CAUSED
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('damages-list:tableHeaders.caused')}
                    />
                </ThemeDraggableElement>
            ),
        },
        {
            field: ColumnKeys.TOTAL_PAYMENT,
            flex: 1,
            hide: !columnsSettings.find(
                (column: CheckboxItem) => column.value === ColumnKeys.TOTAL_PAYMENT
            )?.checked,
            renderCell: renderCellAsCurrencyExpand,
            headerAlign: 'right',
            align: 'right',
            renderHeader: () => (
                <ThemeDraggableElement
                    draggableId={ColumnKeys.TOTAL_PAYMENT}
                    draggableIndex={
                        columnsSettings.find(
                            (column: CheckboxItem) =>
                                column.value === ColumnKeys.TOTAL_PAYMENT
                        )?.data?.position
                    }
                    isDragAndDropLocked={isDragAndDropLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                >
                    <ColumnHeaderWithInnerRef
                        title={t('damages-list:tableHeaders.totalAmount')}
                    />
                </ThemeDraggableElement>
            ),
        },
    ];
};

export const generateDamageListToggleButtons = (t: TFunction): ToggleButtonItem[] => {
    return [
        {
            title: t('damages-list:filterTitles.createdDamages'),
            value: DamageStatuses.CREATED,
        },
        {
            title: t('damages-list:filterTitles.openDamages'),
            value: DamageStatuses.OPEN,
        },
        {
            title: t('damages-list:filterTitles.closedDamages'),
            value: DamageStatuses.CLOSED,
        },
    ];
};
