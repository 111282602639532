import { makeStyles, Theme } from '@material-ui/core/styles';

const widgetWidth = 340;
const widghetHeight = 360;
const widgetMargin = 12;

export const useDashboardStyle = makeStyles((theme: Theme) => {
    return {
        dashboard: {
            display: 'flex',
            flexGrow: 1,
            flexWrap: 'wrap',
            gap: widgetMargin,
        },
        widget: {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            width: widgetWidth,
            minHeight: widghetHeight / 2,
            height: 'fit-content',
            transform: 'translate3d(0, 0, 0)',
            cursor: ({ editMode }: { editMode?: boolean }) =>
                editMode ? 'grab' : 'default',
            'page-break-inside': 'avoid',
        },
        widgetXL: {
            height: widghetHeight,
            'page-break-inside': 'avoid',
        },
        widgetXXL: {
            height: widghetHeight,
            width: widgetWidth * 2 + widgetMargin,
            'page-break-inside': 'avoid',
        },
        drag: {
            '&>div': {
                opacity: 0,
            },
            '&:after': {
                content: '""',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundImage: `repeating-linear-gradient(-45deg, ${theme.palette.info.light}, ${theme.palette.info.light} 25px, ${theme.palette.secondary.main} 25px, ${theme.palette.secondary.main} 50px)`,
                border: `2px dashed ${theme.palette.info.main}`,
                zIndex: 1000,
            },
        },
    };
});
