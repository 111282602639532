import React from 'react';
import useDarkMode from 'use-dark-mode';

import { GlobalSearchList } from 'src/contracts';
import { ThemeLayout } from 'src/theming';

export const GlobalSearchPage = (): JSX.Element => {
    const darkMode = useDarkMode();

    return (
        <ThemeLayout darkMode={darkMode}>
            <GlobalSearchList />
        </ThemeLayout>
    );
};
