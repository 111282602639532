import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useHistory } from 'react-router-dom';

import { CheckboxItem, ThemeTable } from 'src/theming';

import { useAuth } from 'src/user-management/context-auth';

import { ThemeLoader } from 'src/theming';

import { useDebounce } from 'src/lib/custom-hooks/use-debounce';
import { useGlobalFilter } from 'src/shared/contexts';

import { generateDamageListTableHeaderForCreatedStatus } from './damages-list-generate-table-for-created-status';
import {
    GridRowParams,
    GridSortModel,
    GridSortModelParams,
} from '@material-ui/data-grid';
import { APP_ROUTES } from 'src/routing';
import { damagesListDamageReportRequestBodyPreparing } from './damage-list-damage-report-body-preparing';
import { damageListDamageReportAdapter } from './damage-list-damage-report-adapter';
import { DamagesListTableSettings } from './damages-list-table-settings';

import { DamageReportDTO } from './damage-report-dto';

type DamagesListWithCreatedStatusFormData = {
    vnr: string;
    page: number;
    rowsPerPage: number;
};

interface DamageListWithCreatedStatusProps {
    columnsSettingsList: CheckboxItem[];
    division: string;
    isDragAndDropLocked: boolean;
    hoverPosition: number;
    startPosition: number;
    columnSettingsResponse: DamagesListTableSettings;
}

export const DamageListWithCreatedStatus = (
    props: DamageListWithCreatedStatusProps
): JSX.Element => {
    const { t } = useTranslation(['common', 'damages-list', 'contract-types']);
    const history = useHistory();

    const {
        columnsSettingsList,
        division,
        isDragAndDropLocked,
        hoverPosition,
        startPosition,
        columnSettingsResponse,
    } = props;

    const { rawCustomerConnections, rawCustomers, availableDivisions } =
        useGlobalFilter();

    const { userData } = useAuth();

    const httpClient = useHttpClient();
    const [sortModel, setSortModel] = useState<GridSortModel>(null);
    const [damageReports, setDamageReports] =
        useState<{ result: DamageReportDTO[]; count: number }>(null);
    const [isDamageReportsLoading, setDamageReportsLoading] = useState(false);

    const { watch, setValue } = useForm<DamagesListWithCreatedStatusFormData>({
        mode: 'onChange',
        defaultValues: {
            page: 0,
            rowsPerPage: 10,
            vnr: '',
        },
    });

    useEffect(() => {
        setValue('page', 0);
    }, [division]);

    const handleChangeSort = useCallback(
        (sortOrderParams: GridSortModelParams): void => {
            setSortModel(sortOrderParams.sortModel);
        },
        [setSortModel]
    );

    const handleGoToTheDetailView = useCallback(
        (params: GridRowParams): void => {
            const { id } = params;
            history.push(`${APP_ROUTES.DAMAGE_REPORT}/${id}`);
        },
        [history]
    );

    const handleChangePage = (_: SyntheticEvent, newPage: number): void => {
        setValue('page', newPage);
    };

    const handleChangeRowsPerPage = (e: SyntheticEvent): void => {
        setValue('rowsPerPage', Number((e.target as HTMLInputElement).value));
        setValue('page', 0);
    };

    const handleChangeTableSearchField = useCallback(
        (e): void => {
            switch (e.target.name) {
                case 'vnr': {
                    setValue('vnr', e.target.value);
                    setValue('page', 0);
                    break;
                }
                default: {
                    setValue(e.target.name, e.target.value);
                    setValue('page', 0);
                }
            }
        },
        [setValue]
    );

    const [vnr, rowsPerPage, page] = watch(['vnr', 'rowsPerPage', 'page']);

    const debouncedVnr = useDebounce(vnr, 1000);

    const makeRequestForDamageReportsList = (): Promise<{
        result: DamageReportDTO[];
        count: number;
    }> => {
        setDamageReportsLoading(true);
        const requestBody = damagesListDamageReportRequestBodyPreparing(
            debouncedVnr,
            sortModel,
            page,
            rowsPerPage,
            division,
            rawCustomerConnections,
            rawCustomers,
            availableDivisions.map((item) => item.division)
        );
        return httpClient.post<{ result: DamageReportDTO[]; count: number }>(
            `get-damage-reports`,
            requestBody
        );
    };

    useEffect(() => {
        setDamageReportsLoading(true);
        if (division) {
            makeRequestForDamageReportsList()
                .then((data: { result: DamageReportDTO[]; count: number }) => {
                    setDamageReports({
                        ...data,
                        result: data?.result?.map((item) =>
                            damageListDamageReportAdapter(item, t)
                        ),
                    });
                })
                .finally(() => {
                    setDamageReportsLoading(false);
                });
        }
    }, [
        t,
        sortModel,
        debouncedVnr,
        division,
        page,
        rowsPerPage,
        userData,
        rawCustomers,
        rawCustomerConnections,
    ]);

    const columns = useMemo(
        () =>
            generateDamageListTableHeaderForCreatedStatus(
                t,
                vnr,
                handleChangeTableSearchField,
                columnsSettingsList,
                handleGoToTheDetailView,
                hoverPosition,
                startPosition,
                isDragAndDropLocked
            ).sort((a, b) => {
                const aPosition = columnSettingsResponse.columns.find(
                    (item) => item.name === a.field
                )?.position;
                const bPosition = columnSettingsResponse.columns.find(
                    (item) => item.name === b.field
                )?.position;
                if (typeof aPosition !== 'number' || typeof bPosition !== 'number') {
                    return 1;
                }
                return aPosition > bPosition ? 1 : -1;
            }),
        [
            t,
            vnr,
            columnsSettingsList,
            handleChangeTableSearchField,
            handleGoToTheDetailView,
            isDragAndDropLocked,
            hoverPosition,
            startPosition,
        ]
    );

    return (
        <>
            {!isDamageReportsLoading && division && (
                <ThemeTable
                    infinite
                    minTableHeight={600}
                    columns={columns}
                    rows={damageReports?.result || []}
                    sortModel={sortModel}
                    handleChangeSort={handleChangeSort}
                    rowCount={damageReports?.count || 0}
                    rowsPerPageOptions={[10, 25, 50]}
                    pageSize={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    noRowsMessage={t('damages-list:noDamageReports')}
                    amountRecordsToShow={damageReports?.count}
                    amountRecordsToShowLoading={isDamageReportsLoading}
                />
            )}
            {isDamageReportsLoading && <ThemeLoader />}
        </>
    );
};
