import { makeStyles, Theme } from '@material-ui/core/styles';
import {
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorInstance,
    isHectorDemoInstance,
} from 'src/environment';
import { THEME_MODES } from '..';

interface StyleProps {
    withoutPagination?: boolean;
    isOnRowClick?: boolean;
    minTableHeight?: number;
}

export const useDataGridStyle = makeStyles(
    (theme: Theme) => {
        return {
            root: {
                userSelect: 'none',
                '& .MuiDataGrid-dataContainer': {
                    minWidth: 'auto',
                },
                '& .MuiDataGrid-main': {
                    minHeight: ({ minTableHeight }: StyleProps) => minTableHeight && 600,
                },
                '& .MuiDataGrid-sortIcon': {
                    color: theme.palette.info.contrastText,
                },
                '& .MuiDataGrid-colCell:focus-within': {
                    outlineWidth: '0!important',
                },
                '& .MuiDataGrid-colCellTitleContainer': {
                    alignItems: 'center',
                },
                '& .MuiDataGrid-colCellWrapper': {
                    alignItems: 'stretch',
                },
                '& .MuiDataGrid-colCellMoving': {
                    backgroundColor: theme.palette.secondary.main,
                },
                '& .MuiDataGrid-columnsContainer': {
                    backgroundColor: theme.palette.secondary.main,
                    '& .MuiTypography-root,.MuiDataGrid-colCellTitle': {
                        color: theme.palette.secondary.contrastText,
                    },
                },
                '& .MuiDataGrid-columnSeparator': {
                    display: 'none!important',
                },
                '& .MuiDataGrid-row': {
                    cursor: ({ isOnRowClick }: StyleProps) => isOnRowClick && 'pointer',
                    transition: '0.2s',
                    backgroundColor: isHectorInstance
                        ? theme.palette.type === THEME_MODES.DARK
                            ? theme.palette.secondary.light
                            : theme.palette.primary.main
                        : theme.palette.primary.main,
                    '&:nth-child(even)': {
                        backgroundColor: () => {
                            if (
                                isAonDigitalInstance ||
                                isAonMotorInstance ||
                                isHectorDemoInstance
                            ) {
                                return `${
                                    theme.palette.type === THEME_MODES.DARK
                                        ? theme.palette.info.main
                                        : theme.palette.secondary.light
                                }!important`;
                            }
                            if (isHectorInstance) {
                                return `${
                                    theme.palette.type === THEME_MODES.DARK
                                        ? theme.palette.secondary.dark
                                        : theme.palette.secondary.light
                                }!important`;
                            }
                            return `${theme.palette.primary.light}!important`;
                        },
                    },
                    '&:hover': {
                        backgroundColor: ({ isOnRowClick }: StyleProps) =>
                            isOnRowClick
                                ? `${theme.palette.info.light}!important`
                                : theme.palette.primary.main,
                    },
                },
                '& .MuiDataGrid-footer': {
                    display: ({ withoutPagination }: StyleProps) =>
                        withoutPagination && 'none!important',
                },
                '& .MuiDataGrid-cell,.MuiDataGrid-colCell': {
                    [theme.breakpoints.down('md')]: {
                        padding: theme.spacing(1),
                        '& .MuiTypography-root': {
                            padding: theme.spacing(1),
                        },
                    },
                },
            },
        };
    },
    { index: 1 }
);
