import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
    SyntheticEvent,
    ChangeEvent,
} from 'react';
import useDarkMode from 'use-dark-mode';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import clsx from 'clsx';

import { GridRowParams, GridSortModelParams } from '@material-ui/data-grid';
import { Button, Grid, Typography, IconButton, Box, Link } from '@material-ui/core';

import { useAuth } from 'src/user-management/context-auth';
import { useAbilityCtx } from 'src/roleAccesses';
import {
    useGlobalFilter,
    useContractsListSettings,
    defaultContractListSettingsFormData,
} from 'src/shared/contexts';
import {
    ThemeToggleButtons,
    ThemeTable,
    ThemeCheckboxesDropdown,
    ThemeLoader,
    ThemeCircularProgress,
    ThemeSearchField,
} from 'src/theming';
import { APP_ROUTES } from 'src/routing';
import { ContractsNavigationPanel } from 'src/contracts/contracts-navigation-panel/contracts-navigation-panel';
import { DivisionsSection } from 'src/contracts/division-section/division-section';
import { isCeatInstance } from 'src/environment';

import { useStyle } from '../contracts-styles/contracts-styles';
import {
    generateContractsListTableHeader,
    generateContractsListToggleButtons,
} from './contracts-list-configs';

import { useContractsDetailsSearch } from '../contracts-hooks';

import { globalSearchListGetAllowedFilterValues } from '../global-search-list/global-search-list-get-allowed-filter-values';
import { SearchOptions } from '../contracts-dashboard/generateContractTypeSearchOptions';
import { SECTIONS } from '../contracts-details-view/contract-details-view-details';

import { FilterOptions } from '../contracts-configs';

import { useSettings } from './use-contracts-list-settings';
import { useProductListAndDivision } from './use-contracts-products-list-and-division';
import { useContractsListData } from './use-contracts-list-data';
import {
    CurrentLockIcon,
    CurrentLockOpenIcon,
    CurrentSettingsIcon,
} from './contract-list-source';

const CEAT_APPLICATION_LINK = 'https://www.portal-bs.de/users/sign_in';

export const ContractsList = (): JSX.Element => {
    const darkMode = useDarkMode();
    const { t } = useTranslation(['common', 'contracts-list', 'contract-types']);
    const history = useHistory();
    const ability = useAbilityCtx();
    const location = useLocation<{ dontResetContractContext: boolean }>();

    const { userData } = useAuth();

    const { filter, division, changeDivision } = useGlobalFilter();

    const classes = useStyle({ isDarkMode: darkMode.value });

    const [isEditDivisionsSection, setEditDivisionsSection] = useState<boolean>(false);
    const [isDragAndDropLocked, setDragAndDropLocked] = useState<boolean>(true);

    const {
        sortModel,
        vnrValue,
        handleChangeVNR,
        productsList,
        handleChangeList,
        page,
        rowsPerPage,
        isSubmitted,
        search,
        reset,
        handleSubmit,
        setValue,
        handleFilter,
        handleChangeSort,
        handleChangePage,
        handleChangeRowsPerPage,
        filterValue,
    } = useContractsListSettings();

    const {
        setColumnsSettingsList,
        isColumnSettingsLoading,
        columnsSettingsList,
        columnSettingsResponse,
        setColumnSettingsResponse,
        updateTableSettings,
        onDragEnd,
        onDragStart,
        onDragUpdate,
        getTableSettings,
        hoverPosition,
        startPosition,
        handleResetSettings,
    } = useSettings();

    const updateProductListAndDivision = useProductListAndDivision();

    const [triggerUpdate, setTriggerUpdate] = useState(false);

    const { contracts, contractsLoading, contractsAmount, contractsAmountLoading } =
        useContractsListData(isColumnSettingsLoading, triggerUpdate);

    const onSearchSubmit = (): void => undefined;

    const handleCloseColumnSettings = async (): Promise<void> => {
        try {
            await updateTableSettings(
                columnsSettingsList.sort((a, b) =>
                    a?.data?.position > b?.data?.position ? 1 : -1
                )
            );
        } finally {
            const data = await getTableSettings();
            setColumnSettingsResponse({
                columns: data.columns?.sort((a, b) => (a.position > b.position ? 1 : -1)),
            });
        }
    };

    const handleColumnSettingsList = useCallback((newSettingsList): void => {
        setColumnsSettingsList(newSettingsList);
    }, []);

    const handleGoToTheDetailView = useCallback(
        (params: GridRowParams, preselectedTab?: SECTIONS): void => {
            const { id } = params;
            history.push({
                pathname: `${APP_ROUTES.CONTRACTS}/${id}`,
                state: {
                    preselectedTab,
                    prevPath: APP_ROUTES.CONTRACTS,
                    prevState: {
                        dontResetContractContext: true,
                    },
                },
            });
        },
        [history]
    );

    const goTo = (path: string): void => {
        history.push(path);
    };

    const toggleButtons = useMemo(() => generateContractsListToggleButtons(t), [t]);

    useEffect(() => {
        if (!location.state?.dontResetContractContext) {
            reset(defaultContractListSettingsFormData);
            handleChangeSort({ sortModel: [] } as GridSortModelParams);
            handleFilter({} as SyntheticEvent, FilterOptions.ACTIVE);
        }
    }, []);

    useEffect(() => {
        updateProductListAndDivision(
            division,
            null,
            !location.state?.dontResetContractContext ? [] : productsList
        );
    }, [division]);

    const onCloseHandler = useCallback(() => {
        setTriggerUpdate((value) => !value);
    }, []);

    const columns = useMemo(() => {
        if (productsList?.length > 0 && !isColumnSettingsLoading && division) {
            return generateContractsListTableHeader(
                t,
                vnrValue,
                handleChangeVNR,
                productsList,
                handleChangeList,
                columnsSettingsList,
                handleGoToTheDetailView,
                goTo,
                ability,
                onCloseHandler,
                isDragAndDropLocked,
                hoverPosition,
                startPosition
            ).sort((a, b) => {
                let aPosition = columnSettingsResponse.columns.find(
                    (item) => item.name === a.field
                )?.position;
                let bPosition = columnSettingsResponse.columns.find(
                    (item) => item.name === b.field
                )?.position;
                if (a.field === 'actions') {
                    aPosition = 1000;
                }
                if (b.field === 'actions') {
                    bPosition = 1000;
                }
                if (a.field === '') {
                    aPosition = -1;
                }
                if (b.field === '') {
                    bPosition = -1;
                }
                if (typeof aPosition !== 'number' || typeof bPosition !== 'number') {
                    return 1;
                }
                return aPosition > bPosition ? 1 : -1;
            });
        }
        return [];
    }, [
        t,
        vnrValue,
        handleChangeVNR,
        productsList,
        columnSettingsResponse,
        handleChangeList,
        columnsSettingsList,
        handleGoToTheDetailView,
        contracts,
        onCloseHandler,
        isDragAndDropLocked,
        hoverPosition,
        startPosition,
    ]);

    const allowedClientConnections = useMemo(
        () => globalSearchListGetAllowedFilterValues(filter.customerConnections),
        [filter]
    );
    const allowedClients = useMemo(
        () => globalSearchListGetAllowedFilterValues(filter.customers),
        [filter]
    );

    const contractsSearchResults = useContractsDetailsSearch(
        isSubmitted,
        search,
        allowedClients,
        null,
        null,
        allowedClientConnections
    );

    useEffect(() => {
        if (
            Array.isArray(contractsSearchResults.searchResults?.result) &&
            contractsSearchResults.searchResults.result?.length !== 0
        ) {
            reset({
                page,
                rowsPerPage,
                vnr: !location.state?.dontResetContractContext ? '' : vnrValue,
                search: '',
            });
            if (contractsSearchResults.searchResults.result.length === 1) {
                history.push(
                    `${APP_ROUTES.CONTRACTS}/${contractsSearchResults.searchResults.result[0].amsIdNr}`
                );
            } else if (contractsSearchResults.searchResults.result.length > 1) {
                history.push(APP_ROUTES.GLOBAL_SEARCH, {
                    type: SearchOptions.CONTRACT,
                    globalSearchTerm: search,
                });
            }
        } else {
            reset({
                page,
                rowsPerPage,
                vnr: !location.state?.dontResetContractContext ? '' : vnrValue,
                search: '',
            });
        }
    }, [contractsSearchResults.searchResults]);

    return (
        <Grid container wrap='nowrap' className={classes.contractsListContainer}>
            <Grid
                item
                container
                component='form'
                className={classes.toolBar}
                onSubmit={handleSubmit(onSearchSubmit)}
            >
                <ContractsNavigationPanel activeMenu='contracts' />
                <div style={{ flexGrow: 1 }} />
                {isCeatInstance && (
                    <Link
                        target='_blank'
                        href={CEAT_APPLICATION_LINK}
                        className={clsx(classes.containedButtons, classes.applicationBtn)}
                    >
                        {t('contracts-list:application')}
                    </Link>
                )}
                <div className={classes.searchFieldContainer}>
                    <ThemeSearchField
                        disabled={contractsSearchResults.searchLoading}
                        isLoading={contractsSearchResults.searchLoading}
                        name='search'
                        value={search}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setValue('search', e.target.value)
                        }
                    />
                </div>
            </Grid>
            <Grid item className={classes.divisionsContainer}>
                {Array.isArray(userData.divisions) && userData.divisions.length > 1 && (
                    <DivisionsSection
                        editMode={isEditDivisionsSection}
                        setEditMode={setEditDivisionsSection}
                        activeDivision={division}
                        handleOnClickCard={(division) =>
                            updateProductListAndDivision(division, changeDivision)
                        }
                    />
                )}
            </Grid>
            <Grid item container className={classes.contentContainer}>
                <Grid item container className={classes.settingsContainer}>
                    <Typography variant='h5'>{t('contracts-list:title')}</Typography>
                    <div style={{ flexGrow: 1 }} />
                    <Grid item container className={classes.settings}>
                        {!isDragAndDropLocked && (
                            <Box mr={2}>
                                <Button
                                    startIcon={
                                        <ThemeCircularProgress
                                            isLoading={isColumnSettingsLoading}
                                        />
                                    }
                                    disabled={isColumnSettingsLoading}
                                    className={classes.resetSettingsButton}
                                    onClick={handleResetSettings}
                                >
                                    {t('resetSettings')}
                                </Button>
                            </Box>
                        )}
                        <IconButton
                            className={classes.settingButton}
                            onClick={() => setDragAndDropLocked(!isDragAndDropLocked)}
                        >
                            {isDragAndDropLocked ? (
                                <CurrentLockIcon />
                            ) : (
                                <CurrentLockOpenIcon />
                            )}
                        </IconButton>
                        <ThemeCheckboxesDropdown
                            withoutBadge
                            buttonIcon={<CurrentSettingsIcon />}
                            itemsList={columnsSettingsList}
                            setItemsList={handleColumnSettingsList}
                            iconButtonClass={classes.settingButton}
                            onCloseHandler={handleCloseColumnSettings}
                        />
                        <ThemeToggleButtons
                            value={filterValue}
                            handleChange={handleFilter}
                            buttonsList={toggleButtons}
                        />
                    </Grid>
                </Grid>
                <Grid item className={classes.tableContainer}>
                    {(isColumnSettingsLoading || !columns.length) && <ThemeLoader />}
                    {!isColumnSettingsLoading && contracts && columns.length && (
                        <DragDropContext
                            onDragEnd={onDragEnd}
                            onDragUpdate={onDragUpdate}
                            onDragStart={onDragStart}
                        >
                            <Droppable
                                droppableId='contracts-list'
                                direction='horizontal'
                            >
                                {(provided) => (
                                    <ThemeTable
                                        infinite
                                        amountRecordsToShow={contractsAmount}
                                        amountRecordsToShowLoading={
                                            contractsAmountLoading
                                        }
                                        minTableHeight={600}
                                        dataLoadingMessage={t(
                                            'contracts-list:dataLoading'
                                        )}
                                        isLoading={contractsLoading}
                                        columns={columns}
                                        rows={contracts?.result || []}
                                        rowCount={contracts?.count || 0}
                                        rowsPerPageOptions={[10, 25, 50]}
                                        pageSize={rowsPerPage}
                                        sortModel={sortModel}
                                        handleChangeSort={handleChangeSort}
                                        page={page}
                                        getRowId={(r) => r.amsIdNr}
                                        onChangePage={handleChangePage}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                        noRowsMessage={t('contracts-list:noContracts')}
                                        innerRef={provided.innerRef}
                                        {...provided.droppableProps}
                                    />
                                )}
                            </Droppable>
                        </DragDropContext>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
