import { Tooltip as MuiTooltip } from '@material-ui/core';
import clsx from 'clsx';
import React, { cloneElement, useRef } from 'react';

import { useOverflowActive } from 'src/shared/hooks';

import { useStyles } from './theme-overflow-tooltip-styles';

interface Props {
    label: string;
    children: JSX.Element;
    dataForTooltip?: string;
}

export const ThemeOverflowTooltip = ({
    label,
    children,
    dataForTooltip,
}: Props): JSX.Element => {
    const {
        props: { className: childClassName },
    } = children;

    const classes = useStyles();
    const ref = useRef();

    const overflowActive = useOverflowActive(ref, label);

    const childElementWithRef = cloneElement(children, {
        ref,
        className: clsx(classes.overflowChild, childClassName && childClassName),
    });

    const renderWithTooltip = (): JSX.Element => (
        <MuiTooltip
            title={dataForTooltip || label}
            placement='top'
            classes={{ tooltip: classes.tooltip }}
        >
            {childElementWithRef}
        </MuiTooltip>
    );

    return overflowActive || dataForTooltip ? renderWithTooltip() : childElementWithRef;
};
