import { useState, useEffect } from 'react';

import { CheckboxItem } from 'src/theming';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

export interface ContractsAmount {
    division: string;
    amount: number;
}

interface UseContractsAmountReturn {
    isLoading: boolean;
    contractsAmounts: ContractsAmount[];
    refreshContractsAmounts: () => Promise<void>;
}

export const useContractsAmount = (
    customers: CheckboxItem[]
): UseContractsAmountReturn => {
    const isAllChecked = customers.find((item) => item.value === 'all')?.checked;
    const items = isAllChecked
        ? customers.filter((item) => item.value !== 'all')
        : customers.filter((i) => i.checked);
    const formattedCustomers = items.map((item) => item.value);

    const httpClient = useHttpClient();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [contractsAmounts, setContractsAmount] = useState<ContractsAmount[]>([]);

    const refreshContractsAmounts = async (): Promise<void> => {
        try {
            setLoading(true);
            const contractsAmounts = await httpClient.post<ContractsAmount[]>(
                `dashboard/contract/divisions-amounts`,
                {
                    customers: formattedCustomers,
                }
            );
            setContractsAmount(contractsAmounts);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (Array.isArray(formattedCustomers) && customers.length > 0) {
            refreshContractsAmounts();
        }
    }, [JSON.stringify(formattedCustomers)]);

    return {
        isLoading,
        contractsAmounts,
        refreshContractsAmounts,
    };
};
